/**
 * https://www.hl7.org/implement/standards/fhir/organization.html
 *
 *
 * всё про организацию
 *
 * поле type всегда должно быть ровно одно, type.coding тоже
 * TODO сделать ограничение - у организации адрес не может иметь тип home
 *
 *
 * типовой формат:
 *
 * {
 *     "identifier":[
 *         {
 *             "use":"official",
 *             "system":"urn:1.2.643.5.1.13.13.11.1461:5.151",
 *             "value":"1.2.643.5.1.13.13.12.2.77.7799"
 *         }
 *     ],
 *     "active":true,
 *     "type":[
 *         {
 *             "coding":[
 *                 {
 *                     "system":"http://terminology.hl7.org/CodeSystem/organization-type",
 *                     "code":"prov",
 *                     "display":"Healthcare Provider"
 *                 }
 *             ],
 *             "text":"Федеральное государственное бюджетное учреждение"
 *         }
 *     ],
 *     "name":"федеральное государственное бюджетное учреждение \"Национальный медицинский исследовательский центр онкологии имени Н.Н. Блохина\" Министерства здравоохранения Российской Федерации",
 *     "alias":[
 *         "ФГБУ \"НМИЦ онкологии им. Н.Н.Блохина\" Минздрава России"
 *     ],
 *     "telecom":[
 *         {
 *             "system":"phone",
 *             "value":"8 (499) 324-24-24",
 *             "use":"work"
 *         },
 *         {
 *             "system":"email",
 *             "value":"info@ronc.ru",
 *             "use":"work"
 *         },
 *         {
 *             "system":"url",
 *             "value":"https://www.ronc.ru/",
 *             "use":"work"
 *         }
 *     ],
 *     "address":[
 *         {
 *             "use":"work",
 *             "type":"physical",
 *             "text":"115478, г. Москва, Каширское шоссе 23",
 *             "line":[
 *                 "Каширское шоссе 23"
 *             ],
 *             "city":"Москва",
 *             "district":"Южный административный округ",
 *             "state":"Москва",
 *             "postalCode":"115478",
 *             "country":"RU"
 *         },
 *         {
 *             "use":"work",
 *             "type":"postal",
 *             "text":"115478, г. Москва, Каширское шоссе 24",
 *             "line":[
 *                 "Каширское шоссе 24"
 *             ],
 *             "city":"Москва",
 *             "district":"Южный административный округ",
 *             "state":"Москва",
 *             "postalCode":"115478",
 *             "country":"RU"
 *         }
 *     ]
 * }
 *
 *
 *
 */


import { DomainResource } from './DomainResource';
import { CodeableConcept } from '../Elements/CodeableConcept';
import { Reference } from '../Elements/Reference';
import { Identifier } from '../Elements/Identifier';
import { ContactPoint } from '../Elements/ContactPoint';
import { Address } from '../Elements/Address';
import { OID } from 'MODEL_STORE/DataSource/Constants/oid';

import { Geolocation } from 'MODEL_STORE/FHIR/Extensions/Geolocation';

import { computed } from 'mobx';

import _get from 'lodash.get';

export class Organization extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'Organization';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'partOf',
            'contact',
            'endpoint',
        ]);

        if (Array.isArray(data.identifier)) {
            data.identifier.forEach( e => {
                this._arrayAdd('identifier', new Identifier(e));
            });
        }

        this._data.active = ! data || ! data.hasOwnProperty('active') || !! data.active; // по умолчанию активно

        if (Array.isArray(data.type)) {
            data.type.forEach( e => {
                this._arrayAdd('type', new CodeableConcept(e));
            });
        }

        if (data.name) {
            this._data.name = data.name;
        }

        if (data.alias) {
            this._data.alias = [ ...data.alias ];
        }

        if (Array.isArray(data.telecom)) {
            data.telecom.forEach( e => {
                this._arrayAdd('telecom', new ContactPoint(e));
            });
        }

        if (Array.isArray(data.address)) {
            data.address.forEach( e => {
                this._arrayAdd('address', new Address(e));
            });
        }

        // временно используем переопределение использования расширений (для геолокации)
        this._data.extension = Array.isArray(data.extension) ? data.extension : [];

    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO сделать всё
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get identifier() {

        if ( ! this._data.identifier) {
            return [];
        }
        return this._data.identifier.map( e => new Identifier(e, e => this._arrayReplaceById('identifier', e.id, e) ) );
    }

    /**
     *
     */
    set identifier(newValue) {
        this._data.identifier = newValue.map( e => e._data );
    }

    /**
     *
     */
    get active() {
        return !! this._data.active
    }

    /**
     *
     */
    set active(newValue) {
        this._data.active = !! newValue;
    }

    /**
     *
     */
    get type() {

        if ( ! this._data.type) {
            return [];
        }
        return this._data.type.map( e => new CodeableConcept(e, e => this._arrayReplaceById('type', e.id, e) ) );
    }

    /**
     *
     */
    set type(newValue) {
        this._data.type = newValue.map( e => e._data );
    }

    /**
     *
     */
    get name() {
        return this._data.name
    }

    /**
     * alias for this.name
     */
    get displayName() {
        return this.name;
    }

    /**
     *
     */
    set name(newValue) {
        this._data.name = newValue;
    }

    /**
     *
     */
    get alias() {

        if ( ! Array.isArray(this._data.alias)) {
            return [];
        }

        return [ ...this._data.alias ];
    }

    /**
     *
     */
    set alias(newValue) {
        this._data.alias = [ ...newValue ];
    }

    /**
     *
     */
    get telecom() {

        if ( ! this._data.telecom) {
            return [];
        }
        return this._data.telecom.map( e => new ContactPoint(e, e => this._arrayReplaceById('telecom', e.id, e) ) );
    }

    /**
     *
     */
    set telecom(newValue) {

        // TODO вообще тут должно происходить клонироание значений, а не присвоение
        this._data.telecom = newValue.map( e => e._data );
    }

    /**
     *
     */
    get address() {

        if ( ! this._data.address) {
            return [];
        }
        return this._data.address.map( e => new Address(e, e => this._arrayReplaceById('address', e.id, e) ) );
    }

    /**
     *
     */
    set address(newValue) {
        this._data.address = newValue.map( e => e._data );
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    // немного кастома

    /**
     * идентификатор по реестру МО РФ / OID.MED_ORG_ROSTER_RU
     */
    @computed
    get idRoster() {

        if ( ! Array.isArray(this._data.identifier)) {
            return undefined;
        }

        const filter = i => i.system == OID.MED_ORG_ROSTER_RU;
        const index = this._data.identifier.findIndex(filter);

        return index === -1 ? undefined : this._data.identifier[index].value;
    }

    /**
     * установка идентификатора организации в реестре
     */
    set idRoster(newValue) {

        if(typeof newValue !== 'string') return false;

        const valueIsEmpty = ! newValue.trim().length;

        if ( ! Array.isArray(this._data.identifier)) {
            this._data.identifier = [];
        }
        const filter = i => i.system == OID.MED_ORG_ROSTER_RU;
        const index = this._data.identifier.findIndex(filter);

        if( index === -1 ) { // если идентификатор не найден, то добавляем (если значение указано)
            return valueIsEmpty
                ? false
                : this._data.identifier.push({
                    use: 'official',
                    value: newValue,
                    system: OID.MED_ORG_ROSTER_RU
                });
        } else { // если идентификатор найден, то удаляем (если значение пустое) или меняем значение
            return valueIsEmpty
                ? this._data.identifier.splice(index, 1)
                : this._data.identifier[index].value = newValue;
        }
    }

    /**
     * геттер для широты
     */
    get latitude() {
        return this.getGeolocation('latitude');
    }

    /**
     * установка широты
     */
    set latitude(value) {
        this.setGeolocation('latitude', value);
    }

    /**
     * геттер для долготы
     */
    get longitude() {
        return this.getGeolocation('longitude');
    }

    /**
     * установка долготы
     */
    set longitude(value) {
        this.setGeolocation('longitude', value);
    }

    /**
     * геттер для долготы и широты
     * https://www.hl7.org/fhir/extension-geolocation.html
     */
    getGeolocation(key) {
        return new Geolocation(_get(this._data, 'extension'))[key];
    }

    /**
     * сеттер для долготы и широты
     * https://www.hl7.org/fhir/extension-geolocation.html
     */
    setGeolocation(key, value) {
        const geolocation = new Geolocation(this._data.extension);
        geolocation[key] = value;
        const extensionIndex = _get(this._data, 'extension', []).findIndex(extension => _get(extension, 'url') == geolocation._data.url);
        return extensionIndex === -1
            ? this._arrayAdd('extension', geolocation)
            : this._arrayReplaceByIndex('extension', extensionIndex, geolocation);
    }
}
