import _get from "lodash/get";
import _set from "lodash/set";

/**
 * врач, установивший заболевание
 */
export class ConditionAsserter {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * имя врача
     */
    get display() {
        return _get(this.conditionResource, "asserter.display", "");
    }

    /**
     * изменение имени врача
     */
    onChange = (value) => {
        return (typeof value == "string" && value.trim().length) ? this.change(value) : this.reset();
    }

    /**
     * изменение имени врача
     */
    change = (value) => {
        _set(this.conditionResource, "asserter.display", value);
    }

    /**
     * удаление имени врача
     */
    reset = () => {
        if ( this.conditionResource.asserter ) {
            if ( this.conditionResource.asserter.display ) {
                delete(this.conditionResource.asserter.display);
            }
        }
    }

}
