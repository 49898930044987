import { axiosMaximus } from "./axiosMaximus";
import { makeUrl, makeConfig } from "./filesHelpers";


export const filesRequestPost = async (relativeUrl, data, requestConfig = {}) => {

    return axiosMaximus.post(
        makeUrl(relativeUrl),
        data,
        makeConfig(requestConfig)
    );
}
