import { action, observable } from 'mobx';
import moment from 'moment';
import { registerLocale } from "react-datepicker";


import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

import en from "date-fns/locale/en-US";
registerLocale("en", en);

export const DEFAULT_LOCALE = 'ru';

/**
 * стор для хранения данных
 * компонента Header
 */
class HeaderStoreClass {

    @observable title = '';
    @observable titlePrivate = '';
    @observable presentationMod = '';
    @observable patientId = '';
    @observable hide = false;
    @observable showLeftMenu = true;
    @observable needNotifUpdate = false;
    @observable breadcrumbs = [];
    @observable locale = DEFAULT_LOCALE;
    @observable headerParams = {};
    @observable isOpenNotificationsPopup = false;

    // дефолтная локаль согласно установкам в конфиге системы
    uiDefaultLanguageSetting = '';

    @action
    setTitle = (title = '') => {
        this.title = title;
    }

    @action
    setHeaderParam = (moreParams = {}) => {
        this.headerParams = { ...this.headerParams, ...moreParams};
    }

    @action
    setTitlePrivate = (titlePrivate = '') => {
        this.titlePrivate = titlePrivate;
    }

    @action
    setPresentationMod = (bool = '') => {
        this.presentationMod = bool;
    }

    @action
    setPatientId = (id = '') => {
        this.patientId = id;
    }

    @action
    setLeftMenuVisibility = (bool = true) => {
        this.showLeftMenu = bool;
    }

    @action
    setNeedNotifUpdate = (bool) => {
        this.needNotifUpdate = bool;
    }

    @action
    setIsOpenNotificationsPopup = (bool) => {
        this.isOpenNotificationsPopup = bool;
    }

    @action
    setBreadcrumbs = (bread) => {
        this.breadcrumbs = bread;
    }

    @action
    setLocale = (locale) => {
        moment.locale(locale);
        this.saveLocale(locale);
        this.locale = locale
    };

    saveLocale = (locale) => {
        window.localStorage.setItem('locale', locale);
    }

    loadLocale = () => {
        return window.localStorage.getItem('locale');
    }

    /**
     * эта штука нужна для избавления от циклической зависимости
     */
    @action
    setUiDefaultLanguageSetting = (locale) => {
        this.uiDefaultLanguageSetting = locale;

    }

    /**
     * определяем, какую локаль будем использовать
     * 1 - сохраненная
     * 2 - настройка из конфига
     * 3 - браузер
     */
    useLocale = () => {

        /*
        const savedLocale = this.loadLocale();
        if (savedLocale) {
            return savedLocale;
        }

        if (this.uiDefaultLanguageSetting) {
            return this.uiDefaultLanguageSetting;
        }

        if (window.navigator) {
            const browserLocale = (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage).substr(0, 2).toLowerCase();
            if (browserLocale) {
                return browserLocale;
            }
        }
        */

        return DEFAULT_LOCALE;
    }

    /**
     * локаль берём сохранённую, если её нет - из браузера
     */
    initLocale = () => {
        this.setLocale(this.useLocale());
    }
}

const HeaderStore = new HeaderStoreClass();

export { HeaderStore }
