/**
 * custom aidbox resource
 */

import { createId } from 'GUI_MAIN/Utils/createId';
import { action, observable, computed, toJS } from 'mobx';
import { LANGUAGES } from '../CodeSystem/Languages';
import { Resource } from './Resource';
import { Reference } from '../Elements/Reference';
import { BackboneElementNoId } from '../Elements/BackboneElementNoId';
import {Identifier} from "../Elements/Identifier";
import _get from 'lodash/get';

const SYSTEM_EMIAS_IDENTIFIER = "urn:moscow:emias:identifier:user:id";

export class User extends Resource {

    /**
     *
     */
    _setClassName() {
        this._className = 'User';
    }

    /**
     * новй пароль мы должны хранить в отдельном месте, чтобы понимать, хотят его поменять или нет
     */
    @observable
    _newPassword = '';

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'addressed',
            'costCenter',
            'department',
            'division',
            'email',
            'emails',
            'employeeNumber',
            'entitlements',
            'gender',
            'ims',
            'locale',
            'manager',
            'name',
            'organization',
            'phoneNumber',
            'phoneNumbers',
            'photo',
            'photos',
            'preferredLanguage',
            'profileUrl',
            'roles',
            'timezone',
            'title',
            'userType',
            'x509Certificates',
        ]);

        if (data.userName) {
			this._data.userName = data.userName.toLowerCase();
		}

        this._data.active = ! data || ! data.hasOwnProperty('active') || !! data.active; // по умолчанию активен


        if (data.hasOwnProperty('link')) {

			// TODO нужно отвязаться от объекта, поэтому вот так
            this._data.link = JSON.parse(JSON.stringify(data.link));
		}

        if (data.hasOwnProperty('language')) {
            this._data.language = data.language;
        }

        if (data.hasOwnProperty('password')) {
            this._data.password = data.password;
        }

        if (data.hasOwnProperty('data')) {
            this._data.data = data.data;
        }

        if (data.hasOwnProperty('app_roles')) {
            this._data.app_roles = data.app_roles;
        }

        if (data.hasOwnProperty("identifier")) {
            this._data.identifier = data.identifier;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check all

    }

    /**
     * ресурс для сохранения в бандле
     */
    bundleEntry() {

        // если новый пользователь, то всё стандартно
        if (this._isNewInstance) {
            return {
                request: {
                    method: "PUT",
                    url: `/User/${this._data.id}`,
                },
                resource: {
                    ...this._data,
                    password: this.newPassword
                }
            };
        }

        // если пользователь существующий, его обновляем другим методом, и учитываем, дали ли нам новый пароль
        // если ввели - надо перезаписать, иначе убираем вообще
        if (this.newPassword) {

            this._data.password = this.newPassword;
        } else {
            delete(this._data.password);
        }

        return {
            request: {
                method: "PATCH",
                url: `/User/${this._data.id}`,
            },
            resource: this._data
        };
    }
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    @computed
    get practitionerLink() {

        if ( ! Array.isArray(this._data.link)) {
            return null;
        }

        var link = this._data.link.find( (e) => e.type == 'practitioner_id' );

        if ( ! link) {
            return null;
        }

        return link;
    }

    /**
     *
     */
    set practitionerLink(practitioner) {
        if ( ! Array.isArray(this._data.link)) {
            this._data.link = [];
        }

        var existing = this._data.link.find( (e) => e.type == 'practitioner_id' );
        if (existing) {

            existing.link.id = practitioner.id;

        } else {
            this._data.link.push({
                "link" : {
                    "id" : practitioner.id,
                    "resourceType" : "Practitioner",
                },
                "type" : "practitioner_id",
            });
        }

    }

    get emiasId() {
        const identifier =  _get(this._data, "identifier", [])
            .find((i: any) => i.system === SYSTEM_EMIAS_IDENTIFIER);
        return _get(identifier, "value");
    }

    /**
     *
     */
    get identifier() {
        return this._data.identifier;
    }

    /**
     *
     */
    set identifier(newResource) {
        this._data.identifier = newResource;
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get newPassword() {
        return this._newPassword;
    }

    /**
     *
     */
    set newPassword(newValue) {
        this._newPassword = newValue;
    }

    /**
     *
     */
    get userName() {
        return this._data.userName;
    }

    /**
     *
     */
    set userName(newValue) {
        this._data.userName = newValue.toLowerCase();
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get active() {
        return this._data.active;
    }

    /**
     *
     */
    set active(newValue) {
        this._data.active = !! newValue;
    }

    ///////////////////////////////////////////////////////////////////////////
    /**
     * генерация нового токена для авторизации
     */
    generateToken = () => {
        this.token = createId();
    }

    /**
     * токен для регистрации по ссылке типа /registration/{token}
     */
    get token() {
        return (this._data.data && this._data.data.token) || '';
    }

    /**
     * установка токена для регистрации пользователя по ссылке типа /registration/{token}
     */
    set token(newValue) {
        if(this._data.data) {
            this._data.data.token = newValue;
        } else {
            this._data.data = {
                token: newValue
            }
        }
    }

    /**
     *
     */
    get link() {

        if ( ! this._data.link) {
            return [];
        }
        return this._data.link.map( (e) => new BackboneElementNoId(e, (e, index) => this._arrayReplaceByIndex('link', index, e) ) );
    }

    /**
    *
    */
    set link(newValue) {
        this._data.link = newValue.map( (e) => e._data );
    }

    /**
     * есть ли арм-роли у пользователя?
     */
    hasAccessRoles = () => {
        return this._data.data
            && Array.isArray(this._data.data.accessRole)
            && this._data.data.accessRole.length;
    }

}
