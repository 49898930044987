/**
 * http://hl7.org/fhir/datatypes.html#Attachment
 */

// @ts-ignore
import { Element } from 'MODEL_STORE/FHIR/Elements/Element';

export interface AttachmentConfig {
    contentType: string,
    size: number,
    title: string,
    language: string,
    url: string
}


export class Attachment extends Element {

    _className: 'Attachment';
    _data: AttachmentConfig;


    /**
     *
     */
    _init(data: AttachmentConfig) {

        super._init(data);

        if (data.hasOwnProperty('contentType')) {
            this._data.contentType = data.contentType;
        }

        if (data.hasOwnProperty('size')) {
            this._data.size = data.size;
        }

        this._data.title = data.title || '';
        this._data.url = data.url || '';

        if (data.language) {
            this._data.language = data.language;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check ALL
    }

    /**
     *
     */
    get contentType() {
        return this._data.contentType;
    }

    /**
     *
     */
    set contentType(newValue) {
        this._data.contentType = newValue;
    }

    /**
     *
     */
    get size() {
        return this._data.size;
    }

    /**
     *
     */
    set size(newValue) {
        this._data.size = newValue;
    }

    /**
     *
     */
    get title() {
        return this._data.title;
    }

    /**
     *
     */
    set title(newValue) {
        this._data.title = newValue;
    }

    /**
     *
     */
    get language() {
        return this._data.language;
    }

    /**
     *
     */
    set language(code) {
        this._data.language = code;
    }

    /**
     *
     */
    get url() {
        return this._data.url;
    }

    /**
     *
     */
    set url(newValue) {
        this._data.url = newValue;
    }
}
