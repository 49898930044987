/**
 * Консилиум
 *
 * {
 *     "comment": "just a comment",
 *     "description": "the appointment!",
 *     "id": "23142314234-4342434-2342332-4234",
 *     "participant": [
 *         Person, Location/HealthcareService or Device
 *     ],
 *     "intent": "",
 *     "status": "proposed | pending | booked | arrived | fulfilled | cancelled | noshow | entered-in-error | checked-in | waitlist"
 *     "subject": {
 *         Reference
 *     },
 *     "supportingInformation": [ // чек-лист
 *       { Reference }
 *     ],
 * }
 *
 *
 *
 */

import _get from "lodash/get";
import { DomainResource } from './DomainResource';
import { Reference } from '../Elements/Reference';
import { BackboneElement } from '../Elements/BackboneElement';
import { CodeableConcept } from "../Elements/CodeableConcept";
import { APPOINTMENT_STATUS } from '../CodeSystem/AppointmentStatus';
import { AppointmentConfirmationType } from "../Extensions/AppointmentConfirmationType";

import moment from "moment";
import { getFormat } from "Locale/datetime";
import {Translator} from "eo-locale";
import {HeaderStore} from "../../DataSource/Stores/HeaderStore";
import {locales} from "Locale";

// интервал времени в таймпикере (чтобы не было огромного списка)
const APPOINTMENT_TIME_TIMEPICKER_INTERVAL = 10;

// минимальная длительность консилиума, в минутах
const APPOINTMENT_MINIMAL_DURATION = 1;

const VKS_EXTENSION_URL = "http://miramedix.ru/fhir/StructureDefinition/appointment-vksContactDetail";

export class Appointment extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'Appointment';
    }


    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'identifier',
            'cancelationReason',
            'appointmentType',
            'reasonCode',
            'priority',
            'minutesDuration',
            'patientInstruction',
            'requestedPeriod',
        ]);

        this._data.status = data.status || APPOINTMENT_STATUS.PENDING;
        if (data.hasOwnProperty('start')) {
            this._data.start = data.start;
        }

        if (data.hasOwnProperty('end')) {
            this._data.end = data.end;
        }

        this._data.comment = data.comment;

        if (data.hasOwnProperty('slot')) {
            this._data.slot = data.slot.map( e => new Reference(e)._data )
        }

        if (data.hasOwnProperty('basedOn')) {
            this._data.basedOn = data.basedOn.map( e => new Reference(e)._data )
        }

        if (data.hasOwnProperty('reasonReference')) {
            this._data.reasonReference = data.reasonReference.map( e => new Reference(e)._data )
        }

        if (Array.isArray(data.supportingInformation)) {
            data.supportingInformation.forEach( e => {
                this._arrayAdd('supportingInformation', new Reference(e));
            });
        }

        if (data.hasOwnProperty('description')) {
            this._data.description = data.description
        }
        if (data.hasOwnProperty('created')) {
            this._data.created = data.created
        }

        if (data.hasOwnProperty('participant')) {
            this._data.participant = data.participant.map( e => new BackboneElement(e)._data )
        }

        if (data.hasOwnProperty('serviceType')) {
            this._data.serviceType = data.serviceType.map( e => new CodeableConcept(e)._data)
        }

        if (data.hasOwnProperty('serviceCategory')) {
            this._data.serviceCategory = data.serviceCategory.map( e => new CodeableConcept(e)._data)
        }

        // настройка "Требует согласования" (расширение/TB-315)
        this.confirmationType = new AppointmentConfirmationType(this._data);

    }

    /**
     *
     */
    _validate = () => {

        super._validate();

        if ( ! this._data.status) {
            throw 'Appointment.status is required';
        }
        if ( ! this._validateFieldCode(this._data.status, APPOINTMENT_STATUS)) {
            throw 'Appointment.status is invalid';
        }

        if ( ! this._data.participant) {
            console.warn('Appointment.participant is required');
            // throw 'Appointment.participant is required'; // TODO get it back
        }
        if ( ! Array.isArray(this._data.participant)) {
            console.warn('Appointment.participant must be an array');
            // throw 'Appointment.participant must be an array';
        }

        if (this._data.participant.length < 1) {
            console.warn('Appointment.participant must have at least one element');
            // throw 'Appointment.participant must have at least one element';
        };

        if (this._data.supportingInformation) {
            this._data.supportingInformation.forEach( e => {

                (new Reference(e))._validate();
            });
        }

        // TODO description
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get status() {
        return this._data.status;
    }

    /**
     *
     */
    set status(newValue) {
        this._data.status = newValue;
    }

    /**
     *
     */
    get description() {
        return this._data.description;
    }

    /**
     *
     */
    set description(newValue) {
        this._data.description = newValue;
    }

    /**
     *
     */
    get created() {
        return this._data.created;
    }

    /**
     *
     */
    set created(newValue) {
        this._data.created = newValue;
    }

    /**
     *
     */
    get basedOn() {

        if ( ! this._data.basedOn) {
            return [];
        }
        return this._data.basedOn.map( e => new Reference(e, ref => this._arrayReplaceById('basedOn', ref.id, ref) ) );
    }

    /**
     * на вход подаем массив моделей
     */
    set basedOn(newValue) {
        this._data.basedOn = newValue.map( e => e._data );
    }

    /**
     *
     */
    get reasonReference() {

        if ( ! this._data.reasonReference) {
            return [];
        }
        return this._data.reasonReference.map( e => new Reference(e, ref => this._arrayReplaceById('reasonReference', ref.id, ref) ) );
    }

    /**
     * на вход подаем массив моделей
     */
    set reasonReference(newValue) {
        this._data.reasonReference = newValue.map( e => e._data );
    }

    /**
     *
     */
    get participant() {
        if ( ! this._data.hasOwnProperty('participant')) {
            return [];
        }

        return this._data.participant.map( (e, index) => new BackboneElement(e, elem => this._arrayReplaceByIndex('participant', index, elem) ) );
    }

    set participant(newValue) {
        this._data.participant = newValue;
    }

    /**
     *
     */
    get serviceCategory() {
        if ( ! this._data.hasOwnProperty('serviceCategory')) {
            return [];
        }

        return this._data.serviceCategory;
    }

    set serviceCategory(newValue) {
        this._data.serviceCategory = newValue;
    }

    /**
     *
     */
    get start() {
        if ( ! this._data.start) {
            return null;
        }
        return moment(this._data.start);
    }

    /**
     *
     */
    set start(newValue) {
        this._setDateTimeField('start', newValue);
    }


    /**
     *
     */
    get end() {
        if ( ! this._data.end ) {
            return null;
        }
        return moment(this._data.end);
    }


    /**
     *
     */
    set end(newValue) {
        this._setDateTimeField('end', newValue);
    }

    /**
     * дата консилиума в удобочитаемом виде
     */
    get startDate() {
        const translator = new Translator(HeaderStore.locale, locales);
        return this._data.start ? moment(this._data.start).format(getFormat("DT000001")) : translator.translate('TT023450');
    }

    /**
     *
     */
    get comment() {
        return this._data.comment;
    }

    /**
     *
     */
    set comment(newValue) {
        this._data.comment = newValue;
    }

    /**
     *
     */
    get slot() {
        if ( ! this._data.hasOwnProperty('slot') ) {
            return [];
        }
        return this._data.slot.map( e => new Reference(e, ref => this._arrayReplaceById('slot', ref.id, ref) ) );
    }

    /**
     *
     */
    set slot(newValue) {
        this._data.slot = newValue.map( e => e._data );
    }

    /**
     *
     */
    get supportingInformation() {

        if ( ! this._data.supportingInformation) {
            return [];
        }
        return this._data.supportingInformation.map( e => new Reference(e, info => this._arrayReplaceById('supportingInformation', info.id, info) ) );
    }

    /**
     *
     */
    set supportingInformation(newValue) {
        this._data.supportingInformation = newValue.map( e => e._data );
    }

    /**
     * хелпер для работы с временем проведения консилиума
     */
    get date() {
        return new AppointmentDate(this);
    }

    /**
     * спец. геттер даты проведения консилиума (например, 22 декабря 2019 10:00 - 12:00)
     */
    get periodForHuman() {
        if( ! this._data.start ) {
            return 'n/a';
        }
        try {
            const start = moment(this._data.start).format(getFormat("DT000003"));
            if( ! this._data.end ) {
                return start;
            }
            const end = moment(this._data.end).format(getFormat("DT000018"));
            return `${start}-${end}`;
        } catch(error) {
            console.error(`Ошибка при конвертации даты проведения консилиума`);
        }
        return 'n/a';
    }

    /**
     * ссылка на комнату для вкс
     */
    get vksLink() {
        const ext = _get(this._data, "extension", []).find(e => e.url == VKS_EXTENSION_URL);
        if (!ext) {
            return null;
        }

        const telecomUrl = _get(ext, "valueContactDetail.telecom", []).find(
            t => t.system == "url" && t.use == "work"
        );

        if (!telecomUrl) {
            return null;
        }

        return telecomUrl.value;
    }

    set vksLink(newValue) {
        if ( !Array.isArray(this._data.extension)) {
            this._data.extension = [];
        }
        let newExtension = this._data.extension.filter(e => e.url !== VKS_EXTENSION_URL);
        if (newValue.trim().length !== 0) {
            newExtension.push({
                "url": VKS_EXTENSION_URL,
                "valueContactDetail": {
                    "telecom": [
                        {
                            "use": "work",
                            "value": newValue.trim(),
                            "system": "url"
                        }
                    ]
                }
            });
        } else {
            const index = this._data.extension.findIndex(e => e.url === VKS_EXTENSION_URL);
            if ( index >= 0 ) {
                this._data.extension.splice(index, 1);
            }
        }
        this._data.extension = newExtension;
    }

    /**
     * квант времени для таймпикера при выборе времени начала и окончания
     * если делать по минуте - селектор огромный получается
     */
    get timePickerInterval() {
        return APPOINTMENT_TIME_TIMEPICKER_INTERVAL;
    }

    /**
     *
     */
    get minimalDuration() {
        return APPOINTMENT_MINIMAL_DURATION;
    }


}

/**
 * специальный класс-хелпер для работы с датой проведения консилиума
 */
class AppointmentDate {

    constructor(appointment) {
        this.appointment = appointment;
    }

    /**
     * кол-во дней до сегодняшней даты
     */
    get days() {
        if( ! this.appointment._data.start ) {
            return "n/a";
        }
        const start = moment(this.appointment._data.start);
        if( start.isBefore(moment()) ) {
            return "SS001021";
        }
        const days = start.diff(moment(), 'days');
        if(days >= 11 && days <= 14) {
            return days + ' дней';
        }
        const modulo = days % 10;
        if(modulo == 1) {
            return days + ' день';
        }
        if([2, 3, 4].includes(modulo)) {
            return days + ' дня';
        }
        return days + ' дней';
    }

    /**
     * кол-во дней до сегодняшней даты без текста, для локализации
     */
    get daysWithLocale() {
        if( ! this.appointment._data.start ) {
            return "n/a";
        }
        const start = moment(this.appointment._data.start);
        if( start.isBefore(moment()) ) {
            return "SS001021";
        }
        return start.diff(moment(), 'days');
    }

    /**
     * кол-во часов до сегодняшней даты без текста, для локализации
     */
    get hoursWithLocale() {
        if( ! this.appointment._data.start ) {
            return "n/a";
        }
        const start = moment(this.appointment._data.start);
        if( start.isBefore(moment()) ) {
            return "SS001021";
        }
        return start.diff(moment(), 'hours');
    }

    /**
     * удобочитаемая дата проведения консилиума
     */
    get human() {
        return this.appointment.start ? this.appointment.start.format(getFormat("DT000020")) : 'n/a';
    }

    /**
     *  дата проведения консилиума
     */
    get startDate() {
        return this.appointment._data.start ? moment(this.appointment._data.start).toDate() : null;
    }

    /**
     * при первоначальной установке даты консилиума устанавливаем время начала в 8 утра
     */
    set startDate(date) {
        if ( !date ) {
            this.appointment._setDateTimeField('start', "");
            this.appointment._setDateTimeField('end', "");
            return
        }
        this.appointment._setDateTimeField('start', moment(date).set({hour: 8, minute: 0, second: 0}));
        this.appointment._setDateTimeField('end', moment(date).set({hour: 9, minute: 0, second: 0}));
    }

    /**
     * при первоначальной установке даты консилиума устанавливаем время начала в 8 утра
     */
    set startDateNotEditEnd(date) {
        this.appointment._setDateTimeField('start', moment(date).set({hour: 8, minute: 0, second: 0}));
    }

    /**
     * время начала консилиума
     */
    get startTime() {
        return this.appointment._data.start ? moment(this.appointment._data.start).toDate() : null;
    }

    get startTimeHuman() {
        return this.appointment._data.start ? moment(this.appointment._data.start).format(getFormat("DT000018")) : null;
    }

    /**
     * при установке времени начала консилиума автоматически сдвигаем время окончания на час
     */
    set startTime(date) {

        const suggestedMoment = moment.isMoment(date) ? date : moment(date);
        this.appointment._setDateTimeField('start', moment.min(this.startMaxMoment, suggestedMoment));

        // не разрешаем консилиуму заканчиваться следующими сутками
        const limitEndTime = moment(date).set({hour: 23, minute: 59, second: 0});
        const suggestedEndTime = moment(date).add(1, "hours");

        this.appointment._setDateTimeField('end', moment.min(suggestedEndTime, limitEndTime).format());
    }

    /**
     * установка времени начала консилиума принудительно
     */
    set startDateTime(date) {
        this.appointment._setDateTimeField('start', date);
    }

    /**
     * минимальное время начала консилиума на основе даты проведения
     */
    get startMinTime() {
        return this.appointment._data.start ? moment(this.appointment._data.start).set({hour: 0, minute:0, second: 0}).toDate() : null;
    }

    /**
     * максимальное время начала консилиума на основе даты проведения
     * нужно оставить время, чтобы выставить окончание
     */
     get startMaxMoment() {
        if (this.appointment._data.start) {
            return moment(this.appointment._data.start)
                .set({hour: 23, minute: 59, second: 0})
                .subtract(this.appointment.minimalDuration, 'minute')
        }
        return null;
    }

    get startMaxTime() {
        const maxMoment = this.startMaxMoment;
        if (maxMoment) {
            return maxMoment.toDate();
        }
        return null;
    }

    /**
     * время окончания консилиума
     */
    get endTime() {
        return this.appointment._data.end ? moment(this.appointment._data.end).toDate() : null;
    }

    /**
     * установка времени окончания консилиума
     */
    set endTime(date) {
        this.appointment._setDateTimeField('end', moment(date));
    }

    /**
     * минимальное время окончания консилиума (+1 минута от начала)
     */
    get endMinTime() {
        return this.appointment._data.start ? moment(this.appointment._data.start).add(1, "minutes").toDate() : null;
    }

    /**
     * максимальное время окончания консилиума - полночь того же дня
     */
    get endMaxTime() {
        return this.appointment._data.start ? moment(this.appointment._data.start).endOf("day").toDate() : null;
    }

    /**
     * минимальная дата проведения для копии консилиума
     * (запрет указания прошедшей даты)
     */
    get minStartDateForCopy() {
        return moment().toDate();
    }

    /**
     * минимальное время начала для копии консилиума на основе даты проведения
     */
    get minStartTimeForCopy() {
        if( this.appointment._data.start ) {
            const today = moment();
            if( moment(this.appointment._data.start).isSame(today, "day") ) {
                return today.toDate();
            }
        }
        return this.startMinTime;
    }

    /**
     * установка даты начала для копии консилиума
     */
    setStartDateForCopy = (newDate) => {
        const today = moment();
        const date = moment(newDate);
        if( date.isSame(today, "day") ) {
            const remainder = today.minute() % 10;
            // добавляем от 1-ой до 10 минут к текущему времени
            const minutes = remainder ? (10 - remainder) : 10;
            today.add(minutes, "minutes");

            const startTime = today.clone();
            const endTime = startTime.clone().add(1, 'hour');

            const minStartTime = moment().endOf('day').second(0).subtract(1, 'minute');
            const minEndTime = moment().endOf('day').second(0);

            this.appointment._setDateTimeField("start", moment.min(startTime, minStartTime));
            this.appointment._setDateTimeField("end", moment.min(endTime, minEndTime));
        } else {
            this.startDate = newDate;
        }
    }

    /**
     * установка времени начала для копии консилиума
     */
    setStartTimeForCopy = (newDate) => {
        const date = moment(newDate);
        if(date.isBefore(this.minStartTimeForCopy)) {
            return console.log("время начала для копии консилиума не может быть меньше текущего времени");
        }
        this.startTime = date;
    }

    /**
     * установка времени окончания для копии консилиума
     */
    setEndTimeForCopy = (newDate) => {
        const date = moment(newDate);
        if(date.isBefore(this.startTime)) {
            return console.log("время окончания для копии консилиума не может быть меньше времени начала");
        }
        this.endTime = date;
    }

    /**
     * минимальная дата проведения для копии консилиума
     * (запрет указания прошедшей даты)
     */
    get minStartDateForCopy() {
        return moment().toDate();
    }

}
