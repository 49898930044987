/**
 * приспособа для сохранения жанных в виде бандла-транзакции
 *
 *
 *
 */

import { basicRequestPost } from 'MODEL_STORE/DataSource/Requests/basicRequestPost';
import _cloneDeep from 'lodash/cloneDeep';



export class Bundle {

    /**
     * сюда складываем сущности, которые нужно будет сохранить
     */
    data;

    /**
     *
     */
    constructor() {

        this.reset();
    }

    /**
     *
     */
    reset() {

        this.data = [];

        return this;
    }

    /**
     *
     */
    add(resource, method = 'PUT') {

        // TODO в некоторых случаях нужно вырезать ID из ресурса
        const targetUrl = '/' + resource._className + '/' + resource.id;

        let bundleElement = {
            request: {
                method: method,
                url: targetUrl,
            },
        };

        if (method == 'PUT' || method == 'POST') {
            bundleElement.resource = resource._data;
        }

        this.data.push(bundleElement);

        return this;
    }

    /**
     * добавление полного ресурса, с методом и адресом
     */
    addRaw = (data) => {
        this.data.push(data);

        return this;
    }

    /**
     *
     */
    flush() {
        return this.save()
            .then( (result) => {
                this.reset();
                return result;
            });
    }

    /**
     *
     */
    save() {

        const fullData = {
            "resourceType": "bundle",
            "type": "transaction",
            "entry": this.data,
        };

        // https://miramedix.atlassian.net/browse/MIRA-146
        // fullData.entry.forEach( (elem, index) => {
        //     if (elem.resource && elem.resource.meta) {
        //         delete(fullData.entry[index].resource.meta);
        //     }
        // });

        return basicRequestPost('/api/mm/bundle/post', fullData);
    }

    toExport() {
        const fullData = {
            "resourceType": "bundle",
            "type": "transaction",
            "entry": this.data,
        };

        // https://miramedix.atlassian.net/browse/MIRA-146
        // fullData.entry.forEach( (elem, index) => {
        //     if (elem.resource && elem.resource.meta) {
        //         delete(fullData.entry[index].resource.meta);
        //     }
        // });

        return JSON.stringify(fullData);
    }
}
