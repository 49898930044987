import _get from "lodash/get";

/**
 * тип морфологического исследования
 * (цитологический или гистологический)
 */
export class MorphologicalObservationType {

    /**
     * кодирование по умолчанию
     * https://wiki.mmdx.ru/pages/viewpage.action?pageId=2006450194
     */
    static DEFAULT_CODING = {
        "text": "Морфологическое исследование (неуточненное)",
        "code": "morphological-examination-unspecified",
        "system": "http://miramedix.ru/fhir/CodeSystem/entity-type",
        "display": "Морфологическое исследование (неуточненное)"
    }


    /**
     * кодирование типов (TODO: справочник?)
     */
    static CODINGS = [
        {
            "text": "Цитологическое исследование",
            "code": "116147009",
            "system": "http://snomed.info/sct",
            "display": "Cytologic test"
        },
        {
            "text": "Гистологическое исследование",
            "code": "714797009",
            "system": "http://snomed.info/sct",
            "display": "Histologic test"
        },
    ]

    /**
     * код цитологического исследования
     */
    static CYTOLOGICAL_CODE = "116147009";

    /**
     * код гистологического исследования
     */
    static HISTOLOGICAL_CODE = "714797009";

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://snomed.info/sct";

    /**
     * код обследования для морфологического исследования
     * (если не указан ни цитологический ни гистологический)
     */
    static UNSPECIFIED_CODE = "morphological-examination-unspecified";

    /**
     * система обследования для морфологического исследования
     * (если не указан ни цитологический ни гистологический)
     */
    static UNSPECIFIED_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/entity-type";

    /**
     * ссылка на ресурс Observation
     */
    resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * кодирование
     */
    get coding() {
        const codingSystems = MorphologicalObservationType.CODINGS.map(coding => coding.system);
        return _get(this.resource, "code.coding", []).find(coding => {
            return codingSystems.includes(coding.system);
        })
    }

    /**
     * название (например, Цитологическое исследование)
     */
    get text() {
        return _get(this.resource, "code.text", "");
    }

    /**
     * код (например, 116147009)
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название (например, Cytologic test)
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * система (http://snomed.info/sct)
     */
    get system() {
        return _get(this.coding, "system", "");
    }

    /**
     * изменение
     */
    onChange = (code) => {
        const coding = MorphologicalObservationType.CODINGS.find(coding => coding.code == code);
        if ( coding ) {
            this.resource.code = {
                "text": coding.text,
                "coding": [{
                    "code": coding.code,
                    "system": coding.system,
                    "display": coding.display
                }]
            }
        }
    }

}