import React from 'react';
import {observer, inject} from 'mobx-react';
import {reaction} from 'mobx';
import Button, {CommonButtonWrapper} from 'GUI_MAIN/Elements/Button';
import Input from 'GUI_MAIN/Elements/Input';
import {logAction} from 'Stat/logAction';
import styled from "styled-components";
import StyledReactSelect from "GUI_MAIN/Elements/StyledReactSelect";
import _get from "lodash/get";
import {resetPatientsHideDataSettings} from "GUI_MAIN/Utils/localStorageSettings";
import {AuthWrapper} from "Auth/Components/AuthWrapper";
import {clearFiltersInLocalStorage} from "GUI_MAIN/Components/Header/clearFiltersInLocalStorage";
import {locales} from "Locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {Translator, EOLocale} from "eo-locale";
import { base64toUtf }  from "GUI_MAIN/Utils/base64toUtf";
import {overPosFloat, StyledReactTooltip} from "../../../GUI_MAIN/Elements/StyledReactTooltip";
import Logger from '../../../logger';
import { Paths } from 'GUI_TB/Routs/Paths';
import { OfficialWrapper } from "GUI_MAIN/Components/Maintenance/OfficialWrapper";
import { removeProtocolResourcesFromLocalStorage } from "GUI_TB/Components/ProtocolsV2/removeProtocolResourcesFromLocalStorage";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: ${props => props.mainMargin || "0"};
    gap: 16px;
    width: 100%;
`;

const ButtonFlex = styled.div`
    display: flex;
    flex: 1 1;
    justify-content: flex-end;

    ${CommonButtonWrapper}{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
`;

const SvgWrapper = styled.div`
    cursor: pointer;
`;

const UserText = styled.div`
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
    min-height: 38px;
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    color: ${props => props.theme.darkMainTextColor};

    span {
        font-family: ${props => props.theme.OpenSansSemiBold};
        display: inline;
        word-break: break-word;
    }
`;

const RedirectionText = styled.div`
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansSemiBold};
    color: ${props => props.theme.darkMainTextColor};
    word-break: break-word;
`;

const Error = styled.span`
    display: block;
    font-family: ${props => props.theme.OpenSansRegular};
    color: ${props => props.theme.mainRedColor};
    font-size: ${props => props.theme.normalFontSize};
    margin-bottom: 16px;
`;

const WrongWrapper = styled.div`
    width: 660px;
    align-self: center;
`;

const WrongHeader = styled.div`
    text-align: left;
    font-size: ${props => props.theme.twentyFontSize};
    font-family: ${props => props.theme.OpenSansBold};
    margin-bottom: 26px;
`;

const WrongText = styled.div`
    text-align: left;
    width: 100%;
    font-size: ${props => props.theme.bigFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    color: ${props => props.theme.lightenMainTextColor};
    word-break: break-word;
`;

const WrongButtonWrapper = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    width: 172px;
`;

@inject('ToasterStore')
@inject('AuthStore')
@observer
export class AuthPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login         : '',
            password      : '',
            organization  : null,
            role          : null,
            errorLogin    : false,
            changePassword: false,
            redirecting   : false,
        }

        // Показать форму Изменение пароля
        if (this.props.match.path == '/password-reset') {
            const params = new URLSearchParams(this.props.history.location.search);
            this.props.AuthStore.showResetPasswordChangeActivity({
                key  : params.get('key'),
                email: params.get('email'),
            })
        }

        this.redirectReactionDisposer = reaction(
            () => this.mustRedirect,
            () => {
                if (this.mustRedirect && this.canRedirect && !this.isErrorMode && !this.state.redirecting) {
                    console.log("starting OpenID auth")
                    this.initiateOpenId();
                }
            },
            { fireImmediately: true },
        )

        if (this.isErrorMode) {
            console.warn("error details:", this.parseSearchQuery());
        }

        // удаление копий ресурсов протокола из локального хранилища
        // https://jira.mmdx.ru/browse/FCSPOK-54
        removeProtocolResourcesFromLocalStorage();
    }

    /**
     * в адресной строке может придти, например, указание показывать форму ошибки вместо формы логина
     */
    parseSearchQuery = () => {
        const params = new URLSearchParams(window.location.search);
        const detailsInfoEncoded = params.get("error-details");

        if (detailsInfoEncoded) {
            try {
                return JSON.parse(base64toUtf(detailsInfoEncoded));
            } catch {}
        }

        return {};
    }

    componentWillUnmount() {
        this.redirectReactionDisposer();
    }

    get isErrorMode() {
        const searchParams = this.parseSearchQuery()
        const errorModeCodes = [
            "openid-general-failure",
        ];

        return errorModeCodes.includes(searchParams["code"]);
    }

    /**
     * активация режима входа с логином-паролем
     */
    get showLegacyLogin() {
        return window.location.search.split("?").includes("showLegacyLogin");
    }

    /**
     * если не легаси-вход и только начинаем логин - сразу в ЕСИА
     */
    get mustRedirect() {
        return !this.props.AuthStore.currentUserData.selectRole && !this.showLegacyLogin;
    }

    /**
     * в некоторых случаях редиректить не надо (например, бэкенд видит, что с есиа проблемы)
     */
    get canRedirect() {
        if (this.isErrorMode) {
            return false;
        }

        return true;
    }

    initiateOpenId = () => {
        this.props.AuthStore.startOpenId()
            .then(() => {
                this.setState({ redirecting: true });
            })
            .catch(() => {
                this.setState({ redirecting: false });
            })
            ;
    }

    submit = () => {
        const {AuthStore} = this.props;
        const translator = new Translator(HeaderStore.locale, locales);
        Logger.createAuthentication({login: this.state.login});
        AuthStore.login(this.state.login, this.state.password)
            .then(() => {
                if (AuthStore.currentUserData.isRoot()) {
                    return this.props.completed();
                }
            })
            .then(() => {
                if (AuthStore.currentUserData.isRoot()) {
                    logAction('AUTH:LOGIN', {login: this.state.login});
                    this.props.history.push(Paths.dashboard.path());
                }
            })
            .catch(result => {
                console.warn(result);
                this.setState({errorLogin: true})
                this.props.ToasterStore.error(
                    translator.translate('SS902650')
                    + (typeof result == 'string' ? ' : \r\n' + result : '')
                );
            })
    };

    extractErrorMessage = (result) => {
        if (typeof result == 'string') {
            return result;
        }

        const tryThis = _get(result, "response.data.message");
        if (tryThis) {
            return tryThis;
        }

        return "";
    }

    submitLastStep = () => {
        const {role} = this.state;
        const {AuthStore} = this.props;

        const urlParams = new URLSearchParams(this.props.location.search);
        const source = urlParams.get('source');

        AuthStore.changeRoleId(role.value, source)
            .then(() => {
                return this.props.completed();
            })
            .then(() => {
                logAction('AUTH:LOGIN', {login: this.state.login});
                //this.props.history.push(Paths.dashboard.path());
            })
            .catch(result => {
                console.warn(result);

                const errorMessage = this.extractErrorMessage(result);
                this.props.ToasterStore.error(
                    'Ошибка входа' + (errorMessage ? ":\n\n" + errorMessage : "")
                );
            })
    };

    checkEnter = (e) => {
        if (e.key == 'Enter') {
            this.submit();
        }
    };

    doLogout = () => {
        this.setState({"role": null});
        this.setState({"organization": null});

        this.props.AuthStore.logout();

        // сброс настроек для презентаций (скрытие/отображение имени пациента)
        resetPatientsHideDataSettings();
        clearFiltersInLocalStorage();
    };

    somethingWentWrong = () => {
        return (
            <OfficialWrapper>
                <WrongWrapper>
                    <WrongHeader>
                        Ошибка входа в систему
                    </WrongHeader>
                    <WrongText>
                        Не удалось получить доступ к системе,
                        попробуйте войти позже или обратитесь
                        в службу технической поддержки.
                    </WrongText>
                    <WrongText>
                        Приносим извинения за временные неудобства
                    </WrongText>
                    <WrongButtonWrapper>
                        <Button
                            onClick={this.initiateOpenId}
                            label={"Попробовать снова"}
                            width={"100%"}
                            />
                    </WrongButtonWrapper>
                </WrongWrapper>
            </OfficialWrapper>
        );
    };

    loginForm = () => {
        const translator = new Translator(HeaderStore.locale, locales);
        return (
            <AuthWrapper>
                <form>
                    <Input
                        onKeyPress={this.checkEnter}
                        value={this.state.login}
                        //autoComplete="login-form login"
                        label={translator.translate('SS902631')}
                        onChange={value => this.setState({login: value})}
                        mainMargin={"0 0 16px 0"}
                        data-test-id="authpage-login"
                        placeholder={"Введите логин"}
                        isClearable
                    />
                    <Input
                        onKeyPress={this.checkEnter}
                        type="password"
                        value={this.state.password}
                        //autoComplete="login-form password"
                        label={translator.translate('SS902632')}
                        onChange={value => this.setState({password: value})}
                        mainMargin={this.state.errorLogin ? "0" : "0 0 16px 0"}
                        data-test-id="authpage-password"
                        needShow={true}
                        placeholder={"Введите пароль"}
                        isClearable
                    />
                    {
                        this.state.errorLogin &&
                        <Error>{translator.translate('SS902648')}</Error>
                    }
                    <ButtonWrapper>
                        <ButtonFlex>
                            <Button
                                onClick={this.initiateOpenId}
                                label={"Войти через ЕСИА"}
                                width={"100%"}
                            />
                        </ButtonFlex>
                        <Button
                            onClick={this.submit}
                            label={translator.translate('SS902633')}
                            width={"75px"}
                        />
                    </ButtonWrapper>
                </form>
            </AuthWrapper>
        )
    }

    roleSelectionForm = () => {
        const {AuthStore} = this.props;
        const {organization, role} = this.state;

        const translator = new Translator(HeaderStore.locale, locales);

        let organizationsOptions = AuthStore.currentUserData.organizations
            .map(org => {
                return {value: org.id, label: org.displayName}
            });

        let rolesOptions = AuthStore.currentUserData.roles;
        if (organization) {
            rolesOptions = rolesOptions.filter(i => i.organization.id === organization.value);
        }
        rolesOptions = rolesOptions.map(role => ({
            value: role.id,
            label: _get(role, "code.0.coding.0.display") || _get(role, "code.0.text")
        }));
        return (
            <AuthWrapper>
                <UserText>
                    <>
                        {translator.translate('SS902304')}&nbsp;
                        <span>
                            {_get(AuthStore.currentUserData, "person.displayFullName", "")}
                        </span>
                    </>
                </UserText>
                <form action="">
                    <StyledReactSelect
                        filterByLabelOnly
                        value={organization}
                        options={organizationsOptions}
                        onChange={organization => {
                            this.setState({organization});
                            this.setState({role: null});
                        }}
                        label={translator.translate('SS902652')}
                        mainMargin={"0 0 16px 0"}
                        placeholder={"Организация"}
                        nonPortal
                    />
                    <StyledReactSelect
                        filterByLabelOnly
                        value={role}
                        options={rolesOptions}
                        onChange={role => this.setState({role})}
                        label={translator.translate('SS902653')}
                        mainMargin={"0 0 16px 0"}
                        isDisabled={!organization}
                        placeholder={"Должность"}
                        nonPortal
                    />
                    <ButtonWrapper>
                        {
                            !AuthStore.currentUserData.active_role_id &&
                            <SvgWrapper>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                    onClick={this.doLogout}>
                                    <path d="M24 15H11.83L17.42 9.41L16 8L8 16L16 24L17.41 22.59L11.83 17H24V15Z" fill="#A1A4B1"/>
                                </svg>
                            </SvgWrapper>
                        }
                        <ButtonFlex>
                            <Button
                                disabled={!role}
                                onClick={this.submitLastStep}
                                label={translator.translate('SS902654')}
                                minWidthAuto
                                width={"74px"}
                            />
                        </ButtonFlex>
                    </ButtonWrapper>
                    <StyledReactTooltip
                        id={"float-tooltip"}
                        className={'light light-simple tooltip-menu'}
                        type="light"
                        place='bottom'
                        effect="float"
                        overridePosition={overPosFloat}
                    />
                </form>
            </AuthWrapper>
        )
    }

    redirectionSplash = () => {
        return (
            <AuthWrapper>
                <RedirectionText>
                    Запрос в ЕСИАиА...
                </RedirectionText>
            </AuthWrapper>
        );
    }

    render() {

        const {
            AuthStore,
        } = this.props;

        // тут важен порядок

        if (this.state.redirecting) {
            return this.redirectionSplash();
        }

        if (this.isErrorMode) {
            return this.somethingWentWrong();
        }

        if (AuthStore.currentUserData.selectRole) {
            return this.roleSelectionForm();
        }

        if (this.showLegacyLogin) {
            return this.loginForm();
        }

        return (
            <AuthWrapper>
                <RedirectionText>
                    ...
                </RedirectionText>
            </AuthWrapper>
        )
    };

}
