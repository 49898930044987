export const toast_dictionary = {
    TT010020: "Copied!",
    TT010040: "Navigation context not found",
    TT010060: "Enter the password again to confirm",
    TT010070: "Password must be at least 6 characters",
    TT010080: "Entered passwords don't match",
    TT010090: "Failed to get context id",
    TT010100: "Login error",
    TT010110: "Enter password",
    TT010120: "Password must be at least 6 characters",
    TT010140: "An error while saving information about the prescription",
    TT010150: "Information saved",
    TT010160: "An error occurred during authorization",
    TT010170: "Enter a password consisting of 6 or more characters",
    TT010180: "Passwords do not match",
    TT010200: "Enter allergen",
    TT010210: "Specify the type of reaction",
    TT010220: "Allergy information successfully saved",
    TT010230: "An error while saving allergy information",
    TT010240: "Allergy info successfully deleted",
    TT010250: "An error while deleting allergy information",
    TT010270: "At least one type of reaction must be specified",
    TT010290: "Error while saving disease",
    TT010300: "Choose an oncological disease",
    TT010320: "Choose an oncological disease",
    TT010330: "Error while saving disease",
    TT010340: "The field \"Diagnosed\" is empty",
    TT010350: "Confirmation status not specified",
    TT010360: "The disease nature is not specified",
    TT010370: "Diagnosis date is not specified",
    TT010380: "The diagnosed disease doctor is not specified",
    TT010390: "ICD-10 code not specified",
    TT010400: "Enter a description of the nosological form",
    TT010410: "A disease with this ICD-10 code has already been added",
    TT010420: "Validation error",
    TT010440: "It is impossible to specify two neoplasms marked with the Main tumor",
    TT010460: "Added a new disease complication",
    TT010470: "An error occurred while adding a complication",
    TT010480: "The complication of the disease has been successfully removed",
    TT010490: "Error while removing a complication of the disease",
    TT010510: "Error removing disease",
    TT010530: "Current user role not found",
    TT010540: "Enter a valid e-mail address",
    TT010560: "Document has been saved",
    TT010570: "Error while saving document",
    TT010590: "Specify the drug",
    TT010600: "Specify the dosing schedule",
    TT010610: "Information about the drug being taken has been successfully saved",
    TT010620: "An error while saving information about the medication being taken",
    TT010630: "Information about the medication being taken has been successfully deleted",
    TT010640: "An error while saving information about the drug being taken",
    TT010700: "Error while saving the organization",
    TT010720: "Patient for a Patient App will be created",
    TT010730: "Mobile App user not found, he will be created automatically",
    TT010750: "New patient saved",
    TT010760: "An error while saving the patient",
    TT010780: "Information saved",
    TT010790: "An error  while saving patient information",
    TT010810: "Choose a diagnosis",
    TT010830: "The \"Disabled child\" category is being established up to 18 years only",
    TT010840: "An error while sending the SMS code",
    TT010850: "First of all, ask for a code by SMS",
    TT010860: "Fill in the code from SMS",
    TT010870: "An error while validating the verification code",
    TT010880: "An error while linking the patient",
    TT010890: "An error while synchronization  patient data",
    TT010900: "The \"Disabled child\" category is being established up to 18 years only",
    TT010920: "An error has occurred while loading",
    TT010940: "Patient removed",
    TT010950: "Error when deleting the paitient",
    TT010970: "This username already exists.",
    TT010980: "An error while saving the patient",
    TT011000: "An error while saving",
    TT011020: "It is impossible to delete the disease, for it is the basis for the Tumor Board case",
    TT011030: "It is impossible to remove the disease, because there are treatment plans for it",
    TT011040: "Unable to remove disease",
    TT011060: "You must fill in empty fields",
    TT011070: "Record is added",
    TT011080: "You must fill in empty fields",
    TT011090: "Record has been saved",
    TT011110: "It is impossible to delete the disease, for it is the basis for the Tumor Board case",
    TT011120: "It is impossible to remove the disease, because there are treatment plans for it",
    TT011130: "Unable to remove disease",
    TT011150: "The patient has no diseases",
    TT011170: "Not enough authorization",
    TT011190: "Select substitution start date",
    TT011200: "Select substitution end date",
    TT011210: "Select a substitute employee",
    TT011260: "You cannot delete an organization, because it has roles that are referenced in the case",
    TT011270: "Role cannot be deleted because there are references to it in cases ({count})",
    TT011280: "Role cannot be deleted because there are references to it in Tumor Boards ({count})",
    TT011290: "Phone number must be 11 digits long",
    TT011300: "Check the correctness of filling out substitute employees",
    TT011310: "Employee email verification error Please try again",
    TT011330: "Substitute employee removed",
    TT011340: "Error removing",
    TT011350: "Substitute employee removed",
    TT011370: "Role cannot be deleted",
    TT011390: "Done!",
    TT011400: "Data copied to clipboard",
    TT011420: "Select templates you want to export",
    TT011430: "Import completed",
    TT011440: "Import failed",
    TT011460: "Done!",
    TT011470: "Deleted",
    TT011480: "Something went wrong, see console for details",
    TT011500: "Done!",
    TT011510: "Fail",
    TT011530: "OK",
    TT011540: "fail",
    TT011560: "Treatment plans successfully deleted",
    TT011570: "Error deleting treatment plans",
    TT011580: "Templates removed successfully",
    TT011590: "Error deleting templates",
    TT011610: "Wait for the conversion to complete",
    TT011630: "Specify the Tumor Board",
    TT011640: "Specify the Tumor Board Purpose",
    TT011660: "Could not locate Mobile App user",
    TT011670: "Select a survey plan item",
    TT011690: "Error while receiving data from the service, please try later",
    TT011710: "The file type you selected {fileName} is not supported",
    TT011720: "The file type you selected {fileName} is not supported",
    TT011730: "Error while saving attachments",
    TT011740: "The document name must be filled in",
    TT011750: "Patient link not found!",
    TT011760: "Error while saving document",
    TT011780: "Invalid break time",
    TT011790: "Break time is not entered",
    TT011810: "Unable to set the medication period {value} {display}",
    TT011830: "To edit the Treatment Cycle, you need to save the Treatment Template",
    TT011850: "A file {fileName} already exists and cannot be added",
    TT011860: "Error while saving attachments",
    TT011880: "Enter the name of the treatment cycle",
    TT011890: "Enter the treatment cycle duration",
    TT011900: "Enter the number of treatment cycles",
    TT011910: "All updates was saved",
    TT011930: "The number of the treatment cycles cannot be less than 1",
    TT011940: "The duration of the treatment cycle cannot be less than 1 day",
    TT011950: "To add a drug, indicate the duration of the treatment cycle",
    TT011960: "To remove a drug, you must remove all links with it",
    TT011970: "You cannot link drugs prescribed for different days",
    TT011980: "You cannot link a previously linked drug First, remove the existing link",
    TT011990: "More than three drugs cannot be linked",
    TT012000: "You cannot move drugs from different days",
    TT012020: "To add diseases, you must select the type of drug therapy",
    TT012030: "At least one type of drug therapy must be selected",
    TT012050: "Treatment template recovered from archive",
    TT012060: "An error occurred while recovering the treatment template from the archive",
    TT012070: "Treatment template is moved to archive",
    TT012080: "An error occurred while archiving the treatment template",
    TT012090: "Treatment template deleted",
    TT012100: "Error while deleting a treatment template",
    TT012110: "Insufficient rights to perform an action",
    TT012130: "Specify the reason for archiving",
    TT012230: "The current user has no roles",
    TT012250: "Choose a treatment template",
    TT012260: "The current user has no roles",
    TT012270: "Record added",
    TT012290: "Select the type of search templates",
    TT012310: "Specify a positive value of serum creatinine level",
    TT012330: "Unable to set the medication period {value} {display}",
    TT012350: "Data update error",
    TT012370: "An error while saving information about the prescription",
    TT012380: "Save drug error",
    TT012390: "Error while loading prescription",
    TT012400: "Error while loading the terminology glossaries",
    TT012410: "Error while loading prescription",
    TT012420: "Error while loading the terminology glossaries",
    TT012430: "Indicate the reason when adjusting the dose",
    TT012440: "Specify the medication period",
    TT012450: "To calculate the dosage of the drug, you must enter the value of the serum creatinine level",
    TT012470: "To add a drug, you must enter data in the Height and Weight fields Save the values",
    TT012480: "Break change error",
    TT012500: "Error loading reasons for termination of the medication",
    TT012510: "The previous cycle #{cycleNumber} {cycleName} ends on {dateEnd}. Select the start date of treatment starting from {dateStart}",
    TT012520: "Enter the treatment cycle duration",
    TT012530: "The duration of the cycle cannot be less than one day",
    TT012540: "The cycle duration cannot be changed Cancel the prescribed drugs for the days you want to take away",
    TT012550: "No resources of type Observation were found in this treatment cycle Changing the cycle status and patient parameters is not possible",
    TT012570: "An error while saving the cycle totals",
    TT012580: "Error while saving into the service",
    TT012590: "Error converting data in fhir",
    TT012600: "Error while reading the cycle results: when accessing the service",
    TT012610: "Error while reading the cycle results: not enough parameters",
    TT012630: "Saved",
    TT012640: "Saving error",
    TT012650: "At the moment, there is already a Treatment cycle in the \"In progress\" status Complete it before continuing with this Treatment Cycle",
    TT012660: "At the moment, there is already a Treatment cycle in the \"Paused\" status Complete it before continuing with this Treatment Cycle",
    TT012670: "To switch the cycle to the \"Assigned\" status, please fill in the \"Start Date\" field",
    TT012680: "The cycle duration cannot be changed Cancel the prescribed drugs for the days you want to take away",
    TT012690: "The previous cycle #{cycleNumber} {cycleName} ends on {dateEnd}. Select the start date of treatment starting from {dateStart}",
    TT012700: "To switch the cycle to the \"Assigned\" status, fill in the \"Cycle duration (d.)\" field",
    TT012710: "The duration of the cycle cannot be less than one day",
    TT012720: "To switch the cycle to the \"Assigned\" status, please fill in the \"Weight\" field",
    TT012730: "To switch the cycle to the \"Assigned\" status, please fill in the \"Weight\" field",
    TT012740: "To transfer the cycle to the \"Assigned\" status, you need to create medical prescriptions",
    TT012750: "To switchthe cycle to the \"Appointed\" status, it is necessary to confirm all prescriptions",
    TT012760: "Treatment cycle successfully deleted",
    TT012770: "Error while deleting treatment cycle",
    TT012780: "Error during updates saving",
    TT012790: "Prescriptions could not be transferred to the Mobile App because the patient account was not found",
    TT012800: "Error during updates saving",
    TT012810: "Error during updates saving",
    TT012820: "To complete the Treatment cycle, it is necessary to indicate the reason for stopping/not injected. Please do it on the \"Treatment status\" tab for each drug prescription in the \"Stopped\" and/or \"Not entered\" status.",
    TT012830: "To complete the Treatment cycle, it is necessary to indicate a comment on another reason for stopping/not introducing. Please do it on the \"Treatment status\" tab for each drug prescription in the \"Stopped\" and/or \"Not entered\" status.",
    TT012840: "Error generating start tasks",
    TT012850: "Error in generating a questionnaire",
    TT012860: "Error activating treatment plan",
    TT012870: "Error canceling tasks in Mobile App",
    TT012890: "Failed to get creatinine clearance",
    TT012900: "Patient BSA not calculated, calculators may not be available, please try again later",
    TT012920: "The drug cannot be removed because the treatment status is specified",
    TT012930: "Drug removal error",
    TT012940: "Add drug error",
    TT012950: "To remove a drug, you must remove all links with it",
    TT012960: "You cannot move drugs from different days",
    TT012970: "Result saved",
    TT012980: "Error while saving result",
    TT012990: "Break changed successfully",
    TT013000: "Break change error",
    TT013010: "You cannot link drugs prescribed for different days",
    TT013020: "You cannot link a previously linked drug First, remove the existing link",
    TT013030: "More than three drugs cannot be linked",
    TT013040: "A link successfully established",
    TT013050: "Linking error",
    TT013060: "Link removed",
    TT013070: "Link deletion error",
    TT013090: "Fill in the end date of medication",
    TT013100: "Fill in the date of medication",
    TT013110: "Fill in a comment on the reason for stopping/not injection the drug",
    TT013130: "Invalid break time",
    TT013140: "Break time is not entered",
    TT013160: "To add a drug, you must enter data in the Height and Weight fields. Save the values.",
    TT013170: "To add a drug, you must enter data in the Height and Weight fields. Save the values.",
    TT013220: "Fill in the start date of medication",
    TT013230: "Enter Time",
    TT013250: "Select a treatment cycle",
    TT013270: "Select the type of drug therapy",
    TT013280: "To save the template, you must select at least one disease code (ICD-10)",
    TT013290: "Specify the name of the regimen",
    TT013300: "Specify a short description",
    TT013310: "Template saved successully",
    TT013320: "There was an error during saving",
    TT013330: "The file type you selected {fileName} is not supported",
    TT013340: "Error while saving attachments",
    TT013360: "All updates was saved",
    TT013370: "Error while saving changes",
    TT013390: "Unable to delete a treatment plan The plan contains cycles with statuses other than \"Created\"/\"Deleted\"/\"Copied\"",
    TT013410: "Group was not found",
    TT013430: "The current user does not have any roles Creation/editing of the Tumor Bord is not possible",
    TT013440: "Failed to move the Tumor Board for another time",
    TT013450: "An error while saving the Tumor Board",
    TT013510: "Failed to send an invitation to the member of the copied Tumor Board",
    TT013530: "Fill in the period of event",
    TT013540: "There is no {dayName} in the selected period",
    TT013550: "Choose at least one day of the week",
    TT013570: "There were TBs in the scheme, with assigned cases, which were detached to separate TBs",
    TT013590: "Enter the name",
    TT013600: "Enter a description",
    TT013620: "This disease is already being considered at the Tumor Board",
    TT013630: "Error while deleting the disease being considered",
    TT013670: "Wait for the template information to finish loading",
    TT013680: "Enter the name",
    TT013690: "Diseases from the selected group are already being considered at the Tumor Board",
    TT013710: "Enter a group name",
    TT014040: "Select a place to move the case",
    TT014050: "Case successfully moved",
    TT014070: "An error occurred while changing the status",
    TT014090: "An error occurred while searching for the last valid order",
    TT014100: "Participant team saved successfully",
    TT014110: "An error while saving the team of participants",
    TT014120: "The team of the participants of the Tumor Board from {date} has been successfully canceled",
    TT014130: "An error occurred when canceling the team of the members of the consultation from {date}",
    TT014150: "Only one file can be uploaded",
    TT014190: "No disease selected",
    TT014210: "Disease saved",
    TT014220: "An error occurred while adding patient diseases to the diagnosis section",
    TT014230: "Disease saved",
    TT014240: "An error occurred while adding a new disease to the diagnosis section",
    TT014250: "{subject} removed",
    TT014260: "Error when deleting",
    TT014280: "An error while saving the protocol version",
    TT014290: "An error while saving the protocol version",
    TT014300: "The patient was not found. Creation of a protocol is not possible",
    TT014310: "Fill in the date of the Tumor Board",
    TT014320: "Specify the Tumor Board ChairPerson",
    TT014330: "Specify attending fisician",
    TT014340: "Specify the secretary of the Board",
    TT014350: "Specify a Surgical oncologist",
    TT014360: "Specify a specialist in drug treatment",
    TT014370: "Specify a radiotherapist",
    TT014380: "Fill in documents submitted",
    TT014390: "Fill in anamnesis of disease",
    TT014400: "Fill in the surveys performed",
    TT014410: "Specify the Tumor Board results",
    TT014420: "Fill in the Diagnostic method field",
    TT014430: "Fill in the Event location field",
    TT014440: "Fill in the field \"Where the patient is reffered to\"",
    TT014450: "Fill in the objective status",
    TT014460: "An error while working with the report;\na unique report identification number was not assigned.",
    TT014470: "You must attach a paper copy of the document",
    TT014480: "Error: {message}",
    TT014490: "unknown",
    TT014500: "An error while getting the context",
    TT014510: "The authorized user does not have a valid HIS id",
    TT014520: "Error while saving signed document",
    TT014530: "Document signing error",
    TT014540: "An error occurred while preparing the printable form, please try again later",
    TT014560: "It is currently impossible to change this Try in a few seconds",
    TT014580: "Error while saving document",
    TT014600: "Attachment loading error",
    TT014610: "The document name must be filled in",
    TT014620: "Patient link not found!",
    TT014630: "Error while saving document",
    TT014650: "An error occurred while generating the report",
    TT014670: "This time slot is occupied, choose another one",
    TT014680: "An error while linking the case to the new Tumor Board",
    TT014690: "An error while creating cases",
    TT014700: "An error while saving changes",
    TT014710: "An error while linking the case to the new Tumor Board",
    TT014730: "You cannot add a case for a Tumor Board, since all slots are already occupied",
    TT014740: "You cannot link a case to the current user",
    TT014750: "You cannot add a case to the past Tumor Board",
    TT014760: "No free slots found for the case",
    TT014770: "Can't take a slot for a case",
    TT014880: "The case is not considered at any of the Tumor Boards Select a Tumor Board to continue working with the case",
    TT014890: "Composition resource for general medical anamnesis not found You need to re-create the case",
    TT014900: "The Composition resource for the medical anamnesis was not found You need to re-create the case",
    TT014910: "No Composition resource was found for the current state You need to re-create the case",
    TT014920: "CarePlan not found for this case",
    TT014930: "Specify the Tumor Board Purpose",
    TT014940: "Specify the names of the items of the survey plan",
    TT014950: "Error while saving record",
    TT014960: "Case saved",
    TT014970: "Error sending the SMS",
    TT014980: "Case submitted",
    TT014990: "Can't reject the case",
    TT015000: "The comment is required",
    TT015010: "Unable to delete a case, because a protocol has been created for it",
    TT015020: "Error in deleting a record",
    TT015040: "Error adding observation",
    TT015050: "Error when deleting",
    TT015070: "Error when updating CarePlan",
    TT015080: "Error while updating the task",
    TT015090: "Error while accepting the task",
    TT015100: "Error while resubmitting the task",
    TT015120: "Failed to load comments",
    TT015130: "Failed to add comment",
    TT015140: "Error while deleting document",
    TT015330: "Comments have been updated",
    TT015340: "Specify the name of the item",
    TT015350: "Error when adding a document",
    TT015360: "Task documents copied successfully",
    TT015370: "Error while copying documents from the task",
    TT015380: "Error while deleting document",
    TT015400: "Failed to get general anamnesis resource",
    TT015420: "Failed to get anamnesis of the disease of the case for Tumor Board",
    TT015440: "Failed to get patient resource",
    TT015450: "Failed to obtain resources of complications and comorbidities",
    TT015470: "Failed to get current state resource",
    TT015490: "Failed to get patient resource",
    TT015500: "Attempt to get disease resource failed",
    TT015520: "Failed to get current state resource",
    TT015540: "Failed to get patient resource",
    TT015550: "Failed to get resources for current state",
    TT015570: "In the case with id: {serviceRequestId} unknown combination of statuses",
    TT015580: "There is no browser history, you cannot go to the previous page",
    TT015600: "Created calendar event for Mobile App patient based on approved case",
    TT015610: "Calendar event for a Mobile App patient not found",
    TT015620: "Could not find user of Mobile App",
    TT015630: "An error occurred while changing the status of a calendar event for a Mobile App patient",
    TT015640: "An error while creating a calendar event for a Mobile App patient based on an approved case:",
    TT015650: "Case for a Tumor Board not found",
    TT015660: "The Tumor Board being referred to by the case is not found",
    TT015670: "Patient not found",
    TT015680: "Patient of Mobile App not found",
    TT015690: "An error while sending a push notification",
    TT015710: "Authorization error for request {uri}",
    TT015730: "Login failed: {message}",
    TT015740: "A password reset instruction has been sent to the specified e-mail address",
    TT015750: "Password change completed successfully You can log in now",
    TT015770: "In the case with id: {serviceRequestId} unknown combination of statuses",
    TT015800: "The type of multiple primary tumor is not specified",
    TT015810: "Diagnosis status is not specified",
    TT015820: "The method of confirming the diagnosis is not specified",
    TT015830: "Diagnosis date is not specified",
    TT015840: "It is not specified whether the tumor is the primary one",
    TT015850: "The affected side is not specified",
    TT015860: "Specify the histological type",
    TT015870: "Fill in the clinical and/or pathoanatomical stage",
    TT015880: "Not all clinical stage factors are specified",
    TT015890: "No stage specified for the clinical stage",
    TT015900: "Not all factors of the pathoanatomical stage are specified",
    TT015910: "The stage is not specified for the pathological stage",
    TT015920: "Immunohistochemistry results are incompletely filled in{titleWithDate}",
    TT015930: "Molecular genetics results are incompletely filled in{titleWithDate}",
    TT015940: "Localization of distant metastases is not specified",
    TT015960: "Clinical stage cannot be specified here",
    TT015970: "Pathological stage cannot be specified",
    TT015980: "The complication resource already exists",
    TT015990: "The complication link already exists",
    TT016000: "Added disease complication",
    TT016010: "Complication resource not found",
    TT016020: "Complication resource link not found",
    TT016030: "Complication removed successfully",
    TT016050: "An error occurred while adding a clinical stage",
    TT016060: "Classification type not found for Cancer Type group '{code}'",
    TT016070: "No source found for classification type with Cancer Type '{code}'",
    TT016080: " The staging type was not found for the classification type with the Cancer Type group '{code}'",
    TT016100: "An error occurred while adding a pathologic stage",
    TT016110: "Classification type not found for Cancer Type group '{code}'",
    TT016120: "No source found for classification type with Cancer Type '{code}'",
    TT016130: " The staging type was not found for the classification type with the Cancer Type group '{code}'",
    TT016150: "Error loading patient disease information",
    TT016160: "Error loading patient disease information",
    TT016170: "Error loading patient and his diseases information",
    TT016190: "SSN must consist of {length} digits",
    TT016210: "Type of the organisation",
    TT016220: "{label} not selected",
    TT016240: "Specify a organization",
    TT016260: "Identifier # {number} has no type specified",
    TT016270: "The identifier with the type {type} has no value specified",
    TT016290: "Can't delete default organization",
    TT016300: "Organization deleted",
    TT016310: "An error while validating links to an organization in other resources",
    TT016320: "The organization cannot be deleted because there are references to it ({count}) in other resources",
    TT016330: "Error when deleting organisation",
    TT016350: "Login is not specified",
    TT016360: "Need to create a password",
    TT016370: "Patient password must be 6 digits long",
    TT016380: "Date of birth not specified",
    TT016390: "Date of birth has a wrong format",
    TT016400: "Last name not specified",
    TT016410: "Name is not specified",
    TT016420: "Gender not specified",
    TT016430: "No organisation specified",
    TT016440: "The attending physician is not specified",
    TT016450: "Mobility is not specified",
    TT016460: "SSN should be 11 digits",
    TT016480: "The surname must contain Cyrillic characters only",
    TT016490: "The name must contain Cyrillic characters only",
    TT016500: "The name must contain Cyrillic characters only",
    TT016510: "Specify birth date",
    TT016520: "Date of birth has a wrong format",
    TT016530: "Health Insurance Policy number must consist of {length} digits",
    TT016540: "Phone number must be 11 digits long",
    TT016550: "Specify the sending MO",
    TT016570: "An error  while looking for patient dependencies",
    TT016590: "Patient's illness saved",
    TT016600: "Error while saving the patient's disease",
    TT016620: "Error removing disease",
    TT016640: "Specify a district",
    TT016650: "Specify the postcode",
    TT016670: "Please specify a value",
    TT016680: "Phone number must be 11 digits long",
    TT016690: "Enter a valid e-mail address",
    TT016700: "This contact already exists",
    TT016720: "The start date of the substitution is not specified",
    TT016730: "Substitution end date is not specified",
    TT016740: "No substitute employee specified",
    TT016760: "You cannot delete the default employee",
    TT016770: "An error while checking links to an employee in other resources",
    TT016780: "You cannot delete an employee because there are links to him ({count}) in other resources",
    TT016790: "Error when deleting an employee",
    TT016800: "Employee has been deleted",
    TT016820: "A role with this identifier already exists",
    TT016830: "Validation passed",
    TT016850: "Specify a name",
    TT016860: "Name should not exceed 255 characters",
    TT016870: "Please specify identifier",
    TT016880: "The identifier must not exceed 50 characters",
    TT016900: "An error occurred while loading the list of roles",
    TT016920: "An error occurred while loading the list of permissions",
    TT016940: "The original role ({roleId}) was not found. Reload this page",
    TT016950: "There is already a copy of a role with this identifier ({roleId}). Reload this page",
    TT016960: "An error while creating a copy of the role ({roleId})",
    TT016980: "Role ({roleId}) not found",
    TT016990: "An error while searching for the role ({roleId})",
    TT017010: "An error while saving the new role ({roleId}): a role with the same identifier already exists. Reload this page",
    TT017020: "Role (\"{roleId}\") saved successfully",
    TT017030: "An error while saving the role ({roleId})",
    TT017050: "Error while deleting a role: the role resource ({roleId}) was not found Reload this page",
    TT017060: "The role ({roleId}) cannot be deleted because there are users ({count}) with this role in the system Reload this page",
    TT017070: "Error while deleting the role ({roleId}): could not get the number of users in the system",
    TT017080: "Error while deleting the role ({roleId}): the resource was not found Reload this page",
    TT017090: "Error while deleting the role ({roleId})",
    TT017110: "An error occurred when requesting the number of users with a role",
    TT017130: "Format of all disease resources is correct",
    TT017140: "An error occurred while converting disease resources",
    TT017150: "{total} disease resources converted to a new format",
    TT017170: "All resources was successfully converted",
    TT017180: "Converted {length} resources",
    TT017190: "An error occurred while converting the system for disability coding",
    TT017210: "Patient data not specified in this context",
    TT017220: "An error occurred when requesting previously created cases and the identifier of the underlying disease. Creation of a new case is not possible",
    TT017240: "Cycle name cannot be empty",
    TT017260: "Error while loading the terminology glossaries",
    TT017270: "Errors in preparation for saving prescription: ",
    TT017280: "INN not selected",
    TT017290: "Medication route not selected",
    TT017300: "It is necessary to indicate the quantity and measurement units of a drug \"{display}\"",
    TT017310: "Indicate the reason when adjusting the dose",
    TT017320: "It is necessary to indicate the quantity and measurement units of an infusion environment",
    TT017330: "It is necessary to set the duration of the drug intaking",
    TT017340: "The drug intaking period must be specified",
    TT017350: "The days of drug intaking not being indicated",
    TT017430: "Indicate the date and time of the Tumor Bord",
    TT017450: "Specify the organisation - provider of the Tumor Board",
    TT017460: "Specify the detailed address of the event location",
    TT017480: "Specify the event location",
    TT017500: "No diseases are indicated in the '{name}' group",
    TT017520: "This Tumor Board cannot be rescheduled, since the case has already been reviewed at it",
    TT017600: "Specify the protocol date",
    TT017610: "Specify an organization",
    TT017620: "Specify an additional participant",
    TT017640: "File format of {fileName} is not supported",
    TT017650: "File {fileName} uploaded successfully",
    TT017660: "An error occurred while downloading file {fileName}",
    TT017680: "Edit a Tumor board team",
    TT017690: "An error occurred while copying the command",
    TT017700: "This team has already been canceled",
    TT017710: "An error occurred while canceling the command",
    TT017730: "An error occurred while validating the diagnosis",
    TT017740: "No diagnosis resource found",
    TT017750: "You have not specified the underlying disease",
    TT017760: "You have not specified the minimum number of the nested diseases (2)",
    TT017770: "Mark one of the neoplasms as the Primary tumor. Edit nested diseases",
    TT017780: "There should be only one neoplasm marked as a Primary tumor. Edit nested diseases",
    TT017790: "Mandatory fields are not filled in the form for specifying information about the disease ({mkb10Code})",
    TT017810: "Treatment tactics not specified {postfix}",
    TT017820: "for oncology disease {oncology}",

    TT017830: "Provide personal phone number",
    TT017840: "Allergy information successfully saved",
    TT017850: "It is not possible to add a request for a consultation beyond the established norm, because the consultation has already begun",
    TT017860: "A user with this login is already registered in the system",
    TT017890: "Failed to send an E-mail to the specified address. Please try again.",
    TT017900: "Error. Please try again.",
    TT017910: "Enter a password consisting of 6 or more characters.",
    TT017920: "Passwords do not match",
    TT017930: "A password cannot be changed. Please equest a new password.",
    TT017940: "Change password code is out of date. Please request again for a new password.",
    TT017950: "The user who needs to have a password changed, not found",

    "TT013540-01": "Monday",
    "TT013540-02": "Tuesday",
    "TT013540-03": "Wednesday",
    "TT013540-04": "Thursday",
    "TT013540-05": "Friday",
    "TT013540-06": "Saturday",
    "TT013540-07": "Sunday",

    TT018000: "Slot taken",
    TT018010: "No slots available",
    TT018020: "No slots available",
    TT018030: "Enter ICD-10 code or disease name",
    TT018040: "Reset filters",
    TT018050: "Downloading….",
    TT018060: "Moving the case...",
    TT018070: "Error while uploading information",
    TT018080: "No TBs were found. Change your search terms",
    TT018090: "Error while moving the case within the TB",
    TT018100: "The case is moving but corresponding resource of the TB is not found",
    TT018110: "A resource of the slot where the case is being moved to was not found",
    TT018120: "The original slot in the TB is not occupied",
    TT018130: "Resource of a new slot for the case was not found",
    TT018140: "A resource for the case being moved was not found",
    TT018150: "The case does not refer to the slot from which it is moved",
    TT018160: "Error while moving the case to another TB",
    TT018170: "A resource for the space in the original TB was not found",
    TT018180: "A slot in the original TB is not occupied by the case",
    TT018190: "Resource for the original TB not found",
    TT018200: "Resource of the initial TB event location is not found",
    TT018210: "Resource of organization of the initial TB event location was not found",
    TT018220: "Resource of a free TB slot not found",
    TT018230: "Targeted TB slot is already occupied",
    TT018240: "Resource of a targeted TB slot is not found",
    TT018250: "Resource of the targeted TB event location is not found",
    TT018260: "Resource of organization of the targeted TB event location was not found",
    TT018270: "Resource of the case being moved was not found",
    TT018280: "The case does not refer to a slot in the original TB",
    TT018290: "The case is ready for consideration and cannot be moved",
    TT018300: "Can't move a cancelled case",
    TT018310: "Can't move the case that was considered",
    TT018320: "Can't move a case with unknown status",
    TT018330: "Can't move deleted cases",
    TT018340: "Can't move the case with a such status",
    TT018350: "Error while creating a new record number",
    TT018360: "Error while moving the case to another TB",
    TT018370: "Error while composing a new protocol number",
    TT018380: "Choose a slot to move the case",
    TT018390: "Choose another slot to move the case",
    TT018400: "Upload PDF document",
    TT018410: "Can't upload file more than 15 mb",

    TT018420: "No reports were found. Change your search terms.",
    TT018430: "No reports were found for this period",
    TT018440: "Loading a list of documents...",
    TT018450: "(tbd)",
    TT018460: "Treatment Cycle № {CycleNumber} {CycleName} was assigned to patient {PatientFullName} on date: {CycleDate}. It should be taken into processing.",
    TT018470: "Treatment Cycle № {CycleNumber} {CycleName} of patient {PatientFullName} has been taken into processing by employee {EmplFullName}",
    TT018480: "Treatment Cycle № {CycleNumber} {CycleName} with the start date today ({TODAY}) of patient {PatientFullName} has not been changed into \"Active\" status. You need to take it into processing or change the start date of the Treatment Cycle.",
    TT018490: "Patient {PatientFullName} has been specified as taking {DrugName} {DrugStatus} in Treatment Cycle № {CycleNumber} {CycleName}. Reason: {DrugCause} ",
    TT018500: "Patient {PatientFullName} has been specified as taking {DrugName} {DrugStatus} in Treatment Cycle № {CycleNumber} {CycleName}. Reason: {DrugCause}",
    TT018510: "Please specify Treatment Cycle № {CycleNumber} {CycleName} results of patient {PatientFullName}",
    TT018520: "Please specify treatment status for patient {PatientFullName} in the Treatment Cycle № {CycleNumber} {CycleName}",
    TT018530: "(tbd)",
    TT018540: "(tbd)",
    TT018550: "You have been invited to Tumor Board \"{TBName}\" that will be held on {TBDateTime}",
    TT018560: "The patient {PatientFullName}’s case you created has been approved",
    TT018570: "Patient {PatientFullName}'s case for the Tumor Board has been rejected by the secretary. Please review the secretary's comments and submit your case again for reconsideration.",
    TT018580: "Please be reminded that you are invited to the Tumor Board {TBName} tomorrow, which will be held on {TBDateTime}.",
    TT018590: "Warning! The Tumor Board scheduled for {TBDateTime} has been cancelled.",
    TT018600: "Warning! The Tumor Board that was scheduled for {TBDateTime} has been rescheduled to {NewTBDateTime}.",
    TT018610: "Patient {PatientFullName}’s case is in the \"Draft\" status. Fill out the case for presentation at the Tumor Board.",
    TT018620: "Patient {PatientFullName} has submitted new documents to \"Suggested checkups\"",
    TT018630: "Patient {PatientFullName} has made a new record in the Health Diary",
    TT018640: "Patient {PatientFullName}’s case has been submitted for {TBName}, {TBDateTime}",
    TT018650: "The Tumor Board report needs to be completed for patient {PatientFullName}",
    TT018660: "Patient {PatientFullName}’s case report has been published",
    TT018670: "Employee {EmployeeFullName} has left a comment on patient {PatientFullName}’s case.",
    TT018680: "No patients were found. Change your search terms",
    TT018690: "Next page",
    TT018700: "Previous page",
    TT018710: "No information on the patient's allergies and intolerances",

    //confirm messages
    TT018760: "Delete a disease?",
    TT018770: "Delete document {documentDescription}?",
    TT018780: "Delete document\r\n{documentDescription}?",
    TT018790: "Delete the patient\r\n{code}: {name}?",
    TT018800: "Convert items (description and comment) of disease resources to the new format?",
    TT018810: "Convert patient disability system to a new format ({patientDisability})?",
    TT018820: "The treatment plan cycle is not saved. Are you sure to leave the page?",
    TT018830: "The treatment plan template is not saved. Are you sure to leave the page?",
    TT018840: "The {displayName} Tumor Board report has not been completed. Are you sure to leave the page?",
    TT018850: "Delete document\r\n{documentDescription}}?",

    //Переводы 24.11.21
    TT021890: "To move the cycle to \"Assigned\" status, you must select a prescription from all major groups",
    TT021900: "You cannot delete a role because it is referenced in other resources",
    TT021910: "An error when deleting the role",
    TT022010: "The value of the \"To\" field cannot be less than the value of the \"From\" field of the \"{display}\" drug",
    TT022190: "To move a cycle to \"Assigned\" status, you must select a drug regimen from all major groups",
    TT022200: "A start date cannot be earlier than the birth date",
    TT022270: "A TB has already been created for the selected date. Try to reassign the case",

    //Перевод dv кодов 29.11.21
    SS9035941: "Cannot specify a cycle length longer than 366 days",

    //Переводы 1.12.21
    TT022290: "The report date cannot be later than the current date",

    //Переводы 7.12.21
    TT022300: "An error when loading of patients ...",
    TT022310: "An error when loading cancer diseases of patients ...",
    TT022320: "An error when loading organizations...",
    TT022330: "An error when loading employees...",
    TT022340: "An error when moving the case...",
    TT022350: "An error when loading TB information...",
    TT022360: "An error when loading the current date cases...",
    TT022370: "An error while loading reports...",
    TT022380: "An error while validating the user’s login...",
    TT022390: "A treatment plan not found",
    TT022400: "An error when retrieving patient information. Error code: {error}",
    TT022410: "A treatment template resource not found",
    TT022420: "An error when deleting a template...",
    TT022430: "The treatment template has been deleted already",
    TT022440: "An error while restoring the template from the archive...",
    TT022450: "An error when archiving the treatment template...",
    TT022460: "Changing a custom template…",
    TT022470: "Report for the Ministry of Health",
    TT022480: "A register of the conducted Tumor Boards",
    TT022490: "No attachments were found",
    TT022500: "Generate a printed form",

    //Переводы 08.12.21
    TT022510: "Unable to create a printed copy for protocols that are not in \"Signed\" status",
    TT022520: "Unable to make changes in a cycle day that has passed",
    TT022530: "Unable to make changes in a cycle day that already obtained a treatment status",

    //Переводы 11.01.22
    TT022610: "Select Patient Treatment Location",
    TT022620: "You cannot remove the medication because {value} day consists of taking it at the same time",
    TT022630: "You cannot cancel {value} day because the medication is concurrently taken",
    TT022640: "You cannot remove the group because the {value} day consists of taking it at the same time",
    TT022650: "Cannot cancel {value} day because the group is concurrently taking",
    TT022660: "Cannot reduce cycle length because there are appointments for {day}",
    TT022670: "Enter cycle length",
    TT022680: "Cannot reduce cycle length as there are appointments on {day} day",
    TT022690: "Enter cycle length",
    TT022700: "Cannot change the days of the prescription as it has simultaneous appointments on {day} day",
    TT022710: "At this point, there is already a Cycle of Treatment in Assigned status. Complete it before continuing with this Treatment Cycle.",
    TT022720: "Report does not refer to any of the cases",
    TT022730: "Select",
    TT022740: "Specify",
    TT022750: "organization",
    TT022760: "position",
    TT022770: "employee",
    TT022780: "specialty",
    TT022790: "An error while uploading the report",
    TT022800: "Patient information not found",
    TT022810: "Error while searching for organizations",
    TT022820: "Report was not found",
    TT022830: "No reference to the actual participants",
    TT022840: "There was an error when searching for the participants of the Tumor Board",
    TT022850: "No reference to the organization in the report was found",
    TT022860: "Organization not found",
    TT022870: "Case form was not found",
    TT022880: "No reference to a document",
    TT022890: "Document not found",
    TT022900: "Document has been successfully signed",
    TT022910: "Authorized user does not have an MRS ID",
    TT022920: "Error on document undersigning",
    TT022930: "Unknown error",
    TT022940: "Transmitting the document data",
    TT022950: "Receiving context",
    TT022960: "Electronic signature agent was closed",
    TT022970: "An error while changing the report status",
    TT022980: "An error while assigning a number to the report",
    TT022990: "validation is not required",
    TT023000: "treatment tactics validation passed",
    TT023010: "Report number was assigned",
    TT023020: "An electronic copy of the document has been attached",
    TT023030: "Please attach an electronic copy of a document",
    TT023040: "Date of the TB is indicated",
    TT023050: "Type of TB is indicated",
    TT023060: "The purpose of the TB is indicated",
    TT023070: "All attendees were indicated",
    TT023080: "{participant} is not specified",
    TT023090: "One of the participants",
    TT023100: "Section with medical history filled in",
    TT023110: "Section with general condition completed",
    TT023120: "Section with submitted medical documents filled out",
    TT023130: "Fill out medical documents submitted",
    TT023140: "TB result indicated",
    TT023150: "Diagnostic methods validation passed",
    TT023160: "Diagnostic Method #{number} field must be populated",
    TT023170: "Location #{number} needs to be populated",
    TT023180: "Referral location field complete",
    TT023190: "Specify where the patient was referred to",
    TT023200: "Section with special opinions of the TB participants is checked",
    TT023210: "Report refers to more than one case at the same time",
    TT023220: "Case was reviewed on the TB",

    //Переводы 2.02.22
    TT023230: "Specify a unique name for the regimen",
    TT023240: "No reports for this period were found",

    TT023530: "Enter values ​​in the field “Height” and “Weight”",
    TT023540: "Enter values ​​in the field “Height”",
    TT023550: "Enter values ​​in the field “Weight”",
    TT023560: "To add a drug, you must enter data in the Height and Weight fields. Save the values.",
    TT023700: "Participant with an electronic signature has already been selected. Turn off the current participant's electronic signature to assign a new one",
    TT023710: "The record is successfully saved",
    TT023720: "Cancer Type group is not specified",
    TT023730: "The date of one of the morphological tests is not specified",
    TT023740: "The organization of one of the morphological tests is not specified",
    TT023750: "The type of one of the morphological tests is not specified",
    TT023760: "The result of one of the morphological tests is not specified",
    TT023770:	"The number of one of the molecular genetic tests is not specified",
    TT023780:	"The date of one of the molecular genetic tests is not specified",
    TT023790:	"The organization of one of the molecular genetic tests is not specified",
    TT023800:	"The indexes of one of the molecular genetic tests are not specified",
    TT023810:	"The index of one of the molecular genetic tests is not specified",
    TT023820:	"The result of one of the molecular genetic tests is not specified",
    TT023830:	"The number of one of the immunohistochemical tests is not specified",
    TT023840:	"The date of one of the immunohistochemical tests is not specified",
    TT023850:	"The organization of one of the immunohistochemical tests is not specified",
    TT023860:	"The indexes of one of the immunohistochemical tests are not specified",
    TT023870:	"The index of one of the immunohistochemical tests is not specified",
    TT023880:	"The result of one of the immunohistochemical tests is not specified",
    TT023890:	"An error while saving disease",
    TT023900:	"Information about the disease is saved",
    TT023910:	"The record is added in the history of treatment of the underlying disease",
    TT023920:	"The record from the history of treatment of the underlying disease is updated",
    TT023930:	"The record from the history of treatment of the underlying disease is deleted",
    TT023940: "An error occurred while saving the case",
    TT023950: "An error occurred while changing the patient case status",
    TT023960: "Signing is completed",
    TT023970: "Signing is canceled",
    TT023980: "An error occurred while searching for a patient's known diseases",
    TT024000: "An error occurred while adding",
    TT024020: "Document added successfully",
    TT024070: "To add a drug, you must enter data in the Height and Weight fields. Save the values.",
    TT024080: "Saving to a Treatment Template",
    TT024090: "Fill in the start date of the treatment cycle",
    TT024100: "An error occurred while uploading patient documents",
    TT024110: "Case resource is not passed",
    TT024040: "After making changes, you need to reconfigure the administration schedule for medication orders on the 'Prescription List' tab",
    TT024140: "Attachment processing error",
    TT024150: "Processing is canceled",

    __FCSPOK_157_1: "При загрузке расширенных данных пациента произошла ошибка. Попробуйте позднее.",
}
