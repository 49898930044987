/**
 * стор версии 2
 * для непосредственного использования в компоненте, иммутабельный
 */
import { computed, autorun, observable, action, toJS } from 'mobx';

import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';
import { basicRequestPost } from 'MODEL_STORE/DataSource/Requests/basicRequestPost';
import { basicRequestDelete } from 'MODEL_STORE/DataSource/Requests/basicRequestDelete';
import { basicRequestPut } from 'MODEL_STORE/DataSource/Requests/basicRequestPut';
import { basicRequestPatch } from 'MODEL_STORE/DataSource/Requests/basicRequestPatch';

export class _BasicStore {

    /**
     *
     */
    resourceUri = 'Something';

    /**
     * индикатор состояния
     */
    @observable
    loading = false;

    /**
     * массив с загруженными элементами
     */
    @observable
    data = [];

    /**
     * фильтр для загрузки
     */
    @observable
    _filter = '';

    /**
     * сколько грузить
     */
    @observable
    _limit = 100;

    /**
     * сортировка
     */
    @observable
    _order = null;

    /**
     * Количество всех записей
     */
    @observable
    total = null;

    /**
     * Количество страниц
     */
    @observable
    countPage = 1;

    /**
     *
     */
    constructor() {

    }

    /**
     *
     */
    @action
    setLoadingState = (newValue) => {
        this.loading = !! newValue;
    }

    /**
     * функция создания нового элемента. должна быть реализована в наследнике
     */
    createElement(data) {
        throw 'One must define createElement function for descendant class';
    }

    /**
     *
     */
    @action
    setData = (newData) => {
        this.data = newData.map( elem => this.createElement(elem) );
    };

    setTotal = (count) => {
        this.total = count;
    };

    get total() {
        return this.total;
    }

    setCountPage = (count) => {
        this.countPage = count;
    };

    get countPage() {
        return this.countPage;
    }

    /**
     *
     */
    @computed
    get limit() {

        // TODO доделать функционал
        return this._limit;
    }

    /**
     *
     */
    set limit(newValue) {
        this._limit = newValue;
    }

    /**
     *
     */
    @computed
    get order() {
        return this._order;
    }

    /**
     *
     */
    set order(newValue) {
        this.setOrder(newValue);
    }

    /**
     *
     */
    setOrder(newValue) {
        this._order = newValue;
        return this;
    }

    /**
     *
     */
    @computed
    get count() {
        return this.data.length;
    }

    /**
     *
     */
    load() {
        return this.search();
    }

    /**
     *
     */
    find = (id) => {

        if (this.data.length === 0) {
            return null;
        }

        const elem = this.data.find( elem => elem.id === id );

        if ( ! elem) {
            return null;
        }

        return elem;
    };

    /**
     *
     */
    search = (params, count = 999) => {

        // да, именно так, а не дефолтный параметр, ибо могут и null передать
        params = params || {};

        this.setLoadingState(true);

        let requestUrl = `${this.resourceUri}/_search`;

        if (this._order) {
            params._sort= this._order;
        }

        params._count = count;

        return basicRequestPost(requestUrl, params)
            .then(response => {
                this.setData(response.data.entry);
                return this.data;
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            });
    };


    searchWithPage = (params, count = 100, ) => {

        // да, именно так, а не дефолтный параметр, ибо могут и null передать
        params = params || {};

        this.setLoadingState(true);

        let requestUrl = `${this.resourceUri}/_search`;

        if (this._order) {
            params._sort= this._order;
        }

        params._count = count;

        return basicRequestPost(requestUrl, params)
            .then(response => {
                this.setData(response.data.entry);
                this.setTotal(response.data.total);
                let lastPageUrl = response.data.link.find(i => i.relation === "last");
                if (lastPageUrl) {
                    lastPageUrl = lastPageUrl.url.split('=');
                    this.setCountPage(parseInt(lastPageUrl[lastPageUrl.length-1]));
                }
                return this.data;
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            });
    };

    /**
     *
     */
    history = (id, version = -1) => {

        this.setLoadingState(true);

        let requestUrl = `${this.resourceUri}/${id}/_history`;

        if (version !== -1) {
            requestUrl = `${requestUrl}/${version}`;
        }

        if (this._order) {
            requestUrl += '?_sort=' + this._order;
        }

        return basicRequestGet(requestUrl)
            .then(response => {
                this.setData(response.data.entry);
                return this.data;
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            });
    };

    /**
     *
     */
    _ilike = (param) => {

        this.setLoadingState(true);

        const requestUrl = `${this.resourceUri}?_ilike=${param}`;

        return basicRequestGet(requestUrl)
            .then(response => {
                this.setData(response.data.entry);
                return this.data;
            })
            .finally(data => {
                this.setLoadingState(false)
                return data;
            });
    }


    /**
     * специфическая приблуда из-за того, что иногда нам нужно сохранять
     * всё, кроме пароля, тогда мы должны использовать PATCH
     */
    patch(element) {
        const url = `${this.resourceUri}/${element.id}`;
        return basicRequestPatch(url, element._data);
    }

    /**
     *
     */
    save(element) {
        const url = `${this.resourceUri}/${element.id}`;
        return basicRequestPut(url, element._data);
    }

    /**
     *
     */
    drop(element) {
        const deleteUrl = `${this.resourceUri}/${element.id}`;
        return basicRequestDelete(deleteUrl);
    }

    /**
     *
     */
    dropAndRefresh(element) {
        return this.drop(element)
            .then( () => {
                return this.load()
            }
            );
    }
}
