/**
 * https://www.hl7.org/implement/standards/fhir/extensibility.html#Extension
 */

import { Element } from './Element';
import {Identifier} from "./Identifier";



export class Reference extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Reference';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'type',
        ]);

        // необязательные простые поля, копируются как есть
        this._directCopyFields(data, ['resourceType', 'display']);

        if (data.identifier) {
            this._data.identifier = new Identifier(data.identifier)._data;
        }

        if (data.localRef) {
            this._data.localRef = data.localRef;
        }

    }

    /**
     *
     */
    static fromResource(newResource) {

        let data = {};
        if ( ! newResource._className) {
            throw 'This resource cannot be referenced';
        }

        if (newResource._className == 'Reference') {

            data = {
                resourceType : newResource.resourceType,
                id           : newResource.id,
            }
        } else {

            data = {
                resourceType : newResource._className,
                id           : newResource.id,
            }
        }

        return new Reference(data);
    }

    /**
     * проверка того, является ли объект (обычный объект, а не инстанс  FHIR-класса) референсом
     */
    static isReferenceData(obj) {

        if ( ! obj) {
            return false;
        }

        if ( ! obj.id) {
            return false;
        }

        if ( ! obj.resourceType) {
            return false;
        }

        return true;
    }


    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check reference
        // TODO check type
        // TODO check identifier
        // TODO check display
    }

    /**
     * геттер нужно переопределить заново, потому что сеттер тоже переопределяется
     */
    get id() {
        return this._data.id
    }

    /**
     * наверное, единственное место, где ID можно менять,
     * так как у референса оно равно ID объекта, на который он показывает
     */
    set id(newValue) {
        this._data.id = newValue;
    }

    /**
     *
     */
    get resourceType() {
        return this._data.resourceType;
    }

    /**
     *
     */
    set resourceType(newValue) {
        this._data.resourceType = newValue;
    }

    /**
     *
     */
    get display() {
        return this._data.display;
    }

    /**
     *
     */
    set display(newValue) {
        this._data.display = newValue;
    }

    /**
     *
     */
    get localRef() {
        return this._data.localRef;
    }

    /**
     *
     */
    set localRef(newValue) {
        this._data.localRef = newValue;
    }

    /**
     *
     */
    get identifier() {
        return this._data.identifier;
    }

    /**
     *
     */
    set identifier(newResource) {
        this._data.identifier = newResource;
    }


}
