import { AbstractIcd10ConceptValueSet } from "./AbstractIcd10ConceptValueSet";

/**
 * справочник кодов МКБ-10 (всех)
 */
export class ConditionMkb10CodesValueSet extends AbstractIcd10ConceptValueSet {

    /**
     * инициализация
     */
    constructor() {
        super({}); // ищем все подряд
    }

}
