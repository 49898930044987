/**
 * https://www.hl7.org/implement/standards/fhir/resource.html
 */

import { LANGUAGES } from '../CodeSystem/Languages';
import { _BaseClass } from '../Elements/_BaseClass';
import { Meta } from '../Elements/Meta';

export class Resource extends _BaseClass {

    /**
     *
     */
    _setClassName() {
        this._className = 'Resource';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'implicitRules',
        ]);

        if (data.hasOwnProperty('meta')) {
            this._data.meta = new Meta(data.meta)._data;
        }

        if (data.hasOwnProperty('language')) {
            this.language = data.language;
        }

         // JSON Representation of Resources
         if (data.hasOwnProperty('resourceType')) {
            this._data.resourceType = data.resourceType;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        if (this._data.hasOwnProperty('language')) {
            if ( ! this._validateFieldCode(this._data.language, LANGUAGES)) {
                throw 'Resource.language has bad value';
            }
        }

        // TODO check ID
        // TODO check meta
        // TODO check implicitRules

    }


    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get id() {
        return this._data.id;
    }

    /**
     *
     */
    set id(newValue) {
        throw 'ID cannot be changed';
    }

    /**
     *
     */
    get uri() {
        console.log('%cResource.uri getter is called', 'background-color: red; color: white;');
        return this._data.uri;
    }

    /**
     *
     */
    set uri(newValue) {
        console.log('%cResource.uri setter is called', 'background-color: red; color: white;');
        this._data.uri = newValue;
    }

    /**
     *
     */
    get meta() {
        return this._data.meta;
    }

    /**
     *
     */
    set meta(newValue) {
        throw 'Meta should not be changed';
    }


    /**
     *
     */
    get language() {
        return this._data.language;
    }

    /**
     *
     */
    set language(newValue) {

        this._data.language = newValue;
    }


}
