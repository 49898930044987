import _get from "lodash/get";
import { observable } from "mobx";
import _orderBy from "lodash/orderBy";

/**
 * электронная почта пациента
 */
export class PatientEmail {

    /**
     * ссылка на ресурс пациента
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на контакт
     */
    get contactPoint() {
        return _get(this.resource, "telecom", [])
        .find(contactPoint => contactPoint.system == "email");
    }

    /**
     * номер телефона
     */
    get text() {
        return _get(this.contactPoint, "value", "-");
    }

}
