import _get from "lodash/get";
import { observable } from "mobx";

/**
 * имя пациента
 */
export class PatientName {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на Resource.name[0]
     */
    get firstHumanName() {
        if ( this.resource && Array.isArray(this.resource.name) ) {
            if ( this.resource.name.length ) {
                return this.resource.name[0];
            }
        }
    }

    /**
     * полное имя
     */
    get fullName() {
        const parts = []
        const { firstHumanName } = this;
        if ( firstHumanName ) {
            if ( firstHumanName.family ) {
                parts.push(firstHumanName.family); // фамилия
            }
            if ( Array.isArray(firstHumanName.given) ) {
                if ( firstHumanName.given[0] ) {
                    parts.push(firstHumanName.given[0]); // имя
                }
                if ( firstHumanName.given[1] ) {
                    parts.push(firstHumanName.given[1]); // отчество
                }
            }
        }
        return parts.join(" ");
    }

    /**
     * короткое имя
     */
    get shortName() {
        const parts = []
        const { firstHumanName } = this;
        if ( firstHumanName ) {
            if ( firstHumanName.family ) {
                parts.push(firstHumanName.family); // фамилия
            }
            if ( Array.isArray(firstHumanName.given) ) {
                if ( firstHumanName.given[0] ) {
                    parts.push(firstHumanName.given[0][0].toUpperCase() + "."); // имя
                }
                if ( firstHumanName.given[1] ) {
                    parts.push(firstHumanName.given[1][0].toUpperCase() + "."); // отчество
                }
            }
        }
        return parts.join(" ");
    }


}
