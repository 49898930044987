import _get from "lodash/get";

/**
 * группа TNM
 */
export class ConditionTnmGroup {

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/ds-group";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.conditionResource, "code.coding", []).find(coding => {
            return _get(coding, "system") == ConditionTnmGroup.CODING_SYSTEM;
        });
    }

    /**
     * кодирование
     */
    get codingIndex() {
        return _get(this.conditionResource, "code.coding", []).findIndex(coding => {
            return _get(coding, "system") == ConditionTnmGroup.CODING_SYSTEM;
        });
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * клиническая или патологоанатомическая стадия
     * не заполняется при выборе в п.6.1 кодов:
     * C33, C47, C70, C71, C72, C81-C97
     * TNM GROUP 6002 - Нестадируемые по TNM
     */
    get canHaveClassificationStage() {
        return !! (this.code && this.code != "6002");
    }

    /**
     * изменение группы TNM
     */
    onChange = ({concept}) => {
        if( ! concept ) {
            return this.reset();
        }
        if(concept.code != this.code) {
            return this.create(concept);
        }
    }

    /**
     * создание/изменение группы TNM
     */
    create = (concept) => {
        const coding = {
            code: concept.code,
            system: ConditionTnmGroup.CODING_SYSTEM,
            display: concept.display
        }
        if( ! Array.isArray(_get(this.conditionResource, "code.coding")) ) {
            return console.log("Нельзя изменить группу TNM");
        }
        const { codingIndex } = this;
        if( codingIndex >= 0 ) {
            this.conditionResource.code.coding[codingIndex] = coding;
        } else {
            this.conditionResource.code.coding.push(coding);
        }
    }

    /**
     * сброс группы TNM
     */
    reset = () => {
        const { codingIndex } = this;
        if ( codingIndex >= 0 ) {
            this.conditionResource.code.coding.splice(codingIndex, 1);
        }
    }

}
