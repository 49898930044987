import urlJoin from "url-join";
import { config } from 'config';
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";
import { AuthStoreData } from "MODEL_STORE/DataSource/Stores/AuthStoreDataClass";

export const makeUrl = (relativeUrl) => {
    return urlJoin(config.AIDBOX_BASE_URL, relativeUrl);
}

export const makeConfig = (requestConfig) => {
    return {
        ...requestConfig,
        headers: {
            "Accept-Language": HeaderStore.locale,
            ...AuthStoreData.authHeaders(),
            ...requestConfig.headers,
        },
    }
}
