import axios from "axios";

import { AuthStoreData } from "MODEL_STORE/DataSource/Stores/AuthStoreDataClass";

const axiosMaximus = axios.create()
let nowRefreshing = false;
let queue = [];

axiosMaximus.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // https://jira.mmdx.ru/browse/FCSPOK-737
        if ( ! error.response ) { // request canceled?
            return Promise.reject(error);
        }
        const originalRequest = error.config;
        const responseStatus = error.response.status;

        if (responseStatus != 401) {
            return Promise.reject(error);
        }

        // тут важно правильно соблюсти последовательность выполнения - ответ на рефреш должен обрабатываться
        // после того, как запрос добавится в очередь

        if (!nowRefreshing) {
            nowRefreshing = true;

            const traceparent = originalRequest.headers["traceparent"];

            AuthStoreData.refreshToken({traceparent: traceparent})
                .then(refreshResult => {
                    // if (!refreshResult) {
                    //     return;
                    // }
                    const token = AuthStoreData.loadAccessToken()
                    queue.map((func) => func(token));
                })
                .finally(() => {
                    nowRefreshing = false;
                    queue = [];
                });
        }

        return new Promise((resolve, reject) => {
            queue.push((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(axios(originalRequest));
            });
        });
    }
)

export { axiosMaximus } ;

