export const dv_dictionary = {
    __MIRA_739_001: "Add .pdf file",

    __MIRA_659_001: 'The report is generated only for the protocols with the status "Ready"',
    __MIRA_659_002: 'The report could not be generated. The report is generated only for protocols with the status "Ready"',

    __GLB_609_001: "Новообразование",
    __GLB_609_002: "Заболевание",

    __GLB_617_001: "Changes saved",

    __GLB_645_001: "Coverage Document",

    __GLB_690_1: 'Группа',
    __GLB_690_2: 'Дополнительные препараты на выбор',
    __GLB_703_1: "Organization",
    __GLB_703_2: "Organization branch",

    __GLB_594_1: "Add to printable version",
    __GLB_594_2: "No clinical trials available",
    __GLB_711_1: "The drug was successfully selected",
    __GLB_725_1: "from {first_value} to {second_value} {unit}",
    __GLB_778_1: "Choose a drug",

    __MIRA_1338_1: "Some resources have changed, please reload the page and make changes again",

    __MIRA_666_001: "Add a document",
    __MIRA_666_002: "Delete document",
    __MIRA_666_003: "Are you sure you want to delete the document?",

    __MIRA_1232_001: "No studies",

    __MIRA_1315_001: "Reset filters",

    __MIRA_1304_001: "Вернуть к рассмотрению",
    __MIRA_1304_002: "Перевод заявки в статус “Готова для рассмотрения“ невозможен, так как протокол находится в статусе “{status_name}“. Пожалуйста, обратитесь в службу поддержки.",
    __MIRA_1304_003: "Вернуть на доработку",
    __MIRA_1304_004: "Перевод заявки в статус “Возвращена на доработку“ невозможен, так как протокол находится в статусе “{status_name}“",
    __MIRA_1304_005: "Вернуть в работу",
    __MIRA_1304_006: "Вы уверены, что хотите вернуть протокол в работу?",
    __MIRA_1304_007: "Вернуть к статусу Готов",
    __MIRA_1304_008: "Вы уверены, что хотите вернуть протокол к статусу Готов?",

    __MIRA_1418_001: "Редактировать текущее состояние пациента",

    __MIRA_1422_001: "История состояния пациента",
    __MIRA_1422_002: "Дата",
    __MIRA_1422_003: "Рост (см)",
    __MIRA_1422_004: "Вес (кг)",
    __MIRA_1422_005: "ППТ (м2)",
    __MIRA_1422_006: "Ур. сыв. креат. (мкмоль/л)",
    __MIRA_1422_007: "Клиренс креатинина (мл/мин)",
    __MIRA_1422_008: "Пользователь",
    __MIRA_1635_001: "Select not more than {count} candidates",

    __MIRA_1866_001: "Нет доступных значений",
    __MIRA_1866_002: "Дополнительные классификаторы и биомаркеры",
    __MIRA_1866_003: "Заполните клиническое описание диагноза",
    __MIRA_1866_004: "Внимание!",
    __MIRA_1866_005: "Загрузка документов",
    __MIRA_1866_006: "СНИЛС идентификатор MIRA-1348",
    __MIRA_1866_007: "Новый датапикер MIRA-547 v3",
    __MIRA_1866_008: "Список загружаемых справочников",
    __MIRA_1866_009: "Выберите шаблоны лечения для экспорта",
    __MIRA_1866_010: "Код схемы",
    __MIRA_1866_011: "Наименование",
    __MIRA_1866_012: "Выберите архив для импорта шаблонов",
    __MIRA_1866_013: "Выберите xlsx для импорта шаблонов",
    __MIRA_1866_014: "Импортировать XLSX",
    __MIRA_1866_015: "Стадия импорта: {stageImportXlsxText}",
    __MIRA_1866_017: "Укажите обоснование для изменения схемы лечения",
    __MIRA_1866_018: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10:",
    __MIRA_1866_019: "Выберите другой Протокол ОК.",
    __MIRA_1866_020: "Измените диагноз МКБ-10 в Протоколе осмотра врача.",
    __MIRA_1866_021: "Выберите \"Протокол ОК из другого медучреждения\".",
    __MIRA_1866_022: "Отмените выбор протокола ОК в поле \"Онкоконсилиум\".",
    __MIRA_1866_023: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом, полученным из Протокола осмотра врача:",
    __MIRA_1866_024: "Добавление плана лечения по диагнозу C97 запрещено, необходимо изменить диагноз МКБ-10 в Протоколе осмотра врача",
    __MIRA_1866_025: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10:",
    __MIRA_1866_026: "Назначен",
    __MIRA_1866_027: "Не удалось загрузить данные плана лечения",
    __MIRA_1866_028: "Если были внесены изменения в Цикл лечения, необходимо повторно распечатать Лист назначений",
    __MIRA_1866_029: "Рассчитанное по формуле Мостеллера",
    __MIRA_1866_030: "Рассчитанное по формуле Кокрофта-Голта",
    __MIRA_1866_031: "Загрузка...",
    __MIRA_1866_032: "Невозможно удалить план лечения. В плане имеются циклы со статусами отличными от \"Создан\"/\"Удален\"/\"Скопирован\"",
    __MIRA_1866_033: "Наименование плана лечения не может быть пустым",
    __MIRA_1866_034: "Вы уверены, что хотите завершить план лечения?",
    __MIRA_1866_035: "Добавлены группы из всех шаблонов",
    __MIRA_1866_036: "При добавлении групп из всех шаблонов произошла ошибка",
    __MIRA_1866_037: "Добавление всех шаблонов",
    __MIRA_1866_038: "При запросе к AidboxQuery 'appointment-info-search' произошла ошибка",
    __MIRA_1866_039: "Формат файла не поддерживается",
    __MIRA_1866_040: "Файл успешно загружен",
    __MIRA_1866_041: "При загрузке файла произошла ошибка",
    __MIRA_1866_042: "Вы действительно хотите удалить {subject} {code}?",
    __MIRA_1866_043: "Коды заболеваний по МКБ 10",
    __MIRA_1866_044: "Нельзя сохранить заявку, так как не указано название одного из пунктов плана обследования",
    __MIRA_1866_045: "Ошибка при удалении заявки",
    __MIRA_1866_046: "Заполните анамнез",
    __MIRA_1866_047: "Объективный статус",
    __MIRA_1866_048: "Просмотр документа",
    __MIRA_1866_049: "Заполните общее состояние",
    __MIRA_1866_050: "Без наименования",
    __MIRA_1866_051: "Вы можете добавить пункт плана обследования, нажав на пиктограмму \"плюсик\" в списке слева.",
    __MIRA_1866_052: "Или добавьте новый план обследования.",
    __MIRA_1866_054: "Выписной эпикриз стационара",
    __MIRA_1866_055: "Протокол операции",
    __MIRA_1866_056: "Гистология",
    __MIRA_1866_057: "Лабораторное исследование",
    __MIRA_1866_058: "Заключение онкоконсилиума",
    __MIRA_1866_059: "КТ исследование",
    __MIRA_1866_060: "МРТ исследование",
    __MIRA_1866_061: "ПЭТ/КТ исследование",
    __MIRA_1866_062: "Выписка из амбулаторной карты",
    __MIRA_1866_063: "Консультация врача специалиста",
    __MIRA_1866_064: "Маммография",
    __MIRA_1866_065: "Ультразвуковое исследование",
    __MIRA_1866_067: "Укажите уникальное наименование Шаблона лечения",
    __MIRA_1866_068: "Загрузка информации",
    __MIRA_1866_069: "Загрузка списка организаций...",
    __MIRA_1866_070: "Новая специализация",
    __MIRA_1866_071: "Выбор специализации",
    __MIRA_1866_072: "Новая специализация",
    __MIRA_1866_073: "Выбор специализации",
    __MIRA_1866_074: "Просмотр документа",
    __MIRA_1866_075: "Редактирование документа",
    __MIRA_1866_076: "Клинические рекомендации",
    __MIRA_1866_077: "Получение списка...",
    __MIRA_1866_078: "Удалить",
    __MIRA_1866_079: "",
    __MIRA_1866_80: "Организация",
    __MIRA_1866_81: "Тип стационара",
    __MIRA_1866_82: "Подождите. Идет создание заявок...",

    __MIRA_2007_01: "Добавить все шаблоны",

    __MIRA_1995_01: "Show all",
    __MIRA_2659_01: "Delete",
    __MIRA_2809_01: "Создание протокола",

    __FCSPOK_31_01: "Укажите причину проведения консилиума",
    __FCSPOK_31_02: "Причина проведения консилиума",
    __FCSPOK_31_03: "Форма консилиума",
    __FCSPOK_31_04: "Укажите форму проведения консилиума",

    __FCSPOK_60_01: "Состояние пациента",
    __FCSPOK_16_01: "Укажите признак \"Уровень проведения\"",
    __FCSPOK_16_02: "Уровень проведения",

    __FCSPOK_83_01: "Контрольный список документов и исследований",
    __FCSPOK_83_02: "Контрольный список документов и исследований",
    __FCSPOK_83_03: "Код или название заболевания",
    __FCSPOK_83_04: "Коды или названия заболеваний",

    __FCSPOK_286: "Не указан морфологический тип опухоли диагноза {mkb10}: {name}",

}
