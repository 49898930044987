import { observable, reaction, computed } from "mobx";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";


export class Data {

    endpointList = [
        {
            "title": "entrypoint",
            "path": "/api/integrations/element/mm/entrypoint",
        },
    ];

    @observable
    basicAuthUser = '';

    @observable
    basicAuthPassword = '';

    @observable
    userId = '';

    @observable
    selectedEndpoint = this.endpointList[0].path;

    @observable
    accessToken = '';

    @observable
    requestData = '';

    @observable
    responseData = 'Hurry up, send request!';

    getResponsePayload = (response) => {

        const textContent = JSON.stringify(response.data, null, 4);

        const html = textContent.replace(/(https?:\/\/[^"]+)/g, '<a target="_blank" href="$1">$1</a>');
        return html;
    }

    getErrorText = (error) => {
        return error.toString() + '\n' + JSON.stringify(error.response.data, null, 4);
    }

    getAccessToken = () => {

        const authUrl = '/api/integrations/element/auth/token';
        const data = {
            "userId": this.userId,
        };
        const axiosConfig = {
            headers: {
                'Authorization' : 'Basic ' + btoa(this.basicAuthUser + ':' + this.basicAuthPassword),
            },
        };

        return basicRequestPost(authUrl, data, axiosConfig)
            .then(response => {
                this.accessToken = response.data.access_token;
                this.responseData = 'OK';
            })
            .catch(error => {
                this.responseData = this.getErrorText(error);
            })
            ;
    }

    sendRequest = () => {

        this.responseData = 'Loading...';
        const axiosConfig = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken,
            },
        };

        return basicRequestPost(this.selectedEndpoint, this.requestData, axiosConfig)
            .then(response => {
                this.responseData = this.getResponsePayload(response);
            })
            .catch(error => {
                this.responseData = this.getErrorText(error);
            });
    }


}
