import _get from "lodash/get";

/**
 * использумая система для расширения
 */
const EXTENSION_SYSTEM = "http://miremedix.ru/fhir/StructureDefinition/appointment-confirmationType";

/**
 * использумая система для услуги
 */
const SERVICE_TYPE_CODEABLE_CONCEPT_SYSTEM = "urn:mmdx:internal:entity";

/**
 * концепт услуги (appointment.serviceType)
 */
const SERVICE_TYPE_CODEABLE_CONCEPT = {
    "coding": [{
        "code": "consilium-event",
        "display": "Консилиум в расписании",
        "system": SERVICE_TYPE_CODEABLE_CONCEPT_SYSTEM
    }]
}

/**
 * заявка согласуется по факту направления (по умолчанию)
 */
const AUTO_APPROVE = {
    CODE: "auto-approve",
    DISPLAY: "Заявка согласуется по факту направления"
}

/**
 * заявка согласуется секретарем
 */
const SECRETARY_APPROVE = {
    CODE: "secretary-approve",
    DISPLAY: "Заявка согласуется секретарем"
}

/**
 * собираем расширение на основе переданных параметров
 */
const MAKE_EXTENSION = (CODING) => {
    return {
        "url": EXTENSION_SYSTEM,
        "valueCoding": {
            "code": CODING.CODE,
            "display": CODING.DISPLAY,
            "system": EXTENSION_SYSTEM,
        }
    }
}

/**
 *  настройка "Требует согласования" для консилиума
 */
export class AppointmentConfirmationType {

    /**
     * ссылка на ресурс консилиума
     */
    appointment = null;

    /**
     * appointment - ресурс консилиума
     */
    constructor(appointment) {
        this.appointment = appointment;
        this.initialize();
    }

    /**
     * инициализация (по умолчанию не требуется одобрение/согласование секретарем)
     */
    initialize = () => {
        if( this.findServiceTypeIndex() === -1 ) {
            this.setServiceTypeConcept();
        }
        if( this.findExtensionIndex() === -1 ) {
            this.setExtension(MAKE_EXTENSION(AUTO_APPROVE));
        }
    }

    /**
     * заявка не требует согласование/одобрение секретарем?
     */
    isAuto = () => {
        return _get(this.appointment, "extension", []).findIndex(extension => {
            return _get(extension, "url") == EXTENSION_SYSTEM
                && _get(extension, "valueCoding.code") == AUTO_APPROVE.CODE
                && _get(extension, "valueCoding.system") == EXTENSION_SYSTEM;
        }) >= 0;
    }

    /**
     * переключение между auto-approve и secretary-approve
     */
    toggle = () => {
        if( this.isAuto() ) {
            this.setExtension(MAKE_EXTENSION(SECRETARY_APPROVE));
        } else {
            this.setExtension(MAKE_EXTENSION(AUTO_APPROVE));
        }
    }

    /**
     * индекс концепта услуги в appointment.serviceType[]
     */
    findServiceTypeIndex = () => {
        return _get(this.appointment, "serviceType", []).findIndex(concept => {
            return _get(concept, "coding", []).find(coding => {
                return _get(coding, "system") == SERVICE_TYPE_CODEABLE_CONCEPT_SYSTEM;
            });
        });
    }

    /**
     * установка концепта услуги
     */
    setServiceTypeConcept = () => {
        if( ! Array.isArray(this.appointment.serviceType) ) {
            this.appointment.serviceType = [];
        }
        const serviceTypeIndex = this.findServiceTypeIndex();
        if( serviceTypeIndex === -1 ) {
            this.appointment.serviceType.push(SERVICE_TYPE_CODEABLE_CONCEPT);
        } else {
            this.appointment.serviceType[serviceTypeIndex] = SERVICE_TYPE_CODEABLE_CONCEPT;
        }
    }

    /**
     * индекс расширения в appointment.extension[]
     */
    findExtensionIndex = () => {
        return _get(this.appointment, "extension", []).findIndex(extension => {
            return _get(extension, "url") == EXTENSION_SYSTEM;
        });
    }

    /**
     * установка расширения
     */
    setExtension = (extension) => {
        if( ! Array.isArray(this.appointment.extension) ) {
            this.appointment.extension = [];
        }
        const extensionIndex = this.findExtensionIndex();
        if( extensionIndex === -1 ) {
            this.appointment.extension.push(extension);
        } else {
            this.appointment.extension[extensionIndex] = extension;
        }
    }

}
