import moment from "moment";
import _get from "lodash/get";
import { observable } from "mobx";
import { getFormat } from "Locale/datetime";

/**
 * информация о времени начала
 */
export class AppointmentSlotStart {

    /**
     * ссылка на ресурс слота
     */
    @observable slotResource = null;

    /**
     * инициализация
     */
    constructor(slotResource) {
        this.slotResource = slotResource;
    }

    /**
     * время в формате
     */
    get time() {
        if ( this.slotResource.start ) {
            return moment(this.slotResource.start).format(getFormat("DT000013"));
        }
    }

    /**
     * дата в формате
     */
    get date() {
        if ( this.slotResource.start ) {
            return moment(this.slotResource.start).format(getFormat("DT000001"));
        }
    }

}