import axios from "axios";
import _get from "lodash/get";
import { observable } from "mobx";
import { UrlSearchParams } from "GUI_MAIN/Utils/UrlSearchParams";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";

/**
 * справочник концептов МКБ-10
 * так как aidbox из коробки
 * не поддерживает сортировку значений в ValueSet
 * пришлось написать кастомный запрос AidboxQuery
 * https://miramedix.atlassian.net/browse/MIRA-300
 */
export class AbstractIcd10ConceptValueSet {

    /**
     * текущий запрос к серверу
     */
    request = null;

    /**
     * путь до AidboxQuery
     */
    endpoint = "/api/mm/icd-10-concepts/$query";

    /**
     * параметры запроса (объект)
     * параметр type по умолчанию "category,subcategory" (на сервере)
     */
    params = {
        type: "", // тип (group, chapter, category, subcategory)
        count: 50, // кол-во результатов
        filter: "", // фильтр по коду и названию
        gender: "", // фильтр по полу пациента
        locale: "", // локаль (ru/en)
        exclude: "", // исключить коды МКБ-10
        orderKey: "", // ключ сортировки
        orderDir: "", // направление сортировки,
        onlyOncology: false, // флаг поиска только онкологических заболеваний
        onlyConcurrent: false, // флаг поиска только конкурирующих заболеваний (в диагнозе)
        onlyComorbidity: false, // флаг поиска только сопутствующих заболеваний (в диагнозе)
        onlyComplication: false, // флаг поиска только осложнений заболевания (в диагнозе)
        onlyOncologyRelated: false, // флаг поиска только вложенных в C97 заболеваний (в диагнозе)
        onlyOncologyMalignant: false, // флаг поиска только злокачественных онкологических заболеваний (в диагнозе)
    }

    /**
     * список загруженных концептов
     */
    @observable concepts = [];

    /**
     * инициализация
     */
    constructor(params = {}) {
        Object.keys(params).forEach(key => {
            if ( this.params.hasOwnProperty(key) ) {
                this.params[key] = params[key];
            }
        });
    }

    /**
     * установка фильтра по полу пациента
     */
    setGender = (gender = "") => {
        this.params.gender = gender;
    }


    /**
     * хелппер для options - проверка вхождения образца в строку
     */
    includes = (value, search) => {
        return value.toUpperCase().indexOf(search.trim().toUpperCase()) >= 0
    }

    /**
     * хелппер для options - добавление образца в массив, если он подходит под условия поиска
     */
    addIncluded = (value, search, store = undefined) => {

        const includes = this.includes(value, search);

        if (includes && Array.isArray(store)) {
            store.push(value);
        }

        return includes;
    }

    /**
     * хелппер для options - генерация текста из основных и, если требуется, дополнительных полей концепта
     */
    extendedOption = (concept, searchedBy) => {

        var label = `${concept.code}: ${concept.display}`;
        const search = searchedBy.toUpperCase();

        // проверяем - если совпадение не в display, приклеиваем сисноним или альтернативное название
        if (! this.includes(concept.code, searchedBy) && ! this.includes(concept.display, searchedBy)) {
            var additionals = [];

            this.addIncluded(_get(concept, 'designation.display.en', ''), searchedBy, additionals);
            this.addIncluded(_get(concept, 'designation.display.ru', ''), searchedBy, additionals);

            _get(concept, 'designation.synonym.en', []).forEach( e => this.addIncluded(e, searchedBy, additionals) );
            _get(concept, 'designation.synonym.ru', []).forEach( e => this.addIncluded(e, searchedBy, additionals) );

            if (additionals.length > 0) {
                label += ' [' + additionals.join(', ') + ']';
            }
        }

        return {
            value: concept.code,
            label: label,
            concept: concept,
        };
    }

    /**
     * список возможных вариантов (React-Select)
     * параметром указываем, что нужно добавить designation.synonym или designation.display,
     * если поиск сработал по дополнительному варианту, а не по основному display
     * это подробно описано в MIRA-306
     */
    get options() {
        try {
            return this.concepts.map(concept => this.extendedOption(concept, this.params.filter));
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * алиас возможных вариантов (для совместимости)
     */
    get finalOptions() {
        return this.options;
    }

    /**
     * параметры запроса (строка)
     */
    get query() {
        const query = {}
        Object.keys(this.params).forEach(key => {
            if (this.params[key]) {
                query[key] = this.params[key];
            }
        })
        return UrlSearchParams(query);
    }

    /**
     * остановка текущего запроса к серверу
     */
    cancel = (reason = "new request") => {
        if ( this.request ) {
            this.request.cancel(reason);
        }
    }

    /**
     * поиск заболеваний
     */
    search = (filter = "", exclude = []) => {
        this.cancel("new request");
        this.params.filter = filter;

        // Нужно убрать c97 из результатов поиска FCSPOK-388
        exclude.push('C97')

        this.params.exclude = exclude.join(",");
        this.params.exclude
        this.request = axios.CancelToken.source();
        basicRequestGet(`${this.endpoint}?${this.query}`, {
            cancelToken: this.request.token
        }).then(this.onLoad, this.onError);
    }

    /**
     * после загрузки
     */
    onLoad = (response) => {
        this.concepts = response.data.data.data;
    }

    /**
     * при ошибке загрузки
     */
    onError = (error) => {
        if ( axios.isCancel(error) ) {
            return console.warn("Request canceled. Reason:", error.message);
        }
        console.error("При поиске концептов МКБ-10 произошла ошибка", error);
    }

    /**
     * временное решение (имеется ли код в наборе)
     */
    hasCode = (code) => {
        if (this.params.onlyOncology) {
            return this.isOncologyCode(code);
        }
        if (this.params.onlyConcurrent) {
            return this.isConcurrentCode(code);
        }
        if (this.params.onlyComorbidity) {
            return this.isComorbidityCode(code);
        }
        if (this.params.onlyComplication) {
            return this.isComplicationCode(code);
        }
        if (this.params.onlyOncologyRelated) {
            return this.isOncologyRelatedCode(code);
        }
        if (this.params.onlyOncologyMalignant) {
            return this.isOncologyMalignantCode(code);
        }
        return false;
    }

    /**
     * это код онкологического заболевания?
     */
    isOncologyCode = (code) => {
        return !! (
            (code >= "C00" && code <= "D48.9")
        );
    }

    /**
     * это код конкурирующего заболевания?
     */
    isConcurrentCode = (code) => {
        return !! (
            (code >= "A00" && code <= "B99.9")
            ||
            (code >= "D50" && code <= "Z99.9")
        );
    }

    /**
     * это код сопутствующего заболевания?
     */
    isComorbidityCode = (code) => {
        return !! (
            (code >= "A00" && code <= "B99.9")
            ||
            (code >= "D50" && code <= "Z99.9")
        );
    }
    /**
     * это код осложнения заболевания?
     */
    isComplicationCode = (code) => {
        return !! (
            (code >= "A00" && code <= "B99.9")
            ||
            (code >= "D50" && code <= "Z99.9")
        );
    }

    /**
     * это код вложенного в C97 заболевания?
     */
    isOncologyRelatedCode = (code) => {
        return !! (
            (code >= "C00" && code <= "C96.9")
            ||
            (code >= "D00" && code <= "D09.9")
        );
    }

    /**
     * это код злокачественного заболевания?
     */
    isOncologyMalignantCode = (code) => {
        return !! (
            (code >= "C00" && code <= "C96.9")
            ||
            (code >= "D00" && code <= "D09.9")
        );
    }
}
