import _get from "lodash/get";

/**
 * класс для управления ссылками на вложенные заболевания
 */
export class ConditionRelatedConditions {

    /**
     * url расширения для вложенных в C97 заболеваний
     */
    static EXTENSION_URL = "http://hl7.org/fhir/StructureDefinition/condition-related";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * список расширений
     */
    get extensions() {
        return _get(this.conditionResource, "extension", []).filter(extension => {
            return _get(extension, "url") == ConditionRelatedConditions.EXTENSION_URL;
        });
    }

    /**
     * список ссылок
     */
    get references() {
        return this.extensions.map(extension => {
            return _get(extension, "valueReference");
        });
    }

    /**
     * список идентификаторов
     * id - для ресурса
     * localRef для ссылки в протоколе
     */
    get identifiers() {
        return this.references.map(reference => reference.id || reference.localRef);
    }

    /**
     * удаление информации о вложенных заболеваниях
     */
    reset = () => {
        if ( Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = this.conditionResource.extension.filter(extension => {
                return _get(extension, "url") != ConditionRelatedConditions.EXTENSION_URL;
            });
        }
    }

    /**
     * есть ли ссылка на заболевание
     */
    hasConditionReference = (identifier) => {
        return this.identifiers.includes(identifier);
    }

    /**
     * добавление расширения со ссылкой на заболевание
     */
    createConditionReference = (identifier, referenceType = "id") => {
        if ( ! Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = [];
        }
        this.conditionResource.extension.push({
            "url": ConditionRelatedConditions.EXTENSION_URL,
            "valueReference": {
                [referenceType]: identifier,
                "resourceType": "Condition"
            }
        });
    }

    /**
     * удаление расширения со ссылкой на заболевание
     */
    removeConditionReference = (identifier, referenceType = "id") => {
        const index = _get(this.conditionResource, "extension", []).findIndex(extension => {
            return (
                _get(extension, "url") == ConditionRelatedConditions.EXTENSION_URL
                &&
                _get(extension, ["valueReference", referenceType]) == identifier
            )
        });
        if ( index >= 0 ) {
            this.conditionResource.extension.splice(index, 1);
        }
    }

    /**
     * делаем ссылки локальными
     */
    makeLocalReferences = () => {
        _get(this.conditionResource, "extension", []).filter(extension => {
            return _get(extension, "url") == ConditionRelatedConditions.EXTENSION_URL;
        }).forEach(extension => {
            if ( extension.valueReference && extension.valueReference.id ) {
                extension.valueReference.localRef = extension.valueReference.id;
                delete(extension.valueReference.id);
            }
        })
    }

}
