import { OID } from 'MODEL_STORE/DataSource/Constants/oid';

/**
 * код подтвержденного заболевания
 */
export const CONFIRMED_CODE = 'confirmed';

// GET /Concept?valueset=condition-clinical
// http://hl7.org/implement/standards/fhir/condition-definitions.html#Condition.clinicalStatus
// http://hl7.org/implement/standards/fhir/codesystem-condition-clinical.html

export const CONDITION_CLINICAL = [
    { code: 'active',     display: 'Активно (впервые)' },
    { code: 'recurrence', display: 'Повторно' },
    { code: 'relapse',    display: 'Рецидив' },
    { code: 'inactive',   display: 'Неактивно' },
    { code: 'remission',  display: 'Ремиссия' },
    { code: 'resolved',   display: 'Излечено' },
];

// GET /Concept?valueset=condition-clinical
// http://hl7.org/implement/standards/fhir/condition-definitions.html#Condition.verificationStatus
// http://hl7.org/implement/standards/fhir/codesystem-condition-ver-status.html

export const CONDITION_VER_STATUS = [
    { code: 'provisional',      display: 'Подозреваемое (предварительное)'},
    { code: CONFIRMED_CODE,     display: 'Подтвержденное'},
    { code: 'refuted',          display: 'Опровергнутое'},
    { code: 'entered-in-error', display: 'Ошибочное'},
];

// http://hl7.org/implement/standards/fhir/condition-definitions.html#Condition.severity
// http://hl7.org/implement/standards/fhir/valueset-condition-severity.html
export const CONDITION_SEVERITY = [
    { code: '2704003',  display: 'Острое'},
    { code: '27624003', display: 'Хроническое'},
];

// https://www.hl7.org/fhir/valueset-participationstatus.html
export const PARTICIPATION_STATUS = [
    { code: 'accepted',     display: 'Подтверждено'}, // The participant has accepted the appointment.
    { code: 'declined',     display: 'Отказано'}, // The participant has declined the appointment and will not participate in the appointment.
    { code: 'tentative',    display: 'Возможно'}, // The participant has tentatively accepted the appointment. This could be automatically created by a system and requires further processing before it can be accepted. There is no commitment that attendance will occur.
    { code: 'needs-action', display: 'Нужно подтверждение'}, // The participant needs to indicate if they accept the appointment by changing this status to one of the other statuses.
];

// https://www.hl7.org/fhir/valueset-encounter-participant-type.html
export const PARTICIPATION_TYPE = [
    { code: 'CON',     display: 'Консультант'},       // An advisor participating in the service by performing evaluations and making recommendations.
    { code: 'SPRF',    display: 'Эксперт'},           // A person assisting in an act through his substantial presence and involvement This includes: assistants, technicians, associates, or whatever the job titles may be.
    { code: 'PPRF',    display: 'Председатель'},      // The principal or primary performer of the act.
    { code: 'ATND',    display: 'Секретарь'},         // The practitioner that has responsibility for overseeing a patient's care during a patient encounter.
    { code: 'CALLBCK', display: 'Адрес до кабинета'}, // A person or organization who should be contacted for follow-up questions about the act in place of the author.
];

// http://hl7.org/fhir/extension-patient-disability.html
// https://miramedix.atlassian.net/wiki/spaces/PZ/pages/14450748
export const PATIENT_MOBILITY_CODEABLE_CONCEPTS = [
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '100', display: 'Без ограничений' } ],
        text: 'Без каких-либо значимых ограничений',
    },
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '110', display: 'С ограничениями, но самостоятельно' } ],
        text: 'Самостоятельно, но с некоторыми ограничениями (в том числе с подручными средствами опоры)',
    },
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '120', display: 'С поддержкой / на руках' } ],
        text: 'С поддержкой третьего лица (в том числе на руках, для пациентов-детей)',
    },
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '130', display: 'В сидячем положении' } ],
        text: 'На кресле-каталке или на инвалидном кресле (в том числе механизированном)',
    },
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '140', display: 'В лежачем положении' } ],
        text: 'На каталке в горизонтальном положении',
    },
    {
        coding: [ { system: OID.PATIENT_MOBILITY, code: '150', display: 'С оборудованием жизнеобеспечения' } ],
        text: 'Связан с оборудованием жизнеобеспечения или находится в зафиксированном положении с учетом особенностей травмы',
    },
];

// http://hl7.org/fhir/extension-patient-disability.html
// https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1053
export const PATIENT_DISABILITY_CODEABLE_CONCEPTS = [
    {
        coding: [ { system: OID.PATIENT_DISABILITY, code: '0', display: 'Нет инвалидности' } ],
    },
    {
        coding: [ { system: OID.PATIENT_DISABILITY, code: '1', display: '1 группа' } ],
    },
    {
        coding: [ { system: OID.PATIENT_DISABILITY, code: '2', display: '2 группа' } ],
    },
    {
        coding: [ { system: OID.PATIENT_DISABILITY, code: '3', display: '3 группа' } ],
    },
    {
        coding: [ { system: OID.PATIENT_DISABILITY, code: '4', display: 'Ребенок-инвалид' } ],
    }
];

// https://www.hl7.org/fhir/valueset-encounter-status.html

export const ENCOUNTER_STATUS = [
    {
        code: 'planned',
        display: 'planned',
    },
    {
        code: 'arrived',
        display: 'arrived',
    },
    {
        code: 'triaged',
        display: 'triaged',
    },
    {
        code: 'in-progress',
        display: 'in-progress',
    },
    {
        code: 'onleave',
        display: 'onleave',
    },
    {
        code: 'finished',
        display: 'finished',
    },
    {
        code: 'cancelled',
        display: 'cancelled',
    },
    {
        code: 'entered-in-error',
        display: 'entered-in-error',
    },
    {
        code: 'unknown',
        display: 'unknown',
    },
];


// https://www.hl7.org/fhir/valueset-account-status.html

export const ACCOUNT_STATUS = [
    {
        code: 'active',
        display: 'active',
    },
    {
        code: 'inactive',
        display: 'inactive',
    },
    {
        code: 'entered-in-error',
        display: 'entered-in-error',
    },
    {
        code: 'on-hold',
        display: 'on-hold',
    },
    {
        code: 'unknown',
        display: 'unknown',
    },
];


// https://www.hl7.org/fhir/valueset-fm-status.html

export const FM_STATUS = [
    {
        code: 'active',
        display: 'active',
    },
    {
        code: 'cancelled',
        display: 'cancelled',
    },
    {
        code: 'draft',
        display: 'draft',
    },
    {
        code: 'entered-in-error',
        display: 'entered-in-error',
    },
];
