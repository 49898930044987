import _get from "lodash/get";
import _set from "lodash/set";
import _unset from "lodash/unset";

/**
 * класс для управления характером заболевания
 */
export class ConditionSeverityStatus {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * код
     */
    get code() {
        return _get(this.conditionResource, "severity.coding.0.code");
    }

    /**
     * название
     */
    get display() {
        return _get(this.conditionResource, "severity.coding.0.display");
    }

    /**
     * значение характера заболевания (ReactSelect)
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * изменение характера
     */
    onChange = ({concept}) => {
        _set(this.conditionResource, "severity", {
            coding: [concept]
        });
    }

    /**
     * сброс характера
     */
    reset = () => {
        _unset(this.conditionResource, "severity");
    }

}
