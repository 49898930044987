import { _BasicStore } from 'MODEL_STORE/DataSource/Stores/_BasicStore';

export class PagePaginationStore extends _BasicStore {

    sectionCode;
    storageItem = 'pageCount';
    defaultCount = 15;
    static storage = {}

    constructor(sectionCode) {
        super();
        this.sectionCode = `${this.storageItem}-${sectionCode}`;
    }

    getPageCount = () => {
        const count = PagePaginationStore.storage[this.sectionCode]
        return count ? count : this.defaultCount;
    }

    setDefaultCount = count => {
        this.defaultCount = count;
    }

    setPageCount = count => {
        PagePaginationStore.storage[this.sectionCode] = count;
    }

    clearPageCount = () => {
        delete PagePaginationStore.storage[this.sectionCode];
    }

    static clearAll = () => {
        PagePaginationStore.storage = {};
    }
}
