/**
 * https://www.hl7.org/implement/standards/fhir/extensibility.html#Address
 */
import _get from "lodash/get";
import { Element } from './Element';

/**
 * свойства в элементе Address
 * для формирования полного адреса в одну строку
 */
const FULL_ADDRESS_PROPERTIES = [
    "postalCode", // почтовый индекс
    // "country",    // страна ru_RU
    "state",      // регион
    "district",   // район
    "city",       // город (населенный пункт)
    "line.0",     // адрес
];


export class Address extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Address';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'period',
        ]);

        this._directCopyFields(data, ['use', 'type', 'text', 'city', 'district', 'state', 'postalCode', 'country', ]);

        if (data.line) {
            this._data.line = [ ...data.line ];
        }

    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check all
    }

    /**
     *
     */
    get use() {
        return this._data.use;
    }

    /**
     *
     */
    set use(newValue) {
        this._data.use = newValue;
    }

    /**
     *
     */
    get type() {
        return this._data.type;
    }

    /**
     *
     */
    set type(newValue) {
        this._data.type = newValue;
    }

    /**
     *
     */
    get text() {
        return this._data.text;
    }

    /**
     *
     */
    set text(newValue) {
        this._data.text = newValue;
    }

    /**
     *
     */
    get line() {

        if ( ! Array.isArray(this._data.line)) {
            return [];
        }

        return [ ...this._data.line ];
    }

    /**
     *
     */
    set line(newValue) {
        this._data.line = [ ...newValue ];
    }

    /**
     *
     */
    get city() {
        return this._data.city;
    }

    /**
     *
     */
    set city(newValue) {
        this._data.city = newValue;
    }

    /**
     *
     */
    get district() {
        return this._data.district;
    }

    /**
     *
     */
    set district(newValue) {
        this._data.district = newValue;
    }

    /**
     *
     */
    get state() {
        return this._data.state;
    }

    /**
     *
     */
    set state(newValue) {
        this._data.state = newValue;
    }

    /**
     *
     */
    get postalCode() {
        return this._data.postalCode;
    }

    /**
     *
     */
    set postalCode(newValue) {
        this._data.postalCode = newValue;
    }

    /**
     *
     */
    get country() {
        return this._data.country;
    }

    /**
     *
     */
    set country(newValue) {
        this._data.country = newValue;
    }

    /**
     * полный адрес в одну строку
     */
    get fullAddressToString() {
        return FULL_ADDRESS_PROPERTIES.map(property => {
            return _get(this._data, property);
        }).filter(value => value).join(", ");
    }

}
