export const toast_dictionary = {
    TT010020: "Скопировано!",
    TT010040: "Не найден контекст перехода",
    TT010060: "Введите подтверждение пароля",
    TT010070: "Пароль должен состоять из минимум 6 символов",
    TT010080: "Введенные пароли не совпадают",
    TT010090: "Не удалось получить context id",
    TT010100: "Ошибка входа",
    TT010110: "Введите пароль",
    TT010120: "Пароль должен состоять из минимум 6 символов",
    TT010140: "При сохранении информации произошла ошибка",
    TT010150: "Информация сохранена",
    TT010160: "При авторизации произошла ошибка",
    TT010170: "Укажите пароль, состоящий из 6 и более символов",
    TT010180: "Пароли не совпадают",
    TT010200: "Укажите аллерген",
    TT010210: "Укажите тип реакции",
    TT010220: "Информация об аллергии успешно сохранена",
    TT010230: "При сохранении информации об аллергии произошла ошибка",
    TT010240: "Информация об аллергии успешно удалена",
    TT010250: "При удалении информации об аллергии произошла ошибка",
    TT010270: "Должен быть указан как минимум один тип реакции",
    TT010290: "Ошибка при сохранении заболевания",
    TT010300: "Необходимо выбрать онкологическое заболевание",
    TT010320: "Необходимо выбрать онкологическое заболевание",
    TT010330: "Ошибка при сохранении заболевания",
    TT010340: "Не заполнено поле \"Установлено\"",
    TT010350: "Не указано состояние подтверждения",
    TT010360: "Не указан характер заболевания",
    TT010370: "Не указана дата установки",
    TT010380: "Не указан врач, установивший заболевание",
    TT010390: "Не указан код МКБ-10",
    TT010400: "Необходимо ввести описание нозологической формы",
    TT010410: "Заболевание с таким кодом МКБ-10 уже добавлено",
    TT010420: "Ошибка валидации",
    TT010440: "Нельзя указать два новообразования, имеющих отметку Основная опухоль",
    TT010460: "Добавлено новое осложнение заболевания",
    TT010470: "При добавлении осложнения произошла ошибка",
    TT010480: "Осложнение заболевания успешно удалено",
    TT010490: "При удалении осложнения заболевания произошла ошибка",
    TT010510: "Ошибка при удалении заболевания",
    TT010530: "Не найдена текущая роль пользователя",
    TT010540: "Укажите валидный email адрес",
    TT010560: "Документ сохранен",
    TT010570: "Ошибка при сохранении документа",
    TT010590: "Укажите препарат",
    TT010600: "Укажите порядок приема",
    TT010610: "Информация о принимаемом препарате успешно сохранена",
    TT010620: "При сохранении информации о принимаемом препарате произошла ошибка",
    TT010630: "Информация о принимаемом препарате успешно удалена",
    TT010640: "При удалении информации о принимаемом препарате произошла ошибка",
    TT010700: "Ошибка при сохранении организации",
    TT010720: "Будет создан пациент МПП",
    TT010730: "Не найден пользователь МПП, будет создан автоматически",
    TT010750: "Новый пациент сохранен",
    TT010760: "При сохранении пациента произошла ошибка",
    TT010780: "Информация сохранена",
    TT010790: "При сохранении информации о пациенте произошла ошибка",
    TT010810: "Выберите диагноз",
    TT010830: "Категория \"Ребенок-инвалид\" устанавливается до 18 лет",
    TT010840: "При отправке СМС кода произошла ошибка",
    TT010850: "Запросите сначала код по СМС",
    TT010860: "Заполните код из СМС",
    TT010870: "При проверке кода подтверждения произошла ошибка",
    TT010880: "При привязке пациента произошла ошибка",
    TT010890: "При синхронизации данных пациентов произошла ошибка",
    TT010900: "Категория \"Ребенок-инвалид\" устанавливается до 18 лет",
    TT010920: "При загрузке пациентов произошла ошибка",
    TT010940: "Пациент удален",
    TT010950: "При удалении пациента произошла ошибка",
    TT010970: "Такой логин уже существует",
    TT010980: "При сохранении пациента произошла ошибка",
    TT011000: "При сохранении произошла ошибка",
    TT011020: "Невозможно удалить заболевание, оно является основанием для заявки на ОК",
    TT011030: "Невозможно удалить заболевание, т.к. по нему есть планы лечения",
    TT011040: "Невозможно удалить заболевание",
    TT011060: "Необходимо заполнить пустые поля",
    TT011070: "Запись добавлена",
    TT011080: "Необходимо заполнить пустые поля",
    TT011090: "Запись сохранена",
    TT011110: "Невозможно удалить заболевание, оно является основанием для заявки на ОК",
    TT011120: "Невозможно удалить заболевание, т.к. по нему есть планы лечения",
    TT011130: "Невозможно удалить заболевание",
    TT011150: "У пациента нет заболеваний",
    TT011170: "Недостаточно прав",
    TT011190: "Выберите дату начала замещения",
    TT011200: "Выберите дату окончания замещения",
    TT011210: "Выберите замещающего сотрудника",
    TT011260: "Нельзя удалить организацию, так как в ней есть роли на которые есть ссылки в заявках",
    TT011270: "Нельзя удалить роль, так как на нее есть ссылки в заявках ({count})",
    TT011280: "Нельзя удалить роль, так как на нее есть ссылки в консилиумах ({count})",
    TT011290: "Телефон должен состоять из 11 цифр",
    TT011300: "Проверьте правильность заполнения замещающих сотрудников",
    TT011310: "Ошибка проверки Email сотрудника. Попробуйте еще раз",
    TT011330: "Замещающий сотрудник удален",
    TT011340: "Ошибка при удалении",
    TT011350: "Замещающий сотрудник удален",
    TT011370: "Нельзя удалить роль",
    TT011390: "Готово!",
    TT011400: "Данные скопированы в буфер обмена",
    TT011420: "Выберите шаблоны для экспорта",
    TT011430: "Импорт завершен",
    TT011440: "Ошибка импорта",
    TT011460: "Готово!",
    TT011470: "Удалено!",
    TT011480: "Something went wrong, see console for details",
    TT011500: "Done!",
    TT011510: "Fail",
    TT011530: "OK",
    TT011540: "fail",
    TT011560: "Планы лечения успешно удалены",
    TT011570: "Ошибка удаления планов лечения",
    TT011580: "Шаблоны успешно удалены",
    TT011590: "Ошибка удаления шаблонов",
    TT011610: "Подождите пока закончится конвертация",
    TT011630: "Укажите консилиум",
    TT011640: "Укажите цель проведения консилиума",
    TT011660: "Не удалось обнаружить пользователя МПП",
    TT011670: "Выберете пункт плана обследования",
    TT011690: "Произошла ошибка при получении данных с сервиса, попробуйте позднее",
    TT011710: "Тип выбранного вами файла {fileName} не поддерживается",
    TT011720: "Тип выбранного вами файла {fileName} не поддерживается",
    TT011730: "Ошибка при сохранении вложений",
    TT011740: "Наименование документа должно быть заполнено",
    TT011750: "Ссылка на пациента не обнаружена!",
    TT011760: "Ошибка при сохранении документа",
    TT011780: "Некорректное время перерыва",
    TT011790: "Не введено время перерыва",
    TT011810: "Невозможно установить период приёма {value} {display}",
    TT011830: "Для редактирования Цикла лечения нужно сохранить Шаблон лечения",
    TT011850: "Файл с именем {fileName} уже существует и не может быть добавлен",
    TT011860: "Ошибка при сохранении вложений",
    TT011880: "Введите наименование цикла лечения",
    TT011890: "Введите продолжительность цикла лечения",
    TT011900: "Введите количество циклов лечения",
    TT011910: "Изменения сохранены",
    TT011930: "Количество циклов цикла лечения не может быть меньше 1",
    TT011940: "Длительность цикла лечения не может быть меньше 1 дня",
    TT011950: "Для добавления препарата укажите длительность цикла лечения",
    TT011960: "Чтобы удалить препарат, необходимо удалить все связи с ним",
    TT011970: "Нельзя связать препараты назначенные на разные дни",
    TT011980: "Нельзя связать ранее связанный препарат. Сначала удалите существующую связь.",
    TT011990: "Нельзя связать более трех препаратов",
    TT012000: "Нельзя перемещать препараты из разных дней",
    TT012020: "Для добавления заболеваний необходимо выбрать тип лекарственной терапии",
    TT012030: "Должен быть выбран хотя бы один тип лекарственной терапии",
    TT012050: "Шаблон лечения возвращен из архива",
    TT012060: "При возвращении шаблона лечения из архива произошла ошибка",
    TT012070: "Шаблон лечения перемещен в архив",
    TT012080: "При архивации шаблона лечения произошла ошибка",
    TT012090: "Шаблон лечения удален",
    TT012100: "При удалении шаблона лечения произошла ошибка",
    TT012110: "Недостаточно прав на совершение действия",
    TT012130: "Укажите причину архивации",
    TT012230: "У текущего пользователя нет ролей",
    TT012250: "Выберите шаблон лечения",
    TT012260: "У текущего пользователя нет ролей",
    TT012270: "Запись добавлена",
    TT012290: "Выберите тип шаблонов для поиска",
    TT012310: "Задайте положительное значение уровня сывороточного креатинина",
    TT012330: "Невозможно установить период приёма {value} {display}",
    TT012350: "Ошибка обновления данных",
    TT012370: "При сохранении информации о МН произошла ошибка",
    TT012380: "Ошибка сохранения препарата",
    TT012390: "Произошла ошибка при загрузке назначения",
    TT012400: "Произошла ошибка при загрузке справочников терминологии",
    TT012410: "Произошла ошибка при загрузке назначения",
    TT012420: "Произошла ошибка при загрузке справочников терминологии",
    TT012430: "При корректировке дозы необходимо указать причину",
    TT012440: "Необходимо указать период приема",
    TT012450: "Для расчёта дозировки препарата необходимо ввести значение уровня сывороточного креатинина",
    TT012470: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",
    TT012480: "Ошибка изменения перерыва",
    TT012500: "Ошибка загрузки причин Остановки введения",
    TT012510: "Предыдущий цикл №{cycleNumber} {cycleName} заканчивается {dateEnd}. Выберите дату начала лечения начиная с {dateStart}",
    TT012520: "Введите продолжительность цикла",
    TT012530: "Продолжительность цикла не может быть меньше одного дня",
    TT012540: "Нельзя изменить продолжительность цикла. Отмените назначенные препараты на дни, которые хотите убрать",
    TT012550: "Не найдены ресурсы типа Observation в данном цикле лечения. Изменение статуса цикла и показателей пациента невозможно.",
    TT012570: "При сохранении итогов цикла произошла ошибка",
    TT012580: "Ошибка при сохранении в сервис",
    TT012590: "Ошибка преобразования данных в fhir",
    TT012600: "При чтении итогов цикла произошла ошибка: при обращении к сервису",
    TT012610: "При чтении итогов цикла произошла ошибка: не хватает параметров",
    TT012630: "Сохранено",
    TT012640: "Ошибка сохранения",
    TT012650: "В данный момент уже имеется Цикл лечения в статусе \"В работе\". Завершите его, прежде чем продолжить работу с этим Циклом лечения.",
    TT012660: "В данный момент уже имеется Цикл лечения в статусе \"Приостановлен\". Завершите его, прежде чем продолжить работу с этим Циклом лечения.",
    TT012670: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Дата начала\"",
    TT012680: "Нельзя изменить продолжительность цикла. Отмените назначенные препараты на дни, которые хотите убрать",
    TT012690: "Предыдущий цикл №{cycleNumber} {cycleName} заканчивается {dateEnd}. Выберите дату начала лечения начиная с {dateStart}",
    TT012700: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Прод. цикла (д)\"",
    TT012710: "Продолжительность цикла не может быть меньше одного дня",
    TT012720: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Рост\"",
    TT012730: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Вес\"",
    TT012740: "Для перевода цикла в статус \"Назначен\" необходимо создать медикаментозные назначения",
    TT012750: "Для перевода цикла в статус \"Назначен\" необходимо подтвердить все медикаментозные назначения",
    TT012760: "Цикл лечения успешно удален",
    TT012770: "Ошибка при удалении цикла лечения",
    TT012780: "Ошибка при сохранении изменений",
    TT012790: "Медикаментозные назначения не могут быть переданы в мобильное приложение, так как не найдена учётная запись пациента",
    TT012800: "Ошибка при сохранении изменений",
    TT012810: "Ошибка при сохранении изменений",
    TT012820: "Для завершения Цикла лечения необходимо для каждого медикаментозного назначения в статусе \"Остановлен\" и \"Не введен\", на вкладке \"Статус лечения\", указать причину остановки/не введения",
    TT012830: "Для завершения Цикла лечения необходимо для каждого медикаментозного назначения в статусе \"Остановлен\" и \"Не введен\", на вкладке \"Статус лечения\", указать комментарий к иной причине остановки/не введения",
    TT012840: "Ошибка генерации стартовых задач",
    TT012850: "Ошибка генерации анкеты-вопросника",
    TT012860: "Ошибка запуска плана лечения в работу",
    TT012870: "Ошибка отмены задач в МПП",
    TT012890: "Не удалось получить клиренс креатинина",
    TT012900: "Не рассчитан ППТ пациента, возможно калькуляторы недоступны, пожалуйста попробуйте повторить позже",
    TT012920: "Нельзя удалить препарат, так как указан статус лечения",
    TT012930: "Ошибка удаления препарата",
    TT012940: "Ошибка добавления препарата",
    TT012950: "Чтобы удалить препарат, необходимо удалить все связи с ним",
    TT012960: "Нельзя перемещать препараты из разных дней",
    TT012970: "Результат сохранен",
    TT012980: "Ошибка сохранения результата",
    TT012990: "Перерыв успешно изменен",
    TT013000: "Ошибка изменения перерыва",
    TT013010: "Нельзя связать препараты назначенные на разные дни",
    TT013020: "Нельзя связать ранее связанный препарат. Сначала удалите существующую связь.",
    TT013030: "Нельзя связать более трех препаратов",
    TT013040: "Связь успешно установлена",
    TT013050: "Ошибка установления связи",
    TT013060: "Связь удалена",
    TT013070: "Ошибка удаления связи",
    TT013090: "Заполните дату окончания введения препарата",
    TT013100: "Заполните дату принятия препарата",
    TT013110: "Заполните комментарий к причине остановки/не введения препарата",
    TT013130: "Некорректное время перерыва",
    TT013140: "Не введено время перерыва",
    TT013160: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",
    TT013170: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",
    TT013220: "Заполните дату начала введения препарата",
    TT013230: "Введите время",
    TT013250: "Выберите цикл лечения",
    TT013270: "Выберите тип лекарственной терапии",
    TT013280: "Для сохранения шаблона необходимо выбрать хотя бы один код заболевания (МКБ-10)",
    TT013290: "Укажите наименование схемы",
    TT013300: "Укажите краткое описание",
    TT013310: "Шаблон успешно сохранен",
    TT013320: "Во время сохранения шаблона произошла ошибка",
    TT013330: "Тип выбранного вами файла не поддерживается",
    TT013340: "Ошибка при сохранении вложений",
    TT013360: "Изменения сохранены",
    TT013370: "Ошибка сохранения изменений",
    TT013390: "Невозможно удалить план лечения. В плане имеются циклы со статусами отличными от \"Создан\"/\"Удален\"/\"Скопирован\"",
    TT013410: "Группа не найдена",
    TT013430: "У текущего пользователя нет ни  одной роли. Создание/редактирование консилиума невозможно",
    TT013440: "Не удалось перенести консилиум на другое время",
    TT013450: "При сохранении консилиума произошла ошибка",
    TT013510: "Не удалось отправить приглашение участнику скопированного консилиума",
    TT013530: "Заполните период проведения",
    TT013540: "В выбранном периоде нет {dayName}",
    TT013550: "Выберите хотя бы один день недели",
    TT013570: "В схеме имелись консилиумы с назначенными заявками, которые были отсоединены в отдельные консилиумы",
    TT013590: "Введите название",
    TT013600: "Введите описание",
    TT013620: "Данное заболевание уже рассматривается на консилиуме",
    TT013630: "При удалении рассматриваемого заболевания произошла ошибка",
    TT013670: "Дождитесь окончания загрузки информации о шаблоне",
    TT013680: "Введите название",
    TT013690: "Заболевания из выбранной группы уже рассматривается на консилиуме",
    TT013710: "Введите название группы",
    TT014040: "Выберите место для перемещения заявки",
    TT014050: "Заявка успешно перемещена",
    TT014070: "При изменении статуса произошла ошибка",
    TT014090: "При поиске последнего действующего приказа произошла ошибка",
    TT014100: "Команда участников успешно сохранена",
    TT014110: "При сохранении команды участников произошла ошибка",
    TT014120: "Команда участников консилиума от {date} успешно аннулирована",
    TT014130: "При аннулировании команды участников консилиума от {date} произошла ошибка",
    TT014150: "Загрузить можно только один файл",
    TT014190: "Не выбрано заболевание",
    TT014210: "Заболевание сохранено",
    TT014220: "При добавлении заболеваний пациента в секцию диагноза произошла ошибка",
    TT014230: "Заболевание сохранено",
    TT014240: "При добавлении нового заболевания в секцию диагноза произошла ошибка",
    TT014250: "{subject} удалено",
    TT014260: "При удалении произошла ошибка",
    TT014280: "При сохранении версии протокола произошла ошибка",
    TT014290: "При сохранении протокола произошла ошибка",
    TT014300: "Пациент не найден. Создание протокола не возможно.",
    TT014310: "Заполните дату проведения консилиума",
    TT014320: "Укажите председателя консилиума",
    TT014330: "Укажите лечащего врача",
    TT014340: "Укажите секретаря консилиума",
    TT014350: "Укажите специалиста по хирургическому лечению",
    TT014360: "Укажите специалиста по лекарственному лечению",
    TT014370: "Укажите специалиста по радиотерапевтическому лечению",
    TT014380: "Заполните предоставленные медицинские документы",
    TT014390: "Заполните анамнез заболевания",
    TT014400: "Заполните проведенные обследования",
    TT014410: "Укажите результат проведения консилиума",
    TT014420: "Необходимо заполнить поле Диагностический метод",
    TT014430: "Необходимо заполнить поле Место проведения",
    TT014440: "Заполните поле \"Куда направлен пациент\"",
    TT014450: "Заполните объективный статус",
    TT014460: "При работе с протоколом произошла ошибка, не был\nприсвоен уникальный идентификационный номер протокола.",
    TT014470: "Необходимо прикрепить бумажный экземпляр документа",
    TT014480: "Ошибка: {message}",
    TT014490: "не известна",
    TT014500: "При получении контекста произошла ошибка",
    TT014510: "У авторизованного пользователя не указа emias ID",
    TT014520: "Ошибка сохранения подписанного документа",
    TT014530: "Ошибка подписания документа",
    TT014540: "Во время подготовки печатной формы произошла ошибка, попробуйте выполнить операцию позже",
    TT014560: "В настоящий момент невозможно это изменить. Попробуйте через несколько секунд",
    TT014580: "Произошла ошибка при сохранении документа",
    TT014600: "Ошибка подгрузки вложений",
    TT014610: "Наименование документа должно быть заполнено",
    TT014620: "Ссылка на пациента не обнаружена!",
    TT014630: "Ошибка при сохранении документа",
    TT014650: "Во время генерации отчета произошла ошибка",
    TT014670: "Данный временной слот занят, выберите другой",
    TT014680: "При привязке заявки к новому консилиуму произошла ошибка",
    TT014690: "При создании заявок произошла ошибка",
    TT014700: "При сохранении изменений произошла ошибка",
    TT014710: "При привязке заявки к новому консилиуму произошла ошибка",
    TT014730: "Нельзя добавить заявку на консилиум, так как все места уже заняты",
    TT014740: "Нельзя привязать заявку к текущему пользователю",
    TT014750: "Нельзя добавить заявку в прошедший консилиум",
    TT014760: "Не найдено свободное место для подачи заявки",
    TT014770: "Нельзя занять место для подачи заявки",
    TT014880: "Заявка не рассматривается ни на одном из консилиумов. Выберите консилиум для продолжения работы с заявкой.",
    TT014890: "Не найден ресурс Composition для общего анамнеза. Нужно пересоздать заявку.",
    TT014900: "Не найден ресурс Composition для анамнеза заболевания. Нужно пересоздать заявку.",
    TT014910: "Не найден ресурс Composition для текущего состояния. Нужно пересоздать заявку.",
    TT014920: "CarePlan не найден для заявки",
    TT014930: "Укажите цель проведения консилиума",
    TT014940: "Необходимо указать наименования пунктов плана обследования",
    TT014950: "Ошибка при сохранении записи",
    TT014960: "Заявка сохранена",
    TT014970: "Ошибка при отправке",
    TT014980: "Заявка отправлена",
    TT014990: "Нельзя отклонить заявку",
    TT015000: "Комментарий обязателен к заполнению",
    TT015010: "Невозможно удалить заявку, так как к ней создан протокол",
    TT015020: "Ошибка при удалении записи",
    TT015040: "Ошибка добавления наблюдения",
    TT015050: "При удалении произошла ошибка",
    TT015070: "Ошибка при обновлении CarePlan",
    TT015080: "Ошибка при обновлении задачи",
    TT015090: "Ошибка при принятии задачи",
    TT015100: "Ошибка при повторной отправке задачи",
    TT015120: "Не удалось загрузить комментарии",
    TT015130: "Не удалось добавить комментарий",
    TT015140: "Ошибка при удалении документа",
    TT015330: "Комментарии были обновлены",
    TT015340: "Необходимо указать наименование пункта",
    TT015350: "Произошла ошибка при добавлении документа",
    TT015360: "Документы задачи успешно скопированы",
    TT015370: "Ошибка при копировании документов из задачи",
    TT015380: "Ошибка при удалении документа",
    TT015400: "Не удалось получить ресурс общего анамнеза",
    TT015420: "Не удалось получить Анамнез заболевания к заявке на консилиум",
    TT015440: "Не удалось получить ресурс пациента",
    TT015450: "Не удалось получить ресурсы осложнений и сопутствующих заболеваний",
    TT015470: "Не удалось получить ресурс текущего состояния",
    TT015490: "Не удалось получить ресурс пациента",
    TT015500: "Не удалось получить ресурс заболевания",
    TT015520: "Не удалось получить ресурс текущего состояния",
    TT015540: "Не удалось получить ресурс пациента",
    TT015550: "Не удалось получить ресурсы для текущего состояния",
    TT015570: "В заявке id: {serviceRequestId} не известная комбинация статусов",
    TT015580: "Отсутствует история браузера, переход к предыдущей странице невозможен",
    TT015600: "Создано событие календаря для пациента МПП на основе одобренной заявки",
    TT015610: "Событие календаря для пациента МПП не найдено",
    TT015620: "Не удалось обнаружить пользователя МПП",
    TT015630: "При изменении статуса события календаря для пациента МПП произошла ошибка",
    TT015640: "При создании события календаря для пациента МПП на основе одобренной заявки произошла ошибка:",
    TT015650: "Заявка на консилиум не найдена",
    TT015660: "Консилиум, на который ссылается заявка не найден",
    TT015670: "Пациент не найден",
    TT015680: "Пациент МПП не найден",
    TT015690: "При отправке push-уведомления произошла ошибка",
    TT015710: "Ошибка авторизации на запрос {uri}",
    TT015730: "Ошибка входа: {message}",
    TT015740: "На указанный e-mail адрес отправлена инструкция по сбросу пароля",
    TT015750: "Смена пароля успешно завершена. Вы можете авторизоваться",
    TT015770: "В заявке id: {serviceRequestId} не известная комбинация статусов",
    TT015800: "Не указан тип первично-множественной опухоли",
    TT015810: "Не указан статус диагноза",
    TT015820: "Не указан метод подтверждения диагноза",
    TT015830: "Не указана дата постановки",
    TT015840: "Не указано является ли опухоль основной",
    TT015850: "Не указана сторона поражения",
    TT015860: "Укажите гистологический тип",
    TT015870: "Заполните клиническую и/или патологоанатомическую стадию",
    TT015880: "Указаны не все факторы клинической стадии",
    TT015890: "Не указана стадия для клинической стадии",
    TT015900: "Указаны не все факторы патологоанатомической стадии",
    TT015910: "Не указана стадия для патологоанатомической стадии",
    TT015920: "Не полностью заполнены результаты ИГХ-исследования{titleWithDate}",
    TT015930: "Не полностью заполнены результаты молекулярно-генетического исследования{titleWithDate}",
    TT015940: "Не указана локализация отдаленных метастазов",
    TT015960: "Клиническая стадия не может быть указана",
    TT015970: "Патологоанатомическая стадия не может быть указана",
    TT015980: "Ресурс осложнения уже существует",
    TT015990: "Ссылка на осложнение уже существует",
    TT016000: "Добавлено осложнение заболевания",
    TT016010: "Не найден ресурс осложнения",
    TT016020: "Не найдена ссылка на ресурс осложнения",
    TT016030: "Осложнение успешно удалено",
    TT016050: "При добавлении клинической стадии произошла ошибка",
    TT016060: "Не найден тип классификации для группы ЗНО '{code}'",
    TT016070: "Не найден источник для типа классификации с группой ЗНО '{code}'",
    TT016080: "Не найден тип стадирования для типа классификации с группой ЗНО '{code}'",
    TT016100: "При добавлении патологоанатомической стадии произошла ошибка",
    TT016110: "Не найден тип классификации для группы ЗНО '{code}'",
    TT016120: "Не найден источник для типа классификации с группой ЗНО '{code}'",
    TT016130: "Не найден тип стадирования для типа классификации с группой ЗНО '{code}'",
    TT016150: "Ошибка при загрузке информации о пациенте",
    TT016160: "Ошибка при загрузке информации о заболевании пациента",
    TT016170: "Ошибка при загрузке информации о пациенте и заболеваниях",
    TT016190: "СНИЛС должен состоять из {length} цифр",
    TT016210: "Тип организации",
    TT016220: "{label} не выбран",
    TT016240: "Укажите организацию",
    TT016260: "У идентификатора #{number} не указан тип",
    TT016270: "У идентификатора с типом {type} не указано значение",
    TT016290: "Нельзя удалить дефолтную организацию",
    TT016300: "Организация удалена",
    TT016310: "При проверке ссылок на организацию в других ресурсах произошла ошибка",
    TT016320: "Нельзя удалить организацию, так как на нее есть ссылки ({count}) в других ресурсах",
    TT016330: "При удалении организации произошла ошибка",
    TT016350: "Логин не указан",
    TT016360: "Нужно создать пароль",
    TT016370: "Пароль для пациента должен состоять из 6 цифр",
    TT016380: "Дата рождения не указана",
    TT016390: "Дата рождения указана в неверном формате",
    TT016400: "Фамилия не указана",
    TT016410: "Имя не указано",
    TT016420: "Пол не указан",
    TT016430: "Организация не указана",
    TT016440: "Лечащий врач не указан",
    TT016450: "Мобильность не указана",
    TT016460: "СНИЛС должен состоять из 11 цифр",
    TT016480: "Фамилия должна содержать только кириллические символы",
    TT016490: "Имя должно содержать только кириллические символы",
    TT016500: "Отчество должно содержать только кириллические символы",
    TT016510: "Укажите дату рождения",
    TT016520: "Дата рождения указана в неверном формате",
    TT016530: "Номер полиса ОМС должен состоять из {length} цифр",
    TT016540: "Телефон должен состоять из 11 цифр",
    TT016550: "Укажите направившую МО",
    TT016570: "При поиске зависимостей пациента произошла ошибка",
    TT016590: "Заболевание пациента сохранено",
    TT016600: "Ошибка при сохранении заболевания пациента",
    TT016620: "Ошибка при удалении заболевания",
    TT016640: "Укажите район",
    TT016650: "Укажите почтовый индекс",
    TT016670: "Укажите значение",
    TT016680: "Телефон должен состоять из 11 цифр",
    TT016690: "Укажите валидный email адрес",
    TT016700: "Такой контакт уже существует",
    TT016720: "Не указана дата начала замещения",
    TT016730: "Не указана дата окончания замещения",
    TT016740: "Не указан замещающий сотрудник",
    TT016760: "Нельзя удалить дефолтного сотрудника",
    TT016770: "При проверке ссылок на сотрудника в других ресурсах произошла ошибка",
    TT016780: "Нельзя удалить сотрудника, так как на него есть ссылки ({count}) в других ресурсах",
    TT016790: "При удалении сотрудника произошла ошибка",
    TT016800: "Сотрудник удален",
    TT016820: "Роль с таким идентификатором уже существует",
    TT016830: "Валидация пройдена",
    TT016850: "Укажите название",
    TT016860: "Название не должно превышать 255 символов",
    TT016870: "Укажите идентификатор",
    TT016880: "Идентификатор не должен превышать 50 символов",
    TT016900: "При загрузке списка ролей произошла ошибка",
    TT016920: "При загрузке списка разрешений произошла ошибка",
    TT016940: "Оригинальная роль ({roleId}) не найдена. Обновите страницу",
    TT016950: "Уже есть копия роли с таким идентификатором ({roleId}). Обновите страницу",
    TT016960: "При создании копии роли ({roleId}) произошла ошибка",
    TT016980: "Роль ({roleId}) не найдена",
    TT016990: "При поиске роли ({roleId}) произошла ошибка",
    TT017010: "При сохранении новой роли ({roleId}) произошла ошибка: роль с таким идентификатором уже существует. Обновите страницу",
    TT017020: "Роль (\"{roleId}\") успешно сохранена",
    TT017030: "При сохранении роли ({roleId}) произошла ошибка",
    TT017050: "При удалении роли произошла ошибка: ресурс роли ({roleId}) не найден. Обновите страницу",
    TT017060: "Нельзя удалить роль ({roleId}), так как есть пользователи ({count}) с этой ролью в системе. Обновите страницу",
    TT017070: "При удалении роли ({roleId}) произошла ошибка: не удалось получить кол-во пользователей в системе",
    TT017080: "При удалении роли ({roleId}) произошла ошибка: ресурс не найден. Обновите страницу",
    TT017090: "При удалении роли ({roleId}) произошла ошибка",
    TT017110: "При запросе кол-ва пользователей с ролью произошла ошибка",
    TT017130: "Все ресурсы заболеваний имеют правильный формат",
    TT017140: "При конвертации ресурсов заболеваний произошла ошибка",
    TT017150: "{total} ресурсов заболеваний конвертированы в новый формат",
    TT017170: "Все ресурсы сконвертированы",
    TT017180: "Сконвертировано {length} ресурсов",
    TT017190: "При конвертации системы для кодирования инвалидности произошла ошибка",
    TT017210: "В контексте не указаны данные о пациенте",
    TT017220: "При запросе ранее созданных заявок и идентификатора основного заболевания произошла ошибка. Создание новой заявки не возможно",
    TT017240: "Наименование цикла не может быть пустым",
    TT017260: "Произошла ошибка при загрузке справочников терминологии",
    TT017270: "Ошибки при подготовке к сохранению МН: ",
    TT017280: "не выбрано МНН",
    TT017290: "Не выбран путь приема",
    TT017300: "Необходимо указать кол-во и ед. измерения препарата \"{display}\"",
    TT017310: "При корректировке дозы необходимо указать причину",
    TT017320: "Необходимо указать кол-во и ед.изм. инф. среды",
    TT017330: "Необходимо задать длительность введения",
    TT017340: "Необходимо указать период приема",
    TT017350: "Не указаны дни приема препарата",
    TT017430: "Укажите дату и время проведения консилиума",
    TT017450: "Укажите организацию, проводящую консилиум",
    TT017460: "Укажите детальный адрес места проведения консилиума",
    TT017480: "Укажите место проведения консилиума",
    TT017500: "В группе '{name}' не указаны заболевания",
    TT017520: "Данный консилиум не может быть перенесен, так как на нем уже была рассмотрена заявка",
    TT017600: "Укажите дату протокола",
    TT017610: "Укажите организацию",
    TT017620: "Укажите дополнительного участника",
    TT017640: "Формат файла {fileName} не поддерживается",
    TT017650: "Файл {fileName} успешно загружен",
    TT017660: "При загрузке файла {fileName} произошла ошибка",
    TT017680: "Отредактируйте команду",
    TT017690: "При копировании команды произошла ошибка",
    TT017700: "Команда уже аннулирована",
    TT017710: "При аннулировании команды произошла ошибка",
    TT017730: "При валидации диагноза произошла ошибка",
    TT017740: "Ресурс диагноза не найден",
    TT017750: "Вы не указали основное заболевание",
    TT017760: "Вы не указали минимальное количество вложенных заболеваний (2)",
    TT017770: "Отметьте одно из новообразований, как Основную опухоль. Отредактируйте вложенные заболевания.",
    TT017780: "Должно быть только одно новообразование, имеющее отметку Основная опухоль. Отредактируйте вложенные заболевания.",
    TT017790: "В форме указания информации о заболевании ({mkb10Code}) не заполнены обязательные поля",
    TT017810: "Не указана тактика лечения {postfix}",
    TT017820: "для онкологического заболевания {oncology}",

    TT017830: "Укажите номер личного телефона",
    TT017840: "Информация об аллергии успешно сохранена",
    TT017850: "Невозможно добавить заявку на консилиум сверх установленной нормы, так как консилиум уже начался",
    TT017860: "Пользователь с таким логином уже зарегистрирован в системе",
    TT017890: "Не удалось отправить E-mail на указанный адрес. Попробуйте еще раз.",
    TT017900: "Ошибка. Попробуйте еще раз",
    TT017910: "Укажите пароль, состоящий из 6 и более символов",
    TT017920: "Пароли не совпадают",
    TT017930: "Не возможно сменить пароль. Необходимо заново запросить смену пароля",
    TT017940: "Код смены пароля устарел. Необходимо заново запросить смену пароля",
    TT017950: "Пользователь, которому нужно изменить пароль, не найден",

    "TT013540-01": "понедельника",
    "TT013540-02": "вторника",
    "TT013540-03": "среды",
    "TT013540-04": "четверга",
    "TT013540-05": "пятницы",
    "TT013540-06": "субботы",
    "TT013540-07": "воскресенья",

    TT018000: "Место занято",
    TT018010: "Нет свободных мест",
    TT018020: "Нет мест",
    TT018030: "Введите код по МКБ-10 или название заболевания",
    TT018040: "Сбросить фильтры",
    TT018050: "Загрузка",
    TT018060: "Перемещение заявки...",
    TT018070: "При загрузке информации произошла ошибка",
    TT018080: "Консилиумы не найдены. Измените условия поиска",
    TT018090: "При перемещении заявки внутри консилиума произошла ошибка",
    TT018100: "Не найден ресурс консилиума внутри которого перемещается заявка",
    TT018110: "Не найден ресурс места перемещаемой заявки",
    TT018120: "Исходное место в консилиуме не занято",
    TT018130: "Не найден ресурс нового места для заявки",
    TT018140: "Не найден ресурс перемещаемой заявки",
    TT018150: "Заявка не ссылается на место из которого перемещается",
    TT018160: "При перемещении заявки на другой консилиум произошла ошибка",
    TT018170: "Не найден ресурс места в исходном консилиуме",
    TT018180: "Место в исходном консилиуме не занято заявкой",
    TT018190: "Не найден ресурс исходного консилиума",
    TT018200: "Не найден ресурс места проведения исходного консилиума",
    TT018210: "Не найден ресурс организации места проведения исходного консилиума",
    TT018220: "Не найден ресурс свободного места целевого консилиума",
    TT018230: "Место в целевом консилиуме уже занято",
    TT018240: "Не найден ресурс целевого консилиума",
    TT018250: "Не найден ресурс места проведения целевого консилиума",
    TT018260: "Не найден ресурс организации места проведения целевого консилиума",
    TT018270: "Не найден ресурс перемещаемой заявки",
    TT018280: "Заявка не ссылается на место в исходном консилиуме",
    TT018290: "Нельзя перенести готовую к рассмотрению заявку",
    TT018300: "Нельзя перенести аннулированную заявку",
    TT018310: "Нельзя перенести рассмотренную заявку",
    TT018320: "Нельзя перенести заявку с неизвестным статусом",
    TT018330: "Нельзя перенести удаленную заявку",
    TT018340: "Нельзя перенести заявку с таким статусом",
    TT018350: "При формировании нового номера протокола произошла ошибка",
    TT018360: "При перемещении заявки на другой консилиум произошла ошибка",
    TT018370: "При формировании нового номера протокола произошла ошибка",
    TT018380: "Выберите место для перемещения заявки",
    TT018390: "Выберите другое место для перемещения заявки",
    TT018400: "Загрузите документ в формате PDF",
    TT018410: "Нельзя загрузить больше 15 мб",

    TT018420: "Протоколы не найдены. Измените условия поиска",
    TT018430: "Не найдено ни одного протокола за данный период",
    TT018440: "Загрузка списка документов…",
    TT018450: "(сообщение в работе)",
    TT018460: "Цикл Лечения № {CycleNumber} {CycleName} был назначен пациенту {PatientFullName} на дату: {CycleDate}. Его нужно взять в работу.",
    TT018470: "Цикл Лечения № {CycleNumber} {CycleName} пациента {PatientFullName} был взят в работу сотрудником {EmplFullName}",
    TT018480: "Цикл Лечения № {CycleNumber} {CycleName} пациента {PatientFullName} дата начала которого назначена на сегодня ({TODAY}) не был переведён в статус “В работе”. Необходимо взять его в работу или изменить дату начала цикла лечения.",
    TT018490: "В Цикле Лечения № {CycleNumber} {CycleName} пациента {PatientFullName} был указан статус приёма препарата {DrugName} {DrugStatus}. Причина: {DrugCause}",
    TT018500: "В Цикле Лечения № {CycleNumber} {CycleName} пациента {PatientFullName} был указан статус приёма препарата {DrugName} {DrugStatus}. Причина: {DrugCause}",
    TT018510: "Необходимо заполнить Итоги цикла лечения пациента {PatientFullName} в Цикле Лечения № {CycleNumber} {CycleName}",
    TT018520: "Необходимо указать статус лечения пациента {PatientFullName} в Цикле Лечения № {CycleNumber} {CycleName}",
    TT018530: "(сообщение в работе)",
    TT018540: "(сообщение в работе)",
    TT018550: "Вы были приглашены на консилиум “{TBName}“, который состоится {TBDateTime}",
    TT018560: "Созданная вами заявка на пациента {PatientFullName} была согласована",
    TT018570: "Заявка пациента {PatientFullName} на консилиум была отклонена секретарем. Пожалуйста, ознакомьтесь с замечаниями секретаря и отправьте заявку на повторное согласование",
    TT018580: "Напоминаем, что завтра вы приглашены на консилиум “{TBName}“, который состоится {TBDateTime}",
    TT018590: "Внимание! Консилиум, запланированный на {TBDateTime} был отменен.",
    TT018600: "Внимание! Консилиум, запланированный на {TBDateTime} был перенесен на {NewTBDateTime}",
    TT018610: "Заявка пациента {PatientFullName} находится в статусе “Создана“. Заполните заявку для представления ее на консилиуме",
    TT018620: "Пациент(ка) добавил(а) новые документы в “Возможные обследования“",
    TT018630: "Пациент {PatientFullName} внес новую запись в дневник здоровья",
    TT018640: "На консилиум {TBName}, {TBDateTime} была подана заявка на пациента {PatientFullName}",
    TT018650: "Необходимо заполнить протокол консилиума для пациента {PatientFullName}",
    TT018660: "Протокол по случаю пациента {PatientFullName} был опубликован",
    TT018670: "Сотрудник {EmployeeFullName} пользователя оставил комментарий в заявке на пациента {PatientFullName}.",
    TT018680: "Пациенты не найдены. Измените условия поиска",
    TT018690: "Следующая страница",
    TT018700: "Предыдущая страница",
    TT018710: "Информации по аллергиям и непереносимостям пациента нет",

    //confirm messages
    TT018760: "Удалить заболевание?",
    TT018770: "Удалить документ {documentDescription}?",
    TT018780: "Удалить документ\r\n{documentDescription}?",
    TT018790: "Удалить заболевание пациента\r\n{code}: {name}?",
    TT018800: "Конвертировать элементы (описание и комментарий) ресурсов заболеваний в новый формат?",
    TT018810: "Конвертировать систему инвалидности для пациентов в новый формат ({patientDisability})?",
    TT018820: "Цикл плана лечения не сохранен. Вы точно хотите покинуть страницу?",
    TT018830: "Шаблон плана лечения не сохранен. Вы точно хотите покинуть страницу?",
    TT018840: "Протокол онкологического консилиума по случаю {displayName} не завершен. Вы точно хотите покинуть страницу?",
    TT018850: "Удалить документ\r\n{documentDescription}?",

    //Переводы 24.11.21
    TT021890: "Для перевода цикла в статус \"Назначен\" необходимо выбрать медикаментозное назначение из всех основных групп",
    TT021900: "Нельзя удалить роль, так как на нее есть ссылки в других ресурсах",
    TT021910: "При удалении роли произошла ошибка",
    TT022010: "Значение поля \"До\" не может быть меньше значения поля \"От\" у препарата \"{display}\"",
    TT022190: "Для перевода цикла в статус \"Назначен\" необходимо выбрать медикаментозное назначение из всех основных групп",
    TT022200: "Дата начала не может быть раньше даты рождения",
    TT022270: "На выбранную дату уже был создан консилиум. Попробуйте распределить заявку заново",

    //Перевод dv кодов 29.11.21
    SS9035941: "Невозможно указать длительность цикла более 366 дней",

    //Переводы 1.12.21
    TT022290: "Дата протокола не может быть позднее текущей даты",

    //Переводы 7.12.21
    TT022300: "При загрузке пациентов произошла ошибка...",
    TT022310: "При загрузке онкологических заболеваний пациента произошла ошибка…",
    TT022320: "При загрузке организаций произошла ошибка...",
    TT022330: "При загрузке сотрудников произошла ошибка...",
    TT022340: "При перемещении заявки произошла ошибка…",
    TT022350: "При загрузке информации о консилиумах произошла ошибка...",
    TT022360: "При загрузке заявок на текущую дату произошла ошибка...",
    TT022370: "При загрузке протоколов произошла ошибка...",
    TT022380: "При валидации логина пользователя произошла ошибка…",
    TT022390: "План лечения не найден",
    TT022400: "Ошибка при получении информации о пациенте. Код ошибки: {error}",
    TT022410: "Ресурс шаблона лечения не найден",
    TT022420: "При удалении шаблона произошла ошибка…",
    TT022430: "Шаблон лечения уже был удален",
    TT022440: "При возвращении шаблона из архива произошла ошибка…",
    TT022450: "При архивации шаблона лечения произошла ошибка…",
    TT022460: "Изменение пользовательского шаблона",
    TT022470: "Отчет для Mинздрава",
    TT022480: "Журнал проведенных консилиумов",
    TT022490: "Вложений не было найдено",
    TT022500: "Сформировать печатную форму",

    //Переводы 08.12.21
    TT022510: "Отчет формируется только для протоколов в статусе \"Готов\"",
    TT022520: "Невозможно внести изменения в день цикла, который прошёл",
    TT022530: "Невозможно внести изменения в день цикла, по которому уже проставлен статус лечения",

    //Переводы 11.01.22
    TT022610: "Выберите Место лечения пациента",
    TT022620: "Нельзя удалить препарат, так как в {value} день состоит в одновременном приеме",
    TT022630: "Нельзя отменить {value} день, так как препарат состоит в одновременном приеме",
    TT022640: "Нельзя удалить группу, так как в {value} день состоит в одновременном приеме",
    TT022650: "Нельзя отменить {value} день, так как группа состоит в одновременном приеме",
    TT022660: "Невозможно уменьшить продолжительность цикла, так как есть назначения на {day} день",
    TT022670: "Введите продолжительность цикла",
    TT022680: "Невозможно уменьшить продолжительность цикла, так как есть назначения на {day} день",
    TT022690: "Введите продолжительность цикла",
    TT022700: "Невозможно изменить дни приема препарата, так как у него есть одновременный прием на {day} день",
    TT022710: "В данный момент уже имеется Цикл лечения в статусе \"Назначен\". Завершите его, прежде чем продолжить работу с этим Циклом лечения.",
    TT022720: "Протокол не ссылается ни на одну из заявок",
    TT022730: "Выберите",
    TT022740: "Укажите",
    TT022750: "организацию",
    TT022760: "должность",
    TT022770: "сотрудника",
    TT022780: "специализацию",
    TT022790: "При загрузке протокола произошла ошибка",
    TT022800: "Не найдена информация о пациенте",
    TT022810: "При поиске организаций произошла ошибка",
    TT022820: "Протокол не найден",
    TT022830: "Нет ссылок на реальных участников",
    TT022840: "При поиске состава онкологического консилиума произошла ошибка",
    TT022850: "Не найдена ссылка на организацию в протоколе",
    TT022860: "Организация не найдена",
    TT022870: "Заявка не найдена",
    TT022880: "Нет ссылки на документ",
    TT022890: "Документ не найден",
    TT022900: "Документ успешно подписан",
    TT022910: "У авторизованного пользователя не указан emias ID",
    TT022920: "Ошибка при подписании документа",
    TT022930: "Неизвестная ошибка",
    TT022940: "Передача данных о документах",
    TT022950: "Получение контекста",
    TT022960: "Агент СЭП был закрыт",
    TT022970: "При смене статуса протокола произошла ошибка",
    TT022980: "При присвоении номера для протокола произошла ошибка",
    TT022990: "валидация не требуется",
    TT023000: "валидации тактик лечения пройдена",
    TT023010: "Номер протокола присвоен",
    TT023020: "Электронный экземпляр документа прикреплен",
    TT023030: "Необходимо прикрепить электронный экземпляр документа",
    TT023040: "Дата проведения консилиума указана",
    TT023050: "Тип консилиума указан",
    TT023060: "Цель проведения консилиума указана",
    TT023070: "Все участники указаны",
    TT023080: "{participant} не указан",
    TT023090: "Один из участников",
    TT023100: "Секция с анамнезом заболевания заполнена",
    TT023110: "Секция с общим состоянием заполнена",
    TT023120: "Секция с представленными медицинскими документами заполнена",
    TT023130: "Заполните представленные медицинские документы",
    TT023140: "Результат проведения консилиума указан",
    TT023150: "Валидация диагностических методов пройдена",
    TT023160: "Необходимо заполнить поле Диагностический метод #{number}",
    TT023170: "Необходимо заполнить Место проведения #{number}",
    TT023180: "Поле \"куда направлен пациент\" заполнено",
    TT023190: "Укажите куда направлен пациент",
    TT023200: "Секция с особыми мнениями участников консилиума проверена",
    TT023210: "Протокол ссылается сразу на несколько заявок",
    TT023220: "Заявка рассмотрена на консилиуме",

    //Переводы 2.02.22
    TT023230: "Укажите уникальное название схемы",
    TT023240: "Не найдено ни одного протокола за данный период",

    TT023530: "Необходимо ввести значение в поле “Рост” и “Вес”",
    TT023540: "Необходимо ввести значение в поле “Рост”",
    TT023550: "Необходимо ввести значение в поле “Вес”",
    TT023560: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",
    TT023700: "Участник с ЭП уже выбран. Выключите ЭП у текущего участника, чтобы назначить нового",
    TT023710: "Заявка успешно сохранена",
    TT023720: "Не указана группа ЗНО",
    TT023730: "Не указана дата одного из морфологических исследований",
    TT023740: "Не указана организация одного из морфологических исследований",
    TT023750: "Не указан тип одного из морфологических исследований",
    TT023760: "Не указан результат одного из морфологических исследований",
    TT023770:	"Не указан номер одного из молекулярно-генетических исследований",
    TT023780:	"Не указана дата одного из молекулярно-генетических исследований",
    TT023790:	"Не указана организация одного из молекулярно-генетических исследований",
    TT023800:	"Не указаны показатели одного из молекулярно-генетических исследований",
    TT023810:	"Не указан показатель одного из молекулярно-генетических исследований",
    TT023820:	"Не указан результат одного из молекулярно-генетических исследований",
    TT023830:	"Не указан номер одного из иммуногистохимических исследований",
    TT023840:	"Не указана дата одного из иммуногистохимических исследований",
    TT023850:	"Не указана организация одного из иммуногистохимических исследований",
    TT023860:	"Не указаны показатели одного из иммуногистохимических исследований",
    TT023870:	"Не указан показатель одного из иммуногистохимических исследований",
    TT023880:	"Не указан результат одного из иммуногистохимических исследований",
    TT023890:	"При сохранении заболевания произошла ошибка",
    TT023900:	"Информация о заболевании сохранена",
    TT023910:	"Добавлена запись в историю лечения основного заболевания",
    TT023920:	"Запись из истории лечения основного заболевания обновлена",
    TT023930:	"Запись из истории лечения основного заболевания удалена",
    TT023940: "При сохранении заявки произошла ошибка",
    TT023950: "При изменении статуса заявки произошла ошибка",
    TT023960: "Подписание завершено",
    TT023970: "Подписание отменено",
    TT023980: "При поиске известных заболеваний пациента произошла ошибка",
    TT024000: "Произошла ошибка при добавлении",
    TT024020: "Документ успешно добавлен",
    TT024070: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",
    TT024080: "Сохранение как Шаблон лечения",
    TT024090: "Заполните дату начала цикла лечения",
    TT024100: "При загрузке документов пациента произошла ошибка",
    TT024110: "Не передан ресурс заявки",
    TT024040: "После внесенных изменений необходимо повторно настроить порядок приема МН на вкладке “Лист назначений“",
    TT024140: "Ошибка обработки вложений",
    TT024150: "Обработка отменена",

    __FCSPOK_157_1: "При загрузке расширенных данных пациента произошла ошибка. Попробуйте позднее.",
    __FCSPOK_681_1: "Укажите прогноз",
}
