import _get from "lodash/get";
import {computed, observable} from "mobx";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";

/**
 * абстрактный класс для справочников
 */
export class AbstractValueSet {

    /**
     * ссылка на получение справочника
     */
    endpoint = "";

    /**
     * ответ от сервера
     */
    @observable data = null;

    /**
     * ответы от сервера (кеш)
     */
    static cache = observable({});

    /**
     * при инициализации проверяем есть ли данные в кеше
     * если нет - загружаем справочник
     */
    constructor(endpoint) {
        this.endpoint = endpoint;
        if( this.isDataCached() ) {
            this.setData(this.getCachedData());
        } else {
            this.loadData().then(this.setData);
        }
    }

    /**
     * закешированы ли данные?
     */
    isDataCached = () => {
        return !! AbstractValueSet.cache.hasOwnProperty(this.endpoint);
    }

    /**
     * закешированные данные
     */
    getCachedData = () => {
        return AbstractValueSet.cache[this.endpoint];
    }

    /**
     * загрузка данных (промис)
     */
    loadData = () => {
        return basicRequestGet(`/api/mm${this.endpoint}`).then(response => response.data.data);
    }

    /**
     * установка данных
     */
    setData = (data) => {
        this.data = AbstractValueSet.cache[this.endpoint] = data;
    }

    /**
     * список концептов, используемых в справочнике
     */
    @computed
    get concepts() {
        const concepts = _get(this.data, "expansion.contains", []);
        return Array.isArray(concepts) ? concepts : [];
    }

}
