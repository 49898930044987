import {Translator} from "eo-locale";
import {HeaderStore} from "../Stores/HeaderStore";
import {locales} from "Locale";

/**
 * кол-во цифр в номере СНИЛС
 */
const SNILS_NUMBER_LENGTH = 11;

/**
 * валидация для номера СНИЛС
 */
export class SnilsValidation {

    /**
     * значение номера СНИЛС
     * например, 112-233-445-95
     */
    value = "";

    /**
     * номер СНИЛС без форматирования
     * например, 11223344595
     */
    number = "";

    /**
     * ошибка в удобочитаемом формате
     * например, СНИЛС не указан
     */
    error = "";

    /**
     * value - значение номера СНИЛС
     */
    constructor(value = "") {
        this.value = typeof value == "string" ? value.trim() : "";
        this.number = this.value.replace(/\D/g, "");
        this.check();
    }

    /**
     * проверка номера СНИЛС и установка ошибки
     */
    check = () => {
        const translator = new Translator(HeaderStore.locale, locales);
        if( this.isEmpty() ) {
            return this.error = translator.getMessageById("SS91135");
        }
        if(HeaderStore.locale === "ru") {
            if (!this.isComplete()) {
                return this.error = translator.translate('SS904053', { digitCount: SNILS_NUMBER_LENGTH });
            }
            if (!this.isCorrect()) {
                return this.error = translator.getMessageById("SS009058");
            }
        }
    }

    /**
     * значение пустое?
     */
    isEmpty = () => {
        return this.value.length == 0;
    }

    /**
     * номер состоит из 11 цифр?
     */
    isComplete = () => {
        return this.number.length == SNILS_NUMBER_LENGTH;
    }

    /**
     * номер корректен? (проверка контрольного числа)
     */
    isCorrect = () => {
        if( ! this.isComplete() ) {
            return false;
        }
        let sum = 0;
        for (let index = 0; index < 9; index++) {
            sum += parseInt(this.number[index]) * (9 - index);
        }
        let checkDigit = 0;
        if (sum < 100) {
            checkDigit = sum;
        } else if (sum > 101) {
            checkDigit = parseInt(sum % 101);
            if (checkDigit === 100) {
                checkDigit = 0;
            }
        }
        return checkDigit === parseInt(this.number.slice(-2));
    }

}
