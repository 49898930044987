import _get from "lodash/get";
import _set from "lodash/set";
import _unset from "lodash/unset";

/**
 * сторона поражения
 */
export class ConditionAffectedBodySite {

    /**
     * система кодирования (Condition.bodySite.coding.system)
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.99.2.143";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
        if( ! Array.isArray(this.conditionResource.bodySite) ) {
            this.conditionResource.bodySite = [];
        }
    }

    /**
     * концепт
     */
    get concept() {
        if( this.conditionResource.bodySite.length ) {
            return this.conditionResource.bodySite[0];
        }
    }

    /**
     * описание
     */
    get text() {
        return _get(this.concept, "text", "");
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.concept, "coding", []).find(coding => {
            return _get(coding, "system") == ConditionAffectedBodySite.CODING_SYSTEM;
        });
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение для ReactSelect
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * при изменении
     */
    onChange = ({concept}) => {
        if(this.code == concept.code) {
            return false;
        }
        this.conditionResource.bodySite = [{
            text: concept.display,
            coding: [{
                code: concept.code,
                system: ConditionAffectedBodySite.CODING_SYSTEM,
                display: concept.display,
                version: concept.version
            }]
        }];
    }

    /**
     * удаление
     */
    reset = () => {
        this.conditionResource.bodySite = [];
    }

}
