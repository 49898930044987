import { VisibilityStoreGenerator } from 'MODEL_STORE/DataSource/Stores/VisibilityStoreGenerator';

const PatientCreatePopupStore = new VisibilityStoreGenerator();
const PractitionerCreatePopupStore = new VisibilityStoreGenerator();
const PractitionerRolePopupStore = new VisibilityStoreGenerator();

export {
    PatientCreatePopupStore,
    PractitionerCreatePopupStore,
    PractitionerRolePopupStore,
};
