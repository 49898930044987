import React from "react";
import styled from "styled-components"

export const FullHeightWrapper = styled.div`
    height: 100vh;
    padding: 8px;
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
        margin-top: 8px;
    }
`;

export const Toolbar = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const SettingsContainer = styled.div`
    flex: 10px 1 1;
`;



export const InputRow = styled.div`
    display: flex;
    &:not(:first-child) {
        margin-top: 8px;
    }
`;

export const Button = styled.button`
    height: 32px;
    padding: 0 12px;
    border-radius: 4px;
    background-color: #06f;
    border: none;
    color: white;
    margin-left: 8px;
    outline: none;
`;

export const Label = styled.span`
    flex: 200px 0 0;
    line-height: 32px;
    padding: 0;
`;

export const Select = styled.select`
    height: 32px;
    padding: 0 12px;
    border-radius: 4px;
    border: 1px #ccc solid;
    background-color: transparent;
    width: 100%;
`;

export const Input = styled.input`
    padding: 0 12px;
    border-radius: 4px;
    border: 1px #ccc solid;
    background-color: transparent;
    width: 100%;
    height: 32px;
`;

export const Request = styled.textarea`
    border: 1px #ccc solid;
    border-radius: 4px;
    padding: 0 12px;
    white-space: pre;
    font-family: monospace;
    background-color: transparent;
    width: 100%;
    flex: 50% 1 1;
    font-size: 12px;
`;

export const Response = styled.div`
    border: 1px #ccc solid;
    border-radius: 4px;
    padding: 0 12px;
    white-space: pre;
    font-family: monospace;
    background-color: transparent;
    width: 100%;
    font-size: 12px;
    flex: 50% 1 1;
    overflow: auto;
`;
