export const tb_dictionary = {
    SS001001: "Рабочий стол",
    SS001002: "Пациенты",
    SS001003: "Рабочий стол",
    SS001004: "Консилиумы",
    SS001005: "Участники консилиума",
    SS001006: "Заявки",
    SS001007: "Протоколы",
    SS001008: "Планы лечения",
    SS001009: "Шаблоны Лечения",
    SS001010: "Сотрудники",
    SS001011: "Организации",
    SS001012: "Управление ролями",
    SS001013: "Документы",
    SS001014: "Заявки",
    SS001015: "ФИО пациента",
    SS001016: "Дата проведения",
    SS001017: "Медицинская организация",
    SS001018: "Статус заявки",
    SS001019: "Календарь консилиумов",
    SS001020: "Обязательно присутствие пациента",
    SS001021: "Консилиум прошел",
    SS001022: "Создана",
    SS001023: "Председатель",
    SS001024: "Секретарь",
    SS001025: "Глобальный поиск (пациенты, заявки, врачи и прочее)",
    SS001026: "У вас пока нет ни одной заявки на текущую дату",
    SS001027: "Создать заявку",
    SS001028: "На эту дату не запланировано ни одного консилиума",
    SS001029: "Показать уведомления",
    //значение приходит с бекенда, пока не используется
    SS001030: "главный врач медицинской организации",
    SS001031: "Настройки",
    SS001032: "Выйти",
    SS001033: "Рассмотрена",
    SS001034: "Возвращена на доработку",
    SS001035: "На согласовании",
    SS001036: "Готова для рассмотрения",
    //Не используется, заменена на PP
    SS001037: "{days} день",
    SS002001: "Пациенты",
    SS002002: "ФИО пациента",
    SS002003: "Дата рождения",
    SS002004: "Полис ОМС",
    SS002005: "СНИЛС",
    SS002006: "ФИО пациента",
    SS002007: "Дата рождения",
    SS002008: "Пол",
    SS002009: "Полис ОМС",
    SS002010: "СНИЛС",
    SS002011: "",
    SS002012: "Ранее просматриваемые пациенты",
    SS002013: "ФИО",
    SS002014: "Дата рождения",
    SS002015: "Пол:",
    SS002016: "Полис ОМС",
    SS002017: "СНИЛС",
    SS002018: "Сбросить фильтры",
    //---Здесь закончили, согласно комментарию от 8апреля 2021
    SS002050: "Укажите значения для поиска пациента и нажмите «Найти»",
    SS002051: "Найти",
    SS003001: "Пациенты",
    SS003002: "Карта пациента",
    SS003003: "лет",
    SS003004: "Женский",
    SS003005: "Мужской",
    SS003006: "Дата рождения",
    SS003007: "СНИЛС",
    SS003008: "Направившая организация:",
    SS003009: "Телефон",
    SS003010: "Электронная почта",
    SS003011: "Инвалидность",
    SS003012: "ОМС",
    SS003013: "Обзор",
    SS003014: "Анамнез жизни и сигнальная информация",
    SS003015: "История лечения",
    SS003016: "Дневник здоровья",
    SS003017: "Файлы и сканы документов",
    SS003018: "Заболевания",
    SS003019: "Добавить заболевание",
    SS003020: "Все заболевания",
    SS003021: "МКБ-10",
    SS003022: "Описание нозологической формы/расшифровка кода МКБ-10",
    SS003023: "Дата установки",
    SS003024: "Врач",
    SS003025: "Удалить",
    SS003026: "Консилиумы",
    SS003027: "Все консилиумы",
    SS003028: "МКБ-10",
    SS003029: "Место проведения",
    SS003030: "Дата проведения",
    SS003031: "Перейти к заявке",
    SS003032: "Планы лечения",
    SS003033: "Добавить план лечения",
    SS003034: "Все планы лечения",
    SS003035: "У пациента нет ни одного Плана лечения",
    SS003036: "Перейти к протоколу",
    SS003039: "Редактирование данных пациента",
    SS003067: "Информация о заболевании",
    SS003068: "Информация о заболевании",
    SS003069: "Установлено",
    SS003070: "Не выбрано",
    //Не используются, так как берутся из справочника
    SS003071: "Активно (впервые)",
    SS003072: "Повторно",
    SS003073: "Рецидив",
    SS003074: "Неактивно",
    SS003075: "Ремиссия",
    SS003076: "Излечено",
    //-----
    //Не используются, так как отсутствуют на экране
    SS003077: "Состояние подтверждения",
    SS003078: "Не выбрано",
    SS003079: "Подозреваемое (предварительное)",
    SS003080: "Подтвержденное",
    SS003081: "Опровергнутое",
    SS003082: "Ошибочное",
    SS003083: "Характер заболевания",
    SS003084: "Не выбрано",
    SS003085: "Острое",
    SS003086: "Хроническое",
    SS003087: "Дата постановки",
    SS003088: "Установивший врач",
    SS003089: "Код МКБ-10",
    SS003090: "Код МКБ-10 или название",
    SS003091: "Локализация",
    SS003092: "Локализация",
    SS003093: "Уточнение",
    SS003094: "Гистологический тип и код по МКБ-О",
    SS003095: "Гистологический тип",
    SS003096: "Прогностические факторы",
    SS003097: "Тип классификации",
    SS003098: "Тип классификации",
    SS003099: "Описание нозологической формы",
    SS003100: "Сгенерировать описание",
    SS003101: "Комментарий",
    SS003102: "Сохранить",
    SS003103: "Отмена",
    SS003104: "Новое заболевание",
    SS003105: "Информация о заболевании",
    SS003106: "Установлено",
    SS003107: "Не выбрано",
    SS003108: "Активно (впервые)",
    SS003109: "Повторно",
    SS003110: "Рецидив",
    SS003111: "Неактивно",
    SS003112: "Ремиссия",
    SS003113: "Излечено",
    SS003114: "Состояние подтверждения",
    SS003115: "Не выбрано",
    SS003116: "Подозреваемое (предварительное)",
    SS003117: "Подтвержденное",
    SS003118: "Опровергнутое",
    SS003119: "Ошибочное",
    SS003120: "Характер заболевания",
    SS003121: "Не выбрано",
    SS003122: "Острое",
    SS003123: "Хроническое",
    SS003124: "Дата установки",
    SS003125: "Установивший врач",
    SS003126: "Код МКБ-10",
    SS003127: "Код МКБ-10 или название",
    SS003128: "Локализация",
    SS003129: "Локализация",
    SS003130: "Уточнение",
    SS003133: "Прогностические факторы",
    SS003134: "Тип классификации",
    SS003135: "Тип классификации",
    SS003136: "Описание нозологической формы",
    SS003137: "Сгенерировать описание",
    SS003138: "Комментарий",
    SS003139: "Сохранить",
    SS003140: "Отмена",
    SS003141: "Гистологический тип и код по МКБ-О",
    SS003142: "Гистологический тип",
    SS003143: "Группа ЗНО",
    SS003144: "Группа ЗНО",
    SS003145: "T - Первичная опухоль",
    SS003146: "Значение",
    SS003147: "N - Регионарные лимфатические узлы",
    SS003148: "Значение",
    SS003149: "M - Отдаленные метастазы",
    SS003150: "Значение",
    SS003151: "G - Гистологическая градация",
    SS003152: "Значение",
    SS003153: "Анатомическое стадирование",
    SS003154: "Стадия не определена",
    SS003155: "Дополнительные классификаторы и биомаркеры",
    SS003156: "#Название Маркера#",
    SS003157: "Значение",
    SS003158: "#Варианты значений Маркера#",
    SS003159: "#Дополняющие оси основных классификаций#",
    SS003160: "Заболевания",
    SS003161: "Установлено:",
    SS003162: "Врач:",
    SS003163: "Выберите заболевание в списке для его просмотра или редактирования",
    SS004001: "Анамнез жизни",
    SS004002: "Заполните анамнез жизни",
    SS004003: "Семейный анамнез",
    SS004004: "Заполните семейный анамнез",
    SS004005: "Акушерско-гинекологический анамнез",
    SS004006: "Заполните акушерско-гинекологический анамнез",
    SS004007: "Аллергии/Непереносимости",
    SS004008: "Аллерген",
    SS004009: "Тип реакции",
    SS004010: "Принимаемые препараты",
    SS004011: "Препарат",
    SS004012: "Порядок приема",
    SS004013: "Добавить/редактировать аллергии/непереносимости",
    SS004014: "Добавить/редактировать принимаемые препараты",
    SS004015: "Добавить аллерген",
    SS004016: "Сохранить",
    SS004017: "Добавить МН",
    SS004018: "Сохранить",
    SS004019: "Аллерген",
    SS004020: "Тип реакции",
    SS004021: "Препарат",
    SS004022: "Порядок приема",
    SS005001: "Дата",
    SS005002: "С",
    SS005003: "По",
    SS005004: "Событие",
    SS005005: "Все",
    SS005006: "",
    SS005020: "Организация",
    SS005021: "Все",
    SS005022: "Дата",
    SS005023: "Событие",
    SS005024: "",
    SS005038: "Организация",
    SS005039: "Описание",
    SS005040: "Документы",
    SS006001: "Загрузка",
    SS006002: "Оценка состояния",
    SS006003: "Хорошо",
    SS006004: "Нормально",
    SS006005: "Плохо",
    SS006006: "Ужасно",
    SS006007: "ПН",
    SS006008: "ВТ",
    SS006009: "СР",
    SS006010: "ЧТ",
    SS006011: "ПТ",
    SS006012: "СБ",
    SS006013: "ВС",
    SS006014: "Сегодня",
    //Справочники
    SS006015: "Масса тела (кг)",
    SS006016: "Артериальное давление (мм рт ст/мм рт ст)",
    SS006017: "Уровень глюкозы (ммоль/л)",
    SS006018: "Жар/озноб (°C)",
    SS006019: "Усталость/слабость",
    SS006020: "Тошнота",
    SS006021: "Рвота",
    SS006022: "Стоматит",
    SS006023: "Изменение стула",
    SS006024: "Запор",
    SS006025: "Диарея",
    SS006026: "Потеря аппетита",
    SS006027: "Боль/затруднение при глотании",
    SS006028: "Аллергическая реакция",
    SS006029: "Чесотка и сыпь",
    SS006030: "Одышка",
    SS006031: "Кашель",
    SS006032: "Боль в мышцах и суставах",
    SS006033: "Онемение или покалывание в руках/ногах",
    SS006034: "Отек рук или ног",
    //Конец справочника
    SS007001: "Добавить",
    SS007002: "Документы не найдены",
    SS007003: "Создание документа",
    SS007004: "Наименование документа",
    SS007005: "Новый документ",
    SS007006: "Список вложений",
    SS007007: "Добавить документ",
    SS007008: "Допустимые форматы: ",
    SS007009: "Дата выдачи документа",
    SS007010: "Тип документа",
    SS007011: "",
    SS007025: "Комментарий",
    SS007026: "Организация, выдавшая документ",
    SS007027: "Авторы документа",
    SS007028: "Добавить автора",
    SS007029: "Удалить",
    SS007030: "Сохранить",
    SS007031: "Отменить",
    SS007032: "Наименование документа",
    SS007033: "Список вложений",
    SS007034: "Дата выдачи документа",
    SS007035: "Тип документа",
    SS007036: "Комментарий",
    SS007037: "Организация выдавшая документ",
    SS007050: "Наименование организации",
    SS007051: "Иная",
    SS007052: "Внимание!",
    SS007053: "Вы начали редактировать документ. Выйти без сохранения?",
    SS007054: "Да",
    SS007055: "Нет",
    SS007056: "Документ не может быть сохранен, пожалуйста, заполните обязательные поля:",
    SS007057: "Список вложений",
    SS007058: "Тип документа",
    SS007059: "Организация, выдавшая документ",
    SS003200: "Создать заявку",
    SS003201: "Информация о заболевании",
    SS003202: "МКБ-10",
    SS003203: "Код МКБ-10 или название",
    SS003204: "поля, обязательные для заполнения",
    SS003205: "Сохранить",
    SS003206: "Отмена",
    SS003207: "Не указан МКБ-10",
    SS003208: "Клиническое описание диагноза",
    SS003209: "Сгенерировать описание",
    SS003210: "Установлено",
    SS003211: "Не выбрано",
    SS003212: "Активно (впервые)",
    SS003213: "Повторно",
    SS003214: "Рецидив",
    SS003215: "Неактивно",
    SS003216: "Ремиссия",
    SS003217: "Излечено",
    SS003218: "Статус диагноза",
    SS003219: "Не выбрано",
    SS003220: "Предварительный",
    SS003221: "Подтвержденный",
    SS003222: "Установивший врач",
    SS003223: "Дополнительная информация",
    SS003224: "Комментарий",
    SS003225: "Не указано клиническое описание диагноза {mkb10}: {name}",
    SS003226: "Метод подтверждения диагноза",
    SS003227: "Нет доступных значений",
    SS003228: "Дата постановки",
    SS003229: "Сторона поражения",
    SS003230: "Нет доступных значений",
    SS003231: "Информация о заболевании",
    SS003232: "",
    SS002052: "По вашему запросу не найдено ни одного пациента",
    SS002053: "Добавить пациента",
    SS002054: "Добавление пациента",
    SS002055: "Фамилия",
    SS002056: "Имя",
    SS002057: "Отчество",
    SS002058: "Дата рождения",
    SS002059: "Пол",
    SS002060: "Пол",
    SS002061: "Укажите пол пациента",
    SS002062: "Мужской",
    SS002063: "Женский",
    SS002064: "СНИЛС",
    SS002065: "Тип полиса ОМС",
    SS002066: "Тип полиса ОМС",
    SS002067: "Укажите тип полиса ОМС",
    SS002071: "Номер полиса ОМС",
    SS002072: "Электронная почта",
    SS002073: "Телефон",
    SS002074: "Инвалидность",
    SS002075: "Инвалидность",
    SS002076: "Укажите инвалидность",
    SS002077: "Нет инвалидности",
    SS002078: "1 группа",
    SS002079: "2 группа",
    SS002080: "3 группа",
    SS002081: "Ребёнок-инвалид",
    SS002082: "Сохранить",
    SS002083: "Адрес места жительства",
    SS002084: "Адрес места жительства",
    SS003197: "Создать заявку",
    SS003198: "Нет записей",
    SS003199: "Нет записей",
    SS006050: "Пациент не дал согласия на предоставление сведений из дневника самонаблюдения",
    SS008001: "Управление ролями",
    SS008002: "Администратор организации",
    SS008003: "Лечащий врач",
    SS008004: "Председатель консилиума",
    SS008005: "Секретарь консилиума",
    SS008006: "Эксперт",
    SS008007: "Добавить роль",
    SS008008: "Выберите роль для настройки прав доступа",
    SS008009: "Название роли",
    SS008010: "Введите название",
    SS008011: "Идентификатор",
    SS008012: "Введите идентификатор",
    SS008013: "Разрешить",
    SS008014: "Запретить",
    SS008015: "Фильтровать по названию",
    SS008016: "Показать только отмеченные",
    SS008017: "Выбрать все",
    SS008018: "Аннулирование протокола",
    SS008019: "Архивирование своих Шаблонов лечения",
    SS008020: "Архивирование Шаблонов лечения",
    SS008021: "Внесение комментария/особого мнения по протоколу консилиума",
    SS008022: "Добавление новой команды/состава участников",
    SS008023: "Добавление/редактирование медикаментозных назначений",
    SS008024: "Доступность вкладки Документы",
    SS008025: "Доступность вкладки Заявки",
    SS008026: "Доступность вкладки Консилиумы",
    SS008027: "Доступность вкладки Организации",
    SS008028: "Доступность вкладки Планы лечения",
    SS008029: "Доступность вкладки Протоколы ОК",
    SS008030: "Доступность вкладки Рабочий стол",
    SS008031: "Доступность вкладки Сотрудники",
    SS008032: "Доступность вкладки Шаблоны лечения",
    SS008033: "Доступность страницы \"Пациенты\" по кнопке \"Выбор пациента\"",
    SS008034: "Заполнение вкладки Сводка по дням",
    SS008035: "Заполнение вкладки Статус лечения",
    SS008036: "Заполнение вкладки Статус лечения только у своего Цикла лечения",
    SS008037: "Заполнение Даты начала и продолжительности цикла",
    SS008038: "Заполнение Наименование цикла",
    SS008039: "Заполнение текущего состояния пациента",
    SS008040: "Запуск презентации в заявке на ОК",
    SS008041: "Изменение статуса заявки на \"Одобрена\", все заявки",
    SS008042: "Изменение статуса заявки на \"Одобрена\", свои заявки",
    SS008043: "Изменение статуса заявки на \"Отозвана\"",
    SS008044: "Изменение статуса заявки на \"Рассмотрена\" (доступность кнопки \"Вынести заключение\"), все",
    SS008045: "Изменение статуса заявки на \"Рассмотрена\" (доступность кнопки \"Вынести заключение\"), свои",
    SS008046: "Назначение эксперта на консилиум",
    SS008047: "Оставление комментария в заявке на вкладке \"Комментарий\", все",
    SS008048: "Оставление комментария в заявке на вкладке \"Комментарий\", только свои заявки",
    SS008049: "Отображение аватара в заголовке",
    SS008050: "Отображение панели действий в заголовке",
    SS008051: "Отображение формы UpToDate в заголовке",
    SS008052: "Перевод Плана лечения в статус Завершён",
    SS008053: "Перевод цикла в статус В работе",
    SS008054: "Перевод цикла в статус Завершён",
    SS008055: "Перевод цикла в статус Назначен",
    SS008056: "Перевод цикла в статус Отменён",
    SS008057: "Перевод цикла в статус Прерван",
    SS008058: "Перевод цикла в статус Приостановлен",
    SS008059: "Перевод цикла в статус Удалён",
    SS008060: "Переход в Цикл лечения из карты пациента",
    SS008061: "Печать протокола Плана лечения",
    SS008062: "Печать протокола Цикла лечения",
    SS008063: "Подтверждение протокола консилиума ЭЦП",
    SS008064: "Подтверждение разом всех МН",
    SS008065: "Получение электронной версии протокола",
    SS008066: "Получение электронной версии протокола (только для эксперта)",
    SS008067: "Прикрепление PDF со сканом протокола консилиума, все протоколы",
    SS008068: "Прикрепление PDF со сканом протокола консилиума, свои протоколы",
    SS008069: "Просмотр всех вкладок Цикла лечения в зависимости от статуса Цикла лечения",
    SS008070: "Просмотр всех заявок ОК куда приглашен и поиск заявки с помощью фильтров",
    SS008071: "Просмотр всех существующих заявок и поиск заявки с помощью фильтров",
    SS008072: "Просмотр всех существующих консилиумов",
    SS008073: "Просмотр заявки на ОК",
    SS008074: "Просмотр заявки на ОК куда приглашен как эксперт",
    SS008075: "Просмотр Заявок на ОК и Протоколов ОК в модальном окне",
    SS008076: "Просмотр заявок на ОК через интерфейс списка ОК (read only), все",
    SS008077: "Просмотр заявок на ОК через интерфейс списка ОК (read only), на консилиум которых приглашен как участник",
    SS008078: "Просмотр заявок на ОК через интерфейс списка ОК (read only), свои",
    SS008079: "Просмотр карты пациента",
    SS008080: "Просмотр консилиумов, на которые приглаше как участник (Свои)",
    SS008081: "Просмотр контента страницы \"Документы\"",
    SS008082: "Просмотр контента страницы \"Организации\"",
    SS008083: "Просмотр контента страницы \"Рабочий стол\"",
    SS008084: "Просмотр контента страницы \"Сотрудники\"",
    SS008085: "Просмотр медикаментозных назначений в модальном окне",
    SS008086: "Просмотр организации",
    SS008087: "Просмотр протокола консилиума",
    SS008088: "Просмотр сотрудника",
    SS008089: "Просмотр списка пациентов и поиск пациентов в списке с помощью фильтров",
    SS008090: "Просмотр списка пациентов и поиск пациентов в списке с помощью фильтров в разделе Планы лечения",
    SS008091: "Просмотр списка Планов лечения пациента",
    SS008092: "Просмотр списка протоколов консилиума",
    SS008093: "Просмотр списка своих протоколов/на которых отмечен как участник",
    SS008094: "Просмотр страницы команд/составов участников",
    SS008095: "Пункт в меню команд/составов участников",
    SS008096: "Редактирование вкладки Итоги лечения",
    SS008097: "Редактирование вкладки Итоги лечения только у своего Цикла лечения",
    SS008098: "Редактирование заявки на ОК",
    SS008099: "Редактирование заявки на ОК",
    SS008100: "Редактирование карты пациента",
    SS008101: "Редактирование консилиума",
    SS008102: "Редактирование организации",
    SS008103: "Редактирование параметров своих Шаблонов лечения в разделе Шаблоны лечения",
    SS008104: "Редактирование параметров Шаблонов лечения в разделе Шаблоны лечения",
    SS008105: "Редактирование поля Итоги на вкладке Итоги лечения",
    SS008106: "Редактирование протокола консилиума, все протоколы",
    SS008107: "Редактирование протокола консилиума, куда приглашен",
    SS008108: "Редактирование протокола консилиума, свой протокол",
    SS008109: "Редактирование сотрудника",
    SS008110: "Создание диагноза в разделе Планы лечения пациента",
    SS008111: "Создание/копирование Циклов лечения в Плане лечения",
    SS008112: "Создание нового и редактирование консилиума",
    SS008113: "Создание нового пациента",
    SS008114: "Создание новой заявки",
    SS008115: "Создание организации",
    SS008116: "Создание Плана лечения",
    SS008117: "Создание Плана лечения из карты пациента",
    SS008118: "Создание сотрудника",
    SS008119: "Сохранение Плана лечения в Шаблон",
    SS008120: "Сохранение своего Плана лечения в Шаблон",
    SS008121: "Удаление заявок на ОК, все",
    SS008122: "Удаление заявок на ОК, свои",
    SS008123: "Удаление консилиума",
    SS008124: "Удаление организации",
    SS008125: "Удаление Плана лечения",
    SS008126: "Удаление своих Шаблонов лечения",
    SS008127: "Удаление сотрудника",
    SS008128: "Удаление Шаблонов лечения",
    SS008129: "Управление ролями пользователей",
    SS008130: "Управление ролями пользователей (пункт меню)",
    SS008131: "Формирование протокола консилиума",
    SS008132: "Создать",
    SS008133: "Отменить",
    SS008134: "Идентификатор должен состоять только из строчных латинских букв, знака подчеркивания и дефиса",
    SS008135: "Создать копию",
    SS008136: "Удалить",
    SS008137: "Внимание!",
    SS008138: "Вы действительно хотите удалить роль {role}?",
    SS008139: "Да",
    SS008140: "Нет",
    SS008141: "Роль ({role}) успешно удалена",
    SS008142: "Роль ({role}) успешно создана",
    SS008143: "Вы действительно хотите сделать копию роли {role}?",
    SS008144: "Копия {role1} роли ({role2}) успешно создана",
    SS009001: "Сотрудники",
    SS009002: "ФИО сотрудника",
    SS009003: "Логин",
    SS009004: "СНИЛС",
    SS009005: "Организация",
    SS009006: "Роль",
    SS009007: "Сбросить фильтры",
    SS009008: "ФИО сотрудника",
    SS009009: "Логин",
    SS009010: "СНИЛС",
    SS009011: "Организация",
    SS009012: "",
    SS009017: "Добавить сотрудника",
    SS009018: "Сотрудники",
    SS009019: "Добавление сотрудника",
    SS009020: "Личная информация",
    SS009021: "Логин",
    SS009022: "Пароль",
    SS009023: "Введите пароль",
    SS009024: "Активен",
    SS009025: "Фамилия",
    SS009026: "Имя",
    SS009027: "Отчество",
    SS009028: "Дата рождения",
    SS009029: "Пол",
    SS009030: "Мужской",
    SS009031: "Женский",
    SS009032: "СНИЛС",
    SS009033: "Личный телефон",
    SS009034: "Организации и должности",
    SS009035: "Добавить место работы Сотрудника",
    SS009036: "Удалить организацию",
    SS009037: "Организация",
    SS009038: "E-mail сотрудника",
    SS009039: "Телефон сотрудника",
    SS009040: "Должность",
    SS009041: "",
    SS009042: "Сохранить",
    SS009043: "",
    SS009044: "Удалить должность",
    SS009045: "Специализация",
    SS009046: "",
    SS009047: "АРМ роли",
    SS009048: "Обязательное поле",
    SS009049: "Замещающий сотрудник",
    SS009050: "Добавить замещающего сотрудника",
    SS009051: "История замещений",
    SS009052: "ФИО",
    SS009053: "Должность",
    SS009054: "Дата начала замещения",
    SS009055: "Дата окончания замещения",
    SS009056: "Логин должен быть валидным e-mail адресом",
    SS009057: "Укажите пароль, состоящий из 6 и более символов",
    SS009058: "СНИЛС имеет неверный формат (контрольная сумма не совпадает)",
    SS009059: "Информация сохранена",
    SS000001: "Отображать по",
    SS000002: "Первая страница",
    SS000003: "Предыдущая страница",
    SS000004: "Следующая страница",
    SS000005: "Последняя страница",
    SS009060: "Выбор замещающего сотрудника",
    SS009061: "Дата начала замещения",
    SS009062: "Дата окончания замещения",
    SS009063: "Выберите сотрудника",
    SS009064: "АРМ роли",
    SS009065: "ФИО",
    SS009066: "ФИО",
    SS009067: "Должность",
    SS009068: "Поиск",
    SS009069: "ФИО",
    SS009070: "Специализация",
    SS009071: "Должность",
    SS009072: "АРМ роли",
    SS009073: "Добавить",
    SS009074: "Отмена",
    SS009075: "АРМ роли:",
    SS009076: "Должность:",
    SS009077: "Выбранный сотрудник уже назначен заместителем на пересекающиеся даты. Выберите другого сотрудника или измените даты замещения",
    SS009079: "Укажите валидный e-mail адрес",
    SS009080: "При сохранении произошла ошибка",
    SS009081: "Логин пользователя не указан",
    SS009082: "Фамилия не указана",
    SS009083: "Имя не указано",
    SS009084: "Дата рождения не указана",
    SS009085: "Пол не указан",
    SS009086: "Укажите номер телефона",
    SS009087: "Укажите хотя бы одну организацию",
    SS009088: "Укажите хотя бы одну должность в каждой организации",
    SS009089: "Укажите APM роль",
    SS009091: "Редактирование сотрудника",
    SS010001: "Организации",
    SS010002: "Наименование",
    SS010003: "Адрес",
    SS010004: "Сбросить фильтры",
    SS010005: "Наименование",
    SS010006: "Адрес",
    SS010007: "Добавить организацию",
    SS010008: "Организации",
    SS010009: "Добавление организации",
    SS010010: "Основная информация",
    SS010011: "Наименование",
    SS010012: "Полное наименование",
    SS010013: "Наименование для вывода на печать",
    SS010014: "Тип",
    SS010015: "Организация",
    SS010016: "Филиал, структурное подразделение",
    SS010017: "Функциональные роли организации",
    SS010018: "Направляет на ОК",
    SS010019: "Проводит ОК",
    SS010020: "Контактные данные",
    SS010021: "Адреса",
    SS010022: "Сохранить",
    SS010023: "Идентификаторы",
    SS010024: "Добавить идентификатор",
    SS010025: "Тип",
    SS010026: "Значение",
    SS010027: "Координаты",
    SS010028: "Долгота",
    SS010029: "Широта",
    SS010030: "Наименование не указано",
    SS010031: "Полное наименование не указано",
    SS010032: "Наименование для вывода на печать не указано",
    SS010033: "Организация сохранена",
    SS010034: "Добавление контакта",
    SS010035: "Тип",
    SS010036: "телефон",
    SS010037: "факс",
    SS010038: "e-mail",
    SS010039: "пейджер",
    SS010040: "URL",
    SS010041: "СМС",
    SS010042: "другое",
    SS010043: "Значение",
    SS010044: "Добавить",
    SS010045: "Введите адрес",
    SS010046: "Тип",
    SS010047: "домашний",
    SS010048: "рабочий",
    SS010049: "временный",
    SS010050: "устаревший",
    SS010051: "для корреспонденции",
    SS010052: "Назначение",
    SS010053: "почтовый и физический",
    SS010054: "почтовый",
    SS010055: "физический",
    SS010056: "Почтовый индекс",
    SS010057: "Страна",
    SS010058: "Россия",
    SS010059: "Регион",
    SS010060: "Район",
    SS010061: "Населенный пункт",
    SS010062: "Адрес",
    SS010063: "Добавить",
    SS010064: "Укажите адрес",
    SS010065: "Укажите населенный пункт",
    SS010066: "Укажите регион",
    SS010067: "Укажите страну",
    SS010068: "Организации",
    SS010069: "Редактирование организации",
    SS011001: "Консилиумы",
    SS011002: "ФИО пациента",
    SS011003: "Название консилиума",
    SS011004: "Код или название заболевания",
    SS011005: "Список пуст…",
    SS011006: "Медицинская организация",
    SS011007: "Сбросить фильтры",
    SS011008: "На эту дату не запланировано ни одного консилиума",
    SS011009: "Сформировать консилиум",
    SS011010: "Свободное место",
    SS011011: "Показать еще…",
    SS011012: "Ориентировочное время рассмотрения",
    SS011013: "",
    SS011014: "Все консилиумы",
    SS011015: "Формирование консилиума",
    SS011016: "Сохранить",
    SS011017: "Основная информация",
    SS011018: "Название консилиума",
    SS011019: "Введите название консилиума",
    SS011020: "Дата проведения",
    SS011021: "Период проведения",
    SS011022: "Дата проведения",
    SS011023: "Место проведения",
    SS011024: "Организация",
    SS011025: "ЦАОП",
    SS011026: "Время проведения",
    SS011027: "Количество мест",
    SS011028: "Ориентировочное время рассмотрения клинического случая ~ ",
    SS011029: "Медицинская организация",
    SS011030: "Детальный адрес",
    SS011031: "Особые требования",
    SS011032: "Заявки в данном консилиуме требуют согласования секретарем",
    SS011033: "Рассматриваемые заболевания",
    SS011034: "Добавить группу заболеваний",
    SS011035: "Команда консилиума",
    SS011036: "Команда участников",
    SS011037: "Председатель консилиума",
    SS011038: "Секретарь",
    SS011039: "Врач-онколог, специалист по хирургическому лечению",
    SS011040: "Врач-онколог, специалист по лекарственному лечению",
    SS011041: "Врач-радиотерапевт",
    SS011042: "Дополнительный участник",
    SS011043: "удалить место",
    SS011044: "Повестка ({count})",
    SS011045: "Количество мест:",
    SS011046: "Введите название консилиума",
    SS011047: "Укажите председателя консилиума",
    SS011048: "Укажите секретаря консилиума",
    SS011049: "Укажите cпециалиста по хирургическому лечению",
    SS011050: "Укажите cпециалиста по лекарственному лечению",
    SS011051: "Укажите cпециалиста по радиотерапевтическому лечению",
    SS011052: "Консилиум сохранен",
    SS011053: "Период и дни проведения",
    SS0110541: "пн",
    SS0110542: "вт",
    SS0110543: "ср",
    SS0110544: "чт",
    SS0110545: "пт",
    SS0110546: "сб",
    SS0110547: "вс",
    SS011055: "Выберите дни проведения",
    SS011056: "Добавление группы",
    SS011057: "Группа заболеваний",
    SS011058: "",
    SS011059: "Рассматриваемые заболевания:",
    SS011060: "",
    SS011061: "Контрольный список документов и исследований",
    SS011062: "",
    SS011063: "Добавить группу",
    SS011064: "Отменить",
    SS011065: "",
    SS011066: "Заболевания",
    SS011067: "",
    SS011068: "При выборе рубрики заболевания все подрубрики учитываются автоматически",
    SS011069: "Введите код по МКБ-10 или название заболевания",
    SS011070: "Добавить",
    SS011071: "Контрольный список документов и исследований",
    SS011072: "Список пуст…",
    SS011073: "Добавить",
    SS011074: "",
    SS011075: "Заболевание добавлено в список рассматриваемых на консилиуме",
    SS011076: "Заболевание удалено из рассматриваемых на консилиуме",
    SS011077: "Внимание!",
    SS011078: "Вы действительно хотите удалить заболевание {xxx} из списка рассматриваемых на консилиуме?",
    SS011079: "Да",
    SS011080: "Нет",
    SS011081: "Команды участников",
    SS011082: "Команды не найдены",
    SS011083: "Выбрать команду",
    SS011084: "Отменить",
    SS011085: "Добавление участников консилиума",
    SS011086: "Председатель консилиума",
    SS011087: "Секретарь",
    SS011088: "Врач-онколог, специалист по хирургическому лечению",
    SS011089: "Врач-онколог, специалист по лекарственному лечению",
    SS011090: "Врач-радиотерапевт",
    SS011091: "Дополнительный участник",
    SS011092: "Организация",
    SS011093: "Выберите организацию",
    SS011094: "НОВАЯ ОРГАНИЗАЦИЯ",
    SS011095: "ВЫБОР ОРГАНИЗАЦИИ",
    SS011096: "Сотрудник",
    SS011097: "Выберите сотрудника",
    SS011098: "НОВЫЙ СОТРУДНИК",
    SS011099: "ВЫБОР СОТРУДНИКА",
    SS011100: "Должность",
    SS011101: "Выберите должность сотрудника",
    SS011102: "НОВАЯ ДОЛЖНОСТЬ",
    SS011103: "ВЫБОР ДОЛЖНОСТИ",
    SS011104: "",
    SS011105: "Специализация",
    SS011106: "Специализация",
    SS011107: "",
    SS011108: "Дистанционное присутствие",
    SS011109: "Добавить участника",
    SS011110: "Очистить",
    SS011111: "Закрыть",
    SS011112: "",
    SS011113: "",
    SS011114: "",
    SS011115: "",
    SS011116: "Дистанционное присутствие",
    SS011117: "Редактировать",
    SS011118: "Удалить",
    SS011119: "Внимание!",
    SS011120: "Вы действительно хотите удалить участника консилиума?",
    SS011121: "Да",
    SS011122: "Нет",
    SS011123: "Все консилиумы",
    SS011124: "Актуализация консилиума",
    SS011125: "Создать копию",
    SS011126: "Сохранить",
    SS011127: "",
    SS011128: "Копия консилиума",
    SS011129: "Укажите дату проведения консилиума",
    SS011130: "Создать копию",
    SS011131: "Копия текущего консилиума успешно создана",
    SS011132: "Отменить консилиум",
    SS011133: "Внимание!",
    SS011134: "Удалить консилиум {xxx}?",
    SS011135: "Да",
    SS011136: "Нет",
    SS011137: "Консилиум удален",
    SS011138: "Председатель консилиума",
    SS011139: "Секретарь",
    SS011140: "Врач-онколог, специалист по хирургическому лечению",
    SS011141: "Врач-онколог, специалист по лекарственному лечению",
    SS011142: "Врач-радиотерапевт",
    SS011143: "Дополнительный участник",
    SS012001: "Участники консилиума",
    SS012002: "Статус",
    SS012003: "Актуальная",
    SS012004: "Аннулированная",
    SS012005: "ФИО участника",
    SS012006: "дд.мм.гггг",
    SS012007: "Сначала новые",
    SS012008: "Сначала старые",
    SS012009: "Сбросить фильтры",
    SS012010: "Команды не найдены",
    SS012011: "Сформировать команду",
    SS012012: "Председатель консилиума",
    SS012013: "Секретарь",
    SS012014: "Врач-онколог, специалист по хирургическому лечению",
    SS012015: "Врач-онколог, специалист по лекарственному лечению",
    SS012016: "Врач-радиотерапевт",
    SS012017: "Дополнительный участник",
    SS012018: "",
    SS012019: "",
    SS012020: "Актуальная",
    SS012021: "Аннулированная",
    SS012022: "Создать копию",
    SS012023: "Аннулировать",
    SS012024: "Внимание!",
    SS012025: "Копировать состав участников консилиума от {date}?",
    SS012026: "Да",
    SS012027: "Нет",
    SS012028: "Команда успешно скопирована",
    SS012029: "Внимание!",
    SS012030: "Аннулировать состав участников консилиума {tumorBoardName} от {date}?",
    SS012031: "Да",
    SS012032: "Нет",
    SS012033: "Команда успешно аннулирована",
    SS012034: "Участники консилиума",
    SS012035: "Формирование команды",
    SS012036: "Дата",
    SS012037: "Номер приказа",
    SS012038: "Добавить скан приказа",
    SS012039: "Сохранить",
    SS012040: "Председатель консилиума",
    SS012041: "Секретарь",
    SS012042: "Врач-онколог, специалист по хирургическому лечению",
    SS012043: "Врач-онколог, специалист по лекарственному лечению",
    SS012044: "Врач-радиотерапевт",
    SS012045: "Дополнительный участник",
    SS012046: "удалить место",
    SS012047: "Председатель консилиума",
    SS012048: "Секретарь",
    SS012049: "Врач-онколог, специалист по хирургическому лечению",
    SS012050: "Врач-онколог, специалист по лекарственному лечению",
    SS012051: "Врач-радиотерапевт",
    SS012052: "Дополнительный участник",
    SS012053: "Организация",
    SS012054: "Выберите организацию",
    SS012055: "НОВАЯ ОРГАНИЗАЦИЯ",
    SS012056: "ВЫБОР ОРГАНИЗАЦИИ",
    SS012057: "Сотрудник",
    SS012058: "Выберите сотрудника",
    SS012059: "НОВЫЙ СОТРУДНИК",
    SS012060: "ВЫБОР СОТРУДНИКА",
    SS012061: "Должность",
    SS012062: "Выберите должность сотрудника",
    SS012063: "НОВАЯ ДОЛЖНОСТЬ",
    SS012064: "ВЫБОР ДОЛЖНОСТИ",
    SS012065: "",
    SS012066: "Специализация",
    SS012067: "Специализация",
    SS012068: "",
    SS012069: "Дистанционное присутствие",
    SS012070: "Сохранить",
    SS012071: "Очистить",
    SS012072: "Удалить",
    SS012073: "Отмена",
    SS012074: "Укажите председателя консилиума",
    SS012075: "Укажите секретаря консилиума",
    SS012076: "Укажите cпециалиста по хирургическому лечению",
    SS012077: "Укажите cпециалиста по лекарственному лечению",
    SS012078: "Укажите cпециалиста по радиотерапевтическому лечению",

    SS012079: "Внимание!",
    SS012080: "Вы действительно хотите удалить информацию о сотруднике?",
    SS012081: "Да",
    SS012082: "Нет",
    SS012083: "Дистанционное присутствие",
    SS012084: "Редактировать",
    SS012085: "Удалить",
    SS012086: "Команда участников консилиума от {date} успешно аннулирована",
    SS012087: "Команда участников консилиума успешно сохранена",

    SS013001: "Документы",
    SS013002: "Поиск по названию документа",
    SS013003: "Вложения:",
    SS013004: "отсутствуют",
    SS013005: "Наименование документа",
    SS013006: "Новый документ",
    SS013007: "Список вложений",
    SS013008: "Добавить",
    SS013009: "Поддерживаемые форматы файлов: bmp, gif, tiff, jpeg, png, pdf, doc, docx",
    SS013010: "Дата выдачи документа",
    SS013011: "Тип документа",
    SS013012: "",
    SS013013: "Комментарий",
    SS013014: "Организация выдавшая документ",
    SS013015: "Авторы документа",
    SS013016: "Добавить",
    SS013017: "Сохранить",
    SS013018: "Удалить",
    SS000006: "Ошибка",
    SS000007: "Ой, что-то пошло не так. Повторите попытку позже.",
    SS000008: "Загрузка",
    SS014001: "Заявки",
    SS014002: "ФИО пациента",
    SS014003: "Статус заявки",
    SS014004: "В обработке",
    SS014005: "Создана",
    SS014006: "На согласовании",
    SS014007: "Готова для рассмотрения",
    SS014008: "Возвращена на доработку",
    SS014009: "Аннулирована",
    SS014010: "Удалена",
    SS014011: "Рассмотрена",
    SS014012: "Код или название заболевания",
    SS014013: "Список пуст…",
    SS014014: "Проводящая медицинская организация",
    SS014015: "Сбросить фильтры",
    SS014016: "На эту дату не запланировано ни одной заявки",
    SS014017: "Создать заявку",
    SS014018: "ФИО пациента",
    SS014019: "Дата создания",
    SS014020: "Дата проведения",
    SS014021: "Проводящая медицинская организация",
    SS014022: "Статус заявки",
    SS014023: "лет",
    SS014024: "год",
    SS014025: "года",
    SS014026: "Мужской",
    SS014027: "Женский",
    SS014028: "Консилиум прошел",
    SS014029: "{number} дней",
    SS014030: "{number} дня",
    SS014031: "{number} день",
    SS014032: "В обработке",
    SS014033: "Создана",
    SS014034: "На согласовании",
    SS014035: "Готова для рассмотрения",
    SS014036: "Возвращена на доработку",
    SS014037: "Аннулирована",
    SS014038: "Удалена",
    SS014039: "Завершена",
    SS014040: "Заявки",
    SS014041: "Выберите пациентов для направления на онкоконсилиум",
    SS014042: "ФИО пациента",
    SS014043: "Код или название заболевания",
    SS014044: "Сбросить фильтры",
    SS014045: "Выбранные пациенты",
    SS014046: "ФИО",
    SS014047: "Возраст",
    SS014048: "Пол",
    SS014049: "Диагноз",
    SS014050: "лет",
    SS014051: "год",
    SS014052: "года",
    SS014053: "Мужчина",
    SS014054: "Женщина",
    SS014055: "Подбор консилиума",
    SS014056: "Заявки",
    SS014057: "Выберите консилиум - {info}",
    SS014058: "муж",
    SS014059: "жен",
    SS014060: "лет",
    SS014061: "год",
    SS014062: "года",
    SS014063: "Не распределен",
    SS014064: "Эксперт",
    SS014065: "Медицинская организация",
    SS014066: "Показать заполненные",
    SS014067: "Сортировать по дате",
    SS014068: "Ничего не найдено. Попробуйте изменить условия поиска",
    SS014069: "Создать заявку",
    SS011144: "Не удалось отправить приглашение новым участникам консилиума",
    SS014071: "",
    SS014072: "План обследования",
    SS014073: "",
    SS014074: "",
    SS014075: "Всего заявок",
    SS014076: "Мои заявки",
    SS014077: "Свободные слоты для подачи заявки",
    SS014078: "Создать заявку",
    SS014079: "Выберите время",
    SS014080: "с {time1} по {time2}",
    SS014081: "Выбрать",
    SS014082: "Председатель",
    SS014083: "Секретарь",
    SS014084: "",
    SS014085: "",
    SS014086: "выберите время",
    SS014087: "Укажите время, необходимо закончить распределение текущей заявки",
    SS014088: "Заявка на пациента \"{text}\" создана",
    SS014089: "Заявки",
    SS014090: "Заявка №{number} - {param}",
    SS014091: "Карта Пациента",
    SS014092: "лет",
    SS014093: "год",
    SS014094: "года",
    SS014095: "мужчина",
    SS014096: "женщина",
    SS014097: "Режим презентации",
    SS014098: "В обработке",
    SS014099: "Создана",
    SS014100: "На согласовании",
    SS014101: "Готова для рассмотрения",
    SS014102: "Возвращена на доработку",
    SS014103: "Аннулирована",
    SS014104: "Удалена",
    SS014105: "Завершена",
    SS014106: "ОМС",
    SS014107: "СНИЛС",
    SS014108: "Дата и время создания",
    SS014109: "Направившая медицинская организация",
    SS014110: "Лечащий врач",
    SS014111: "Дата проведения",
    SS014112: "Название консилиума",
    SS014113: "Дата рождения",
    SS014114: "Телефон",
    SS014115: "Проводящая медицинская организация",
    SS014116: "Цель проведения консилиума",
    SS014117: "Цель проведения консилиума",
    SS014118: "Общая информация",
    SS0141190: "Общая информация",
    SS014119: "Диагноз",
    SS014120: "Анамнез основного заболевания",
    SS014121: "Анамнез жизни",
    SS0141210: "Семейный анамнез",
    SS0141211: "Акушерско-гинекологический анамнез",
    SS0141212: "Аллергии и непереносимости",
    SS0141213: "Принимаемые препараты",
    SS014122: "Общее состояние",
    SS014123: "Диагностические исследования",
    SS014124: "Комментарии",
    SS014125: "Презентация",
    SS014126: "Сохранить",
    SS014127: "Удалить",
    SS014128: "Заявка готова",
    SS014129: "Основное заболевание",
    SS014130: "",
    SS014131: "Установлено",
    SS014132: "Онколог",
    SS014133: "Конкурирующие заболевания",
    SS014134: "Добавить конкурирующее заболевание",
    SS014135: "Сведения отсутствуют",
    SS014136: "Осложнения заболевания",
    SS014137: "Добавить осложнение основного заболевания",
    SS014138: "Сведения отсутствуют",
    SS014139: "Сопутствующие заболевания",
    SS014140: "Добавить сопутствующее заболевание",
    SS014141: "Сведения отсутствуют",
    SS014142: "",
    SS014143: "",
    SS014144: "",
    SS014145: "Конкурирующие заболевания",
    SS014146: "Осложнения заболевания",
    SS014147: "Сопутствующие заболевания",
    SS014148: "Новая запись о заболевании",
    SS014149: "Для добавления нового заболевания произведите поиск по коду или наименованию",
    SS014150: "Список пуст…",
    SS014151: "Известные заболевания пациента",
    SS014152: "",
    SS014153: "Добавить заболевание",
    SS014154: "Внимание!",
    SS014155: "Вы уверены что хотите удалить заболевание «{code} / {text}» ?",
    SS014156: "Да",
    SS014157: "Нет",
    SS014158: "Заболевание удалено",
    SS014159: "Редактирование заболевания",
    SS014160: "",
    SS014161: "",
    SS014162: "Редактирование заболевания",
    SS014163: "Метод подтверждения диагноза",
    SS014164: "",
    SS014165: "Сторона поражения",
    SS014166: "Слева",
    SS014167: "Справа",
    SS014168: "Двусторонняя",
    SS014169: "Неприменимо",
    SS014170: "Морфологический тип опухоли",
    SS014171: "Введите значение для поиска",
    SS014172: "Клиническая стадия cTNM",
    SS014173: "T - Первичная опухоль",
    SS014174: "N - Регионарные лимфатические узлы",
    SS014175: "M - Отдаленные метастазы",
    SS014176: "Стадия",
    SS014177: "неизвестно",
    SS014178: "неприменимо",
    SS014179: "Патологоанатомическая стадия pTNM",
    SS014180: "T - Первичная опухоль",
    SS014181: "N - Регионарные лимфатические узлы",
    SS014182: "M - Отдаленные метастазы",
    SS014183: "Стадия",
    SS014184: "неизвестно",
    SS014185: "неприменимо",
    SS014186: "G - Гистологическая градация",
    SS014187: "L - Лимфатическая инвазия",
    SS014188: "V - Венозная инвазия",
    SS014189: "Pn - Периневральная инвазия",
    SS014190: "R - Остаточная опухоль",
    SS014191: "Локализация отдаленных метастазов",
    SS014192: "{ссылка на справочник}",
    SS014193: "Результаты иммуногистохимических исследований",
    SS014194: "Добавить исследование",
    SS014195: "Номер исследования",
    SS014196: "Дата исследования",
    SS014197: "Медицинская организация",
    SS014198: "ВЫБОР ОРГАНИЗАЦИИ",
    SS014199: "Удалить исследование",
    SS014200: "Показатель",
    SS014201: "Добавить показатель",
    SS014202: "Результат",
    SS014203: "",
    SS014204: "",
    SS014205: "",
    SS014206: "Результаты молекулярно-генетических исследований",
    SS014207: "Добавить исследование",
    SS014208: "",
    SS014209: "",
    SS014210: "Заболевание сохранено",
    SS014211: "Анамнез",
    SS014212: "История лечения",
    SS014213: "Добавить запись",
    SS014214: "История лечения отсутствует",
    SS014215: "Добавление записи анамнеза",
    SS014216: "Дата",
    SS014217: "Событие",
    SS014218: "",
    SS014219: "Описание",
    SS014220: "Введите описание результата",
    SS014221: "Медицинская организация",
    SS014222: "Документы",
    SS014223: "Добавить документ",
    SS014224: "Сохранить",
    SS014225: "Отменить",
    SS014226: "Редактирование документа",
    SS014227: "",
    SS014228: "Поле \"Дата\" обязательно к заполнению",
    SS014229: "Поле \"Событие\" обязательно к заполнению",
    SS014230: "Поле \"Описание\" обязательно к заполнению",
    SS014231: "Дата",
    SS014232: "Событие",
    SS014233: "",
    SS014234: "Описание",
    SS014235: "Медицинская организация",
    SS014236: "Документы",
    SS014237: "Анамнез жизни",
    SS0142370: "Анамнез жизни не заполнен",
    SS014238: "Опишите анамнез жизни",
    SS014239: "Семейный анамнез",
    SS0142390: "Семейный анамнез не заполнен",
    SS014240: "Опишите семейный анамнез",
    SS014241: "Акушерско-гинекологический анамнез",
    SS0142410: "Акушерско-гинекологический анамнез не заполнен",
    SS014242: "Опишите акушерско-гинекологический анамнез",
    SS014243: "Аллергии и непереносимости",
    SS014244: "Аллерген",
    SS014245: "",
    SS014246: "Тип реакции",
    SS014247: "",
    SS014248: "",
    SS014249: "",
    SS014250: "Сохранить",
    SS014251: "Принимаемые препараты",
    SS014252: "Препарат",
    SS014253: "",
    SS014254: "Порядок приема",
    SS014255: "",
    SS014256: "Сохранить",
    SS014257: "Общее состояние",
    SS014258: "Текущее состояние",
    SS014259: "Дата",
    SS014260: "Показатель",
    SS014261: "Результат",
    SS014262: "Единица измерения",
    SS014263: "",
    SS011145: "Внимание!",
    SS011146: "Какие консилиумы изменить?",
    SS011147: "Только этот",
    SS011148: "Этот и все последующие",
    SS011149: "Все",
    SS014264: "План обследования",
    SS014265: "Добавить пункт",
    SS014266: "Печать",
    SS014267: "",
    SS014268: "Необходимо загрузить документы согласно чек-листу",
    SS014269: "План обследования перед онкоконсилиумом",
    SS014270: "Выберите пункт плана обследования в списке слева для его просмотра или редактирования",
    SS014271: "Добавить документ",
    SS014272: "Новый документ",
    SS014273: "Отправить как задачу пациенту",
    SS014274: "Редактирование документа",
    SS014275: "",
    SS014276: "Добавить из мобильного приложения",
    SS014277: "QR-код для загрузки вложений из мобильного приложения",
    SS014278: "Откройте мобильное приложение и отсканируйте QR-код",
    SS014279: "Загрузите файлы в мобильном приложении",
    SS014280: "По окончании загрузки закройте окно. Новые файлы отобразятся в списке вложений",
    SS014281: "Задача: {text}",
    SS014282: "Комментарий для пациента",
    SS014283: "Предоставить до",
    SS014284: "Отправить задачу",
    SS014285: "Необходимо указать дату",
    SS014286: "Задача отправлена пациенту. Срок исполнения до {date}, Вам придет уведомление когда он отправит ответ.",
    SS014287: "Нажмите здесь чтобы изменить или отменить задачу.",
    SS014288: "Задача: {text}",
    SS014289: "Ваш комментарий",
    SS014290: "Предоставить до",
    SS014291: "Отменить задачу",
    SS014292: "Отмена",
    SS014293: "Сохранить",
    SS014294: "Ошибка при изменении задачи",
    SS014295: "Ошибка при отмене задачи",
    SS014296: "Добавлен новый пункт",
    SS014297: "Наименование пункта",
    SS014298: "Добавленный пункт",
    SS014299: "Сохранить",
    SS014300: "Добавить документ",
    SS014301: "Пункт успешно переименован",
    SS014302: "Удалить",
    SS014303: "Пункт успешно удален",
    SS014304: "Распечатать",
    SS014305: "м",
    SS014306: "ж",
    SS014307: "?",
    SS014308: "лет",
    SS014309: "год",
    SS014310: "года",
    SS014311: "Консилиум запланирован на",
    SS014312: "",
    SS014313: "Комментарий",
    SS014314: "Введите текст",
    SS014315: "Добавить",
    SS014316: "",
    SS014317: "",
    SS014318: "Изменить статус",
    SS014319: "Статус пункта плана обследования был изменен",
    SS014320: "Слайды",
    SS014321: "Отображать слайд",
    SS014322: "Скрывать слайд",
    SS014323: "Диагноз/Анамнез основного заболевания/Общее состояние",
    SS0143241: "История лечения",
    SS0143242: "Текущее состояние",
    SS0143243: "Конкурирующие заболевания, осложнения и сопутствующие заболевания",
    SS014325: "Аллергии и принимаемые препараты",
    SS014326: "Документы",
    SS014327: "Произвольная информация/DICOM исследование",
    SS014328: "Добавить слайд",
    SS014329: "Слайд с текстом",
    SS014330: "Слайд с документами",
    SS014331: "Отобразить блок",
    SS014332: "На весь экран",
    SS014333: "Свернуть",
    SS014334: "Диагноз",
    SS014335: "",
    SS014336: "Тип:",
    SS014337: "метахронная",
    SS014338: "",
    SS014339: "Дата постановки:",
    SS014340: "",
    SS014341: "Анамнез основного заболевания",
    SS014342: "Общее состояние",
    SS014343: "Хронология лечения",
    SS014344: "Дата",
    SS014345: "Событие",
    SS014346: "Организация",
    SS014347: "Описание",
    SS014348: "Документ",
    SS014349: "Текущее состояние",
    SS014350: "",
    SS014351: "Конкурирующие заболевания, осложнения и сопутствующие заболевания",
    SS014352: "Осложнения",
    SS014353: "Сведения отсутствуют",
    SS014354: "",
    SS014355: "Сопутствующие заболевания",
    SS014356: "Сведения отсутствуют",
    SS014357: "",
    SS014358: "Конкурирующие заболевания",
    SS014359: "Сведения отсутствуют",
    SS014360: "",
    SS014361: "Анамнез общий",
    SS014362: "Анамнез жизни",
    SS014363: "Семейный анамнез",
    SS014364: "Акушерско-гинекологический анамнез",
    SS014365: "Аллергии и принимаемые препараты",
    SS014366: "Аллергии/непереносимости",
    SS014367: "",
    SS014368: "",
    SS014369: "Принимаемые препараты",
    SS014370: "",
    SS014371: "Редактирование документа",
    SS014372: "",
    SS014373: "Загрузка документа",
    SS014374: "Слайды",
    SS014375: "Диагности- ческие исследования",
    SS014376: "Рентгенологическое исследование",
    SS014377: "Прочие документы",
    SS014378: "Скрыть ФИО пациента",
    SS014379: "",
    SS014380: "Гистологический тип и код по МКБ-О",
    SS014381: "",
    SS014382: "Описание нозологической формы",
    SS014383: "",
    SS014384: "",
    SS014385: "",
    SS014386: "",
    SS014387: "Документ с {x1} но с пустым {x2}",
    SS014388: "",
    SS014389: "",
    SS014390: "Прочие документы",
    SS014391: "Документ с {x1} но с пустым {x2}",
    SS014392: "Прочие документы",
    SS014393: "",
    SS014394: "лет",
    SS014395: "год",
    SS014396: "года",
    SS014397: "муж",
    SS014398: "жен",
    SS014399: "Подтвердите действие на странице",
    SS014400: "Вы действительно хотите выйти из режима презентации?",
    SS014401: "ОК",
    SS014402: "Отмена",
    SS014403: "Заявка на пациента {x1} с заболеванием {x2} уже рассматривается на консилиуме '{x3}'",
    SS014404: "Заявка обновлена",
    SS014405: "Внимание!",
    SS014406: "Вы действительно хотите удалить заявку?",
    SS014407: "Да",
    SS014408: "Нет",
    SS014409: "Успешно удалено",
    SS0144101: "Вы не указали",
    SS0144102: " и ",
    SS0144103: "анамнез",
    SS0144104: "общее состояние",
    SS014411: "Внимание!",
    SS014412: "Вы уверены, что заявка подготовлена для ОК?",
    SS014413: "Да",
    SS014414: "Нет",
    SS014415: "Вернуть",
    SS014416: "Аннулировать",
    SS014417: "Завершить редактирование",
    SS014418: "Открыть протокол",
    SS014419: "Возвращение на доработку",
    SS014420: "Комментарий (причина возврата)",
    SS014421: "Комментарий",
    SS014422: "Вернуть на доработку",
    SS014423: "Внимание!",
    SS014424: "Отклонить заявку?",
    SS014425: "Да",
    SS014426: "Нет",
    SS014427: "Заявка отклонена",
    SS014428: "Статус события календаря для пациента МПП успешно изменен на: отменено",
    SS014429: "push-уведомление успешно отправлено",
    SS014430: "Статус события календаря для пациента МПП успешно изменен на: запланировано",
    SS014431: "Аннулирование заявки",
    SS014432: "Комментарий (причина аннулирования заявки)",
    SS014433: "Комментарий",
    SS014434: "Аннулировать",
    SS014435: "Поле Комментарий обязательно к заполнению",
    SS014436: "Заявка аннулирована",
    SS014437: "Внимание!",
    SS014438: "Вы уверены, что хотите изменить статус заявки?",
    SS014439: "Да",
    SS014440: "Нет",
    SS014441: "Заявка рассмотрена",
    SS015001: "Протоколы",
    SS015002: "ФИО пациента",
    SS015003: "Статус протокола",
    SS015004: "В работе",
    SS015005: "Аннулирован",
    SS015006: "К подписанию",
    SS015007: "Готов",
    SS015008: "Идет подписание",
    SS015009: "Подписан ЭП",
    SS015010: "Ошибка публикации",
    SS015011: "Код или название заболевания",
    SS015012: "Список пуст…",
    SS015013: "Проводящая медицинская организация",
    SS015014: "Сбросить фильтры",
    SS015015: "ФИО пациента",
    SS015016: "мужчина",
    SS015017: "женщина",
    SS015018: "Полис ОМС",
    SS015019: "Проводящая медицинская организация",
    SS015020: "Дата проведения",
    SS015021: "Прошло со дня создания протокола",
    SS015022: "дн.",
    SS015023: "Статус протокола",
    SS015024: "В работе",
    SS015025: "Аннулирована",
    SS015026: "К подписанию",
    SS015027: "Готов",
    SS015028: "Идет публикация",
    SS015029: "Опубликован",
    SS015030: "Ошибка публикации",
    SS015031: "Ошибка статуса",
    SS015032: "Протоколы",
    SS015033: "Экранная форма протокола и сведения для СЭМД - {param}",
    SS0150331: "Протокол ОК",
    SS015034: "Заявка еще не рассмотрена на Консилиуме.",
    SS015035: "Перейти в заявку",
    SS015036: "Дата создания протокола:",
    SS015037: "В работе",
    SS015038: "Аннулирована",
    SS015039: "К подписанию",
    SS015040: "Готов",
    SS015041: "Идет публикация",
    SS015042: "Опубликован",
    SS015043: "Ошибка публикации",
    SS015044: "Ошибка статуса",
    SS015045: "Сведения о пациенте",
    SS015046: "Общая информация",
    SS015047: "Состав онкологического консилиума",
    SS015048: "Диагноз",
    SS015049: "Анамнез заболевания",
    SS015050: "Общее состояние",
    SS015051: "Представленные документы",
    SS015052: "Решение консилиума",
    SS015053: "Сведения о пациенте",
    SS015054: "ФИО",
    SS015055: "Пол",
    SS015056: "Мужской",
    SS015057: "Женский",
    SS015058: "Дата рождения",
    SS015059: "ОМС",
    SS015060: "СНИЛС",
    SS015061: "Общая информация",
    SS015062: "Медицинская организация",
    SS015063: "Адрес проведения консилиума",
    SS015064: "Номер протокола",
    SS015065: "Дата проведения консилиума",
    SS015066: "Консилиум",
    SS015067: "Первичный",
    SS015068: "Повторный",
    SS015069: "Цель проведения консилиума",
    SS015070: "",
    SS015071: "Состав консилиума",
    SS015072: "",
    SS015073: "Диагноз",
    SS015074: "",
    SS015075: "Общее состояние",
    SS015076: "Представленные документы",
    SS015077: "Решение консилиума",
    SS015078: "Результат консилиума",
    SS015079: "",
    SS015080: "",
    SS015081: "Тактика лечения",
    SS015082: "",
    SS015083: "Особые мнения участников консилиума",
    SS015084: "Аннулировать",
    SS015085: "К подписанию",
    SS015086: "Хирургическое лечение",
    SS015087: "Удалить",
    SS015088: "Условия проведения лечения",
    SS015089: "",
    SS015090: "Планируемая дата начала лечения",
    SS015091: "Рекомендовать схему лекарственной терапии",
    SS015092: "Удалить",
    SS015093: "Схема лекарственной терапии",
    SS015094: "",
    SS015095: "Лучевая терапия",
    SS015096: "Удалить",
    SS015097: "Способ лучевой терапии",
    SS015098: "",
    SS015099: "Методика лучевой терапии",
    SS015100: "",
    SS015101: "Заключение/Обоснование рекомендуемой тактики лечения",
    SS015102: "Химиолучевое лечение",
    SS015103: "Удалить",
    SS015104: "Схема лекарственной терапии",
    SS015105: "",
    SS015106: "Способ лучевой терапии",
    SS015107: "",
    SS015108: "Методика лучевой терапии",
    SS015109: "",
    SS015110: "Планируемая дата начала лечения",
    SS015111: "Комплексное (кроме химиолучевого)",
    SS015112: "Удалить",
    SS015113: "Схема лекарственной терапии",
    SS015114: "",
    SS015115: "Планируемая дата начала лечения",
    SS015116: "Наблюдение",
    SS015117: "Удалить",
    SS015118: "Паллиативное лечение",
    SS015119: "Удалить",
    SS015120: "Планируемая дата начала лечения",
    SS015121: "Добавить особое мнение",
    SS015122: "Участник",
    SS015123: "Удалить",
    SS015124: "Внимание!",
    SS015125: "Вы уверены, что хотите изменить статус протокола на \"К подписанию\"?",
    SS015126: "Да",
    SS015127: "Нет",
    SS015128: "Выберите тип консилиума",
    SS015129: "Заполните все строки особого мнения участников консилиума",
    SS015130: "Заполните планируемую дату начала лечения для онкологического заболевания",
    SS015131: "Укажите условия проведения лечения для онкологического заболевания",
    SS015132: "Укажите \"Заключение/Обоснование\" рекомендуемой тактики лечения для онкологического заболевания",
    SS015133: "Завершите работу с заявкой",
    SS015134: "Документы",
    SS015135: "Загрузите PDF",
    SS015136: "Вернуть в работу",
    SS015137: "Готов",
    SS015138: "Протокол.",
    SS015139: "Внимание!",
    SS015140: "Вы действительно хотите удалить документ?",
    SS015141: "Да",
    SS015142: "Нет",
    SS015143: "Вложение сохранено",
    SS015144: "Загрузка документа",
    SS015145: "Прикрепление документа к протоколу",
    SS015146: "Внимание!",
    SS015147: "Вы уверены, что хотите вернуть протокол на доработку?",
    SS015148: "Да",
    SS015149: "Нет",
    SS015150: "Внимание!",
    SS015151: "Вы уверены, что хотите изменить статус протокола на \"Готов\"?",
    SS015152: "Да",
    SS015153: "Нет",
    SS015154: "Подписать ЭП",
    SS015155: "Внимание!",
    SS015156: "Вы уверены, что хотите подписать протокол?",
    SS015157: "Да",
    SS015158: "Нет",
    SS003233: "Метод подтверждения диагноза",
    SS003234: "",
    SS003235: "Морфологический тип и код по МКБ-О",
    SS003236: "Морфологический тип опухоли",
    SS003237: "Введите значение для поиска",
    SS003238: "Информация о клинической стадии удалена",
    SS003239: "Укажите факторы клинической стадии",
    SS003240: "Укажите факторы патологоанатомической стадии",
    SS003241: "Информация о патологоанатомической стадии удалена",
    SS003242: "Клиническая стадия cTNM",
    SS003243: "T - Первичная опухоль",
    SS003244: "N - Регионарные лимфатические узлы",
    SS003245: "M - Отдаленные метастазы",
    SS003246: "Стадия",
    SS003247: "Патологоанатомическая стадия pTNM",
    SS003248: "T - Первичная опухоль",
    SS003249: "N - Регионарные лимфатические узлы",
    SS003250: "M - Отдаленные метастазы",
    SS003251: "Стадия",
    SS003252: "G - Гистологическая градация",
    SS003253: "L - Лимфатическая инвазия",
    SS003254: "V - Венозная инвазия",
    SS003255: "Pn - Периневральная инвазия",
    SS003256: "R - Остаточная опухоль",
    SS003257: "Результаты иммуногистохимических исследований",
    SS003258: "Добавить исследование",
    SS003259: "Номер исследования",
    SS003260: "Дата исследования",
    SS003261: "Организация",
    SS003262: "Удалить исследование",
    SS003263: "Показатель",
    SS003264: "Добавить показатель",
    SS003265: "",
    SS003266: "Результаты молекулярно-генетических исследований",
    SS003267: "Добавить исследование",
    SS003268: "Не указано клиническое описание диагноза",
    SS003269: "Локализация отдаленных метастазов",
    SS003270: "",
    //Номера после правки дублирования идентификаторов
    SS003900: "Новое заболевание", //SS003200
    SS003901: "Создать заявку", //SS003200
    SS003902: "Информация о заболевании", //SS003201
    SS003903: "Нет записей", //SS003201
    SS003904: "Нет записей", //SS003202
    SS003905: "МКБ-10", //SS003202

    SS020000: "{название месяцев везде, где оно словом или сокращением - на русском, модуль календаря - на русском}",
    SS020001: "Цель проведения консилиума",
    SS020002: "{ссылка на справочник}",
    SS020003: "Статус онкоконсилиума",
    SS020004: "{ссылка на список}",
    SS020005: "Заявка",
    SS020006: "Повторный",
    SS020007: "Не указан",
    SS020008: "Аллергии и непереносимости",
    SS020009: "Сохранить",
    SS020010: "Дата",
    SS020011: "С",
    SS020012: "По",
    SS020013: "Событие",
    SS020014: "Все",
    SS020015: "{ссылка на справочник}",
    SS020016: "Организация",
    SS020017: "Все",
    SS020018: "Дата",
    SS020019: "Не указана",
    SS020020: "Событие",
    SS020021: "{ссылка на справочник}",
    SS020022: "Не указана",
    SS020023: "{ссылка на справочник}",
    SS020024: "Организация",
    SS020025: "Описание",
    SS020026: "Документы",
    SS020027: "Добавить",
    SS020028: "Редактирование документа",
    SS020029: "Новый документ",
    SS020030: "Протокол.",
    SS020031: "* - обязательно для заполнения",
    SS020032: "Выбор диагноза",
    SS020033: "Добавьте новый диагноз",
    SS020034: "или выберите из уже известных",
    SS020035: "Диагнозы пациента",
    SS020036: "Создать заявку",
    SS020037: "Установлено:",
    SS020038: "{месяца}",
    SS020039: "Онколог:",
    SS020040: "Не указан",
    SS021001: "Время проведения",
    SS021002: "Время заявки",
    SS021003: "{месяца}",
    SS021004: "Всего заявок",
    SS021005: "Мои заявки",
    SS021006: "Свободные слоты для подачи заявки",
    SS021007: "Создать заявку",
    SS022001: "Новая группа",
    SS022002: "Группа из шаблона",
    SS022003: "{ссылка на справочник}",
    SS022004: "Председатель консилиума",
    SS022005: "Секретарь консилиума",
    SS022006: "Врач-онколог, специалист по хирургическому лечению",
    SS022007: "Врач-онколог, специалист по лекарственному лечению",
    SS022008: "Врач-радиотерапевт",
    SS022009: "Дополнительный участник",
    SS022010: "№ не указан",
    SS022011: "Председатель консилиума",
    SS022012: "Секретарь",
    SS022013: "Врач-онколог, специалист по хирургическому лечению",
    SS022014: "Врач-онколог, специалист по лекарственному лечению",
    SS022015: "Врач-радиотерапевт",
    SS022016: "Дополнительный участник",
    SS022017: "Сортировать по",
    SS022018: "{статус, например \"В работе\"}",
    SS022019: "{специальность}",
    SS022020: "{блок - аналог}",
    SS023001: "Режим презентации",
    SS023002: "{месяца}",
    SS023003: "Отсутсвует",
    SS023004: "Завершить рассмотрение",
    SS023005: "Рекомендованные диагностические исследования",
    SS023006: "{ссылка на справочник}",
    SS023007: "Отсутствуют",
    SS023008: "Отсутствуют",
    SS023009: "Отсутствуют",
    SS023010: "Отсутствуют",
    SS023011: "Дата постановки:",
    SS023012: "Онколог:",
    SS023012A: "Доктор:",
    SS023013: "Дата постановки:",
    SS023014: "Подготовлена для рассмотрения",
    SS023015: "Документы",
    SS023016: "Внимание!",
    SS023017: "Отменить изменения?",
    SS023018: "Да",
    SS023019: "Нет",
    SS023020: "{блок - аналог}",
    SS023021: "Добавленный пункт",
    SS023022: "{ссылка на справочник}",
    "SS023022-1": "Не участвует в консилиуме",
    "SS023022-2": "Лечащий врач",
    "SS023022-3": "Председатель",
    "SS023022-4": "Секретарь",
    "SS023022-5": "Эксперт",
    "SS023022-6": "Внешний консультант",
    SS023023: "{месяца}",
    SS023024: "Код заболевания по МКБ 10",
    SS023025: "{месяца}",
    SS023026: "Контрольный список документов и исследований",
    SS023027: "{ссылка на справочник}",
    SS023028: "Внимание!",
    SS023029: "Заболевание пациента {patientName} {conditionCode} не рассматривается на консилиуме \"{appointmentName}\". Все равно добавить?",
    SS023030: "Да",
    SS023031: "Нет",
    SS023032: "Заявка на пациента \"{patientName}\" создана",
    SS024001: "Идентификаторы",
    SS024002: "Добавить идентификатор",
    SS024003: "Тип",
    SS024004: "Выберите тип",
    SS024005: "Логин МИС Элемент",
    SS024006: "Значение",
    SS024007: "Удалить идентификатор",
    SS024008: "Выбор должности",
    SS024009: "{ссылка на справочник}",
    SS024010: "Пароль сохранен. Введите новый пароль для изменения",
    SS024011: "Удалить",
    SS024012: "Сохранить",
    SS024013: "Аннулирование протокола",
    SS024014: "Архивирование своих Шаблонов лечения",
    SS024015: "Архивирование Шаблонов лечения",
    SS024016: "Внесение комментария/особого мнения по протоколу консилиума",
    SS024017: "Добавление новой команды/состава участников",
    SS024018: "Добавление/редактирование медикаментозных назначений",
    SS024019: "Доступность вкладки Документы",
    SS024020: "Доступность вкладки Заявки",
    SS024021: "Доступность вкладки Консилиумы",
    SS024022: "Доступность вкладки Организации",
    SS024023: "Доступность вкладки Планы лечения",
    SS024024: "Доступность вкладки Протоколы ОК",
    SS024025: "Доступность вкладки Рабочий стол",
    SS024026: "Доступность вкладки Сотрудники",
    SS024027: "Доступность вкладки Шаблоны лечения",
    SS024028: "Доступность страницы \"Пациенты\" по кнопке \"Выбор пациента\"",
    SS024029: "Заполнение вкладки Сводка по дням",
    SS024030: "Заполнение вкладки Статус лечения",
    SS024031: "Заполнение вкладки Статус лечения только у своего Цикла лечения",
    SS024032: "Заполнение Даты начала и продолжительности цикла",
    SS024033: "Заполнение Наименование цикла",
    SS024034: "Заполнение текущего состояния пациента",
    SS024035: "Запуск презентации в заявке на ОК",
    SS024036: "Изменение статуса заявки на \"Одобрена\", все заявки",
    SS024037: "Изменение статуса заявки на \"Одобрена\", свои заявки",
    SS024038: "Изменение статуса заявки на \"Отозвана\"",
    SS024039: "Изменение статуса заявки на \"Рассмотрена\" (доступность кнопки \"Вынести заключение\"), все",
    SS024040: "Изменение статуса заявки на \"Рассмотрена\" (доступность кнопки \"Вынести заключение\"), свои",
    SS024041: "Назначение эксперта на консилиум",
    SS024042: "Оставление комментария в заявке на вкладке \"Комментарий\", все",
    SS024043: "Оставление комментария в заявке на вкладке \"Комментарий\", только свои заявки",
    SS024044: "Отображение аватара в заголовке",
    SS024045: "Отображение панели действий в заголовке",
    SS024046: "Отображение формы UpToDate в заголовке",
    SS024047: "Перевод Плана лечения в статус Завершён",
    SS024048: "Перевод цикла в статус В работе",
    SS024049: "Перевод цикла в статус Завершён",
    SS024050: "Перевод цикла в статус Назначен",
    SS024051: "Перевод цикла в статус Отменён",
    SS024052: "Перевод цикла в статус Прерван",
    SS024053: "Перевод цикла в статус Приостановлен",
    SS024054: "Перевод цикла в статус Удалён",
    SS024055: "Переход в Цикл лечения из карты пациента",
    SS024056: "Печать протокола Плана лечения",
    SS024057: "Печать протокола Цикла лечения",
    SS024058: "Подтверждение протокола консилиума ЭЦП",
    SS024059: "Подтверждение разом всех МН",
    SS024060: "Получение электронной версии протокола",
    SS024061: "Получение электронной версии протокола (только для эксперта)",
    SS024062: "Прикрепление PDF со сканом протокола консилиума, все протоколы",
    SS024063: "Прикрепление PDF со сканом протокола консилиума, свои протоколы",
    SS024064: "Просмотр всех вкладок Цикла лечения в зависимости от статуса Цикла лечения",
    SS024065: "Просмотр всех заявок ОК куда приглашен и поиск заявки с помощью фильтров",
    SS024066: "Просмотр всех существующих заявок и поиск заявки с помощью фильтров",
    SS024067: "Просмотр всех существующих консилиумов",
    SS024068: "Просмотр заявки на ОК",
    SS024069: "Просмотр заявки на ОК куда приглашен как эксперт",
    SS024070: "Просмотр Заявок на ОК и Протоколов ОК в модальном окне",
    SS024071: "Просмотр заявок на ОК через интерфейс списка ОК (read only), все",
    SS024072: "Просмотр заявок на ОК через интерфейс списка ОК (read only), на консилиум которых приглашен как участник",
    SS024073: "Просмотр заявок на ОК через интерфейс списка ОК (read only), свои",
    SS024074: "Просмотр карты пациента",
    SS024075: "Просмотр консилиумов, на которые приглаше как участник (Свои)",
    SS024076: "Просмотр контента страницы \"Документы\"",
    SS024077: "Просмотр контента страницы \"Организации\"",
    SS024078: "Просмотр контента страницы \"Рабочий стол\"",
    SS024079: "Просмотр контента страницы \"Сотрудники\"",
    SS024080: "Просмотр медикаментозных назначений в модальном окне",
    SS024081: "Просмотр организации",
    SS024082: "Просмотр протокола консилиума",
    SS024083: "Просмотр сотрудника",
    SS024084: "Просмотр списка пациентов и поиск пациентов в списке с помощью фильтров",
    SS024085: "Просмотр списка пациентов и поиск пациентов в списке с помощью фильтров в разделе Планы лечения",
    SS024086: "Просмотр списка Планов лечения пациента",
    SS024087: "Просмотр списка протоколов консилиума",
    SS024088: "Просмотр списка своих протоколов/на которых отмечен как участник",
    SS024089: "Просмотр страницы команд/составов участников",
    SS024090: "Пункт в меню команд/составов участников",
    SS024091: "Редактирование вкладки Итоги лечения",
    SS024092: "Редактирование вкладки Итоги лечения только у своего Цикла лечения",
    SS024093: "Редактирование заявки на ОК",
    SS024094: "Редактирование заявки на ОК",
    SS024095: "Редактирование карты пациента",
    SS024096: "Редактирование консилиума",
    SS024097: "Редактирование организации",
    SS024098: "Редактирование параметров своих Шаблонов лечения в разделе Шаблоны лечения",
    SS024099: "Редактирование параметров Шаблонов лечения в разделе Шаблоны лечения",
    SS024100: "Редактирование поля Итоги на вкладке Итоги лечения",
    SS024101: "Редактирование протокола консилиума, все протоколы",
    SS024102: "Редактирование протокола консилиума, куда приглашен",
    SS024103: "Редактирование протокола консилиума, свой протокол",
    SS024104: "Редактирование сотрудника",
    SS024105: "Создание диагноза в разделе Планы лечения пациента",
    SS024106: "Создание задачи для пациента (диагностические исследования)",
    SS024107: "Создание/копирование Циклов лечения в Плане лечения",
    SS024108: "Создание нового и редактирование консилиума",
    SS024109: "Создание нового пациента",
    SS024110: "Создание новой заявки",
    SS024111: "Создание организации",
    SS024112: "Создание Плана лечения",
    SS024113: "Создание Плана лечения из карты пациента",
    SS024114: "Создание сотрудника",
    SS024115: "Сохранение Плана лечения в Шаблон",
    SS024116: "Сохранение своего Плана лечения в Шаблон",
    SS024117: "Удаление заявок на ОК, все",
    SS024118: "Удаление заявок на ОК, свои",
    SS024119: "Удаление консилиума",
    SS024120: "Удаление организации",
    SS024121: "Удаление Плана лечения",
    SS024122: "Удаление своих Шаблонов лечения",
    SS024123: "Удаление сотрудника",
    SS024124: "Удаление Шаблонов лечения",
    SS024125: "Управление ролями пользователей",
    SS024126: "Управление ролями пользователей (пункт меню)",
    SS024127: "Формирование протокола консилиума",
    SS024128: "Ошибка при сохранении вложений",
    SS024129: "Новый документ",
    SS024130: "Добавить",
    SS025001: "{роль}",
    SS025002: "{роль}",
    SS025003: "Язык",
    SS025004: "Рабочий стол",
    SS025005: "Выбор пациента",
    SS025006: "Информация о пациенте",
    SS025007: "Заявка ОК",
    SS025008: "Протоколы ОК",
    SS025009: "Планы лечения",
    SS025010: "Сотрудники",
    SS025011: "Редактирование сотрудника",
    SS026001: "Формирование отчетов",
    SS026002: "дн.",
    SS026003: "Генерация отчета…",
    SS026004: "Отчетный период:",
    SS026005: "Организация,\nпроводившая онкоконсилиум (ОК)",
    SS026006: "Количество пациентов,\nпрошедших через ОК",
    SS026007: "В том числе с впервые установленным диагнозом",
    SS026008: "В том числе количество пациентов отправленных на дообследование",
    SS026009: "Пациент направлен на лечение, случаев",
    SS026010: "Медицинская организация МО",
    SS026011: "Медицинская организация Москвы",
    SS026012: "Федеральные учреждения",
    SS026013: "Вид лечения, случаев ",
    SS026014: "Хирургическое ",
    SS026015: "ПХТ",
    SS026016: "Лучевое",
    SS026017: "Комбинированное",
    SS026018: "Количество ОК, проведенных с использованием телемедицинских технологий",
    SS026019: "Организация",
    SS026020: "главный врач медицинской организации",
    SS026021: "Стр. {число} из {число} ",
    SS026022: "Адрес места жительства",
    SS026023: "Номер телефона",
    SS026024: "Код льготы :",
    SS026025: "Проведено с применением телемедицинских технологий",
    SS026026: "{ссылка на справочник}",
    SS026027: "Председатель консилиума",
    SS026028: "Секретарь",
    SS026028A: "Лечащий врач",
    SS026029: "Врач-онколог, специалист по хирургическому лечению",
    SS026030: "Врач-онколог, специалист по лекарственному лечению",
    SS026031: "Врач-радиотерапевт",
    SS026032: "Дополнительный участник",
    SS026033: "Дистанционное присутствие",
    SS026034: "Онколог:",
    SS026035: "Отсутствуют",
    SS026036: "Отсутствуют",
    SS026037: "Отсутствуют",
    SS026038: "Анамнез заболевания",
    SS026039: "{ссылка на справочник}",
    SS026040: "Куда направлен пациент",
    SS026041: "В Федеральную медицинскую организацию",
    SS026042: "В медицинскую организацию МО",
    SS026043: "В медицинскую организацию иного объекта федерации",
    SS026044: "Выберите",
    SS026045: "Прогноз",
    SS026046: "Благоприятный",
    SS026047: "Сомнительный",
    SS026048: "Неблагоприятный",
    SS026049: "Рекомендовать диагностический метод",
    SS026050: "Добавить команду участников",
    SS026051: "Добавление участников консилиума",
    SS026052: "Организация",
    SS026053: "Выберите организацию",
    SS026054: "Новая организация",
    SS026055: "Выбор организации",
    SS026056: "Сотрудник",
    SS026057: "Выберите сотрудника",
    SS026058: "Новый сотрудник",
    SS026059: "Выбор сотрудника",
    SS026060: "Должность",
    SS026061: "Выберите должность",
    SS026062: "Новая должность",
    SS026063: "Выбор должности",
    SS026064: "Специализация",
    SS026065: "Специализация",
    SS026066: "Дистанционное присутствие",
    SS026067: "Добавить участника",
    SS026068: "Сохранить",
    SS026069: "Очистить",
    SS026070: "Закрыть",
    SS026071: "Выберите организацию",
    SS026072: "Выберите сотрудника",
    SS026073: "Выберите должность",
    SS026074: "Председатель консилиума",
    SS026075: "Секретарь",
    SS026076: "Врач-онколог, специалист по хирургическому лечению",
    SS026077: "Врач-онколог, специалист по лекарственному лечению",
    SS026078: "Врач-радиотерапевт",
    SS026079: "Дополнительный участник",
    SS026080: "удалить место",
    SS026081: "МКБ-10",
    SS026082: "Выберите заболевание",
    SS026083: "Введите значение для поиска",
    SS026084: "Все заболевания распределены",
    SS026085: "Хирургическое лечение",
    SS026086: "Планируемая дата госпитализации",
    SS026087: "Место проведения",
    SS026088: "В круглосуточном стационаре",
    SS026089: "Информационного материала, содержащегося в предоставленных документах, достаточно для принятия решения о выборе метода лечения.\nНеобходимости в переносе материалов и получении дополнительных данных нет.",
    SS026090: "Лекарственная терапия",
    SS026091: "дд.мм.гггг",
    SS026092: "Планируемая длительность (месяцев)",
    SS026093: "Место проведения",
    SS026094: "В круглосуточном стационаре",
    SS026095: "В дневном стационаре",
    SS026096: "Амбулаторно",
    SS026097: "Лучевая терапия",
    SS026098: "Планируемая дата начала",
    SS026099: "Место проведения",
    SS026100: "В круглосуточном стационаре",
    SS026101: "В дневном стационаре",
    SS026102: "Химиолучевое лечение",
    SS026103: "Комплексное",
    SS026104: "Наблюдение",
    SS026105: "Паллиативное лечение",
    SS026106: "Хирургическое лечение",
    SS026107: "Лекарственная терапия",
    SS026108: "Лучевая терапия",
    SS026109: "Химиолучевое лечение",
    SS026110: "Комплексное (кроме химиолучевого)",
    SS026111: "Наблюдение",
    SS026112: "Паллиативное лечение",
    SS027001: "Готова",
    SS027002: "Рассмотрена",
    SS027003: "Рассмотрена",
    SS027004: "При чтении данных произошла ошибка, попробуйте выполнить операцию позже",
    SS027005: "При чтении данных произошла ошибка, попробуйте выполнить операцию позже",
    //Переведено 19.08.21 - 31.08.21
    SS90001: "Время начала введения должно быть меньше времени окончания введения",
    SS90002: "Время окончания введения должно быть позже, чем время начала введения",
    SS90003: "Невозможно создать План лечения с Датой начала лечения ранее Даты рождения пациента",
    SS90004: "Невозможно подтвердить медикаментозное назначение с дозировкой, равной 0",
    SS90005: "Указанное значение уровня сывороточного креатинина находится вне ожидаемого интервала нормальных значений. Проверьте результат и его референсные значения в исследовании пациента.\n\nНорма креатинина в крови - ",
    "SS90005-1": "дети до 1 года: 18—35 мкмоль/л",
    "SS90005-2": "дети от 1 года до 14 лет: 27—62 мкмоль/л",
    "SS90005-3": "мужчины: 74.0—110.0 мкмоль/л",
    "SS90005-4": "женщины: 44.0—80.0 мкмоль/л",
    SS90006: "Новая должность",
    SS90007: "Вы действительно хотите сделать копию роли {roleName}?",
    SS90010: "Клиническое описание диагноза",
    SS90011: "Статус диагноза",
    SS90012: "Метод подтверждения диагноза",
    SS90013: "Дата постановки",
    SS90014: "Установивший врач",
    SS90015: "Основная опухоль",
    SS90016: "Сторона поражения",
    SS90017: "Морфологический тип и код по МКБ-О",
    SS90018: "Клиническая стадия cTNM",
    SS90019: "Патологоанатомическая стадия pTNM",
    SS90020: "Локализация отдаленных метастазов",
    SS90021: "Результаты иммуногистохимических исследований",
    SS90022: "Результаты молекулярно-генетических исследований",
    SS90023: "Осложнения заболевания",
    SS90024: "Дополнительная информация",
    SS90030: "У консилиума нет пунктов плана обследования",
    SS90040: "Формирование печатной формы Протокола ОК",
    SS90050: "Проведенные обследования",
    SS90060: "Диагностический метод",
    SS90061: "Место проведения",
    SS90062: "Удалить",
    SS90070: "Организация",
    SS90071: "ФИО Врача",
    SS90072: "ФИО Врача*",
    SS90073: "Должность",
    SS90074: "Выберите специализацию",
    SS90075: "Новая специализация",
    SS90076: "Выбор специализации",
    SS90090: "Комментарий к хирургическому лечению",
    SS90091: "Комментарий к лекарственной терапии",
    SS90092: "Комментарий к лучевой терапии",
    SS90093: "Комментарий к химиолучевому лечению",
    SS90094: "Комментарий к комбинированному лечению",
    SS90095: "Комментарий к наблюдению",
    SS90096: "Комментарий к паллиативному лечению",
    SS90097: "Комментарий к иным видам лечения",

    //Переводы за 3.09.21
    //28
    SS91132: "Укажите фамилию",
    SS91133: "Укажите имя",
    SS91134: "Укажите пол пациента",
    SS91135: "СНИЛС не указан",
    SS91136: "Укажите тип полиса ОМС",
    SS91137: "Укажите номер полиса ОМС",
    SS91138: "Укажите e-mail",
    SS91139: "Укажите телефон",
    SS91140: "Укажите инвалидность",
    SS91141: "Укажите валидный e-mail адрес",
    //29
    SS91142: "У пациента нет ни одного заболевания",
    //30
    SS91143: "Аллергии и непереносимости",
    SS91144: "Принимаемые препараты",
    //31
    SS91145: "Дата",
    SS91146: "Событие",
    SS91147: "С",
    SS91148: "По",
    SS91149: "Организация",
    SS91150: "Организация",
    SS91151: "Описание",
    SS91152: "Документы",

    //Переводы за 7.09.21 - 13.09.21
    SS92200: "История замещений",
    SS92201: "ФИО",
    SS92202: "Даты",
    SS92203: "Поиск",
    SS92204: "ФИО",
    SS92205: "Должность",
    SS92206: "Даты начала и окончания замещений",
    SS92207: "Внимание!",
    SS92208: "К данному пункту плана обследования был добавлен документ. Все равно удалить пункт?",
    SS92209: "Консилиум идет",
    SS92210: "Укажите фамилию",
    SS92211: "Укажите имя",
    SS92212: "Укажите дату рождения",
    SS92213: "СНИЛС не указан",
    SS92220: "Выбор пациента",
    SS92221: "Информация о пациенте",
    SS92222: "Дневник здоровья",
    SS92223: "Данные дневника здоровья отсутствуют, так как пациент не использует приложение",
    SS903214: "Название схемы лечения",
    SS903215: "Тип лечения",
    SS903216: "Дата начала лечения",
    SS903217: "Дата окончания лечения",
    SS903218: "Статус",
    SS903219: "У пациента еще {number} план{inflect} лечения",
    SS903220: "Консилиумы",
    SS903221: "Код заболевания по МКБ-10",
    SS903222: "МКБ-10",
    SS903223: "Место проведения",
    SS903224: "Цель проведения консилиума",
    SS903225: "Дата проведения",
    SS903226: "Статус онкоконсилиума",
    SS903227: "Создать протокол",
    SS903228: "Создать заявку",
    SS903229: "Введите значение для поиска",
    SS903230: "Введите порядок приема",
    SS903231: "Сохранить",
    SS903232: "Удалить",
    SS903233: "Выберите тип реакции",
    SS903234: "Скрыть выбор типа реакции",
    SS903235: "Данные дневника здоровья отсутствуют, так как пациент не использует приложение",
    SS903236: "Внимание!",
    SS903237: "Вы уверены, что хотите удалить файл «{fileName}» ?",
    SS903238: "Да",
    SS903239: "Нет",
    SS903240: "Выберите дату начала замещения",
    SS903241: "Укажите валидный e-mail адрес",
    SS903242: "Изменение типа организации недоступно, так как данная МО имеет филиалы, структурные подразделения",
    SS903243: "Структурные подразделения (филиалы):",
    SS903244: "Тип",
    SS903245: "Значение",
    SS903246: "Тип",
    SS903247: "Назначение",
    SS903248: "Индекс",
    SS903249: "Страна",
    SS903250: "Регион",
    SS903251: "Район",
    SS903252: "Населенный пункт",
    SS903253: "Адрес",
    SS903254: "Удалите координаты",
    SS903255: "Кол-во свободных мест",
    SS903256: "Кол-во заявок",
    SS903257: "Перенести заявку",
    SS903258: "Адрес организации",
    SS903259: "Редактирование группы",
    SS903260: "Название",
    SS903261: "Изменить",
    SS903262: "Отмена",
    SS903263: "Внимание!",
    SS903264: "Вы действительно хотите удалить группу?",
    SS903265: "Да",
    SS903266: "Нет",
    SS903267: "Внимание!",
    SS903268: "Вы действительно хотите удалить возможное обследование?",
    SS903269: "Да",
    SS903270: "Нет",
    SS903271: "Выберите заявки для удаления",
    SS903272: "Внимание!",
    SS903273: "Вы действительно хотите удалить выбранные заявки?",
    SS903274: "Да",
    SS903275: "Нет",
    SS903276: "Какие консилиумы удалить?",
    SS903277: "Внимание!",
    SS903278: "Удалить все последующие консилиумы?",
    SS903279: "Да",
    SS903280: "Нет",
    SS903281: "Медицинская организация",
    SS903282: "Медицинская организация",
    SS903283: "Укажите организацию",
    SS903284: "Файл {fileName} успешно загружен",
    SS903285: "Аннулировать состав участников консилиума {title} от {date}?",
    SS903286: "Организация",
    SS903287: "ФИО врача",
    SS903288: "ФИО врача",
    SS903289: "Выберите специализацию",
    SS903290: "НОВАЯ СПЕЦИАЛИЗАЦИЯ",
    SS903291: "ВЫБОР СПЕЦИАЛИЗАЦИИ",
    SS903292: "Загрузка информации",
    SS903293: "ФИО сотрудника",
    SS903294: "ФИО сотрудника",
    SS903295: "Выберите специализацию",
    SS903296: "Удалить",
    SS903297: "Внимание!",
    SS903298: "Вы уверены, что хотите удалить запись истории лечения?",
    SS903299: "Да",
    SS903300: "Нет",
    SS903301: "Введите значение для поиска",
    SS903302: "Введите порядок приема",
    SS903303: "Сохранить",
    SS903304: "Удалить",
    SS903305: "Выберите тип реакции",
    SS903306: "Результаты проведенных исследований",
    SS903307: "У консилиума нет пунктов плана обследования",
    SS903308: "Внимание!",
    SS903309: "Удалить документ {fileName}?",
    SS903310: "Да",
    SS903311: "Нет",
    SS903312: "Удалить слайд",
    SS903313: "Результаты проведенных обследований",
    SS903314: "Информация о принимаемом препарате успешно сохранена",
    SS903315: "Информация о принимаемом препарате успешно удалена",
    SS903316: "Результаты проведенных обследований",
    SS903317: "Результаты проведенных обследований",
    SS903318: "Вы уверены, что хотите аннулировать протокол?",
    SS903319: "Вы уверены, что хотите вернуть протокол к подписанию?",

    //Переводы добавленные 20.09.21
    SS904000: "Сначала новые",
    SS904001: "ОМС",
    SS904002: "СНИЛС",
    SS904003: "Дата и время создания",
    SS904004: "Направившая организация",
    SS904005: "Лечащий врач",
    SS904006: "Дата ОК",
    SS904007: "Дата рождения",
    SS904008: "Телефон",
    SS904009: "Название ОК",
    SS904010: "Место проведения ОК",
    SS904011: "Цель проведения консилиума:",
    SS904012: "ECOG статус",
    SS904013: "Индекс Карновского",
    SS904014: "Вес",
    SS904015: "Рост",
    SS904016: "Креатинин сыворотки [ммоль/л]",
    SS904017: "Площадь поверхности тела по формуле Мостеллера",
    SS904018: "План обследования",
    SS904019: "Нет записей",
    SS904020: "Заявка сохранена",
    SS904021: "Завершить рассмотрение",
    SS904022: "Команда участников",
    SS904023: "Председатель консилиума",
    SS904024: "Секретарь",
    SS904025: "Лечащий врач",
    SS904026: "Врач онколог, специалист по хирургическому лечению",
    SS904027: "Врач-онколог, специалист по лекарственному лечению",
    SS904028: "Врач-радиотерапевт",
    SS904029: "Адрес места жительства:",
    SS904030: "Номер телефона:",
    SS904031: "Код льготы:",
    SS904032: "ОК с применением ТМ-технологий",
    SS904033: "",
    SS904034: "Анамнез заболевания",
    SS904035: "Заключения (консультации) специалистов: ",
    SS904036: "выписки из медицинской документации",
    SS904037: "копии лабораторных исследований:",
    SS904038: "копии инструментальных исследований:",
    SS904039: "Куда направлен пациент",
    SS904040: "Выберите",
    SS904041: "Прогноз",
    SS904042: "Заявки",
    SS904043: "Диагноз ",
    SS904044: "Анамнез основного заболевания ",
    SS904045: "Общее состояние",
    SS904046: "Общее состояние",
    SS904047: "Анамнез основного заболевания",
    SS904048: "Скрыть ФИО пациента",
    SS904049: "Дата постановки:",
    SS904050: "Онколог:",
    SS904051: "Укажите дату рождения",
    SS904052: "СНИЛС имеет неверный формат (контрольная сумма не совпадает)",
    SS904053: "СНИЛС должен состоять из {digitCount} цифр",
    SS904054: "Номер полиса ОМС должен состоять из {digitCount} цифр",
    SS904055: "Номер полиса ОМС должен состоять из 9 цифр",
    SS904056: "Телефон должен состоять из 11 цифр",
    SS904057: "Укажите детальный адрес места проведения консилиума",
    SS904058: "Выберите хотя бы один день недели",
    SS904059: "Заполните период проведения",
    SS904060: "Загрузка информации",
    SS904061: "Перейти",
    SS904062: "Выбор типа уведомления",
    SS904063: "Дата",
    SS904064: "Только непрочитанные",
    SS904065: "Очистить",
    SS904066: "Применить",
    SS904067: "Укажите цель проведения консилиума",
    SS904068: "Вы не указали Анамнез основного заболевания",
    SS904069: "Вы не указали Общее состояние",
    SS904070: "Внимание!",
    SS904071: "Отменить изменения?",
    SS904072: "Да",
    SS904073: "Нет",
    SS904074: "Внимание!",
    SS904075: "Удалить запись?",
    SS904076: "Да",
    SS904077: "Нет",
    SS904078: "Заявка обновлена",
    SS904079: "Возвращена в доработку",
    SS904080: "Аннулирована",
    SS904081: "Комментарий обязателен к заполнению",
    SS904082: "Комментарий",
    SS904083: "Подготовка к рассмотрению",
    SS904084: "Завершение рассмотрения",
    SS904085: "Не указан тип первично-множественной опухоли",
    SS904086: "Вы не указали минимальное количество вложенных заболеваний (2)",
    SS904087: "Не указан статус диагноза {mkb10}: {name}",
    SS904088: "Не указано является ли опухоль основной",
    SS904089: "Не указана дата постановки",
    SS904090: "Не указана сторона поражения",
    SS9040610: "Укажите морфологический тип и код по МКБ-О",
    SS904092: "Заполните клиническую и/или патологоанатомическую стадию",
    SS904093: "Указаны не все факторы клинической стадии",
    SS904094: "Не указана локализация отдаленных метастазов",
    SS904095: "Указаны не все факторы патологоанатомической стадии",
    SS904096: "Не указан МКБ-10",
    SS904097: "Не указано клиническое описание диагноза",
    SS904098: "Не указан статус диагноза",
    SS904099: "Дата постановки",
    SS904100: "Не указана сторона поражения",
    SS904101: "Укажите гистологический тип",
    SS904102: "Заполните клиническую и/или патологоанатомическую стадию",
    SS904103: "Указаны не все факторы клинической стадии",
    SS904104: "Указаны не все факторы патологоанатомической стадии",
    SS904105: "Указаны не все факторы патологоанатомической стадии",
    SS904106: "Не полностью заполнены результаты ИГХ-исследования",
    SS904107: "Поле \"Дата\" обязательно к заполнению",
    SS904108: "Поле \"Тип документа\" обязательно к заполнению",
    SS904109: "Поле \"Тип документа\" обязательно к заполнению.",
    SS904110: "Укажите председателя консилиума",
    SS904111: "Укажите лечащего врача",
    SS904112: "Укажите секретаря консилиума",
    SS904113: "Укажите специалиста по хирургическому лечению",
    SS904114: "Укажите специалиста по радиотерапевтическому лечению",
    SS904115: "Выберите сотрудника",
    SS904116: "Выберите должность",
    SS904117: "Выберите организацию",
    SS904118: "Заполните предоставленные медицинские документы",
    SS904119: "Заполните анамнез заболевания",
    SS904120: "Заполните поле \"Куда направлен пациент\"",
    SS904121: "Не указана тактика лечения для онкологического заболевания {oncology}",
    SS904122: "Завершите работу с заявкой",
    SS904123: "Отметьте одно из новообразований, как Основную опухоль. Отредактируйте вложенные заболевания",
    SS904124: "Должно быть только одно новообразование, имеющее отметку Основная опухоль. Отредактируйте вложенные заболевания",
    SS904125: "Вложение сохранено",
    SS904126: "Необходимо заполнить поле Диагностический метод",
    SS904127: "Необходимо заполнить поле Место проведения",
    SS904128: "Не указана тактика лечения для онкологического заболевания",
    SS904129: "Возвращение на доработку",
    SS904130: "Поднять слайд",
    SS904131: "Опустить слайд",
    SS904132: "Причина возврата",
    SS904133: "Заполните общее состояние",

    SS904159: "Основная опухоль",
    SS904160: "Диагностический метод",
    SS904161: "Место проведения",
    SS904162: "Время",
    SS904163: "Время",
    SS904164: "Отметить все как прочитанное",
    SS904165: "Посмотреть все уведомления",

    SS904166: "Только мои заявки",

    SS904167: "Сегодня",
    SS904168: "Вчера",
    SS904169: "Следующие 7 дней",
    SS904170: "Следующие 14 дней",
    SS904171: "Следующие 30 дней",
    SS904172: "Текущий месяц",
    SS904173: "Применить",
    SS904174: "Отмена",

    //Переводы 27.09 - 28.09.21
    SS902240: "Готова",
    SS902241: "Выберите организацию",
    SS902242: "Сотрудники",
    SS902243: "Пациенты",
    SS902244: "Организации",
    SS902245: "Заявки",
    SS902246: "Планы лечения",
    SS902247: "{справочник схем лечения}",
    SS902248: "{справочник статусов}",
    SS902249: "Телефон:",
    SS902250: "Телефон:",
    SS902251: "Заявка ОК",
    SS902252: "План обследования отсутствует",
    SS902253: "У консилиума нет пунктов плана обследования",
    SS902254: "Внимание!",
    SS902255: "К данному пункту плана обследования был добавлен документ. Все равно удалить пункт?",
    SS902256: "Да",
    SS902257: "Нет",
    SS902258: "План обследования перед онкоконсилиумом",
    SS902259: "Состояние подтверждения",
    SS902260: "Предварительный",
    SS902261: "Подтвержденный",
    SS902262: "Установивший врач",
    SS902263: "Описание нозологической формы",
    SS902264: "Создание/изменение расписания",
    SS902265: "Идет создание или изменение элементов расписания. Не закрывайте эту страницу браузера до окончания процесса",
    SS902266: "Перенос заявки - {conditions} - {patientName} ({patientGender} {patientAge})",
    SS902267: "Название консилиума",
    SS902268: "Код или название заболевания",
    SS902269: "Медицинская организация",
    SS902270: "Свободное место",
    SS902271: "Текущее место",
    SS902272: "Перенести заявку",
    SS902273: "Отмена",
    SS902274: "Укажите номер личного телефона",
    SS902275: "Пациенты",
    SS902276: "Протоколы ОК",

    SS902278: "Сотрудники",

    SS902289: "Изменить статус",
    SS902290: "Подготовлена для рассмотрения",
    SS902291: "Отправить заявку на согласование?",
    SS902292: "Отправка на согласование",

    SS902299: "Вернуть \"К подписанию\"",
    SS902300: "Онкоконсилиум",
    SS902301: "Логин",
    SS902302: "Пароль",
    SS902303: "Далее",
    SS902304: "Здравствуйте,",
    SS902305: "Организация",
    SS902306: "Должность",
    SS902307: "Вход",
    SS902308: "Изменение пароля",
    SS902309: "Введите e-mail адрес для получения инструкций по смене пароля",
    SS902310: "E-mail",
    SS902311: "Отправить",
    SS902312: "Мирамедикс.Онкоконсилиум: Смена пароля",
    SS902313: "Смена пароля",
    SS902314: "Для смены пароля перейдите по ссылке:",
    SS902315: "Сменить пароль",
    SS902316: "Сменить организацию/должность",
    SS902317: "Планы лечения у пациента отсутствуют",
    SS902318: "Заболевания у пациента отсутствуют",
    SS902319: "Тип первично-множественной опухоли",
    SS902320: "Информация о пациенте",
    SS902321: "Информация об аллергии успешно сохранена",
    SS902322: "ВЫБОР РЕЗУЛЬТАТА",
    SS902323: "Просмотр документа",
    SS902324: "Просмотр документа",
    SS902325: "Пациент с таким СНИЛС уже зарегистрирован в системе",
    SS902326: "ФИО",
    SS902327: "Полис ОМС",
    SS902328: "СНИЛС",
    SS902329: "Телефон:",
    SS902330: "Пациент с таким полисом ОМС уже зарегистрирован в системе",

    SS902364: "Мои заявки",
    SS902365: "Срочно!",
    SS902366: "Срочно!",
    SS902367: "Создание заявки",
    SS902368: "Я участник",
    SS902369: "Свободных мест нет. Добавить заявку сверх нормы и пометить ее как срочную?",
    SS902370: "Срочно!",
    SS902371: "Невозможно добавить заявку на консилиум сверх установленной нормы, так как консилиум уже начался",

    SS902372: "Последний месяц",
    SS902373: "Последние 14 дней",
    SS902374: "Последние 7 дней",
    SS902375: "Завтра",
    SS902376: "НОВАЯ ОРГАНИЗАЦИЯ",
    SS902377: "НОВЫЙ РЕЗУЛЬТАТ",
    SS902378: "ВЫБОР РЕЗУЛЬТАТА",

    SS902421: "Редактирование контакта",
    SS902422: "Обновить",

    SS902500: "ФИО",
    SS902501: "Полис ОМС",
    SS902502: "СНИЛС",
    SS902503: "Циклы",
    SS902504: "Наименование цикла",
    SS902505: "Длительность (д)",
    SS902506: "Дата начала",
    SS902507: "Дата окончания",
    SS902508: "Статус",
    SS902509: "Телефон:",
    SS902510: "Срочно!",
    SS902511: "Медицинская организация",
    SS902512: "Команда участников успешно сохранена",
    SS902513: "Заболевание удалено",
    SS902514: "Вы действительно хотите удалить заболевание {код}?",
    SS902515: "Заболевание сохранено",
    SS902516: "Укажите порядок приема",
    SS902517: "Да",
    SS902518: "Нет",
    SS902519: "Редактирование документа",
    SS902520: "Пациент с таким телефоном уже зарегистрирован в системе",
    SS902521: "ФИО",
    SS902522: "Полис ОМС",
    SS902523: "СНИЛС",
    SS902524: "Создано событие календаря для пациента МПП на основе одобренной заявки",
    SS902525: "Укажите минимум 2 вложенных заболевания",
    SS902526: "Нажмите чтобы добавить новообразование",
    SS902600: "ФИО",
    SS902601: "Полис ОМС",
    SS902602: "СНИЛС",
    SS902603: "Циклы",
    SS902604: "Наименование цикла",
    SS902605: "Длительность (д)",
    SS902606: "Дата начала",
    SS902607: "Дата окончания",
    SS902608: "Статус",
    SS902609: "Телефон:",
    SS902610: "Срочно!",
    SS902611: "Организация",
    SS902612: "Команда участников успешно сохранена",
    SS902613: "Заболевание удалено",
    SS902614: "Вы действительно хотите удалить заболевание {код}?",
    SS902615: "Заболевание сохранено",
    SS902616: "Укажите порядок приема",
    SS902617: "Да",
    SS902618: "Нет",
    SS902619: "Редактирование документа",
    SS902620: "Пациент с таким телефоном уже зарегистрирован в системе",
    SS902621: "ФИО",
    SS902622: "Полис ОМС",
    SS902623: "СНИЛС",
    SS902624: "Создано событие календаря для пациента МПП на основе одобренной заявки",
    SS902625: "Укажите минимум 2 вложенных заболевания",
    SS902626: "Нажмите чтобы добавить новообразование",
    SS902630: "Некорректный e-mail адрес",
    SS902631: "Логин",
    SS902632: "Пароль",
    SS902633: "Далее",
    SS902634: "Онкоконсилиум",
    SS902635: "Смена пароля.",
    SS902636: "Для смены пароля перейдите по ссылке:",
    SS902637: "Сменить пароль",
    SS902638: "Пользователь с таким e-mail не найден. ",
    SS902639: "Проверьте корректность указанного e-mail адреса.",
    SS902640: "На указанный e-mail адрес отправлена инструкция по сбросу пароля",
    SS902641: "Изменение пароля",
    SS902642: "Введите e-mail адрес для получения инструкции по смене пароля",
    SS902643: "Отправить",
    SS902644: "Логин",
    SS902645: "Пароль",
    SS902646: "Далее",
    SS902647: "На указанный e-mail адрес отправлена инструкция по сбросу пароля",
    SS902648: "Неправильно указан логин и/или пароль",
    SS902649: "Забыли пароль?",
    SS902650: "Неправильно указан логин и/или пароль",
    SS902651: "Здравствуйте,",
    SS902652: "Организация",
    SS902653: "Должность",
    SS902654: "Войти",

    SS902656: "Введите новый пароль",
    SS902657: "Подтвердите новый пароль",
    SS902658: "Новообразование удалено",
    SS902659: "Вы действительно хотите удалить новообразование {code}?",
    SS902660: "Сохранить",

    //Переводы 1.10 - 4.10.21
    SS902663: "Выполнение подписания документов",
    SS902664: "Выполняется подписание документов….",
    SS902665: "Редактирование данных пациента", // NOT USED
    SS902666: "Фамилия",
    SS902667: "Имя",
    SS902668: "Отчество",
    SS902669: "Дата рождения",
    SS902670: "Пол",
    SS902671: "СНИЛС",
    SS902672: "Тип полиса ОМС",
    SS902673: "Номер полиса ОМС",
    SS902674: "E-mail",
    SS902675: "Телефон",
    SS902676: "Инвалидность",
    SS902677: "Адрес",
    SS902678: "Сохранить",
    SS902679: "Необходимо подтверждение",
    SS902680: "Нужно подтвердить номер телефона {phone}",
    SS902681: "Отправить код",
    SS902682: "Ведите код подтверждения, отправленный на {phone}",
    SS902683: "Код из CMC",
    SS902684: "Проверить",
    SS902685: "Пациент с таким СНИЛС уже зарегистрирован в системе",
    SS902686: "ФИО",
    SS902687: "Полис ОМС",
    SS902688: "СНИЛС",
    SS902689: "Создать протокол",
    SS902697: "Аннулирование заявки…",
    SS902698: "Удаление заявки…",
    SS902699: "Тип первично-множественной опухоли",

    SS902705: "Ничего не найдено. Попробуйте изменить условия поиска",
    SS902706: "мин.",
    SS902707: "Загрузка заболеваний…",

    TT018400: "Загрузите документ в формате PDF",
    TT018410: "Нельзя загрузить больше 15 мб",
    //Переводы 6.10.21
    SS902721: "Найден МПП пациент с таким же телефоном",
    SS902722: "Да",
    SS902723: "Нет",
    SS902724: "Связать создаваемого пациента с найденным пациентом МПП?",
    SS902725: "Не указан телефон ОК пациента",
    SS902726: "Не указана",
    SS902727: "Добавить запись",
    SS902728: "Добавить запись",
    SS902730: "Передача данных о документах…",
    SS902731: "Агент СЭП готов для работы и принял параметры для подписания документов",
    SS902732: "Агент СЭП оповещает о факте подписания документа {documentId}",

    //Переводы 7.10.21
    SS902735: "Удалить идентификатор",

    //Переводы 8.10.21
    SS902739: "Презентация",

    SS9035756: "Загрузка информации о сотруднике",
    SS9035757: "Внимание!",
    SS9035758: "Вы действительно хотите удалить сотрудника {employeeName}?",
    SS9035759: "Да",
    SS9035760: "Нет",
    SS9035761: "Укажите значение",

    SS9035764: "Внимание!",
    SS9035765: "Вы действительно хотите удалить организацию {organizationName}?",
    SS9035766: "Да",
    SS9035767: "Нет",
    SS9035768: "Внимание!",
    SS9035769: "Вы действительно хотите удалить {system} {value}?",
    SS9035770: "Да",
    SS9035771: "Нет",
    SS9035772: "Внимание!",
    SS9035773: "Вы действительно хотите удалить URL {link}?",
    SS9035774: "Да",
    SS9035775: "Нет",
    SS9035776: "Внимание!",
    SS9035777: "Вы действительно хотите удалить адрес?",
    SS9035778: "Да",
    SS9035779: "Нет",
    SS9035780: "Выберите страну",
    SS9035781: "Россия",
    SS9035782: "Обновить",
    SS9035783: "Не выбрана",

    //Перевод 11.10.21
    SS9035789: "Добавленный пункт",
    SS9035790: "Комментарий от пациента",
    SS9035791: "Отсутствует",
    SS9035792: "Список вложений",
    SS9035793: "Отправить повторно",
    SS9035794: "Принять",
    SS9035795: "Заполнить анкету",
    SS9035796: "длит.",

    SS9035798: "Создана",
    SS9035799: "На согласовании",
    SS9035800: "Готова для рассмотрения",
    SS9035801: "Возвращена на доработку",
    SS9035802: "Аннулирована",
    SS9035803: "Удалена",
    SS9035804: "Рассмотрена",
    SS9035805: "В работе",
    SS9035806: "Аннулирован",
    SS9035807: "К подписанию",
    SS9035808: "Готов",
    SS9035809: "Идет публикация",
    SS9035810: "Опубликован",
    SS9035811: "Ошибка публикации",

    //Перевод 12.10.21
    SS9035815: "Пациент прислал ответ.",
    SS9035816: "Комментарий:",
    SS9035817: "Подробнее",
    SS9035818: "Любой тип",
    SS9035819: "Задание",
    SS9035820: "Уведомление",
    SS9035821: "Перейти",
    SS9035822: "Пациент не смог предоставить запрошенные вами документы",
    SS9035823: "Закрыть задачу",
    SS9035824: "Подробнее",
    SS9035825: "Задача просрочена!",
    SS9035826: "Пациент прислал ответ",
    SS9035827: "отсутствует",
    SS9035828: "Задача повторно отправлена пациенту. Срок исполнения до {date}",
    SS9035829: "Вам придет уведомление, когда он отправит ответ.",

    //Перевод GLB-415
    SS9035830: "Не выбрано",
    SS9035831: "Не определена",
    SS9035832: "Не указан",
    SS9035833: "Не указано",
    SS9035834: "Не применима",
    SS9035835: "не указан",
    SS9035836: "Не назначен",

    //Переводы 19.10.21
    SS92224: "Результаты морфологических исследований",
    SS9035850: "Без наименования",
    SS9035851: "Не переведено",

    //Переводы 20.10.21
    SS9035852: "Гистологическое исследование",
    SS9035853: "Цитологическое исследование ",
    SS9035854: "Результаты морфологических исследований",
    SS9035855: "Тип исследования",
    SS9035856: "Результат",

    //Перевод 21.10.21
    SS9035857: "Организация",

    //Перевод 22.10.21
    SS9035863: "Протокол",
    SS9035864: "Протокол ОК",

    //Переводы 25.10.21
    SS9035866: "Сверх нормы",
    SS9035867: "Выберите документ",
    SS9035868: "Отмена",
    SS9035869: "Закрыть",
    SS9035870: "Свернуть",
    SS9035871: "Убрать документ из просмотра",
    SS9035872: "Добавить документ к просмотру",
    SS9035873: "Развернуть",

    //Переводы 26.10.21
    SS9035874: "Для просмотра содержимого недостаточно прав.",
    SS9035875: "Обратитесь к администратору",
    SS9035876: "Ссылка на Zoom конференцию",
    SS9035877: "Ссылка на Zoom конференцию",
    SS9035878: "Протокол не существует",
    SS9035879: "Сотрудник не найден",
    SS9035880: "Организация не найдена",
    SS9035881: "Заявка {caseId} не найдена",
    TT018740: "При загрузке информации о сотруднике произошла ошибка",
    TT018750: "При загрузке информации об организации произошла ошибка",
    SS9035882: "ВКС",
    SS9035883: "Выберите",
    SS9035884: "Загрузка планов лечения",

    //Переводы 27.10.21
    SS9035885: "Не помогло",
    SS9035886: "Получена новая задача",
    SS9035887: "Помогло",
    SS9035888: "Эффект не известен",
    SS9035889: "мм рт ст",
    SS9035890: "ммоль/литр",
    SS9035891: "кг",
    SS9035892: "Эффект не известен",
    SS9035893: "Левая",
    SS9035894: "Правая",
    SS9035895: "Опубликовать",
    SS9035896: "Вы уверены, что хотите опубликовать протокол?",
    SS9035897: "Повторить",
    SS9035898: "Предыдущий консилиум",
    SS9035899: "Следующий консилиум",
    SS9035900: "Не удалось определить ид. пациента",
    SS9035901: "При чтении данных произошла ошибка, попробуйте выполнить операцию позже",
    SS9035902: "Скрыть блок",
    SS9035903: "Свернуть блок",
    SS9035904: "Развернуть блок",

    //Переводы 29.10.21
    SS9035906: "Отчетный период:",
    SS9035907: "Организация, проводившая онкоконсилиум(ОК)",
    SS9035908: "Количество пациентов, прошедших через ОК",
    SS9035909: "В том числе с впервые установленным диагнозом",
    SS9035910: "В том числе количество пациентов, отправленных на дообследование",
    SS9035911: "Пациент направлен на лечение, случаев",
    SS9035912: "Вид лечения, случаев",
    SS9035913: "Количество ОК, проведенных с использованием телемедицинских технологий",
    SS9035914: "Организация МО",
    SS9035915: "Организация Москвы",
    SS9035916: "Федеральные учреждения",
    SS9035917: "Хирургическое",
    SS9035918: "ПХТ",
    SS9035919: "Лучевое",
    SS9035920: "Комбинированное",
    SS9035921: "Организации не найдены. Измените условия поиска.",
    SS9035923: "Сотрудники не найдены. Измените условия поиска.",

    //переводы 1.11.21
    TT018860: "Эмуляция передачи контекста",
    TT018870: "Логин",
    TT018880: "Пароль",
    TT018890: "Подтверждение пароля",
    TT018900: "Далее",
    TT018910: "СНИЛС",
    TT018920: "При загрузке информации произошла ошибка",
    TT018930: "При загрузке информации произошла ошибка",
    TT018940: "Стадия не определена",
    TT018950: "Нет доступных значений",
    TT018960: "Добавить осложнение",
    TT018970: "Загрузка пациента",
    TT018980: "Загрузка заболеваний",
    TT018990: "Продолжить",
    TT019000: "При загрузке информации произошла ошибка",
    TT019010: "Организация",
    TT019020: "Пациент не найден",
    TT019030: "Отсутствует запись пациента МПП или пользователя, требуется пересоздание пациента",
    TT019040: "Удалить",
    TT019050: "Загрузка пациента",
    TT019060: "Загрузка заболеваний",
    TT019070: "Добавить заболевание",
    TT019080: "Удалить",
    TT019090: "Вы уверены, что хотите удалить заболевание?",
    TT019100: "Загрузка заболеваний пациента",
    TT019110: "Экспортировать",
    TT019120: "Импортировать",
    TT019130: "Первичный",
    TT019140: "Повторный",
    TT019150: "Цель консилиума:",
    TT019160: "Цель проведения консилиума",
    TT019170: "Создать",
    TT019180: "Выберите даты",
    TT019210: "Загрузка информации",
    TT019220: "Ошибка при загрузке информации...",
    TT019230: "Консилиум не найден",
    TT019240: "Ошибка при загрузке информации...",
    TT019250: "Редактирование возможного обследования",
    TT019260: "Название",
    TT019270: "Описание",
    TT019280: "Сохранить",
    TT019290: "Отмена",
    TT019300: "Выберите группу заболеваний",
    TT019310: "Участники",
    TT019320: "При загрузке информации произошла ошибка",
    TT019330: "При поиске команд произошла ошибка",
    TT019340: "Сортировать по",
    TT019350: "При поиске команд произошла ошибка",
    TT019360: "Выберите",
    TT019370: "Информация о пациенте отсутствует",
    TT019380: "Редактировать",
    TT019390: "Удалить",
    TT019400: "Диагностические исследования",
    TT019410: "Лабораторное исследование",
    TT019420: "Инструментальная диагностика",
    TT019430: "Условия проведения лечения",
    TT019440: "Удалить",
    TT019450: "Перейти к протоколу прошедшего консилиума",
    TT019460: "Предыдущий консилиум",
    TT019470: "Дата проведение консилиума",
    TT019480: "Основной диагноз",
    TT019490: "Дата постановки диагноза",
    TT019500: "Тип первично-множественной опухоли",
    TT019510: "Тип классификации",
    TT019520: "Стадия",
    TT019530: "нет классификации",
    TT019540: "нет даты",
    TT019550: "Дата постановки диагноза",
    TT019560: "Тип классификации",
    TT019570: "Стадия",
    TT019580: "{number} этап",
    TT019590: "При поиске консилиумов произошла ошибка",
    TT019600: "Запланированные консилиумы",
    TT019610: "Новый консилиум",
    TT019620: "Выбор желаемой даты",
    TT019630: "Анамнез отсутствует",
    TT019640: "Выберите тип документа",
    TT019650: "Выберите медицинскую организацию",
    TT019660: "Комментарий секретаря",
    TT019670: "Здесь пока пусто",
    TT019680: "Комментарий врача",
    TT019690: "Сохранить",
    TT019700: "Загруженные документы согласно чек-листу",
    TT019710: "При загрузке заявок произошла ошибка",
    TT019720: "Тип:",
    TT019730: "нет даты",
    TT019740: "Инструкции",
    TT019750: "Выбор пациента",
    TT019770: "У пациента не установлено мобильное приложение",
    TT019780: "У текущего пользователя нет ролей. Нельзя добавить план лечения",
    TT019790: "Редактировать",
    TT019800: "Удалить",
    TT019810: "Удалить",
    TT019820: "Перейти к протоколу прошедшего консилиума",
    TT019830: "Удалить осложнение",
    TT019840: "Добавить осложнение",
    TT019860: "Печатная форма протокола ОК",
    TT019870: "Удалить",
    TT019880: "Загрузка информации",
    TT019890: "Консилиум не найден",
    SS9035924: "Редактирование сотрудника",
    SS9035925: "Загрузка списка организаций",
    SS9035926: "Нет организаций, в которых есть хотя бы один {appRoleName}",
    TT019900: "Код МКБ-10",
    TT019910: "Вложенные заболевания",
    TT019920: "Заключение",
    TT019930: "Тактика лечения",

    //Переводы 2.11.21
    SS9035927: "Специализация",
    SS9035928: "удалить место",
    TT019940: "Отметить как прочитанное",
    TT019950: "Клиническая",
    TT019960: "Патологоанатомическая",
    TT019970: "Обе",
    TT019980: "Не применимо",
    TT019990: "Выберите значение",
    TT020000: "Нет значений",
    SS9035929: "еще {count}",

    //Переводы 8.11.21
    TT020010: "Печатная форма протокола ОК",
    TT020020: "Закрыть",
    TT020030: "Отмена",
    TT020040: "Внимание!",
    TT020050: "Были обнаружены связи с другими ресурсами:",
    TT020060: "Заявки: {count}",
    TT020070: "Документы: {count}",
    TT020080: "Удаление пациента может привести к сбоям работы системы",
    TT020090: "Подождите, идет поиск зависимостей...",
    TT020100: "Вы действительно хотите продолжить удаление?",
    TT020110: "Удаление пациента",
    TT020120: " или создайте новую",
    TT020130: "Диагнозы:",
    TT020140: "Консилиум:",
    TT020150: "Цель консилиума: ",
    TT020160: "Другой пол",
    TT020170: "Пол неизвестен",
    TT020180: "Запись о полисе не найдена",
    TT020190: "СНИЛС неизвестен",
    TT020200: "Удалить документ {document}?",
    TT020210: "Общее состояние",
    TT020220: "от ",
    TT020230: "Не полностью заполнены результаты морфологических исследований",

    //Переводы 11.11.21
    TT020240: "E-mail",
    TT020250: "Пароль",
    TT020260: "Подтверждение пароля",
    TT020270: "Регистрация",
    TT020280: "При поиске обследований произошла ошибка",
    TT020290: "При поиске осложнений заболевания произошла ошибка",
    TT020300: "Ресурс заболевания не найден",
    TT020310: "Ресурс заболевания не найден",
    TT020320: "Ресурс пациента не найден",
    TT020330: "Все ресурсы сохранены в композиции протокола",
    TT020340: "Амплификация Her2 методом гибридизации in situ (FISH\\CISH)",
    TT020350: "Анатомическая локализация",
    TT020360: "Гистологическая градация",
    TT020370: "Дата установки",
    TT020380: "Дополнительные классификаторы и биомаркеры",
    TT020390: "Значение/Результат",
    TT020400: "Комментарии",
    TT020410: "Лимфатические узлы",
    TT020420: "Локализация",
    TT020430: "Наименование",
    TT020440: "Описание нозологической формы",
    TT020450: "Отдаленные метастазы",
    TT020460: "Первичная опухоль",
    TT020470: "Рецепторы прогестерона (ER) имунногистохимическим методом",
    TT020480: "Рецепторы эстрогена (ER) имунногистохимическим методом",
    TT020490: "Сгенерировать описание",
    TT020500: "Состояние подтверждения",
    TT020510: "Сохранить",
    TT020520: "Стадирование",
    TT020530: "Стадия",
    TT020540: "Сторона",
    TT020550: "Тип классификации",
    TT020560: "Установлено",
    TT020570: "Уточнение",
    TT020580: "Характер заболевания",
    TT020590: "Экспрессия Her2 имунногистохимическим методом",
    TT020600: "Экспрессия Ki-67 имунногистохимическим методом",
    TT020610: "Заболевания не найдены",
    TT020620: "Код МКБ-10 или название",
    TT020630: "Редактирование заболевание",
    TT020640: "Пациент не найден",
    TT020650: "При загрузке диагноза пациента произошла ошибка",
    TT020660: "При загрузке пациента произошла ошибка",
    TT020670: "Осложнения основных заболеваний",
    TT020680: "Основные заболевания",
    TT020690: "Сопутствующие заболевания",
    TT020700: "Имя не указано",
    TT020710: "Сотрудник не найден",
    TT020720: "[ сохранен ]",
    TT020730: "Валидация пройдена",
    TT020740: "При валидации номера телефона пациента произошла ошибка",
    TT020750: "При валидации ОМС полиса пациента произошла ошибка",
    TT020760: "При валидации СНИЛС пациента произошла ошибка",
    TT020770: "Ошибка загрузки Групп инвалидности",
    TT020780: "другой пол",
    TT020790: "пол неизвестен",
    TT020800: "Не удалось получить данные о пациенте",
    TT020810: "Добавить",
    TT020820: "[ Неизвестная организация ]",
    TT020830: "Неизвестная организация",
    TT020840: "Организация",
    TT020850: "При загрузке заболеваний пациента произошла ошибка",
    TT020860: "Заболевания не найдены",
    TT020870: "Код МКБ-10 или название",
    TT020880: "Заболевания пациента: {patientName}",
    TT020890: "Пациент не найден",
    TT020900: "При загрузке пациента произошла ошибка",
    TT020910: "неизвестный",
    TT020920: "Идентификатор логина МИС элемента не найден",
    TT020930: "Не указано значение логина МИС Элемент",
    TT020940: "Нет сотрудников с таким логином МИС элемента: {value}",
    TT020950: "При валидации логина МИС элемента сотрудника произошла ошибка",
    TT020960: "Сотрудник с таким логином МИС элемента уже зарегистрирован в системе",
    TT020970: "Идентификатора СНИЛС не найден",
    TT020980: "Нет сотрудников с таким СНИЛС: {value}",
    TT020990: "При валидации СНИЛС сотрудника произошла ошибка",
    TT021000: "Сотрудник с таким СНИЛС уже зарегистрирован в системе",
    TT021010: "Дата рождения указана в неверном формате",
    TT021020: "Имя не может быть более 20 символов",
    TT021030: "Отчество не может быть более 30 символов",
    TT021040: "Фамилия не может быть более 50 символов",
    TT021050: "[ Организация не найдена ]",
    TT021060: "Роль сотрудника",
    TT021070: " Найдены отсутствующие ссылки на заболевания ",
    TT021080: " Ошибок не найдено ",
    TT021090: "В плане лечения {careplan} консилиума {appointment} нет ссылок на заболевания: {conditions}.",
    TT021100: "Завершена проверка {appointmentsCount} консилиумов, {careplansCount} планов лечения и {conditionsCount} заболеваний.",
    TT021110: "Идет загрузка...",
    TT021120: "Произошла ошибка при исправлении ссылок на ресурсы",
    TT021130: "Произошла ошибка при проверке связей",
    TT021140: "Диагноз пациента",
    TT021150: "Заболевания пациента",
    TT021160: "Создать",
    TT021280: "Укажите должность",
    TT021290: "Укажите организацию",
    TT021300: "Укажите специализацию",
    TT021310: "Укажите ФИО врача",
    TT021320: "Оригинальный консилиум не найден. Обновите страницу",
    TT021330: "При создании копии консилиума произошла ошибка",
    TT021340: "Произошла ошибка при запросе к серверу",
    TT021350: "Достигнуто максимально возможное количество мест",
    TT021360: "Свободных мест не осталось",
    TT021370: "[неизвестно]",
    TT021380: "В заявке id: {serviceRequestId} неизвестная комбинация статусов",
    TT021390: "неизвестно",
    TT021400: "При удалении заявок произошла ошибка",
    TT021410: "Срочные заявки ({overbookedItemsCount})",
    TT021420: "Статус",
    TT021430: "ФИО",
    TT021440: "Не найден ресурс заявки",
    TT021450: "Не найден ресурс пациента, на которого ссылается заявка",
    TT021460: "Не найден ресурс сотрудника, создавшего заявку",
    TT021470: "Не найдены ресурсы слотов целевого консилиума",
    TT021480: "Дата",
    TT021490: "Комментарии",
    TT021500: "Название",
    TT021510: "При добавлении ссылки на вложенное заболевание в комплексном заболевании диагноза произошла ошибка",
    TT021520: "Не найдена ресурс заболевания во вложенных ресурсах протокола",
    TT021530: "Не найдена ссылка на заболевание в секции диагноза",
    TT021540: "Не найдена ссылка на заболевание в секции диагноза протокола",
    TT021550: "Удалена ссылка на заболевание и ресурс заболевания из протокола",
    TT021560: "Удалена ссылка на заболевание из секции диагноза",
    TT021570: "Не найден ресурс вложенного заболевания во ресурсах протокола",
    TT021580: "Не найдена ссылка на вложенное заболевание в комплексном заболевании диагноза",
    TT021590: "Не найдена ссылка на вложенное заболевание в комплексном заболевании диагноза протокола",
    TT021600: "При удалении ссылки на вложенное заболевание в комплексном заболевании диагноза произошла ошибка",
    TT021610: "Удалена ссылка на вложенное заболевание в комплексном заболевании диагноза",
    TT021620: "Удалена ссылка на вложенное заболевание из секции диагноза протокола",
    TT021630: "Укажите должность",
    TT021640: "Укажите организацию",
    TT021650: "Укажите специализацию",
    TT021660: "Укажите ФИО врача",
    TT021670: "лечащий врач",
    TT021680: "председатель",
    TT021690: "секретарь",
    TT021700: "эксперт",
    TT021710: "Источник финансирования",
    TT021720: "Выберите значение",
    TT021730: "Нет значений",
    TT021740: "Произошла ошибка при загрузке данных. Создание протокола не возможно.",
    TT021750: "Протокол онкологического консилиума по случаю {patientName} не завершен. Вы точно хотите покинуть страницу?",
    TT021760: "Новый консилиум",
    TT021770: "Удаляемая заявка не найдена",
    TT021780: "Вложенное заболевание не найдено",
    TT021790: "ECOG статус",
    TT021800: "Вы не указали минимальное количество вложенных заболеваний (2)",
    TT021810: "Вы не указали основное заболевание",
    TT021820: "Должно быть только одно новообразование, имеющее отметку Основная опухоль. Отредактируйте вложенные заболевания.",
    TT021830: "Отметьте одно из новообразований, как Основную опухоль. Отредактируйте вложенные заболевания.",
    TT021840: "При валидации диагноза заявки произошла ошибка",
    TT021850: "Стандартный план обследования для данной нозологии отсутствует, обсудите с секретарем консилиума необходимые исследования.",
    TT021860: "неизвестный",

    //Переводы 15.11.21
    SS9035932: "Специальность",
    SS9035933: "Исправить?",

    //Переводы 24.11.21
    TT021920: "Рекомендуемая доза",
    TT021930: "Редактировать документ",
    TT021940: "Удалить документ",
    TT021950: "Редактировать документ",
    TT021960: "Редактировать запись",
    TT021970: "Сохранить запись",
    TT021980: "Удалить документ",
    TT021990: "Удалить запись",
    TT022240: "из мобильного приложения",
    TT022250: "или",
    TT022260: "с компьютера ",
    TT022280: "загрузка",

    //Перевод dv кодов 29.11.21
    SS9035942: "Введите значение для поиска",
    SS9035944: "Изменить заболевание",
    SS9035945: "Удалить заболевание",
    SS9035946: "Только мои протоколы",

    //Переводы 1.12.21
    SS9035957: "Заполните результаты проведенных обследований",

    //Переводы 2.12.21
    SS9035968: "Редактировать консилиум",

    //Переводы 9.12.21
    TT022550: "Создать консилиум на ближайшую дату в выбранном периоде, если поиск не дал результатов",
    TT022560: "Создать ОК при необходимости",
    TT022570: "Консилиумы не найдены: {err}",
    TT022580: "Новый консилиум на {dateString}",

    //Переводы 11.01.22
    SS9036010: "Валидация протокола",
    SS9036020: "Внимание",
    SS9036030: "Смена статуса протокола",
    SS9036040: "Особое мнение участника",
    SS9036050: "Предыдущие протоколы онкоконсилиума",

    //Переводы 31.01.22
    SS9036060: "янв",
    SS9036070: "фев",
    SS9036080: "март",
    SS9036090: "апр",
    SS9036100: "май",
    SS9036110: "июнь",
    SS9036120: "июль",
    SS9036130: "авг",
    SS9036140: "сент",
    SS9036150: "окт",
    SS9036160: "нояб",
    SS9036170: "дек",
    SS9036180: "Пн",
    SS9036190: "Вт",
    SS9036200: "Ср",
    SS9036210: "Чт",
    SS9036220: "Пт",
    SS9036230: "Сб",
    SS9036240: "Вс",
    SS9036250: "Журнал проведенных консилиумов",
    SS9036260: "Стр. {PageNo} из {Pages}",
    SS9036270: "Загрузить файл",
    SS9036280: "Убрать документ из просмотра",
    SS9036290: "Перетащить",
    SS9036300: "Светлее/темнее",
    SS9036310: "Закрыть",
    SS9036320: "Свернуть",
    SS9036330: "Добавить документ к просмотру",
    SS9036340: "Редактировать консилиум",
    SS9036350: "Редактировать запись",
    SS9036360: "Удалить запись",
    SS9036370: "Добавить запись",
    SS9036380: "Запись добавлена",
    SS9036390: "Сохранить запись",
    SS9036400: "Запись сохранена",
    SS9036410: "Редактировать документ",
    SS9036420: "Планируемая длительность (месяцев)",
    SS9036430: "Редактировать документ",
    SS9036440: "Удалить документ",
    SS9036450: "Результаты проведенных обследований",
    SS9036460: "Заполните результаты проведенных обследований",
    SS9036470: "Отсутствуют",

    //Переводы 2.02.22
    SS9036480: "Клинические исследования",
    SS9036490: "Сбросить фильтр",
    SS9036510: "Создать ОК если необходимо",
    SS9036520: "Добавить участников из ранее созданной команды участников",
    SS9036530: "Клинические исследования не найдены",
    SS9036550: "Обновить список",
    SS9036560: "Обновить список клинических исследований?\nЭто может занять несколько минут.\nВо время обновления вы можете продолжить работу с заявкой.",
    SS9036570: "Да",
    SS9036580: "Нет",
    SS9036590: "Отображать КИ в протоколе",

    //Переводы GLB-595
    SS9037060: "Для отображения актуальных клинических исследований нажмите кнопку “Обновить список“",
    "SS9037060-1": "Все заболевания	фильтр",
    SS9037080: "Смотреть все",
    SS9037090: "Первая страница",
    SS9037100: "Предыдущая страница",
    SS9037110: "Следующая страница",
    SS9037120: "Последняя страница",
    SS9037130: "Сохранить",
    SS9037140: "Закрыть",
    SS9037150: "Не выбрано",
    SS9038530: "Не найден ресурс для хранения списка заявок.",
    SS9038540: "Выбрать исследования",
    SS9038550: "Изменить выбор исследований",
    SS9038560: "Нет клинического описания",
    SS9038570: "Название исследования",
    SS9038580: "Статус",
    SS9038590: "Название",
    SS9038600: "Условие",
    SS9038610: "Вмешательство",
    SS9038620: "Место",
    SS9038640: "Список доступных клинических исследований обновлён. Необходимо обновить страницу",
    SS9038650: "Клинические исследования не выбраны",






//Settings
    SS00058: "После установки",
    SS00059: "Забытые ссылки",
    SS00060: "Ортханк",
    SS00061: "Экспорт",
    SS00062: "Пуши",
    SS00063: "Монитор",
    SS00064: "Не входить",
    SS00065: "Фикс планов лечения",
    SS00066: "Конвертеры ресурсов",
    SS00067: "Экспорт/импорт шаблонов лечения",
    SS00068: "Импорт сотрудников ЕМИАС",
    SS00072: "Редактирование цикла",
    SS00079: "Редактирование консилиума",
    SS00080: "Все консилиумы",
    SS00081: "Предстоящие консилиумы",
    SS00082: "Архив консилиумов",
    SS00083: "Информация о консилиуме",
    SS00086: "Протокол ОК",
    SS00092: "Создание заявок",
    SS00093: "Заявка ОК",
    SS00095: "{param}",
    SS00096: "Заявки в обработке",
    SS00097: "Заявки на рассмотрении",
    SS00098: "Отклоненные заявки",
    SS00099: "Архив заявок",
    SS00100: "Консилиум идет",
    SS00101: "Удалить", //Универсальный для тултипов иконок
    SS00102: "Закрыть", //Универсальный для тултипов иконок
    SS00103: "Просмотреть", //Универсальный для тултипов иконок


    FCSPOK_158_1: "Осталось менее {h} часов",
    FCSPOK_158_2: "Осталось менее часа",


    PP001001: "Осталось {days} дней",
    PP001002: "Остался {days} день",
    PP001003: "Осталось {days} дня",
    PP003001: "{years} лет",
    PP003002: "{years} год",
    PP003003: "{years} года",

    //Временные, чтобы не было ошибок
    TT000002: "Выбор пациента",
    TT000003: "Информация о пациенте",
    TT000007: "Создание пациента",
    TT000013: "Входящие направления",

    TT018720: "Укажите продолжительность цикла",
    TT023470: "Не добавлено медицинских назначений",
    SS9036660: "Анамнез общий",

    SS9036600: "Результаты проведенных обследований",
    SS9036610: "Опишите результаты проведенных обследований",
    SS9036620: "Отсутствуют",

    SS9036640: "Новообразование",
    SS9036650: "Заболевание",

    SS9036630: "Сведения о медицинской страховке:",

    // GLB-630
    SS9036680: "Документы пациента",
    SS9036690: "Сортировать по дате",
    SS9036700: "Обработанный документ",
    SS9036710: "На полный экран",
    SS9036720: "Загрузить",
    SS9036730: "Перетащить",
    SS9036740: "Светлее/Темнее",
    SS9036750: "Свернуть экран",
    SS9036760: "Файл добавлен",
    SS9036770: "Загрузить файл",
    SS9036780: "Изменить название",
    SS9036790: "Удалить файл",
    SS9036800: "Не удалось добавить файл. Неверный формат",
    SS9036810: "Не удалось добавить файл", // Не удалось обработать файл ?
    SS9036820: "Повторить добавление",
    SS9036830: "Отменить добавление",
    SS9036840: "Вы можете завершить добавление или попытаться добавить заново",
    SS9036850: "Добавить документ к просмотру",
    SS9036860: "Свернуть",
    SS9036870: "Закрыть",
    SS9036880: "Перетащить",
    SS9036890: "Светлее/Темнее",
    SS9036900: "Удалить",
    SS9036910: "Специальная технология распознавания символов анализирует печатный или рукописный текст и превращает его в редактируемый цифровой файл формата PDF",
    SS9036920: "В PDF",
    SS9036930: "Распознать",
    SS9036940: "Выполнить",
    SS9036950: "Обработка вложений возможна только для форматов jpeg, png, pdf",
    SS9036960: "Обработка",
    SS9036970: "Файл обработан",
    SS9036980: "Удалить исходные файлы?",
    SS9036990: "Да",
    SS9037000: "Нет",
    SS9037010: "Обработанный документ",
    SS9037020: "Свернуть экран",
    SS9037030: "Удалить",
    SS9038360: "Документы пациента отсутствуют",
    SS9038370: "Показать список",
    SS9038380: "Обработка вложений",
    SS9038390: "Свернуть список",
    SS9038400: "Загрузка",
    SS9038410: "Отменить обработку",
    SS9038420: "Повторить обработку",
    SS9038430: "Вы можете завершить обработку или попытаться обработать заново",
    TT023280: "Не удалось обработать файл",
    TT023290: "Не удалось обработать файл. Неверный формат.",
    SS9038450: "По запросу \"{TextSample}\" ничего не найдено",
    STT023270: "Запись добавлена",
    S9038440: "Назначить",
    TT023320: "Обязателен выбор одного препарата",
    TT023340: "Наименование группы {GroupName} уже используется в Цикле Лечения, выберите другое наименование",
    TT023330: "Дополнительные препараты на выбор",
    SS9038470: "Дополнительные препараты на выбор",
    SS9038460: "Группа",
    SS9038510: "Организация",
    SS9038520: "Филиал, структурное подразделение",
    SS9038630: "Препарат успешно выбран",
    SS9038500: "Устаревший шаблон лечения",

    SS9039890: "Внимание",
    SS9039900: "Подготовка заявки к рассмотрению",
    SS9039910: "Отправка заявки на согласование",
    SS9039920: "Аннулирование заявки",
    SS9039930: "Возвращение заявки на доработку",
    SS9039940: "Удаление заявки",
    SS9039950: "Завершение рассмотрения заявки",
    SS9039960: "Возвращение заявки к рассмотрению",
    SS9039970: "Ошибка при смене статуса заявки",
    SS9039820: "Опишите анамнез основного заболевания",
    SS9039740: "Вернуть на доработку",
    SS9039720: "Вернуть к рассмотрению",
    SS9039980: "Добавить все шаблоны",
    SS9039730: 'Перевод заявки в статус “Готова для рассмотрения“ невозможен, так как протокол находится в статусе "{status_name}". Пожалуйста, обратитесь в службу поддержки',
    SS9039750: 'Перевод заявки в статус “Возвращена на доработку“ невозможен, так как протокол находится в статусе "{status_name}"',
    SS9039760: "Вернуть в работу",
    SS9039770: 'Вы уверены, что хотите вернуть протокол в работу?',
    SS9039780: 'Вернуть к статусу Готов',
    SS9039790: 'Вы уверены, что хотите вернуть протокол к статусу Готов?',
    SS9039800: "Необходимо указать наименования пунктов плана обследования",
    SS9039810: "Ошибка при удалении заявки",
    SS9039830: "Просмотр документа",
    SS9039840: "Опишите состояние пациента на момент проведения консилиума",
    SS9039850: "Или добавьте новый план обследования",
    SS9039860: "Просмотр документа",
    SS9039870: "Редактирование документа",
    SS9039880: "Получение списка...",
    TT023370: "Заполните поля Рост, Вес и Ур. сыв.креатинина",
    TT023380: "Сохранить произведенные изменения?",
    TT023390: "Вы уверены, что хотите удалить назначение препарата?",
    SS9040050: "Добавление нового заболевания",
    SS9040020: "Редактировать текущее состояние пациента",
    SS9040030: "Отменить внесенные изменения",
    TT023450: "Дата не назначена",
    TT023420: "Группа МН должна содержать хотя бы одно медикаментозное назначение. Для сохранения Шаблона лечения необходимо добавить медикаментозное назначение в группу",
    SS9040110: "Причина аннулирования",
    SS9040120: "Индекс массы тела",
    TT023480: "Для редактирования МН заполните поля Рост, Вес",
    SS9040130: "Обоснование для изменения схемы лечения",
    TT023460: "Невозможно уменьшить продолжительность цикла, так как есть назначения на '{day}' день",
    TT023490: "Изменение даты невозможно, так как одно или несколько назначений уже выполнены",
    SS9040150: "Вы действительно хотите отказаться от произведенных изменений?",
    TT023430: "Заболевание добавлено",
    TT023440: "Заболевания добавлены",
    TT018730: "Не добавлено медицинских назначений",
    MIRA_1910_1: "Номер заявки",
    SS9040170: "Чтобы изменить дату для первого дня цикла, необходимо изменить дату начала цикла",
    SS9040180: "Перенести назначения на предыдущий день",
    SS9040190: "Перенести назначения на следующий день",
    SS9040200: "Перенос назначений возможен только на свободную дату",
    SS9040210: "Назначения успешно перенесены",
    SS9040220: "Перенос невозможен, так как одно или несколько назначений уже выполнены",
    SS9040240: "Подписывает ЭП",
    SS9040250: "Выберите сотрудника из выпадающего списка",
    SS9040260: "Введите корректную фамилию",
    SS9040270: "Введите корректное имя",
    TT023520: "При добавлении цикла шаблона лечения произошла ошибка",
    SS9040280: "Данные от",
    SS9040290: "Если вы измените значения ППТ / Клиренса креатинина дозы препаратов будут пересчитаны. И вам необходимо будет снова подтвердить их назначение. Изменить значения текущего состояния пациента?",
    TT023410: "Редактирование медикаментозных назначений из раздела Премедикация и Противоопухолевая терапия доступно только в статусе Цикла лечения \"Создан\" и \"Приостановлен\"",
    TT023580: "Для полного редактирования медикаментозного назначения необходимо снять отметки о введении во вкладке “Статус лечения”",
    TT023590: "Для перевода цикла в статус \"Назначен\" необходимо подтвердить все медикаментозные назначения",
    TT023570: "Невозможно указать длительность цикла более 366 дней",
    SS9040330: "Направление на плановую госпитализацию",
    SS9040340: "Направление на лучевую терапию",
    SS9040350: "Сохранить",
    SS9040360: "Отсутствуют",
    SS9040530: "Подписать ЭП",
    SS9040540: "Подождите. Идет получение сертификатов",
    SS9040550: "Подписал",
    SS9040560: "Не подписал",
    SS9040570: "Идет подписание",
    SS9040580: "Протокол подписан",
    SS9040310: "Консилиум создан",
    SS9040320: "Консилиум отменен",
    SS9040600: "Закрыт",
    TT023650: "Введите корректное отчество",
    TT023660: "Не указана группа ЗНО",
    SS9040620: "Подписание документа",
    SS9040630: "Выберите сертификат для подписи",
    SS9040640: "Подписать",
    SS9040650: "Отменить",
    SS9040660: "Статус ЭП",
    SS9040670: "Валидация ЭП",
    SS9040680: "Отменить ЭП",
    SS9040690: "Завершить подписание ЭП",
    SS9040700: "Сертификат",
    SS9040710: "Срок действия",
    SS9040720: "Статус подписи",
    SS9040730: "Скачать pdf",
    SS9040740: "Скачать pdf с ЭП",
    SS9040750: "Показать статус ЭП",
    SS9040770: "Просмотр документа",
    SS9040780: "Подтверждение подлинности ЭП",
    SS9040800: "Введите текст для поиска",
    SS9040820: "Внимание",
    SS9040830: "Идет сохранение заявки",
    SS9040840: "Добавление записи в историю лечения",
    SS9040850: "Редактирование записи из истории лечения",
    SS9040860: "Электронная подпись может быть применена только для одного участника консилиума",
    SS9040870: "Проверьте корректность введенных значений",
    SS9040880: "Смена статуса заявки",
    SS9040890: "Сведения отсутствуют",
    SS9040900: "Вы уверены, что хотите завершить подписание протокола?",
    SS9040910: "Вы уверены, что хотите отменить ЭП?",
    SS9040920: "Добавление конкурирующего заболевания",
    SS9040930: "Все заболевания пациента распределены. Выберите в поисковой строке новое",
    SS9040940: "Добавление осложнения заболевания",
    SS9040950: "Добавление сопутствующего заболевания",
    SS9040960: "Вы действительно хотите удалить замещающего сотрудника?",
    SS9041000: "Редактировать",
    SS9041010: "Добавление пункта плана обследования",
    SS9041020: "Редактирование пункта плана обследования",
    TT024130:  "В презентации отсутствует информация для отображения. Пожалуйста, перейдите на экран редактирования презентации и включите отображение слайдов/блоков",
    // SS9041010: "Добавление пункта плана обследования",
    // SS9041020: "Редактирование пункта плана обследования",
    SS002068:  "Старого образца",
    SS002069:  "Нового образца",
    SS002070:  "Временное свидетельство",
    SS9041030: "КТ исследование",
    SS9041040: "Выписной эпикриз стационара",
    SS9041050: "Лабораторное исследование",
    SS9041060: "Маммография",
    SS9041070: "МРТ исследование",
    SS9041080: "Прочие документы",
    SS9041090: "Выписка из амбулаторной карты",
    SS9041100: "Гистологическое исследование",
    SS9041110: "ПЭТ/КТ исследование",
    SS9041120: "Консультация врача специалиста",
    SS9041130: "Протокол операции",
    SS9041140: "Заключение онкоконсилиума",
    SS9041150: "Ультразвуковое исследование",
    SS9041160: "Рентгенологическое исследование",
};
