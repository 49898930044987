import _get from "lodash/get";
import { observable } from "mobx";
import { ServiceRequestMeta } from "./ServiceRequestMeta";
import { ServiceRequestNumber } from "./ServiceRequestNumber";
import { ServiceRequestStatus } from "./ServiceRequestStatus";
import { ServiceRequestReason } from "./ServiceRequestReason";
import { ServiceRequestPatient } from "./ServiceRequestPatient";
import { ServiceRequestRequester } from "./ServiceRequestRequester";
import { ServiceRequestStatusComment } from "./ServiceRequestStatusComment";

/**
 * информация о заявке
 */
export class ServiceRequest {

    /**
     * ресурс заявки
     */
    @observable resource = null;

    /**
     * мета-информация о заявке
     */
    @observable meta = null;

    /**
     * номер заявки
     */
    @observable number = null;

    /**
     * ссылка на пациента
     */
    @observable patient = null;

    /**
     * цель проведения консилиума
     */
    @observable reason = null;

    /**
     * статус заявки
     */
    @observable status = null;

    /**
     * ссылка на автора заявки
     */
    @observable requester = null;

    /**
     * причина отклонения/аннулирования заявки
     */
    @observable statusComment = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
        this.meta = new ServiceRequestMeta(this.resource);
        this.number = new ServiceRequestNumber(this.resource);
        this.reason = new ServiceRequestReason(this.resource);
        this.status = new ServiceRequestStatus(this.resource);
        this.patient = new ServiceRequestPatient(this.resource);
        this.requester = new ServiceRequestRequester(this.resource);
        this.statusComment = new ServiceRequestStatusComment(this.resource);
    }

    /**
     * идентификатор заявки
     */
    get id() {
        return _get(this.resource, "id");
    }

    /**
     * тип ресурса
     */
    get resourceType() {
        return _get(this.resource, "resourceType");
    }

    /**
     * версия ресурса заявки
     */
    get versionId() {
        return this.meta.versionId;
    }

    /**
     * указана ли причина отклонения/возвращения на доработку (break, canceled)?
     */
    get hasStatusComment() {
        return !! (
            this.status.canHaveComment
            &&
            this.statusComment.isExists
        );
    }

    /**
     * добавление ссылки на заболевание в заявке
     */
    addConditionToReasonReference = (condition) => {
        this.resource.reasonReference.push({
            id: condition.id,
            resourceType: "Condition"
        })
    }

    /**
     * удаление ссылки на заболевание в заявке
     */
    delConditionFromReasonReference = (condition) => {
        this.resource.reasonReference = this.resource.reasonReference.filter(reference => {
            return reference.id != condition.id;
        });
    }

    /**
     * статус заявки позволяет переместить ее на другой консилиум?
     * можно переносить заявки только со статусами:
     * 1. создана
     * 2. на согласовании
     * 3. возвращена на доработку
     */
    get hasMovableStatus() {
        return (
            this.status.isCreated // создана
            ||
            this.status.isMatching // на согласовании
            ||
            this.status.isBreak // возвращена на доработку
        )
    }

}
