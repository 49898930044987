import _get from "lodash/get";

/**
 * класс для управления ссылками на вложенные заболевания
 */
export class ConditionComplicationConditions {

    /**
     * url расширения для осложнения
     */
    static EXTENSION_URL = "http://hl7.org/fhir/StructureDefinition/condition-dueTo";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * список расширений
     */
    get extensions() {
        return _get(this.conditionResource, "extension", []).filter(extension => {
            return _get(extension, "url") == ConditionComplicationConditions.EXTENSION_URL;
        });
    }

    /**
     * список ссылок
     */
    get references() {
        return this.extensions.map(extension => {
            return _get(extension, "valueReference");
        });
    }

    /**
     * список идентификаторов
     */
    get identifiers() {
        return this.references.map(reference => reference.id || reference.localRef);
    }

    /**
     * создание расширения со ссылкой на осложнение
     */
    create = (identifier, referenceType = "id") => {
        if( ! Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = [];
        }
        this.conditionResource.extension.push({
            "url": ConditionComplicationConditions.EXTENSION_URL,
            "valueReference": {
                [referenceType]: identifier,
                "resourceType": "Condition"
            }
        });
    }

    /**
     * удаление расширения со ссылкой на осложнение
     */
    remove = (identifier, referenceType = "id") => {
        const index = _get(this.conditionResource, "extension", []).findIndex(extension => {
            return !! (
                _get(extension, "url") == ConditionComplicationConditions.EXTENSION_URL
                &&
                _get(extension, ["valueReference", referenceType]) == identifier
            )
        });
        return index >= 0 ? this.conditionResource.extension.splice(index, 1) : false;
    }

    /**
     * удаление информации об осложнениях
     */
    reset = () => {
        this.conditionResource.extension = this.conditionResource.extension.filter(extension => {
            return _get(extension, "url") != ConditionComplicationConditions.EXTENSION_URL;
        });
    }

    /**
     * делаем ссылки локальными
     */
    makeLocalReferences = () => {
        _get(this.conditionResource, "extension", []).filter(extension => {
            return _get(extension, "url") == ConditionComplicationConditions.EXTENSION_URL;
        }).forEach(extension => {
            if ( extension.valueReference && extension.valueReference.id ) {
                extension.valueReference.localRef = extension.valueReference.id;
                delete(extension.valueReference.id);
            }
        })
    }

}
