import urlJoin from "url-join";
import { config } from 'config';
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";
import { AuthStoreData } from "MODEL_STORE/DataSource/Stores/AuthStoreDataClass";

export const makeUrl = (relativeUrl) => {
    return urlJoin(config.FILE_STORAGE_BASE_URL, relativeUrl);
}

export const makeConfig = (requestConfig) => {
    return {
        ...requestConfig,
        headers: {
            "Accept-Language": HeaderStore.locale,
            "Content-Type": "multipart/form-data",
            "X-OAuth-Token": AuthStoreData.loadAccessToken(),
            ...requestConfig.headers,
        },
    }
}
