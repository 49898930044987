import _get from "lodash/get";
import { observable } from "mobx";

/**
 * инвалидность пациента
 */
export class PatientDisability {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * значение
     */
    get value() {
        const url = "http://hl7.org/fhir/StructureDefinition/patient-disability";
        const extension = _get(this.resource, "extension", []).find(i => i.url === url);
        const code = _get(extension, "valueCodeableConcept.coding.0");
        return _get(code, "display", "-");
    }
}
