import _sortBy from "lodash/sortBy";
import _get from "lodash.get";
import { AbstractValueSet } from "./AbstractValueSet";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";

/**
 * список возможных языков документа MIRA-1878
 */
export class DocumentLanguagesValueSet extends AbstractValueSet {

    /**
     * при инициализации сразу загружаем справочник
     */
    constructor() {
        super("/ValueSet/mmdx-vs-document-languages/$expand");
    }

    /**
     * ищем концепт, наиболее точно подходящий к локали
     * @param {*} locale
     */
     findConceptForLocale = (locale) => {

        const concepts = this.concepts;

        // сначала ищем designation по точному совпадению локали и кода
        // случаи "en"<->en и "en-GB"<->"en-GB"
        const matchExact = concepts.find(c => { return c.code == locale; });
        if (matchExact) {
            return matchExact;
        }
        // если не нашлось точного совпадения, ищем концепт с коротким кодом, чтобы подходил к длинной локали интерфейса
        const shortLocale = locale.substr(0, 2);
        const matchPartial = concepts.find(c => { return c.code == shortLocale; });
        if (matchPartial) {
            return matchPartial;
        }

        return undefined;
    }
    /**
     * согласно локали вытаскиваем из концепта нужный display
     * @param {*} locale
     */
    findDisplayByLocale = (concept, locale) => {

        // сначала ищем designation по точному совпадению локали и кода
        // случаи "en"<->en и "en-GB"<->"en-GB"
        const matchExact = _get(concept, `designation.display.${locale}`);
        if (matchExact) {
            return matchExact;
        }

        // дальше ищем короткий код локали (2 знака) в полном коде в designation
        // это для варианта "en-GB" в локали и "en" в концепте
        const shortLocale = locale.substr(0, 2);
        const matchShortLocale = _get(concept, `designation.display.${shortLocale}`);

        if (matchShortLocale) {
            return matchShortLocale;
        }

        // всё, варианты кончились
        const defaultDesignation = _get(concept, `designation.display.en`);
        if (defaultDesignation) {
            return defaultDesignation;
        }

        return concept.display

    }

    /**
     * варианты для ReactSelect
     */
    get options() {

        const currentLocale = HeaderStore.locale;

        return _sortBy(this.concepts, "id").map(concept => ({
            value: concept.code,
            label: this.findDisplayByLocale(concept, currentLocale) + ' - ' + concept.property.iso_639_2,
            labelShort: concept.property.iso_639_2,
            concept: concept,
        }));
    }
}
