export const DOCUMENT_STATUS = {
    CURRENT: 'current',
    ENTERED_IN_ERROR: 'entered-in-error',
    SUPERSEDED: 'superseded'
};

export const LIBRARY_STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    RETIRED: 'retired',
    UNKNOWN: 'unknown'
};
