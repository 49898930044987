import React, { PureComponent, Suspense } from "react";
import PropTypes from "prop-types";
import Preloader from "./GUI_MAIN/Elements/Preloader";

class LazyComponent extends PureComponent {
    static propTypes = {
        component: PropTypes.any,
        data: PropTypes.object,
        children: PropTypes.any,
    };

    static defaultProps = {
        data: {},
    };

    render = () => {
        const { component, data } = this.props;
        if (!component) {
            return <div/>;
        }

        const Component = component;

        return (
            <Suspense fallback={<Preloader />}>
                <Component {...data} />
            </Suspense>
        );
    };
}

export { LazyComponent };
