import _get from "lodash/get";

/**
 * тип стадирования
 */
export class ClassificationObservationStagingType {

    /**
     * ресурс классификации
     */
    resource = null;

    /**
     * код клинической (cTNM) классификация
     */
    static CLINICAL_CODE = "c";

    /**
     * код патологоанатомической (pTNM) классификация
     */
    static PATHOLOGICAL_CODE = "p";

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/ds-stagingType";

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.resource, "code.coding", []).find(coding => {
            return _get(coding, "system") == ClassificationObservationStagingType.CODING_SYSTEM;
        });
    }

    /**
     * код (например, c)
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * описание (например, Клиническая классификация (cTNM))
     */
    get display() {
        return _get(this.coding, "display", "");
    }

}
