import _get from "lodash/get";

/**
 * ссылка на пациента
 */
export class ConditionPatient {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * ссылка
     */
    get subject() {
        if ( this.conditionResource.subject ) {
            if ( this.conditionResource.subject.resourceType == "Patient" ) {
                return this.conditionResource.subject;
            }
        }
    }

    /**
     * идентификатор ресурса пациента
     */
    get id() {
        return _get(this.subject, "id", "");
    }

    /**
     * тип ресурса
     */
    get resourceType() {
        return _get(this.subject, "resourceType", "");
    }

}
