import _get from "lodash/get";

/**
 * тип исследования
 */
export class ResearchObservationType {

    /**
     * код молекулярно-генетических исследований
     */
    static MOLECULAR_GENETICS_CODE = "116148004";

    /**
     * код имунногистохимических исследований
     */
    static IMMUNO_HISTO_CHEMISTRY_CODE = "117617002";

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://snomed.info/sct";

    /**
     * ссылка на ресурс
     */
    resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * название (например, Молекулярно-генетические исследования)
     */
    get text() {
        return _get(this.resource, "code.text", "");
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.resource, "code.coding", []).find(coding => {
            return _get(coding, "system") == ResearchObservationType.CODING_SYSTEM;
        });
    }

    /**
     * код (например, 116148004)
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * описание (например, Molecular genetics procedure)
     */
    get display() {
        return _get(this.coding, "display", "");
    }

}
