/**
 *
 *
 *
 *
 * --------------------------------------------------------
 *
 * всё про пациента
 *
 * неявные ограничения и рекомендации:
 *
 * подразумевается, что имя только одно (стандарт предусматривает несколько)
 *
 *
 * типовой формат:
 *
 * {
 *     "identifier":[
 *         {
 *             "use":"official",
 *             "system":"urn:1.2.643.5.1.13.13.11.1035:1.1|1",
 *             "value":"770000 3013640513",
 *             "period":{
 *                 "start":"1998-01-15"
 *             }
 *         },
 *         {
 *             "use":"official",
 *             "system":"urn:oid:1.2.643.100.3",
 *             "value":"132-456-789 90"
 *         },
 *         {
 *             "use":"official",
 *             "system":"urn:1.2.643.5.1.13.13.99.2.48:2.1|1",
 *             "value":"01 23 789456",
 *             "type":{
 *                 "coding":[
 *                     {
 *                         "system":"urn:1.2.643.5.1.13.13.99.2.48",
 *                         "version":"2.1",
 *                         "code":"1",
 *                         "display":"Паспорт гражданина Российской Федерации"
 *                     }
 *                 ]
 *             },
 *             "period":{
 *                 "start":"1995-01-25"
 *             },
 *             "assigner":{
 *                 "display":"Отделом УФМС России по району Коломенское города Москва 770-077"
 *             }
 *         },
 *         {
 *             "use":"official",
 *             "value":"13245/18",
 *             "system":"1.2.643.5.1.13.13.12.2.77.7973.100.1.1.15",
 *             "assigner":{
 *                 "reference":"Organization/Organization_id"
 *             }
 *         }
 *     ],
 *     "active":true,
 *     "gender":"female",
 *     "birthDate":"1964-02-27",
 *     "name":[
 *         {
 *             "use":"official",
 *             "text":"Лотфи Заде Анна Вероника Рагим оглы",
 *             "family":"Лотфи Заде",
 *             "given":[
 *                 "Анна Вероника",
 *                 "Рагим оглы"
 *             ]
 *         }
 *     ],
 *     "telecom":[
 *         {
 *             "system":"phone",
 *             "value":"+7 (495) 123-45-67 доб. 89"
 *         },
 *         {
 *             "system":"email",
 *             "value":"mailbox@example.ru"
 *         }
 *     ],
 *     "address":[
 *         {
 *             "use":"home",
 *             "type":"both",
 *             "line":[
 *                 "микрорайон АБ, дом 3, корпус 2, квартира 136-137"
 *             ],
 *             "city":"город Пущино",
 *             "district":"Серпуховской район",
 *             "state":"50",
 *             "postalCode":"142280",
 *             "country":"RU"
 *         },
 *         {
 *             "use":"temp",
 *             "type":"both",
 *             "line":[
 *                 "микрорайон АБ, дом 3, корпус 2, квартира 136-137"
 *             ],
 *             "city":"город Пущино",
 *             "district":"Серпуховской район",
 *             "state":"50",
 *             "postalCode":"142280",
 *             "country":"RU"
 *         }
 *     ],
 *     "managingOrganization":{
 *         "reference":"Organization/Organization_id"
 *     }
 * }
 *
 *
 *
 **/

import uuid from 'uuid/v4';
import _get from 'lodash.get';
import _set from 'lodash.set';
import { OID } from 'MODEL_STORE/DataSource/Constants/oid';

import { DomainResource } from './DomainResource';
import { HumanName } from 'MODEL_STORE/FHIR/Elements/HumanName';
import { Reference } from 'MODEL_STORE/FHIR/Elements/Reference';
import { ContactPoint } from 'MODEL_STORE/FHIR/Elements/ContactPoint';
import { Identifier } from 'MODEL_STORE/FHIR/Elements/Identifier';
import { BackboneElement } from 'MODEL_STORE/FHIR/Elements/BackboneElement';
import { TRANSLATION } from 'MODEL_STORE/DataSource/Constants/translation';
import { ADMINISTRATIVE_GENDER } from '../CodeSystem/AdministrativeGender'
import { toJS, computed } from 'mobx';
import { OmsPolicy } from 'MODEL_STORE/FHIR/Elements/OmsPolicy';

import moment from "moment";
import {EOLocale, Translator} from "eo-locale";
import React from "react";
import {HeaderStore} from "../../DataSource/Stores/HeaderStore";
import {locales} from "../../../Locale";
import _orderBy from "lodash/orderBy";

import { TypeOfMedicalCard } from "MODEL_STORE/MIRAMEDIX/Models/Patient/PatientMedicalRecord/TypeOfMedicalCard";
import { KindOfMedicalCard } from "MODEL_STORE/MIRAMEDIX/Models/Patient/PatientMedicalRecord/KindOfMedicalCard";
import { MedicalRecordNumber } from "MODEL_STORE/MIRAMEDIX/Models/Patient/PatientMedicalRecord/MedicalRecordNumber";

import { SNILS_IDENTIFIER_SYSTEM } from "MODEL_STORE/DataSource/Constants/systems";

const EMPTY_NAME = {
    family: '',
    given: [ ''
        // отчество может быть необязательным, а пустую строку не сохранить
        // , ''
    ],
}

const BENEFITS_URL = "http://miramedix.ru/fhir/StructureDefinition/patient-benefits";

export class Patient extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'Patient';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'deceasedBoolean',
            'deceasedDateTime',
            'maritalStatus',
            'multipleBirthBoolean',
            'multipleBirthInteger',
            'photo',
            'contact',
            'communication'
        ]);


        this._directCopyFields(data, ['gender', 'birthDate', ]);

        if (Array.isArray(data.name)) {
            data.name.forEach( e => {
                this._arrayAdd('name', new HumanName(e));
            });
        }
        // по соглашению, у нас ровно одно имя. поэтому если нету, сделаем
        else {
            this._arrayAdd('name', new HumanName());
        }

        if (Array.isArray(data.telecom)) {
            data.telecom.forEach( e => {
                this._arrayAdd('telecom', new ContactPoint(e));
            });
        }

        if (Array.isArray(data.address)) {
            data.address.forEach( e => {
                this._arrayAdd('address', e);
            })
        }

        if (Array.isArray(data.identifier)) {
            data.identifier.forEach( e => {
                this._arrayAdd('identifier', new Identifier(e));
            });
        }

        if (Array.isArray(data.link)) {
            data.link.forEach( e => {
                this._arrayAdd('link', new BackboneElement(e));
            });
        }

        // TODO ASAP переделать организацию на модель второй версии и сделать нормальную установку тут
        if (data.hasOwnProperty('managingOrganization')) {
            this._data.managingOrganization = data.managingOrganization;
        }
        // TODO ASAP переделать организацию на модель второй версии и сделать нормальную установку тут
        if (data.hasOwnProperty('generalPractitioner')) {
            this._data.generalPractitioner = data.generalPractitioner;
        }

        this._data.active = !! data.active;

        // информация о полисе ОМС
        this.omsPolicy = new OmsPolicy(this._data);
    }

    medicalCardNumberIdentifier = null;

    typeOfMedicalCard = null;
    kindOfMedicalCard = null;
    medicalRecordNumber = null;

    /**
     *
     */
    _validate() {

        super._validate();

        if ( ! this._validateFieldCode(this._data.gender, ADMINISTRATIVE_GENDER)) {
            throw 'Patient.gender is incorrect';
        }


    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get name() {

        if ( ! this._data.name) {
            return [];
        }

        return this._data.name.map( e => new HumanName(e, name => this._data.name[0] = name._cloneData()) );
    }

    /**
     *
     */
    get firstName() {
        return _get(this._data, ['name', 0, 'given', 0], '');
    }

    set firstName(newValue) {
        if ( ! Array.isArray(this._data.name)) {
            this._data.name = [EMPTY_NAME];
        }
        if ( ! Array.isArray(this._data.name[0].given)) {
            this._data.name[0].given = EMPTY_NAME.given;
        }
        this._data.name[0].given[0] = newValue;
    }

    get secondName() {
        return _get(this._data, ['name', 0, 'given', 1], '');
    }
    set secondName(newValue) {
        if ( ! Array.isArray(this._data.name)) {
            this._data.name = [EMPTY_NAME];
        }
        if ( ! Array.isArray(this._data.name[0].given)) {
            this._data.name[0].given = EMPTY_NAME.given;
        }
        // отчество может быть необязательным
        const val = newValue.trim();
        if (val.length > 0) {
            this._data.name[0].given[1] = newValue;
        } else {
            this._data.name[0].given = [this._data.name[0].given[0]]
        }
    }

    get lastName() {
        return _get(this._data, ['name', 0, 'family'], '');
    }
    set lastName(newValue) {
        if ( ! Array.isArray(this._data.name)) {
            this._data.name = [EMPTY_NAME];
        }
        this._data.name[0].family = newValue;
    }


    /**
     * массив контактных данных
     *
     */
    get telecom() {

        if ( ! this._data.telecom) {
            return [];
        }

        return this._data.telecom.map( (e, index) => new ContactPoint(e, contact => this._arrayReplaceById('telecom', contact.id, contact) ) );
    }

    @computed
    get email() {
        const email = _get(this._data, ['telecom'], []).find( ({system = null}) => system === 'email' );
        return email ? email.value : '';
    }

    set email(email) {
        const i = _get(this._data, ['telecom'], []).findIndex(({system = null}) => system === 'email');

        if (i !== -1) {
            this._data.telecom[i].value = email;
        } else {
            if ( ! Array.isArray(this._data.telecom)) {
                this._data.telecom = [];
            }
            if ( this._data.telecom.push({system: 'email', value: email}));
        }
    }

    @computed
    get phone() {
        const homes = _get(this._data, ['telecom'], []).filter( ({use  = null}) => use === 'home' || use === 'mobile' );
        if (homes.length) {
            return _orderBy(homes, [item => parseInt(item.rank), "use"], ["asc", "desc"])[0].value;
        }
        else {
            //Старая реализация
            const phone = _get(this._data, ['telecom'], []).find( ({system = null}) => system === 'phone' );
            return phone ? phone.value : '';
        }
    }

    set phone(phone) {

        if ( ! Array.isArray(this._data.telecom)) {
            this._data.telecom = [];
        }

        let index = this._data.telecom.findIndex(({system = null}) => system === 'phone');

        if (index == -1) {
            index = this._data.telecom.length;
        }

        if (phone.trim() == '') {
            this._data.telecom.splice(index, 1);

            if (this._data.telecom.length == 0) {
                delete(this._data.telecom);
            }

        } else {
            this._data.telecom[index] = {
                system: 'phone',
                value: phone,
            };
        }

    }

    get isSetAddress() {
        return _get(this._data, 'address', null) !== null;
    }

    get address() {
        const homes = _get(this._data, "address", []).filter( ({use  = null}) => use === 'home' );
        if (homes.length) {
            return homes[0].text;
        }
        else {
            const temps = _get(this._data, 'address', []).filter( ({use  = null}) => use === 'temp' );
            if (temps.length) {
                return temps[0].text;
            }
            else {
                //Старая реализация
                const address = _get(this._data, 'address', null);
                return address ? address[0].text : '';
            }
        }
    }

    set address(address) {
        this._data.address = [{
            text: address,
            type: "both",
            use: "home"
        }];

        if (this.address.trim().length === 0) {
            delete(this._data.address);
        }
    }

    set addressWithoutType(address) {
        this._data.address = [{
            text: address,
            use: "home"
        }];

        if (this.address.trim().length === 0) {
            delete(this._data.address);
        }
    }

    get benefitCode() {
        // ToDo пока нет справочника
        if (this._data.extension) {
            const benefitCode = this._data.extension.find(e => e.url === BENEFITS_URL);
            return benefitCode ? benefitCode.valueCodeableConcept.text : '';
        }
        return '';

    }

    set benefitCode(text) {
        if ( !Array.isArray(this._data.extension)) {
            this._data.extension = [];
        }
        let newExtension = this._data.extension.filter(e => e.url !== BENEFITS_URL);
        if (text.trim().length !== 0) {
            newExtension.push({
                url: BENEFITS_URL,
                valueCodeableConcept: {text}
            });
        }
        this._data.extension = newExtension;
    }


    /**
     *
     */
    get gender() {
        return this._data.gender;
    }

    /**
     *
     */
    set gender(newValue) {
        this._data.gender = newValue;
    }

    /**
     *
     */
    get active() {
        return this._data.active;
    }

    /**
     *
     */
    set active(newValue) {
        this._data.active = !! newValue;
    }

    /**
     *
     */
    get birthDate() {
        return this._data.birthDate;
    }

    /**
     *
     */
    set birthDate(newValue) {
        this._data.birthDate = newValue;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    // дальше идут нестандартные геттеры

    /**
     * просто что-то удобочитаемое в одну строку
     */
    get displayName() {
        const translator = new Translator(HeaderStore.locale, locales);
        const nameData = _get(this._data, 'name[0]');

        if ( ! nameData) {
            return translator.translate('TT021860');
        }

        return HumanName.fullNameShort(this._data.name[0])

    }

    /**
     * проверка на наличие имени
     */
    hasName() {
        return Array.isArray(this._data.name)
            && this._data.name.length;
    }

    /**
     * полное имя в одну строку
     */
    get displayFullName() {
        const translator = new Translator(HeaderStore.locale, locales);
        return this.hasName()
            ? HumanName.fullNameFull(this._data.name[0])
            : translator.translate('TT021860');
    }

    /**
     *
     */
    get genderName() {
        const genderCode = _get(TRANSLATION.ATTR.GENDER, this._data.gender, '');
        const translator = new Translator(HeaderStore.locale, locales);
        return translator.getMessageById(genderCode);
    }

    /**
     *
     */
    get genderPatientName() {
        return <EOLocale.Text id={_get(TRANSLATION.ATTR.PERSON_BY_GENDER, this._data.gender, '')}/>;
    }

    /**
     * муж || жен || n/a
     */
    get genderShortName() {
        if(this._data.gender == 'male' || this._data.gender == 'female') {
            return this.genderName.substr(0, 3).toLowerCase();
        }
        return 'n/a';
    }

    /**
     * м || ж || n/a
     */
    get genderSymbolName() {
        if(this._data.gender == 'male' || this._data.gender == 'female') {
            return this.genderName.substr(0, 1).toLowerCase();
        }
        return 'n/a';
    }

    /**
     *
     */
    get emiasId() {

        if ( ! Array.isArray(this._data.identifier)) {
            return '';
        }

        const idIndex = this._data.identifier.findIndex( e => e.system === OID.EMIAS_ID);

        if (idIndex < 0) {
            return '';
        }

        return this._data.identifier[idIndex].value;
    }

    /**
     *
     */
    get snils() {

        if ( ! Array.isArray(this._data.identifier)) {
            return '';
        }

        const idIndex = this._data.identifier.findIndex( e => e.system === SNILS_IDENTIFIER_SYSTEM);

        if (idIndex < 0) {
            return '';
        }

        return this._data.identifier[idIndex].value;
    }

    /**
     *
     */
    set snils(newValue) {

        if ( ! Array.isArray(this._data.identifier)) {
            this._data.identifier = [];
        }

        const identifier = new Identifier({
            use : 'official',
            system : SNILS_IDENTIFIER_SYSTEM,
            value: newValue,
        });

        const idIndex = this._data.identifier.findIndex( e => e.system === SNILS_IDENTIFIER_SYSTEM);

        if (idIndex < 0) {
            this._arrayAdd('identifier', identifier);
        } else {
            this._arrayReplaceByIndex('identifier', idIndex, identifier);
        }
    }


    /**
     * возвращает референс организации, занимающейся пациентом
     * // TODO поменять на managingOrganization.id в тех местах, где это появляется
     */
    get managingOrganization() {

        return this._data.managingOrganization;
    }

    /**
     * присвоение организации, занимающейся пациентом
     */
    set managingOrganization(organization) {

        if (organization) {
            this._data.managingOrganization = Reference.fromResource(organization)._data;
        } else {
            delete(this._data.managingOrganization);
        }

        // еще нужно обновить в номере медкарты
        if (Array.isArray(this._data.identifier)) {

            const medicalCardIndex = this._data.identifier.findIndex( e => _get(e, 'type.coding.0.code') == 'MR');
            if (medicalCardIndex) {

                const existing = this._data.identifier[medicalCardIndex];
                const updated = {
                    ...existing,
                    system : organization ? organization.idRoster : null,
                    assigner : organization ? Reference.fromResource(organization)._data : null,
                }
                this._data.identifier[medicalCardIndex] = updated;
            }
        }

    }

    /**
     * возвращает id врача, занимающейся пациентом
     * // TODO поправить после переноса Practitioner на модель типа 2
     */
    get generalPractitioner() {

        if ( ! Array.isArray(this._data.generalPractitioner)) {
            return null;
        }

        if (this._data.generalPractitioner.length == 0) {
            return null;
        }

        return this._data.generalPractitioner[0];
    }

    /**
     * присвоение лечащего врача пациента
     */
    set generalPractitioner(practitioner) {

        if ( ! Array.isArray(this._data.generalPractitioner)) {
            this._data.generalPractitioner = [];
        }

        if (practitioner) {
            this._data.generalPractitioner[0] = Reference.fromResource(practitioner)._data;
        } else {
            delete(this._data.generalPractitioner[0]);
        }
    }

    /**
     * возвращает номер медицинской карты пациента
     */
    get medicalCardNumber() {

        if ( ! Array.isArray(this._data.identifier)) {
            return '';
        }
        const index = this._data.identifier.findIndex( e => _get(e, 'type.coding.0.code') == 'MR');
        return index < 0 ? '' : this._data.identifier[index].value;
    }

    /**
     * номер медкарты. ставится даже при отсутсвииорганизации, а что делать
     */
    set medicalCardNumber(newValue) {

        if ( ! Array.isArray(this._data.identifier)) {
            this._data.identifier = [];
        }

        const index = this._data.identifier.findIndex( e => _get(e, 'type.coding.0.code') == 'MR');

        // просто удаляем, если пришло пустое
        if( ! newValue) {
            return index >= 0 ? this._data.identifier.splice(index, 1) : false;
        }

        const existing = index >= 0 ? this._data.identifier[index] : {};

        const newData = {
            ...existing,
            use: 'official',
            type: {
                coding: [{
                    code: 'MR'
                }]
            },
            value: newValue
        };

        this._data.identifier[index >= 0 ? index : this._data.identifier.length] = newData;
    }

    /**
     *
     */
    get identifier() {

        if ( ! this._data.identifier) {
            return [];
        }
        return this._data.identifier.map( e => new Identifier(e, e => this._arrayReplaceById('identifier', e.id, e) ) );
    }

    /**
     *
     */
    set identifier(newValue) {
        this._data.identifier = newValue.map( e => e._data );
    }

    /**
     *
     */
    get link() {

        if ( ! this._data.link) {
            return [];
        }
        return this._data.link.map( e => new BackboneElement(e, e => this._arrayReplaceById('link', e.id, e) ) );
    }

    /**
     *
     */
    set link(newValue) {
        this._data.link = newValue.map( e => e._data );
    }

    /**
     *
     */
    get mobility() {

        if ( ! Array.isArray(this._data.extension)) {
            return '';
        }

        const extensionValue = this._data.extension.find(extension => _get(extension, ['valueCodeableConcept', 'coding', 0, 'system']) == OID.PATIENT_MOBILITY);

        if ( ! extensionValue) {
            return '';
        }

        return extensionValue.valueCodeableConcept;
    }

    set mobility(mobility) {

        if ( ! Array.isArray(this._data.extension)) {
            this._data.extension = [];
        };

        const index = this._data.extension.findIndex(extension => _get(extension, ['valueCodeableConcept', 'coding', 0, 'system']) == OID.PATIENT_MOBILITY)

        const data = {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-disability',
            valueCodeableConcept: mobility
        }

        if (index == -1) {
            if ( ! Array.isArray(this._data.extension)) {
                this._data.extension = [];
            }
            this._data.extension.push(data);
        } else {
            this._data.extension[index] = data;
        }
    }


    /**
     *
     */
    get disability() {

        if ( ! Array.isArray(this._data.extension)) {
            return '';
        }

        const extensionValue = this._data.extension.find(extension => _get(extension, ['valueCodeableConcept', 'coding', 0, 'system']) == OID.PATIENT_DISABILITY);

        if ( ! extensionValue) {
            return '';
        }

        return extensionValue.valueCodeableConcept;
    }

    /**
     * удаление информации об инвалидности
     */
    deleteDisability = () => {
        this._data.extension = _get(this._data, ['extension'], []).filter(extension => {
            return extension.url != 'http://hl7.org/fhir/StructureDefinition/patient-disability';
        })
    }

    set disability(disability) {
        const extension = _get(this._data, ['extension'], []);

        const index = extension.findIndex(extension => _get(extension, ['valueCodeableConcept', 'coding', 0, 'system']) == OID.PATIENT_DISABILITY)

        const data = {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-disability',
            valueCodeableConcept: disability
        }

        if (index == -1) {
            if ( ! Array.isArray(this._data.extension)) {
                this._data.extension = [];
            }
            this._data.extension.push(data);
        } else {
            this._data.extension[index] = data;
        }
    }

    /**
     * возраст пациента (например, 32 года)
     * https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals
     */
    get ageInYears() {
        const translator = new Translator(HeaderStore.locale, locales);
        if( ! this.birthDate ) {
            return '-';
        }
        try {
            let id = "";
            const diffInYears = moment().diff(this.birthDate, 'years');
            const modulo = diffInYears % 10;
            if(diffInYears >= 11 && diffInYears <= 14) {
                id = "PP003001";
            }
            else if(modulo == 1) {
                id = "PP003002";
            }
            else if(modulo == 2 || modulo == 3 || modulo == 4) {
                id = "PP003003";
            }
            else {
                id = "PP003001";
            }
            return translator.translate(id, {years: diffInYears});
        } catch(e) {
            return 'n/a';
        }
    }

    /**
     * Актуально для пациента из МПП
     * возвратит данные из extension http://mmdx.ru/fhir/StructureDefinition/patient-socialPermissions
     * результат в формате списка
     */
    get socialPermissions() {
        if (!Array.isArray(this._data.extension)) {
            return [];
        }

        const extUrl = "http://mmdx.ru/fhir/StructureDefinition/patient-socialPermissions";

        const extensionValue = this._data.extension.find(extension => _get(extension, ['url']) == extUrl);

        if (!extensionValue) {
            return [];
        } else {
            return extensionValue.extension;
        }
    }

    get shareDiaryPermission() {
        if (!Array.isArray(this._data.extension)) {
            return false;
        }

        const extUrl = "http://mmdx.ru/fhir/StructureDefinition/patient-shareDiaryToPhysician";

        const extensionValue = this._data.extension.find(extension => _get(extension, ['url']) == extUrl);

        if (extensionValue) {
            return !!_get(extensionValue, "valueBoolean");
        } else {
            return false;
        }
    }

    setMedicalCardNumberIdentifierId = (serviceRequest) => {
        this.findMedicalCardNumberIdentifierId(serviceRequest);
        this.typeOfMedicalCard = new TypeOfMedicalCard(this.medicalCardNumberIdentifier, this._data);
        this.kindOfMedicalCard = new KindOfMedicalCard(this.medicalCardNumberIdentifier, this._data);
        this.medicalRecordNumber = new MedicalRecordNumber(this.medicalCardNumberIdentifier, this._data);
    }

    findMedicalCardNumberIdentifierId = (serviceRequest) => {
        if (serviceRequest._data.extension) {
             let medicalCardNumberExtension = serviceRequest._data.extension.find(extension =>
                extension.url === "http://hl7.org/fhir/fcsp/sd/fcsp-ex-servicerequest-medicalRecord");
             if (medicalCardNumberExtension) {
                this.medicalCardNumberIdentifier = medicalCardNumberExtension.valueReference.identifier.id;
             }
        }
    }

    onUpdateMedicalCardNumberIdentifierId = (newId) => {
        this.medicalCardNumberIdentifier = newId;
        this.typeOfMedicalCard = new TypeOfMedicalCard(this.medicalCardNumberIdentifier, this._data);
        this.kindOfMedicalCard = new KindOfMedicalCard(this.medicalCardNumberIdentifier, this._data);
        this.medicalRecordNumber = new MedicalRecordNumber(this.medicalCardNumberIdentifier, this._data);
    }

    createMedicalCardNumberIdentifier = () => {
        if ( ! Array.isArray(this._data.identifier) ) {
            this._data.identifier = []
        }

        const newId = uuid();
        this._data.identifier.push({
            id: newId,
            use: "official",
            system: "urn:oid:1.2.643.5.1.13.13.12.2.66.6795.100.1.1.16",
            type: {}
        });
        this.onUpdateMedicalCardNumberIdentifierId(newId);
    }

}
