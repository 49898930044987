import _get from "lodash/get";
import { observable } from "mobx";

/**
 * ссылка на пациента
 */
export class ServiceRequestPatient {

    /**
     * ссылка на ресурс заявки
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на ресурс пациента
     */
    get reference() {
        return this.resource.subject;
    }

    /**
     * идентификатор ресурса пациента
     */
    get id() {
        return _get(this.reference, "id");
    }

    /**
     * тип ресурса пациента
     */
    get resourceType() {
        return _get(this.reference, "resourceType");
    }
}
