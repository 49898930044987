export const PROTOCOL_COMPOSITION_STATUS = {
    PRELIMINARY          : 'preliminary',
    FINAL                : 'final',
    ENTERED_IN_ERROR     : 'entered-in-error'
};

export const PROTOCOL_COMPOSITION_INTEGRATION = {
    PLAN                : 'plan',
    ORDER     : 'order',
    IN_PROGRESS     : 'in-progress',
    FAILED     : 'failed',
    COMPLETE: 'completed'
};

export const PROTOCOL_COMPOSITION_STATUS_COMBINED = {
    IN_WORK: {name:'SS015004', value:'in_work'},
    TO_SIGN: {name:'SS015006', value: 'to_sign'},
    READY: {name: 'SS015007', value: 'ready'},
    PUBLISHED: {name:'SS015009', value:'published'},
    PUBLISHING: {name:'SS015008', value:'publishing'},
    CANCELED: {name:'SS015005', value:'canceled'},
    FAILED: {name:'SS015010', value:'failed'},
    ERROR: {name:'SS015044', value:'failed'}
};
