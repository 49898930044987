import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";

/**
 * форматы дат в разных локалях
 */
const FORMATS = {
    DT000001: {
        ru: "DD.MM.YYYY",
        en: "MM/DD/YYYY",
        cn: "DD.MM.YYYY",
    },
    DT000002: { // не подходит для дейтпикера
        ru: "DD MMMM YYYY",
        en: "DD MMMM YYYY",
        cn: "DD MMMM YYYY",
    },
    DT000003: {
        ru: "DD.MM.YYYY HH:mm",
        en: "MM/DD/YYYY hh:mm A",
        cn: "DD.MM.YYYY HH:mm",
    },
    DT000004: { // для дейтпикера
        ru: "HH:mm",
        en: "hh:mm a",
        cn: "HH:mm",
    },
    DT000005: { // ISO формат даты - не менять!
        ru: "YYYY-MM-DDTHH:mm:ssZ",
        en: "YYYY-MM-DDTHH:mm:ssZ",
        cn: "YYYY-MM-DDTHH:mm:ssZ",
    },
    DT000006: { // общий формат даты - не менять!
        ru: "YYYY-MM-DD",
        en: "YYYY-MM-DD",
        cn: "YYYY-MM-DD",
    },
    DT000007: { // для дейтпикера
        ru: "dd.MM.yyyy",
        en: "MM/dd/yyyy",
        cn: "dd.MM.yyyy",
    },
    DT000008: { // MaskedInput
        ru: "99.99.9999",
        en: "99/99/9999",
        cn: "99.99.9999",
    },
    DT000009: { // общий формат даты - не менять!
        ru: "YYYY-MM-DD HH:mm",
        en: "YYYY-MM-DD HH:mm",
        cn: "YYYY-MM-DD HH:mm",
    },
    DT000010: {
        ru: "DD MMM YYYY",
        en: "DD MMM YYYY",
        cn: "DD MMM YYYY",
    },
    DT000011: {
        ru: "MMMM YYYY",
        en: "MMMM YYYY",
        cn: "MMMM YYYY",
    },
    DT000012: { // общий формат даты - не менять!
        ru: "YYYY-MM-DD HH:mm:ss",
        en: "YYYY-MM-DD HH:mm:ss",
        cn: "YYYY-MM-DD HH:mm:ss",
    },
    DT000013: { // только время, в формате moment.js
        ru: "H:mm",
        en: "h:mm A",
        cn: "H:mm",
    },
    DT000014: {
        ru: "DD.MM",
        en: "MM/DD",
        cn: "DD.MM",
    },
    DT000015: {
        ru: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
        en: /^(0[0-9]|[1][0-2]):([0-5][0-9])\s[AP][M]$/,
        cn: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
    },
    DT000016: {
        ru: "dd.MM.yyyy HH:mm", // для дейтпикера
        en: "MM/dd/yyyy h:mm a",
        cn: "dd.MM.yyyy HH:mm",
    },
    DT000017: { // общий стандартный формат даты - не менять!
        ru: "HH:mm",
        en: "HH:mm",
        cn: "HH:mm",
    },
    DT000018: { // moment.js, формат для вывода времени
        ru: "HH:mm",
        en: "hh:mm A",
        cn: "HH:mm",
    },
    DT000019: { //Аналог DT000002 но с припиской "г." в конце
        ru: "DD MMMM YYYY г.",
        en: "DD MMMM YYYY",
        cn: "DD MMMM YYYY",
    },
    DT000020: { // в версиях выше release-1.8 используются такой формат дат
        ru: "D MMMM YYYY",
        en: "D MMMM YYYY",
        cn: "D MMMM YYYY",
    },
    DT000021: { // в версиях выше release-1.8 используются такой формат дат
        ru: "d-MM-yyyy",
        en: "d-MM-yyyy",
        cn: "d-MM-yyyy",
    },
    DT000022: { // в версиях выше release-1.8 используются такой формат дат
        ru: "D.MM.YYYY",
        en: "D.MM.YYYY",
        cn: "D.MM.YYYY",
    },
    DT000023: { // в версиях выше release-1.8 используются такой формат дат
        ru: "D MMMM YYYY, HH:mm",
        en: "D MMMM YYYY, HH:mm",
        cn: "D MMMM YYYY, HH:mm",
    },
    DT000024: { // в версиях выше release-1.8 используются такой формат дат
        ru: "DD MMMM YYYY HH:mm",
        en: "DD MMMM YYYY HH:mm",
        cn: "DD MMMM YYYY HH:mm",
    },
    DT000025: { // в версиях выше release-1.8 используются такой формат дат
        ru: "dd-MM-yyyy",
        en: "dd-MM-yyyy",
        cn: "dd-MM-yyyy",
    },
}

/**
 * получение формата даты в зависимости от настроек локали
 */
export const getFormat = (key) => {
    if ( FORMATS.hasOwnProperty(key) ) {
        if ( FORMATS[key].hasOwnProperty(HeaderStore.locale) ) {
            return FORMATS[key][HeaderStore.locale];
        }
    }
    return "";
}

/**
 * из формата вида DD.MM.YYYY делаем маску для ввода [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]
 */
export const formatToMask = (format) => {

    const toDigits = ['y', 'Y', 'm', 'M', 'd', 'D', 'h', 'H', 's', 'S', ]

    var result = [];

    format
        .replace('aa', 'a') // формат не отличается, но одна лишняя
        .split('')
        .forEach(e => {
            if (toDigits.indexOf(e) >= 0) {
                result.push(/\d/);
                return;
            }
            if (e == 'a') {
                result.push(/A|P/);
                result.push(/M/);
                return;
            }
            result.push(e);
        });

    return result;
}
