import { locales } from "Locale";
import { Translator } from "eo-locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";

/**
 * функция перевода текста
 */
export const translate = (code, params = {}) => {
    return (new Translator(HeaderStore.locale, locales)).translate(code, params);
}
