/**
 * реализация расширения Geolocation
 * https://www.hl7.org/fhir/extension-geolocation.html
 */

import _get from 'lodash.get';
import { createId } from 'GUI_MAIN/Utils/createId';

export class Geolocation {

    _data = {
        url: 'http://hl7.org/fhir/StructureDefinition/geolocation',
        extension: [{
            url: 'latitude',
            valueDecimal: null
        }, {
            url: 'longitude',
            valueDecimal: null
        }]
    }

    constructor(resourceExtensions = []) {
        const extension = resourceExtensions.find(extension => _get(extension, 'url') == this._data.url);

        const latitude  = _get(extension, 'extension', []).find(extension => _get(extension, 'url') == 'latitude');
        const longitude = _get(extension, 'extension', []).find(extension => _get(extension, 'url') == 'longitude');

        this.latitude   = _get(latitude, 'valueDecimal');
        this.longitude  = _get(longitude, 'valueDecimal');

        this._data.id = _get(extension, 'id', createId());

    }

    _index = (url) => this._data.extension.findIndex(extension => extension.url == url);

    get latitude() {
        return this._data.extension[this._index('latitude')].valueDecimal || '';
    }

    set latitude(value) {
        this._data.extension[this._index('latitude')].valueDecimal = parseFloat(value) || null;
    }

    get longitude() {
        return this._data.extension[this._index('longitude')].valueDecimal || '';
    }

    set longitude(value) {
        this._data.extension[this._index('longitude')].valueDecimal = parseFloat(value) || null;
    }

}
