/**
 * тут переводы для разных штук, которые сделаны готовыми кодами в FHIR (например, пол)
 *
 */

export const TRANSLATION = {

    ATTR : {
        CONTACT_POINT_SYSTEM : {
            'phone' : 'SS010036',
            'fax'   : 'SS010037',
            'email' : 'SS010038',
            // 'pager' : 'SS010039', // https://miramedix.atlassian.net/browse/MIRA-1329
            // 'pager' : 'SS010039', // https://miramedix.atlassian.net/browse/GLB-625
            'url'   : 'SS010040',
            'sms'   : 'SS010041',
            'other' : 'SS010042',
        },

        GENDER : {
            'male'    : 'SS014058',
            'female'  : 'SS014059',
            'other'   : 'Другое',
            'unknown' : 'Неизвестно',
        },

        PERSON_BY_GENDER : {
            'male'    : 'SS014026',
            'female'  : 'SS014027',
            'other'   : 'другое',
            'unknown' : 'неизвестно',
        },

        ADDRESS_USE : {
            'home'    : 'SS010047',
            'work'    : 'SS010048',
            'temp'    : 'SS010049',
            // 'old'     : 'SS010050', // https://miramedix.atlassian.net/browse/MIRA-1329
            // 'old'     : 'SS010050', // https://miramedix.atlassian.net/browse/GLB-625
            'billing' : 'SS010051',
        },

        ADDRESS_TYPE : {
            'both'     : 'SS010053',
            'postal'   : 'SS010054',
            'physical' : 'SS010055',
        },

    },
}
