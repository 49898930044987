import moment from "moment";
import _get from "lodash/get";
import { observable } from "mobx";
import { getFormat } from "Locale/datetime";

/**
 * мета-информация о заявке
 */
export class ServiceRequestMeta {

    /**
     * ссылка на ресурс заявки
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * версия заявки
     */
    get versionId() {
        if ( this.resource.meta ) {
            return this.resource.meta.versionId;
        }
    }

    /**
     * дата и время создания заявки
     */
    get createdDateTime() {
        if ( this.resource.meta ) {
            return moment(this.resource.meta.createdAt).format(getFormat("DT000003"));
        }
    }

    /**
     * дата и время обновления заявки
     */
    get updatedDateTime() {
        if ( this.resource.meta ) {
            return moment(this.resource.meta.updatedAt).format("DD.MM.YYYY HH:mm");
        }
    }

}
