/**
 *
 */
import { computed, autorun, observable, action, toJS } from 'mobx';



export class DocumentViewerStoreClass {

    /**
     *
     */
    @observable
    files = [];

    /**
     * это существенно проще, чем следить
     */
    @observable
    version = 0;

    /**
     *
     */
    @observable
    minimized = false;

    @action
    _update() {
        this.version += 1;
        this.files = this.files.filter( e => e );
    }

    /**
     *
     * данные для одного файла:
     * url,
     * type,
     * title,
     *
     * пока реализация предполагает только два файла
     */
    @action
    add(data) {
        if (this.files.find(e => e.url === data.url)) {
            console.log('file already opened');
        } else {
            this.files.push(data);
        }
        // call _update always to cause maximize
        this._update();
    }

    /**
     *
     */
    @action
    remove(index) {
        delete(this.files[index]);
        this._update();
    }

    /**
     *
     */
    @action
    clear() {
        this.files = [];
        this._update();
    }

    /**
     *
     */
    @action
    minimize() {
        this.minimized = true;
    }

    /**
     *
     */
    @action
    maximize() {
        this.minimized = false;
    }

    /**
     *
     */
    @action
    swap(indexA, indexB) {

        if (indexA < 0 || indexA >= this.files.length) {
            return;
        }
        if (indexB < 0 || indexB >= this.files.length) {
            return;
        }
        const t = this.files[indexA];
        this.files[indexA] = this.files[indexB];
        this.files[indexB] = t;

        this._update();
    }

}

const DocumentViewerStore = new DocumentViewerStoreClass();

export { DocumentViewerStore };