// @ts-ignore
import { _BasicStore } from 'MODEL_STORE/DataSource/Stores/_BasicStore';

// @ts-ignore
import { DocumentReference, DocumentReferenceConfig } from 'MODEL_STORE/FHIR/Resources/DocumentReference';
// @ts-ignore
import {AttachmentConfig, Attachment} from "MODEL_STORE/FHIR/Elements/Attachment"
// @ts-ignore
import {filesRequestPost} from "MODEL_STORE/DataSource/Requests/filesRequestPost";
import {toJS} from "mobx";
import {basicRequestPut} from "../Requests/basicRequestPut";
// @ts-ignore
import {DocumentLanguagesValueSet} from "MODEL_STORE/DataSource/Stores/ValueSets/DocumentLanguagesValueSet";
import moment from "moment";
import { HeaderStore } from './HeaderStore';




export class DocumentReferenceStore extends _BasicStore {

    resourceUri = 'DocumentReference';

    data: DocumentReference[];

    documentLanguageStore = new DocumentLanguagesValueSet();

    /**
     *
     */
    createElement(data?: any) {
        return new DocumentReference(data ? data.resource: {});
    };

    /**
     *
     */
    addElement = (element?: DocumentReference) => {
        this.data.push( element ? element : this.createElement() )
    };


    /**
     *
     */
    addAttachments = (document: DocumentReferenceConfig, files: any[]) => {

        var patient_id = '';

        if (document.subject) {
            patient_id = document.subject.id
        } else {
            console.error('uploading requires patient ID but it is empty at this DocumentReference');
            return new Promise((resolve, reject) => { reject('no patient ID specified'); })
        }

        const formData = new FormData();
        let promises = [];

        formData.append('folder', document.id);

        files.forEach( file => {
            formData.append('file', file);
        });
        promises.push(filesRequestPost('store', formData));

        return Promise.all(promises);
    };

    /**
     * согласно локали вытаскиваем из концепта нужный display
     * @param {*} locale
     */
     findDefaultAttachmentLanguage = () => {

        const locale = HeaderStore.locale;
        const concept = this.documentLanguageStore.findConceptForLocale(locale);

        if (concept) {
            return concept.code;
        }

        return '';
    }

    /**
     *
     */
    updateAttachments(document: DocumentReference, files: any[], ocr: boolean = false) {
        const oldContent = document.content;

        const defaultAttachmenLanguage = this.findDefaultAttachmentLanguage();

        document.content = [
            ...oldContent,
            ...files.map(file => {
                if (ocr) {
                    const date = moment();
                    return {
                        attachment: new Attachment({
                            contentType: file.type,
                            size: file.size,
                            title: `doc_${date.format('YYYYMMDD_HHmmss')}.pdf`,
                            url: `${document.id}/${file.name}`,
                            extension: [{
                                url: "http://miramedix.ru/fhir/StructureDefinition/ocr-service-data",
                                extension: [{
                                        url: "status",
                                        valueCode: "done"
                                    }, {
                                        url: "date",
                                        valueDateTime: date.format()
                                    }
                                ]
                            }]
                        })._data
                    }
                }
                return {
                    attachment: new Attachment({

                        contentType: file.type,
                        size: file.size,
                        title: file.name,
                        language: defaultAttachmenLanguage,
                        url: `${document.id}/${file.name}`
                    })._data
                }
            })
        ];
        return document;
    };

    mergeAttachments = (document: DocumentReference, actualDocument: DocumentReference) => {
        const newContent = actualDocument.content.filter((element: any) =>
            !document.content.find((el: any) => el.attachment.id === element.attachment.id)
        );

        document.content = [...document.content, ...newContent];
    };

    save = (document: DocumentReference) => {
        console.warn('[DocumentStore] SAVE:', toJS(document.id));

        return basicRequestPut('DocumentReference/' + document.id, document._data)
    };

    removeDocById = (id: string) => {
        this.data = this.data.filter(document => document.id !== id)
    }

}
