import _get from "lodash/get";
import { observable } from "mobx";
import _orderBy from "lodash/orderBy";
import moment from "moment";

/**
 * url для расширения
 */
const EXTENSION_URL = "http://hl7.org/fhir/StructureDefinition/request-statusReason";

/**
 * причина отклонения/аннулирования заявки
 */
export class ServiceRequestStatusComment {

    /**
     * ресурс заявки
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * существует ли?
     */
    get isExists() {
        return !! (
            this.extensions.length
            &&
            this.text.trim().length
        )
    }

    /**
     * ссылка на расширение
     */
    get extensions() {
        let extensions = _get(this.resource, "extension", []).filter(extension => {
            return _get(extension, "url") == EXTENSION_URL;
        });
        extensions = _orderBy(extensions, i => moment(_get(i, "valueAnnotation.time", "")).unix(), 'desc');
        return extensions;
    }

    /**
     * последний комментарий
     */
    get lastExtension() {
        return this.extensions.length > 0 ? this.extensions[0] : null;
    }

    /**
     * Список всех комментариев
     */
    get comments() {
        return this.extensions.map(item => {
            return {
                text: _get(item, "valueAnnotation.text", ""),
                time: moment(_get(item, "valueAnnotation.time", "")).format('DD.MM.YYYY HH:mm')
            }
        });
    }

    /**
     * значение
     */
    get text() {
        return _get(this.lastExtension, "valueAnnotation.text", "");
    }

    /**
     * время
     */
    get time() {
        return moment(_get(this.lastExtension, "valueAnnotation.time", "")).format('DD.MM.YYYY HH:mm');
    }

}
