/**
 *
 * https://www.hl7.org/implement/standards/fhir/datatypes.html#HumanName
 *
 */
import { Element } from './Element';



export class HumanName extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'HumanName';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'use',
            'prefix',
            'suffix',
            'period',
        ]);

        if (data.text) {
            this._data.text = data.text;
        }

        if (data.family) {
            this._data.family = data.family;
        }

        if (data.given) {
            this._data.given = [ ...data.given ];
        }

    }

    /**
     *
     */
    _validate() {

        super._validate();

        if (this._data.hasOwnProperty('family') && ! this._data.family) {
            throw 'HumanName.family must not be empty if exists';
        }

        // TODO check given
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get family() {
        return this._data.family;
    }

    /**
     *
     */
    set family(newValue) {
        this._data.family = newValue;
        this._updateText()
    }


    // TODO вообще это массив, возможно, нужна сооветственная обработка методами _arrayAdd
    /**
     *
     */
    get given() {
        return this._data.given;
    }

    /**
     *
     */
    set given(newValue) {
        this._data.given = newValue;
        this._updateText()
    }

    /**
     *
     */
    get text() {
        return this._data.text;
    }

    /**
     *
     */
    set text(newValue) {
        this._data.text = newValue;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    // нестандартные геттеры

    /**
     * имя в смысле имя
     */
    get firstName() {
        return this._data.given && this._data.given.length > 0 ? this._data.given[0] : '';
    }

    set firstName(newValue) {
        if ( ! Array.isArray(this._data.given)) {
            this._data.given = [];
        }

        this._data.given[0] = newValue;
        this._updateText()
    }

    /**
     * отчество
     */
    get middleName() {
        return this._data.given && this._data.given.length > 1 ? this._data.given[1] : '';
    }

    set middleName(newValue) {

        if ( ! Array.isArray(this._data.given)) {
            this._data.given = [];
        }

        // отчество элемент необязательный, но пустым быть не может, поэтому просто удаляем элемент массива
        if ( ! newValue) {
            if (this._data.given.length > 0) {
                this._data.given = [ this._data.given[0] ];
            } else {
                this._data.given = [];
            }
            return;
        }

        this._data.given[1] = newValue;
        this._updateText()
    }

    /**
     * сборка полного имени в формате "Фамилия Имя Отчество"
     */
    static fullNameFull(resource) {
        return (
            (resource.family || '') +
            (resource.given && resource.given.length > 0 ? ' ' + resource.given[0] : '') +
            (resource.given && resource.given.length > 1 ? ' ' + resource.given[1] : '')
        )

    }

    /**
     * сборка полного имени в формате "Фамилия И. О."
     */
    static fullNameShort(resource) {
        return (
            (resource.family || '') +
            (resource.given && resource.given.length > 0 ? ' ' + resource.given[0].replace(/([а-яА-Яa-zA-Z])[^-\s]*(\B|\b)/g, '$1.') : '') +
            (resource.given && resource.given.length > 1 ? ' ' + resource.given[1].replace(/([а-яА-Яa-zA-Z])[^-\s]*(\B|\b)/g, '$1.') : '')
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * автозаполнение поля name.text
     */
    _updateText() {

        this._data.text = HumanName.fullNameShort(this._data);
    }



}