import _get from "lodash/get";

/**
 * признак первичной опухоли
 */
export class ConditionPrimaryTumorFlag {

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/primaryTumorFlag";

    /**
     * url расширения
     */
    static EXTENSION_URL = "http://miramedix.ru/fhir/StructureDefinition/condition-primaryTumorFlag";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * ищем расширение в ресурсе заболевания
     */
    get extension() {
        return _get(this.conditionResource, "extension", []).find(extension => {
            return _get(extension, "url") == ConditionPrimaryTumorFlag.EXTENSION_URL;
        });
    }

    /**
     * индекс расширения в массиве расширений
     */
    get extensionIndex() {
        return _get(this.conditionResource, "extension", []).findIndex(extension => {
            return _get(extension, "url") == ConditionPrimaryTumorFlag.EXTENSION_URL;
        });
    }

    /**
     * ищем CodableConcept в расширении
     */
    get coding() {
        return _get(this.extension, "valueCodeableConcept.coding", []).find(coding => {
            return _get(coding, "system") == ConditionPrimaryTumorFlag.CODING_SYSTEM;
        });
    }

    /**
     * описание
     */
    get text() {
        return _get(this.extension, "valueCodeableConcept.text", "");
    }

    /**
     * основное новообразование?
     */
    get isMain() {
        return this.code == "main";
    }

    /**
     * второстепенное новообразование?
     */
    get isCollateral() {
        return this.code == "collateral";
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение для ReactSelect
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * при изменении кода
     */
    onChange = ({concept}) => {
        const extension = {
            url: ConditionPrimaryTumorFlag.EXTENSION_URL,
            valueCodeableConcept: {
                text: concept.display,
                coding: [{
                    code: concept.code,
                    system: ConditionPrimaryTumorFlag.CODING_SYSTEM,
                    display: concept.display,
                }]
            }
        }
        if ( ! Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = []
        }
        const { extensionIndex } = this;
        if( extensionIndex >= 0 ) {
            this.conditionResource.extension[extensionIndex] = extension;
        } else {
            this.conditionResource.extension.push(extension);
        }
    }

    /**
     * сброс значения
     */
    reset = () => {
        if ( Array.isArray(this.conditionResource.extension) ) {
            const { extensionIndex } = this;
            if ( extensionIndex >= 0 ) {
                this.conditionResource.extension.splice(extensionIndex, 1);
            }
        }
    }

}
