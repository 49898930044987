import _get from "lodash/get";
import { observable } from "mobx";

/**
 * номер заявки
 * https://jira.mmdx.ru/browse/MIRA-1910
 */
export class ServiceRequestNumber {

    /**
     * система для идентификатора
     */
    static IDENTIFIER_SYSTEM = "http://miramedix.ru/fhir/identifier/consilium-request-number";

    /**
     * ссылка на ресурс заявки
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на идентификатор
     */
    get identifier() {
        return _get(this.resource, "identifier", []).find(identifier => {
            return _get(identifier, "system") == ServiceRequestNumber.IDENTIFIER_SYSTEM;
        });
    }

    /**
     * значение идентификатора
     */
    get value() {
        return _get(this.identifier, "value", "");
    }

}
