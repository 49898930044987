export const dv_dictionary = {
    __MIRA_739_001: "Добавить файл формата .pdf", //translated
    __MIRA_778_001: "Код схемы, наименование, описание", //translated
    __MIRA_876_001: "Вы уверены, что хотите отменить создание шаблона лечения?", //translated
    __MIRA_893_001: "Наименование Шаблона лечения", //translated
    __MIRA_893_002: "Описание Шаблона лечения", //translated
    __MIRA_896_002: "Удалить МН", //translated
    __MIRA_192_001: "Описание", //translated
    __MIRA_914_001: "Невозможно указать длительность цикла более 366 дней", //translated
    __MIRA_920_001: "Введите значение для поиска", //translated
    __MIRA_916_001: "Отделение не указано", //translated
    __MIRA_828_001: "Изменить заболевание", //translated
    __MIRA_828_002: "Удалить заболевание", //translated
    __MIRA_828_003: "Мои протоколы", //translated
    __MIRA_828_004: "Код схемы", //translated
    __MIRA_828_005: "Черновик", //translated
    __MIRA_828_006: "Активный", //translated
    __MIRA_828_007: "Архивный", //translated
    __MIRA_828_008: "Удален", //translated
    __MIRA_828_009: "При загрузке шаблонов произошла ошибка...", //translated
    __MIRA_873_001: "Добавление нового плана лечения", //translated
    __MIRA_859_001: "Шаблоны для выбранного МКБ-10 и типу терапии в системе не найдены", //translated
    __MIRA_116_001: "Архивировать шаблон", //translated
    __MIRA_896_001: "Добавить МН",
    __MIRA_979_001: "Файл с данным форматом прикрепить невозможно", //translated
    __MIRA_837_001: "Ошибка ресурса, отсутствует номер цикла", //translated SS9035966
    __MIRA_977_001: "Обоснование для изменения схемы лечения", //translated TT022080
    __MIRA_977_002: "Комментарий", //translated SS007025
    __MIRA_977_003: "Отделение", //translated TT022090
    __MIRA_977_004: "Создать План лечения", //translated TT022110
    __MIRA_977_005: "По шаблону", //translated TT022100
    __MIRA_977_006: "Новый", //translated TT022070
    __MIRA_977_007: "Схема лекарственной терапии", //translated SS015093
    __MIRA_977_008: "Онкоконсилиум", //translated SS902300
    __MIRA_977_009: "Вы уверены, что хотите отменить создание Плана лечения?", //translated TT022060
    __MIRA_977_010: "Далее", //translated SS9035784
    __MIRA_981_001: "Добавление нового плана лечения", //translated SS9035953
    __MIRA_981_002: "Добавить", //translated TT022170
    __MIRA_981_003: "Вы уверены, что хотите отменить создание Плана лечения?", //translated TT022160
    __MIRA_981_004: "Схема лекарственной терапии", //translated TT022180
    __MIRA_593_001: "Редактировать консилиум", //translated SS9035968
    __MIRA_659_001: 'Отчет формируется только для протоколов в статусе "Готов"', //translated TT022510
    __MIRA_659_002: 'Отчет формируется только для протоколов в статусе "Готов"', //translated TT022510
    __MIRA_1047_001: 'Схема лекарственной терапии', //translated SS015113
    __MIRA_1046_001: 'Невозможно перевести Шаблон лечения в статус "Активный". Заполните поля: Продолжительность цикла, Количество циклов. Добавьте МН или Группу МН.', //translated TT022540

    __MIRA_1053_001: "Рекомендуемая нагрузочная доза", //translated SS9035980

    __MIRA_1098_001: "Добавить МН", //translated SS004017

    __MIRA_1028_001: "Выберите Место лечения пациента", //translated TT022610
    __MIRA_1104_001: "Наименование Шаблона лечения:", //translated SS9035990

    __MIRA_1125_003: "Невозможно уменьшить продолжительность цикла, так как есть назначения на {day} день", //translated TT022680
    __MIRA_1125_004: "Введите продолжительность цикла", //translated TT022690
    __MIRA_1125_005: "Невозможно изменить дни приема препарата, так как у него есть одновременный прием на {day} день", //translated TT022700

    __MIRA_1101_001: "Чтобы изменить дату для первого дня цикла, необходимо изменить дату начала цикла",
    __MIRA_1101_002: "Перенести назначения на предыдущий день",
    __MIRA_1101_003: "Перенести назначения на следующий день",
    __MIRA_1101_004: "Перенос назначений возможен только на свободную дату",
    __MIRA_1101_005: "Назначения успешно перенесены",
    __MIRA_1101_006: "Перенос невозможен, так как одно или несколько назначений уже выполнены",

    __MIRA_1192_001: "Комментарий к циклу лечения",

    __MIRA_1018_001: "Вернуть в работу",
    __MIRA_1018_002: "Вы уверены, что хотите вернуть в работу план лечения?",

    __MIRA_1129_001: "При добавлении цикла шаблона лечения произошла ошибка",

    __MIRA_666_001: "Добавить документ",
    __MIRA_666_002: "Удалить документ",
    __MIRA_666_003: "Вы уверены, что хотите удалить документ?",

    __MIRA_1232_001: "Исследования отсутствуют",

    __MIRA_1275_001: "Добавление нового заболевания",
    __MIRA_1275_002: "Добавить",

    __MIRA_1292_001: "Вы уверены, что хотите перевести Цикл лечения в работу?",

    __MIRA_1315_001: "Сбросить фильтры",

    __MIRA_1304_001: "Вернуть к рассмотрению",
    __MIRA_1304_002: "Перевод заявки в статус “Готова для рассмотрения“ невозможен, так как протокол находится в статусе “{status_name}“. Пожалуйста, обратитесь в службу поддержки.“",
    __MIRA_1304_003: "Вернуть на доработку",
    __MIRA_1304_004: "Перевод заявки в статус “Возвращена на доработку“ невозможен, так как протокол находится в статусе “{status_name}“",
    __MIRA_1304_005: "Вернуть в работу",
    __MIRA_1304_006: "Вы уверены, что хотите вернуть протокол в работу?",
    __MIRA_1304_007: "Вернуть к статусу Готов",
    __MIRA_1304_008: "Вы уверены, что хотите вернуть протокол к статусу Готов?",

    __MIRA_1275_003: "Добавить новое заболевание",

    __MIRA_1288_001: "Изменение даты невозможно, так как одно или несколько назначений уже выполнены",

    __MIRA_1349_001: "Недостаточно прав",

    __MIRA_1363_001: "Для препарата настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить назначение препарата?",
    __MIRA_1363_002: "Вы уверены, что хотите удалить назначение препарата?",
    __MIRA_1363_003: "Для препаратов из группы МН настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить Группу МН, включая все добавленные МН?",
    __MIRA_1363_004: "Удалить всю группу МН включая все добавленные МН?",
    __MIRA_1363_005: "После внесенных изменений необходимо повторно настроить порядок приема МН на вкладке “Лист назначений“",

    __MIRA_1400_001: "При активации шаблона лечения произошла ошибка",
    __MIRA_1400_002: "При архивировании шаблона лечения произошла ошибка",

    __MIRA_1418_001: "Редактировать текущее состояние пациента",
    __MIRA_1418_003: "Сохранить изменения",
    __MIRA_1418_004: "Отменить внесенные изменения",
    __MIRA_1418_005: "Подтвердите или отмените редактирование данных о текущем состоянии пациента",
    __MIRA_1418_006: "Вы действительно хотите отказаться от произведенных изменений?",
    __MIRA_1418_007: "Сохранить произведенные изменения?",
    __MIRA_1418_008: "Если вы измените значения ППТ / Клиренса креатинина дозы препаратов будут пересчитаны. И вам необходимо будет снова подтвердить их назначение. Изменить значения текущего состояния пациента?",
    __MIRA_1418_009: "Да",
    __MIRA_1418_010: "Нет",
    __MIRA_1418_011: "Сохранить",
    __MIRA_1418_012: "Отмена",
    __MIRA_1418_013: "Для расчёта доз необходимо ввести значение в поле “Рост”",
    __MIRA_1418_014: "Для расчёта доз необходимо ввести значение в поле “Вес”",
    __MIRA_1418_015: "Для расчёта доз необходимо ввести значение в поле “Ур. сыв. креатинина”",
    __MIRA_1418_016: "Для расчёта доз необходимо ввести значение в поле “Рост” и “Вес”",
    __MIRA_1418_017: "Необходимо ввести значение в поле “Рост” и “Вес”",
    __MIRA_1418_018: "Необходимо ввести значение в поле “Рост”",
    __MIRA_1418_019: "Необходимо ввести значение в поле “Вес”",

    __MIRA_1305_001: "закр.",

    __MIRA_1420_001: "Данные от",
    __MIRA_1420_002: "Для полного редактирования медикаментозного назначения необходимо снять отметки о введении во вкладке “Статус лечения”",

    __MIRA_1419_001: "Заполните поля Рост, Вес и Ур. сыв.креатинина",
    __MIRA_1419_002: "Для добавления препарата необходимо ввести данные в поля Рост и Вес",
    __MIRA_1419_003: "Для расчёта доз необходимо ввести значение в поле “Ур.сыв. креатинина”",
    __MIRA_1419_004: "Для расчёта доз необходимо ввести значение в поле “Вес”",
    __MIRA_1419_005: "Для расчёта доз необходимо ввести значения в поля “Рост” и “Вес”",
    __MIRA_1419_006: "Для редактирования МН заполните поле Ур. сыв. креат.",
    __MIRA_1419_008: "Для перевода цикла в статус \"В работе\" необходимо подтвердить все медикаментозные назначения",
    __MIRA_1419_009: "Для редактирования МН заполните поля Рост, Вес",

    __MIRA_1421_001: "После внесенных изменений необходимо повторно настроить порядок приема МН во вкладке “Лист назначений”",

    __MIRA_1422_001: "История состояния пациента",
    __MIRA_1422_002: "Дата",
    __MIRA_1422_003: "Рост (см)",
    __MIRA_1422_004: "Вес (кг)",
    __MIRA_1422_005: "ППТ (м2)",
    __MIRA_1422_006: "Ур. сыв. креат. (мкмоль/л)",
    __MIRA_1422_007: "Клиренс креатинина (мл/мин)",
    __MIRA_1422_008: "Пользователь",

    __MIRA_1423_015: "Закрыт",

    __MIRA_1487_001: "Для расчёта доз необходимо ввести значение в поле “Ур. сыв. креатинина”",

    __MIRA_1371_001: "Медикаментозное назначение успешно удалено",
    __MIRA_1371_002: "Не удалось удалить Медикаментозное Назначение",
    __MIRA_1371_003: "Чтобы удалить препарат, необходимо удалить все связи с ним",
    __MIRA_1371_004: "Нельзя удалить препарат, так как указан статус лечения",
    __MIRA_1371_005: "Не удалось удалить группу назначений",
    __MIRA_1371_006: "Группа назначений успешно удалена",
    __MIRA_1371_007: "Для препарата настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить назначение препарата?",
    __MIRA_1371_008: "Вы уверены, что хотите удалить назначение препарата?",
    __MIRA_1371_009: "Для препаратов из группы МН настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить Группу МН, включая все добавленные МН?",
    __MIRA_1371_010: "Удалить всю группу МН включая все добавленные МН?",
    __MIRA_1371_011: "После внесенных изменений необходимо повторно настроить порядок приема МН на вкладке “Лист назначений“",

    __MIRA_1587_001: "Ошибка отмены задач в МПП",
    __MIRA_1587_002: "Ошибка при сохранении изменений",
    __MIRA_1635_001: "Максимальное количество кандидатов - {count}",

    __MIRA_1383_001: "Для добавления препарата необходимо ввести данные в поля Рост, Вес. Сохраните значения.",

    __MIRA_1657_001: "Код КСГ",
    __MIRA_1657_002: "Кол-во дней ведения в тарифе",

    __MIRA_1685_001: "Отсутствуют",
    __MIRA_1685_002: "Отсутствуют",

    __MIRA_1764_001: "Группа МН должна содержать хотя бы одно медикаментозное назначение. Для сохранения Шаблона лечения необходимо добавить медикаментозное назначение в группу",

    __MIRA_1826_001: "Заполните дату начала цикла лечения",

    __MIRA_1822_001: "Печать листа назначения",

    __MIRA_1854_001: "Для перевода цикла в статус {status} необходимо заполнить поле \"Рост\"",
    __MIRA_1854_002: "Для перевода цикла в статус {status} необходимо заполнить поле \"Вес\"",
    __MIRA_1854_003: "Для перевода цикла в статус {status} необходимо заполнить поле \"Прод. цикла (д)\"",
    __MIRA_1854_004: "Продолжительность цикла не может быть меньше одного дня",
    __MIRA_1854_005: "Для перевода цикла в статус {status} необходимо выбрать медикаментозное назначение из всех основных групп",
    __MIRA_1854_006: "Для перевода цикла в статус {status} необходимо создать медикаментозные назначения",

    __MIRA_1732_001: "Выберите сертификат для подписания документа",
    __MIRA_1732_002: "Отсутствует файл для подписи",
    __MIRA_1854_007: "",
    __MIRA_1854_008: "",
    __MIRA_1854_009: "",

    __MIRA_1866_001: "Нет доступных значений",
    __MIRA_1866_002: "Дополнительные классификаторы и биомаркеры",
    __MIRA_1866_003: "Заполните клиническое описание диагноза",
    __MIRA_1866_004: "Внимание!",
    __MIRA_1866_005: "Загрузка документов",
    __MIRA_1866_006: "СНИЛС идентификатор MIRA-1348",
    __MIRA_1866_007: "Новый датапикер MIRA-547 v3",
    __MIRA_1866_008: "Список загружаемых справочников",
    __MIRA_1866_009: "Выберите шаблоны лечения для экспорта",
    __MIRA_1866_010: "Код схемы",
    __MIRA_1866_011: "Наименование",
    __MIRA_1866_012: "Выберите архив для импорта шаблонов",
    __MIRA_1866_013: "Выберите xlsx для импорта шаблонов",
    __MIRA_1866_014: "Импортировать XLSX",
    __MIRA_1866_015: "Стадия импорта: {stageImportXlsxText}",
    __MIRA_1866_017: "Укажите обоснование для изменения схемы лечения",
    __MIRA_1866_018: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10:",
    __MIRA_1866_019: "Выберите другой Протокол ОК.",
    __MIRA_1866_020: "Измените диагноз МКБ-10 в Протоколе осмотра врача.",
    __MIRA_1866_021: "Выберите \"Протокол ОК из другого медучреждения\".",
    __MIRA_1866_022: "Отмените выбор протокола ОК в поле \"Онкоконсилиум\".",
    __MIRA_1866_023: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом, полученным из Протокола осмотра врача:",
    __MIRA_1866_024: "Добавление плана лечения по диагнозу C97 запрещено, необходимо изменить диагноз МКБ-10 в Протоколе осмотра врача",
    __MIRA_1866_025: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10:",
    __MIRA_1866_026: "Назначен",
    __MIRA_1866_027: "Не удалось загрузить данные плана лечения",
    __MIRA_1866_028: "Если были внесены изменения в Цикл лечения, необходимо повторно распечатать Лист назначений",
    __MIRA_1866_029: "Рассчитанное по формуле Мостеллера",
    __MIRA_1866_030: "Рассчитанное по формуле Кокрофта-Голта",
    __MIRA_1866_031: "Загрузка...",
    __MIRA_1866_032: "Невозможно удалить план лечения. В плане имеются циклы со статусами отличными от \"Создан\"/\"Удален\"/\"Скопирован\"",
    __MIRA_1866_033: "Наименование плана лечения не может быть пустым",
    __MIRA_1866_034: "Вы уверены, что хотите завершить план лечения?",
    __MIRA_1866_035: "Добавлены группы из всех шаблонов",
    __MIRA_1866_036: "При добавлении групп из всех шаблонов произошла ошибка",
    __MIRA_1866_037: "Добавление всех шаблонов",
    __MIRA_1866_038: "При запросе к AidboxQuery 'appointment-info-search' произошла ошибка",
    __MIRA_1866_039: "Формат файла не поддерживается",
    __MIRA_1866_040: "Файл успешно загружен",
    __MIRA_1866_041: "При загрузке файла произошла ошибка",
    __MIRA_1866_042: "Вы действительно хотите удалить {subject} {code}?",
    __MIRA_1866_043: "Коды заболеваний по МКБ 10",
    __MIRA_1866_044: "Нельзя сохранить заявку, так как не указано название одного из пунктов плана обследования",
    __MIRA_1866_045: "Ошибка при удалении заявки",
    __MIRA_1866_046: "Заполните анамнез",
    __MIRA_1866_047: "Объективный статус",
    __MIRA_1866_048: "Просмотр документа",
    __MIRA_1866_049: "Заполните общее состояние",
    __MIRA_1866_050: "Без наименования",
    __MIRA_1866_051: "Вы можете добавить пункт плана обследования, нажав на пиктограмму \"плюсик\" в списке слева.",
    __MIRA_1866_052: "Или добавьте новый план обследования.",
    __MIRA_1866_054: "Выписной эпикриз стационара",
    __MIRA_1866_055: "Протокол операции",
    __MIRA_1866_056: "Гистология",
    __MIRA_1866_057: "Лабораторное исследование",
    __MIRA_1866_058: "Заключение онкоконсилиума",
    __MIRA_1866_059: "КТ исследование",
    __MIRA_1866_060: "МРТ исследование",
    __MIRA_1866_061: "ПЭТ/КТ исследование",
    __MIRA_1866_062: "Выписка из амбулаторной карты",
    __MIRA_1866_063: "Консультация врача специалиста",
    __MIRA_1866_064: "Маммография",
    __MIRA_1866_065: "Ультразвуковое исследование",
    __MIRA_1866_067: "Укажите уникальное наименование Шаблона лечения",
    __MIRA_1866_068: "Загрузка информации",
    __MIRA_1866_069: "Загрузка списка организаций...",
    __MIRA_1866_070: "Новая специализация",
    __MIRA_1866_071: "Выбор специализации",
    __MIRA_1866_072: "Новая специализация",
    __MIRA_1866_073: "Выбор специализации",
    __MIRA_1866_074: "Просмотр документа",
    __MIRA_1866_075: "Редактирование документа",
    __MIRA_1866_076: "Клинические рекомендации",
    __MIRA_1866_077: "Получение списка...",
    __MIRA_1866_078: "Удалить",
    __MIRA_1866_079: "",
    __MIRA_1866_80: "Организация",
    __MIRA_1866_81: "Тип стационара",
    __MIRA_1866_82: "Подождите. Идет создание заявок...",

    __MIRA_1131_001: "Еще", //translated SS9036000
    __MIRA_1144_001: "В данный момент уже имеется Цикл лечения в статусе \"Назначен\". Завершите его, прежде чем продолжить работу с этим Циклом лечения.", //translated TT022710
    __MIRA_1100_001: "Нельзя удалить препарат, так как в {value} день состоит в одновременном приеме", //translated TT022620
    __MIRA_1100_002: "Нельзя отменить {value} день, так как препарат состоит в одновременном приеме", //translated TT022630
    __MIRA_1100_003: "Нельзя удалить группу, так как в {value} день состоит в одновременном приеме", //translated TT022640
    __MIRA_1100_004: "Нельзя отменить {value} день, так как группа состоит в одновременном приеме", //translated TT022650

    __MIRA_725_001: "Валидация протокола", //translated SS9036010
    __MIRA_725_002: "Внимание", //translated SS9036020
    __MIRA_725_003: "Смена статуса протокола", //translated SS9036030
    __MIRA_725_004: "Особое мнение участника", //translated SS9036040
    __MIRA_725_005: "Предыдущие протоколы онкоконсилиума", //translated SS9036050
    __MIRA_725_006: "Выберите", //translated TT022730
    __MIRA_725_007: "Укажите", //translated TT022740
    __MIRA_725_008: "организацию", //translated TT022750
    __MIRA_725_009: "должность", //translated TT022760
    __MIRA_725_010: "сотрудника", //translated TT022770
    __MIRA_725_011: "специализацию", //translated TT022780
    __MIRA_725_012: "При загрузке протокола произошла ошибка", //translated TT022790
    __MIRA_725_013: "Не найдена информация о пациенте", //translated TT022800
    __MIRA_725_014: "При поиске организаций произошла ошибка", //translated TT022810
    __MIRA_725_015: "Протокол не найден", //translated TT022820
    __MIRA_725_016: "Нет ссылок на реальных участников", //translated TT022830
    __MIRA_725_017: "При поиске состава онкологического консилиума произошла ошибка", //translated TT022840
    __MIRA_725_018: "Не найдена ссылка на организацию в протоколе", //translated TT022850
    __MIRA_725_019: "Организация не найдена", //translated TT022860
    __MIRA_725_020: "Заявка не найдена", //translated TT022870
    __MIRA_725_021: "Нет ссылки на документ", //translated TT022880
    __MIRA_725_022: "Документ не найден", //translated TT022890
    __MIRA_725_023: "Документ успешно подписан", //translated TT022900
    __MIRA_725_024: "У авторизованного пользователя не указан emias ID", //translated TT022910
    __MIRA_725_025: "Ошибка при подписании документа", //translated TT022920
    __MIRA_725_026: "Неизвестная ошибка", //translated TT022930
    __MIRA_725_027: "Передача данных о документах", //translated TT022940
    __MIRA_725_028: "Получение контекста", //translated TT022950
    __MIRA_725_029: "Агент СЭП был закрыт", //translated TT022960
    __MIRA_725_030: "При смене статуса протокола произошла ошибка", //translated TT022970
    __MIRA_725_031: "При присвоении номера для протокола произошла ошибка", //translated TT022980
    __MIRA_725_032: "валидация не требуется", //translated TT022990
    __MIRA_725_033: "валидации тактик лечения пройдена", //translated TT023000
    __MIRA_725_034: "Номер протокола присвоен", //translated TT023010
    __MIRA_725_035: "Электронный экземпляр документа прикреплен", //translated TT023020
    __MIRA_725_036: "Необходимо прикрепить электронный экземпляр документа", //translated TT023030
    __MIRA_725_037: "Дата проведения консилиума указана", //translated TT023040
    __MIRA_725_038: "Тип консилиума указан", //translated TT023050
    __MIRA_725_039: "Цель проведения консилиума указана", //translated TT023060
    __MIRA_725_040: "Все участники указаны", //translated TT023070
    __MIRA_725_041: "{participant} не указан", //translated TT023080
    __MIRA_725_042: "Один из участников", //translated TT023090
    __MIRA_725_043: "Секция с анамнезом заболевания заполнена", //translated TT023100
    __MIRA_725_044: "Секция с общим состоянием заполнена", //translated TT023110
    __MIRA_725_045: "Секция с представленными медицинскими документами заполнена", //translated TT023120
    __MIRA_725_046: "Заполните представленные медицинские документы", //translated TT023130
    __MIRA_725_047: "Результат проведения консилиума указан", //translated TT023140
    __MIRA_725_048: "Валидация диагностических методов пройдена", //translated TT023150
    __MIRA_725_049: "Необходимо заполнить поле Диагностический метод #{number}", //translated TT023160
    __MIRA_725_050: "Необходимо заполнить Место проведения #{number}", //translated TT023170
    __MIRA_725_051: "Поле \"куда направлен пациент\" заполнено", //translated TT023180
    __MIRA_725_052: "Укажите куда направлен пациент", //translated TT023190
    __MIRA_725_053: "Секция с особыми мнениями участников консилиума проверена", //translated TT023200
    __MIRA_725_054: "Протокол ссылается сразу на несколько заявок", //translated TT023210
    __MIRA_725_055: "Заявка рассмотрена на консилиуме", //translated TT023220

    __MIRA_1157_001: "Протокол не ссылается ни на одну из заявок", //translated TT022720
    __GLB_609_001: "Новообразование",
    __GLB_609_002: "Заболевание",

    __GLB_617_001: "Изменения сохранены",

    __GLB_645_001: "Номер полиса ОМС",

    __GLB_690_1: 'Группа',
    __GLB_690_2: 'Дополнительные препараты на выбор',

    __GLB_703_1: "Организация",
    __GLB_703_2: "Филиал, структурное подразделение",
    __GLB_711_1: "Препарат успешно выбран",
    __GLB_725_1: "от {first_value} до {second_value} {unit}",
    __GLB_778_1: "Выберите препарат",

    __GLB_594_1: "Отображать в печатной форме",
    __GLB_594_2: "Клинические исследования отсутствуют",

    __MIRA_1338_1: "Консилиум был изменён другим пользователем, необходимо обновить страницу и внести изменения заново",
    __MIRA_2007_01: "Добавить все шаблоны",

    __MIRA_2125_1: "Консилиум создан",
    __MIRA_2125_2: "Консилиум отменен",

    __MIRA_1995_01: "Показать все",

    __MIRA_2267_01: "Данные о росте, весе и ур.сыв. креатинина актуальны",

    __MIRA_1984_01: "Не удалось создать Шаблон лечения. Пожалуйста, обновите страницу и повторите действие.",
    __MIRA_1984_02: "Сохранение как Шаблон лечения…",

    __MIRA_2385_01: "Участник с ЭП уже выбран.\nВыключите ЭП у текущего участника, чтобы назначить нового.",
    __MIRA_2385_02: "Электронная подпись может быть применена только для одного участника консилиума.",

    __MIRA_1142_01: "Не указана группа ЗНО",
    __MIRA_1142_02: "Не указана дата одного из морфологических исследований",
    __MIRA_1142_03: "Не указана организация одного из морфологических исследований",
    __MIRA_1142_04: "Не указан тип одного из морфологических исследований",
    __MIRA_1142_05: "Не указан результат одного из морфологических исследований",
    __MIRA_1142_06: "Не указан номер одного из молекулярно-генетических исследований",
    __MIRA_1142_07: "Не указана дата одного из молекулярно-генетических исследований",
    __MIRA_1142_08: "Не указана организация одного из молекулярно-генетических исследований",
    __MIRA_1142_09: "Не указаны показатели одного из молекулярно-генетических исследований",
    __MIRA_1142_10: "Не указан показатель одного из молекулярно-генетических исследований",
    __MIRA_1142_11: "Не указан результат одного из молекулярно-генетических исследований",
    __MIRA_1142_12: "Не указан номер одного из иммуногистохимических исследований",
    __MIRA_1142_13: "Не указана дата одного из иммуногистохимических исследований",
    __MIRA_1142_14: "Не указана организация одного из иммуногистохимических исследований",
    __MIRA_1142_15: "Не указаны показатели одного из иммуногистохимических исследований",
    __MIRA_1142_16: "Не указан показатель одного из иммуногистохимических исследований",
    __MIRA_1142_17: "Не указан результат одного из иммуногистохимических исследований",
    __MIRA_1142_18: "При сохранении заболевания произошла ошибка",
    __MIRA_1142_19: "Информация о заболевании сохранена",
    __MIRA_1142_20: "Добавление записи в историю лечения",
    __MIRA_1142_21: "Редактирование записи из истории лечения",
    __MIRA_1142_22: "Добавлена запись в историю лечения основного заболевания",
    __MIRA_1142_23: "Запись из истории лечения основного заболевания обновлена",
    __MIRA_1142_24: "Запись из истории лечения основного заболевания удалена",
    __MIRA_2048_01: "Введите корректное отчество",
    __MIRA_2659_01: "Удалить показатель",
    __MIRA_2771_01: "При изменении статуса нотификации {notification_id} произошла ошибка",
    __MIRA_2809_01: "Создание протокола",
    __MIRA_2818_01: "Добавление пункта плана обследования",
    __MIRA_2818_02: "Редактирование пункта плана обследования",
    __MIRA_2818_03: "Редактировать",
    __MIRA_2866_01: "В презентации отсутствует информация для отображения. Пожалуйста, перейдите на экран редактирования презентации и включите отображение слайдов/блоков",

    __FCSPOK_31_01: "Укажите причину проведения консилиума",
    __FCSPOK_31_02: "Причина проведения консилиума",
    __FCSPOK_31_03: "Форма консилиума",
    __FCSPOK_31_04: "Укажите форму проведения консилиума",

    __FCSPOK_60_01: "Степень тяжести состояния пациента",
    __FCSPOK_16_01: "Укажите признак \"Уровень проведения\"",
    __FCSPOK_16_02: "Уровень проведения",

    __FCSPOK_83_01: "Контрольный список документов и исследований",
    __FCSPOK_83_02: "Контрольный список документов и исследований",
    __FCSPOK_83_03: "Код или название заболевания",
    __FCSPOK_83_04: "Коды или названия заболеваний",

    __FCSPOK_378_01: "Укажите \"{fieldName}\" рекомендуемой тактики лечения для онкологического заболевания",

    __FCSPOK_96_01: "Консилиум уже начался, записать заявку на него невозможно",

    __FCSPOK_457_01: "Перенос заявки",

    __FCSPOK_286: "Не указан морфологический тип опухоли диагноза {mkb10}: {name}",
    
    __FCSPOK_554_01: "Добавить приказ",
};
