import moment from "moment";
import { locales } from "Locale";
import { observable } from "mobx";
import { Translator } from "eo-locale";
import { getFormat } from "Locale/datetime";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";


/**
 * дата рождения + возраст
 */
export class PatientBirthDay {

    /**
     * ссылка на ресурс пациента
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * дата рождения
     */
    get display() {
        if ( this.resource.birthDate ) {
            return moment(this.resource.birthDate).format(getFormat("DT000001"));
        }
        else {
            return ''
        }
    }

    /**
     * дата (объект)
     */
    get date() {
        return this.resource.birthDate ? new Date(this.resource.birthDate) : null;
    }

    /**
     * кол-во лет
     */
    get years() {
        if( ! this.resource.birthDate ) {
            return 0;
        }
        return moment().diff(this.resource.birthDate, 'years');
    }

    /**
     * возраст пациента (например, 32 года)
     * https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals
     */
    get age() {
        const translator = new Translator(HeaderStore.locale, locales);
        if( ! this.resource.birthDate ) {
            return 'n/a';
        }
        try {
            let id = "";
            const { years } = this;
            const modulo = years % 10;
            if(years >= 11 && years <= 14) {
                id = "PP003001";
            }
            else if(modulo == 1) {
                id = "PP003002";
            }
            else if(modulo == 2 || modulo == 3 || modulo == 4) {
                id = "PP003003";
            }
            else {
                id = "PP003001";
            }
            return translator.translate(id, {"years": years});
        } catch(e) {
            return 'n/a';
        }
    }

}
