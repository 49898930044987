import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import styled from 'styled-components';
import { PractitionerRolePopup } from 'GUI_MAIN/Components/PractitionerRole/PractitionerRolePopup';
import {PopupBasic} from "GUI_MAIN/Elements/Popups/PopupBasic";
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";
import {resetPatientsHideDataSettings} from "GUI_MAIN/Utils/localStorageSettings";
import {clearFiltersInLocalStorage} from "GUI_MAIN/Components/Header/clearFiltersInLocalStorage";
import Button from "GUI_MAIN/Elements/Button/index";
import { Header } from 'GUI_MAIN/Components/Header';
import SideMenu from 'GUI_MAIN/Components/SideMenu/SideMenu';
import ContextualMenu from "./GUI_MAIN/Elements/ContextualMenu";
import { DocumentViewer } from 'GUI_MAIN/Components/DocumentViewer';
import Preloader from "./GUI_MAIN/Elements/Preloader";
import {StyledReactTooltip, overPos, overPosFloat} from "./GUI_MAIN/Elements/StyledReactTooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Routs as AuthRouts} from "./Auth/Routs/Routs";
import {Paths} from "Paths";
import {LazyComponent} from "./LazyComponent";
import {errorBoundary} from "./GUI_MAIN/ErrorBoundary/ErrorBoundary";
import {EOLocale, Translator} from "eo-locale";
import {locales} from "./Locale";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { AccessPoint } from "GUI_MAIN/Components/MisIntegration/AccessPoint";
import { ContextPage } from "GUI_MAIN/Components/MisIntegration/ContextPage";
import { Maintenance } from "GUI_MAIN/Components/Maintenance";
import { observable } from "mobx";

const MainRouts = React.lazy(() => import('GUI_MAIN/Routs/Routs'));
const TBRouts = React.lazy(() => import('GUI_TB/Routs/Routs'));

const MainWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 1511px;
  margin: 0 auto;
`;

const PopupContext = styled.div`
  font-family: ${props => props.theme.OpenSansRegular};
  color: ${props => props.theme.darkMainTextColor};
  font-size: ${props => props.theme.mediumFontSize};
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  flex-grow: 1;
  width: calc(100% - 240px);
  height: calc(100vh - 64px);
  background-color: #ffffff;
  overflow-y: hidden; //auto

  ::-webkit-scrollbar {height: 6px;}
  ::-webkit-scrollbar-track { background-color: transparent;}
  ::-webkit-scrollbar-thumb { height: 6px; background-color: rgba(0, 0, 0, 0.2); border-radius: 3px;}
`;

const BuildInfo = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    color: rgba(255, 255, 255, 0.75);
    font-size: 10px;
    padding: 2px 4px;
    cursor: pointer;
`;

@errorBoundary(null, null, null, null, "APP")
@inject('PractitionerRolePopupStore')
@inject('AuthStore')
@inject('ContextualMenuStore')
@inject('ToasterStore')
@inject('SideMenuStore')
@inject('HeaderStore')
@inject('MedicalCasesTableStore')
@observer
export class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
        props.AuthStore.testCurrentLogged().then(this.onLogin, this.onError);

        this.props.HeaderStore.initLocale();

        this.checkMaintenance(); // https://jira.mmdx.ru/browse/FCSPOK-834
    }

    /**
     *
     */
    onLogin = () => {
        this.setState({loading: false});
        console.log('App data init started');
    };

    /**
     *
     */
    onError = (e) => {
        this.setState({
            loading: false
        });
        console.warn('test current login exception:', e);
    };

    /**
     *
     */
    renderBuildInfo = () => {

        const buildVersion = __BRANCH__ + ' ' + __COMMIT_HASH__.substr(0, 6);
        const translator = new Translator(this.props.HeaderStore.locale, locales);

        return (
            <CopyToClipboard text={buildVersion} onCopy={() => this.props.ToasterStore.info(translator.translate('TT010020'))}>
                <BuildInfo>
                    { buildVersion }
                </BuildInfo>
            </CopyToClipboard>
        );
    }

    /**
     * Каждые 10 секунд фронт должен обращаться к бэку в aidbox. Если ресурс с текстом уведомления для
     * пользователей существует,то фронт должен отображать уведомление, если ресурса нет, то уведомление не выводится.
     * https://jira.mmdx.ru/browse/FCSPOK-834
     */
    checkMaintenance = (delay = 0) => {
        setTimeout(() => {
            basicRequestGet("/api/mm/maintenance/notification").then(response => {
                if ( response.data && response.data.message && response.data.message != "<empty>" ) {
                    this.maintenanceMessage = response.data.message;
                } else {
                    this.maintenanceMessage = null;
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.checkMaintenance(10000);
            })
        }, delay);
    }

    doLogout = () => {
        this.props.AuthStore.logout();
        PagePaginationStore.clearAll();
        // сброс настроек для презентаций (скрытие/отображение имени пациента)
        resetPatientsHideDataSettings();
        clearFiltersInLocalStorage();
    };

    /**
     * сообщение об аварийном режиме работы
     */
    @observable maintenanceMessage = null;

    /**
     * отрисовка
     */
    render() {

        if( this.maintenanceMessage ) {
            return <Maintenance message={this.maintenanceMessage}/>
        }

        if( this.state.loading ) {
            return <Preloader/>;
        }

        if ( ! this.props.AuthStore.currentUserData.ready ) {
            return <AuthRouts completed={() => this.onLogin()} />
        }

        // TODO тут явно наступим на какие-нибудь грабли с z-index нескольких видимых попапов
        // компонент Header берет данные из HeaderStore (singleton)

        // TODO Пока не трогать !!!
        console.log(this.props.ContextualMenuStore.componentNumber);
        //---

        return (
            <EOLocale.Provider  language={this.props.HeaderStore.locale} locales={locales}>
                <Header/>
                <>
                    { this.props.PractitionerRolePopupStore.popupVisible ? <PractitionerRolePopup /> : null }
                </>
                <>
                    {this.props.ContextualMenuStore.isOpen && <ContextualMenu/>}
                </>
                {
                    this.props.AuthStore.currentUserData.sessionInvalid &&
                    <PopupBasic
                        size={'sessionEnd'}
                        title={"Время сеанса истекло"}
                        onClose={() => {}}
                        padding={'40px 0px'}
                        btnCloseHidden
                        bottomActions={
                            <Button label={'Обновить сессию'}
                                    onClick={this.doLogout}
                                    BGcolor={'dark'}
                                    padding={'5px 15px'}
                                    fontWeight={'normal'}
                            />
                        }
                    >
                        <PopupContext>К сожалению, Ваша пользовательская сессия истекла. Пожалуйста, обновите сессию.</PopupContext>
                    </PopupBasic>
                }
                <DocumentViewer />
                { this.renderBuildInfo() }
                <MainWrapper>
                    <SideMenu isMenuShow={this.state.toggleMenu}/>

                    <Content>
                        <Switch>
                            <Route path={ Paths.tb.servicerequest.mask() } children={ props => <LazyComponent component={TBRouts} data={props} /> } />
                            <Route path={ Paths.tb.appointment.mask() } children={ props => <LazyComponent component={TBRouts} data={props} /> } />
                            <Route path={ Paths.tb.protocol.mask() } children={ props => <LazyComponent component={TBRouts} data={props} /> } />
                            <Route path={ Paths.tb.careteam.mask() } children={ props => <LazyComponent component={TBRouts} data={props} /> } />
                            <Route path={ Paths.dashboard.mask() } children={ props => <LazyComponent component={TBRouts} data={props} /> } />

                            <Route path={ Paths.main.patient.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.practitioner.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.organization.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.directions.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.condition.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.settings.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.diagnosis.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.documents.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />
                            <Route path={ Paths.main.rolesmanagement.mask() } children={ props => <LazyComponent component={MainRouts} data={props} /> } />

                            {/* https://jira.mmdx.ru/browse/FCSPOK-321 */}
                            <Route
					            path={"/integration/accesspoint"}
					            children={props => <LazyComponent component={AccessPoint} data={props}/>}
				            />
				            <Route
					            path={"/integration/context/(:id)"}
                                children={props => <LazyComponent component={ContextPage} data={props}/>}
				            />
                            {/* https://jira.mmdx.ru/browse/FCSPOK-321 */}

                            <Route exact path="/"><Redirect to={ Paths.dashboard.mask() } /></Route>
                            <Route exact path=""><Redirect to={ Paths.dashboard.mask() } /></Route>
                        </Switch>
                    </Content>
                </MainWrapper>
                <StyledReactTooltip
                    className={'light light-simple tooltip-menu'}
                    type="light"
                    place='bottom'
                    effect="solid"
                    overridePosition={overPos}
                />
                <StyledReactTooltip
                  id={"float-tooltip"}
                  className={'light light-simple tooltip-menu'}
                  type="light"
                  place='bottom'
                  effect="float"
                  overridePosition={overPosFloat}
                  html={true}
                />
             </EOLocale.Provider>
        );
    }
}
