import { observable, computed, autorun, action } from 'mobx';

class ClientWindowStoreClass{
    @observable
    clientWindowHeight =  document.documentElement.clientHeight

    @action
    set(){
        window.addEventListener('resize', () => {
            this.clientWindowHeight = document.documentElement.clientHeight
        })
    }

}

const ClientWindowStore = new ClientWindowStoreClass();

export { ClientWindowStore };