import _get from "lodash/get";

/**
 * в рамках какого документа создается заболевание
 * https://jira.mmdx.ru/browse/MIRA-2042
 */
export class ConditionSourceType {

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/mmdx-cs-diagnosis-source-type";

    /**
     * Диагноз из профиля пациента
     */
    static PATIENT_PROFILE = {
        "code": "patientInfo-diagnosis",
        "system": ConditionSourceType.CODING_SYSTEM,
        "display": "Диагноз из профиля пациента",
    }

    /**
     * Диагноз из заявки на онкоконсилиум
     */
    static CONSILIUM_REQUEST = {
        "code": "consiliumRequest-diagnosis",
        "system": ConditionSourceType.CODING_SYSTEM,
        "display": "Диагноз из заявки на онкоконсилиум",
    }

    /**
     * Диагноз из протокола онкоконсилиума
     */
    static CONSILIUM_PROTOCOL = {
        "code": "consiliumProtocol-diagnosis",
        "system": ConditionSourceType.CODING_SYSTEM,
        "display": "Диагноз из протокола онкоконсилиума"
    }

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * ссылка на категорию
     */
    get category() {
        return _get(this.conditionResource, "category", []).find(category => {
            return _get(category, "coding", []).find(coding => {
                return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
            });
        });
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.category, "coding", []).find(coding => {
            return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
        })
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название (например, Диагноз из заявки на онкоконсилиум)
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * обновление расширения
     * coding - [PATIENT_PROFILE, CONSILIUM_REQUEST, CONSILIUM_PROTOCOL]
     */
    update = (coding) => {
        if ( ! Array.isArray(this.conditionResource.category) ) {
            this.conditionResource.category = []
        }
        const categoryElement = {
            "text": coding.display,
            "coding": [coding]
        }
        const index = this.conditionResource.category.findIndex(category => {
            return _get(category, "coding", []).find(coding => {
                return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
            })
        })
        if ( index >= 0 ) {
            this.conditionResource.category[index] = categoryElement
        } else {
            this.conditionResource.category.push(categoryElement)
        }
    }

    /**
     * диагноз из протокола?
     */
    get isProtocol() {
        return !! _get(this.conditionResource, "category", []).find(category => {
            return _get(category, "coding", []).filter(coding => {
                return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
            }).find(coding => {
                return _get(coding, "code") == "consiliumProtocol-diagnosis";
            })
        })
    }

    /**
     * диагноз из профиля пациента?
     */
    get isPatientProfile() {
        return !! _get(this.conditionResource, "category", []).find(category => {
            return _get(category, "coding", []).filter(coding => {
                return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
            }).find(coding => {
                return _get(coding, "code") == "patientInfo-diagnosis";
            })
        })
    }

    /**
     * диагноз из заявки?
     */
    get isServiceRequest() {
        return !! _get(this.conditionResource, "category", []).find(category => {
            return _get(category, "coding", []).filter(coding => {
                return _get(coding, "system") == ConditionSourceType.CODING_SYSTEM;
            }).find(coding => {
                return _get(coding, "code") == "consiliumRequest-diagnosis";
            })
        })
    }

}
