import _get from "lodash/get";

/**
 * класс для управления типом первично-множественной опухоли
 */
export class ConditionMultipleTumorType {

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.99.2.141";

    /**
     * url расширения
     */
    static EXTENSION_URL = "http://miramedix.ru/fhir/StructureDefinition/condition-multipleTumorType";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * ссылка на расширения
     */
    get extension() {
        return _get(this.conditionResource, "extension", []).find(extension => {
            return _get(extension, "url") == ConditionMultipleTumorType.EXTENSION_URL;
        });
    }

    /**
     * индекс расширения
     */
    get extensionIndex() {
        return _get(this.conditionResource, "extension", []).findIndex(extension => {
            return _get(extension, "url") == ConditionMultipleTumorType.EXTENSION_URL;
        });
    }

    /**
     * ссылка на концепт
     */
    get concept() {
        return _get(this.extension, "valueCodeableConcept");
    }

    /**
     * ссылка на кодирование
     */
    get coding() {
        return _get(this.concept, "coding", []).find(coding => {
            return _get(coding, "system") == ConditionMultipleTumorType.CODING_SYSTEM;
        });
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение (ReactSelect)
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * изменение
     */
    onChange = ({concept}) => {
        const extension = {
            "url": ConditionMultipleTumorType.EXTENSION_URL,
            "valueCodeableConcept": {
                "text": concept.definition,
                "coding": [{
                    "code": concept.code,
                    "system": concept.system,
                    "display": concept.display,
                    "version": concept.version,
                }]
            }
        }
        if ( ! Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = [];
        }
        const { extensionIndex } = this;
        if( extensionIndex >= 0 ) {
            this.conditionResource.extension[extensionIndex] = extension;
        } else {
            this.conditionResource.extension.push(extension);
        }
    }

    /**
     * сброс значения
     */
    reset = () => {
        if ( Array.isArray(this.conditionResource.extension) ) {
            const { extensionIndex } = this;
            if( extensionIndex >= 0 ) {
                this.conditionResource.extension.splice(extensionIndex, 1);
            }
        }
    }

}
