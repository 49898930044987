const Paths = {
    registration: {
        token: {
            path: (token) => `/registration/${token}`,
            mask: () => `/registration/(:token)`,
        }
    },
    password: {
        reset: {
            path: () => `/password-reset`,
            mask: () => `/password-reset`,
        },
        change_request: {
            path: () => `/password-request`,
            mask: () => `/password-request`,
        },
    },

	testing : {
        element : {
            path : () => `/testing/element`,
            mask : () => `/testing/element`,
        }
	},

};

export { Paths };
