/**
 * https://www.hl7.org/implement/standards/fhir/datatypes.html#Identifier
 */

import { Element } from './Element';
import { IDENTIFIER_USE } from '../CodeSystem/IdentifierUse';
import { Period } from './Period';



export class Identifier extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Identifier';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'assigner',
        ]);

        this._directCopyFields(data, ['use', 'system', 'value']);

        // TODO тут тоже омделька должна быть или что-то такое
        if (data.hasOwnProperty('type')) {
            this._data.type = data.type
        }

        if (data.hasOwnProperty('period')) {
            this._data.period = new Period(data.period)._data;
        }

        if (data.hasOwnProperty('period')) {
            this._data.period = new Period(data.period)._data;
        }

    }

    /**
     *
     */
    _validate() {

        super._validate();

        if ( ! this._validateFieldCode(this._data.use, IDENTIFIER_USE)) {
            throw 'Identifier.use is incorrect';
        }

        if (this._data.period) {
            new Period(this._data.period)._validate();
        }

        // TODO check system
        // TODO check value if possible
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get use() {
        return this._data.use;
    }

    /**
     *
     */
    set use(newValue) {
        this._data.use = newValue;
    }

    /**
     *
     */
    get period() {
        return new Period(this._data.period);
    }

    /**
     * если здесь нашли какой-то баг - он должен быть в ресурсе Coverage, туда скопипастил
     */
    set period(newValue) {
        this._data.period = newValue._data;
    }

    /**
     *
     */
    get system() {
        return this._data.system;
    }

    /**
     *
     */
    set system(newValue) {
        this._data.system = newValue;
    }

    /**
     *
     */
    get value() {
        return this._data.value;
    }

    /**
     *
     */
    set value(newValue) {
        this._data.value = newValue;
    }




}
