import _get from "lodash/get";

/**
 * класс для управления статусом заболевания (установлено)
 */
export class ConditionClinicalStatus {

    /**
     * система кодирования (Condition.clinicalStatus.coding.system)
     */
    static CODING_SYSTEM = "http://terminology.hl7.org/CodeSystem/condition-clinical";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * концепт
     */
    get concept() {
        return _get(this.conditionResource, "clinicalStatus")
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.concept, "coding", []).find(coding => {
            return _get(coding, "system") == ConditionClinicalStatus.CODING_SYSTEM;
        });
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение статуса (ReactSelect)
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * изменение статуса
     */
    onChange = (value) => {
        if (!value) return this.reset()
        const {concept} = value;
        this.conditionResource.clinicalStatus = {
            "coding": [{
                "code": concept.code,
                "system": concept.system,
                "display": concept.display
            }]
        }
    }

    /**
     * сброс статуса
     */
    reset = () => {
        delete (this.conditionResource.clinicalStatus);
    }

}
