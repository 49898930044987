import _get from "lodash/get";
import { DistantMetastasesObservation } from "MODEL_STORE/MIRAMEDIX/Models/DistantMetastasesObservation";
import { ResearchObservationType } from "MODEL_STORE/MIRAMEDIX/Models/ResearchObservation/ResearchObservationType";
import { MorphologicalObservationType } from "MODEL_STORE/MIRAMEDIX/Models/MorphologicalObservation/MorphologicalObservationType";
import { ClassificationObservationStagingType } from "MODEL_STORE/MIRAMEDIX/Models/ClassificationObservation/ClassificationObservationStagingType";

/**
 * Condition.stage[]
 */
export class ConditionStage {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
        if( ! Array.isArray(this.conditionResource.stage) ) {
            this.conditionResource.stage = [];
        }
    }

    /**
     * ссылка на клиническую стадию
     */
    get clinicalStage() {
        const {
            CODING_SYSTEM,
            CLINICAL_CODE
        } = ClassificationObservationStagingType;
        return _get(this.conditionResource, "stage", []).find(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == CLINICAL_CODE);
        });
    }

    /**
     * ссылка на патологоанатомическую стадию
     */
    get pathologicalStage() {
        const {
            CODING_SYSTEM,
            PATHOLOGICAL_CODE
        } = ClassificationObservationStagingType;
        return _get(this.conditionResource, "stage", []).find(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == PATHOLOGICAL_CODE);
        });
    }

    /**
     * ссылка на локализацию отдаленных метастазов
     */
    get distantMetastasesStage() {
        const {
            CODING_CODE,
            CODING_SYSTEM
        } = DistantMetastasesObservation;
        return _get(this.conditionResource, "stage", []).find(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == CODING_CODE);
        });
    }

    /**
     * морфологические исследования
     * 1. неуточненные
     * 2. цитологические
     * 3. гистологические
     */
    get morphologicalStages() {
        return [
            ...this.unspecifiedMorphologicalStages,
            ...this.cytologicalMorphologicalStages,
            ...this.histologicalMorphologicalStages
        ]
    }

    /**
     * неуточненные морфологические исследования
     */
    get unspecifiedMorphologicalStages() {
        const {
            UNSPECIFIED_CODE,
            UNSPECIFIED_SYSTEM
        } = MorphologicalObservationType;
        return _get(this.conditionResource, "stage", []).filter(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == UNSPECIFIED_SYSTEM;
            }).find(coding => _get(coding, "code") == UNSPECIFIED_CODE);
        });
    }

    /**
     * ссылки на цитологические исследования
     */
    get cytologicalMorphologicalStages() {
        const {
            CODING_SYSTEM,
            CYTOLOGICAL_CODE
        } = MorphologicalObservationType;
        return _get(this.conditionResource, "stage", []).filter(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == CYTOLOGICAL_CODE);
        });
    }

    /**
     * ссылки на гистологические исследования
     */
    get histologicalMorphologicalStages() {
        const {
            CODING_SYSTEM,
            HISTOLOGICAL_CODE
        } = MorphologicalObservationType;
        return _get(this.conditionResource, "stage", []).filter(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == HISTOLOGICAL_CODE);
        });
    }

    /**
     * ссылки на иммуногистохимические исследования
     */
    get immunohistoChemistryStages() {
        const {
            CODING_SYSTEM,
            IMMUNO_HISTO_CHEMISTRY_CODE
        } = ResearchObservationType;
        return _get(this.conditionResource, "stage", []).filter(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == IMMUNO_HISTO_CHEMISTRY_CODE);
        });
    }

    /**
     * ссылки на молекулярно-генетические исследования
     */
    get molecularGeneticsStages() {
        const {
            CODING_SYSTEM,
            MOLECULAR_GENETICS_CODE
        } = ResearchObservationType;
        return _get(this.conditionResource, "stage", []).filter(stage => {
            return _get(stage, "type.coding", []).filter(coding => {
                return _get(coding, "system") == CODING_SYSTEM;
            }).find(coding => _get(coding, "code") == MOLECULAR_GENETICS_CODE);
        });
    }

    /**
     * идентификатор Observation клинической стадии
     */
    get clinicalObservationIdentifier() {
        const identifiers = _get(this.clinicalStage, "assessment", []).filter(reference => {
            return _get(reference, "resourceType") == "Observation";
        }).map(reference => reference.id || reference.localRef);
        return identifiers.length ? identifiers[0] : null;
    }

    /**
     * идентификатор Observation патологоанатомическую стадии
     */
    get pathologicalObservationIdentifier() {
        const identifiers = _get(this.pathologicalStage, "assessment", []).filter(reference => {
            return _get(reference, "resourceType") == "Observation";
        }).map(reference => reference.id || reference.localRef);
        return identifiers.length ? identifiers[0] : null;
    }

    /**
     * идентификатор Observation локализация отдаленных метастазов
     */
    get distantMetastasesObservationIdentifier() {
        const identifiers = _get(this.distantMetastasesStage, "assessment", []).filter(reference => {
            return _get(reference, "resourceType") == "Observation";
        }).map(reference => reference.id || reference.localRef);
        return identifiers.length ? identifiers[0] : null;
    }

    /**
     * идентификаторы Observation цитологических исследований
     */
    get morphologicalObservationIdentifiers() {
        const identifiers = [];
        this.morphologicalStages.forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( _get(reference, "resourceType") == "Observation" ) {
                    identifiers.push(reference.id || reference.localRef);
                }
            });
        })
        return identifiers;
    }

    /**
     * идентификаторы Observation цитологических исследований
     */
    get cytologicalObservationIdentifiers() {
        const identifiers = [];
        this.cytologicalMorphologicalStages.forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( _get(reference, "resourceType") == "Observation" ) {
                    identifiers.push(reference.id || reference.localRef);
                }
            });
        })
        return identifiers;
    }

    /**
     * идентификаторы Observation гистологических исследований
     */
    get histologicalObservationIdentifiers() {
        const identifiers = [];
        this.histologicalMorphologicalStages.forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( _get(reference, "resourceType") == "Observation" ) {
                    identifiers.push(reference.id || reference.localRef);
                }
            });
        })
        return identifiers;
    }

    /**
     * идентификаторы Observation иммуногистохимических исследований
     */
    get immunoHistoChemistryObservationIdentifiers() {
        const identifiers = [];
        this.immunohistoChemistryStages.forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( _get(reference, "resourceType") == "Observation" ) {
                    identifiers.push(reference.id || reference.localRef);
                }
            });
        })
        return identifiers;
    }

    /**
     * идентификаторы Observation молекулярно-генетических исследований
     */
    get molecularGeneticsObservationIdentifiers() {
        const identifiers = [];
        this.molecularGeneticsStages.forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( _get(reference, "resourceType") == "Observation" ) {
                    identifiers.push(reference.id || reference.localRef);
                }
            });
        })
        return identifiers;
    }

    /**
     * создание ссылки на обследование
     */
    createForObservation = (observation, ref = "id") => {
        this.conditionResource.stage.push({
            assessment: [{
                [ref]: observation.id,
                resourceType: "Observation"
            }],
            type: observation.resource.code
        });
    }

    /**
     * удаление ссылки на обследование
     */
    removeForObservation = (observation, ref = "id") => {
        const index = this.conditionResource.stage.findIndex(stage => {
            return _get(stage, "assessment", []).find(reference => {
                return _get(reference, ref) == _get(observation, "id");
            });
        });
        return index >= 0 ? this.conditionResource.stage.splice(index, 1) : false;
    }

    /**
     * делаем ссылки локальными
     */
    makeLocalReferences = () => {
        _get(this.conditionResource, "stage", []).forEach(stage => {
            _get(stage, "assessment", []).forEach(assessment => {
                if ( assessment.id ) {
                    assessment.localRef = assessment.id;
                    delete(assessment.id);
                }
            })
        })
    }

    /**
     * идентификаторы всех Observation, на которые ссылается заболевание
     */
    get allObservationIdentifiers() {
        const identifiers = []
        _get(this.conditionResource, "stage", []).forEach(stage => {
            _get(stage, "assessment", []).forEach(reference => {
                if ( reference.id && reference.resourceType == "Observation" ) {
                    identifiers.push(reference.id);
                }
            })
        });
        return identifiers;
    }

}
