import _get from "lodash/get";
import { observable } from "mobx";
import { AppointmentSlotEnd } from "./AppointmentSlotEnd";
import { AppointmentSlotStart } from "./AppointmentSlotStart";
import { AppointmentSlotStatus } from "./AppointmentSlotStatus";

/**
 * информация о временном слоте консилиуме
 */
export class AppointmentSlot {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * статус слота (занят/свободен)
     */
    status = null;

    /**
     * время начала
     */
    start = null;

    /**
     * время окончания
     */
    end = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
        this.status = new AppointmentSlotStatus(this.resource);
        this.start = new AppointmentSlotStart(this.resource);
        this.end = new AppointmentSlotEnd(this.resource);
    }

    /**
     * идентификатор ресурса
     */
    get id() {
        return this.resource.id;
    }

    /**
     * сверх нормы?
     */
    get isOverbooked() {
        return this.status.isBusy && this.resource.overbooked === true;
    }
}