/**
 * Антотация
 */
import { AuthStore } from 'MODEL_STORE/DataSource/Stores/AuthStore';
import { Element } from './Element';
import moment from "moment";
import {Reference} from "./Reference";

export class Annotation extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Annotation';
    }


    /**
     *
     */
    _init(data) {

        super._init(data);

        this._data.text = data.text || '';

        if (data.hasOwnProperty('author')) {
            this._data.author = data.author;
        } else {
            this._data.author = {Reference: Reference.fromResource(AuthStore.currentUserData.person)._data}
        }

        if (data.hasOwnProperty('time')) {
            this._data.time = data.time;
        } else {
            this._data.time = moment().format();
        }

    }

    /**
     *
     */
    _validate = () => {
        super._validate();
    };

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get text() {
        return this._data.text;
    }

    /**
     *
     */
    // TODO тут может быть markdown, но нужен ли он нам? пока просто строка
    set text(newValue) {
        this._data.text = newValue;
    }

    /**
     *
     */
    get author() {
        return this._data.author || {Reference: null};
    }

    /**
     *
     */
    set author(newValue) {
        this._data.author = {Reference: newValue._data};
    }
}
