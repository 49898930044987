/**
 * https://www.hl7.org/implement/standards/fhir/backboneelement.html
 *
 * В некоторых местах у AidBox есть сущности, похожие на BackboneElement, но там не поля id
 */

import { Element } from './Element';
import { BackboneElement } from './BackboneElement';



export class BackboneElementNoId extends BackboneElement {

    /**
     *
     */
    _setClassName() {
        this._className = 'BackboneElementNoId';
    }

    /**
     *
     */
    _init(data) {

        // эта строчка должна быть до _init
        this._noAutoId = true;

        super._init(data);
    }


}

