import React, {PureComponent} from "react";
import {Route, Switch} from "react-router-dom";
import {Paths} from "./Paths";
// import {RegistrationToken} from "../Components/Registration/Token";
import {AuthPage} from "../Components/AuthPage/AuthPage";
import {Element as TestingElement} from "../Components/Testing/Element";
// import {PasswordChangeRequest} from "../Components/PasswordChangeRequest";
// import {PasswordChange} from "../Components/PasswordChange";
import { AccessPoint } from "GUI_MAIN/Components/MisIntegration/AccessPoint";
import { ContextPage } from "GUI_MAIN/Components/MisIntegration/ContextPage";

class Routs extends PureComponent {
	render() {
		return (
			<Switch>
				<Route path={Paths.testing.element.mask()}
				       children={props => <TestingElement {...props} />}/>

				{/*
				<Route path={Paths.registration.token.mask()}
				       children={props => <RegistrationToken {...props} />}/>

				<Route path={Paths.password.reset.mask()}
				       children={props => <PasswordChange {...props} />}/>
				<Route path={Paths.password.change_request.mask()}
				       children={props => <PasswordChangeRequest {...props} />}/>
				*/}

				{/* https://jira.mmdx.ru/browse/FCSPOK-321 */}
				<Route
					path={"/integration/accesspoint"}
					children={props => <AccessPoint {...props} />}
				/>
				{/* тут была авторизация для страницы контекста
				<Route
					path={"/integration/context/(:id)"}
					children={props => <ContextPage {...props} />}
				/>
				*/}
				{/* https://jira.mmdx.ru/browse/FCSPOK-321 */}

				<Route path="*"
				       children={ props => <AuthPage completed={this.props.completed}
				                                     {...props} /> } />
			</Switch>
		)
	}
}

export {Routs}
