/**
 *
 * https://www.hl7.org/implement/standards/fhir/practitionerrole.html
 *
 * сведения о должности врача в организации
 *
 *
 * {
 *     "practitioner":{
 *         "reference":"Practitioner/Practitioner_id"
 *     },
 *     "organization":{
 *         "reference":"Organization/Organization_id"
 *     },
 *     "code":[
 *         {
 *             "coding":[
 *                 {
 *                     "system":"urn:1.2.643.5.1.13.13.11.1002",
 *                     "version":"4.2",
 *                     "code":"46",
 *                     "display":"врач-нейрохирург"
 *                 }
 *             ]
 *         }
 *     ],
 *     "specialty":[
 *         {
 *             "coding":[
 *                 {
 *                     "system":"urn:1.2.643.5.1.13.13.11.1066",
 *                     "version":"4.2",
 *                     "code":"30",
 *                     "display":"Хирургия"
 *                 }
 *             ]
 *         }
 *     ]
 * }
 *
 *
 *
 */

import { action, toJS } from 'mobx';

import { ARM_ADMINISTRATOR, PRACTITIONER_ROLE_CODE_VERSION, PRACTITIONER_ROLE_SPECIALTY_VERSION, ARM_HLCHEMOTHERAPIST } from 'MODEL_STORE/DataSource/Constants/misc';
import { OID } from 'MODEL_STORE/DataSource/Constants/oid';

import { DomainResource } from './DomainResource';
import { Identifier } from '../Elements/Identifier';
import { Reference } from '../Elements/Reference';
import { CodeableConcept } from '../Elements/CodeableConcept';



export class PractitionerRole extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'PractitionerRole';
    }

    /**
     * признак того, что модель отделена от сотрудника и ее нужно удалить
     */
    _detached = false;

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'active',
            'period',
            'location',
            'healthcareService',
            'availableTime',
            'availableExceptions',
            'endpoint',
        ]);

        if (Array.isArray(data.identifier)) {
            data.identifier.forEach( e => {
                this._arrayAdd('identifier', new Identifier(e));
            });
        }

        if (data.practitioner) {
            this._data.practitioner = new Reference(data.practitioner)._data;
        }

        if (data.organization) {
            this._data.organization = new Reference(data.organization)._data;
        }

        if (data.telecom) {
            this._data.telecom = data.telecom;
        }

        if (Array.isArray(data.code)) {
            data.code.forEach( e => {
                this._arrayAdd('code', new CodeableConcept(e));
            });
        }

        if (Array.isArray(data.specialty)) {
            data.specialty.forEach( e => {
                this._arrayAdd('specialty', new CodeableConcept(e));
            });
        }


        //
    }

    /**
     * функция валидации
     * на выходе список ошибок в формате { поле, сообщение }
     */
    _validate() {

        super._validate();

        // TODO валидировать всё!
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get identifier() {

        if ( ! this._data.identifier) {
            return [];
        }
        return this._data.identifier.map( e => new Identifier(e, e => this._arrayReplaceById('identifier', e.id, e) ) );
    }

    /**
     *
     */
    set identifier(newValue) {
        this._data.identifier = newValue.map( e => e._data );
    }

    /**
     *
     */
    get practitioner() {
        return new Reference(this._data.practitioner);
    }

    /**
     *
     */
    set practitioner(newValue) {
        this._data.practitioner = Reference.fromResource(newValue)._data;
    }

    /**
     *
     */
    get organization() {

        if ( ! this._data.organization) {
            return undefined;
        }
        return new Reference(this._data.organization);
    }

    /**
     *
     */
    set organization(newValue) {

        if ( ! newValue) {
            delete this._data.organization;
            return;
        }

        this._data.organization = Reference.fromResource(newValue)._data;
    }

    /**
     *
     */
    get code() {

        if ( ! this._data.code) {
            return [];
        }
        return this._data.code.map( e => new CodeableConcept(e, e => this._arrayReplaceById('code', e.id, e) ) );
    }

    /**
     *
     */
    set code(newValue) {
        this._data.code = newValue.map( e => e._data );
    }

    /**
     *
     */
    get specialty() {

        if ( ! this._data.specialty) {
            return [];
        }
        return this._data.specialty.map( s => new CodeableConcept(s, u => this._arrayReplaceById('specialty', u.id, u) ) );
    }

    /**
     *
     */
    set specialty(newValue) {
        this._data.specialty = newValue.map( e => e._data );
    }


    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    // теперь немного магии

    /**
     * задаем должность (code) из массива valueset
     * тип данных предполагает CodeableConcept, который немного другой по структуре
     */
    setCodeFromValueset(valuesetArray) {

        this._data.code = valuesetArray.map( e => {
            return {
                coding: [
                    {
                        system: OID.POSITIONS_ROSTER,
                        version: PRACTITIONER_ROLE_CODE_VERSION,
                        code: e.code,
                        display: e.display,
                    },
                ],
            };
        });
    }

    /**
     * список должностей для определенного OID
     */
    getCodeForOid(oid) {

        if ( ! Array.isArray(this._data.code)) {
            return [];
        }

        return toJS(this._data.code)
            .map( e => {
                return e.coding.find( c => {
                    return c.system == oid
                });

            })
            .filter( e => e )
            ;
    }


    /**
     * задаем специаьность (specialty) из массива valueset
     * тип данных предполагает CodeableConcept, который немного другой по структуре
     */
    setSpecialtyFromValueset(valuesetArray) {

        this._data.specialty = valuesetArray.map( e => {
            return {
                coding: [
                    {
                        system: OID.SPECIALTIES_ROSTER,
                        version: PRACTITIONER_ROLE_SPECIALTY_VERSION,
                        code: e.code,
                        display: e.display,
                    },
                ],
            };
        });
    }

    /**
     * список специальностей для определенного OID
     */
    getSpecialtyForOid(oid) {

        if ( ! Array.isArray(this._data.specialty)) {
            return [];
        }

        return toJS(this._data.specialty)
            .map( e => {
                return e.coding.find( c => {
                    return c.system == oid;
                });
            })
            .filter( e => e )
            ;
    }

    /**
     * снимаем привязку к сотруднику. при сохранении сотрудника такая модель должна быть удалена
     */
    @action
    _detach() {
        this._detached = true;
    }

    hasCode(_code) {
        return Array.isArray(this.code) && this.code.filter(code => {
            return Array.isArray(code.coding) && code.coding.filter(coding => {
                return coding.code == _code;
            }).length;
        }).length;
    }

    /**
     * Имеется ли роль - Старший врач-химиотерапевт
     */
    get isArmHlChemotherapist() {
        return !!this._data.extension.find(e =>
            e.extension && Array.isArray(e.extension) && e.extension.find(e => e.url === ARM_HLCHEMOTHERAPIST)
        );
    }

    /**
     * Имеется ли роль - Администратор МО
     */
    get isArmAdministrator() {
        return !!this._data.extension.find(e =>
            e.extension && Array.isArray(e.extension) && e.extension.find(e => e.url === ARM_ADMINISTRATOR)
        );
    }
}
