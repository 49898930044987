import moment from "moment";
import _get from "lodash/get";

/**
 * класс для управления датой установки заболевания
 */
export class ConditionRecordedDate {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * значение даты
     */
    get value() {
        return _get(this.conditionResource, "recordedDate");
    }

    /**
     * дата в удобном формате
     */
    get display() {
        return this.value ? moment(this.value).format("DD.MM.YYYY") : "";
    }

    /**
     * изменение даты
     */
    onChange = (value) => {
        return value ? this.change(value) : this.reset();
    }

    /**
     * изменение даты
     */
    change = (value) => {
        this.conditionResource.recordedDate = value;
    }

    /**
     * удаление даты
     */
    reset = () => {
        if ( this.conditionResource.recordedDate ) {
            delete(this.conditionResource.recordedDate);
        }
    }

}
