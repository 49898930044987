export const mm_dictionary = {
    SS016001: "Список пациентов",
    SS016002: "ФИО пациента",
    SS016003: "Пол",
    SS016004: "Мужской",
    SS016005: "Женский",
    SS016006: "Дата рождения",
    SS016007: "Полис ОМС",
    SS016008: "Сбросить фильтры",
    SS016009: "Пациенты не найдены. Измените условия поиска",
    SS016010: "ФИО пациента",
    SS016011: "Пол",
    SS016012: "Мужской",
    SS016013: "Женский",
    SS016014: "Дата рождения",
    SS016015: "Полис ОМС",
    SS016016: "Список пациентов",
    SS016017: "Планы лечения пациента",
    SS016018: "Просмотр Протоколов и Заявок на ОК",
    SS016019: "",
    SS016020: "E-mail:",
    SS016021: "",
    SS016022: "",
    SS016023: "Еще {x}…",
    SS016024: "",
    SS016025: "Все заболевания",
    SS016026: "Добавить заболевание",
    SS016027: "Добавить План лечения",
    SS016028: "Скрыть завершенные",
    SS016029: "Принимаемые препараты",
    SS016030: "Добавление плана лечения",
    SS016031: "МКБ-10",
    SS016032: "МКБ-10",
    SS016033: "",
    SS016034: "",
    SS016035: "Место лечения",
    SS016036: "Место лечения",
    SS016037: "Тип лекарственной терапии",
    SS016038: "Тип лекарственной терапии",
    SS016039: "",
    SS016040: "Линия лечения",
    SS016041: "Предполагаемая дата начала лечения",
    SS016042: "По шаблону",
    SS016043: "Наименование Плана лечения",
    SS016044: "Описание Плана лечения",
    SS016045: "Описание",
    SS016046: "Добавить",
    SS016047: "Отмена",
    SS016048: "Выберите хотя бы одно заболевание",
    SS016049: "Выберите организацию",
    SS016050: "Выберите тип лекарственной терапии",
    SS016051: "Укажите линию лечения",
    SS016052: "Заполните Наименование Плана лечения",
    SS016053: "Заполните Описание Плана лечения",
    SS016054: "Запись добавлена",
    SS016055: "",
    SS016056: "{x} линия",
    SS016057: "",
    SS016058: "Печать протокола Плана лечения",
    SS016059: "Сохранить в шаблон",
    SS016060: "Удалить план лечения",
    SS016061: "Наименование цикла",
    SS016062: "Без наименования",
    SS016063: "№ Цикла",
    SS016064: "Дата начала",
    SS016065: "Дата окончания",
    SS016066: "Статус",
    SS016067: "",
    SS016068: "+ Добавить новый цикл",
    SS016069: "Новый",
    SS016070: "Скопировать",
    SS016071: "Добавить",
    SS016072: "Скопировать",
    SS016073: "Ошибка обновления информации о цикле",
    SS016074: "Принимаемые препараты",
    SS016075: "",
    SS016076: "Планы лечения",
    SS016077: "Планы лечения пациента",
    SS016078: "Цикл лечения",
    SS016079: "мужчина",
    SS016080: "женщина",
    SS016081: "Просмотр Протоколов и Заявок на ОК",
    SS016082: "Статус",
    SS016083: "",
    SS016084: "Заболевание",
    SS016085: "",
    SS016086: "Наименование схемы",
    SS016087: "Краткое описание схемы",
    SS016088: "Место лечения",
    SS016089: "Тип лекар. терапии",
    SS016090: "",
    SS016091: "Линия лечения",
    SS016092: "Наименование цикла",
    SS016093: "Номер цикла",
    SS016094: "Дата начала",
    SS016095: "Прод. цикла (д)",
    SS016096: "Рост (см)",
    SS016097: "Вес (кг)",
    SS016098: "ППТ (м²)",
    SS016099: "Ур. сыв. креат. (мкмоль/л)",
    SS016100: "Клиренс креатинина (мл/мин)",
    SS016101: "Цикл лечения",
    SS016102: "Сводка по дням",
    SS016103: "Статус лечения",
    SS016104: "Итоги цикла лечения",
    SS016105: "Рост пациента должен быть в диапазоне от {x1} до {x2} см",
    SS016106: "Заполните рост пациента",
    SS016107: "Вес пациента должен быть в диапазоне от {x1} до {x2} кг",
    SS016108: "Заполните вес пациента",
    SS016109: "Заполните продолжительность цикла лечения и сохраните его",
    SS016110: "Ошибка обновления информации о цикле",
    SS016111: "Ошибка добавления записи",
    SS016112: "Информация о цикле успешно обновлена",
    SS016113: "Данные обновлены",
    SS016114: "Премедикация",
    SS016115: "Противоопухолевое лечение",
    SS016116: "Сопутствующая терапия",
    SS016117: "Добавить препарат",
    SS016118: "Наименование препарата",
    SS016119: "Дни цикла",
    SS016120: "Доза",
    SS016121: "Путь приема",
    SS016122: "Длительность введения",
    SS016123: "Кратность",
    SS016124: "Примечание/Комментарий",
    SS016125: "Скрининг",
    SS016126: "Принимаемые препараты",
    SS016127: "Назначить",
    SS016128: "Удалить",
    SS016129: "",
    SS016130: "Добавление МН - {x1}",
    SS016131: "Добавление МН - Противоопухолевая терапия",
    SS016132: "Добавление МН - Сопутствующая терапия",
    SS016133: "Рост (см)",
    SS016134: "Вес (кг)",
    SS016135: "ППТ (м²)",
    SS016136: "Клиренс креатинина (мл/мин)",
    SS016137: "Добавить препарат",
    SS016138: "Введите название препарата",
    SS016139: "",
    SS016140: "Подтвердить",
    SS016141: "Отменить",
    SS0161420: "Ошибки при подготовке к сохранению МНН: ",
    SS0161421: "Не добавлено ни одного МНН",
    SS016143: "Редактирование МН - {x1}",
    SS016144: "Редактирование МН - Противоопухолевая терапия",
    SS016145: "Редактирование МН - Сопутствующая терапия",
    SS016146: "Рост (см)",
    SS016147: "Вес (кг)",
    SS016148: "ППТ (м²)",
    SS016149: "Клиренс креатинина",
    SS016150: "Добавить препарат",
    SS016151: "Список пуст…",
    SS016152: "",
    SS016153: "Наименование",
    SS016154: "Составной препарат",
    SS016155: "",
    SS016156: "",
    SS016157: "Удалить",
    SS016158: "Путь приема",
    SS016159: "",
    SS016160: "Информировать пациента",
    SS016161: "Метод приема",
    SS016162: "",
    SS016163: "Тип инфузионной среды",
    SS016164: "",
    SS016165: "мл",
    SS016166: "л",
    SS016167: "Длительность введения",
    SS016168: "сек",
    SS016169: "мин",
    SS016170: "час",
    SS016171: "Период приема",
    SS016172: "Поле \"Период приема\" используется для заполнения периода непрерывного приема препарата в рамках продолжительности цикла. При необходимости его заполнения установите в поле флажок",
    SS016173: "дн",
    SS016174: "нед",
    SS016175: "мес",
    SS016176: "Кратность",
    SS016177: "",
    SS016178: "Дни цикла",
    SS016179: "Примечание",
    SS016180: "Выбрать из списка",
    SS016181: "",
    SS016182: "Комментарий",
    SS016183: "Введите комментарий",
    SS016184: "Подтвердить",
    SS016185: "Отмена",
    SS016186: "Ошибки при подготовке к сохранению МН: ",
    SS016187: "Не выбран путь приема",
    SS016188: "Необходимо указать кол-во и ед. измерения препарата \"{x1}\"",
    SS016189: "Не указаны дни приема препарата",
    SS016190: "Препарат успешно добавлен",
    SS016191: "Корректировка дозы",
    SS016192: "Итоговая доза",
    SS016193: "Итоговая доза была изменена",
    SS016194: "Рассчитанная доза ({unit})",
    SS016195: "Корректировка дозы ({unit})",
    SS016196: "Итоговая доза ({unit})",
    SS016197: "Наименование препарата",
    SS016198: "Составной препарат",
    SS016199: "Редактировать",
    SS016200: "Дни цикла",
    SS016201: "Доза",
    SS016202: "Путь приема",
    SS016203: "Длительность введения",
    SS016204: "Кратность",
    SS016205: "Примечание/Комментарий",
    SS016206: "Скрининг",
    SS016207: "",
    SS016208: "",
    SS016209: "",
    SS016210: "сек",
    SS016211: "мин",
    SS016212: "час",
    SS016213: "",
    SS016214: "Посмотреть скрининг лекарственных назначений",
    SS016215: "Назначение будет передано в приложение пациента",
    SS016216: "Удалить назначение препарата",
    SS016217: "Итоговая доза была изменена",
    SS016218: "Внимание!",
    SS016219: "Вы уверены, что хотите удалить назначение препарата?",
    SS016220: "Да",
    SS016221: "Нет",
    SS016222: "Препарат успешно удален",
    SS016223: "Изменения сохранены",
    SS016224: "Для перевода цикла в статус {status} необходимо заполнить поле \"Наименование цикла\"",
    SS016225: "Внимание!",
    SS016226: "Вы действительно хотите отменить цикл лечения №{x1} {x2}?",
    SS016227: "Да",
    SS016228: "Нет",
    SS016229: "{x1} ДЕНЬ",
    SS016230: "Недели:",
    SS016231: "",
    SS016232: "",
    SS016233: "Отменить",
    SS016234: "В работу",
    SS016235: "Приостановить",
    SS016236: "Прервать",
    SS016237: "Завершить",
    SS016238: "Вернуть в работу",
    SS016239: "Прервать лечение",
    SS0162400: "Проверьте правильность заполнения формы: ",
    SS0162401: "Итоги цикла лечения",
    SS016241: "Внимание!",
    SS016242: "Подтвердите текущее состояние пациента:",
    SS016243: "Рост (см)",
    SS016244: "Цикл",
    SS016245: "см",
    SS016246: "Вес (кг)",
    SS016247: "Цикл",
    SS016248: "кг",
    SS016249: "Ур. сыв. креатинина (мкмоль/л)",
    SS016250: "Цикл",
    SS016251: "мкмоль/л",
    SS016252: "Подтвердить",
    SS016253: "Заполните вес пациента",
    SS016254: "Для завершения Цикла лечения необходимо для каждого медикаментозного назначения указать корректные данные на вкладке \"Статус лечения\"",
    SS016255: "Заполните причину остановки/не введения препарата",
    SS016256: "Задачи МПП отменены",
    SS016257: "Подтвердить все МН",
    SS016258: "Предыдущие назначения",
    SS016259: "Предыдущие назначения",
    SS016260: "Выберите Цикл лечения",
    SS016261: "Премедикация",
    SS016262: "Состав: ",
    SS016263: "",
    SS016264: "Противоопухолевое лечение",
    SS016265: "",
    SS016266: "",
    SS016267: "Дни приема:",
    SS016268: "Сопутствующая терапия",
    SS016269: "Введен",
    SS016270: "Остановлен",
    SS016271: "Не введен",
    SS016272: "Принят",
    SS016273: "Не принят",
    SS016274: "Принят",
    SS016275: "в",
    SS016276: "Торговое наименование",
    SS016277: "",
    SS016278: "Комментарий о самочувствии пациента",
    SS016279: "Заполните дату начала введения препарата",
    SS016280: "Время:",
    SS016281: "Начало",
    SS016282: "не указано",
    SS016283: "{дата} в {время}",
    SS016284: "Окончание",
    SS016285: "{дата} в {время}",
    SS016286: "Причина остановки введения:",
    SS016287: "Выберите причину",
    SS016288: "",
    SS016289: "Комментарий к причине остановки введения:",
    SS016290: "Комментарий к причине остановки введения (обязательно):",
    SS016291: "Причина не введения:",
    SS016292: "Комментарий к причине не введения:",
    SS016293: "Принят",
    SS016294: "{дата} в {время}",
    SS016295: "Оценка динамики заболевания",
    SS016296: "Тип",
    SS016297: "",
    SS016298: "Описание",
    SS016299: "Исчезновение всех экстранодальных целевых образований. Все патологические лимфатические узлы должны уменьшиться < 10 мм по короткой оси в абсолютном значении.",
    SS016300: "Уменьшение суммы наибольших размеров очагов более чем на 30 %; для сравнения берется первичное исследование",
    SS016301: "Увеличение суммы наибольших размеров не менее чем на 20 % по сравнению с временной точкой, имеющей наименьшую сумму наибольших размеров (надир); Также сумма наибольших размеров должна демонстрировать абсолютное увеличение размеров не менее чем на 5 мм.; Достоверное появление нового опухолевого очага",
    SS016302: "Все прочие случаи",
    SS016303: "По окончанию цикла лечения оценка не была произведена",
    SS016304: "Оценка Токсичности и влияния химиотерапии",
    SS016305: "Нет оценок",
    SS016306: "Итоги",
    SS016307: "Сохранить",
    SS016308: "Группа расстройства",
    SS016309: "",
    SS016310: "Тип расстройства",
    SS016311: "Получение списка…",
    SS016312: "",
    SS016313: "Степень",
    SS016314: "Описание влияния",
    SS016315: "",
    SS016316: "Определение",
    SS016317: "",
    SS016318: "Укажите тип расстройства для группы \"{x1}\"",
    SS016319: "Укажите степень влияния для расстройства \"{x1}\"",
    SS016320: "Печать Протокола цикла лечения",
    SS016321: "Ошибка генерации протокола",
    SS001038: "Служба поддержки:",
    SS017001: "Шаблоны лечения",
    SS017002: "Наименование",
    SS017003: "Код заболевания",
    SS017004: "Список пуст…",
    SS017005: "Тип терапии",
    SS017006: "",
    SS017007: "Создал",
    SS017008: "Архив",
    SS017009: "Сбросить фильтры",
    SS017010: "Шаблоны не найдены. Измените условия поиска",
    SS017011: "Создать шаблон",
    SS017012: "Шаблоны лечения",
    SS017013: "Создание шаблона лечения",
    SS017014: "Наименование схемы",
    SS017015: "Краткое описание схемы лечения",
    SS017016: "МКБ-10:",
    SS017017: "Введите код заболевания МКБ-10",
    SS017018: "Заболевания не найдены",
    SS017019: "Тип лекарственной терапии:",
    SS017020: "Адъювантная",
    SS017021: "Самостоятельная",
    SS017022: "Неоадъювантная",
    SS017023: "Общее количество циклов:",
    SS017024: "Документы:",
    SS017025: "Наименование цикла",
    SS017026: "Без названия",
    SS017027: "Кол-во циклов",
    SS017028: "Длительность цикла (д)",
    SS017029: "+ Добавить новый цикл",
    SS017030: "Новый",
    SS017031: "Скопировать",
    SS017032: "Без названия",
    SS017033: "Добавить",
    SS017034: "Поддерживаются только файлы в формате PDF",
    SS017035: "Шаблоны лечения",
    SS017036: "Редактирование шаблона лечения",
    SS017037: "Редактирование цикла",
    SS017038: "Наименование схемы:",
    SS017039: "МКБ-10:",
    SS017040: "Наименование цикла",
    SS017041: "Краткое описание схемы:",
    SS017042: "Прод. цикла (д)",
    SS017043: "Кол-во циклов",
    SS017044: "Цикл лечения",
    SS017045: "Сводка по дням",
    SS017046: "Для добавления препарата укажите наименование цикла лечения",
    SS017047: "",
    SS017048: "Недели",
    SS017049: "На эту неделю нет медикаментозных назначений",
    SS017050: "",
    SS017051: "Сигнальная информация",
    SS90001: "Время начала введения должно быть меньше времени окончания введения",
    SS90002: "Время окончания введения должно быть позже, чем время начала введения",
    SS90003: "Невозможно создать План лечения с Датой начала лечения ранее Даты рождения пациента",
    SS90004: "Невозможно подтвердить медикаментозное назначение с дозировкой, равной 0",

    //Переводы за 3.09.21
    //33
    SS91188: "Наименование",
    SS91189: "Краткое описание",
    SS91190: "Тип терапии",
    SS91191: "Кол-во циклов",
    SS91192: "Источник",
    SS91193: "Создал",
    SS91194: "Дата создания",
    SS91195: "Архив",
    SS91196: "Пользовательский",
    SS91197: "Адъювантная",
    SS91198: "Самостоятельная",
    SS91199: "Неоадъювантная",
    SS911100: "Шаблон лечения удален",
    //34
    SS911101: "Редактировать шаблон лечения",
    SS911102: "История изменений",
    SS911103: "Скопировать шаблон лечения",
    SS911104: "Удалить шаблон",
    //35
    SS911105: "Адъювантная",
    SS911106: "Самостоятельная",
    SS911107: "Неоадъювантная",
    SS911108: "Сохранить",
    SS911109: "Для сохранения шаблона лечения нужно заполнить наименование схемы",
    SS911110: "Для сохранения шаблона лечения нужно заполнить краткое описание схемы лечения",
    SS911111: "Для сохранения шаблона лечения нужно указать тип лекарственной терапии",
    SS911112: "Для сохранения шаблона лечения нужно указать МКБ-10",
    SS911113: "Изменения сохранены",
    //36
    SS911114: "Укажите причину архивации",
    SS911115: "Причина",
    SS911116: "Отмена",
    SS911117: "Архивировать",
    SS911118: "Шаблон лечения перемещен в архив",
    SS911119: "Шаблон лечения возвращен из архива",
    //37
    SS911120: "Причина архивации:",
    //38
    SS911121: "Внимание!",
    SS911122: "Вы действительно хотите удалить шаблон лечения?",
    SS911123: "Да",
    SS911124: "Нет",
    //39
    SS911125: "История изменений шаблона",
    SS911126: "Дата",
    SS911127: "ФИО",
    SS911128: "Дата и время",
    SS911129: "ФИО",
    //13
    SS91100: "МКБ-10",
    //14
    SS91101: "Самостоятельная",
    SS91102: "Адъювантная",
    SS91103: "Неоадъювантная",
    //15
    SS91104: "Загрузка",
    //16
    SS91105: "Информации по постоянно принимаемым пациентом препаратам нет",
    SS91106: "Лист назначений",
    SS91107: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Прод. цикла (д)\"",
    SS91108: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Рост\"",
    SS91109: "Для перевода цикла в статус \"Назначен\" необходимо заполнить поле \"Вес\"",
    SS91110: "Для перевода цикла в статус \"Назначен\" необходимо создать медикаментозные назначения",
    SS91111: "Для перевода цикла в статус \"Назначен\" необходимо подтвердить все медикаментозные назначения",
    //17
    SS91112: "Список пуст…",
    //18
    SS91113: "Необходимо указать кол-во и ед.изм. инф. среды",
    //19
    SS91114: "Необходимо задать длительность введения",
    //20
    SS91115: "Меры предосторожности:",
    //21
    SS91116: "Изменения сохранены",
    //22
    SS91117: "Просмотр результатов анкет",
    //23
    SS91118: "Результаты анкет",
    SS91119: "Общий опросник",
    SS91120: "Описание опроса",
    SS91121: "30 вопросов",
    SS91122: "Результаты",
    SS91123: "Обработанные",
    SS91124: "Первичные",
    SS91125: "Данные для отображения не найдены",
    //24
    SS91126: "По дням",
    SS91127: "Весь цикл",
    //25
    SS91128: "Итоги цикла лечения",
    //26
    SS91129: "Сохранение",
    //27
    SS91130: "Изменить дату и время",
    SS91131: "вечера",
    //32
    SS91153: "Для добавления плана лечения укажите хотя бы одно заболевание пациента",
    //2
    SS91010: "У пациента нет ни одной заявки на консилиум",
    //3
    SS91001: "Записи о заболеваниях отсутствуют",
    //4
    SS91002: "Схема лечения",
    SS91003: "По шаблону",
    SS91004: "Новая",
    SS91005: "Для поиска шаблонов укажите заболевание и тип лекарственной терапии",
    SS91006: "Самостоятельная",
    SS91007: "Адъювантная",
    SS91008: "Неоадъювантная",
    //6
    SS91009: "Состав схемы",
    "SS91010-1": "Наименование цикла",
    SS91011: "Длительность (д)",
    //12
    SS91015: "Редактирование шаблона",
    SS91016: "Наименование схемы:",
    SS91017: "Тип лекарственной терапии",
    SS91018: "Адъювантная",
    SS91019: "Самостоятельная",
    SS91020: "Неоадъювантная",
    SS91021: "Количество циклов:",
    SS91022: "Краткое описание:",
    SS91023: "Документы",
    //7
    SS91012: "Описание:",
    SS91013: "Кол-во циклов",
    //Замечание 2 в переводах
    SS91014: "План лечения {treatmentPlanName} в статусе \"Завершённый\". Редактирование Циклов лечения невозможно.",

    //Переводы за 7.09.21 - 13.09.21
    SS903320: "Сохранение в шаблон",
    SS903321: "Наименование схемы:",
    SS903322: "МКБ-10",
    SS903323: "Код заболевания по МКБ-10",
    SS903324: "Тип лекарственной терапии:",
    SS903325: "Адъювантная",
    SS903326: "Самостоятельная",
    SS903327: "Неоадъювантная",
    SS903328: "Количество циклов",
    SS903329: "Наименование цикла",
    SS903330: "Длительность (д)",
    SS903331: "Краткое описание",
    SS903332: "Документы:",
    SS903333: "Сохранить",
    SS903334: "Укажите уникальное наименование схемы",
    SS903335: "Укажите наименование схемы",
    SS903336: "Шаблон успешно сохранен",
    SS903337: "Для добавления препарата необходимо ввести данные в поля Рост и Вес",
    SS903338: "Для добавления препарата необходимо ввести данные в поля Рост и Вес. Сохраните значения.",
    SS903339: "Порядок приема:",
    SS903340: "Побочные эффекты:",
    SS903341: "Хранение:",
    SS903342: "Внимание!",
    SS903343: "Вы действительно хотите удалить цикл лечения?",
    SS903344: "Да",
    SS903345: "Нет",
    SS903346: "Если вы измените значение ППТ/клиренс креатинина, дозы препаратов будут пересчитаны и вам необходимо будет снова подтвердить их назначение",
    SS903347: "Использовать значение ППТ",
    SS903348: "Рассчитанное",
    SS903349: "Использовать значение Клиренса креатинина",
    SS903350: "Добавить перерыв",
    SS903351: "Перерыв",
    SS903352: "мин.",
    SS903353: "Перерыв после завершения",
    SS903354: "минут",
    SS903355: "Изменить",
    SS903356: "Удалить",
    SS903357: "По дням",
    SS903358: "Весь цикл",
    SS903359: "Добавить перерыв после завершения",
    SS903360: "Изменить время перерыва",
    SS903361: "Для переноса цикла в статус \"Назначен\" необходимо заполнить поле \"Дата начала\"",
    SS903362: "Для переноса цикла в статус \"Назначен\" необходимо заполнить поле \"Рост\"",
    SS903363: "Для переноса цикла в статус \"Назначен\" необходимо заполнить поле \"Вес\"",
    SS903364: "Медикаментозные назначения не могут быть переданы в мобильное приложение, так как не найдена учетная запись пациента",
    SS903365: "Изменения сохранены",
    SS903366: "Внимание!",
    SS903367: "Данный Шаблон лечения используется в работе. Чтобы его отредактировать, текущий шаблон будет перенесен в архив, и на его основании будет создана копия, которую вы сможете отредактировать.",
    SS903368: "Ок",
    SS903369: "Отмена",
    SS903370: "История изменений шаблона",
    SS903371: "Дата",
    SS903372: "ФИО",
    SS903373: "Дата и время",
    SS903374: "ФИО",
    SS903375: "Внимание!",
    SS903376: "Вы уверены, что хотите удалить шаблон лечения?",
    SS903377: "Удалить",
    SS903378: "Отмена",
    SS903379: "Автор",
    SS903380: "Статус",
    SS903381: "Статус",
    SS903382: "Для редактирования Цикла лечения нужно сохранить Шаблон лечения",
    SS903383: "Для сохранения шаблона лечения нужно заполнить наименование схемы",
    SS903384: "Для сохранения шаблона лечения нужно заполнить краткое описание схемы лечения",
    SS903385: "Для сохранения шаблона лечения нужно указать тип лекарственной терапии",
    SS903386: "Для сохранения шаблона лечения нужно указать МКБ-10",
    SS903387: "Шаблон лечения активирован и доступен для планирования терапии",
    SS903388: "Укажите причину архивации",
    SS903389: "Причина",
    SS903390: "Отмена",
    SS903391: "Архивировать",
    SS903392: "Архивировать",
    SS903393: "Редактировать",
    SS903394: "Удалить",
    SS903395: "Активировать",
    SS903396: "Сохранить",
    SS903397: "Лист назначений",
    SS903398: "Добавить МН",
    SS903399: "Добавить группу МН",
    SS903400: "Укажите уникальное наименование схемы",
    SS903401: "Введите наименование цикла лечения",
    SS903402: "Введите продолжительность цикла лечения",
    SS903403: "Введите количество циклов лечения",
    SS903404: "Изменения сохранены",
    SS903405: "Произошла ошибка при загрузке справочников терминологии",
    SS903406: "Произошла ошибка при получении данных с сервиса. Попробуйте позднее",
    SS903407: "Сохранить",
    SS903408: "Удалить",
    SS903409: "Добавление МН - Противоопухолевая терапия",
    SS903410: "{Блок-Аналог S016.6.4.1}",
    SS903411: "Ошибки при подготовке к сохранению МН. Не добавлено ни одного МН",
    SS903412: "Группа:",
    SS903413: "Наименование группы",
    SS903414: "Основное назначение",
    SS903415: "Дополнительное назначение",
    SS9040140: "Дополнительное назначение",
    SS903416: "Часто назначаемое",
    SS903417: "Осн.",
    SS903418: "Доп.",
    SS903419: "Добавить препарат",
    SS903420: "Удалить группу МН",
    SS903421: "Удалить назначение препарата",

    //Переводы добавленные 20.09.21
    SS904134: "Редактирование МН - Противоопухолевая терапия",
    SS904135: "от",
    SS904136: "до",
    SS904137: "Интервал дозирования",
    SS904138: "Удалить",
    SS904139: "Путь приема",
    SS904140: "Метод приема",
    SS904141: "Тип инфузионной среды",
    SS904142: "мл",
    SS904143: "л",
    SS904144: "Длительность введения",
    SS904145: "сек",
    SS904146: "мин",
    SS904147: "час",
    SS904148: "Период приема",
    SS904149: "дн",
    SS904150: "нед",
    SS904151: "мес",
    SS904152: "Кратность",
    SS904153: "Дни цикла",
    SS904154: "Примечание",
    SS904155: "Выбрать из списка",
    SS904156: "Порядок приема",
    SS904157: "Добавить",
    SS904158: "Отменить",

    SS904175: "Добавление МН - Противоопухолевая терапия",
    SS904176: "Добавить препарат",
    SS904177: "Добавить",
    SS904178: "Отмена",

    SS904180: "Сохранить",
    SS904181: "Добавить",
    SS904182: "Отменить",

    //GLB-219-3
    SS904190: "Скопирован",
    SS904191: "Создан",
    SS904192: "Удален",
    SS904193: "Назначен",
    SS904194: "В работе",
    SS904195: "Отменен",
    SS904196: "Приостановлен",
    SS904197: "Прерван",
    SS904198: "Завершен",
    SS904199: "Ошибка",
    SS904200: "Согласование",
    SS904201: "Новый",
    SS904202: "Удален",
    SS904203: "Активный",
    SS904204: "Завершенный",

    //Переводы 27.09 - 28.09.21
    SS902277: "Планы лечения",

    SS902279: "Добавление МН - Премедикация",
    SS902280: "Добавить препарат",
    SS902281: "Добавить",
    SS902282: "Отменить",
    SS902283: "Внимание!",
    SS902284: "Вы действительно хотите удалить Цикл лечения?",
    SS902285: "Да",
    SS902286: "Нет",
    SS902287: "Сохранить",
    SS902288: "Удалить",

    SS902293: "Консилиумы пациента {patient}",
    SS902294: "Заявка",
    SS902295: "Просмотр заявки на ОК",
    SS902296: "Протокол",
    SS902297: "Выберите протокол или заявку в необходимом консилиуме для просмотра",
    SS902298: "версия",

    SS902331: "Завершить план лечения",
    SS902332: "Добавление плана лечения",
    SS902333: "Введите код МКБ-10, код схемы, наименование или описание схемы",
    SS902334: "Шаблоны",
    SS902335: "Базовые схемы ФФОМС",
    SS902336: "Поиск",
    SS902337: "Наименование",
    SS902338: "Описание",
    SS902339: "Кол-во циклов",
    SS902340: "Добавить",
    SS902341: "Отмена",
    SS902342: "Нагрузочная",
    SS902343: "Нагруз. доза",
    SS902344: "Курс {count} {unit}",
    SS902345: "Добавить",
    SS902346: "Одновременный прием с…",
    SS902347: "Отменить одновременный прием",

    SS902348: "Подробнее…",
    SS902349: "Скрининг лекарственных препаратов",
    SS902350: "Предупреждение",
    SS902351: "Эффект",
    SS902352: "Механизм",
    SS902353: "Действия",
    SS902354: "Взаимодействие",
    SS902355: "Взаимодействие с пищей",
    SS902356: "Взаимодействие с алкоголем",
    SS902357: "Аллергическая реакция",
    SS902358: "Диагноз",
    SS902359: "Фармакогенетика",
    SS902360: "Уровень риска",
    SS902361: "Доказанность",
    SS902362: "Скорость",
    SS902363: "Действия",

    SS902400: "Общий опросник ORTC-QLQ-C30, версия 3",
    SS902401: "Загрузка",
    SS902402: "Дата получения результата ",
    SS902403: "Общий статус здоровья ",
    SS902404: "Общий статус здоровья ",
    SS902405: "Функциональные шкалы ",
    SS902406: "Физическая функция ",
    SS902407: "Ролевая функция ",
    SS902408: "Эмоциональная функция ",
    SS902409: "Когнитивная функция ",
    SS902410: "Социальная функция ",
    SS902411: "Симптомы ",
    SS902412: "Усталость ",
    SS902413: "Тошнота/рвота ",
    SS902414: "Боль ",
    SS902415: "Диспноэ ",
    SS902416: "Бессонница ",
    SS902417: "Потеря аппетита ",
    SS902418: "Запор ",
    SS902419: "Понос ",
    SS902420: "Финансовые трудности ",

    SS902655: "Просмотр протокола ОК",

    //Переводы 1.10 - 4.10.21
    SS902661: "мг",
    SS902662: "Для расчета дозировки препарата необходимо ввести значение уровня сывороточного креатинина",

    SS902690: "Удалить оценку токсичности?",
    SS902691: "Да",
    SS902692: "Отмена",
    SS902693: "Получение списка",
    SS902694: "Протокол курса лечения",
    SS902695: "Печать протокола курса лечения….",
    SS902696: "Печать протокола Плана лечения….",

    SS902708: "Описание нозологической формы",
    SS902709: "Загрузка",
    SS902710: "Шаблоны лечения не найдены",
    SS902711: "день",
    SS902712: "дня",
    SS902713: "дней",
    SS902714: "неделя",
    SS902715: "недели",
    SS902716: "недель",
    SS902717: "месяц",
    SS902718: "месяца",
    SS902719: "месяцев",

    //Переводы 6.10.21
    SS902720: "Введение остановлено",
    SS902729: "Назначение передано в приложение пациента",
    SS902733: "Заболевания",
    SS902734: "Дата постановки:",

    //Переводы 7.10.21
    SS902736: "Комментарий о самочувствии пациента отсутствует",

    //Переводы 8.10.21
    SS9035740: "Рассчитанное значение",
    SS9035741: "Выбранное значение",
    SS9035742: "ц.",
    SS9035743: "д.",
    SS9035744: "Введите причину изменения дозировки",
    SS9035745: "Причина корректировки: \"{cause}\".",
    SS9035746: "Удалить",
    SS9035747: "При сохранении информации о МН произошла ошибка",
    SS9035748: "Связь успешно установлена",
    SS9035749: "Связь удалена",
    SS9035750: "Перерыв успешно изменен",
    SS9035751: "Предыдущая страница",
    SS9035752: "Следующая страница",
    SS9035753: "На эту неделю нет медикаментозных назначений",
    SS9035754: "Результат сохранен",
    SS9035755: "Редактировать",

    SS9035762: "Комментарий к причине {cause}",
    SS9035763: "Комментарий о самочувствии пациента отсутствует",

    SS9035784: "Далее",

    SS9035796: "длит.",

    //Переводы 11.10.21
    SS9035785: "Вы уверены, что хотите завершить план лечения {treatmentPlan}?",
    SS9035786: "Вы действительно хотите удалить план лечения {treatmentPlan}?",
    SS9035787: "Удалить",
    SS9035788: "Удалить",

    SS9035797: "Список пуст…",

    SS9035812: "Если вы измените значение ППТ/клиренс креатинина, дозы препаратов будут пересчитаны и вам необходимо будет снова подтвердить их назначение.\nИзменить значение текущего состояния пациента?",
    SS9035813: "Изменить",
    SS9035814: "Отмена",

    //Переводы 21.10.21
    SS9035858: "Список пуст…",
    SS9035859: "копия",
    SS9035860: "Поиск…",
    SS9035861: "Нагрузочная доза",

    //Переводы 22.10.21
    SS9035862: "Нагрузочная",
    SS9035865: "Документы",

    //Переводы 27.10.21
    SS9035905: "Отсутствует конфигурация для CareCoordination",

    //Переводы 1.11.21
    TT019190: "Нет",
    TT019200: "Удалить",
    TT019760: "Редактировать",
    TT019850: "Удалить группу МН",

    //Переводы 11.11.21
    TT021170: "Расчет дозировки: при изменении корректировки отличной от 100% укажите причину",
    TT021180: "Схема ФФОМС",
    TT021190: "Расчет дозировки: при изменении корректировки отличной от 100% укажите причину",
    TT021200: "Данные о препарате",
    TT021210: "Здесь будет описание препарата",
    TT021220: "Выберите Цикл лечения",
    TT021230: "Наименование и дата начала",
    TT021240: "Не указан врач",
    TT021250: "Не указан пациент",
    TT021260: "Не указан цикл линии лечения",
    TT021270: "Не указана организация",

    //Переводы 15.11.21
    SS9035930: "У пациента отсутствуют заболевания. ",
    SS9035931: "Перейти в профиль...",

    //Переводы 24.11.21
    TT021870: "Вы уверены, что хотите отменить создание Плана лечения?",
    TT021880: "Введите код по МКБ-10 или название заболевания",
    TT022000: "Введите наименование группы МН",
    TT022020: "Удалить всю группу МН включая все добавленные МН?",
    TT022030: "Удалить всю группу МН включая все добавленные МН?",
    TT022040: "При изменении протокола ОК будут удалены полученные данные о диагнозах и схемах лечения, произвести изменения?",
    TT022050: "Ошибка сервера",
    TT022060: "Вы уверены, что хотите отменить создание Плана лечения?",
    TT022070: "Новый",
    TT022080: "Обоснование для изменения схемы лечения",
    TT022090: "Отделение",
    TT022100: "По шаблону",
    TT022110: "Создать План лечения",
    TT022120: "Вы уверены, что хотите отменить создание Плана лечения?",
    TT022130: "Клинические рекомендации",
    TT022140: "Код",
    TT022150: "Схема лекарственной терапии (Протокол ОК от {consiliumDate})",
    TT022160: "Вы уверены, что хотите отменить создание Плана лечения?",
    TT022170: "Добавить",
    TT022180: "Схема лекарственной терапии",
    TT022210: "Код КСГ",
    TT022220: "Кол-во дней ведения в тарифе",
    TT022230: "Отделение {branch}",

    //Перевод dv кодов 29.11.21
    SS9035934: "Добавить файл формата .pdf",
    SS9035935: "Код схемы, наименование, описание",
    SS9035936: "Вы уверены, что хотите отменить создание шаблона лечения?",
    SS9035937: "Наименование Шаблона лечения",
    SS9035938: "Описание Шаблона лечения",
    SS9035939: "Удалить МН",
    SS9035940: "Описание",
    SS9035943: "Отделение не указано",
    SS9035947: "Код схемы",
    SS9035948: "Черновик",
    SS9035949: "Активный",
    SS9035950: "Архивный",
    SS9035951: "Удален",
    SS9035952: "При загрузке шаблонов произошла ошибка...",
    SS9035953: "Добавление нового плана лечения",
    SS9035954: "Шаблоны для выбранного МКБ-10 и типу терапии в системе не найдены",
    SS9035955: "Архивировать шаблон",

    //Переводы 1.12.21
    SS9035956: "Файл с данным форматом прикрепить невозможно",
    SS9035958: "Протокол ОК из другого медучреждения",
    SS9035959: "Номер протокола",
    SS9035960: "Дата проведения",
    SS9035961: "Мед. организация",
    SS9035962: "Скан протокола",
    SS9035963: "Загрузить скан протокола ОК. Допустимый формат для загрузки bmp, tiff, jpeg, png, pdf, doc, docx",
    SS9035964: "Удалить файл",

    //Переводы 2.12.21
    SS9035966: "Ошибка ресурса, отсутствует номер цикла",
    SS9035967: "При копировании цикла шаблона лечения произошла ошибка",

    //Переводы 3.12.21
    SS9035969: "Только дни приема",
    SS9035970: "от",
    SS9035971: "до",

    //Переводы 9.12.21
    TT022540: "Невозможно перевести Шаблон лечения в статус \"Активный\". Заполните поля: Продолжительность цикла, Количество циклов. Добавьте МН или Группу МН.", //Unable to set the Treatment Template to "Active" status. Fill in the fields: Cycle duration, Number of cycles. Add a Prescription or Prescription Group.

    //Переводы 11.01.22
    SS9035980: "Рекомендуемая нагрузочная доза",
    SS9035990: "Наименование Шаблона лечения:",
    SS9036000: "Еще",

    SS9036520: "Добавить участников из ранее созданной команды участников",

    //Переводы 2.02.22
    SS9036500: "Дата архивации",

    TT023250: "Шаблон лечения активирован и доступен для планирования терапии",
    TT023260: "Изменения сохранены",

    //Переводы MIRA-1866
    SS9038870: "Код схемы, наименование, описание",
    SS9038880: "Добавить новое заболевание",
    SS9038890: "По шаблону",
    SS9038900: "Новый",
    SS9038910: "Вы уверены, что хотите отменить создание Плана лечения?",
    SS9038920: "Код схемы, наименование, описание",
    SS9038930: "Статус заявки успешно изменен",
    SS9038940: "Наименование Шаблона лечения:",
    SS9038950: "Код КСГ",
    SS9038960: "Кол-во дней ведения в тарифе",
    SS9038970: "Комментарий к циклу лечения",
    SS9038980: "Подтвердите или отмените редактирование данных о текущем состоянии пациента",
    SS9038990: "Сохранить изменения",
    SS9039000: "История состояния пациента",
    SS9039010: "Дата",
    SS9039020: "Рост (см)",
    SS9039030: "Вес (кг)",
    SS9039040: "ППТ (м²)",
    SS9039050: "Ур. сыв. креат. (мкмоль/л)",
    SS9039060: "Клиренс креатинина (мл/мин)",
    SS9039070: "Пользователь",
    SS9039080: "Указанное значение уровня сывороточного креатинина находится вне ожидаемого интервала нормальных значений. Проверьте результат и его референсные значения в исследовании пациента. Норма креатинина в крови –",
    SS9039090: "дети до 1 года: 18—35 мкмоль/л",
    SS9039100: "дети от 1 года до 14 лет: 27—62 мкмоль/л",
    SS9039110: "мужчины: 74.0—110.0 мкмоль/л",
    SS9039120: "женщины: 44.0—80.0 мкмоль/л",
    SS9039130: "Вы уверены, что хотите перевести Цикл лечения в работу?",
    SS9039140: "Нет доступных значений",
    SS9039150: "Дополнительные классификаторы и биомаркеры",
    SS9039160: "Введите описание",
    SS9039170: "Внимание!",
    SS9039180: "Загрузка документов",
    SS9039190: "СНИЛС идентификатор MIRA-1348",
    SS9039200: "Новый датапикер MIRA-547 v3",
    SS9039210: "Список загружаемых справочников",
    SS9039220: "Выберите шаблоны лечения для экспорта",
    SS9039230: "Код схемы",
    SS9039240: "Наименование",
    SS9039250: "Выберите архив для импорта шаблонов",
    SS9039260: "Выберите xlsx для импорта шаблонов",
    SS9039270: "Импортировать XLSX",
    SS9039280: "Стадия импорта: {stageImportXlsxText}",
    SS9039290: "Обработка возможна только для форматов: jpeg, png, pdf.",
    SS9039300: "Укажите обоснование для изменения схемы лечения",
    SS9039310: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10",
    SS9039320: "Выберите другой Протокол ОК",
    SS9039330: "Измените диагноз МКБ-10 в Протоколе осмотра врача",
    SS9039340: "Выберите \"Протокол ОК из другого медучреждения\"",
    SS9039350: "Отмените выбор протокола ОК в поле \"Онкоконсилиум\"",
    SS9039360: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом, полученным из Протокола осмотра врача",
    SS9039370: " Добавление плана лечения по диагнозу C97 запрещено, необходимо изменить диагноз МКБ-10 в Протоколе осмотра врача",
    SS9039380: "Диагноз МКБ-10 в выбранном протоколе ОК не совпадает с диагнозом в поле МКБ-10",
    SS9039390: "Назначен",
    SS9039400: "Не удалось загрузить данные плана лечения",
    SS9039410: "Если были внесены изменения в Цикл лечения, необходимо повторно распечатать Лист назначений",
    SS9039420: "Рассчитанное по формуле Мостеллера",
    SS9039430: "Рассчитанное по формуле Кокрофта-Голта",
    SS9039440: "Загрузка...",
    SS9039450: "Невозможно удалить план лечения. В плане имеются циклы со статусами отличными от \"Создан\"/\"Удален\"/\"Скопирован\"",
    SS9039460: "Наименование плана лечения не может быть пустым",
    SS9039470: "Вы уверены, что хотите завершить план лечения?",
    SS9039480: "Добавлены группы из всех шаблонов",
    SS9039490: "При добавлении групп из всех шаблонов произошла ошибка",
    SS9039500: "Добавление всех шаблонов",
    SS9039510: "При запросе к AidboxQuery 'appointment-info-search' произошла ошибка",
    SS9039520: "Формат файла не поддерживается",
    SS9039530: "Файл успешно загружен",
    SS9039540: "При загрузке файла произошла ошибка",
    SS9039550: "Вы действительно хотите удалить {subject} {code}?",
    SS9039560: "Коды заболеваний по МКБ 10",
    SS9039570: "Без наименования",
    SS9039580: "Укажите уникальное наименование Шаблона лечения",
    SS9039590: "Загрузка информации",
    SS9039600: "Загрузка списка организаций...",
    SS9039610: "Новая специализация",
    SS9039620: "Выбор специализации",
    SS9039630: "Новая специализация",
    SS9039640: "Выбор специализации",
    SS9039650: "Клинические рекомендации",
    SS9039660: "Удалить",
    SS9039670: "Медицинская организация",
    SS9039680: "Тип стационара",
    SS9039690: "Подождите. Идет создание заявок...",
    SS9040000: "Печать ЛН",
    SS9039990: "Вернуть в работу",
    SS9039710: "от {first_value} до {second_value} {unit}",
    TT023350: "Медикаментозное назначение успешно удалено",
    TT023360: "Не удалось удалить Медикаментозное Назначение",
    SS9040010: "Весь цикл",
    SS9040060: "Сохранить как шаблон",
    SS9040070: "Сохранить Шаблон лечения на основании Плана лечения?",
    SS9040080: "Невозможно сохранить пустой План лечения как шаблон",
    TT023400: "Шаблон лечения создан успешно",
    SS9040370: "Вы действительно хотите прервать Цикл лечения?",
    SS9040380: "Данные о Цикле лечения не будут переданы в МИС, так как не заполнено поле Схема лекарственной терапии.",
    SS9040390: "Перевести Цикл лечения в работу?",
    SS9040400: "Статус",
    SS9040410: "Тип",
    SS9040420: "№ИБ",
    SS9040430: "Дата начала",
    SS9040440: "Дата окончания",
    SS9040450: "Порт-система",
    SS9040460: "Рост(см)",
    SS9040470: "Вес(кг)",
    SS9040480: "Ур. сыв. креат. (мкмоль/л)",
    SS9040490: "Лечащий врач",
    SS9040500: "Диагноз",
    SS9040510: "Открыт",
    SS9040520: "Журнал госпитализаций",
    SS9040810: "Протокол должен быть в статусе «Готов», «К подписанию» или «Подписан ЭП»",
    TT023600 : "Введите корректный Номер цикла лечения. Допустимое значение для ввода от 1 до 99",
    TT023610 : "Поле Номер цикла не может быть пустым",
    TT023620 : "Номер цикла лечения редактируется только для первого цикла в Плане лечения",
    TT023630 : "Редактирование номера цикла лечения #ММ",
    TT023640 : "Не удалось изменить номер цикла лечения",
    SS9040590 : "Обновить данные",
    TT023670: "Протокол должен быть в статусе «Подписан ЭП»",
    TT023680: "Файл недоступен для просмотра. Закройте область и выберите другой файл.",
    TT023690: "Закрыть",
    SS9040970: "Для препарата настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить назначение препарата?",
    SS9040980: "Для препаратов из группы МН настроен индивидуальный порядок приема на вкладке “Лист назначений”, вы уверены, что хотите удалить Группу МН, включая все добавленные МН?",
    TT024030: "Для перевода цикла в статус {status} необходимо выбрать медикаментозное назначение из всех основных групп",
    TT024040: "После внесенных изменений необходимо повторно настроить порядок приема МН на вкладке “Лист назначений“",
    TT024050: "Для перевода цикла в статус {status} необходимо создать медикаментозные назначения",
    TT024060: "Для перевода цикла в статус {status} необходимо заполнить поле \"Прод. цикла (д)\"",
    SS9040990:	"Показать только дни приема",
    TT024120: "Для перевода цикла в статус \"В работе\" необходимо подтвердить все медикаментозные назначения",
};
