import _get from "lodash/get";
import { observable } from "mobx";
import { locales } from "Locale";
import { observable } from "mobx";
import { Translator } from "eo-locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";

/**
 * пол пациента
 */
export class PatientGender {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * перевод
     */
    translate = (code) => {
        return (new Translator(HeaderStore.locale, locales)).translate(code);
    }

    /**
     * значение
     */
    get value() {
        return _get(this.resource, "gender");
    }

    /**
     * полное название (мужчина/женщина)
     */
    get name() {
        if ( this.resource.gender == "male" ) {
            return this.translate("SS003005");
        }
        else if ( this.resource.gender == "female" ) {
            return this.translate("SS003004");
        }
        else {
            return '-';
        }
    }

    /**
     * полное название (мужской/женский)
     */
    get fullName() {
        if ( this.resource.gender == "male" ) {
            return this.translate("SS002062");
        }
        if ( this.resource.gender == "female" ) {
            return this.translate("SS002063");
        }
    }

    /**
     * ультра-короткое название (м/ж)
     */
    get tinyName() {
        if ( this.resource.gender == "male" ) {
            return this.translate("SS014305");
        }
        if ( this.resource.gender == "female" ) {
            return this.translate("SS014306");
        }
    }

    /**
     * короткое название (муж/жен)
     */
    get shortName() {
        if ( this.resource.gender == "male" ) {
            return this.translate("SS014058");
        }
        if ( this.resource.gender == "female" ) {
            return this.translate("SS014059");
        }
    }

}
