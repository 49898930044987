/**
 * https://www.hl7.org/fhir/element.html
 */

import uuid from 'uuid/v4';
import { _BaseClass } from './_BaseClass';


export class Element extends _BaseClass {

    /**
     *
     */
    _setClassName() {
        this._className = 'Element';
    }

    /**
     * тут всё унаследовано от базового типа
     */
    _init(data) {

        super._init(data);

        if (data.hasOwnProperty('extension')) {
            this._data.extension = data.extension;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check extension
    }

    /**
     *
     */
    get id() {
        return this._data.id
    }

    /**
     *
     */
    set id(newValue) {
        throw 'ID cannot be changed';
    }

    /**
     *
     */
    get extension() {
        return this._data.extension;
    }

    /**
     *
     */
    set extension(newValue) {
        this._data.extension = newValue;
    }
}

