
// @ts-ignore
import {DomainResource} from "MODEL_STORE/FHIR/Resources/DomainResource"
// @ts-ignore
import {AttachmentConfig, Attachment} from "MODEL_STORE/FHIR/Elements/Attachment"
// @ts-ignore
import {DOCUMENT_STATUS} from "MODEL_STORE/FHIR/CodeSystem/DocumentStatus"
// @ts-ignore
import { CodeableConcept } from 'MODEL_STORE/FHIR/Elements/CodeableConcept';
// @ts-ignore
import {Reference} from "MODEL_STORE/FHIR/Elements/Reference";
// @ts-ignore
import { AuthStore } from 'MODEL_STORE/DataSource/Stores/AuthStore';
// @ts-ignore
import {BackboneElement} from "MODEL_STORE/FHIR/Elements/BackboneElement";
// @ts-ignore
import {Narrative} from "MODEL_STORE/FHIR/Elements/Narrative";
import moment from "moment";
import {Translator} from "eo-locale";
import {HeaderStore} from "../../DataSource/Stores/HeaderStore";
import {locales} from "../../../Locale";

export interface DocumentReferenceConfig {
    id: string,
    status: string,
    description: any,
    content: any[],
    // category: any[],
    date: string
    type: any,
    custodian: any,
    //TODO тут вроде должены быть [референсы], но пока просто [ФИО]
    author: any[],
    subject: any,
    context: any,
    text: any,
    extension: any[]
}


export class DocumentReference extends DomainResource {

    _className: string;
    _data: DocumentReferenceConfig;


    _setClassName() {
        this._className = 'DocumentReference';
    }

    /**
     * заполнение данных по умолчанию, которые всегда должны быть
     */

    // @action
    _init(data :DocumentReferenceConfig) {


        super._init(data);


        const translator = new Translator(HeaderStore.locale, locales);
        this._data.status = data.status || DOCUMENT_STATUS.CURRENT;
        this._data.description = data.description || translator.getMessageById("SS007005");

        this._data.content =  data.content
            ? data.content.map( elem => ({attachment: new Attachment(elem.attachment)._data}) )
            : [];

        // if (data.category)
        //     this._data.category = data.category.map(elem => new CodeableConcept(elem)._data);

        this._data.date = data.date || moment().format();

        if (data.type)
            this._data.type = new CodeableConcept(data.type)._data;

        if (data.custodian)
            this._data.custodian = data.custodian;

        if (data.author) {
            this._data.author = data.author;
        } else {
            if (AuthStore.currentUserData.activeRole) {
                this._data.author = [Reference.fromResource(AuthStore.currentUserData.activeRole)._data];
            }
        }

        if (data.subject) {
            this._data.subject = new Reference(data.subject)._data;
        }

        if (data.context)
            this._data.context = new BackboneElement(data.context)._data;

        if (data.text)
            this._data.text = new Narrative(data.text)._data;
        // if (data.extension) {
        // this._data.extension = [{
        //   "url": "http://miramedix.ru/fhir/StructureDefinition/app-tb-document-context-code",
        //   "valueString": "123"
        // }];
        // }

        // if (data.extension) {
          this._data.extension = [{
            "url": "http://miramedix.ru/fhir/StructureDefinition/app-tb-document-context-code",
            "valueString": "empty"
          }];
        // }

    }


    get _isValidName() {
        return this.description.trim() !== ''
    }

    get _isValidSubject() {
        return this.subject
    }

    get _isValidAttachments() {
        return this.content.length !== 0
    }

    get _isValidDate() {
        return this.date !== ''
    }


    get _isValidMO() {
        return this.custodian && (this.custodian.display || this.custodian.id);
    }


    get _isValidType() {
        return this.type
    }


    isValidDocument({attachmentsIsNotRequired=false}) {
        let isValid = true;
        if (!attachmentsIsNotRequired) {
            isValid = this._isValidAttachments;
        }
        return isValid &&
            this._isValidDate &&
            this._isValidMO &&
            this._isValidName &&
            this._isValidType;
    }

    /**
     *
     */
    get status() {
        return this._data.status || '';
    }

    /**
     *
     */
    set status(newValue :string) {
        this._data.status = newValue
    }


    /**
     *
     */
    get description() {
        return this._data.description || '';
    }

    /**
     *
     */
    set description(newValue :string) {
        this._data.description = newValue
    }

    get extension(){
      return this._data.extension || [];
    }

    set extension(newValue :any[]) {
      this._data.extension = [{
        "url": "http://miramedix.ru/fhir/StructureDefinition/app-tb-document-context-code",
        "valueString": newValue
      }];;
    }

    /**
     *
     */
    get content() {
        return this._data.content || []
    }

    /**
     *
     */
    set content(newValue :any[]) {
        this._data.content = newValue
    }
    //
    // /**
    //  *
    //  */
    // get category() {
    //     return this._data.category || []
    // }
    //
    // /**
    //  *
    //  */
    // set category(newValue :any[]) {
    //     this._data.category = newValue
    // }

    /**
     *
     */
    get date() {
        return this._data.date || null
    }

    /**
     *
     */
    set date(newValue :string) {
        this._data.date = newValue
    }

    /**
     *
     */
    get type() {
        return this._data.type
    }

    /**
     *
     */
    set type(newValue :any) {
        this._data.type = newValue
    }

    /**
     *
     */
    get custodian() {
        return this._data.custodian
    }

    /**
     *
     */
    set custodian(newValue :any) {
        this._data.custodian = newValue
    }

    /**
     *
     */
    get author() {
        return this._data.author || []
    }

    /**
     *
     */
    set author(newValue :any[]) {
        this._data.author = newValue
    }

    /**
     *
     */
    get subject() {
        return this._data.subject;
    }

    /**
     *
     */
    set subject(newResource) {
        this._data.subject = Reference.fromResource(newResource)._data;
    }

    /**
     *
     */
    get context() {
        return this._data.context
    }

    /**
     *
     */
    set context(newValue :any) {
        this._data.context = newValue
    }

    /**
     *
     */
    get text() {
        return new Narrative(this._data.text)._data
    }

    /**
     *
     */
    set text(newValue :any) {
        this._data.text = new Narrative(newValue)._data
    }
}
