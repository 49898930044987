import _get from "lodash/get";
import { observable } from "mobx";
import _isEqual from "lodash/isEqual";

import {
    REFERRER_PARTICIPANT,
    CHAIRMAN_PARTICIPANT,
    SECRETARY_PARTICIPANT,
    ADDITIONAL_PARTICIPANT,
    PRIMARY_PARTICIPANT_TYPE_SYSTEM,
    DRUG_TREATMENT_PARTICIPANT,
    RADIATION_THERAPY_PARTICIPANT,
    SURGICAL_PARTICIPANT,
} from "MODEL_STORE/DataSource/Constants/participant_types";

/**
 * собираем объект из массива coding[]
 */
const CODING_REDUCER = (accumulator, coding) => {
    accumulator[coding.system] = coding.code;
    return accumulator;
}

/**
 * концепт типа участия
 */
export class ParticipantType {

    /**
     * ссылка на CareTeam.participant.role[]
     */
    @observable concepts = null;

    /**
     * concepts - ссылка на CareTeam.participant.role[]
     */
    constructor(concepts = []) {
        this.concepts = concepts;
    }

    /**
     * индекс концепта в CareTeam.participant.role[]
     */
    get index() {
        return this.concepts.findIndex(codeableConcept => {
            return _get(codeableConcept, "coding", []).find(coding => {
                return coding.system == PRIMARY_PARTICIPANT_TYPE_SYSTEM;
            });
        });
    }

    /**
     * концепт типа участия
     */
    get concept() {
        return this.concepts[this.index];
    }

    /**
     * массив кодирования
     */
    get coding() {
        return _get(this.concept, "coding", []);
    }

    /**
     * название (например, Секретарь)
     */
    get name() {
        return _get(this.concept, "text", "");
    }

    /**
     * тип участника - секретарь?
     */
    get isChairman() {
        return this.is(CHAIRMAN_PARTICIPANT);
    }

    /**
     * тип участника - секретарь?
     */
    get isSecretary() {
        return this.is(SECRETARY_PARTICIPANT);
    }

    /**
     * тип участника - cпециалист по хирургическому лечению?
     */
    get isSurgical() {
        return this.is(SURGICAL_PARTICIPANT);
    }

    /**
     * тип участника - cпециалист по лекарственному лечению?
     */
    get isDrugTreatment() {
        return this.is(DRUG_TREATMENT_PARTICIPANT);
    }

    /**
     * тип участника - cпециалист по радиотерапевтическому лечению?
     */
    get isRadiationTherapy() {
        return this.is(RADIATION_THERAPY_PARTICIPANT);
    }

    /**
     * тип участника - лечащий врач?
     */
    get isReferrer() {
        return this.is(REFERRER_PARTICIPANT);
    }

    /**
     * это дополнительный участник?
     */
    get isAdditional() {
        return this.is(ADDITIONAL_PARTICIPANT);
    }

    /**
     * сравниваем тип участия
     */
    is = (participant) => {
        const current = this.coding.reduce(CODING_REDUCER, {});
        const comparable = participant.type[0].coding.reduce(CODING_REDUCER, {});
        return _isEqual(current, comparable);
    }

    /**
     * арм-роль для текущего типа участия
     */
    get accessRole() {
        if(this.isChairman) {
            return "arm_chairman";
        }
        if(this.isReferrer) {
            return "arm_practitioner";
        }
        if(this.isSecretary) {
            return "arm_secretary";
        }
        return "arm_expert";
    }

    /**
     * название арм-роли
     */
    get accessRoleName() {
        if(this.isChairman) {
            return "председатель";
        }
        if(this.isReferrer) {
            return "лечащий врач";
        }
        if(this.isSecretary) {
            return "секретарь";
        }
        return "эксперт";
    }

    /**
     * выбираем код строки в зависимости от типа участия
     */
    get displayCode() {
        if(this.isChairman){
            return 'SS022004'
        }else if(this.isSecretary){
            return 'SS022005'
        }else if(this.isSurgical){
            return 'SS022006'
        }else if(this.isDrugTreatment){
            return 'SS022007'
        }else if(this.isRadiationTherapy){
            return 'SS022008'
        }else if(this.isReferrer){
            return 'SS026028A'
        }else if(this.isAdditional){
            return 'SS022009'
        }
        return this.name;
    }

}
