const Paths = {

	dashboard: {
        path : () => `/dashboard`,
        mask : () => `/dashboard`,
	},

    tb : {
		servicerequest : {
			path : () => `/servicerequest/*`,
			mask : () => `/servicerequest/*`,
		},
		appointment : {
			path : () => `/appointment/*`,
			mask : () => `/appointment/*`,
		},
        protocol : {
			path : () => `/protocol/*`,
			mask : () => `/protocol/*`,
		},
        careteam : {
			path : () => `/careteam/*`,
			mask : () => `/careteam/*`,
		},
    },

    main : {
        patient : {
			path : () => `/patient/*`,
			mask : () => `/patient/*`,
		},
        practitioner : {
			path : () => `/practitioner/*`,
			mask : () => `/practitioner/*`,
		},
        organization : {
			path : () => `/organization/*`,
			mask : () => `/organization/*`,
		},
        directions : {
			path : () => `/directions/*`,
			mask : () => `/directions/*`,
		},
        condition : {
			path : () => `/condition/*`,
			mask : () => `/condition/*`,
		},
        settings : {
			path : () => `/settings`,
			mask : () => `/settings`,
		},
        diagnosis : {
			path : () => `/diagnosis/*`,
			mask : () => `/diagnosis/*`,
		},
        documents : {
			path : () => `/documents`,
			mask : () => `/documents`,
		},
		rolesmanagement : {
			path : () => `/rolesmanagement`,
			mask : () => `/rolesmanagement`,
		}
    },

	protocol : {
        all : {
            path : () => `/protocol/*`,
            mask : () => `/protocol/*`,
        }
	},
};

export { Paths };
