import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Data } from "./Data";

import {
    FullHeightWrapper,
    Toolbar,
    SettingsContainer,
    InputRow,
    Button,
    Select,
    Label,
    Input,
    Request,
    Response,
} from "./Components";

@observer
export class Element extends React.Component {

    data = new Data();

    @observable
    settingsVisible = false;

    toggleLogVisible = () => {
        this.logVisible = !this.logVisible;
    }

    toggleSettingsVisible = () => {
        this.settingsVisible = !this.settingsVisible;
    }

    onKeyPress = (e) => {
        if (e.ctrlKey && !e.altKey && !e.shiftKey && e.charCode == 13) {
            this.data.sendRequest();
        }
    }

    render() {

        console.log(this.data.responseData);

        return (
            <FullHeightWrapper onKeyPress={this.onKeyPress}>

                <Toolbar>
                    <Select value={this.data.selectedEndpoint} onChange={e => this.data.selectedEndpoint = e.target.value}>
                        <option value={null}>select enpoint...</option>
                        {
                            this.data.endpointList.map((e, index) => {
                                return (
                                    <option key={index} value={e.path}>{e.title} ({e.path})</option>
                                );
                            })
                        }
                    </Select>

                    <Button onClick={this.data.sendRequest}>Send</Button>
                    <Button onClick={this.toggleSettingsVisible}>Settings</Button>
                </Toolbar>

                {
                    this.settingsVisible &&
                    <SettingsContainer>
                        <InputRow>
                            <Label>Basic auth user</Label>
                            <Input value={this.data.basicAuthUser} onChange={e => this.data.basicAuthUser = e.target.value} />
                        </InputRow>
                        <InputRow>
                            <Label>Basic auth password</Label>
                            <Input value={this.data.basicAuthPassword} onChange={e => this.data.basicAuthPassword = e.target.value} />
                        </InputRow>
                        <InputRow>
                            <Label>User ID</Label>
                            <Input value={this.data.userId} onChange={e => this.data.userId = e.target.value} />
                        </InputRow>
                        <InputRow>
                            <Label>Access token</Label>
                            <Input defaultValue={this.data.accessToken} />
                            <Button onClick={this.data.getAccessToken}>Refresh</Button>
                        </InputRow>
                    </SettingsContainer>
                }

                <Request
                    placeholder="json you place here"
                    defaultValue={this.data.requestData}
                    onChange={e => this.data.requestData = e.target.value}
                >
                </Request>

                <Response dangerouslySetInnerHTML={{__html: this.data.responseData}}>
                </Response>

            </FullHeightWrapper>
        );

    }


}