export const mm_dictionary = {
    SS016001: "Patient list",
    SS016002: "Patient's full name",
    SS016003: "Gender",
    SS016004: "Male",
    SS016005: "Female",
    SS016006: "Date of Birth",
    SS016007: "Coverage Document",
    SS016008: "Reset filters",
    SS016009: "No patients were found. Change your search terms",
    SS016010: "Patient's full name",
    SS016011: "Gender",
    SS016012: "Male",
    SS016013: "Female",
    SS016014: "Date of Birth",
    SS016015: "Coverage Document",
    SS016016: "Patient list",
    SS016017: "Patient treatment plans",
    SS016018: "View reports and Patient Cases",
    SS016019: "",
    SS016020: "E-mail:",
    SS016021: "",
    SS016022: "",
    SS016023: "{x} more...",
    SS016024: "",
    SS016025: "All diseases",
    SS016026: "Add a disease",
    SS016027: "Add treatment plan",
    SS016028: "Show Completed",
    SS016029: "Medications",
    SS016030: "Add a treatment plan",
    SS016031: "ICD-10",
    SS016032: "ICD-10",
    SS016033: "",
    SS016034: "",
    SS016035: "Location of treatment",
    SS016036: "Location of treatment",
    SS016037: "Drug Therapy type",
    SS016038: "Drug Therapy type",
    SS016039: "",
    SS016040: "Treatment Line",
    SS016041: "Estimated start date of treatment",
    SS016042: "By template",
    SS016043: "Regimen name",
    SS016044: "A brief description of the treatment regimen",
    SS016045: "Description",
    SS016046: "Add",
    SS016047: "Cancel",
    SS016048: "Select at least one disease",
    SS016049: "Select organization",
    SS016050: "Select the type of drug therapy",
    SS016051: "Choose the type of drug therapy",
    SS016052: "Specify the name of the scheme",
    SS016053: "Specify a brief description of the scheme",
    SS016054: "Record added",
    SS016055: "",
    SS016056: "{x} line",
    SS016057: "",
    SS016058: "Print a Treatment Plan report",
    SS016059: "Save in the template",
    SS016060: "Delete treatment plan",
    SS016061: "Cycle Name",
    SS016062: "Untitled",
    SS016063: "Cycle No.",
    SS016064: "Start date",
    SS016065: "End date",
    SS016066: "Status",
    SS016067: "",
    SS016068: "Add new cycle",
    SS016069: "New",
    SS016070: "Copy",
    SS016071: "Add",
    SS016072: "Copy",
    SS016073: "Cycle information update error",
    SS016074: "Medications",
    SS016075: "",
    SS016076: "Treatment plans",
    SS016077: "Patient treatment plans",
    SS016078: "Treatment cycle",
    SS016079: "Male",
    SS016080: "Female",
    SS016081: "View reports and Patient Cases",
    SS016082: "Status",
    SS016083: "",
    SS016084: "Disease",
    SS016085: "",
    SS016086: "Regimen name",
    SS016087: "A brief description of the regimen",
    SS016088: "Location of treatment",
    SS016089: "Drug Therapy type",
    SS016090: "",
    SS016091: "Treatment Line",
    SS016092: "Cycle Name",
    SS016093: "Cycle No.",
    SS016094: "Start date",
    SS016095: "Cycle duration (d)",
    SS016096: "Height (cm)",
    SS016097: "Weight (kg)",
    SS016098: "BSA (m²)",
    SS016099: "Serum creatinine (µmol/L)",
    SS016100: "Creatinine clearance (ml/min)",
    SS016101: "Treatment cycle",
    SS016102: "Daily summary",
    SS016103: "Treatment status",
    SS016104: "Results of the treatment cycle",
    SS016105: "The patient must be between {x1} and {x2} cm",
    SS016106: "Fill in the patient's height",
    SS016107: "The weight of the patient must be between 3 and 220 kg",
    SS016108: "Fill in the patient's weight",
    SS016109: "Fill in the duration of the treatment cycle and save it",
    SS016110: "Error of cycle information update",
    SS016111: "Error of adding a record",
    SS016112: "Cycle information has been successfully updated",
    SS016113: "Data has been updated",
    SS016114: "Premedication",
    SS016115: "Antitumor therapy",
    SS016116: "Concomitant therapy",
    SS016117: "Add medication",
    SS016118: "Medication name",
    SS016119: "Cycle days",
    SS016120: "Dose",
    SS016121: "Medication route",
    SS016122: "Injection duration",
    SS016123: "Multiplicity",
    SS016124: "Note/Comment",
    SS016125: "Screening",
    SS016126: "Medications",
    SS016127: "Assign",
    SS016128: "Delete",
    SS016129: "",
    SS016130: "Add a prescription - {x1}",
    SS016131: "Add a prescription  - Antitumor therapy",
    SS016132: "Add a prescription - Concomitant therapies",
    SS016133: "Height (cm)",
    SS016134: "Weight (kg)",
    SS016135: "BSA (m²)",
    SS016136: "Creatinine clearance (ml/min)",
    SS016137: "Add INN",
    SS016138: "Enter the name of the drug",
    SS016139: "",
    SS016140: "Confirm",
    SS016141: "Cancel",
    SS0161420: "Errors in preparing to save INNs: ",
    SS0161421: "No INNs added",
    SS016143: "Edit prescriptions - {x1}",
    SS016144: "Edit prescriptions - Antitumor Therapy",
    SS016145: "Edit prescriptions - Companion therapy",
    SS016146: "Height (cm)",
    SS016147: "Weight (kg)",
    SS016148: "BSA (m²)",
    SS016149: "Creatinine clearance (ml/min)",
    SS016150: "Add INN",
    SS016151: "The list is empty...",
    SS016152: "",
    SS016153: "Name",
    SS016154: "Compounding medication",
    SS016155: "",
    SS016156: "",
    SS016157: "Delete",
    SS016158: "Medication route",
    SS016159: "",
    SS016160: "Inform the patient",
    SS016161: "Medication method",
    SS016162: "",
    SS016163: "Type of infusion medium",
    SS016164: "",
    SS016165: "ml",
    SS016166: "y.o.",
    SS016167: "Injection duration",
    SS016168: "sec",
    SS016169: "min",
    SS016170: "hour",
    SS016171: "Medication period",
    SS016172: "Use this field to fill in the period of continuous medication intake within the duration of the cycle. If you need to fill it in, check the box",
    SS016173: "days",
    SS016174: "weeks",
    SS016175: "month",
    SS016176: "Multiplicity",
    SS016177: "",
    SS016178: "Cycle days",
    SS016179: "Note",
    SS016180: "Select from the list",
    SS016181: "",
    SS016182: "Comment",
    SS016183: "Enter a comment",
    SS016184: "Confirm",
    SS016185: "Cancel",
    SS016186: "Errors in preparation for the saving of the prescriptions: ",
    SS016187: "Medication route is not chosen",
    SS016188: "It is necessary to specify the amount and unit of the drug \"{x1}\"",
    SS016189: "The days of taking medication are not specified",
    SS016190: "Drug has been successfully added",
    SS016191: "Dose adjustment",
    SS016192: "Total dose",
    SS016193: "The final dose has been changed",
    SS016194: "Calculated dose ({unit})",
    SS016195: "Dose adjustment ({unit})",
    SS016196: "Total dose ({unit})",
    SS016197: "Medication name",
    SS016198: "Compounding medication",
    SS016199: "Edit",
    SS016200: "Cycle days",
    SS016201: "Dose",
    SS016202: "Medication route",
    SS016203: "Injection duration",
    SS016204: "Multiplicity",
    SS016205: "Note/Comment",
    SS016206: "Screening",
    SS016207: "",
    SS016208: "",
    SS016209: "",
    SS016210: "sec",
    SS016211: "min",
    SS016212: "Hour",
    SS016213: "",
    SS016214: "View medication prescription screening",
    SS016215: "Prescription will be transferred to the Patient App",
    SS016216: "Delete medication prescription",
    SS016217: "Final dose has been changed",
    SS016218: "Warning!",
    SS016219: "Are you sure you want to delete medication prescription?",
    SS016220: "Yes",
    SS016221: "No",
    SS016222: "Medication has been successfully deleted",
    SS016223: "Changes have been saved",
    SS016224: "To move the cycle to the {status} status, you must fill in the \"Cycle name\" field",
    SS016225: "Warning!",
    SS016226: "Are you sure to cancel treatment cycle #{x1} {x2}?",
    SS016227: "Yes",
    SS016228: "No",
    SS016229: "{x1} Day",
    SS016230: "Weeks:",
    SS016231: "",
    SS016232: "",
    SS016233: "Cancel",
    SS016234: "Activate",
    SS016235: "Pause",
    SS016236: "Terminate",
    SS016237: "Finish",
    SS016238: "Return to work",
    SS016239: "Terminate treatment",
    SS0162400: "Check that the form is filled out correctly: ",
    SS0162401: "Treatment Cycle Results",
    SS016241: "Warning!",
    SS016242: "Confirm the patient's current condition:",
    SS016243: "Height (cm)",
    SS016244: "Cycle",
    SS016245: "cm",
    SS016246: "Weight (kg)",
    SS016247: "Cycle",
    SS016248: "kg",
    SS016249: "Serum creatinine (µmol/L)",
    SS016250: "Cycle",
    SS016251: "μmol/L",
    SS016252: "Confirm",
    SS016253: "Fill in the patient's weight",
    SS016254: "To complete the Treatment Cycle, you should enter the correct data for each medication assignment in the \"Treatment Status\" tab",
    SS016255: "Fill in the reason for stopping/not injection the drug",
    SS016256: "Patient App tasks have been cancelled",
    SS016257: "Confirm all prescriptions",
    SS016258: "Previous medication prescriptions",
    SS016259: "Previous prescriptions",
    SS016260: "Select treatment cycle",
    SS016261: "Premedication",
    SS016262: "Composition: ",
    SS016263: "",
    SS016264: "Antitumor treatment",
    SS016265: "",
    SS016266: "",
    SS016267: "Days of intake:",
    SS016268: "Concomitant therapy",
    SS016269: "Injected",
    SS016270: "Discontinued",
    SS016271: "Not injected",
    SS016272: "Completed",
    SS016273: "Not done",
    SS016274: "Completed",
    SS016275: "at",
    SS016276: "Commercial name",
    SS016277: "",
    SS016278: "Comment on how the patient is feeling",
    SS016279: "Fill in the start date of the medication",
    SS016280: "Time:",
    SS016281: "Start",
    SS016282: "not specified",
    SS016283: "{date} at {time}",
    SS016284: "Finish",
    SS016285: "{date} at {time}",
    SS016286: "Reason for stopping the injection:",
    SS016287: "Select reason",
    SS016288: "",
    SS016289: "Comment on the reason for stopping the injection:",
    SS016290: "Comment on the reason for stopping the injection (mandatory):",
    SS016291: "The reason for not injecting:",
    SS016292: "Comment on the reason for not injecting:",
    SS016293: "Completed",
    SS016294: "{date} at {time}",
    SS016295: "Assessment of disease progression",
    SS016296: "Type",
    SS016297: "",
    SS016298: "Description",
    SS016299: "The disappearance of all extranodal target entities. All abnormal lymph nodes should shrink <10 mm in absolute terms in the short axis.",
    SS016300: "Decrease in the sum of the largest foci sizes by more than 30%; the primary research is taken for comparison",
    SS016301: "An increase in the sum of the largest dimensions by at least 20% compared to the time point with the smallest sum of the largest dimensions (nadir); Also the sum of the largest dimensions must show an absolute increase in size by at least 5 mm.; Reliable appearance of a new tumor nidus",
    SS016302: "Other cases",
    SS016303: "No assessment was made at the end of the treatment cycle",
    SS016304: "Assessment of the Toxicity and Effects of Chemotherapy",
    SS016305: "No grades",
    SS016306: "Summary",
    SS016307: "Save",
    SS016308: "Disorder Group",
    SS016309: "",
    SS016310: "Disorder Type",
    SS016311: "Loading…",
    SS016312: "",
    SS016313: "Grade",
    SS016314: "Effect description",
    SS016315: "",
    SS016316: "Definition",
    SS016317: "",
    SS016318: "Specify the disorder type for the group \"{x1}\"",
    SS016319: "Specify the effect level for the disorder \"{x1}\"",
    SS016320: "Print report",
    SS016321: "Report generation error",
    SS001038: "Customer Service:",
    SS017001: "Treatment templates",
    SS017002: "Name",
    SS017003: "Disease code",
    SS017004: "The list is empty…",
    SS017005: "Therapy Type",
    SS017006: "",
    SS017007: "Created",
    SS017008: "Archive",
    SS017009: "Reset filters",
    SS017010: "No templates were found. Change your search terms",
    SS017011: "Create a template",
    SS017012: "Treatment templates",
    SS017013: "Create a treatment template",
    SS017014: "Scheme name",
    SS017015: "Brief description of the treatment regimen",
    SS017016: "ICD-10:",
    SS017017: "Enter ICD-10 disease code",
    SS017018: "No diseases found",
    SS017019: "Drug therapy type:",
    SS017020: "Adjuvant",
    SS017021: "Definitive radiation therapy",
    SS017022: "Neoadjuvant",
    SS017023: "Total number of cycles:",
    SS017024: "Documents:",
    SS017025: "Cycle Name",
    SS017026: "Untitled",
    SS017027: "Number of cycles",
    SS017028: "Cycle duration (d)",
    SS017029: "Add a new cycle",
    SS017030: "New",
    SS017031: "Copy",
    SS017032: "Untitled",
    SS017033: "Add",
    SS017034: "Only PDF files are supported",
    SS017035: "Treatment templates",
    SS017036: "Edit the treatment template",
    SS017037: "Edit a cycle",
    SS017038: "Regimen name:",
    SS017039: "ICD-10:",
    SS017040: "Cycle Name",
    SS017041: "A brief description of the regimen:",
    SS017042: "Cycle duration (d)",
    SS017043: "Number of cycles",
    SS017044: "Treatment cycle",
    SS017045: "Day-by-day summary",
    SS017046: "To add a drug, enter the name of the treatment cycle",
    SS017047: "",
    SS017048: "Weeks",
    SS017049: "No medication appointments for this week",
    SS017050: "",
    SS017051: "Signaling information",
    SS90001: "The start time of medication must be before its end time",
    SS90002: "The completion time of medication must be later than its start time",
    SS90003: "The Treatment Plan is not created. The treatment start date must be after the patient's birth date",
    SS90004: "Unable to confirm a medication prescription with a dosage of 0",

    //Переводы за 3.09.21
    //33
    SS91188: "Name",
    SS91189: "Short description",
    SS91190: "Therapy type",
    SS91191: "Cycles",
    SS91192: "Source",
    SS91193: "Author",
    SS91194: "Date",
    SS91195: "Archive",
    SS91196: "Custom",
    SS91197: "Adjuvant",
    SS91198: "Definitive radiation therapy",
    SS91199: "Neoadjuvant",
    SS911100: "Treatment template deleted",
    //34
    SS911101: "Edit the treatment template",
    SS911102: "History of changes",
    SS911103: "Copy the treatment template",
    SS911104: "Remove template",
    //35
    SS911105: "Adjuvant",
    SS911106: "Definitive radiation therapy",
    SS911107: "Neoadjuvant",
    SS911108: "Save",
    SS911109: "To save the template, you must fill in the treatment scheme name",
    SS911110: "To save the template, you must fill in a brief description of the treatment regimen",
    SS911111: "To save the treatment template you need to specify the type of drug therapy",
    SS911112: "To save the template, you need to specify the illness code on ICD-10",
    SS911113: "Changes saved",
    //36
    SS911114: "Enter the reason for archiving",
    SS911115: "Cause",
    SS911116: "Cancel",
    SS911117: "Archive",
    SS911118: "Treatment template is moved to the archive",
    SS911119: "Treatment template is restored from the archive",
    //37
    SS911120: "Reason for archiving:",
    //38
    SS911121: "Warning!",
    SS911122: "Are you sure you want to delete the treatment template? ",
    SS911123: "Yes",
    SS911124: "No",
    //39
    SS911125: "History of template changes",
    SS911126: "Date",
    SS911127: "Full name",
    SS911128: "Date",
    SS911129: "Full name",
    //13
    SS91100: "ICD-10",
    //14
    SS91101: "Definitive radiation therapy",
    SS91102: "Adjuvant",
    SS91103: "Neoadjuvant",
    //15
    SS91104: "Loading",
    //16
    SS91105: "No information about regularly consumated drugs by this patient ",
    SS91106: "List of appointments",
    SS91107: "To transfer cycle in the \"Assigned\" status you must fill in the \"Cycle Duration (days)\"",
    SS91108: "To transfer cycle in the \"Assigned\" status you must fill in the \"Height\"",
    SS91109: "To transfer cycle in the \"Assigned\" status you must fill in the \"Weight\"",
    SS91110: "To transfer the cycle to the status of \"Assigned\" necessary to create a prescription",
    SS91111: "To transfer cycle in the \"Assigned\" status must confirm all medical prescriptions",
    //17
    SS91112: "The list is empty…",
    //18
    SS91113: "You must specify the volume and a measurement unit of infusive medium",
    //19
    SS91114: "You must specify the duration of medication",
    //20
    SS91115: "Precautionary measures:",
    //21
    SS91116: "Changes saved",
    //22
    SS91117: "View the results of questionaries",
    //23
    SS91118: "Results of questionaries",
    SS91119: "The total questionnaire",
    SS91120: "Poll description",
    SS91121: "30 questions",
    SS91122: "Results",
    SS91123: "Treated",
    SS91124: "Primary",
    SS91125: "No data found to display",
    //24
    SS91126: "By days",
    SS91127: "The whole cycle",
    //25
    SS91128: "The results of the treatment cycle",
    //26
    SS91129: "Saving",
    //27
    SS91130: "Change date and time",
    SS91131: "PM",
    //32
    SS91153: "To add a treatment plan, specify at least one of the patient's disease",
    //2
    SS91010: "The patient has no one case for a Tumor Board",
    //3
    SS91001: "Absence of sickness records",
    //4
    SS91002: "Treatment regimen",
    SS91003: "From template",
    SS91004: "New",
    SS91005: "To search for templates, specify the type of disease and drug therapy",
    SS91006: "Definitive radiation therapy",
    SS91007: "Adjuvant",
    SS91008: "Neoadjuvant",
    //6
    SS91009: "The scheme composition",
    "SS91010-1": "Cycle name",
    SS91011: "Duration (days)",
    //12
    SS91015: "Edit template",
    SS91016: "Name of scheme:",
    SS91017: "Type of drug therapy",
    SS91018: "Adjuvant",
    SS91019: "Definitive radiation therapy",
    SS91020: "Neoadjuvant",
    SS91021: "Cycles:",
    SS91022: "Short description:",
    SS91023: "The documents",
    //7
    SS91012: "Description:",
    SS91013: "Cycles",
    //Замечание 2 в переводах
    SS91014: "The treatment plan {treatmentPlanName} in the status of \"completed\". Editing а treatment cycle is not possible.",

    //Переводы за 7.09.21 - 13.09.21
    SS903320: "Saving in the template",
    SS903321: "A regimen name:",
    SS903322: "ICD-10",
    SS903323: "Code of the disease in the ICD-10",
    SS903324: "Drug therapy type:",
    SS903325: "Adjuvant",
    SS903326: "Definitive radiation therapy",
    SS903327: "Neoadyvante",
    SS903328: "Cycles",
    SS903329: "Cycle",
    SS903330: "Duration (d)",
    SS903331: "Short description",
    SS903332: "The documents:",
    SS903333: "Save",
    SS903334: "Specify the unique name of the regimen",
    SS903335: "Specify the name of the regimen",
    SS903336: "The template has been successfully saved",
    SS903337: "To add the drug, enter the data in the Height and Weight fields",
    SS903338: "To add the drug, enter the data in the Height and Weight fieldst. Save values.",
    SS903339: "Dosing schedule:",
    SS903340: "Side effects:",
    SS903341: "Storage:",
    SS903342: "Warning!",
    SS903343: "Are you sure to delete a treatment cycle?",
    SS903344: "Yes",
    SS903345: "No",
    SS903346: "If you change the value of the BSA/clearance of creatinine, doses of preparations will be recalculated and you will have to confirm their appointment again",
    SS903347: "Use the BSA value",
    SS903348: "Calculated",
    SS903349: "Use creatinine clearance value",
    SS903350: "Add a break",
    SS903351: "Break",
    SS903352: "min.",
    SS903353: "Break after completion",
    SS903354: "minutes",
    SS903355: "Change",
    SS903356: "Delete",
    SS903357: "By days",
    SS903358: "Whole cycle",
    SS903359: "Add break after completion",
    SS903360: "Change the break time",
    SS903361: "To switch the cycle to the status \"Assigned\", you must fill in the \"Start date\" field",
    SS903362: "To switch the cycle to the status \"Assigned\" it is necessary to fill in the \"Height\" field",
    SS903363: "To transfer the cycle to the status \"Assigned\", you must fill the field \"Weight\"",
    SS903364: "Medical prescriptions cannot be transferred into a mobile application, since the patient's account has not found",
    SS903365: "Changes saved",
    SS903366: "Warning!",
    SS903367: "This treatment template is used in operation. To edit it, the current template will be transferred to the archive and a copy will be created on its basis that you can edit",
    SS903368: "OK",
    SS903369: "Cancel",
    SS903370: "History of template changes",
    SS903371: "Date",
    SS903372: "Full name",
    SS903373: "Date",
    SS903374: "Full name",
    SS903375: "Warning!",
    SS903376: "Are you sure you want to remove the treatment template?",
    SS903377: "Delete",
    SS903378: "Cancel",
    SS903379: "Author",
    SS903380: "Status",
    SS903381: "Status",
    SS903382: "To edit the treatment cycle, you need to save the treatment template",
    SS903383: "To save the treatment template, you need to fill out the regimen name",
    SS903384: "To save the treatment template, you need to fill in a brief description of the treatment regimen",
    SS903385: "To save the treatment template, you need to specify the type of drug therapy",
    SS903386: "To save the treatment template, you need to specify the ICD-10",
    SS903387: "Treatment Template is activated and available for planning of a therapy",
    SS903388: "Specify the cause of archiving",
    SS903389: "Cause",
    SS903390: "Cancel",
    SS903391: "Archive",
    SS903392: "Archive",
    SS903393: "Edit",
    SS903394: "Delete",
    SS903395: "Activate",
    SS903396: "Save",
    SS903397: "Sheet of appointments",
    SS903398: "Add prescription",
    SS903399: "Add a group of prescriptions",
    SS903400: "Specify the unique name of the scheme",
    SS903401: "Enter the name of the treatment cycle",
    SS903402: "Enter the treatment cycle duration",
    SS903403: "Enter the number of treatment cycles",
    SS903404: "Changes saved",
    SS903405: "An error occurred when loading terminology directories",
    SS903406: "An error occurred when receiving data from the service. Try later",
    SS903407: "Save",
    SS903408: "Delete",
    SS903409: "Add prescription - Antitumor therapy",
    SS903410: "{Block-analog S016.6.4.1}",
    SS903411: "Errors in preparing to save prescription: No prescriptions added",
    SS903412: "Group:",
    SS903413: "Group name",
    SS903414: "Main appointment",
    SS903415: "Additional purpose",
    SS9040140: "Additional appointment",
    SS903416: "Often appointed",
    SS903417: "Base",
    SS903418: "Extra",
    SS903419: "Add a drug",
    SS903420: "Remove the prescription group",
    SS903421: "Remove the prescription of the drug",

    //Переводы добавленные 20.09.21
    SS904134: "Prescription editing - Antitumor therapy",
    SS904135: "from",
    SS904136: "to",
    SS904137: "Dosing interval",
    SS904138: "Remove",
    SS904139: "Medication route",
    SS904140: "Medication method",
    SS904141: "Type of infusion medium",
    SS904142: "ml",
    SS904143: "l",
    SS904144: "Injection duration",
    SS904145: "sec",
    SS904146: "min",
    SS904147: "hr",
    SS904148: "Medication period",
    SS904149: "day",
    SS904150: "week",
    SS904151: "month",
    SS904152: "Multiplicity",
    SS904153: "Cycle days",
    SS904154: "Comment",
    SS904155: "Select from a list",
    SS904156: "Dosing schedule",
    SS904157: "Add",
    SS904158: "Cancel",

    SS904175: "Add a prescription  - Antitumor therapy",
    SS904176: "Add drug",
    SS904177: "Add",
    SS904178: "Cancel",

    SS904180: "Save",
    SS904181: "Add",
    SS904182: "Cancel",

    //GLB-219-3
    SS904190: "Copied",
    SS904191: "Draft",
    SS904192: "Deleted",
    SS904193: "Ordered",
    SS904194: "Active",
    SS904195: "Cancelled",
    SS904196: "On-hold",
    SS904197: "Stopped",
    SS904198: "Completed",
    SS904199: "Error",
    SS904200: "Matching",
    SS904201: "Draft",
    SS904202: "Deleted",
    SS904203: "Active",
    SS904204: "Completed",

    //Переводы 27.09 - 28.09.21
    SS902277: "Treatment plans",

    SS902279: "Add a prescription - Premedication",
    SS902280: "Add a drug",
    SS902281: "Add",
    SS902282: "Cancel",
    SS902283: "Warning!",
    SS902284: "Are you sure you want to delete the Treatment Cycle?",
    SS902285: "Yes",
    SS902286: "No",
    SS902287: "Save",
    SS902288: "Delete",

    SS902293: "Patient Tumor Boards {patient}",
    SS902294: "Case",
    SS902295: "View a case for TB",
    SS902296: "Report",
    SS902297: "Select the report or case in the required Tumor Board for viewing",
    SS902298: "version",

    SS902331: "Complete the treatment plan",
    SS902332: "Add a treatment plan",
    SS902333: "Enter ICD-10 code, regimen code, name or description of a regimen",
    SS902334: "Templates",
    SS902335: "Basic regiments of insurance company",
    SS902336: "Search",
    SS902337: "Name",
    SS902338: "Description",
    SS902339: "Cycles",
    SS902340: "Add",
    SS902341: "Cancel",
    SS902342: "Loading",
    SS902343: "Loading dose",
    SS902344: "Course {count} {unit}",
    SS902345: "Add",
    SS902346: "Simultaneous reception with ...",
    SS902347: "Cancel simultaneous reception",

    SS902348: "Details",
    SS902349: "Drug screening",
    SS902350: "Warning!",
    SS902351: "Effect",
    SS902352: "Mechanism",
    SS902353: "Actions",
    SS902354: "Interaction",
    SS902355: "Drug food interactions",
    SS902356: "Drug alcohol interactions Interaction",
    SS902357: "Allergic effect",
    SS902358: "Diagnosis",
    SS902359: "Pharmacogenetics",
    SS902360: "Risk Level",
    SS902361: "Evidence",
    SS902362: "Speed",
    SS902363: "Actions",

    SS902400: "General questionnaire ORTC-QLQ-C30, version 3",
    SS902401: "Loading…",
    SS902402: "Date of result ",
    SS902403: "Overall health status ",
    SS902404: "General health status ",
    SS902405: "Functional scales ",
    SS902406: "Physical function ",
    SS902407: "Role function ",
    SS902408: "Emotional function ",
    SS902409: "Cognitive function ",
    SS902410: "Social function ",
    SS902411: "Symptoms ",
    SS902412: "Fatigue ",
    SS902413: "Nausea/vomiting ",
    SS902414: "Pain ",
    SS902415: "Dyspnea ",
    SS902416: "Insomnia ",
    SS902417: "Loss of appetite ",
    SS902418: "Constipation ",
    SS902419: "Diarrhea ",
    SS902420: "Financial difficulties ",

    SS902655: "View TB's report",

    //Переводы 1.10 - 4.10.21
    SS902661: "mg",
    SS902662: "To calculate the dosage of the drug, enter the serum creatinine level",

    SS902690: "Remove the toxicity assessment?",
    SS902691: "Yes",
    SS902692: "Cancel",
    SS902693: "Obtaining a list",
    SS902694: "Treatment report",
    SS902695: "Printing treatment report....",
    SS902696: "Print treatment plan report....",

    SS902708: "Description of nosological form",
    SS902709: "Loading…",
    SS902710: "Treatment templates not found",
    SS902711: "day",
    SS902712: "days",
    SS902713: "days",
    SS902714: "week",
    SS902715: "weeks",
    SS902716: "weeks",
    SS902717: "month",
    SS902718: "months",
    SS902719: "months",

    //Переводы 6.10.21
    SS902720: "Injection stopped",
    SS902729: "Prescription transferred to Mobile App",
    SS902733: "Diseases",
    SS902734: "Date confirmed:",

    //Переводы 7.10.21
    SS902736: "No comment about the patient's state of health",

    //Переводы 8.10.21
    SS9035740: "Calculated value",
    SS9035741: "Selected value",
    SS9035742: "c.",
    SS9035743: "d.",
    SS9035744: "Enter the reason for the dosage change",
    SS9035745: "Reason for adjustment: \"{cause}\".",
    SS9035746: "Delete",
    SS9035747: "Error while saving the prescription information",
    SS9035748: "Link was successfully established",
    SS9035749: "Link deleted",
    SS9035750: "Break successfully changed",
    SS9035751: "Previous page",
    SS9035752: "Next page",
    SS9035753: "No medication appointments for this week",
    SS9035754: "Result saved",
    SS9035755: "Edit",

    SS9035762: "Comment on {cause}",
    SS9035763: "No comment about the patient's state of health",

    SS9035784: "Next",

    SS9035796: "duration",

    //Переводы 11.10.21
    SS9035785: "Are you sure you want to complete the {treatmentPlan}?",
    SS9035786: "Are you sure you want to delete {treatmentPlan}?",
    SS9035787: "Delete",
    SS9035788: "Delete",

    SS9035797: "The list is empty…",

    SS9035812: "If you change the value of the BSA/clearance of creatinine, doses of preparations will be recalculated and you will have to confirm their appointment again.\nChange the value of the patient's current condition?",
    SS9035813: "Change",
    SS9035814: "Cancel",

    //Переводы 21.10.21
    SS9035858: "The list is empty…",
    SS9035859: "copy",
    SS9035860: "Search…",
    SS9035861: "Loading dose",

    //Переводы 22.10.21
    SS9035862: "Loading",
    SS9035865: "Documents",

    //Переводы 27.10.21
    SS9035905: "No configuration for CareCoordination",

    //Переводы 1.11.21
    TT019190: "No",
    TT019200: "Delete",
    TT019760: "Edit",
    TT019850: "Delete a group of prescriptions",

    //Переводы 11.11.21
    TT021170: "Dosage Calculation: If adjustment changes other than 100%, specify the reason",
    TT021180: "A regiment of insurance company",
    TT021190: "Dosage Calculation: If adjustment changes other than 100%, specify the reason",
    TT021200: "Drug Information",
    TT021210: "Here will be the drug description",
    TT021220: "Select Treatment Cycle",
    TT021230: "Name and start date",
    TT021240: "No doctor specified",
    TT021250: "Patient not specified",
    TT021260: "No cycle of treatment line was specified",
    TT021270: "Organization not specified",

    //Переводы 15.11.21
    SS9035930: "The patient does not have any diseases. ",
    SS9035931: "Switch to profile...",

    //Переводы 24.11.21
    TT021870: "Are you sure you want to cancel creating a Treatment Plan?",
    TT021880: "Enter the ICD-10 code or disease name",
    TT022000: "Enter the name of the presciption group",
    TT022020: "Delete the entire prescription group including all added prescriptions?",
    TT022030: "Delete the entire prescription group including all added prescriptions?",
    TT022040: "If you change the TB report the received data on diagnoses and treatment regimens will be deleted, make changes?",
    TT022050: "Server error",
    TT022060: "Are you sure to cancel the creation of the Treatment Plan?",
    TT022070: "New",
    TT022080: "Rationale for changing Treatment Regimen",
    TT022090: "Department",
    TT022100: "By template",
    TT022110: "Create a Treatment Plan",
    TT022120: "Are you sure to cancel the creation of a Treatment Plan?",
    TT022130: "Clinical guidelines",
    TT022140: "Code",
    TT022150: "Regimen of Drug Therapy (TB Report of {consiliumDate})",
    TT022160: "Are you sure you want to discard all the changes to the Treatment plan?",
    TT022170: "Add",
    TT022180: "Drug therapy regimen",
    TT022210: "Code of diagnostic related groups",
    TT022220: "Number of days of a Patient management under the rate",
    TT022230: "Department {branch}",

    //Перевод dv кодов 29.11.21
    SS9035934: "Add a .pdf file",
    SS9035935: "Regimen code, Name, Description",
    SS9035936: "Are you sure to cancel creating a Treatment Template?",
    SS9035937: "Name of the Treatment Template",
    SS9035938: "Description of the Treatment Template",
    SS9035939: "Delete a prescription",
    SS9035940: "Description",
    SS9035943: "Department not specified",
    SS9035947: "Regimen code",
    SS9035948: "Draft",
    SS9035949: "Active",
    SS9035950: "Archived",
    SS9035951: "Deleted",
    SS9035952: "An error occurred while loading the templates...",
    SS9035953: "Add a new treatment plan",
    SS9035954: "Templates for the selected ICD-10 and therapy type were not found in the system",
    SS9035955: "Archive template",

    //Переводы 1.12.21
    SS9035956: "It is not possible to attach a file of this format",
    SS9035958: "TB report is from another hospital",
    SS9035959: "Report number",
    SS9035960: "Event date",
    SS9035961: "Hospital",
    SS9035962: "Scan of report",
    SS9035963: "Upload a scan of the TB report. Acceptable formats for uploading are: bmp, tiff, jpeg, png, pdf, doc, docx",
    SS9035964: "Delete file",

    //Переводы 2.12.21
    SS9035966: "Resource error, missing cycle number",
    SS9035967: "An error while copying the treatment template cycle",

    //Переводы 3.12.21
    SS9035969: "Reception days only",
    SS9035970: "from",
    SS9035971: "to",

    //Переводы 9.12.21
    TT022540: "Unable to set the Treatment Template to \"Active\" status. Fill in the fields: Cycle duration, Number of cycles. Add a Prescription or Prescription Group.",

    //Переводы 11.01.22
    SS9035980: "Recommended loading dose",
    SS9035990: "Name of Treatment Template:",
    SS9036000: "More",

    SS9036520: "Add participants from the previously created TB team",

    //Переводы 2.02.22
    SS9036500: "Date of archiving",

    TT023250: "Treatment template is activated and available for treatment planning",
    TT023260: "Changes saved",

    //Переводы MIRA-1866
    SS9038870: "Regimen code, name or description of a regimen",
    SS9038880: "Add a disease",
    SS9038890: "By template",
    SS9038900: "New",
    SS9038910: "Are you sure you want to discard all the changes to the Treatment plan?",
    SS9038920: "Regimen code, name or description of a regimen",
    SS9038930: "The case status has been successfully updated",
    SS9038940: "Name of Treatment plan:",
    SS9038950: "Code of diagnostic related groups",
    SS9038960: "Number of days of a Patient care under the payment rate",
    SS9038970: "Note/Comment",
    SS9038980: "Confirm or cancel editing",
    SS9038990: "Save",
    SS9039000: "History of the patient's condition",
    SS9039010: "Date",
    SS9039020: "Height (cm)",
    SS9039030: "Weight (kg)",
    SS9039040: "BSA (m²)",
    SS9039050: "Serum creatinine (µmol/L)",
    SS9039060: "Creatinine clearance (ml/min)",
    SS9039070: "User",
    SS9039080: "The indicated level of serum creatinine is out of the standard deviation. Check the result and its reference values in the patient's screening report. Normal level of blood creatinine is -",
    SS9039090: "children under 1 y.o.: 18—35 μmol/L",
    SS9039100: "children ages 1 to 14: 27—62 μmol/L",
    SS9039110: "male: 74.0—110.0 μmol/L",
    SS9039120: "female: 44.0—80.0 μmol/L",
    SS9039130: "Activate the Treatment cycle?",
    SS9039140: "No available values",
    SS9039150: "Additional classifiers and biomarkers",
    SS9039160: "Fill a clinical description of the diagnosis",
    SS9039170: "Attention!",
    SS9039180: "Loading the documents",
    SS9039190: "SSN",
    SS9039200: "New datepicker",
    SS9039210: "List of loading registers",
    SS9039220: "Select treatment templates to export",
    SS9039230: "Regimen code",
    SS9039240: "Name",
    SS9039250: "Select an archive to import templates",
    SS9039260: "Select xlsx files to import templates",
    SS9039270: "Import XLSX",
    SS9039280: "Import stage: {stageImportXlsxText}",
    SS9039290: "Processing is possible only for jpeg, png file formats",
    SS9039300: "Specify the rationale for changing the treatment regimen",
    SS9039310: "The ICD-10 diagnosis in the selected Tumor Board report does not match the diagnosis in the ICD-10 field",
    SS9039320: "Select another Tumor Board report",
    SS9039330: "Change the ICD-10 diagnosis in the Physician's report",
    SS9039340: "Select \"TB report from another medical institution\"",
    SS9039350: "Discard selection of TB report",
    SS9039360: "The ICD-10 diagnosis in the selected  TB report does not match the diagnosis obtained from the Physician's report",
    SS9039370: "Add a treatment plan for a C97 diagnosis is prohibited, it is necessary to change the ICD-10 diagnosis in the Physician's report",
    SS9039380: "The ICD-10 diagnosis in the selected Tumor Board report does not match the diagnosis in the ICD-10 field",
    SS9039390: "Assigned",
    SS9039400: "Failed to load treatment plan data",
    SS9039410: "If you have made any changes to the Treatment Cycle, please re-print the Appointment list",
    SS9039420: "Calculated with the Mosteller formula",
    SS9039430: "Calculated with the Cockcroft-Gault formula",
    SS9039440: "Loading…",
    SS9039450: "Unable to delete treatment plan. There are cycles in the plan with statuses other than \"Draft\"/\"Deleted\"/\"Copied\"",
    SS9039460: "Treatment plan name cannot be empty",
    SS9039470: "Complete the Treatment plan?",
    SS9039480: "Groups from all templates have been added",
    SS9039490: "An error occurred while adding groups from all templates",
    SS9039500: "Add all templates",
    SS9039510: "An error occurred while querying AidboxQuery 'appointment-info-search'",
    SS9039520: "File format is not supported",
    SS9039530: "File uploaded successfully",
    SS9039540: "An error occurred while uploading the file",
    SS9039550: "Delete {subject} {code}?",
    SS9039560: "ICD-10 disease codes",
    SS9039570: "No name",
    SS9039580: "Type a unique name for the Treatment Template",
    SS9039590: "Loading information",
    SS9039600: "Loading the list of organizations…",
    SS9039610: "A new specialization",
    SS9039620: "Select a specialization",
    SS9039630: "A new specialization",
    SS9039640: "Selection of specialization",
    SS9039650: "Clinical guidelines",
    SS9039660: "Delete",
    SS9039670: "Organization",
    SS9039680: "Type of the hospital",
    SS9039690: "Creating cases... Please wait...",
    SS9040000: "Print",
    SS9039990: "Return to work",
    SS9039710: "from {first_value} to {second_value} {unit}",
    TT023350: "Medication has been successfully deleted",
    TT023360: "Failed to delete medication",
    SS9040010: "The whole cycle",
    SS9040060: "Save as a template",
    SS9040070: "Do you want to save Template based on the Treatment Plan?",
    SS9040080: "Unable to save an empty Treatment Plan as a template ",
    TT023400: "Template has been successfully created",
    SS9040370: "Are you sure you want to terminate the Treatment Cycle?",
    SS9040380: "Data on the Treatment Cycle will not be transferred to the MIS, since the Drug Therapy Scheme field is not filled in.",
    SS9040390: "Activate the Treatment cycle?",
    SS9040400: "Status",
    SS9040410: "Type",
    SS9040420: "MHS",
    SS9040430: "Start date",
    SS9040440: "Discharge date",
    SS9040450: "Port system",
    SS9040460: "Height (cm)",
    SS9040470: "Weight (kg)",
    SS9040480: "Serum creatinine (µmol/L)",
    SS9040490: "Attending physician",
    SS9040500: "Diagnosis",
    SS9040510: "Ongoing",
    SS9040520: "Hospitalization log",
    SS9040810: "The protocol has to have the status «Ready», «To be signed», or «Signed with an electronic signature»",
    TT023600 : "Valid input value is from 1 to 99",
    TT023610 : "The Cycle Number field cannot be empty.",
    TT023620 : "The treatment cycle number is edited only for the first cycle in the Treatment Plan",
    TT023630 : "Editing the treatment cycle number #MM",
    TT023640 : "Failed to change treatment cycle number",
    SS9040590: "Update",
    TT023670: "The protocol has to have the status «Signed with an electronic signature»",
    TT023680: "The file is not available for viewing. Close the area and select another file.",
    TT023690: "Close",
    SS9040970: "An individual administration schedule is set up for the medication on the 'Prescription List' tab. Are you sure you want to delete the medication prescription?",
    SS9040980: "An individual administration schedule is set up for medications from the group on the 'Prescription List' tab. Are you sure you want to delete the group, including all added medication prescription?",
    TT024030: "To transition the cycle to the {status} status, you need to select a medication order from all main groups",
    TT024040: "After making changes, you need to reconfigure the administration schedule for medication orders on the 'Prescription List' tab",
    TT024050: "To transition the cycle to the {status} status, you need to create medication orders",
    TT024060: "To change the cycle to status {status} you must fill in the field \"Cycle extension (d)\"",
    SS9040990: "Show only prescribed days",
    TT024120: "To switch the cycle to the \"Active\" status, it is necessary to confirm all prescriptions",
};
