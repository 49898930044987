import { _BasicStore } from 'MODEL_STORE/DataSource/Stores/_BasicStore';
import _set from "lodash/set";
import _get from "lodash.get";

/**
 * Запоминать значения, введенные в фильтры поиска
 */
export class SearchFilterStore extends _BasicStore {

    sectionCode;

    static storage = {};

    constructor(sectionCode) {
        super();
        this.sectionCode = sectionCode;
    }

    _getPath = (path) => {
        return `${this.sectionCode}.${path}`;
    }
    _getData = (path) => {
        return _get(SearchFilterStore.storage, this._getPath(path));
    }
    _setData = (path, val) => {
        _set(SearchFilterStore.storage, this._getPath(path), val);
    }

    static clearAll = () => {
        SearchFilterStore.storage = {};
    }

    clear = () => {
        delete SearchFilterStore.storage[this.sectionCode];
    }

    get gender() {
        return this._getData('gender');
    }
    set gender(val) {
        this._setData('gender', val);
    }

    get fullName() {
        return this._getData('fullName');
    }
    set fullName(val) {
        this._setData('fullName', val);
    }

    get birthDate() {
        return this._getData('birthDate');
    }
    set birthDate(val) {
        this._setData('birthDate', val);
    }

    get oms() {
        return this._getData('oms');
    }
    set oms(val) {
        this._setData('oms', val);
    }

    get statusFilter() {
        return this._getData('statusFilter');
    }
    set statusFilter(val) {
        this._setData('statusFilter', val);
    }

    get name() {
        return this._getData('name');
    }
    set name(val) {
        this._setData('name', val);
    }

    get mkb10() {
        return this._getData('mkb10');
    }
    set mkb10(val) {
        this._setData('mkb10', val);
    }

    get type() {
        return this._getData('type');
    }
    set type(val) {
        this._setData('type', val);
    }

    get author() {
        return this._getData('author');
    }
    set author(val) {
        this._setData('author', val);
    }
}
