import _get from "lodash/get";
import { observable } from "mobx";
import _orderBy from "lodash/orderBy";

/**
 * номер телефона пациента
 */
export class PatientPhone {

    /**
     * ссылка на ресурс пациента
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на контакт
     * https://jira.mmdx.ru/browse/MIRA-61
     */
    get contactPoint() {
        let phones = _get(this.resource, "telecom", []).filter(contactPoint => {
            return contactPoint.system == "phone";
        });
        const homePhones = phones.filter(contactPoint => contactPoint.use == "home");
        const mobilePhones = phones.filter(contactPoint => contactPoint.use == "mobile");
        if ( mobilePhones.length || homePhones.length ) {
            phones = mobilePhones.length ? mobilePhones : homePhones;
        }
        return _orderBy(phones, [contact => parseInt(contact.rank)], ["asc"])[0];
    }

    /**
     * номер телефона
     */
    get number() {
        return _get(this.contactPoint, "value", "");
    }

}
