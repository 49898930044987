import _get from "lodash/get";
import { observable } from "mobx";
const TYPE_OF_MEDICAL_CARD_SYSTEM = "http://miramedix.ru/fhir/cs/fcsp-cs-nsi-1-2-643-5-1-13-13-99-2-723"
const MEDICAL_RECORD_NUMBER_SYSTEM = "urn:oid:1.2.643.5.1.13.13.12.2.66.6795.100.1.1.16"

/**
 * инвалидность пациента
 */
export class TypeOfMedicalCard {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * id идентификатора случая в пациенте
     */
    @observable medicalCardNumberIdentifierId = null;

    /**
     * инициализация
     */
    constructor(medicalCardNumberIdentifierId, patient) {
       this.resource = patient;
       this.medicalCardNumberIdentifierId = medicalCardNumberIdentifierId;
    }

    /**
     * Получение типа
     */
    get typeOfMedicalCardCoding() {
        return _get(this.medicalCardNumberIdentifier, "type.coding", []).find(coding =>
            coding.system == TYPE_OF_MEDICAL_CARD_SYSTEM);
    }

    /**
     * Получение идентификатора номера карты
     */
    get medicalCardNumberIdentifier() {
        return _get(this.resource, "identifier", []).find(i =>
            i.system === MEDICAL_RECORD_NUMBER_SYSTEM && (this.medicalCardNumberIdentifierId ?
              i.id == this.medicalCardNumberIdentifierId : !i.id));
    }

    /**
     * Отображение
     */
    get display() {
        return this.typeOfMedicalCardCoding ? this.typeOfMedicalCardCoding.display : "";
    }
    /**
     * Код
     */
    get code() {
        return this.typeOfMedicalCardCoding ? this.typeOfMedicalCardCoding.code : "";
    }

    /**
     * значение
     */
    get defaultValue() {
        return this.code && this.display ? {
            value: this.code,
            label: this.display
        } : "";
    }

    onChange = (concept) => {
        if (!concept) {
            const index = _get(this.medicalCardNumberIdentifier, "type.coding", []).findIndex(coding =>
                coding.system == TYPE_OF_MEDICAL_CARD_SYSTEM)
            if (index >= 0) {
                this.medicalCardNumberIdentifier.type.coding.splice(index, 1)
            }
        } else {
            if (!this.typeOfMedicalCardCoding) {
                this.createTypeOfMedicalCardCoding();
            }
            this.typeOfMedicalCardCoding.display = concept.label;
            this.typeOfMedicalCardCoding.code = concept.value;
        }
    }

    createTypeOfMedicalCardCoding = () => {
        if ( ! Array.isArray(this.medicalCardNumberIdentifier.type.coding) ) {
            this.medicalCardNumberIdentifier.type.coding = []
        }
        this.medicalCardNumberIdentifier.type.coding.push({
            system: TYPE_OF_MEDICAL_CARD_SYSTEM,
            version: '1.1',
            code: '',
            display: ""
        })
    }


}
