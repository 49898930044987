/**
 * пока пользователь не залогинен, отправляем запросы с basic-auth согласно конфигу
 * когда залогинен, по bearer
 */

import { AuthStoreActionsClass } from './AuthStoreActionsClass';
import { AuthStoreData } from './AuthStoreDataClass';

const AuthStore = new AuthStoreActionsClass(AuthStoreData);

export { AuthStore };