import _get from "lodash/get";
import urljoin from "url-join";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';

/**
 * версия приложения и конфиг
 */
class CustomVersionClass {

    /**
     * Общий конфиг с бекенда
     */
    config = null;

    /**
     * получаем версию с сервера (загрузка после конфига в index.js)
     */
    load = () => {
        return basicRequestGet("/envs", { headers: { "Authorization": "" }}).then(response => {
            this.config = response.data;
            console.log('this.config: ', this.config);

            // to avoid circular dependency we cannot access this from HeaderStore so we call its method instead
            HeaderStore.setUiDefaultLanguageSetting(this.uiDefaultLanguage);
        }, error => {
            console.error("При получении настроек приложения произошла ошибка", error);
        });
    };

    /**
     * Получение количества видимых слотов в консилиуме
     */
    get appointmentListVisibleSlotsCount() {
        return _get(this.config, "APPOINTMENT_LIST_VISIBLE_SLOTS_COUNT");
    }

    /**
     * Получение DEPERSONALIZED_DATA
     */
    get depersonalizedData() {
        return _get(this.config, "DEPERSONALIZED_DATA");
    }

    /**
     * Получение флага доступности кнопки "Подтвердить все МН"
     */
    get canShowBtnApproveAll() {
        return _get(this.config, "MEDICATION_MANAGEMENT_ON_APPROVE_ALL", "").toLowerCase() === "true";
    }

    /**
     * Получение STATUS_MODEL_PROTOCOL_SKIP_READY
     */
    get statusModelProtocolSkipReady() {
        return _get(this.config, "STATUS_MODEL_PROTOCOL_SKIP_READY", "").toLowerCase() === "true";
    }

     /**
     * Получение SPECIALIZED_NOSOLOGICAL_APPOINTMENTS_ENABLED
     */
    get specializedNosologicalAppointmentsEnabled() {
        return _get(this.config, "SPECIALIZED_NOSOLOGICAL_APPOINTMENTS_ENABLED", "").toLowerCase() === "true";
    }

    /**
     * Включено ли отображение
     */
    get protocolAdditionalReferralsEnable() {
        return _get(this.config, "PROTOCOL_ADDITIONAL_REFERRALS_ENABLE", "").toLowerCase() === "true";
    }

    /**
     * Получение MEDICATION_PROGRESS_TRACKING_METHOD
     */
    get medicationProgressTrackingMethod() {
        return _get(this.config, "MEDICATION_PROGRESS_TRACKING_METHOD", "").toUpperCase();
    }

    /**
     * Получение CARE_COORDINATION_FRONTEND
     */
    get careCoordinationFrontend() {
        return _get(this.config, "CARE_COORDINATION_FRONTEND");
    }

    /**
     * создание/изменение консилиумов через ендпойнт
     */
    get createUpdateAppointmentWithBackend() {

        console.warn("setting CREATE_UPDATE_APPOINTMENT_WITH_BACKEND is supported at version 1.8 only")
        return false;

        // return _get(this.config, "CREATE_UPDATE_APPOINTMENT_WITH_BACKEND", "False").toLowerCase() === "true";
    }

    /**
     * максимальное количество заявок, которые можно одновременно создавать
     */
    get appointmentMassCreateLimit() {
        return parseInt(_get(this.config, "APPOINTMENT_MASS_CREATE_LIMIT", 0)) || 50;
    }

    /**
     * язык по умолчанию из конфига системы
     */
    get uiDefaultLanguage() {
        return _get(this.config, "UI_DEFAULT_LANGUAGE");
    }

    /**
     * Получение PROTOCOL_ELECTRONIC_SIGN_MODE
     */
    get protocolElectronicSignMode() {
        const defaultValue = "disabled";
        const currentSetting = _get(this.config, "PROTOCOL_ELECTRONIC_SIGN_MODE", defaultValue);
        const availableValues = Object.values(PROTOCOL_ELECTRONIC_SIGN_MODE);

        if (availableValues.includes(currentSetting)) {
            return currentSetting;
        }
        console.warn(
            `.env setting PROTOCOL_ELECTRONIC_SIGN_MODE must be one of ${availableValues.map(e => `"${e}"`).join(',')}` +
            `. Current value is "${currentSetting}"` +
            `. "${defaultValue}" will be used instead.`
        );
        return defaultValue;
    }

    /**
     * получение OCR_SERVICE_URL
     */
    get ocrServiceUrl() {
        return _get(this.config, "OCR_SERVICE_URL");
    }

    /**
     *
     */
    get EXTERNAL_ADDRESS_OK() {
        return _get(this.config, "EXTERNAL_ADDRESS_OK", '');
    }

    get medicalPath() {
        console.warn("MM should not exist here");
        return "";
    }
}

export const CustomVersion = new CustomVersionClass();

export const MEDICATION_PROGRESS_TRACKING_METHOD = {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL"
};

export const PROTOCOL_ELECTRONIC_SIGN_MODE = {
    DISABLED: "disabled",
    SINGLE: "single",
    MULTIPLE: "multiple"
};
