//генератор бордера, цвета текста и фона у контроллов
export const controlStyles = (props, {disabled, isError}) => `
    border: 1px solid ${setControlBorderColor(props.theme, isError, disabled)};
    color:  ${setControlTextColor(props.theme, disabled)};
    background-color: ${setControlBg(props.theme, disabled)};
    font-family: ${props.theme.OpenSansRegular};
    font-size: ${props.theme.mediumFontSize};
    border-radius: 4px;
`;

export const controlStylesHover = (props, disabled) => `
    border: 1px solid ${!disabled && props.theme.controlBorderColorHover};
    background-color: ${!disabled && props.theme.controlBgColorHover};
`;

export const controlStylesActive = (props, disabled) => `
    border: 1px solid ${!disabled && props.theme.controlBorderColorActive};
    background-color: ${!disabled && props.theme.whiteColor};
`;

const setControlBorderColor = (theme, isError, disabled) => {
    if(isError) return theme.controlBorderColorError;
    if(disabled) return theme.inputDisabledBorder;

    return theme.controlBorderColor;
};

const setControlBg = (theme, disabled) => {
    if(disabled) return theme.controlBgColorDisabled;

    return theme.controlBgColor;
};

const setControlTextColor = (theme, disabled) => {
    if(disabled) return theme.controlTextColorDisabled;

    return theme.controlTextColor;
};

//генератор стилей лейбла у контролла
export const labelControlStyles = (props, isError, isEmpty, labelFontSize) => `
    font-size: ${labelFontSize || props.theme.normalFontSize};
    line-height: 15px;
    font-family: ${props.theme.OpenSansRegular};
    color: ${props => props.theme.controlLabelColor};
    margin-bottom: 4px;
`;

const setLabelControlColor = (theme, isError, isEmpty) => {
    if(isEmpty) return theme.controlLabelColor;
    if(isError) return theme.controlLabelColorError;

    return theme.controlLabelColor;
};

export const placeholderStyles = (props) => `
    font-size: ${props.theme.mediumFontSize};
    line-height: 15px;
    font-family: ${props.theme.OpenSansRegular};
    color: ${props.theme.controlPlaceholderColor};
`;

export const iconButtonStyles = (props, {width, height}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width || '32px'};
    height: ${height || '32px'};
    border-radius: 4px;
    cursor: ${!props.disabled && 'pointer'};
    //transition: ${props.theme.mainTransition};
`;

export const iconButtonHoverStyles = (props) => `
    background-color: ${props.theme.iconButtonHoverBG} !important;
`;


export const iconButtonActiveStyles = (props) => `
    background-color: ${props.theme.iconButtonActiveBG};
`;

export const headerBlockStyles = (props) => `
     width: 100%;
     display: flex;
     background-color: ${props.theme.headerBlockBG};
`;

export const chipsStyles = (props, isViewMode)=> `
    ${ !isViewMode && `background-color: ${props.theme.chipBG}` };
    ${ isViewMode && "border: 1px solid #D3D4D8" };
    color: ${!isViewMode ? props.theme.darkMainTextColor : "#9C9C9C"};
    border-radius: 4px;
    font-family: ${props.theme.OpenSansRegular};
    font-size: ${props.theme.mediumFontSize};
`;

export const chipsStylesActiveHover = (props) => `
    background-color: ${props.theme.chipBGHoverActive};
`;

export const participantSlotStyles = (props, {width = '48px', height = '40px'}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${width};
    height: ${height};
    min-width: ${width};
    background-color: ${props.theme.participantBlockBG};
    border-radius: 2px;
    color: ${props.theme.whiteColor};
    font-family: ${props.theme.OpenSansBold};
    font-size: 20px;
`;

export const participantRoleStyles = (props, {width = '17px', height = '17px', bg = props.theme.participantRoleDefaultBG}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: ${width};
    height: ${height};
    border: 1px solid ${props.theme.whiteColor};
    border-radius: 4px;
    color: ${props.theme.whiteColor};
    font-family: ${props.theme.OpenSansBold};
    font-size: ${props.theme.normalFontSize};
    background-color: ${bg};
`;
