import _get from "lodash/get";
import { observable } from "mobx";

/**
 * СНИЛС пациента
 */
export class PatientSnils {

    /**
     * система кодирования идентификатора СНИЛС пациента
     */
    static IDENTIFIER_SYSTEM = "urn:oid:1.2.643.100.3";

    /**
     * ссылка на ресурс пациента
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на идентификатор
     */
    get identifier() {
        return _get(this.resource, "identifier", []).find(identifier => {
            return identifier.system == PatientSnils.IDENTIFIER_SYSTEM;
        })
    }

    /**
     * номер СНИЛС
     */
    get number() {
        return _get(this.identifier, "value", "-");
    }

}
