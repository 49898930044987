import _get from "lodash/get";
import _pick from "lodash/pick";
import { observable } from "mobx";

/**
 * описание идентификатора полиса ОМС
 * use official для действующих основных документов
 * https://nsi.rosminzdrav.ru/#!/refbook/1.2.643.5.1.13.13.11.1035/version/1.2
 */
const IDENTIFIER = {
    USE: "official",
    TYPE_TEXT: "Полис ОМС",
    TYPE_CODING_CODE: "MANDPOL",
    TYPE_CODING_SYSTEM: "http://terminology.hl7.org/CodeSystem/v3-ActCode"
}

/**
 * типы полисов ОМС
 * https://jira.mmdx.ru/browse/FCSPOK-915
 * код - идентификатор из НСИ (маппинг)
 */
const TYPES = [
    {
        code: "1",
        name: "Полис ОМС старого образца",
        mask: "XXXXXX_XXXXXXXXXX",
        system: "urn:oid-extension:1.2.643.5.1.13.13.11.1035:1.3:1",
    }, {
        code: "2",
        name: "Полис ОМС единого образца, бессрочный",
        mask: "XXXX_XXXX_XXXX_XXXX",
        system: "urn:oid-extension:1.2.643.5.1.13.13.11.1035:1.3:2",
    }, {
        code: "3",
        name: "Полис ОМС единого образца, со сроком действия",
        mask: "XXXX_XXXX_XXXX_XXXX",
        system: "urn:oid-extension:1.2.643.5.1.13.13.11.1035:1.3:3",
    },{
        code: "4",
        name: "Временное свидетельство",
        mask: "XXXXXXXXX",
        system: "urn:oid-extension:1.2.643.5.1.13.13.11.1035:1.3:4",
    },
];

/**
 * условие, что идентификатор в ресурсе отвечает за полис ОМС
 */
const IS_OMS_POLICY_IDENTIFIER = identifier => {
    return _get(identifier, "use") == IDENTIFIER.USE
        && _get(identifier, "type.coding", []).find(coding => {
            return _get(coding, "code") == IDENTIFIER.TYPE_CODING_CODE
                && _get(coding, "system") == IDENTIFIER.TYPE_CODING_SYSTEM;
        });
}

/**
 * поиск типа полиса по системе
 */
const FIND_TYPE = system => TYPES.find(type => type.system == system);

/**
 * сборка идентификатора полиса ОМС на основе системы и значения
 */
const MAKE_IDENTIFIER = (system = "", value = "") => ({
    use: IDENTIFIER.USE,
    system: system,
    value: value.replace(/\D/g, ""),
    type: {
        text: IDENTIFIER.TYPE_TEXT,
        coding: [{
            code: IDENTIFIER.TYPE_CODING_CODE,
            system: IDENTIFIER.TYPE_CODING_SYSTEM,
        }]
    }
});

/**
 * https://www.hl7.org/fhir/datatypes.html#Identifier
 * класс для управления полисом ОМС пациента или сотрудника
 * хранится как идентификатор в ресурсах Patient и Practitioner
 */
export class OmsPolicy {

    /**
     * ссылка на ресурс
     * Patient или Practitioner
     */
    @observable resource = null;

    /**
     * resource - ссылка на ресурс
     * Patient или Practitioner
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * поиск идентификатора полиса ОМС в массиве идентификаторов
     */
    get identifier() {
        return _get(this.resource, "identifier", []).find(IS_OMS_POLICY_IDENTIFIER);
    }

    /**
     * поиск индекса идентификатора полиса ОМС в массиве идентификаторов
     */
    get identifierIndex() {
        return _get(this.resource, "identifier", []).findIndex(IS_OMS_POLICY_IDENTIFIER);
    }

    /**
     * система полиса ОМС (например, urn:oid:1.2.643.5.1.13.13.11.1035:1.2:2 - единого образца)
     */
    get system() {
        return _get(this.identifier, "system", "");
    }

    /**
     * номер полиса ОМС (например, 123456789 - временное свидетельство)
     */
    get number() {
        return _get(this.identifier, "value", "-");
    }

    /**
     * количество цифр в номере полиса ОМС
     */
    get numberLength() {
        return this.number && _get(this.number.match(/\d/g), 'length', 0);
    }

    /**
     * маска для номера полиса ОМС (например, XXXX_XXXX_XXXX_XXXX)
     */
    get mask() {
        return _get(FIND_TYPE(this.system), "mask", "");
    }

    /**
     * количество цифр в маске полиса ОМС
     */
    get maskLength() {
        return this.mask && this.mask.match(/X/g).length;
    }

    /**
     * алиас для системы полиса ОМС
     */
    get type() {
        return this.system;
    }

    /**
     * тип/название полиса (например, временное свидетельство)
     */
    get typeName() {
        return _get(FIND_TYPE(this.system), "name", "");
    }

    /**
     * тип полиса (для ReactSelect)
     */
    get typeValue() {
        return (this.system && this.typeName) ? {
            value: this.system,
            label: this.typeName
        } : "";
    }

    /**
     * типы полисов (для ReactSelect)
     */
    get typeOptions() {
        return TYPES.map(type => ({
            value: type.system,
            label: type.name
        }));
    }

    /**
     * при изменении типа полиса ОМС
     */
    onChangeType = (typeOption) => {
        const type = FIND_TYPE(_get(typeOption, "value"));
        return type ? this.make(type.system) : this.reset();
    }

    /**
     * изменение типа полиса ОМС (НСИ)
     * https://jira.mmdx.ru/browse/FCSPOK-915
     */
    onChangeNsiType = (concepts = []) => {
        const type = FIND_TYPE(this.system);
        if ( type ) {
            const concept = concepts.find(concept => concept.code == type.code);
            if ( concept ) {
                let coding = this.identifier.type.coding.filter(coding => {
                    return coding.system != concept.system;
                });
                coding.push(_pick(concept, ["code", "system", "display", "version"]));
                this.resource.identifier[this.identifierIndex]["type"]["coding"] = coding;
            }
        }
    }

    /**
     * при изменении номера полиса ОМС
     */
    onChangeNumber = (value = "") => {
        const type = FIND_TYPE(this.system);
        return type ? this.make(type.system, value) : this.reset();
    }

    /**
     * создание/изменение идентификатора с полисом ОМС
     */
    make = (system = "", value = "") => {
        const identifier = MAKE_IDENTIFIER(system, value);
        if(this.identifierIndex >= 0) {
            return this.updateIdentifier(identifier);
        } else {
            return this.createIdentifier(identifier);
        }
    }

    /**
     * создание идентификатора полиса ОМС
     */
    createIdentifier = (identifier) => {
        if( ! Array.isArray(this.resource.identifier) ) {
            this.resource.identifier = [];
        }
        this.resource.identifier.push(identifier);
    }

    /**
     * изменение идентификатора полиса ОМС
     */
    updateIdentifier = (identifier) => {
        this.resource.identifier[this.identifierIndex] = identifier;
    }

    /**
     * сброс/удаление идентификатора полиса ОМС
     */
    reset = () => {
        if(this.identifierIndex >= 0) {
            this.resource.identifier.splice(this.identifierIndex, 1);
        }
    }

}
