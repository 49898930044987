/**
 * http://hl7.org/fhir/narrative-status
 */

import { Element } from './Element';
import { NARRATIVE_STATUS } from '../CodeSystem/NarrativeStatus';



export class Narrative extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Narrative';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._data.status = data.code || NARRATIVE_STATUS.EMPTY;
        this._data.div = data.div || '';
    }

    /**
     *
     */
    _validate(value) {

        super._validate();

        if ( ! this._data.status) {
            throw 'Narrative.status is required';
        }

        if ( ! this._validateFieldCode(this._data.status, NARRATIVE_STATUS)) {
            throw 'Narrative.status is incorrect';
        }

        if ( ! this._data.div) {
            throw 'Narrative.div should not be empty';
        }
    }

    /**
     *
     */
    get status() {
        return this._data.status
    }

    /**
     *
     */
    set status(newValue) {
        this._data.status = newValue;
    }

    /**
     *
     */
    get div() {
        return this._data.div
    }

    /**
     *
     */
    set div(newValue) {
        this._data.div = newValue;
    }

}
