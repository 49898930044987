/**
 * https://miramedix.atlassian.net/browse/MIRA-804
 * система кодирования типов протокола консилиума
 */
export const TB_PROTOCOL_TYPE_SYSTEM = "urn:mmdx:internal:tb:protocoltype";

/**
 * кодирование ServiceRequest.performerType "Куда направлен пациент*
 */
export const ORGANIZATION_SUBORDINATION_TYPE_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/organizationSubordination";

/**
 * система кодирования секций диагноза
 */
export const DIAGNOSIS_CLINICAL_SECTION_CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.11.1077";

/**
 * система кодирования идентификатора СНИЛС пациента и сотрудника
 * https://miramedix.atlassian.net/browse/MIRA-1348
 */
export const SNILS_IDENTIFIER_SYSTEM = "urn:oid:1.2.643.100.3";

/**
 * старая система кодирования идентификатора СНИЛС пациента и сотрудника
 * https://miramedix.atlassian.net/browse/MIRA-1348
 */
export const OLD_SNILS_IDENTIFIER_SYSTEM = "urn:1.2.643.100.3";

/**
 * Кодирование форм проведения консилиума врачей (врачебной комиссии)
 * https://jira.mmdx.ru/browse/FCSPOK-31
 */
export const TB_PROTOCOL_FORMAT_SYSTEM = "http://miramedix.ru/fhir/cs/fcsp-cs-nsi-1-2-643-5-1-13-13-11-1508";
