import { AbstractIcd10ConceptValueSet } from "./AbstractIcd10ConceptValueSet";

/**
 * справочник кодов МКБ-10 (соответствующих онкологическим заболеваниям)
 */
export class ConditionMkb10OncologyCodesValueSet extends AbstractIcd10ConceptValueSet {

    /**
     * инициализация
     */
    constructor() {
        super({
            onlyOncology: true // флаг поиска только онкологических заболеваний
        });
    }

}
