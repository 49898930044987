import {tb_dictionary} from "./tb";
import {mm_dictionary} from "./mm";
import {toast_dictionary} from "./toast";
import {dv_dictionary} from "./dv";

export const lang_ru = {
    language: "ru",
    messages: {
        ...tb_dictionary,
        ...mm_dictionary,
        ...toast_dictionary,
        ...dv_dictionary
    }
};
