/**
 * https://www.hl7.org/fhir/datatypes.html#Period
 */

import { Element } from './Element';
import moment from "moment";



export class Period extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Period';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
        ]);

        if (data.start) {
            this._data.start = data.start;
        }

        if (data.end) {
            this._data.end = data.end;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check all
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get start() {
        return moment(this._data.start);
    }

    /**
     *
     */
    set start(newValue) {
        this._setDateTimeField('start', newValue);
    }

    /**
     *
     */
    get end() {
        return moment(this._data.end);
    }

    /**
     *
     */
    set end(newValue) {
        this._setDateTimeField('end', newValue);
    }

}
