import _get from "lodash/get";

/**
 * Код МКБ-10
 */
export class ConditionMkb10 {

    /**
     * система кодирования (Condition.code.docing.system)
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.11.1005";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.conditionResource, "code.coding", []).find(codableConcept => {
            return _get(codableConcept, "system") == ConditionMkb10.CODING_SYSTEM;
        });
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * код группы заболеваний
     */
    get groupCode() {
        return this.code.slice(0, 3);
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * код + название
     */
    get title() {
        return `${this.code}: ${this.display}`;
    }

    /**
     * значение для ReactSelect
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: `${this.code}: ${this.display}`
        } : "";
    }

    /**
     * при изменении кода
     */
    onChange = ({concept}) => {
        if ( ! this.conditionResource.code ) {
            this.conditionResource.code = {};
        }
        this.conditionResource.code.text = concept.display;
        if ( ! Array.isArray(this.conditionResource.code.coding) ) {
            this.conditionResource.code.coding = [];
        }
        const codeableConcept = {
            "code": concept.code,
            "system": ConditionMkb10.CODING_SYSTEM,
            "display": concept.display
        }
        const index = this.conditionResource.code.coding.findIndex(coding => {
            return coding.system == ConditionMkb10.CODING_SYSTEM;
        });
        if ( index >= 0 ) {
            this.conditionResource.code.coding[index] = codeableConcept;
        } else {
            this.conditionResource.code.coding.push(codeableConcept);
        }
    }

    /**
     * сброс кода
     */
    reset = () => {
        if ( this.conditionResource.code ) {
            delete(this.conditionResource.code);
        }
    }

    /**
     * это комплексное заболевание со вложнными диагнозами (C97)?
     *
     */
    get isComplex() {
        return this.code == "C97";
    }

    /**
     * это код онкологического заболевания?
     */
    get isOncology() {
        return !! (
            (this.code >= "C00" && this.code <= "D48.9")
        );
    }

    /**
     * это код конкурирующего заболевания?
     */
    get isConcurrent() {
        return !! (
            (this.code >= "A00" && this.code <= "B99.9")
            ||
            (this.code >= "D50" && this.code <= "Z99.9")
        );
    }

    /**
     * это код сопутствующего заболевания?
     */
    get isComorbidity() {
        return !! (
            (this.code >= "A00" && this.code <= "B99.9")
            ||
            (this.code >= "D50" && this.code <= "Z99.9")
        );
    }
    /**
     * это код осложнения заболевания?
     */
    get isComplication() {
        return !! (
            (this.code >= "A00" && this.code <= "B99.9")
            ||
            (this.code >= "D50" && this.code <= "Z99.9")
        );
    }

    /**
     * это код вложенного в C97 заболевания?
     */
    get isOncologyRelated() {
        return !! (
            (this.code >= "C00" && this.code <= "C96.9")
            ||
            (this.code >= "D00" && this.code <= "D09.9")
        );
    }

    /**
     * это код злокачественного заболевания?
     */
    get isOncologyMalignant() {
        return !! (
            (this.code >= "C00" && this.code <= "C96.9")
            ||
            (this.code >= "D00" && this.code <= "D09.9")
        );
    }

}
