import _get from "lodash/get";
import _omit from "lodash/omit";
import { observable } from "mobx";
import { ConditionMkb10 } from "./ConditionMkb10";
import { ConditionStage } from "./ConditionStage";
import { ConditionPatient } from "./ConditionPatient";
import { ConditionComment } from "./ConditionComment";
import { ConditionEvidence } from "./ConditionEvidence";
import { ConditionTnmGroup } from "./ConditionTnmGroup";
import { ConditionAsserter } from "./ConditionAsserter";
import { ConditionHistology } from "./ConditionHistology";
import { ConditionSourceType } from "./ConditionSourceType";
import { ConditionDescription } from "./ConditionDescription";
import { ConditionRecordedDate } from "./ConditionRecordedDate";
import { ConditionClinicalStatus } from "./ConditionClinicalStatus";
import { ConditionSeverityStatus } from "./ConditionSeverityStatus";
import { ConditionAffectedBodySite } from "./ConditionAffectedBodySite";
import { ConditionPrimaryTumorFlag } from "./ConditionPrimaryTumorFlag";
import { ConditionMultipleTumorType } from "./ConditionMultipleTumorType";
import { ConditionRelatedConditions } from "./ConditionRelatedConditions";
import { ConditionVerificationStatus } from "./ConditionVerificationStatus";
import { ConditionComplicationConditions } from "./ConditionComplicationConditions";
import { CreatedFromExtension } from "MODEL_STORE/MIRAMEDIX/Extensions/CreatedFromExtension";

/**
 * информация о заболевании пациента
 */
export class PatientCondition {

    /**
     * ресурс заболевания
     */
    @observable resource = null;

    /**
     * код МКБ-10
     */
    @observable mkb10 = null;

    /**
     * комментарий от врача
     */
    @observable comment = null;

    /**
     * ссылка на пациента
     */
    @observable patient = null;

    /**
     * врач, установивший заболевание
     */
    @observable asserter = null;

    /**
     * метод подтверждения диагноза
     */
    @observable evidence = null;

    /**
     * группа TNM
     */
    @observable tnmGroup = null;

    /**
     * гистологический тип и код МКБ-О (морфологический тип опухоли)
     */
    @observable histology = null;

    /**
     * расширение с информацией о том,
     * на основе какого ресурса создан текущий
     */
    @observable createdFrom = null;

    /**
     * в рамках какого документа создается заболевание
     */
    @observable sourceType = null;

    /**
     * описание нозологической формы
     */
    @observable description = null;

    /**
     * дата установки заболевания
     */
    @observable recordedDate = null;

    /**
     * статус заболевания (установлено)
     */
    @observable clinicalStatus = null;

    /**
     * характер заболевания
     */
    @observable severityStatus = null;

    /**
     * состояние подтверждения
     */
    @observable verificationStatus = null;

    /**
     * сторона поражения
     */
    @observable affectedBodySite = null;

    /**
     * признак первичной опухоли
     */
    @observable primaryTumorFlag = null;

    /*
     * тип первично-множественной опухоли
     */
    @observable multipleTumorType = null;

    /*
     * ссылки на вложенные в с97 заболевания
     */
    @observable relatedConditions = null;

    /*
     * осложнения
     */
    @observable complicationConditions = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.initialize(resource);
    }

    /**
     * обновление
     */
    update = (resource) => {
        this.initialize(JSON.parse(JSON.stringify(resource)));
    }

    /**
     * инициализация
     */
    initialize = (resource) => {
        this.resource = resource;
        this.mkb10 = new ConditionMkb10(this.resource);
        this.stage = new ConditionStage(this.resource);
        this.comment = new ConditionComment(this.resource);
        this.patient = new ConditionPatient(this.resource);
        this.asserter = new ConditionAsserter(this.resource);
        this.evidence = new ConditionEvidence(this.resource);
        this.tnmGroup = new ConditionTnmGroup(this.resource);
        this.histology = new ConditionHistology(this.resource);
        this.sourceType = new ConditionSourceType(this.resource);
        this.createdFrom = new CreatedFromExtension(this.resource);
        this.description = new ConditionDescription(this.resource);
        this.recordedDate = new ConditionRecordedDate(this.resource);
        this.clinicalStatus = new ConditionClinicalStatus(this.resource);
        this.severityStatus = new ConditionSeverityStatus(this.resource);
        this.affectedBodySite = new ConditionAffectedBodySite(this.resource);
        this.primaryTumorFlag = new ConditionPrimaryTumorFlag(this.resource);
        this.multipleTumorType = new ConditionMultipleTumorType(this.resource);
        this.relatedConditions = new ConditionRelatedConditions(this.resource);
        this.verificationStatus = new ConditionVerificationStatus(this.resource);
        this.complicationConditions = new ConditionComplicationConditions(this.resource);
    }

    /**
     * данные без мета информации
     */
    get data() {
        return _omit(this.resource, ["meta"]);
    }

    /**
     * идентификатор
     */
    get id() {
        return _get(this.resource, "id");
    }

    set id(id) {
        this.resource.id = id;
    }

    /**
     * Ресурсы contained
     */
    get contained() {
        return _get(this.resource, "contained", []);
    }

    set contained(contained) {
        return this.resource.contained = contained
    }

    /**
     * Расширения
     */
    get extension() {
        return _get(this.resource, "extension", []);
    }

    set extension(extension) {
        return this.resource.extension = extension
    }

    /**
     * Категория
     */
    get category() {
        return _get(this.resource, "category", []);
    }

    set category(category) {
        return this.resource.category = category
    }

    /**
     * тип ресурса
     */
    get resourceType() {
        return _get(this.resource, "resourceType");
    }

    /**
     * версия ресурса
     */
    get versionId() {
        return _get(this.resource, "meta.versionId");
    }

    /**
     * клонирование
     */
    clone = () => {
        return new PatientCondition(JSON.parse(JSON.stringify(this.resource)));
    }

    /**
     * можно ли указать комментарий?
     */
    get canHaveComment() {
        return ! this.mkb10.isComplex;
    }

    /**
     * можно ли указать врача?
     */
    get canHaveAsserter() {
        return ! this.mkb10.isComplex;
    }

    /**
     * можно ли указать клиническое описание диагноза?
     */
    get canHaveDescription() {
        return ! this.mkb10.isComplex;
    }

    /**
     * можно ли указать метод подтверждения диагноза?
     */
    get canHaveEvidence() {
        return !! (
            this.mkb10.isOncologyMalignant
            &&
            this.verificationStatus.isConfirmed
        )
    }

    /**
     * можно ли указать статус заболевания (установлено)
     */
    get canHaveClinicalStatus() {
        return ! this.mkb10.isComplex;
    }

    /**
     * можно ли указать статус диагноза (предварительный/подтвержденный)
     */
    get canHaveVerificationStatus() {
        return ! this.mkb10.isComplex;
    }

    /**
     * можно ли указать дату постановки диагноза?
     */
    get canHaveRecordedDate() {
        return !! (
            this.mkb10.isOncologyMalignant
            &&
            this.verificationStatus.isConfirmed
        )
    }

    /**
     * может ли заболевание иметь гистологический тип?
     */
    get canHaveHistology() {
        return !! (
            this.mkb10.isOncology
            &&
            this.verificationStatus.isConfirmed
        )
    }

    /**
     * может ли заболевание иметь группу ЗНО?
     */
    get canHaveTnmGroup() {
        return !! (
            this.mkb10.isOncology
            &&
            this.verificationStatus.isConfirmed
            &&
            this.histology.code
        )
    }

    /**
     * можно ли указать сторону поражения?
     */
    get canHaveAffectedBodySite() {
        return !! (
            this.mkb10.isOncologyMalignant
            &&
            this.verificationStatus.isConfirmed
        )
    }

    /**
     * можно ли указать тип первично-множественной опухоли?
     */
    get canHaveMultipleTumorType() {
        return this.mkb10.isComplex;
    }

    /**
     * можно ли указать новообразования (только для C97)?
     */
    get canHaveRelatedConditions() {
        return this.mkb10.isComplex;
    }

}
