/**
 * тут мы будем складывать данные о всех ValueSet
 * для загрузки данных используем ensure('valueset.id');
 * для доступа .data[<valueset.id>]
 *
 */

import { observable, computed, autorun, action } from 'mobx';
import { toJS } from 'mobx';
import _get from 'lodash.get';

import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';

const VALUESET_COUNT = 999; // максимальное кол-во загружаемых концептов (по умолчанию aidbox отдает 100)

class ValuesetStoreClass {

    /**
     *
     */
    @observable
    data = {};

    /**
     *
     */
    @action
    setData (systemId, data) {
        this.data[systemId] = data;
    }

    /**
     * просто весь набор данных одного кода
     */
    concept(systemId) {

        return this.data[systemId];
    }

    /**
     * обновление части стора по ссылке GET /Concept?.system=<systemId>
     */
    update = (systemId) => {

        this.setData(systemId, [])
        basicRequestGet(`/api/mm/concepts?valueset=${systemId}&_count=${VALUESET_COUNT}`).then( result => {
            this.setData(systemId, result.data.data)
        });
    }

    /**
     * проверка наличия данных в сторе и обновление при необходимости
     */
    ensure = (systemId) => {
        if (Array.isArray(this.data[systemId])) {
            return;
        }

        this.update(systemId);
    }

    /**
     * поиск элемента
     * на вход подаем:
     *
     * код справочника (например, contact-point-system),
     * название поля, в котором будем искать,
     * и что ищем
     *
     */
    find(systemId, path, testValue) {

        let data = toJS(this.data[systemId]);

        if ( ! data) {
            return null;
        }

        for (let i = 0; i < data.length; i++) {
            if (_get(data[i].resource, path) == testValue) {
                return data[i].resource;
            }
        }
        return null;
    }
}

const ValuesetStore = new ValuesetStoreClass();

export { ValuesetStore };
