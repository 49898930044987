/**
 * https://www.hl7.org/implement/standards/fhir/datatypes.html#CodeableConcept
 */

import { Element } from './Element';
import { Coding } from './Coding';

export class CodeableConcept extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'CodeableConcept';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        if (data.coding) {
            this._data.coding = data.coding.map( elem => (new Coding(elem))._data );
        }

        if (data.text) {
            this._data.text = data.text;
        }
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check ALL
    }

    /**
     *
     */
    get text() {
        return this._data.text;
    }

    /**
     *
     */
    set text(newValue) {
        this._data.text = newValue;
    }

    /**
     *
     */
    get coding() {
        return this._data.coding ? this._data.coding.map(elem => new Coding(elem)) : [];
    }

}
