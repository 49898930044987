import _get from "lodash/get";
import { observable } from "mobx";

const MEDICAL_RECORD_NUMBER_SYSTEM = "urn:oid:1.2.643.5.1.13.13.12.2.66.6795.100.1.1.16"

/**
 * инвалидность пациента
 */
export class MedicalRecordNumber {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * id идентификатора случая в пациенте
     */
    @observable medicalCardNumberIdentifierId = null;

    /**
     * инициализация
     */
    constructor(medicalCardNumberIdentifierId, patient) {
       this.resource = patient;
       this.medicalCardNumberIdentifierId = medicalCardNumberIdentifierId;
    }

    /**
     * Получение идентификатора номера карты
     */
    get medicalRecordNumberIdentifier() {
        return _get(this.resource, "identifier", []).find(i =>
            i.system === MEDICAL_RECORD_NUMBER_SYSTEM && (this.medicalCardNumberIdentifierId ?
              i.id == this.medicalCardNumberIdentifierId : !i.id));
    }

    get id() {
        return _get(this.medicalRecordNumberIdentifier, "id");
    }

    set id(id) {
        this.medicalRecordNumberIdentifier.id = id;
    }

    /**
     * значение
     */
    get value() {
        return this.medicalRecordNumberIdentifier ? this.medicalRecordNumberIdentifier.value : ""
    }

    get period() {
        return this.medicalRecordNumberIdentifier.period;
    }

    set period(value) {
        this.medicalRecordNumberIdentifier.period = {};
        if (value.start) this.medicalRecordNumberIdentifier.period.start = value.start;
        if (value.end) this.medicalRecordNumberIdentifier.period.end = value.end;
    }

    get assigner() {
        return _get(this.medicalRecordNumberIdentifier, "assigner", {}).id
    }

    set assigner(id) {
        this.medicalRecordNumberIdentifier.assigner = {
            id: id,
            resourceType: "Organization"
        }
    }

    onChange = (value) => {
        this.medicalRecordNumberIdentifier.value = value;
    }
}
