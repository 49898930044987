/**
 * https://www.hl7.org/implement/standards/fhir/datatypes.html#Coding
 */

import { Element } from './Element';



export class Coding extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Coding';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._directCopyFields(data, ['system', 'version', 'code', 'display', 'userSelected']);
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check ALL
    }

    /**
     *
     */
    get system() {
        return this._data.system;
    }

    /**
     *
     */
    set system(newValue) {
        this._data.system = newValue;
    }

    /**
     *
     */
    get version() {
        return this._data.version;
    }

    /**
     *
     */
    set version(newValue) {
        this._data.version = newValue;
    }

    /**
     *
     */
    get code() {
        return this._data.code;
    }

    /**
     *
     */
    set code(newValue) {
        this._data.code = newValue;
    }

    /**
     *
     */
    get display() {
        return this._data.display;
    }

    /**
     *
     */
    set display(newValue) {
        this._data.display = newValue;
    }

    /**
     *
     */
    get userSelected() {
        return this._data.userSelected;
    }

    /**
     *
     */
    set userSelected(newValue) {
        this._data.userSelected = !! newValue;
    }


}
