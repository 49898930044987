/**
 * https://www.hl7.org/implement/standards/fhir/domainresource.html
 */

import { Resource } from './Resource';
import { Narrative } from '../Elements/Narrative';
import { Extension } from '../Elements/Extension';
import { toJS } from 'mobx';
import _cloneDeep from 'lodash/cloneDeep';


export class DomainResource extends Resource {

    /**
     *  Contains _data for resetting
     */
    snapshot;

    /**
     *
     */
    _setClassName() {
        this._className = 'DomainResource';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'modifierExtension',
        ]);

        this.snapshot = {};

        if (data.hasOwnProperty('extension') && data.extension) {
            this._data.extension = data.extension.map(i => new Extension(i)._data);
        }
        if (data.hasOwnProperty('text')) {
            this._data.text = new Narrative(data.text)._data;
        }

        // TODO + Rule: If the resource is contained in another resource, it SHALL NOT contain nested Resources
        // TODO + Rule: If the resource is contained in another resource, it SHALL be referred to from elsewhere in the resource or SHALL refer to the containing resource
        // TODO + Rule: If a resource is contained in another resource, it SHALL NOT have a meta.versionId or a meta.lastUpdated
        // TODO + Rule: If a resource is contained in another resource, it SHALL NOT have a security label
        // TODO + Guideline: A resource should have narrative for robust management
    }

    /**
     *
     */
    _validate() {

        super._validate();

        (new Narrative(this._data.text))._validate();
    }

    /**
     *
     */
    get text() {

        return new Narrative(this._data.text, obj => this._data.text = obj._cloneData() );
    }

    /**
     *
     */
    set text(newNarrative) {
        this._data.text = newNarrative._data;
    }

    takeSnapshot = () => {
        this.snapshot = _cloneDeep(this._data);
    }

    reset = () => {
        this.snapshot.id && (this._data = _cloneDeep(this.snapshot));
    }

    /**
     *
     */
    get extension() {
        return this._data.extension;
    }

    /**
     *
     */
    set extension(newValue) {
        this._data.extension = newValue._data;
    }


}
