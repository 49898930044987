export const tb_dictionary = {
    SS001001: "Home",
    SS001002: "Select patient",
    SS001003: "Home",
    SS001004: "Tumor Boards",
    SS001005: "Tumor Board Team",
    SS001006: "Patient Cases",
    SS001007: "Tumor Board Reports",
    SS001008: "Treatment plans",
    SS001009: "Treatment Templates",
    SS001010: "Employees",
    SS001011: "Organizations",
    SS001012: "Role Management",
    SS001013: "Documents",
    SS001014: "Patient Cases",
    SS001015: "Patient’s full name",
    SS001016: "Tumor Board Date",
    SS001017: "Organization",
    SS001018: "Status",
    SS001019: "Tumor board calendar",
    SS001020: "The presence of the patient is mandatory",
    SS001021: "Tumor board in the past",
    SS001022: "Created",
    SS001023: "ChairPerson",
    SS001024: "Secretary",
    SS001025: "Global search (patients, patient cases, doctors, etc.)",
    SS001026: "You don't have any Patient Cases for the current date yet",
    SS001027: "Create Patient Case",
    SS001028: "No Tumor Boards are scheduled for this date",
    SS001029: "Show notifications",
    //значение приходит с бекенда, пока не используется
    SS001030: "Medical director",
    SS001031: "Settings",
    SS001032: "Sign out",
    SS001033: "Completed",
    SS001034: "Returned for revision",
    SS001035: "On approval",
    SS001036: "Ready for review",
    //Не используется, заменена на PP
    SS001037: "{days} day",
    SS002001: "Select patient",
    SS002002: "Patient's full name",
    SS002003: "Date of Birth",
    SS002004: "Coverage Document",
    SS002005: "SSN",
    SS002006: "Patient's full name",
    SS002007: "Date of Birth",
    SS002008: "Gender",
    SS002009: "Coverage Document",
    SS002010: "SSN",
    SS002011: "",
    SS002012: "Previously viewed patients",
    SS002013: "Full name",
    SS002014: "Date of Birth",
    SS002015: "Gender:",
    SS002016: "Coverage Document",
    SS002017: "SSN",
    SS002018: "Reset Filters",
    SS002050: "Enter the values to search for the patient and click \"Search\"",
    SS002051: "Search",
    SS003001: "Select patient",
    SS003002: "Patient information",
    SS003003: "y.o.",
    SS003004: "Female",
    SS003005: "Male",
    SS003006: "Date of Birth:",
    SS003007: "SSN:",
    SS003008: "Sending hospital:",
    SS003009: "Phone Number:",
    SS003010: "E-mail:",
    SS003011: "Disability:",
    SS003012: "Coverage Document:",
    SS003013: "Overview",
    SS003014: "Medical history and signaling information",
    SS003015: "Checkups",
    SS003016: "Health Diary",
    SS003017: "Files and scans",
    SS003018: "Diseases",
    SS003019: "Add a disease",
    SS003020: "All diseases",
    SS003021: "ICD-10",
    SS003022: "Nosological form description/decoding the ICD-10 code",
    SS003023: "Date asserted",
    SS003024: "Doctor",
    SS003025: "Delete",
    SS003026: "Tumor Boards",
    SS003027: "Tumor boards",
    SS003028: "ICD-10",
    SS003029: "Event location",
    SS003030: "Event date",
    SS003031: "Open patient case",
    SS003032: "Treatment plans",
    SS003033: "Add treatment plan",
    SS003034: "Treatment plans",
    SS003035: "Patient doesn't  have any Treatment Plans",
    SS003036: "Open report",
    SS003039: "Edit patient data",
    SS003067: "Disease information",
    SS003068: "Disease information",
    SS003069: "Clinical status",
    SS003070: "Not selected",
    //Не используются, так как берутся из справочника
    SS003071: "Active (first time)",
    SS003072: "Repeated",
    SS003073: "Relapse",
    SS003074: "Inactive",
    SS003075: "Remission",
    SS003076: "Cured",
    //-----
    //Не используются, так как отсутствуют на экране
    SS003077: "Confirmation status",
    SS003078: "Not selected",
    SS003079: "Suspected (preliminary)",
    SS003080: "Confirmed",
    SS003081: "Refuted",
    SS003082: "Erroneous",
    SS003083: "Disease nature",
    SS003084: "Not selected",
    SS003085: "Acute",
    SS003086: "Chronic",
    SS003087: "Date confirmed",
    SS003088: "Name of physician",
    SS003089: "ICD-10 code",
    SS003090: "ICD-10 code or name",
    SS003091: "Localization",
    SS003092: "Localization",
    SS003093: "Clarification",
    SS003094: "Histological type and ICD-O code",
    SS003095: "Histological type",
    SS003096: "Predictive factor",
    SS003097: "Classification type",
    SS003098: "Classification type",
    SS003099: "Nosological form description",
    SS003100: "Generate a description",
    SS003101: "Comment",
    SS003102: "Save",
    SS003103: "Cancel",
    SS003104: "New disease",
    SS003105: "Disease information",
    SS003106: "Prescribed",
    SS003107: "Not selected",
    SS003108: "Active (first time)",
    SS003109: "Repeated",
    SS003110: "Relapse",
    SS003111: "Inactive",
    SS003112: "Remission",
    SS003113: "Cured",
    SS003114: "Confirmation status",
    SS003115: "Not selected",
    SS003116: "Suspected (preliminary)",
    SS003117: "Confirmed",
    SS003118: "Refuted",
    SS003119: "Erroneous",
    SS003120: "Disease nature",
    SS003121: "Not selected",
    SS003122: "Acute",
    SS003123: "Chronic",
    SS003124: "Date asserted",
    SS003125: "Name of physician",
    SS003126: "ICD-10 code",
    SS003127: "ICD-10 code or name",
    SS003128: "Localization",
    SS003129: "Localization",
    SS003130: "Clarification",
    SS003133: "Predictive factor",
    SS003134: "Classification type",
    SS003135: "Classification type",
    SS003136: "Nosological form description",
    SS003137: "Generate a description",
    SS003138: "Comment",
    SS003139: "Save",
    SS003140: "Cancel",
    SS003141: "Histological type and ICD-O code",
    SS003142: "Histological type",
    SS003143: "Cancer Type",
    SS003144: "Cancer Type",
    SS003145: "T - Primary tumor",
    SS003146: "Test",
    SS003147: "N - Regional lymph nodes",
    SS003148: "Test",
    SS003149: "M - Distant metastasis",
    SS003150: "Test",
    SS003151: "G - Histological gradation",
    SS003152: "Test",
    SS003153: "Anatomical staging",
    SS003154: "Stage not defined",
    SS003155: "Additional classifiers and biomarkers",
    SS003156: "?",
    SS003157: "Test",
    SS003158: "?",
    SS003159: "?",
    SS003160: "Diseases",
    SS003161: "Clinical status:",
    SS003162: "Doctor:",
    SS003163: "Select a disease from the list to view or edit",
    SS004001: "Medical history",
    SS004002: "Fill out medical history",
    SS004003: "Family history",
    SS004004: "Fill out family history",
    SS004005: "Obstetric history",
    SS004006: "Fill out obstetric history",
    SS004007: "Allergies",
    SS004008: "Allergen",
    SS004009: "Reaction type",
    SS004010: "Medications",
    SS004011: "Medication",
    SS004012: "Dosing schedule",
    SS004013: "Add/edit Allergies",
    SS004014: "Add/edit Medications",
    SS004015: "Add allergen",
    SS004016: "Save",
    SS004017: "Add prescription",
    SS004018: "Save",
    SS004019: "Allergen",
    SS004020: "Type of reaction",
    SS004021: "Medication",
    SS004022: "Dosing schedule",
    SS005001: "Date",
    SS005002: "From",
    SS005003: "Till",
    SS005004: "Event",
    SS005005: "All",
    SS005006: "",
    SS005020: "Organization",
    SS005021: "All",
    SS005022: "Date",
    SS005023: "Event",
    SS005024: "",
    SS005038: "Organization",
    SS005039: "Description",
    SS005040: "Documents",
    SS006001: "Loading…",
    SS006002: "Condition assessment",
    SS006003: "Good",
    SS006004: "Normal",
    SS006005: "Bad",
    SS006006: "Terrible",
    SS006007: "Mon",
    SS006008: "Tue",
    SS006009: "Wed",
    SS006010: "Thu",
    SS006011: "Fri",
    SS006012: "Sat",
    SS006013: "Sun",
    SS006014: "Today",
    SS006015: "Body weight (kg)",
    SS006016: "Blood pressure (mmHg/mmHg)",
    SS006017: "Glucose level (mmol/L)",
    SS006018: "Fever/chills (° C)",
    SS006019: "Fatigue/weakness",
    SS006020: "Queasiness",
    SS006021: "Vomit",
    SS006022: "Stomatitis",
    SS006023: "Changes in stool",
    SS006024: "Constipation",
    SS006025: "Diarrhea",
    SS006026: "Loss of appetite",
    SS006027: "Pain/difficulty swallowing",
    SS006028: "Allergic reaction",
    SS006029: "Scabies and rashes",
    SS006030: "Shortness of breath",
    SS006031: "Cough",
    SS006032: "Muscle and joint pain",
    SS006033: "Numbness or tingling in the hands/feet",
    SS006034: "Swelling of the hands or feet",
    SS007001: "Add",
    SS007002: "No documents found",
    SS007003: "Create a document",
    SS007004: "Document name",
    SS007005: "New document",
    SS007006: "List of attachments",
    SS007007: "Add",
    SS007008: "Supported file formats: ",
    SS007009: "Document issue date",
    SS007010: "Document type",
    SS007011: "",
    SS007025: "Comment",
    SS007026: "Organization that issued the document",
    SS007027: "Document authors",
    SS007028: "Add",
    SS007029: "Delete",
    SS007030: "Save",
    SS007031: "Cancel",
    SS007032: "Document name",
    SS007033: "List of attachments",
    SS007034: "Document issue date",
    SS007035: "Document type",
    SS007036: "Comment",
    SS007037: "Organization that issued the document",
    SS007050: "Organization name",
    SS007051: "Other",
    SS007052: "Warning!",
    SS007053: "You have started editing the document. Exit without saving?",
    SS007054: "Yes",
    SS007055: "No",
    SS007056: "The document cannot be saved, please fill in the required fields:",
    SS007057: "List of attachments",
    SS007058: "Document type",
    SS007059: "Organization",
    SS003200: "Create Patient Case",
    SS003201: "Disease information",
    SS003202: "Basic diagnosis",
    SS003203: "ICD-10 code or name",
    SS003204: "required fields",
    SS003205: "Save",
    SS003206: "Cancel",
    SS003207: "ICD-10 code was not specified",
    SS003208: "Clinical diagnosis",
    SS003209: "Generate a description",
    SS003210: "Clinical status",
    SS003211: "Not selected",
    SS003212: "Active (first time)",
    SS003213: "Repeated",
    SS003214: "Relapse",
    SS003215: "Inactive",
    SS003216: "Remission",
    SS003217: "Cured",
    SS003218: "Diagnosis status",
    SS003219: "Not selected",
    SS003220: "Preliminary",
    SS003221: "Confirmed",
    SS003222: "Name of physician",
    SS003223: "Additional information",
    SS003224: "Comment",
    SS003225: "Clinical diagnosis {mkb10}: {name} isn't specified",
    SS003226: "Method of confirming the diagnosis",
    SS003227: "No values available",
    SS003228: "Date confirmed",
    SS003229: "Affected side",
    SS003230: "No values available",
    SS003231: "Disease information",
    SS003232: "",
    SS002052: "Patient not found",
    SS002053: "Create a patient",
    SS002054: "Create a patient",
    SS002055: "Last Name",
    SS002056: "Name",
    SS002057: "Middle name",
    SS002058: "Date of birth",
    SS002059: "Gender",
    SS002060: "Gender",
    SS002061: "Specify patient’s gender",
    SS002062: "Male",
    SS002063: "Female",
    SS002064: "SSN",
    SS002065: "Coverage Type",
    SS002066: "Coverage Type",
    SS002067: "Specify Coverage Type",
    SS002068: "Obsolete type",
    SS002069: "New type",
    SS002070: "Temporary certificate",
    SS9041030: "CT test",
    SS9041040: "Hospital discharge summary",
    SS9041050: "Laboratory test",
    SS9041060: "Mammography",
    SS9041070: "MRT",
    SS9041080: "Other Documents",
    SS9041090: "Abstract of outpatient medical record",
    SS9041100: "Histology test",
    SS9041110: "PET-CT",
    SS9041120: "Medical specialist's consultation",
    SS9041130: "Surgical record",
    SS9041140: "Tumor Board conclusion",
    SS9041150: "Ultrasound test",
    SS9041160: "Radiological tests",
    SS002071: "Coverage Document Number",
    SS002072: "E-mail",
    SS002073: "Telephone Number",
    SS002074: "Disability",
    SS002075: "Disability",
    SS002076: "Specify the disability",
    SS002077: "No disability",
    SS002078: "1 group",
    SS002079: "2 group",
    SS002080: "3 group",
    SS002081: "Disabled child",
    SS002082: "Save",
    SS002083: "Address",
    SS002084: "Patient's address:",
    SS003197: "Create request",
    SS003198: "No entries",
    SS003199: "No entries",
    SS006050: "Patient did not agree to provide information from the self-monitoring diary",
    SS008001: "Role Management",
    SS008002: "Organization administrator",
    SS008003: "Attending physician",
    SS008004: "ChairPerson",
    SS008005: "Tumor Board Secretary",
    SS008006: "Expert",
    SS008007: "Add a role",
    SS008008: "Select a role to configure access rights",
    SS008009: "Role name",
    SS008010: "Enter a name",
    SS008011: "ID",
    SS008012: "Enter ID",
    SS008013: "Allow",
    SS008014: "Deny",
    SS008015: "Filter by name",
    SS008016: "Show only marked",
    SS008017: "Select all",
    SS008018: "Report cancelaltion",
    SS008019: "Archiving your Treatment Templates",
    SS008020: "Archiving Treatment Templates",
    SS008021: "Make a comment/dissenting opinion on the report of the Tumor Board",
    SS008022: "Add a new team/members",
    SS008023: "Add/edit medication prescriptions",
    SS008024: "Access to the Documents",
    SS008025: "Access to the Patient Cases",
    SS008026: "Access to the Tumor Boards",
    SS008027: "Access to the \"Organizations\" page",
    SS008028: "Access to the Treatment Plans",
    SS008029: "Access to the TB Records",
    SS008030: "Access to the Workspace",
    SS008031: "Access to the Employees",
    SS008032: "Access to the Treatment Templates",
    SS008033: "Access to the \"All patients\" page by clicking the \"Select patient\" button\"",
    SS008034: "Fill in the \"Summary by Day\"",
    SS008035: "Fill in the \"Treatment status\"",
    SS008036: "Fill in the \"Treatment status only for your Treatment Cycle\"",
    SS008037: "Fill in the \"Start date and duration of the cycle\"",
    SS008038: "Fill in the \"Cycle Name\"",
    SS008039: "Fill in the \"Current patient status\"",
    SS008040: "Launch a Patient Case presentation",
    SS008041: "Change the case status to \"Approved\", all cases",
    SS008042: "Change the case status to \"Approved\", my cases",
    SS008043: "Change the case status to \"Cancelled\"",
    SS008044: "Change case status to \"Completed\" (the availability of the \"Make a conclusion\" button), all",
    SS008045: "Change case status to \"Completed\" (the availability of the \"Make a conclusion\" button), your own",
    SS008046: "Appointment of an expert to the Tumor Board",
    SS008047: "Leave a comment in the case on the \"Comment\" tab, all",
    SS008048: "Leavie a comment in the case on the \"Comment\" tab, only your own cases",
    SS008049: "Display avatar in the title",
    SS008050: "Display the action bar in the header",
    SS008051: "Display the UpToDate form in the header",
    SS008052: "Switch of the Treatment Plan to the status \"Completed\"",
    SS008053: "Switch the Cycle to the \"Active\" status",
    SS008054: "Switch the Cycle to the \"Completed\" status",
    SS008055: "Switch the Cycle to the \"Ordered\" status",
    SS008056: "Switch the Cycle to the \"Cancelled\" status",
    SS008057: "Switch the Cycle to the \"Stopped\" status",
    SS008058: "Switch the Cycle to the \"On-hold\" status",
    SS008059: "Switch the loop to the \"Deleted\" status",
    SS008060: "Switch to the Treatment Cycle from the patient's record",
    SS008061: "Print Treatment plan report",
    SS008062: "Print the Treatment Cycle report",
    SS008063: "Confirm the Tumor Board reports by electronic digital signature",
    SS008064: "Confirm all prescriptions at once",
    SS008065: "Get the electronic version of the report",
    SS008066: "Get the electronic version of the report (only for the expert)",
    SS008067: "Attach a PDF with a scan of the Tumor Board report, all reports",
    SS008068: "Attach a PDF with a scan of the Tumor Board report, your own reports",
    SS008069: "View all Treatment Cycle tabs based on the Treatment Cycle status",
    SS008070: "View all Tumor Board cases where invited and search for cases using filters",
    SS008071: "View all existing patient cases and search for a request using filters",
    SS008072: "View all existing Tumor Boards",
    SS008073: "View the case for the Tumor Boards",
    SS008074: "View the case for the Tumor Board where you are invited as an expert",
    SS008075: "View Patient Cases and Reports in the modal window",
    SS008076: "View Patient Cases via the Tumor Board list interface (read only), all",
    SS008077: "View Patient Cases for the Tumor Board through the interface of the Tumor Board list (read only), to the tumor Board of which you are invited as a participant",
    SS008078: "View Tumor Board Patient Cases via the Tumor Board list interface (read only), your own",
    SS008079: "View a patient's profile",
    SS008080: "View the Tumor Board to which you are invited as a participant (Your own)",
    SS008081: "View the content of the \"Documents\" page",
    SS008082: "View the content of the \"Organizations\" page",
    SS008083: "View the content of the \"Desktop\" page",
    SS008084: "View the content of the \"Employees\" page",
    SS008085: "View medication prescriptions in the modal window",
    SS008086: "View hospital",
    SS008087: "View Tumor Board's report",
    SS008088: "View an employee",
    SS008089: "View the list of patients and search for patients in the list using filters",
    SS008090: "View the list of patients and search for patients in the list using filters in the Treatment Plans section",
    SS008091: "View Patient's Treatment Plans list",
    SS008092: "View Tumor Board's reports list",
    SS008093: "View a list of your reports/on which you are marked as a participant",
    SS008094: "View page of teams/members",
    SS008095: "Item in the menu of teams/members",
    SS008096: "Edit the \"Treatment Results\" tab",
    SS008097: "Edit the \"Treatment Totals\" tab only for your own Treatment Cycle",
    SS008098: "Edit a Patient Case",
    SS008099: "Edit a Patient Case",
    SS008100: "Edit a Patient's Profile",
    SS008101: "Edit Tumor Board",
    SS008102: "Edit hospital",
    SS008103: "Edit the parameters of your Treatment Templates in the Treatment Templates section",
    SS008104: "Edit Treatment Template parameters in the Treatment Templates section",
    SS008105: "Edit the \"Totals\" field on the \"Treatment Totals\" tab",
    SS008106: "Edit the report of the Tumor Board, all reports",
    SS008107: "Edit the report of the Tumor Board to which one is invited",
    SS008108: "Edit the report of the Tumor Board, all reports",
    SS008109: "Edit an employee",
    SS008110: "Create a diagnosis in the \"Patient Treatment Plans\" section",
    SS008111: "Create/Copy Treatment Cycles in a \"Treatment Plan\"",
    SS008112: "Creation of a new Tumor Board and editing it",
    SS008113: "Create new patient",
    SS008114: "Create new patient case",
    SS008115: "Create hospital",
    SS008116: "Create treatment Plan",
    SS008117: "Create a Treatment Plan from a patient's profile",
    SS008118: "Createan employee",
    SS008119: "Save a Treatment Plan to a Template",
    SS008120: "Save your \"Treatment Plan\" to a \"Template\"",
    SS008121: "Delete Patient Cases for the Tumor Board, All",
    SS008122: "Delete Patient Cases for the Tumor Board, your own",
    SS008123: "Delete \"Tumor Board\"",
    SS008124: "Delete organization",
    SS008125: "Delete \"Treatment Plan\"",
    SS008126: "Delete your \"Own Treatment Templates\"",
    SS008127: "Delete \"Employees\"",
    SS008128: "Delete \"Treatment Templates\"",
    SS008129: "Manage user roles",
    SS008130: "Manage user roles (menu item)",
    SS008131: "Create a Tumor Board report",
    SS008132: "Create",
    SS008133: "Cancel",
    SS008134: "ID must consist only of lowercase latin letters, an underscore and a hyphen",
    SS008135: "Make copy",
    SS008136: "Delete",
    SS008137: "Warning!",
    SS008138: "Are you sure to delete the {role} role?",
    SS008139: "Yes",
    SS008140: "No",
    SS008141: "Role ({role}) successfully deleted",
    SS008142: "Role ({role}) created successfully",
    SS008143: "Are you sure to make a copy of the {role} role?",
    SS008144: "A copy of the {role1} role ({role2}) was created successfully",
    SS009001: "Employees",
    SS009002: "Employee full name",
    SS009003: "Login",
    SS009004: "SSN",
    SS009005: "Organization",
    SS009006: "Role",
    SS009007: "Reset filters",
    SS009008: "Employee full name",
    SS009009: "Login",
    SS009010: "SSN",
    SS009011: "Organization",
    SS009012: "",
    SS009017: "Add an employee",
    SS009018: "Employees",
    SS009019: "Add an employee",
    SS009020: "Personal Information",
    SS009021: "Login",
    SS009022: "Password",
    SS009023: "Enter your password",
    SS009024: "Active",
    SS009025: "Last Name",
    SS009026: "Name",
    SS009027: "Middle name",
    SS009028: "Date of Birth",
    SS009029: "Gender",
    SS009030: "Male",
    SS009031: "Female",
    SS009032: "SSN",
    SS009033: "Personal phone number",
    SS009034: "Employment",
    SS009035: "A place of current employment",
    SS009036: "Delete organization",
    SS009037: "Organization",
    SS009038: "Employee's E-mail",
    SS009039: "Employee's phone number",
    SS009040: "Position",
    SS009041: "",
    SS009042: "Save",
    SS009043: "",
    SS009044: "Delete a position",
    SS009045: "Specialization",
    SS009046: "",
    SS009047: "Role",
    SS009048: "Required field",
    SS009049: "Substituting employee",
    SS009050: "Add a substituting employee",
    SS009051: "History of substitutions",
    SS009052: "Full Name",
    SS009053: "Position",
    SS009054: "Substitution start date",
    SS009055: "Substitution end date",
    SS009056: "Login must be a valid e-mail address",
    SS009057: "Enter a password consisting of 6 or more characters",
    SS009058: "SSN",
    SS009059: "Information saved",
    SS000001: "Show by:",
    SS000002: "First page",
    SS000003: "Previous page",
    SS000004: "Next page",
    SS000005: "Last page",
    SS009060: "Selection of a replacement employee",
    SS009061: "Substitution start date",
    SS009062: "Substitution end date",
    SS009063: "Select an employee",
    SS009064: "Role",
    SS009065: "Full Name",
    SS009066: "Full Name",
    SS009067: "Position",
    SS009068: "Search",
    SS009069: "Full Name",
    SS009070: "Specialization",
    SS009071: "Position",
    SS009072: "Role",
    SS009073: "Add",
    SS009074: "Cancel",
    SS009075: "Workbench of role:",
    SS009076: "Position:",
    SS009077: "Selected employee is already assigned as a substituting one on overlapping dates. Select another employee or change the dates of substitution.",
    SS009079: "Enter a valid e-mail address",
    SS009080: "An error while saving",
    SS009081: "The user's login is not specified",
    SS009082: "Last name not specified",
    SS009083: "Name not specified",
    SS009084: "Date of birth not specified",
    SS009085: "Gender not specified",
    SS009086: "Enter your phone number",
    SS009087: "Specify at least one hospital",
    SS009088: "Specify at least one position in each hospital",
    SS009089: "Specify the role",
    SS009091: "Edit an employee",
    SS010001: "Organizations",
    SS010002: "Name",
    SS010003: "Address",
    SS010004: "Reset filters",
    SS010005: "Name",
    SS010006: "Address",
    SS010007: "Add organization",
    SS010008: "Organizations",
    SS010009: "Add organization",
    SS010010: "Basic information",
    SS010011: "Name",
    SS010012: "Full name",
    SS010013: "Name for printing",
    SS010014: "Type",
    SS010015: "Organization",
    SS010016: "Branch office, structural division",
    SS010017: "Functional roles of the organization",
    SS010018: "Directs to Tumor Board",
    SS010019: "Hosts Tumor Board",
    SS010020: "Contact details",
    SS010021: "Addresses",
    SS010022: "Save",
    SS010023: "IDs",
    SS010024: "Add ID",
    SS010025: "Type",
    SS010026: "Value",
    SS010027: "Location coordinates",
    SS010028: "Longitude",
    SS010029: "Latitude",
    SS010030: "Name not specified",
    SS010031: "Full name not specified",
    SS010032: "The name for printing is not specified",
    SS010033: "Organization saved",
    SS010034: "Add a contact",
    SS010035: "Type",
    SS010036: "Phone Number",
    SS010037: "Fax",
    SS010038: "E-mail",
    SS010039: "Pager",
    SS010040: "URL",
    SS010041: "SMS",
    SS010042: "Other",
    SS010043: "Please enter your contact details",
    SS010044: "Add",
    SS010045: "Enter the address",
    SS010046: "Type",
    SS010047: "Home",
    SS010048: "Work",
    SS010049: "Temporary",
    SS010050: "Deprecated",
    SS010051: "For correspondence",
    SS010052: "Purpose",
    SS010053: "Mail and physical address",
    SS010054: "Post",
    SS010055: "physical",
    SS010056: "Postal code",
    SS010057: "Сountry",
    SS010058: "Russia",
    SS010059: "Region",
    SS010060: "District",
    SS010061: "Town/City",
    SS010062: "Address",
    SS010063: "Add",
    SS010064: "Enter the address",
    SS010065: "Specify the town/city",
    SS010066: "Specify region",
    SS010067: "Specify the country",
    SS010068: "Organizations",
    SS010069: "Edit organization",
    SS011001: "Tumor boards",
    SS011002: "Patient's full name",
    SS011003: "Tumor Board Name",
    SS011004: "Disease code or name",
    SS011005: "The list is empty…",
    SS011006: "Organization",
    SS011007: "Reset filters",
    SS011008: "No Tumor Boards found. Change the search terms.",
    SS011009: "Create a Tumor Board",
    SS011010: "Free slot",
    SS011011: "Show more…",
    SS011012: "Estimated review time",
    SS011013: "",
    SS011014: "Tumor boards",
    SS011015: "Create a Tumor Board",
    SS011016: "Save",
    SS011017: "Basic information",
    SS011018: "Tumor Board name",
    SS011019: "Enter a tumor board name",
    SS011020: "Event date",
    SS011021: "Event period",
    SS011022: "Event date",
    SS011023: "Event location",
    SS011024: "Organization",
    SS011025: "Outpatient Cancer Care Center",
    SS011026: "Event time",
    SS011027: "Number of slots",
    SS011028: "Average processing time per case ~ ",
    SS011029: "Organization",
    SS011030: "Detailed address",
    SS011031: "Special requirements",
    SS011032: "Patient cases in this tumor board must be approved by the secretary",
    SS011033: "Diagnoses for consideration",
    SS011034: "Add a group",
    SS011035: "Participants",
    SS011036: "Tumor Board Team",
    SS011037: "ChairPerson",
    SS011038: "Secretary",
    SS011039: "Surgical oncologist",
    SS011040: "Medical oncologist",
    SS011041: "Radiation oncologist",
    SS011042: "Additional participant",
    SS011043: "Delete slot",
    SS011044: "Patient Cases ({count})",
    SS011045: "Number of slots:",
    SS011046: "Specify a Tumor Board name",
    SS011047: "Specify the Tumor Board ChairPerson",
    SS011048: "Specify the Tumor Board Secretary",
    SS011049: "Specify the Surgical oncologist",
    SS011050: "Specify the Medical oncologist",
    SS011051: "Specify the Radiation oncologist",
    SS011052: "Tumor Board saved",
    SS011053: "Tumor Board time frame and schedule",
    SS0110541: "Mon",
    SS0110542: "Tue",
    SS0110543: "Wed",
    SS0110544: "Thu",
    SS0110545: "Fri",
    SS0110546: "Sat",
    SS0110547: "Sun",
    SS011055: "Select the days of Tumor Board",
    SS011056: "Add a group",
    SS011057: "Name",
    SS011058: "",
    SS011059: "Diagnoses for consideration:",
    SS011060: "",
    SS011061: "Suggested checkups:",
    SS011062: "",
    SS011063: "Create",
    SS011064: "Cancel",
    SS011065: "",
    SS011066: "Diseases",
    SS011067: "",
    SS011068: "When selecting a disease category, all subheadings are taken into account automatically",
    SS011069: "Enter the ICD-10 disease code or name",
    SS011070: "Add",
    SS011071: "Suggested checkup",
    SS011072: "The list is empty…",
    SS011073: "Add",
    SS011074: "",
    SS011075: "The disease was added to the list of those considered on the Tumor Board",
    SS011076: "The disease was removed from Tumor Board",
    SS011077: "Warning!",
    SS011078: "Are you sure to remove {xxx} from the Tumor Board agenda?",
    SS011079: "Yes",
    SS011080: "No",
    SS011081: "Tumor Board Team",
    SS011082: "No Tumor Board teams found",
    SS011083: "Select",
    SS011084: "Cancel",
    SS011085: "Edit a list of TB participants",
    SS011086: "ChairPerson",
    SS011087: "Secretary",
    SS011088: "Surgical oncologist",
    SS011089: "Medical oncologist",
    SS011090: "Radiation oncologist",
    SS011091: "Additional participant",
    SS011092: "Organization",
    SS011093: "Select organization",
    SS011094: "NEW ORGANIZATION",
    SS011095: "Select organization",
    SS011096: "Employee",
    SS011097: "Select an employee",
    SS011098: "New employee",
    SS011099: "Selection an employee",
    SS011100: "Position",
    SS011101: "Select an employee position",
    SS011102: "New position",
    SS011103: "Select a position",
    SS011104: "",
    SS011105: "Specialization",
    SS011106: "Specialization",
    SS011107: "",
    SS011108: "Telepresence",
    SS011109: "Add",
    SS011110: "Clear",
    SS011111: "Close",
    SS011112: "",
    SS011113: "",
    SS011114: "",
    SS011115: "",
    SS011116: "Telepresence",
    SS011117: "Edit",
    SS011118: "Delete",
    SS011119: "Warning!",
    SS011120: "Do you want to remove a participant from Tumor Board?",
    SS011121: "Yes",
    SS011122: "No",
    SS011123: "Tumor boards",
    SS011124: "Edit a Tumor Board",
    SS011125: "Make copy",
    SS011126: "Save",
    SS011127: "",
    SS011128: "Tumor Board copy",
    SS011129: "Specify the date of the Tumor Board",
    SS011130: "Make copy",
    SS011131: "A copy of the current Tumor Board was created successfully",
    SS011132: "Cancel Tumor Board",
    SS011133: "Warning!",
    SS011134: "Delete Tumor Board {xxx}?",
    SS011135: "Yes",
    SS011136: "No",
    SS011137: "The Tumor Board was deleted",
    SS011138: "ChairPerson",
    SS011139: "Secretary",
    SS011140: "Surgical oncologist",
    SS011141: "Medical oncologist",
    SS011142: "Radiation oncologist",
    SS011143: "Additional participant",
    SS012001: "Tumor Board Team",
    SS012002: "Status",
    SS012003: "Active",
    SS012004: "Inactive",
    SS012005: "Full name of participant",
    SS012006: "Date",
    SS012007: "Newest",
    SS012008: "Oldest",
    SS012009: "Reset filters",
    SS012010: "No Tumor Board Teams found",
    SS012011: "Add team",
    SS012012: "ChairPerson",
    SS012013: "Secretary",
    SS012014: "Surgical oncologist",
    SS012015: "Medical oncologist",
    SS012016: "Radiation oncologist",
    SS012017: "Additional participant",
    SS012018: "",
    SS012019: "",
    SS012020: "Active",
    SS012021: "Inactive",
    SS012022: "Copy participants",
    SS012023: "Cancel membership",
    SS012024: "Warning!",
    SS012025: "Copy the participants of the Tumor Board from {date}?",
    SS012026: "Yes",
    SS012027: "No",
    SS012028: "Tumor Board Team was copied successfully",
    SS012029: "Warning!",
    SS012030: "Do you want to cancel membership of the Tumor Board {tumorBoardName} participants from {date}?",
    SS012031: "Yes",
    SS012032: "No",
    SS012033: "Tumor Board Team was successfully cancelled",
    SS012034: "Tumor Board Team",
    SS012035: "Add a Tumor Board Team",
    SS012036: "Date",
    SS012037: "Number",
    SS012038: "Add a scan",
    SS012039: "Save",
    SS012040: "ChairPerson",
    SS012041: "Secretary",
    SS012042: "Surgical oncologist",
    SS012043: "Medical oncologist",
    SS012044: "Radiation oncologist",
    SS012045: "Additional participant",
    SS012046: "Delete slot",
    SS012047: "ChairPerson",
    SS012048: "Secretary",
    SS012049: "Surgical oncologist",
    SS012050: "Medical oncologist",
    SS012051: "Radiation oncologist",
    SS012052: "Additional participant",
    SS012053: "Organization",
    SS012054: "Select organization",
    SS012055: "NEW ORGANIZATION",
    SS012056: "Select organization",
    SS012057: "Employee",
    SS012058: "Select an employee",
    SS012059: "New employee",
    SS012060: "Selecting an employee",
    SS012061: "Position",
    SS012062: "Select an employee position",
    SS012063: "New position",
    SS012064: "Select a position",
    SS012065: "",
    SS012066: "Specialization",
    SS012067: "Specialization",
    SS012068: "",
    SS012069: "Telepresence",
    SS012070: "Save",
    SS012071: "Clear",
    SS012072: "Delete",
    SS012073: "Cancel",
    SS012074: "Specify the Tumor Board ChairPerson",
    SS012075: "Specify the Tumor Board Secretary",
    SS012076: "Specify the Surgical oncologist",
    SS012077: "Specify the Medical oncologist",
    SS012078: "Specify the Radiation oncologist",

    SS012079: "Warning!",
    SS012080: "Are you sure to delete employee information?",
    SS012081: "Yes",
    SS012082: "No",
    SS012083: "Telepresence",
    SS012084: "Edit",
    SS012085: "Delete",
    SS012086: "The team of the participants of the Tumor Board from {date} was successfully cancelled",
    SS012087: "The team of the participants of the Tumor Board was successfully saved",

    SS013001: "Documents",
    SS013002: "Search by document name",
    SS013003: "Attachments:",
    SS013004: "No",
    SS013005: "Document name",
    SS013006: "New document",
    SS013007: "List of attachments",
    SS013008: "Add",
    SS013009: "Supported file formats: bmp, gif, tiff, jpeg, png, pdf, doc, docx",
    SS013010: "Document issue date",
    SS013011: "Document type",
    SS013012: "",
    SS013013: "Comment",
    SS013014: "Organization that issued the document",
    SS013015: "Authors of the document",
    SS013016: "Add",
    SS013017: "Save",
    SS013018: "Delete",
    SS000006: "Error",
    SS000007: "Oh, something went wrong. Please try again later.",
    SS000008: "Loading…",
    SS014001: "Patient Cases",
    SS014002: "Patient's full name",
    SS014003: "Case status",
    SS014004: "In processing",
    SS014005: "Created",
    SS014006: "On approval",
    SS014007: "Ready for review",
    SS014008: "Returned for revision",
    SS014009: "Cancelled",
    SS014010: "Deleted",
    SS014011: "Completed",
    SS014012: "Disease code or name",
    SS014013: "The list is empty…",
    SS014014: "Organization",
    SS014015: "Reset filters",
    SS014016: "Patient cases were not found. Change the search terms.",
    SS014017: "Create Patient Case",
    SS014018: "Patient's full name",
    SS014019: "Creation date",
    SS014020: "Tumor Board Date",
    SS014021: "Organization",
    SS014022: "Status",
    SS014023: "y.o.",
    SS014024: "year",
    SS014025: "year",
    SS014026: "Male",
    SS014027: "Female",
    SS014028: "Tumor board in the past",
    SS014029: "{number} of days",
    SS014030: "{number} of day",
    SS014031: "{number} day",
    SS014032: "In processing",
    SS014033: "Created",
    SS014034: "On approval",
    SS014035: "Ready for review",
    SS014036: "Returned for revision",
    SS014037: "Сancelled",
    SS014038: "Deleted",
    SS014039: "Completed",
    SS014040: "Patient Cases",
    SS014041: "Select patients for the Tumor Board",
    SS014042: "Patient's full name",
    SS014043: "Disease code or name",
    SS014044: "Reset filters",
    SS014045: "Selected patients",
    SS014046: "Patient’s full name",
    SS014047: "Age",
    SS014048: "Gender",
    SS014049: "Diagnosis",
    SS014050: "y.o.",
    SS014051: "year",
    SS014052: "year",
    SS014053: "Male",
    SS014054: "Female",
    SS014055: "Select the Tumor Board",
    SS014056: "Patient Cases",
    SS014057: "Assign patients to the Tumor Board - {info}",
    SS014058: "Male",
    SS014059: "Female",
    SS014060: "y.o.",
    SS014061: "year",
    SS014062: "year",
    SS014063: "Not distributed",
    SS014064: "Expert",
    SS014065: "Organization",
    SS014066: "Display TBs with no available slots",
    SS014067: "Sort by date",
    SS014068: "Nothing was found. Try changing your search terms",
    SS014069: "Create Patient Case",
    SS011144: "Couldn't send invitation to new participants of the Tumor Board",
    SS014071: "",
    SS014072: "Examination plan",
    SS014073: "",
    SS014074: "",
    SS014075: "Number of cases",
    SS014076: "My cases",
    SS014077: "Number of free slots",
    SS014078: "Create Patient Case",
    SS014079: "Select time",
    SS014080: "from {time1} until {time2}",
    SS014081: "Select",
    SS014082: "ChairPerson",
    SS014083: "Secretary",
    SS014084: "",
    SS014085: "",
    SS014086: "Select time",
    SS014087: "Specify the time to complete the distribution of the current case",
    SS014088: "Patient case \"{text}\" created",
    SS014089: "Patient Cases",
    SS014090: "Patient Case #{number} - {param}",
    SS014091: "Patient Profile",
    SS014092: "y.o.",
    SS014093: "year",
    SS014094: "year",
    SS014095: "male",
    SS014096: "female",
    SS014097: "Presentation mode",
    SS014098: "In processing",
    SS014099: "Created",
    SS014100: "On approval",
    SS014101: "Ready for review",
    SS014102: "Returned for revision",
    SS014103: "Cancelled",
    SS014104: "Deleted",
    SS014105: "Completed",
    SS014106: "Coverage Document",
    SS014107: "SSN",
    SS014108: "Date and time of creation",
    SS014109: "Sending organization",
    SS014110: "Attending physician",
    SS014111: "Tumor Board Date",
    SS014112: "Tumor Board Name",
    SS014113: "Date of Birth",
    SS014114: "Phone Number",
    SS014115: "Tumor Board Location",
    SS014116: "Tumor Board Purpose",
    SS014117: "Tumor Board Purpose",
    SS014118: "General information",
    SS014119: "Diagnosis",
    SS014120: "Cancer history",
    SS014121: "History",
    SS014122: "General condition",
    SS014123: "Diagnostic tests",
    SS014124: "Comments",
    SS014125: "Presentation",
    SS014126: "Save",
    SS014127: "Delete",
    SS014128: "Ready",
    SS014129: "Underlying disease",
    SS014130: "",
    SS014131: "Clinical status",
    SS014132: "Oncologist",
    SS014133: "Competing diseases",
    SS014134: "Add a competing disease",
    SS014135: "No information available",
    SS014136: "Disease complications",
    SS014137: "Add a complication of the underlying disease",
    SS014138: "No information available",
    SS014139: "Concomitant diseases",
    SS014140: "Add a concomitant disease",
    SS014141: "No information available",
    SS014142: "",
    SS014143: "",
    SS014144: "",
    SS014145: "Competing diseases",
    SS014146: "Disease complications",
    SS014147: "Concomitant diseases",
    SS014148: "New disease record",
    SS014149: "To add a new disease, search by code or name",
    SS014150: "The list is empty…",
    SS014151: "Known patient diseases",
    SS014152: "",
    SS014153: "Add",
    SS014154: "Warning!",
    SS014155: "Are you sure to delete the disease {text}?",
    SS014156: "Yes",
    SS014157: "No",
    SS014158: "Disease removed",
    SS014159: "Edit a disease",
    SS014160: "",
    SS014161: "",
    SS014162: "Edit a disease",
    SS014163: "Method of confirming the diagnosis",
    SS014164: "",
    SS014165: "Affected side",
    SS014166: "Left",
    SS014167: "Right",
    SS014168: "Double-sided",
    SS014169: "Not applicable",
    SS014170: "Morphological type and ICD-O code",
    SS014171: "Enter a value to search for",
    SS014172: "Clinical staging cTNM",
    SS014173: "T - Primary tumor",
    SS014174: "N - Regional lymph nodes",
    SS014175: "M - Distant metastasis",
    SS014176: "Stage",
    SS014177: "Unknown",
    SS014178: "Not applicable",
    SS014179: "Pathological staging pTNM",
    SS014180: "T - Primary tumor",
    SS014181: "N - Regional lymph nodes",
    SS014182: "M - Distant metastasis",
    SS014183: "Stage",
    SS014184: "Unknown",
    SS014185: "Not applicable",
    SS014186: "G - Histological gradation",
    SS014187: "L - Lymphatic invasion",
    SS014188: "V - Venous invasion",
    SS014189: "Pn - Perineural invasion",
    SS014190: "R - Residual tumor",
    SS014191: "Site of distant metastases",
    SS014192: "",
    SS014193: "Immunohistochemistry results",
    SS014194: "Add",
    SS014195: "Number",
    SS014196: "Date reported",
    SS014197: "Organization",
    SS014198: "Select organization",
    SS014199: "Delete",
    SS014200: "Test",
    SS014201: "Add test",
    SS014202: "Result",
    SS014203: "",
    SS014204: "",
    SS014205: "",
    SS014206: "Molecular genetics results",
    SS014207: "Add",
    SS014208: "",
    SS014209: "",
    SS014210: "Disease saved",
    SS014211: "Description",
    SS014212: "Supporting documents",
    SS014213: "Add record",
    SS014214: "There are no supporting documents",
    SS014215: "Add an anamnesis record",
    SS014216: "Date",
    SS014217: "Document type",
    SS014218: "",
    SS014219: "Result",
    SS014220: "Specify result",
    SS014221: "Location",
    SS014222: "Documents",
    SS014223: "Add document",
    SS014224: "Save",
    SS014225: "Cancel",
    SS014226: "Edit document",
    SS014227: "",
    SS014228: "The \"Date\" field is required",
    SS014229: "The \"Document type\" field is required",
    SS014230: "The \"Result\" field is required",
    SS014231: "Date",
    SS014232: "Event",
    SS014233: "",
    SS014234: "Description",
    SS014235: "Organization",
    SS014236: "Documents",
    SS014237: "Medical history",
    SS014238: "Fill out medical history",
    SS014239: "Family history",
    SS014240: "Fill out family history",
    SS014241: "Obstetric history",
    SS014242: "Fill out obstetric history",
    SS014243: "Allergies",
    SS014244: "Allergen",
    SS014245: "",
    SS014246: "Reaction type",
    SS014247: "",
    SS014248: "",
    SS014249: "",
    SS014250: "Save",
    SS014251: "Medications",
    SS014252: "Medication",
    SS014253: "",
    SS014254: "Dosing schedule",
    SS014255: "",
    SS014256: "Save",
    SS014257: "General condition",
    SS014258: "Current status",
    SS014259: "Date",
    SS014260: "Vital sign",
    SS014261: "Result",
    SS014262: "Location",
    SS014263: "",
    SS011145: "Warning!",
    SS011146: "What Tumor Boards should be changed?",
    SS011147: "This",
    SS011148: "This and all next",
    SS011149: "All",
    SS014264: "Examination plan",
    SS014265: "Add item",
    SS014266: "Print",
    SS014267: "",
    SS014268: "It is necessary to upload documents according to the checklist",
    SS014269: "Examination plan before Tumor Board",
    SS014270: "Select an examination plan item in the list on the left to view or edit it",
    SS014271: "Add document",
    SS014272: "New document",
    SS014273: "Send as a task to the patient",
    SS014274: "Document editing",
    SS014275: "",
    SS014276: "Add from a mobile app",
    SS014277: "QR code to download attachments from a mobile app",
    SS014278: "Open the mobile app and scan the QR code",
    SS014279: "Upload files into mobile app",
    SS014280: "When upload is complete, close the window. The new files will appear in the list of attachments.",
    SS014281: "Task: {text}",
    SS014282: "Comment for the patient",
    SS014283: "Provide by",
    SS014284: "Send task",
    SS014285: "It is necessary to specify the date",
    SS014286: "The task has been sent to the patient. Due by {date}, you will be notified when they send a reply.",
    SS014287: "Click here to change or cancel the task",
    SS014288: "Task: {text}",
    SS014289: "Your comment",
    SS014290: "Provide by",
    SS014291: "Cancel task",
    SS014292: "Cancel",
    SS014293: "Save",
    SS014294: "Error when changing task",
    SS014295: "Error when cancelling a task",
    SS014296: "New point added",
    SS014297: "Item name",
    SS014298: "Added point",
    SS014299: "Save",
    SS014300: "Add document",
    SS014301: "Item successfully renamed",
    SS014302: "Delete item",
    SS014303: "Item deleted successfully",
    SS014304: "Print",
    SS014305: "Male",
    SS014306: "Female",
    SS014307: "?",
    SS014308: "y.o.",
    SS014309: "year",
    SS014310: "year",
    SS014311: "Tumor Board is scheduled for",
    SS014312: "",
    SS014313: "Comment",
    SS014314: "Enter a comment",
    SS014315: "Add",
    SS014316: "",
    SS014317: "",
    SS014318: "Change the status",
    SS014319: "The status of the examination plan item has been changed",
    SS014320: "Slides",
    SS014321: "Display slide",
    SS014322: "Hide slide",
    SS014323: "Diagnosis/History of the underlying disease/General condition",
    SS0143241: "Treatment chronology",
    SS0143242: "Current status",
    SS0143243: "Competing diseases, complications, and comorbidities",
    SS014325: "Allergies and Medications",
    SS014326: "Documents",
    SS014327: "Text",
    SS014328: "Add a slide",
    SS014329: "Slide with text",
    SS014330: "Slide with documents",
    SS014331: "Display block",
    SS014332: "Full Screen",
    SS014333: "Roll down",
    SS014334: "Diagnosis",
    SS014335: "",
    SS014336: "Type:",
    SS014337: "metachronous",
    SS014338: "",
    SS014339: "Date confirmed:",
    SS014340: "",
    SS014341: "Cancer history",
    SS014342: "General condition",
    SS014343: "Treatment chronology",
    SS014344: "Date",
    SS014345: "Event",
    SS014346: "Organization",
    SS014347: "Description",
    SS014348: "Document",
    SS014349: "Current status",
    SS014350: "",
    SS014351: "Competing diseases, complications, and comorbidities",
    SS014352: "Disease complications",
    SS014353: "No information available",
    SS014354: "",
    SS014355: "Concomitant diseases",
    SS014356: "No information available",
    SS014357: "",
    SS014358: "Competing diseases",
    SS014359: "No information available",
    SS014360: "",
    SS014361: "Medical history",
    SS014362: "Medical history",
    SS014363: "Family history",
    SS014364: "Family history",
    SS014365: "Allergies and Medications",
    SS014366: "Allergies",
    SS014367: "",
    SS014368: "",
    SS014369: "Medications",
    SS014370: "",
    SS014371: "Document editing",
    SS014372: "",
    SS014373: "Document uploading…",
    SS014374: "Slides",
    SS014375: "Diagnostic tests",
    SS014376: "Radiological tests",
    SS014377: "Other Documents",
    SS014378: "Hide the patient's name",
    SS014379: "",
    SS014380: "Histological type and ICD-O code",
    SS014381: "",
    SS014382: "Description of nosological form",
    SS014383: "",
    SS014384: "",
    SS014385: "",
    SS014386: "",
    SS014387: "A document with {x1} but with an empty {x2}",
    SS014388: "",
    SS014389: "",
    SS014390: "Other Documents",
    SS014391: "Document with {x1} but with an empty {x2}",
    SS014392: "Other Documents",
    SS014393: "",
    SS014394: "y.o.",
    SS014395: "year",
    SS014396: "year",
    SS014397: "Male",
    SS014398: "Female",
    SS014399: "Confirm the action on the page",
    SS014400: "Are you sure to exit presentation mode?",
    SS014401: "OK",
    SS014402: "Cancel",
    SS014403: "A request for a patient {x1} with a {x2} is already being considered by the '{x3}'",
    SS014404: "Patient Case updated",
    SS014405: "Warning!",
    SS014406: "Do you want to delete Patient Case?",
    SS014407: "Yes",
    SS014408: "No",
    SS014409: "Successfully deleted",
    SS0144101: "You didn’t specify",
    SS0144102: " and ",
    SS0144103: "description",
    SS0144104: "general condition",
    SS014411: "Warning!",
    SS014412: "Are you sure the patient case is prepared for the Tumor Board?",
    SS014413: "Yes",
    SS014414: "No",
    SS014415: "Return",
    SS014416: "Cancel",
    SS014417: "Finish editing",
    SS014418: "Open the report",
    SS014419: "Returning for revision",
    SS014420: "Comment (reason for return)",
    SS014421: "Comment",
    SS014422: "Return for revision",
    SS014423: "Warning!",
    SS014424: "Reject patient case?",
    SS014425: "Yes",
    SS014426: "No",
    SS014427: "Patient case rejected",
    SS014428: "Calendar event status for Patient App. successfully changed to: Cancelled",
    SS014429: "Push notification sent successfully",
    SS014430: "Calendar Event Status for Patient App. Successfully Changed to: Scheduled",
    SS014431: "Patient Case withdrawal",
    SS014432: "Comment (reason for withdrawing the case)",
    SS014433: "Comment",
    SS014434: "Cancel",
    SS014435: "The \"Comment\" field is required",
    SS014436: "Patient case cancelled",
    SS014437: "Warning!",
    SS014438: "Are you sure you want to change the status of the case?",
    SS014439: "Yes",
    SS014440: "No",
    SS014441: "Patient Case reviewed",
    SS015001: "Tumor Board Reports",
    SS015002: "Patient's full name",
    SS015003: "Status",
    SS015004: "In progress",
    SS015005: "Сancelled",
    SS015006: "To be signed",
    SS015007: "Ready",
    SS015008: "Publishing....",
    SS015009: "Published",
    SS015010: "Publication error",
    SS015011: "Disease code or name",
    SS015012: "The list is empty…",
    SS015013: "Organization",
    SS015014: "Reset filters",
    SS015015: "Patient's full name",
    SS015016: "Male",
    SS015017: "Female",
    SS015018: "Coverage Document",
    SS015019: "Organization",
    SS015020: "Event date",
    SS015021: "Passed since the day the report was created",
    SS015022: "days",
    SS015023: "Status",
    SS015024: "In progress",
    SS015025: "Сancelled",
    SS015026: "To be signed",
    SS015027: "Ready",
    SS015028: "Publishing....",
    SS015029: "Published",
    SS015030: "Publication error",
    SS015031: "Status error",
    SS015032: "Tumor Board Reports",
    SS015033: "Tumor Board report - {param}",
    SS0150331: "TB report",
    SS015034: "Patient Case has not yet been reviewed at the Tumor Board",
    SS015035: "Go to case",
    SS015036: "Creation date:",
    SS015037: "In progress",
    SS015038: "Сancelled",
    SS015039: "To be signed",
    SS015040: "Ready",
    SS015041: "Publishing....",
    SS015042: "Published",
    SS015043: "Publication error",
    SS015044: "Status error",
    SS015045: "Patient information",
    SS015046: "General Information",
    SS015047: "Tumor Board Members",
    SS015048: "Diagnosis",
    SS015049: "Cancer history",
    SS015050: "General condition",
    SS015051: "Documents submitted",
    SS015052: "Tumor Board decision",
    SS015053: "Patient information",
    SS015054: "Full Name",
    SS015055: "Gender",
    SS015056: "Male",
    SS015057: "Female",
    SS015058: "Date of birth",
    SS015059: "Coverage Document",
    SS015060: "SSN",
    SS015061: "General Information",
    SS015062: "Organization",
    SS015063: "Tumor Board Address",
    SS015064: "Report number",
    SS015065: "Tumor Board Date",
    SS015066: "Tumor Board",
    SS015067: "Primary",
    SS015068: "Repeated",
    SS015069: "Purpose of the Tumor Board",
    SS015070: "",
    SS015071: "Tumor Board Members",
    SS015072: "",
    SS015073: "Diagnosis",
    SS015074: "",
    SS015075: "General condition",
    SS015076: "Documents submitted",
    SS015077: "Tumor Board decision",
    SS015078: "Tumor Board result",
    SS015079: "",
    SS015080: "",
    SS015081: "Treatment tactics",
    SS015082: "",
    SS015083: "Special opinions of the participants of the Tumor Board",
    SS015084: "Cancel",
    SS015085: "To be signed",
    SS015086: "Surgical treatment",
    SS015087: "Delete",
    SS015088: "Treatment conditions",
    SS015089: "",
    SS015090: "Planned start date of treatment",
    SS015091: "Recommend medication therapy regimen",
    SS015092: "Delete",
    SS015093: "Medication therapy regimen",
    SS015094: "",
    SS015095: "Radiation therapy",
    SS015096: "Delete",
    SS015097: "Radiation therapy method",
    SS015098: "",
    SS015099: "Radiotherapy technique",
    SS015100: "",
    SS015101: "Conclusion/Rationale for recommended treatment tactics",
    SS015102: "Chemoradiation treatment",
    SS015103: "Delete",
    SS015104: "Medication therapy regimen",
    SS015105: "",
    SS015106: "Radiation therapy method",
    SS015107: "",
    SS015108: "Radiotherapy technique",
    SS015109: "",
    SS015110: "Planned start date of treatment",
    SS015111: "Complex (except for chemoradiation)",
    SS015112: "Delete",
    SS015113: "Medication therapy regimen",
    SS015114: "",
    SS015115: "Planned start date of treatment",
    SS015116: "Observation",
    SS015117: "Delete",
    SS015118: "Palliative care",
    SS015119: "Delete",
    SS015120: "Planned start date of treatment",
    SS015121: "Add a dissenting opinion",
    SS015122: "Participant",
    SS015123: "Delete",
    SS015124: "Warning!",
    SS015125: "Are you sure you want to change the status of the report to \"To be signed\"?",
    SS015126: "Yes",
    SS015127: "No",
    SS015128: "Select the type of Tumor Board",
    SS015129: "Fill in all the lines of the dissenting opinion of the Tumor Board participants",
    SS015130: "Fill in your planned start date for your cancer treatment",
    SS015131: "Specify the treatment conditions for the cancer disease",
    SS015132: "Indicate the \"Conclusion/Rationale\" of the recommended treatment for cancer",
    SS015133: "Complete the case",
    SS015134: "Documents",
    SS015135: "Attach PDF",
    SS015136: "Return to work",
    SS015137: "Ready",
    SS015138: "Report.",
    SS015139: "Warning!",
    SS015140: "Are you sure to delete a document?",
    SS015141: "Yes",
    SS015142: "No",
    SS015143: "Attachment saved",
    SS015144: "Document uploading…",
    SS015145: "Attaching a document to the report",
    SS015146: "Warning!",
    SS015147: "Are you sure you want to return the report for revision?",
    SS015148: "Yes",
    SS015149: "No",
    SS015150: "Warning!",
    SS015151: "Are you sure you want to change the report status to \"Signed\"?",
    SS015152: "Yes",
    SS015153: "No",
    SS015154: "Sign the electronic report",
    SS015155: "Warning!",
    SS015156: "Are you sure you want to sign the report?",
    SS015157: "Yes",
    SS015158: "No",
    SS003233: "Method of confirming the diagnosis",
    SS003234: "",
    SS003235: "Morphological type and ICD-O code",
    SS003236: "Morphological type",
    SS003237: "Enter a value to search for",
    SS003238: "Information about the clinical stage has been removed",
    SS003239: "Specify clinical staging parameters",
    SS003240: "Specify pathological staging parameters",
    SS003241: "Pathologic stage information deleted",
    SS003242: "clinical stage cTNM",
    SS003243: "T - Primary tumor",
    SS003244: "N - Regional lymph nodes",
    SS003245: "M - Distant metastasis",
    SS003246: "Stage",
    SS003247: "Pathological pTNM staging",
    SS003248: "T - Primary tumor",
    SS003249: "N - Regional lymph nodes",
    SS003250: "M - Distant metastasis",
    SS003251: "Stage",
    SS003252: "G - Histological grading",
    SS003253: "L - Lymphatic invasion",
    SS003254: "V - Venous invasion",
    SS003255: "Pn - Perineural invasion",
    SS003256: "R - Residual tumor",
    SS003257: "Immunohistochemistry results",
    SS003258: "Add",
    SS003259: "Number",
    SS003260: "Date reported",
    SS003261: "Organization",
    SS003262: "Delete",
    SS003263: "Test",
    SS003264: "Add value",
    SS003265: "",
    SS003266: "Molecular genetics results",
    SS003267: "Add",
    SS003268: "No clinical diagnosis",
    SS003269: "Site of distant metastases",
    SS003270: "",
    //Номера после правки дублирования идентификаторов
    SS003900: "New disease", //SS003200
    SS003901: "Create Patient Case", //SS003200
    SS003902: "Disease information", //SS003201
    SS003903: "No records", //SS003201
    SS003904: "No records", //SS003202
    SS003905: "ICD-10", //SS003202

    SS020000: "{Month name wherever it word or abbreviation - in Russian, calendar module - to the Russian}",
    SS020001: "Purpose of the Tumor Board",
    SS020002: "",
    SS020003: "Status of Tumor Board",
    SS020004: "",
    SS020005: "Request",
    SS020006: "Repeated",
    SS020007: "Not specified",
    SS020008: "Allergies",
    SS020009: "Save",
    SS020010: "Date",
    SS020011: "From",
    SS020012: "Till",
    SS020013: "Event",
    SS020014: "All",
    SS020015: "",
    SS020016: "Organization",
    SS020017: "All",
    SS020018: "Date",
    SS020019: "Not specified",
    SS020020: "Event",
    SS020021: "",
    SS020022: "Not specified",
    SS020023: "",
    SS020024: "Organization",
    SS020025: "Description",
    SS020026: "Documents",
    SS020027: "Add",
    SS020028: "Edit document",
    SS020029: "New document",
    SS020030: "report ",
    SS020031: "* - required fields",
    SS020032: "Selection of the diagnosis",
    SS020033: "Add a new diagnosis",
    SS020034: "or choose from the already known",
    SS020035: "Patient diagnoses",
    SS020036: "Create Patient Case",
    SS020037: "Clinical status:",
    SS020038: "{Month}",
    SS020039: "Oncologist:",
    SS020040: "Not specified",
    SS021001: "TB time",
    SS021002: "Case time",
    SS021003: "{Month}",
    SS021004: "Number of cases",
    SS021005: "My cases",
    SS021006: "Number of free slots",
    SS021007: "Create Patient Case",
    SS022001: "A new group",
    SS022002: "A group from template",
    SS022003: "",
    SS022004: "ChairPerson",
    SS022005: "Secretary",
    SS022006: "Surgical oncologist",
    SS022007: "Medical oncologist",
    SS022008: "Radiation oncologist",
    SS022009: "Additional participant",
    SS022010: "Unknown number",
    SS022011: "ChairPerson",
    SS022012: "Secretary",
    SS022013: "Surgical oncologist",
    SS022014: "Medical oncologist",
    SS022015: "Radiation oncologist",
    SS022016: "Additional participant",
    SS022017: "Sort by",
    SS022018: "{Status, such as \"In\"}",
    SS022019: "{specialty}",
    SS022020: "{block - analog}",
    SS023001: "Presentation mode",
    SS023002: "{Month}",
    SS023003: "Missing",
    SS023004: "Complete the review",
    SS023005: "Recommended diagnostic tests",
    SS023006: "",
    SS023007: "Absent",
    SS023008: "Absent",
    SS023009: "Absent",
    SS023010: "Absent",
    SS023011: "Date confirmed:",
    SS023012: "Oncologist:",
    SS023012A: "Doctor:",
    SS023013: "Date confirmed:",
    SS023014: "Ready for consideration",
    SS023015: "Documents",
    SS023016: "Warning!",
    SS023017: "Cancel changes?",
    SS023018: "Yes",
    SS023019: "No",
    SS023020: "{block - analog}",
    SS023021: "Added point",
    SS023022: "",
    "SS023022-1": "Does not participate in the Tumor Board",
    "SS023022-2": "The attending physician",
    "SS023022-3": "Chair person",
    "SS023022-4": "Secretary",
    "SS023022-5": "Expert",
    "SS023022-6": "External Consultant",
    SS023023: "{Month}",
    SS023024: "Provide ICD 10 disease code",
    SS023025: "{Month}",
    SS023026: "Examination plan",
    SS023027: "",
    SS023028: "Warning!",
    SS023029: "Disease patient {patientName} {conditionCode} is not considered a consultation on \"{appointmentName}\". Still add it?",
    SS023030: "Yes",
    SS023031: "No",
    SS023032: "Patient case \"{patientName}\" created",
    SS024001: "IDs",
    SS024002: "Add ID",
    SS024003: "Type",
    SS024004: "Select the type",
    SS024005: "HIS login",
    SS024006: "Value",
    SS024007: "Delete ID",
    SS024008: "Select a position",
    SS024009: "",
    SS024010: "Password saved. Enter a new password to change",
    SS024011: "Delete",
    SS024012: "Save",
    SS024013: "report cancelaltion",
    SS024014: "Archiving your Treatment Templates",
    SS024015: "Archiving Treatment Templates",
    SS024016: "Add comments/dissenting opinion on a TB report",
    SS024017: "Add a new team/members",
    SS024018: "Add/edit medication prescriptions",
    SS024019: "Access to the Documents ",
    SS024020: "Access to the Patient Cases",
    SS024021: "Access to the Tumor Boards",
    SS024022: "Access to the \"Organizations\" page",
    SS024023: "Access to the Treatment Plans",
    SS024024: "Access to the TB Records",
    SS024025: "Access to the Workspace",
    SS024026: "Access to the Employees",
    SS024027: "Access to the Treatment Templates",
    SS024028: "Access to the \"All patients\" page by clicking the \"Select patient\" button\"",
    SS024029: "Fill in the \"Summary by Day\"",
    SS024030: "Fill in the \"Treatment status\"",
    SS024031: "Fill in the \"Treatment status only for your Treatment Cycle\"",
    SS024032: "Fill in the \"Start date and duration of the cycle\"",
    SS024033: "Fill in the \"Cycle Name\"",
    SS024034: "Fill in the \"Current patient status\"",
    SS024035: "Launch a Patient Case presentation ",
    SS024036: "Change the case status to \"Approved\", all cases",
    SS024037: "Change the case status to \"Approved\", my cases",
    SS024038: "Change the case status to \"Cancelled\"",
    SS024039: "Change case status to \"Completed\" (the availability of the \"Make a conclusion\" button), all",
    SS024040: "Change case status to \"Completed\" (the availability of the \"Make a conclusion\" button), your own",
    SS024041: "Appointment of an expert to the Tumor Board",
    SS024042: "Leave a comment in the case on the \"Comment\" tab, all",
    SS024043: "Leavie a comment in the case on the \"Comment\" tab, only your own cases",
    SS024044: "Display avatar in the title",
    SS024045: "Display the action bar in the header",
    SS024046: "Display the UpToDate form in the header",
    SS024047: "Switch of the Treatment Plan to the status Completed",
    SS024048: "Switch the Cycle to the \"In Progress\" status",
    SS024049: "Switch the Cycle to the \"Completed\" status",
    SS024050: "Switch the Cycle to the \"Assigned\" status",
    SS024051: "Switch the Cycle to the \"Cancelled\" status",
    SS024052: "Switch the Cycle to the \"Aborted\" status",
    SS024053: "Switch the Cycle to the \"Suspended\" status",
    SS024054: "Switch the loop to the \"Deleted\" status",
    SS024055: "Switch to the Treatment Cycle from the patient's record",
    SS024056: "Printing a Treatment Plan report",
    SS024057: "Print the Treatment Cycle report",
    SS024058: "Confirm the Tumor Board reports by electronic digital signature",
    SS024059: "Confirm all precriptions at once",
    SS024060: "Get the electronic version of the report",
    SS024061: "Get the electronic version of the report (only for the expert)",
    SS024062: "Attach a PDF with a scan of the Tumor Board report, all reports",
    SS024063: "Attach a PDF with a scan of the Tumor Board report, your own reports",
    SS024064: "View all Treatment Cycle tabs based on the Treatment Cycle status",
    SS024065: "View all cases Tumor Board where invited and search for cases using filters",
    SS024066: "View all existing patient cases and search for a request using filters",
    SS024067: "View all existing Tumor Boards",
    SS024068: "View the case for the Tumor Boards",
    SS024069: "View the case for the Tumor Board  where you are invited as an expert",
    SS024070: "View Patient Cases and Reports in the modal window",
    SS024071: "View Patient Cases via the Tumor Board list interface (read only), all",
    SS024072: "View Patient Cases for the Tumor Board through the interface of the Tumor Board list (read only), to the tumor Board of which you are invited as a participant",
    SS024073: "View Tumor Board Patient Cases via the Tumor Board list interface (read only), your own",
    SS024074: "View a patient's profile",
    SS024075: "View the Tumor Board to which you are invited as a participant (Your own)",
    SS024076: "View the content of the Documents page\"",
    SS024077: "View the content of the \"Organizations\" page",
    SS024078: "View the content of the Desktop page\"",
    SS024079: "View the content of the \"Employees\" page\"",
    SS024080: "View medication prescriptions in the modal window",
    SS024081: "View hospital",
    SS024082: "View Tumor Board's report",
    SS024083: "View an employee",
    SS024084: "View the list of patients and search for patients in the list using filters",
    SS024085: "View the list of patients and search for patients in the list using filters in the Treatment Plans section",
    SS024086: "View Patient's Treatment Plans list",
    SS024087: "View Tumor Board's reports list",
    SS024088: "View a list of your reports/on which you are marked as a participant",
    SS024089: "View the Tumor Board team",
    SS024090: "Item in the menu of teams/lineups of participants",
    SS024091: "Edit the \"Treatment Results\" tab",
    SS024092: "Edit the \"Treatment Totals\" tab only for your own Treatment Cycle",
    SS024093: "Edit a Patient Case",
    SS024094: "Edit a Patient Case",
    SS024095: "Edit a Patient's Profile",
    SS024096: "Edit Tumor Board",
    SS024097: "Edit hospital",
    SS024098: "Edit the parameters of your Treatment Templates in the Treatment Templates section",
    SS024099: "Edit Treatment Template parameters in the Treatment Templates section",
    SS024100: "Edit the \"Totals\" field on the \"Treatment Totals\" tab",
    SS024101: "Edit the report of the Tumor Board, all reports",
    SS024102: "Edit the report of the Tumor Board to which one is invited",
    SS024103: "Edit the report of the Tumor Board, all reports",
    SS024104: "Edit an employee",
    SS024105: "Create a diagnosis in the \"Patient Treatment Plans\" section",
    SS024106: "Create a task for the patient (diagnostic tests)",
    SS024107: "Create/Copy Treatment Cycles in a \"Treatment Plan\"",
    SS024108: "Creation of a new Tumor Board and editing it",
    SS024109: "Create new patient",
    SS024110: "Create new patient case",
    SS024111: "Create hospital",
    SS024112: "Create treatment Plan",
    SS024113: "Create a Treatment Plan from a patient's profile",
    SS024114: "Createan employee",
    SS024115: "Save a Treatment Plan to a Template",
    SS024116: "Save your \"Treatment Plan\" to a \"Template\"",
    SS024117: "Delete Patient Cases for the Tumor Board, All",
    SS024118: "Delete Patient Cases for the Tumor Board, your own",
    SS024119: "Delete \"Tumor Board\"",
    SS024120: "Delete organization",
    SS024121: "Delete \"Treatment Plan\"",
    SS024122: "Delete your \"Own Treatment Templates\"",
    SS024123: "Delete \"Employees\"",
    SS024124: "Delete \"Treatment Templates\"",
    SS024125: "Manage user roles",
    SS024126: "Manage user roles (menu item)",
    SS024127: "Create a Tumor Board report",
    SS024128: "Error while saving attachments",
    SS024129: "New document",
    SS024130: "Add",
    SS025001: "",
    SS025002: "",
    SS025003: "Language",
    SS025004: "Home",
    SS025005: "Select patient",
    SS025006: "Patient information",
    SS025007: "Tumor Board Case ",
    SS025008: "Tumor Board Records",
    SS025009: "Treatment plans",
    SS025010: "Employees",
    SS025011: "Edit an employee",
    SS026001: "Generation of reports",
    SS026002: "days",
    SS026003: "Generating report...",
    SS026004: "Reporting period:",
    SS026005: "Organization, conducted Tumor Board",
    SS026006: "Number of patients\npassed through Tumor Board",
    SS026007: "Including newly diagnosed",
    SS026008: "Including the number of patients sent for further examination",
    SS026009: "The patient is directed to the treatment, cases",
    SS026010: "Capital Region hospital",
    SS026011: "Capital hospital",
    SS026012: "National hospitals",
    SS026013: "Type of treatment, cases",
    SS026014: "Surgical",
    SS026015: "ACT ",
    SS026016: "Beam",
    SS026017: "Combined",
    SS026018: "Number of Tumor Boards conducted using telemedicine technologies",
    SS026019: "Organization",
    SS026020: "medical director",
    SS026021: "Page {Number} of {number}",
    SS026022: "Residence address:",
    SS026023: "Phone number:",
    SS026024: "Privilege code:",
    SS026025: "Tumor Board using TM technology",
    SS026026: "",
    SS026027: "ChairPerson",
    SS026028: "Secretary",
    SS026028A: "Attending physician",
    SS026029: "Surgical oncologist",
    SS026030: "Medical oncologist",
    SS026031: "Radiation oncologist",
    SS026032: "Additional participant",
    SS026033: "Telepresence",
    SS026034: "Oncologist:",
    SS026035: "There are no",
    SS026036: "There are no",
    SS026037: "There are no",
    SS026038: "Description",
    SS026039: "",
    SS026040: "Recommended location of care",
    SS026041: "The National health hospital",
    SS026042: "In Capital Region hospital",
    SS026043: "To the hospital of another object a National facility",
    SS026044: "Select",
    SS026045: "Treatment prognosis",
    SS026046: "Favorable",
    SS026047: "Doubtful",
    SS026048: "Adverse",
    SS026049: "Recommend diagnostic method",
    SS026050: "Tumor Board Team",
    SS026051: "Add members to Tumor Board",
    SS026052: "Organization",
    SS026053: "Select organization",
    SS026054: "New organization",
    SS026055: "Select organization",
    SS026056: "Employee",
    SS026057: "Select an employee",
    SS026058: "New employee",
    SS026059: "Selecting an employee",
    SS026060: "Position",
    SS026061: "Select position",
    SS026062: "New position",
    SS026063: "Select a position",
    SS026064: "Specialization",
    SS026065: "Specialization",
    SS026066: "Telepresence",
    SS026067: "Add",
    SS026068: "Save",
    SS026069: "Clear",
    SS026070: "Close",
    SS026071: "Select organization",
    SS026072: "Select an employee",
    SS026073: "Select position",
    SS026074: "ChairPerson",
    SS026075: "Secretary",
    SS026076: "Surgical oncologist",
    SS026077: "Medical oncologist",
    SS026078: "Radiation oncologist",
    SS026079: "Additional participant",
    SS026080: "Delete slot",
    SS026081: "ICD-10",
    SS026082: "ICD-10 code or name",
    SS026083: "Enter a value to search for",
    SS026084: "All diseases were spread",
    SS026085: "Surgical treatment",
    SS026086: "Planned date of hospitalization",
    SS026087: "Event location",
    SS026088: "In hospitals",
    SS026089: "The information material contained in the documents provided is sufficient to make a decision on the choice of treatment. \nThere is no need to transfer materials or obtain additional data.",
    SS026090: "Medication",
    SS026091: "dd.mm.yyyy",
    SS026092: "Planned duration (months)",
    SS026093: "Event location",
    SS026094: "In hospitals",
    SS026095: "In the day hospital",
    SS026096: "Outpatient",
    SS026097: "Radiation therapy",
    SS026098: "The planned start date",
    SS026099: "Event location",
    SS026100: "In hospitals",
    SS026101: "In the day-care hospital",
    SS026102: "Chemoradiation treatment",
    SS026103: "Complex",
    SS026104: "Observation",
    SS026105: "Palliative care",
    SS026106: "Surgical treatment",
    SS026107: "Medication",
    SS026108: "Radiation therapy",
    SS026109: "Chemoradiation treatment",
    SS026110: "Complex (except for chemoradiation)",
    SS026111: "Observation",
    SS026112: "Palliative care",
    SS027001: "Done",
    SS027002: "",
    SS027003: "",
    SS027004: "An error occurred while reading the data, please try again later",
    SS027005: "An error occurred while reading the data, please try again later",
    //Переведено 19.08.21 - 31.08.21
    SS90001: "The start time of medication must be before its end time",
    SS90002: "The completion time of medication must be later than its start time",
    SS90003: "The Treatment Plan is not created. The treatment start date must be after the patient's birth date ",
    SS90004: "Unable to confirm a medication prescription with a dosage of 0",
    SS90005: "The serum creatinine level indicated is outside the expected normal range. Check the result and its reference values in the patient's study.\n\nNormal blood creatinine is - ",
    "SS90005-1": "children under 1 year of age: 18—35 μmol/L",
    "SS90005-2": "children 1 to 14 years of age: 27—62 μmol/L",
    "SS90005-3": "men: 74.0—110.0 μmol/L",
    "SS90005-4": "women: 44.0—80.0 μmol/L",
    SS90006: "New position",
    SS90007: "Are you sure to make a copy of the {roleName} role?",
    SS90010: "Clinical diagnosis",
    SS90011: "Diagnosis status",
    SS90012: "Diagnosis confirmation method",
    SS90013: "Date confirmed",
    SS90014: "Name of physician",
    SS90015: "Primary tumor",
    SS90016: "Affected side",
    SS90017: "Morphological type and ICD-O code",
    SS90018: "Clinical stage cTNM",
    SS90019: "Pathological stage pTNM",
    SS90020: "Site of distant metastases",
    SS90021: "Immunohistochemistry results",
    SS90022: "Molecular genetics results",
    SS90023: "Disease complications",
    SS90024: "Additional information",
    SS90030: "This Tumor Board has no examination plan items",
    SS90040: "Forming Tumor Board report printed form",
    SS90050: "Surveys performed",
    SS90060: "Diagnostic method",
    SS90061: "Event location",
    SS90062: "Delete",
    SS90070: "Organization",
    SS90071: "Doctor's Full Name",
    SS90072: "Doctor's Full Name*",
    SS90073: "Position",
    SS90074: "Choose a Specialization",
    SS90075: "New Specialization",
    SS90076: "Selection of specialization",
    SS90090: "Commentary on surgical treatment",
    SS90091: "Commentary on drug therapy",
    SS90092: "Commentary on radiation therapy",
    SS90093: "Commentary on chemotherapy",
    SS90094: "Commentary on combinated therapy",
    SS90095: "Commentary on observation",
    SS90096: "Commentary on palliative care ",
    SS90097: "Commentary on other treatments",

    //Переводы за 3.09.21
    //28
    SS91132: "Specify last name",
    SS91133: "Specify first name",
    SS91134: "Specify patient’s gender",
    SS91135: "Specify Social Security Number",
    SS91136: "Specify the Coverage Type",
    SS91137: "Specify coverage document",
    SS91138: "Fill in the e-mail",
    SS91139: "Enter the phone",
    SS91140: "Specify a disability",
    SS91141: "Specify a valid e-mail address",
    //29
    SS91142: "The patient has no disease",
    //30
    SS91143: "Allergies",
    SS91144: "Medications",
    //31
    SS91145: "Date",
    SS91146: "Event",
    SS91147: "Date from",
    SS91148: "Date up to ",
    SS91149: "Organization",
    SS91150: "Organization",
    SS91151: "Description",
    SS91152: "The documents",

    //Переводы за 7.09.21 - 13.09.21
    SS92200: "Substitution history",
    SS92201: "Full name",
    SS92202: "Dates",
    SS92203: "Search",
    SS92204: "Full name",
    SS92205: "Position",
    SS92206: "Start and end dates of substitutions",
    SS92207: "Warning!",
    SS92208: "A document has been added to this Examination plan item. Do you want to delete the item anyway?",
    SS92209: "Tumor board is in progress",
    SS92210: "Specify surname",
    SS92211: "Specify first name",
    SS92212: "Specify birth date",
    SS92213: "Specify Social Security Number",
    SS92220: "Select patient",
    SS92221: "Patient information",
    SS92222: "Health Diary",
    SS92223: "Health diary data are absent for the patient does not use the application",
    SS903214: "Treatment regimen title",
    SS903215: "Type of treatment",
    SS903216: "The start date of treatment",
    SS903217: "The end date of treatment",
    SS903218: "Status",
    SS903219: "The patient has {number} more treatment plan(s)",
    SS903220: "Tumor Boards",
    SS903221: "Code of the disease in the ICD-10",
    SS903222: "ICD-10",
    SS903223: "Event location",
    SS903224: "Tumor Board Purpose",
    SS903225: "Date of event",
    SS903226: "Tumor Board status",
    SS903227: "Create a report",
    SS903228: "Create a case",
    SS903229: "Enter a search value",
    SS903230: "Enter dosing schedule",
    SS903231: "Save",
    SS903232: "Delete",
    SS903233: "Select reaction type",
    SS903234: "Hide the choice of reaction type",
    SS903235: "Health diary data is missing, since the patient does not use the application",
    SS903236: "Warning!",
    SS903237: "Delete attachment {fileName}?",
    SS903238: "Yes",
    SS903239: "No",
    SS903240: "Select the start date of substitution",
    SS903241: "Specify the valid e-mail address",
    SS903242: "A change of the hospital type is not possible, as this hospital has branches, structural units",
    SS903243: "Structural units (branches):",
    SS903244: "Type",
    SS903245: "Value",
    SS903246: "Type",
    SS903247: "Purpose",
    SS903248: "Index",
    SS903249: "Country",
    SS903250: "Region",
    SS903251: "District",
    SS903252: "Locality",
    SS903253: "Address",
    SS903254: "Delete the coordinates",
    SS903255: "Number of free slots",
    SS903256: "Number of cases",
    SS903257: "Transferring a case",
    SS903258: "Address of the organization",
    SS903259: "Group editing",
    SS903260: "Name",
    SS903261: "Change",
    SS903262: "Cancel",
    SS903263: "Warning!",
    SS903264: "Are you sure to delete a group?",
    SS903265: "Yes",
    SS903266: "No",
    SS903267: "Warning!",
    SS903268: "Are you sure to delete a possible examination?",
    SS903269: "Yes",
    SS903270: "No",
    SS903271: "Select cases to be deleted",
    SS903272: "Warning!",
    SS903273: "Are you sure to delete selected cases?",
    SS903274: "Yes",
    SS903275: "No",
    SS903276: "What Tumor Boards to be removed?",
    SS903277: "Warning!",
    SS903278: "Delete all subsequent Tumor Boards?",
    SS903279: "Yes",
    SS903280: "No",
    SS903281: "Organization",
    SS903282: "Organization",
    SS903283: "Specify the organization",
    SS903284: "File {fileName} successfully loaded",
    SS903285: "Cancel the membership of the participants of the Tumor Boards {title} from {date}?",
    SS903286: "Organization",
    SS903287: "Doctor's full name",
    SS903288: "Doctor's full name",
    SS903289: "Select specialization",
    SS903290: "NEW SPECIALIZATION",
    SS903291: "CHOICE OF SPECIALIZATION",
    SS903292: "Loading information",
    SS903293: "Employee full name",
    SS903294: "Employee full name",
    SS903295: "Select Specialization",
    SS903296: "Delete",
    SS903297: "Warning!",
    SS903298: "Delete record?",
    SS903299: "Yes",
    SS903300: "No",
    SS903301: "Enter a search value",
    SS903302: "Enter dosing schedule",
    SS903303: "Save",
    SS903304: "Delete",
    SS903305: "Select the type of reaction",
    SS903306: "Results of studies conducted",
    SS903307: "Tumor Board has no examination plan points",
    SS903308: "Warning!",
    SS903309: "Delete document {fileName}?",
    SS903310: "Yes",
    SS903311: "No",
    SS903312: "Remove slide",
    SS903313: "Results of performed eхaminations",
    SS903314: "Information about the taken drug has been successfully saved",
    SS903315: "Information about  the taken drug successfully removed",
    SS903316: "Results of performed examinations",
    SS903317: "Results of performed examinations",
    SS903318: "Are you sure you want to cancel the report?",
    SS903319: "Are you sure you want to return the report to be signed?",

    //Переводы добавленные 20.09.21
    SS904000: "Latest first",
    SS904001: "Coverage Document",
    SS904002: "SSN",
    SS904003: "Creation date&time",
    SS904004: "Referring organisation",
    SS904005: "Attending physician",
    SS904006: "Date of the Tumor Board",
    SS904007: "Date of Birth",
    SS904008: "Telephone Number",
    SS904009: "Name of the Tumor Board",
    SS904010: "Tumor Board location",
    SS904011: "The purpose of the Tumor Board:",
    SS904012: "ECOG status",
    SS904013: "Karnofsky index",
    SS904014: "Weight",
    SS904015: "Height",
    SS904016: "Serum creatinine [mmol/l]",
    SS904017: "BSA by Mosteller",
    SS904018: "Examination plan",
    SS904019: "No records",
    SS904020: "Case saved",
    SS904021: "Complete consideration",
    SS904022: "Board Members",
    SS904023: "ChairPerson",
    SS904024: "Secretary",
    SS904025: "Attending physician",
    SS904026: "Surgical oncologist",
    SS904027: "Medical oncologist",
    SS904028: "Radiation oncologist",
    SS904029: "Address of residence:",
    SS904030: "Phone Number:",
    SS904031: "Privilege code:",
    SS904032: "Tumor Board with the use of telemedicine technologies",
    SS904033: "",
    SS904034: "Cancer history",
    SS904035: "Conclusions (consultations) of specialists: ",
    SS904036: "extracts from medical documents",
    SS904037: "copies of laboratory results:",
    SS904038: "copies of instrumental results:",
    SS904039: "Where the patient is reffered to",
    SS904040: "Select",
    SS904041: "Prognosis",
    SS904042: "Cases",
    SS904043: "Diagnosis",
    SS904044: "Anamnesis of the primary disease",
    SS904045: "General condition",
    SS904046: "General condition",
    SS904047: "Anamnesis of the primary diseas",
    SS904048: "Hide full name of a patient",
    SS904049: "Date confirmed:",
    SS904050: "Oncologist:",
    SS904051: "Specify a date of birth",
    SS904052: "SSN format is not correct (checksum error)",
    SS904053: "SSN must be {digitCount} digits long",
    SS904054: "Coverage Document Number must be {digitCount} digits long",
    SS904055: "Coverage Document Number must be 9 digits long",
    SS904056: "Phone number must be 11 digits long",
    SS904057: "Specify the event location address",
    SS904058: "Choose at least one day of the week",
    SS904059: "Fill in the period of event",
    SS904060: "Information is downloading",
    SS904061: "Go to",
    SS904062: "Choosing a notification type",
    SS904063: "Date",
    SS904064: "Unread only",
    SS904065: "Clear",
    SS904066: "Apply",
    SS904067: "Specify the Tumor Board Purpose",
    SS904068: "You did not specify an anamnesys of the primary disease",
    SS904069: "You have not specified a general condition",
    SS904070: "Warning!",
    SS904071: "Undo changes?",
    SS904072: "Yes",
    SS904073: "No",
    SS904074: "Warning!",
    SS904075: "Delete record?",
    SS904076: "Yes",
    SS904077: "No",
    SS904078: "Case has been updated",
    SS904079: "Returned for revision",
    SS904080: "Cancelled",
    SS904081: "The comment is required",
    SS904082: "Comment",
    SS904083: "Prepare for the consideration",
    SS904084: "Completing the consideration",
    SS904085: "The type of multiple primary tumor is not specified",
    SS904086: "You have not specified the minimum number of nested diseases (2)",
    SS904087: "Diagnosis status for {mkb10}: {name} is not specified",
    SS904088: "It is not specified whether the tumor is the primary one",
    SS904089: "Date confirmed is not specified",
    SS904090: "The affected side is not specified",
    SS9040610: "Specify morphological type and ICD-O code",
    SS904092: "Fill in the clinical and/or pathoanatomical stage",
    SS904093: "Not all clinical stage factors are specified",
    SS904094: "Site of distant metastases is not specified",
    SS904095: "Not all factors of the pathoanatomical stage are indicated",
    SS904096: "ICD-10 not specified",
    SS904097: "Clinical diagnosis is not specified",
    SS904098: "Diagnosis status is not specified",
    SS904099: "Date confirmed",
    SS904100: "The affected side is not specified",
    SS904101: "Specify the histological type",
    SS904102: "Fill in the clinical and/or pathoanatomical stage",
    SS904103: "Not all clinical stage factors are specified",
    SS904104: "Not all factors of the pathoanatomical stage are indicated",
    SS904105: "Not all factors of the pathoanatomical stage are indicated",
    SS904106: "The IHC test results are incompletely filled",
    SS904107: "Date field  cannot be empty",
    SS904108: "\"Document type\" field  cannot be empty",
    SS904109: "\"Document type\" field  cannot be empty",
    SS904110: "Specify the Tumor Board ChairPerson",
    SS904111: "Specify attending fisician",
    SS904112: "Indicate the secretary of the Board",
    SS904113: "Specify a surgical treatment specialist",
    SS904114: "Specify a radiotherapy specialist",
    SS904115: "Select employee",
    SS904116: "Select a role",
    SS904117: "Select organization",
    SS904118: "Fill in documents submitted",
    SS904119: "Fill in anamnesis of disease",
    SS904120: "Fill in the field \"Where the patient is reffered to\"",
    SS904121: "Treatment tactic for a cancer disease {oncology} is not specified",
    SS904122: "Complete the case",
    SS904123: "Mark one of the neoplasms as the Primary tumor. Edit nested diseases",
    SS904124: "There should be only one neoplasm marked as a Primary tumor. Edit nested diseases",
    SS904125: "Attachment saved",
    SS904126: "Fill in the Diagnostic method field",
    SS904127: "Fill in the Event location field",
    SS904128: "Treatment tactic for a cancer disease is not specified",
    SS904129: "Returning for revision",
    SS904130: "Raise the slide",
    SS904131: "Lower the slide",
    SS904132: "Reason for return",
    SS904133: "Fill in the general condition",

    SS904159: "Primary tumor",
    SS904160: "Diagnostic method",
    SS904161: "Event location",
    SS904162: "Time",
    SS904163: "Time",
    SS904164: "Mark all as read",
    SS904165: "View all notifications",

    SS904166: "My cases",

    SS904167: "Today",
    SS904168: "Yesterday",
    SS904169: "Next 7 days",
    SS904170: "Next 14 days",
    SS904171: "Next 30 days",
    SS904172: "Current month",
    SS904173: "Apply",
    SS904174: "Cancel",

    //Переводы 27.09 - 28.09.21
    SS902240: "Approve",
    SS902241: "Select organization",
    SS902242: "Employees",
    SS902243: "Patients",
    SS902244: "Organizations",
    SS902245: "Cases",
    SS902246: "Treatment plans",
    SS902247: "{treatment regiment datadase}",
    SS902248: "{status daatabase}",
    SS902249: "Phone:",
    SS902250: "Phone:",
    SS902251: "TB case",
    SS902252: "No reseach plan",
    SS902253: "TB has no items of the research plan",
    SS902254: "Warning!",
    SS902255: "A document has been added to this item in the reserch plan Delete the item anyway?",
    SS902256: "Yes",
    SS902257: "No",
    SS902258: "Examination plan before Tumor Board",
    SS902259: "Confirmation status",
    SS902260: "Preliminary",
    SS902261: "Confirmed",
    SS902262: "Name of physician",
    SS902263: "Description of the nosological form",
    SS902264: "Create/modify a schedule",
    SS902265: "Creation or modification of schedule items is in progress. Do not close this browser page until the end of the process",
    SS902266: "Case transfer - {conditions} - {patientName} ({patientGender} {patientAge})",
    SS902267: "Tumor Board name",
    SS902268: "ICD-10 code or disease name",
    SS902269: "Organization",
    SS902270: "Empty slot",
    SS902271: "Current location",
    SS902272: "Transfer",
    SS902273: "Cancel",
    SS902274: "Specify private phone number",
    SS902275: "Patient selection",
    SS902276: "TB reports",

    SS902278: "Employees",

    SS902289: "Change status",
    SS902290: "Prepared for review",
    SS902291: "Send a case for approval?",
    SS902292: "Sending for approval",

    SS902299: "Return \"For signing\"",
    SS902300: "Tumor Board",
    SS902301: "Login",
    SS902302: "Password",
    SS902303: "Next",
    SS902304: "Hello,",
    SS902305: "Organisation",
    SS902306: "Position",
    SS902307: "Logging in",
    SS902308: "Changing password",
    SS902309: "Enter your e-mail address for instructions on how to reset your password",
    SS902310: "E-mail",
    SS902311: "Send",
    SS902312: "Tumor Board: Password Change",
    SS902313: "Changing password",
    SS902314: "To change your password, follow the link:",
    SS902315: "Change password",
    SS902316: "Change hospital/position",
    SS902317: "Patient has no treatment plans",
    SS902318: "The patient has no diseases",
    SS902319: "Multiple primary tumor type",
    SS902320: "Patient information",
    SS902321: "Allergy information successfully saved",
    SS902322: "SELECT A RESULT",
    SS902323: "Document review",
    SS902324: "Document review",
    SS902325: "A patient with such SSN is already registered in the system",
    SS902326: "Full name",
    SS902327: "Coverage document",
    SS902328: "SSN",
    SS902329: "Telephone:",
    SS902330: "A patient with such coverage document is already registered in the system",

    SS902364: "My cases",
    SS902365: "Urgently!",
    SS902366: "Urgently!",
    SS902367: "Generating a case",
    SS902368: "I'm a participant",
    SS902369: "No free slots Add an overweight case and mark it as urgent?",
    SS902370: "Urgently!",
    SS902371: "It is impossible to add an application for a TB in excess of the established norm since the TB has already begun",

    SS902372: "Last month",
    SS902373: "Last 14 days",
    SS902374: "Last 7 days",
    SS902375: "Tomorrow",
    SS902376: "NEW ORGANIZATION",
    SS902377: "NEW RESULT",
    SS902378: "SELECT A RESULT",

    SS902421: "Edit a contact",
    SS902422: "Refresh",

    SS902500: "Full name",
    SS902501: "Coverage document",
    SS902502: "SSN",
    SS902503: "Cycles",
    SS902504: "Cycle name",
    SS902505: "Duration (days)",
    SS902506: "Start date",
    SS902507: "End date",
    SS902508: "Status",
    SS902509: "Phone:",
    SS902510: "Urgently!",
    SS902511: "Organization",
    SS902512: "Team of participant saved successfully ",
    SS902513: "Disease removed",
    SS902514: "Are you sure to delete the disease {code}?",
    SS902515: "Disease saved",
    SS902516: "Specify the order of medication",
    SS902517: "Yes",
    SS902518: "No",
    SS902519: "Edit document",
    SS902520: "Patient with this phone number is already registered in the system",
    SS902521: "Full name",
    SS902522: "Coverage document",
    SS902523: "SSN",
    SS902524: "Calendar event for a Mobile App patient has been created based on the approved case",
    SS902525: "Specify a minimum of 2 nested illnesses",
    SS902526: "Click to add neoplasm",
    SS902600: "Full name",
    SS902601: "Coverage document",
    SS902602: "SSN",
    SS902603: "Cycles",
    SS902604: "Cycle name",
    SS902605: "Duration (days)",
    SS902606: "Start date",
    SS902607: "End date",
    SS902608: "Status",
    SS902609: "Phone:",
    SS902610: "Urgently!",
    SS902611: "Organization",
    SS902612: "Team of participant saved successfully ",
    SS902613: "Disease removed",
    SS902614: "Are you sure to delete the disease {code}?",
    SS902615: "Disease saved",
    SS902616: "Specify the order of medication",
    SS902617: "Yes",
    SS902618: "No",
    SS902619: "Edit document",
    SS902620: "Patient with this phone number is already registered in the system",
    SS902621: "Full name",
    SS902622: "Coverage document",
    SS902623: "SSN",
    SS902624: "Calendar event for a Mobile App patient has been created based on the approved case",
    SS902625: "Specify a minimum of 2 nested illnesses",
    SS902626: "Click to add neoplasm",
    SS902630: "Incorrect e-mail address",
    SS902631: "Login",
    SS902632: "Password",
    SS902633: "Next",
    SS902634: "Tumor Board",
    SS902635: "Change password.",
    SS902636: "To change the password, click on the link:",
    SS902637: "Change password",
    SS902638: "No user with this e-mail address was found. ",
    SS902639: "Check the e-mail address you've specified.",
    SS902640: "Instructions on resetting password were sent to that e-mail address",
    SS902641: "Changing the password",
    SS902642: "Enter your e-mail address to receive instructions on password changing",
    SS902643: "Send",
    SS902644: "Login",
    SS902645: "Password",
    SS902646: "Next",
    SS902647: "Instructions to reset your password were sent to the specified e-mail address",
    SS902648: "Incorrect login and/or password",
    SS902649: "Forget your password?",
    SS902650: "Incorrect login and/or password",
    SS902651: "Hello,",
    SS902652: "Organization",
    SS902653: "Job title",
    SS902654: "Log in",

    SS902656: "Enter a new password",
    SS902657: "Confirm a new password",
    SS902658: "Neoplasm removed",
    SS902659: "Are you sure you want to remove neoplasm {code}?",
    SS902660: "Save",

    //Переводы 1.10 - 4.10.21
    SS902663: "Document signing",
    SS902664: "Document signing is in progress....",
    SS902665: "Edit patient data", // NOT USED
    SS902666: "Last name",
    SS902667: "Name",
    SS902668: "Patronymic name",
    SS902669: "Date of Birth",
    SS902670: "Gender",
    SS902671: "SSN",
    SS902672: "Coverage document type",
    SS902673: "Coverage document number",
    SS902674: "E-mail",
    SS902675: "Phone",
    SS902676: "Disability",
    SS902677: "Address",
    SS902678: "Save",
    SS902679: "Confirmation needed",
    SS902680: "Need to confirm phone number {phone}",
    SS902681: "Send сode",
    SS902682: "Enter confirmation code sent to {phone}",
    SS902683: "Code from SMS",
    SS902684: "Confirm",
    SS902685: "A patient with such SSN is already registered in the system",
    SS902686: "Full name",
    SS902687: "Coverage document",
    SS902688: "SSN",
    SS902689: "Create a report",

    SS902697: "Case cancellation ...",
    SS902698: "Case deletion...",
    SS902699: "Type of primary multiple tumor",
    SS902700: "Activity",
    SS902701: "Show:",
    SS902702: "All",
    SS902703: "Comments ",
    SS902704: "History",
    SS902705: "Nothing found. Try changing search terms",
    SS902706: "min.",
    SS902707: "Uploading the diseases...",

    //Переводы 6.10.21
    SS902721: "Found a Mobile App patient with the same phone",
    SS902722: "Yes",
    SS902723: "No",
    SS902724: "Link the patient being created to the found Mobile App patient?",
    SS902725: "Patient's Tumor Board phone number doesn't foumd",
    SS902726: "Not specified",
    SS902727: "Add a record",
    SS902728: "Add a record",
    SS902730: "Transmission of document data...",
    SS902731: "The Electronic Signature agent is ready to work and has accepted the parameters for signing the documents",
    SS902732: "The Electronic Signature agent notifies that a document {documentId} has been signed",

    //Переводы 7.10.21
    SS902735: "Delete ID",

    //Переводы 8.10.21
    SS902739: "Presentation",

    SS9035756: "Uploading of employee information",
    SS9035757: "Warning!",
    SS9035758: "Are you sure you want to delete {employeeName}?",
    SS9035759: "Yes",
    SS9035760: "No",
    SS9035761: "Specify a value",

    SS9035764: "Warning!",
    SS9035765: "Do you really want to delete {organizationName}?",
    SS9035766: "Yes",
    SS9035767: "No",
    SS9035768: "Warning!",
    SS9035769: "Are you sure to delete {system} {value}?",
    SS9035770: "Yes",
    SS9035771: "No",
    SS9035772: "Warning!",
    SS9035773: "Are you sure to remove URL {link}?",
    SS9035774: "Yes",
    SS9035775: "No",
    SS9035776: "Warning!",
    SS9035777: "Are you sure to delete the address?",
    SS9035778: "Yes",
    SS9035779: "No",
    SS9035780: "Choose a country",
    SS9035781: "Russia",
    SS9035782: "Update",
    SS9035783: "Not selected",

    //Переводы 11.10.21
    SS9035789: "Added item",
    SS9035790: "Comment from patient",
    SS9035791: "No comment",
    SS9035792: "List of attachments",
    SS9035793: "Resend",
    SS9035794: "Accept",
    SS9035795: "Fill in the form",
    SS9035796: "duration ",

    SS9035798: "Draft",
    SS9035799: "On approval",
    SS9035800: "Approved",
    SS9035801: "On revision",
    SS9035802: "Cancelled",
    SS9035803: "Deleted",
    SS9035804: "Completed",
    SS9035805: "In progress",
    SS9035806: "Deleted",
    SS9035807: "For sign",
    SS9035808: "Signed",
    SS9035809: "Publishing",
    SS9035810: "Published",
    SS9035811: "Publication error",

    //Переводы 12.10.21
    SS9035815: "The patient sent a reply.",
    SS9035816: "Comment:",
    SS9035817: "Details",
    SS9035818: "Any type",
    SS9035819: "Task",
    SS9035820: "Notification",
    SS9035821: "Go to  ",
    SS9035822: "The patient was unable to provide the documents you requested",
    SS9035823: "Close the task",
    SS9035824: "Read more",
    SS9035825: "The task is overdue!",
    SS9035826: "Patient submitted an answer.",
    SS9035827: "missing",
    SS9035828: "The task has been resent to the patient. Due by {date}",
    SS9035829: "You will be notified when he/she sends a response.",

    //Перевод GLB-415
    SS9035830: "Not selected",
    SS9035831: "Not specified",
    SS9035832: "Not specified",
    SS9035833: "Not specified",
    SS9035834: "Not applicable",
    SS9035835: "not specified",
    SS9035836: "Not assigned",

    //Переводы 19.10.21
    SS92224: "Morphology results",
    SS9035850: "Default name",
    SS9035851: "Not translated",

    //Переводы 20.10.21
    SS9035852: "Histology test",
    SS9035853: "Cytology laboratory test",
    SS9035854: "Morphology results",
    SS9035855: "Study type",
    SS9035856: "Result",

    //Перевод 21.10.21
    SS9035857: "Organization",

    //Перевод 22.10.21
    SS9035863: "TB Report.",
    SS9035864: "TB Report",

    //Переводы 25.10.21
    SS9035866: "Above the capacity",
    SS9035867: "Select a document",
    SS9035868: "Cancel",
    SS9035869: "Close",
    SS9035870: "Minimize",
    SS9035871: "Remove a document from preview",
    SS9035872: "Add document to preview",
    SS9035873: "Maximize",

    //Переводы 26.10.21
    SS9035874: "You don't have enough rights to view this content.",
    SS9035875: "Contact your administrator",
    SS9035876: "Zoom conference link",
    SS9035877: "Zoom conference link",
    SS9035878: "Report does not exist",
    SS9035879: "Employee was not found",
    SS9035880: "Organization was not found",
    SS9035881: "Case {caseId} was not found",
    TT018740: "An error while downloading employee information",
    TT018750: "An error while downloading organization information",
    SS9035882: "Videoconference",
    SS9035883: "Please select",
    SS9035884: "Treatment plans loading …",

    //Переводы 27.10.21
    SS9035885: "Not helped",
    SS9035886: "New task received",
    SS9035887: "Helped",
    SS9035888: "No effect known",
    SS9035889: "mmHg",
    SS9035890: "mmol/L",
    SS9035891: "kg",
    SS9035892: "No effect known",
    SS9035893: "Left",
    SS9035894: "Right",
    SS9035895: "Publish",
    SS9035896: "Are you sure you want to publish the report?",
    SS9035897: "Repeat",
    SS9035898: "Previous Tumor Board",
    SS9035899: "Next Tumor Board",
    SS9035900: "Patient ID could not be detected",
    SS9035901: "An error while reading the data, try the operation later",
    SS9035902: "Hide a block",
    SS9035903: "Minimize a block",
    SS9035904: "Maximize a block",

    //Переводы 29.10.21
    SS9035906: "Reporting period:",
    SS9035907: "Organization that conducted a Tumor Board",
    SS9035908: "Number of patients, passed through the TB",
    SS9035909: "Including patients who were diagnosed for the first time",
    SS9035910: "Including the number of patients sent for follow-up examination",
    SS9035911: "Patient referred for treatment, cases",
    SS9035912: "Type of treatment, cases",
    SS9035913: "Number of cases of treatment administered using telemedicine technologies",
    SS9035914: "Capital Region organization",
    SS9035915: "Organization of The Capital",
    SS9035916: "National institutions",
    SS9035917: "Surgical",
    SS9035918: "ACT",
    SS9035919: "Radiation",
    SS9035920: "Combined",
    SS9035921: "No hospitals were found. Change your search terms.",
    SS9035923: "No employees were found. Change your search terms.",

    //переводы 1.11.21
    TT018860: "Emulation of context transfer",
    TT018870: "Login",
    TT018880: "Password",
    TT018890: "Confirm password",
    TT018900: "Next",
    TT018910: "SSN",
    TT018920: "An error while loading information",
    TT018930: "An error while loading information",
    TT018940: "No stage defined",
    TT018950: "No values available",
    TT018960: "Add a complication",
    TT018970: "Loading a Patient ...",
    TT018980: "Loading Diseases…",
    TT018990: "Continue",
    TT019000: "An error  while loading information",
    TT019010: "Organization",
    TT019020: "Patient not found",
    TT019030: "No record of Mobile App patient or user, need to recreate patient",
    TT019040: "Delete",
    TT019050: "Loading a Patient ...",
    TT019060: "Loading Diseases…",
    TT019070: "Add a Disease",
    TT019080: "Delete",
    TT019090: "Are you sure to remove the disease?",
    TT019100: "Loading Patient's Diseases",
    TT019110: "Export",
    TT019120: "Import",
    TT019130: "Primary",
    TT019140: "Repeat",
    TT019150: "Purpose of the Tumor Board:",
    TT019160: "Purpose of Tumor Board",
    TT019170: "Create",
    TT019180: "Select dates",
    TT019210: "Information loading…",
    TT019220: "Error loading information...",
    TT019230: "Tumor Board not found",
    TT019240: "Uploading loading error",
    TT019250: "Editing a possible survey",
    TT019260: "Name",
    TT019270: "Description",
    TT019280: "Save",
    TT019290: "Cancel",
    TT019300: "Enter a name",
    TT019310: "Members",
    TT019320: "An error while loading the information",
    TT019330: "Error while searching for teams",
    TT019340: "Sort by",
    TT019350: "Error  while searching for teams",
    TT019360: "Select",
    TT019370: "No patient information",
    TT019380: "Edit",
    TT019390: "Delete",
    TT019400: "Diagnostic tests",
    TT019410: "Laboratory tests",
    TT019420: "Instrumental diagnostics",
    TT019430: "Treatment conditions",
    TT019440: "Delete",
    TT019450: "Go to the report of the past Tumor Board",
    TT019460: "Past Tumor Board",
    TT019470: "Date of the Tumor Board",
    TT019480: "Main diagnosis",
    TT019490: "Date asserted",
    TT019500: "Multiple primary tumor type",
    TT019510: "Type of classification",
    TT019520: "A stage",
    TT019530: "no classification",
    TT019540: "no date",
    TT019550: "Date asserted",
    TT019560: "Type of classification",
    TT019570: "A stage",
    TT019580: "{number} stage",
    TT019590: "There was an error when searching for the Tumor Board",
    TT019600: "Scheduled sessions",
    TT019610: "New Tumor Board",
    TT019620: "Select desired date",
    TT019630: "No anamnesis",
    TT019640: "Select type of document",
    TT019650: "Select organization",
    TT019660: "Secretary's comment",
    TT019670: "Nothing here yet",
    TT019680: "Physician comment",
    TT019690: "Save",
    TT019700: "Uploaded documents according to the checklist",
    TT019710: "An error while loading cases",
    TT019720: "Type:",
    TT019730: "no date",
    TT019740: "Instructions",
    TT019750: "Selecting a patient",
    TT019770: "Patient does not have a Mobile App installed",
    TT019780: "The current user has no roles. Cannot add a treatment plan",
    TT019790: "Edit",
    TT019800: "Delete",
    TT019810: "Delete",
    TT019820: "Go to the minutes of the past Tumor Board",
    TT019830: "Delete complication",
    TT019840: "Add a complication",
    TT019860: "Printed TB report form",
    TT019870: "Delete",
    TT019880: "Information loading…",
    TT019890: "Tumor Board not found",
    SS9035924: "Edit employee",
    SS9035925: "Organisation list loading…",
    SS9035926: "There are no hospitals that have at least one {appRoleName}",
    TT019900: "ICD-10 code",
    TT019910: "Nested diseases",
    TT019920: "Conclusion",
    TT019930: "Treatment tactics",

    //Переводы 2.11.21
    SS9035927: "Specialization",
    SS9035928: "delete a slot",
    TT019940: "Mark as read",
    TT019950: "Clinical",
    TT019960: "Pathological",
    TT019970: "Both",
    TT019980: "Not applicable",
    TT019990: "Select a value",
    TT020000: "No value",
    SS9035929: "and {count} more",

    //Переводы 8.11.21
    TT020010: "Printed TB report form",
    TT020020: "Close",
    TT020030: "Cancel",
    TT020040: "Warning!",
    TT020050: "Links to other resources have been detected:",
    TT020060: "Applications: {count}",
    TT020070: "Documents: {count}",
    TT020080: "Deleting a patient may cause a system malfunction",
    TT020090: "Wait, there is a dependency search going on...",
    TT020100: "Do you really want to proceed with the deletion?",
    TT020110: "Delete the patient",
    TT020120: " or create a new one",
    TT020130: "Diagnoses:",
    TT020140: "Tumor Board:",
    TT020150: "Tumor Board purpose:",
    TT020160: "Other gender",
    TT020170: "Gender unknown",
    TT020180: "No cover document found",
    TT020190: "SSN unknown",
    TT020200: "Delete document {document}?",
    TT020210: "General condition",
    TT020220: "of ",
    TT020230: "Morphology results are incompletely filled in",

    //Переводы 11.11.21
    TT020240: "E-mail",
    TT020250: "Password",
    TT020260: "Confirm password",
    TT020270: "Registration",
    TT020280: "An error while searching for surveys",
    TT020290: "An error while searching for disease complications",
    TT020300: "No disease resource was found",
    TT020310: "No disease resource was found",
    TT020320: "Patient resource not found",
    TT020330: "All resources saved in the report composition",
    TT020340: "Her2 amplification by in situ hybridization (FISH\\CISH)",
    TT020350: "Anatomic localization",
    TT020360: "Histologic grading",
    TT020370: "Date of installation",
    TT020380: "Additional classifiers and biomarkers",
    TT020390: "Meaning/Result",
    TT020400: "Comments",
    TT020410: "Lymph nodes",
    TT020420: "Localization",
    TT020430: "Name",
    TT020440: "Description of nosological form",
    TT020450: "Distant metastases",
    TT020460: "Primary tumor",
    TT020470: "Progesterone receptors (ER) by immunohistochemical method",
    TT020480: "Estrogen receptors (ER) by immunohistochemical method",
    TT020490: "Generate a description",
    TT020500: "Confirmation state",
    TT020510: "Save",
    TT020520: "Staging",
    TT020530: "Stage",
    TT020540: "Side",
    TT020550: "Type of classification",
    TT020560: "Established",
    TT020570: "Clarification",
    TT020580: "Nature of the disease",
    TT020590: "Her2 expression by Immunohistochemistry method",
    TT020600: "Ki-67 expression by Immunohistochemistry method",
    TT020610: "No disease found",
    TT020620: "ICD-10 code or name",
    TT020630: "Editing disease",
    TT020640: "Patient not found",
    TT020650: "An error while uploading patient diagnosis",
    TT020660: "An error while uploading the patient",
    TT020670: "Complications of major diseases",
    TT020680: "Underlying diseases",
    TT020690: "Comorbidities",
    TT020700: "No name was specifyed",
    TT020710: "Employee not found",
    TT020720: "[ saved ]",
    TT020730: "Validation passed",
    TT020740: "An error while validating patient phone number",
    TT020750: "An error while validating patient's PHI",
    TT020760: "An error while while validating patient's SNIL",
    TT020770: "Error loading Disability Group",
    TT020780: "other gender",
    TT020790: "unknown gender",
    TT020800: "Failed to receive patient data",
    TT020810: "Add",
    TT020820: "[ Unknown organization ]",
    TT020830: "Unknown organization",
    TT020840: "Organization",
    TT020850: "An error while uploading the patient's illnesses",
    TT020860: "No disease found",
    TT020870: "ICD-10 code or name",
    TT020880: "Patient's Disease: {patientName}",
    TT020890: "Patient not found",
    TT020900: "An error while uploading the patient",
    TT020910: "unknown",
    TT020920: "No ID for the HIS login was found",
    TT020930: "The value of the HIS login is not specified",
    TT020940: "There are no employees with this HIS login: {value}",
    TT020950: "An error while validating the employee's HIS login",
    TT020960: "Employee with this HIS login is already registered in the system",
    TT020970: "No SSN found",
    TT020980: "There are no employees with this SSN: {value}",
    TT020990: "An error while validating the employee's SSN",
    TT021000: "The employee with this SSN is already registered in the system",
    TT021010: "Date of birth is in an invalid format",
    TT021020: "First name cannot be more than 20 characters",
    TT021030: "Father's name cannot be more than 30 characters",
    TT021040: "Last name cannot be more than 50 characters",
    TT021050: "[ Organization not found ]",
    TT021060: "Employee Role",
    TT021070: " Missing disease references were found",
    TT021080: " No errors found",
    TT021090: "The {careplan} treatment plan of th Tumor Board {appointment} has no reference to diseases: {conditions}.",
    TT021100: "The check up of {appointmentsCount} Tumor Board, {careplansCount} Treatment Plans  and {conditionsCount} diseases has been completed.",
    TT021110: "Loading...",
    TT021120: "An error while fixing resource links",
    TT021130: "An error while checking links",
    TT021140: "Patient's diagnosis",
    TT021150: "Patient's Disease",
    TT021160: "Create",
    TT021280: "Specify position",
    TT021290: "Specify organization",
    TT021300: "Specify specialty",
    TT021310: "Specify physician's name",
    TT021320: "Original Tumor Board  is not found. Refresh page",
    TT021330: "An error while creating a copy of the Tumor Board",
    TT021340: "An error while requesting the server",
    TT021350: "Maximal number of slots was reached",
    TT021360: "No free slots",
    TT021370: "[unknown]",
    TT021380: "Case id: {serviceRequestId} unknown status combination",
    TT021390: "unknown",
    TT021400: "An error while deleting cases",
    TT021410: "Urgent cases ({overbookedItemsCount})",
    TT021420: "Status",
    TT021430: "Full name",
    TT021440: "Case resource not found",
    TT021450: "Resource of the patient to whom the case refers  is not found",
    TT021460: "The resource of the employee who created the case  is not found",
    TT021470: "Resources for slots of the target TB were not found",
    TT021480: "Date",
    TT021490: "Comments",
    TT021500: "Name",
    TT021510: "An error while adding a link to a nested disease in a complex disease diagnosis",
    TT021520: "Disease resource not found in nested report resources",
    TT021530: "A reference to a disease in the diagnosis section  is not found",
    TT021540: "A reference to a disease in the diagnosis section of the report  is not found",
    TT021550: "A link to the disease and the disease resource from the report was removed",
    TT021560: "A link to the disease from the diagnosis section was  was removed",
    TT021570: "Failed to find a nested disease resource in the report resource",
    TT021580: "Reference to a nested disease in the complex diagnosis section  is not found",
    TT021590: "Reference to a nested disease in the complex diagnosis section of the report  is not found",
    TT021600: "An error while the deleting a link to the nested disease in the diagnosis's complex disease",
    TT021610: "A link to the nested disease in the diagnosis's complex disease was removed",
    TT021620: "A link to the nested disease from the diagnosis section of the report was removed",
    TT021630: "Specify position",
    TT021640: "Specify organization",
    TT021650: "Specify specialty",
    TT021660: "Specify physician's name",
    TT021670: "attending physician",
    TT021680: "chair person",
    TT021690: "secretary",
    TT021700: "expert",
    TT021710: "Source of financing",
    TT021720: "Select a value",
    TT021730: "No values",
    TT021740: "An error while loading the data. Report creation is not possible.",
    TT021750: "The Tumor Board report for {patientName} has not been completed. Are you sure to leave the page?",
    TT021760: "New Tumor Board",
    TT021770: "Deletion case  is not found",
    TT021780: "Nested disease not found",
    TT021790: "ECOG status",
    TT021800: "You did not specify a minimum number of nested conditions (2)",
    TT021810: "You did not specify a primary disease",
    TT021820: "There should only be one neoplasm labeled as Major Tumor. Edit the nested diseases.",
    TT021830: "Mark one of the neoplasms as Major Tumor. Edit the nested diseases.",
    TT021840: "An error while validating the diagnosis of the application",
    TT021850: "There is no standard examination plan for this nosology, discuss with the TB secretary the necessary surveys.",
    TT021860: "unknown",

    //Переводы 15.11.21
    SS9035932: "Specialty",
    SS9035933: "Do you like to fix it?",

    //Переводы 24.11.21
    TT021920: "Recommended dosage",
    TT021930: "Edit document",
    TT021940: "Delete document",
    TT021950: "Edit document",
    TT021960: "Edit the record",
    TT021970: "Save the record",
    TT021980: "Delete the document",
    TT021990: "Delete record",
    TT022240: "from mobile app",
    TT022250: "or",
    TT022260: "from a computer",
    TT022280: "downloading…",

    //Перевод dv кодов 29.11.21
    SS9035942: "Enter value to search",
    SS9035944: "Edit disease",
    SS9035945: "Delete disease",
    SS9035946: "My reports",

    //Переводы 1.12.21
    SS9035957: "Fill in the results of your examinations",

    //Переводы 2.12.21
    SS9035968: "Edit Tumor Board",

    //Переводы 9.12.21
    TT022550: "Create a Tumor Board for the nearest date in the selected period, if the search is unsuccessful",
    TT022560: "Create TB if necessary",
    TT022570: "No Tumor Boards found: {err}",
    TT022580: "New Tumor Board on {dateString}",

    //Переводы 11.01.22
    SS9036010: "Report validation",
    SS9036020: "Warning!",
    SS9036030: "Changing the status of the report",
    SS9036040: "Member dissenting opinion",
    SS9036050: "Previous TBs reports",

    //Переводы 31.01.22
    SS9036060: "January",
    SS9036070: "February",
    SS9036080: "March",
    SS9036090: "April",
    SS9036100: "May",
    SS9036110: "June",
    SS9036120: "July",
    SS9036130: "Aug",
    SS9036140: "September",
    SS9036150: "October",
    SS9036160: "November",
    SS9036170: "December",
    SS9036180: "Mon",
    SS9036190: "Tue",
    SS9036200: "Wed",
    SS9036210: "Thu",
    SS9036220: "Fr",
    SS9036230: "Sat",
    SS9036240: "Sun",
    SS9036250: "Registry of TBs",
    SS9036260: "Page {PageNo} of {Pages}",
    SS9036270: "Download file",
    SS9036280: "Remove a document from the view",
    SS9036290: "Drag and Drop",
    SS9036300: "Darker/Lighter",
    SS9036310: "Close",
    SS9036320: "Minimize",
    SS9036330: "Add document to the view",
    SS9036340: "Edit TB",
    SS9036350: "Edit record",
    SS9036360: "Delete record",
    SS9036370: "Add a record",
    SS9036380: "Record is added",
    SS9036390: "Save record",
    SS9036400: "Record is saved",
    SS9036410: "Edit the document",
    SS9036420: "Planned duration (months)",
    SS9036430: "Edit the document",
    SS9036440: "Delete the document",
    SS9036450: "Survey results",
    SS9036460: "Fill in the results of examinations",
    SS9036470: "Absent",

    //Переводы 2.02.22
    SS9036480: "Clinical trials ",
    SS9036490: "Reset filter",
    SS9036510: "Create TB if necessary ",
    SS9036520: "Add participants from the previously created TB team",
    SS9036530: "No clinical trials found",
    SS9036550: "Refresh list",
    SS9036560: "Update the list of clinical trials?\nIt may take a few minutes.\nWhile updating, you can continue with the application.",
    SS9036570: "Yes",
    SS9036580: "No",
    SS9036590: "Show clinical trials in report",

    //Переводы GLB-595
    SS9037060: "Click the \"Refresh list\" button to display the current clinical trials",
    "SS9037060-1": "All diseases",
    SS9037080: "See all",
    SS9037090: "First page",
    SS9037100: "Previous page",
    SS9037110: "Next page",
    SS9037120: "Last Page",
    SS9037130: "Save",
    SS9037140: "Close",
    SS9037150: "Not selected",
    SS9038530: "Case list keeping resource is not found.",
    SS9038540: "Select trials",
    SS9038550: "Change selection of trials",
    SS9038560: "No clinical description",
    SS9038570: "Name of trial",
    SS9038580: "Status",
    SS9038590: "Trial Title",
    SS9038600: "Conditions",
    SS9038610: "Interventions",
    SS9038620: "Locations",
    SS9038640: "List of available clinical trials has been updated. Please refresh the page",
    SS9038650: "No clinical trials selected",

    //Settings
    SS00058: "After installation",
    SS00059: "Forgotten Links",
    SS00060: "Orthank",
    SS00061: "Export",
    SS00062: "Pushy",
    SS00063: "Monitor",
    SS00064: "Do not enter",
    SS00065: "Fix Treatment Plans",
    SS00066: "Resource Converters",
    SS00067: "Export/import of treatment templates",
    SS00068: "EMIAS staff import",

    SS00080: "All Tumor Boards",
    SS00081: "Upcoming Tumor Boards",
    SS00082: "Tumor Boards Archive",
    SS00083: "Tumor Board information",
    SS00092: "Create case",
    SS00093: "Patient Case",
    SS00095: "{param}",
    SS00096: "Cases in processing",
    SS00097: "Cases on approval",
    SS00098: "Cases returned for revision",
    SS00099: "Case archive",

    SS00101: "Delete",
    SS00102: "Close",
    SS00103: "View",


    PP001001: "{days} days",
    PP001002: "{days} day",
    PP001003: "{days} days",
    PP003001: "{years} y.o.",
    PP003002: "{years}  y.o.",
    PP003003: "{years}  y.o.",

    //Временные
    TT000002: "Patient selection",
    TT000007: "Patient creation",
    TT000013: "Incoming referrals",

    TT018720: "Specify the cycle duration",
    TT018730: "No prescriptions were added",
    TT023470: "No prescriptions were added",

    SS9036660: "History",

    SS9036600: "Results of performed examinations",
    SS9036610: "Fill in the results of examinations",
    SS9036620: "Absent",

    SS9036640: "Neoplasm",
    SS9036650: "Disease",

    SS9036630: "Coverage Document:",

    // GLB-630
    SS9036680: "Patient files",
    SS9036690: "Sort by date",
    SS9036700: "Processed document",
    SS9036710: "Full screen",
    SS9036720: "Download",
    SS9036730: "Drag and drop",
    SS9036740: "Lighter/Darker",
    SS9036750: "Collapse the screen",
    SS9036760: "File is added",
    SS9036770: "Download file",
    SS9036780: "Change name",
    SS9036790: "Delete file",
    SS9036800: "Failed to add file. Wrong format.",
    SS9036810: "Failed to add file",
    SS9036820: "Repeat adding",
    SS9036830: "Cancel adding",
    SS9036840: "You can cancel adding or try to add again",
    SS9036850: "Add document to view",
    SS9036860: "Collapse",
    SS9036870: "Close",
    SS9036880: "Drag and Drop",
    SS9036890: "Darker/Darker",
    SS9036900: "Delete",
    SS9036910: "Special character recognition technology analyzes your printed/handwritten text and turns it into an editable digital PDF file",
    SS9036920: "PDF",
    SS9036930: "Recognize",
    SS9036940: "Go",
    SS9036950: "Processing of attachments is possible for jpeg, png, pdf only",
    SS9036960: "Processing",
    SS9036970: "File is being processed",
    SS9036980: "Delete source files?",
    SS9036990: "Yes",
    SS9037000: "No",
    SS9037010: "Processed document",
    SS9037020: "Collapse the screen",
    SS9037030: "Delete",
    SS9038360: "Patient's documents are not found",
    SS9038370: "Show a list",
    SS9038380: "Processing of attachments",
    SS9038390: "Minimize a list",
    SS9038400: "Download",
    SS9038410: "Cancel processing",
    SS9038420: "Repeat processing",
    SS9038430: "You can stop processing or try to process again",
    TT023280: "Failed to process a file",
    TT023290: "Failed to process a file. Format is wrong.",
    SS9038450: "Nothing was found with the query \"{TextSample}\"",
    STT023270: "Record added",
    S9038440: "Assign",
    TT023320: "Only one drug must be chosen",
    TT023340: "The group name {GroupName} is already used in the Treatment Cycle, select another name",
    TT023330: "Additional drugs to choose",
    SS9038470: "Additional drugs to choose",
    SS9038460: "Group",
    SS9038510: "Organization",
    SS9038520: "Branch, Division",
    SS9038630: "Medication has been successfully selected",
    SS9038500: "Outdated Treatment Template",

    SS9039890: "Warning!",
    SS9039900: "Preparing Patient Case for consideration",
    SS9039910: "Sending Patient Case for approval",
    SS9039920: "Cancelling Patient Case",
    SS9039930: "Returning Patient Case for revision",
    SS9039940: "Deleting Patient Case",
    SS9039950: "Completing Patient Case consideration",
    SS9039960: "Returning Patient Case for consideration",
    SS9039970: "Patient Case status changing error",
    SS9039820: "Please fill out cancer history",
    SS9039740: "Return for revision",
    SS9039720: "Return for consideration",
    SS9039980: "Add all template groups",
    SS9039730: 'It is impossible to transfer the Patient Case to the status "Approved" because the TB report is in the status of "{status_name}". Please contact our support team',
    SS9039750: 'It is impossible to transfer the Patient Case to the status "Approved" because the TB report is in the status of "{status_name}". Please contact our support team',
    SS9039760: "Return to work",
    SS9039770: 'Are you sure you want to return the report to "In progress" status?',
    SS9039780: 'Return to "Signed" status',
    SS9039790: 'Are you sure you want to return the report to "Signed" status?',
    SS9039800: "Please specify the examination plan item name",
    SS9039810: "Patient Case delete error",
    SS9039830: "Document review",
    SS9039840: "Please fill out general condition",
    SS9039850: "Or add a new examination plan",
    SS9039860: "Document review",
    SS9039870: "Document editing",
    SS9039880: "List loading...",
    TT023370: "Fill in the height, weight and serum creatinine",
    TT023380: "Save changes?",
    TT023390: "Are you sure you want to delete a prescription?",
    SS9040050: "New disease",
    SS9040020: "Edit",
    SS9040030: "Discard the changes",
    TT023450: "Date not set",
    TT023420: "A medication group must contain at least one medication",
    SS9040110: "Reason for cancel",
    SS9040120: "Body mass index",
    TT023480: "Fill in the values Height, Weight",
    SS9040130: "Rationale for changing the treatment regimen",
    TT023460: "It is impossible to reduce the length of the cycle, as there are appointments for the '{day}' day of treatment",
    TT023490: "The date cannot be changed because one or more appointments have already been completed",
    SS9040150: "Are you sure you want to discard the changes?",
    TT023430: "Disease added",
    TT023440: "Diseases added",
    MIRA_1910_1: "Case number",
    SS9040170: "To change the date for the first day of the cycle, you have to change the start date of the cycle",
    SS9040180: "Move appointments to the previous day",
    SS9040190: "Move appointments to the next day",
    SS9040200: "Rescheduling appointments is possible only on a free date",
    SS9040210: "Appointments Transferred Successfully",
    SS9040220: "Rescheduling is not possible because one or more assignments have already been completed",
    SS9040240: "Sign",
    SS9040250: "Choose the employees from the dropdown menu",
    SS9040260: "The last name field is filled in incorrectly",
    SS9040270: "The name field is filled in incorrectly",
    TT023520: "An error occurred while adding a treatment cycle",
    SS9040280: "Data for",
    SS9040290: "If you change the value of the BSA/clearance of creatinine, doses of preparations will be recalculated and you will have to confirm their appointment again. Change the value of the patient's current condition?",
    TT023410: "Editing medication from the Premedication and Antitumor Therapy section is available when the Treatment Cycle status is \"Created\" or \"Paused\"",
    TT023580: "For full editing, you need to uncheck the introduction in the “Treatment status” tab",
    TT023590: "To change the cycle to the status \"Assigned\", you must confirm all medication appointments",
    TT023570: "Cannot specify a cycle length longer than 366 days",
    SS9040330: "Referral for planned hospitalization",
    SS9040340: "Referral for radiation therapy",
    SS9040350: "Save",
    SS9040360: "Absent",
    SS9040530: "Sign with an electronic signature",
    SS9040540: "Receiving certificates in progress. Please, wait",
    SS9040550: "Signed",
    SS9040560: "Not signed",
    SS9040570: "Signing in progress",
    SS9040580: "TB Report is signed",
    SS9040310: "The Tumor Board has been created",
    SS9040320: "The Tumor Board has been cancelled",
    SS00100: "Tumor board is in progress",
    SS9040600: "Closed",
    TT023650: "Please enter a valid middle name",
    TT023660: "Cancer Type group is not specified",
    SS9040620: "Document signing",
    SS9040630: "Select a certificate for signing",
    SS9040640: "Sign",
    SS9040650: "Cancel",
    SS9040660: "Electronic signature status",
    SS9040670: "Electronic signature validation",
    SS9040680: "Cancel the electronic signature",
    SS9040690: "Finish the electronic signature signing",
    SS9040700: "Certificate",
    SS9040710: "Validity",
    SS9040720: "Signature status",
    SS9040730: "Download pdf",
    SS9040740: "Download pdf with the electronic signature",
    SS9040750: "Show the electronic signature status",
    SS9040770: "Document review",
    SS9040780: "The electronic signature authentication",
    SS9040800: "Enter a text to search for",
    SS9040820: "Warning!",
    SS9040830: "Saving of the patient case is in progress",
    SS9040840: "Add a record in supporting documents",
    SS9040850: "Edit a record from supporting documents",
    SS9040860: "An electronic signature can only be used for one Tumor Board Team member",
    SS9040870: "Check the correctness of the entered values",
    SS9040880: "Changing the status of the patient case",
    SS9040890: "No information available",
    SS9040900: "Are you sure you want to complete signing the report?",
    SS9040910: "Are you sure you want to cancel the electronic signature?",
    SS9040920: "Add a competing disease",
    SS9040930: "No unassigned known diseases",
    SS9040940: "Add disease complications",
    SS9040950: "Add concomitant diseases",
    SS9040960: "Do you really want to remove the substituting employee?",
    SS9041000: "Edit",
    TT024130: "The presentation has no information to display. Please go to the presentation editing screen and turn on the slides/blocks display",
    SS9041010: "Add an examination plan item",
    SS9041020: "Edit an examination plan item",
};
