import uuid from "uuid/v4";
import _get from "lodash/get";
import _omit from "lodash/omit";
import { observable } from "mobx";
import { CreatedFromExtension } from "MODEL_STORE/MIRAMEDIX/Extensions/CreatedFromExtension";

/**
 * локализации отдаленных метастазов
 */
export class DistantMetastasesObservation {

    /**
     * код (Observation.code.coding.code)
     */
    static CODING_CODE = "385421009";

    /**
     * система (Observation.code.coding.system)
     */
    static CODING_SYSTEM = "http://snomed.info/sct";

    /**
     * система (Observation.component.code.coding.system)
     */
    static COMPONENT_CODING_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/distantMetastasesLocation";

    /**
     * ссылка на ресурс Observation
     */
    @observable resource = null;

    /**
     * расширение с информацией о том,
     * на основе какого ресурса создан текущий
     */
    @observable createdFrom = null;

    /**
     * создание ресурса
     */
    static create(patient) {
        return new DistantMetastasesObservation({
            id: uuid(),
            status: "final",
            resourceType: "Observation",
            subject: {
                id: patient.id,
                resourceType: "Patient"
            },
            effective: {
                dateTime: new Date().toISOString().split("T")[0]
            },
            code: {
                text: "Локализация отдаленных метастазов",
                coding: [{
                    code: DistantMetastasesObservation.CODING_CODE,
                    system: DistantMetastasesObservation.CODING_SYSTEM,
                    display: "Site of distant metastasis"
                }]
            },
            component: []
        })
    }

    /**
     * resource - ссылка на ресурс Observation
     */
    constructor(resource) {
        this.initialize(resource);
    }

    /**
     * инициализация
     */
    initialize = (resource) => {
        this.resource = resource;
        this.createdFrom = new CreatedFromExtension(this.resource);
    }

    /**
     * обновление
     */
    update = (resource) => {
        this.initialize(JSON.parse(JSON.stringify(resource)));
    }

    /**
     * клонирование
     */
    clone = () => {
        return new DistantMetastasesObservation(JSON.parse(JSON.stringify(this.resource)));
    }

    /**
     * данные без мета информации
     */
    get data() {
        return _omit(this.resource, ["meta"]);
    }

    /**
     * идентификатор ресурса
     */
    get id() {
        return _get(this.resource, "id");
    }

    set id(id) {
        this.resource.id = id;
    }

    /**
     * Расширения
     */
    get extension() {
        return _get(this.resource, "extension", []);
    }

    set extension(extension) {
        return this.resource.extension = extension
    }

    /**
     * тип ресурса
     */
    get resourceType() {
        return _get(this.resource, "resourceType");
    }

    /**
     * версия ресурса
     */
    get versionId() {
        return _get(this.resource, "meta.versionId");
    }

    /**
     * все значения
     */
    get displayValues() {
        const values = [];
        _get(this.resource, "component", []).forEach(component => {
            const coding = _get(component, "code.coding", []).find(coding => {
                return _get(coding, "system") == DistantMetastasesObservation.COMPONENT_CODING_SYSTEM;
            });
            if ( coding && coding.display ) {
                values.push(coding.display)
            }
        });
        return values;
    }

    /**
     * определены ли какие-либо метастазы?
     */
    get hasMetastases() {
        return !! _get(this.resource, "component", []).length;
    }

    /**
     * поиск индекса компонента по коду
     */
    findComponentIndexByCode = (code) => {
        return _get(this.resource, "component", []).findIndex(component => {
            return _get(component, "code.coding", []).find(coding => {
                return _get(coding, "code") == code;
            });
        });
    }

    /**
     * есть ли компонент с таким кодом?
     */
    hasConcept = (concept) => {
        return this.findComponentIndexByCode(concept.code) >= 0;
    }

    /**
     * изменение
     */
    toggleConcept = (concept) => {
        return this.hasConcept(concept)
            ? this.removeComponent(concept)
            : this.createComponent(concept);
    }

    /**
     * создание компонента
     */
    createComponent = (concept) => {
        if ( ! Array.isArray(this.resource.component) ) {
            this.resource.component = [];
        }
        this.resource.component.push({
            code: {
                coding: [{
                    code: concept.code,
                    system: concept.system,
                    display: concept.display
                }]
            }
        });
    }

    /**
     * удаление компонента
     */
    removeComponent = (concept) => {
        const index = this.findComponentIndexByCode(concept.code);
        return index >= 0 ? this.resource.component.splice(index, 1) : false;
    }

}
