/**
 *
 * https://www.hl7.org/implement/standards/fhir/servicerequest.html
 *
 * Заявка на рассмотрение пациента на консилиуме
 *
 * модель версии 2, не предназначенная для стора
 *
 * конструктору кормим данные, как приходят в ответе бокса, поле resource:
 * {
 *     "resource" : { <--- вот эту часть
 *         ...
 *     },
 *     "fullUrl": "http://...."
 * }
 *
 *
 *
 * {
 *     "comment": "just a comment",
 *     "description": "the appointment!",
 *     "id": "23142314234-4342434-2342332-4234",
 *     "intent": "",
 *     "status": "draft | active | suspended | completed | entered-in-error | cancelled"
 *     "subject": {
 *         Reference
 *     },
 *     "supportingInformation": [ // чек-лист
 *       { Reference }
 *     ],
 * }
 *
 *
 *
 */

import moment from "moment";
import _get from 'lodash.get';
import _orderBy from "lodash/orderBy";
import { DomainResource } from './DomainResource';
import { Reference } from '../Elements/Reference';
import { CodeableConcept } from '../Elements/CodeableConcept';

import { REQUEST_INTENT } from '../CodeSystem/RequestIntent';
import {REQUEST_STATUS} from '../CodeSystem/RequestStatus';
import {Annotation} from "MODEL_STORE/FHIR/Elements/Annotation";
import {AuthStore} from "MODEL_STORE/DataSource/Stores/AuthStore";
import {Extension} from "../Elements/Extension";
import { AppointmentReason } from "MODEL_STORE/FHIR/Elements/AppointmentReason";
import { Identifier } from '../Elements/Identifier';

const REQUEST_NUMBER_SYSTEM = 'http://miramedix.ru/fhir/identifier/consilium-request-number';

export class ServiceRequest extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'ServiceRequest';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'instantiatesCanonical',
            'instantiatesUri',
            'basedOn',
            'replaces',
            'requisition',
            'priority',
            'doNotPerform',
            'orderDetail',
            'quantityQuantity',
            'quantityRatio',
            'quantityRange',
            'encounter',
            'occurrenceDateTime',
            'occurrencePeriod',
            'occurrenceTiming',
            'asNeededBoolean',
            'asNeededCodeableConcept',
            'performerType',
            'performer',
            'locationCode',
            'locationReference',
            'insurance',
            'specimen',
            'bodySite',
            'relevantHistory',
        ]);


        this._data.intent = data.intent || REQUEST_INTENT.PLAN;
        this._data.status = data.status || REQUEST_STATUS.DRAFT;

        if (Array.isArray(data.supportingInfo)) {
            data.supportingInfo.forEach( e => {
                this._arrayAdd('supportingInfo', new Reference(e));
            });
        }

        if (data.hasOwnProperty('authoredOn')) {
            this._data.authoredOn = data.authoredOn;
        }

        if (data.hasOwnProperty('reasonReference')) {
            this._data.reasonReference = data.reasonReference.map(e => new Reference(e)._data);
        }

        if (data.hasOwnProperty('requester')) {
            if (data.requester) this._data.requester = data.requester;
        } else {
            this._data.requester = Reference.fromResource(AuthStore.currentUserData.person)._data;
        }

        if (data.hasOwnProperty('subject')) {
            this._data.subject = new Reference(data.subject)._data;
        }

        if (data.hasOwnProperty('category')) {
            this._data.category = data.category.map( e => new CodeableConcept(e)._data)
        }

        if (data.hasOwnProperty('patientInstruction')) {
            this._data.patientInstruction = data.patientInstruction;
        }

        if (Array.isArray(data.code)) {
            data.code.forEach( e => {
                this._arrayAdd('code', new CodeableConcept(e));
            });
        }

        if (data.hasOwnProperty('note')) {
            this._data.note = data.note;
        }

        if (Array.isArray(data.reasonCode)) {
            data.reasonCode.forEach( e => {
                this._arrayAdd('reasonCode', new CodeableConcept(e));
            });
        }

        if (Array.isArray(data.identifier)) {
            data.identifier.forEach( e => {
                this._arrayAdd('identifier', new Identifier(e));
            });
        }

        // причина записи на консилиум
        this.appointmentReason = new AppointmentReason(this._data);
    }

    /**
     * функция валидации
     * на выходе список ошибок в формате { поле, сообщение }
     */
    _validate() {

        super._validate();

        if (this._data.hasOwnProperty('subject')) {
            throw 'ServiceRequest.subject is required';
        }
        (new Reference(this._data.subject))._validate();

        if ( ! this._validateFieldCode(this._data.intent, REQUEST_INTENT)) {
            throw 'ServiceRequest.intent is incorrect';
        }

        if ( ! this._validateFieldCode(this._data.status, REQUEST_STATUS)) {
            throw 'ServiceRequest.status is incorrect';
        }

        if (this._data.supportingInfo) {
            this._data.supportingInfo.forEach( e => {

                (new Reference(e))._validate();
            });
        }
    }

    /**
     * добавление ссылки на слот
     */
    addReferenceToSlot = (slot) => {
        const value = {
            id: slot.id,
            resourceType: "Slot"
        };
        if (this._data.supportingInfo) {
            this._data.supportingInfo.push(value)
        } else {
            this._data.supportingInfo = [value];
        }

    }

    /**
     * добавление ссылки на план обследования
     */
    addReferenceToCarePlan = (carePlan) => {
        const value = {
            id: carePlan.id,
            resourceType: "CarePlan"
        };
        if (this._data.supportingInfo) {
            this._data.supportingInfo.push(value)
        } else {
            this._data.supportingInfo = [value];
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     * номер заявки
     * MIRA-1910
     */
    get number() {

        if ( ! this._data.identifier) {
            console.warn('no identifier element in resource');
            return '--';
        }

        const identifierElem = this._data.identifier.find( e => e.system == REQUEST_NUMBER_SYSTEM );
        if (!identifierElem) {
            console.warn('no identifier found for request number');
            return '---';
        }

        return _get(identifierElem, 'value')
    }

    /**
     *
     */
    get code() {

        if ( ! this._data.code) {
            return [];
        }
        return this._data.code.map( e => new CodeableConcept(e, e => this._arrayReplaceById('code', e.id, e) ) );
    }

    /**
     *
     */
    set code(newValue) {
        this._data.code = newValue._data;
    }

    /**
     *
     */
    get intent() {
        return this._data.intent;
    }

    /**
     *
     */
    set intent(newValue) {
        this._data.intent = newValue;
    }

    /**
     *
     */
    get status() {
        return this._data.status;
    }

    /**
     *
     */
    set status(newValue) {
        this._data.status = newValue;
    }

    /**
     *
     */
    get authoredOn() {
        return this._data.authoredOn;
    }

    /**
     *
     */
    set authoredOn(newValue) {
        this._data.authoredOn = newValue;
    }

    /**
     *
     */
    get requester() {
        return this._data.requester;
    }

    /**
     *
     */
    set requester(newValue) {
        this._data.requester = newValue;
    }

    /**
     *
     */
    get patientInstruction() {
        return this._data.patientInstruction;
    }

    /**
     *
     */
    set patientInstruction(newValue) {
        this._data.patientInstruction = newValue;
    }

    /**
     *
     */
    get subject() {
        return new Reference(this._data.subject);
    }

    /**
     * на вход можно подать и Reference, и какой-то ресурс
     * референс возьмется "как есть", ресурс резолвится
     */
    set subject(newSubject) {
        this._data.subject = Reference.fromResource(newSubject)._data;
    }

    /**
     *
     */
    get supportingInfo() {

        if ( ! this._data.supportingInfo) {
            return [];
        }
        return this._data.supportingInfo.map( e => new Reference(e, info => this._arrayReplaceById('supportingInfo', info.id, info) ) );
    }

    /**
     * на вход подаем массив моделей
     */
    set supportingInfo(newValue) {
        this._data.supportingInfo = newValue.map( e => e._data );
    }

    /**
     *
     */
    get reasonReference() {

        if ( ! this._data.reasonReference) {
            return [];
        }
        return this._data.reasonReference.map( e => new Reference(e, ref => this._arrayReplaceById('reasonReference', ref.id, ref) ) );
    }

    /**
     * на вход подаем массив моделей
     */
    set reasonReference(newValue) {
        this._data.reasonReference = newValue.map( e => e._data );
    }

    set note(newValue) {
        this._data.note = newValue.map( e => e._data );
    }

    // TODO может быть несколько анатоаций, но пока использую один (для комментария секретаря)
    get note() {
        return this._data.note ? this._data.note.map(n => new Annotation(n)) : [];
    }

    /**
     * массив расширений с комментариями
     */
    get extensionsComment() {
        let extensions = _get(this, "extension", []).filter(extension => {
            return _get(extension, "url") == "http://hl7.org/fhir/StructureDefinition/request-statusReason";
        });
        extensions = _orderBy(extensions, i => moment(_get(i, "valueAnnotation.time", "")).unix(), 'desc');
        return extensions;
    }

    /**
     * комментарий отзыва заявки
     */
    get withdrawComment() {
        if ( this.extensionsComment.length ) {
            return _get(this.extensionsComment[0], ["valueAnnotation", "text"], "");
        }
        return "";
    }

    onChangeWithdrawComment = (value) => {
        if (value === "") {
            this._data.extension = [];
            return;
        }
        const comment = new Extension();
        comment._data.url = "http://hl7.org/fhir/StructureDefinition/request-statusReason";
        comment._data.valueCodeableConcept = {
            text: value
        };
        this._data.extension = [comment._data];
    }

    defaultOnErrorCheckStatus = () => {
        //ToasterStore.error(`В заявке id: ${this._data.id} не известная комбинация статусов`);
        return 'error';
    };
}
