/**
 * https://www.hl7.org/implement/standards/fhir/extensibility.html#Extension
 */

import { Element } from './Element';
import _clone from 'lodash.clone';


export class Extension extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Extension';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        if (data.hasOwnProperty('url')) {
            this._data.url = data.url;
        }
        else {

            console.log('%cExtension.url is not set', 'background-color: red; color: white;');
            console.log('here comes the content:');
            console.log(data);

            this._data.url = "https://placeholder"
        }

        Object.keys(data).forEach( key => {

            if (key.substr(0, 5) == 'value') {
                this._data[key] = _clone(data[key], true);
            }

        });

        // TODO + Rule: Must have either extensions or value[x], not both
    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check url
        // TODO check value[x]
    }

    /**
     *
     */
    get uri() {
        return this._data.uri;
    }

    /**
     *
     */
    set uri(newValue) {
        this._data.uri = newValue;
    }


}
