export const LANGUAGES = {
    AR : 'ar',
    BN : 'bn',
    CS : 'cs',
    DA : 'da',
    DE : 'de',
    DE_AT : 'de-AT',
    DE_CH : 'de-CH',
    DE_DE : 'de-DE',
    EL : 'el',
    EN : 'en',
    EN_AU : 'en-AU',
    EN_CA : 'en-CA',
    EN_GB : 'en-GB',
    EN_IN : 'en-IN',
    EN_NZ : 'en-NZ',
    EN_SG : 'en-SG',
    EN_US : 'en-US',
    ES : 'es',
    ES_AR : 'es-AR',
    ES_ES : 'es-ES',
    ES_UY : 'es-UY',
    FI : 'fi',
    FR : 'fr',
    FR_BE : 'fr-BE',
    FR_CH : 'fr-CH',
    FR_FR : 'fr-FR',
    FY : 'fy',
    FY_NL : 'fy-NL',
    HI : 'hi',
    HR : 'hr',
    IT : 'it',
    IT_CH : 'it-CH',
    IT_IT : 'it-IT',
    JA : 'ja',
    KO : 'ko',
    NL : 'nl',
    NL_BE : 'nl-BE',
    NL_NL : 'nl-NL',
    NO : 'no',
    NO_NO : 'no-NO',
    PA : 'pa',
    PL : 'pl',
    PT : 'pt',
    PT_BR : 'pt-BR',
    RU : 'ru',
    RU_RU : 'ru-RU',
    SR : 'sr',
    SR_RS : 'sr-RS',
    SV : 'sv',
    SV_SE : 'sv-SE',
    TE : 'te',
    ZH : 'zh',
    ZH_CN : 'zh-CN',
    ZH_HK : 'zh-HK',
    ZH_SG : 'zh-SG',
    ZH_TW : 'zh-TW',
}