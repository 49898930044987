import uuid from 'uuid/v4';
import { observable } from "mobx";
import { PatientName } from "./PatientName";
import { PatientSnils } from "./PatientSnils";
import { PatientPhone } from "./PatientPhone";
import { PatientGender } from "./PatientGender";
import { PatientBirthDay } from "./PatientBirthDay";
import { PatientDisability } from "./PatientDisability";
import { PatientAddress } from "./PatientAddress";
import { PatientEmail } from "./PatientEmail";
import { OmsPolicy } from "MODEL_STORE/FHIR/Elements/OmsPolicy";

import { TypeOfMedicalCard } from "./PatientMedicalRecord/TypeOfMedicalCard";
import { KindOfMedicalCard } from "./PatientMedicalRecord/KindOfMedicalCard";
import { MedicalRecordNumber } from "./PatientMedicalRecord/MedicalRecordNumber";

/**
 * информация о пациенте
 */
export class Patient {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * имя пациента
     */
    name = null;

    /**
     * номер телефона
     */
    phone = null;

    /**
     * электронная почта
     */
    email = null;

    /**
     * СНИЛС пациента
     */
    snils = null;

    /**
     * пол пациента
     */
    gender = null;

    /**
     * дата рождения
     */
    birthday = null;

    /**
     * полис ОМС
     */
    omsPolicy = null;

    /**
     * Инвалидность
     */
    disability = null;

    /**
     * Адрес
     */
    address = null;

    @observable medicalCardNumberIdentifierId = null;

    typeOfMedicalCard = null;
    kindOfMedicalCard = null;
    medicalRecordNumber = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
        this.name = new PatientName(this.resource);
        this.phone = new PatientPhone(this.resource);
        this.snils = new PatientSnils(this.resource);
        this.gender = new PatientGender(this.resource);
        this.birthday = new PatientBirthDay(this.resource);
        this.omsPolicy = new OmsPolicy(this.resource);
        this.disability = new PatientDisability(this.resource);
        this.address = new PatientAddress(this.resource);
        this.email = new PatientEmail(this.resource);
    }

    setMedicalCardNumberIdentifierId = (serviceRequest) => {
        this.findMedicalCardNumberIdentifierId(serviceRequest);
        this.typeOfMedicalCard = new TypeOfMedicalCard(this.medicalCardNumberIdentifier, this.resource);
        this.kindOfMedicalCard = new KindOfMedicalCard(this.medicalCardNumberIdentifier, this.resource);
        this.medicalRecordNumber = new MedicalRecordNumber(this.medicalCardNumberIdentifier, this.resource);
    }

    findMedicalCardNumberIdentifierId = (serviceRequest) => {
        if (serviceRequest.resource.extension) {
             let medicalCardNumberExtension = serviceRequest.resource.extension.find(extension =>
                extension.url === "http://hl7.org/fhir/fcsp/sd/fcsp-ex-servicerequest-medicalRecord");
             if (medicalCardNumberExtension) {
                this.medicalCardNumberIdentifier = medicalCardNumberExtension.valueReference.identifier.id;
             }
        }
    }

    onUpdateMedicalCardNumberIdentifierId = (newId) => {
        this.medicalCardNumberIdentifier = newId;
        this.typeOfMedicalCard = new TypeOfMedicalCard(this.medicalCardNumberIdentifier, this.resource);
        this.kindOfMedicalCard = new KindOfMedicalCard(this.medicalCardNumberIdentifier, this.resource);
        this.medicalRecordNumber = new MedicalRecordNumber(this.medicalCardNumberIdentifier, this.resource);
    }

    createMedicalCardNumberIdentifier = () => {
        if ( ! Array.isArray(this.resource.identifier) ) {
            this.resource.identifier = []
        }

        const newId = uuid();
        this.resource.identifier.push({
            id: newId,
            use: "official",
            system: "urn:oid:1.2.643.5.1.13.13.12.2.66.6795.100.1.1.16",
            type: {}
        });
        this.onUpdateMedicalCardNumberIdentifierId(newId);
    }

    /**
     * идентификатор ресурса
     */
    get id() {
        return this.resource.id;
    }
}
