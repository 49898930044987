/**
 * шаблон для генерации сторов видимости объектов
 *
 *
 */

import { observable, action } from 'mobx';



export class VisibilityStoreGenerator {

    /**
     *
     */
    @observable
    popupVisible = false;

    /**
     *
     */
    @action
    open(params) {
        this.popupVisible = true;
        this.params = params;
    }

    /**
     *
     */
    @action
    close() {
        this.popupVisible = false;
    }

}