import _get from "lodash/get";
import { observable } from "mobx";

/**
 * инвалидность пациента
 */
export class PatientAddress {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * значение
     */
    get text() {
        const use = "home";
        const address = _get(this.resource, "address", []).find(i => i.use === use);
        return _get(address, "text", "-");
    }
}
