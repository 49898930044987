import _get from "lodash/get";

/**
 * расширение с информацией о том,
 * на основе какого ресурса создан текущий
 */
export class CreatedFromExtension {

    /**
     * url расширения
     */
    static URL = "http://miramedix.ru/fhir/StructureDefinition/mmdx-ext-created-from";

    /**
     * ссылка на ресурс (Condition, Observation)
     */
    resource = null;

    /**
     * resource - ссылка на ресурс
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * ссылка на расширение
     */
    get extension() {
        return _get(this.resource, "extension", []).find(extension => {
            return _get(extension, "url") == CreatedFromExtension.URL;
        });
    }

    /**
     * ссылка на ресурс
     */
    get reference() {
        return _get(this.extension, "valueReference")
    }

    /**
     * идентификатор ресурса
     */
    get id() {
        return _get(this.reference, "id");
    }

    /**
     * обновление ссылки на ресурс
     */
    update = (identifier) => {
        if ( ! Array.isArray(this.resource.extension) ) {
            this.resource.extension = []
        }
        const extension = {
            "url": CreatedFromExtension.URL,
            "valueReference": {
                "id": identifier,
                "resourceType": this.resource.resourceType
            }
        }
        const index = this.resource.extension.findIndex(extension => {
            return _get(extension, "url") == CreatedFromExtension.URL;
        })
        if ( index >= 0 ) {
            this.resource.extension[index] = extension;
        } else {
            this.resource.extension.push(extension);
        }
    }

}
