import _get from "lodash/get";

/**
 * арм-роль сотрудника из роли
 * PractitionerRole.extension[]
 */
export class PractitionerRoleAppRoles {

    /**
     * ссылка на ресурс PractitionerRole
     */
    practitionerRoleResource = null;

    /**
     * url расширения
     */
    static EXTENSION_URL = "http://miramedix.ru/fhir/StructureDefinition/practitionerRole-appRoles";

    /**
     * инициализация
     */
    constructor(practitionerRoleResource) {
        this.practitionerRoleResource = practitionerRoleResource;
    }

    /**
     * ссылка на расширение
     */
    get extension() {
        return _get(this.practitionerRoleResource, "extension", []).find(extension => {
            return _get(extension, "url") == PractitionerRoleAppRoles.EXTENSION_URL;
        });
    }

    /**
     * есть ли ссылка на арм-роль?
     */
    hasReference = (appRoleIdentifier) => {
        return !! _get(this.extension, "extension", []).find(extension => {
            return !! (
                _get(extension, "url") == appRoleIdentifier
                &&
                _get(extension, "valueReference.id") == appRoleIdentifier
                &&
                _get(extension, "valueReference.resourceType") == "AppRole"
            )
        })
    }
    
}