/**
 * первичная система участника консилиума
 */
export const PRIMARY_PARTICIPANT_TYPE_SYSTEM = "http://terminology.hl7.org/CodeSystem/v3-ParticipationType";

/**
 * вторичная система участника консилиума
 */
export const SECONDARY_PARTICIPANT_TYPE_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/tb-participantType";

/**
 * система для признака присутствия участника консилиума
 */
export const PRESENCE_PARTICIPANT_TYPE_SYSTEM = "http://miramedix.ru/fhir/CodeSystem/distantParticipation";

/**
 * код для очного присутствия
 */
export const ONSITE_PRESENCE_CODE = "on-site";

/**
 * код для дистанционного присутствия
 */
export const REMOTE_PRESENCE_CODE = "remote";

/**
 * кодинг для лечащего врача
 */
export const REF_PRIMARY_CODING = {
    code: "REF",
    display: "referrer",
    system: PRIMARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для секретаря
 */
export const ATND_PRIMARY_CODING = {
    code: "ATND",
    display: "attender",
    system: PRIMARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для председателя
 */
export const PPRF_PRIMARY_CODING = {
    code: "PPRF",
    display: "primary performer",
    system: PRIMARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для вторичного типа участия
 */
export const SPRF_PRIMARY_CODING = {
    code: "SPRF",
    display: "secondary performer",
    system: PRIMARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для cпециалиста по хирургическому лечению
 */
export const SRGN_SECONDARY_CODING = {
    code: "ONCLG-SRGN",
    display: "Врач-онколог, специалист по хирургическому лечению",
    system: SECONDARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для дополнительного участника
 */
export const SUPPL_SECONDARY_CODING = {
    code: "SUPPL",
    display: "Дополнительный участник",
    system: SECONDARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для cпециалиста по лекарственному лечению
 */
export const MDCTN_SECONDARY_CODING = {
    code: "ONCLG-MDCTN",
    display: "Врач-онколог, специалист по лекарственному лечению",
    system: SECONDARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для cпециалиста по радиотерапии
 */
export const RDTHRPST_SECONDARY_CODING = {
    code: "RDTHRPST",
    display: "Специалист по радиотерапии",
    system: SECONDARY_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для очного присутствия
 */
export const ONSITE_DISTANT_CODING = {
    code: ONSITE_PRESENCE_CODE,
    display: "Очно",
    system: PRESENCE_PARTICIPANT_TYPE_SYSTEM
}

/**
 * кодинг для дистанционного присутствия
 */
export const REMOTE_DISTANT_CODING = {
    code: REMOTE_PRESENCE_CODE,
    display: "Дистанционно",
    system: PRESENCE_PARTICIPANT_TYPE_SYSTEM
}


/**
 * тип для очного присутствия участника
 */
export const ONSITE_PARTICIPANT_TYPE = {
    coding: [
        ONSITE_DISTANT_CODING
    ],
    text: "Очное присутствие"
}

/**
 * тип для дистанционного присутствия участника
 */
export const REMOTE_PARTICIPANT_TYPE = {
    coding: [
        REMOTE_DISTANT_CODING
    ],
    text: "Дистанционное присутствие"
}

/**
 * тип для председателя
 */
export const CHAIRMAN_PARTICIPANT_TYPE = {
    coding: [
        PPRF_PRIMARY_CODING
    ],
    text: "Председатель консилиума"
}

/**
 * тип для секретаря
 */
export const SECRETARY_PARTICIPANT_TYPE = {
    coding: [
        ATND_PRIMARY_CODING
    ],
    text: "Секретарь консилиума"
}

/**
 * тип для лечащего врача
 */
export const REFERRER_PARTICIPANT_TYPE = {
    coding: [
        REF_PRIMARY_CODING
    ],
    text: "Лечащий врач"
}

/**
 * тип для cпециалиста по хирургическому лечению
 */
export const SURGICAL_PARTICIPANT_TYPE = {
    coding: [
        SPRF_PRIMARY_CODING,
        SRGN_SECONDARY_CODING
    ],
    text: "Врач-онколог, специалист по хирургическому лечению"
}

/**
 * тип для cпециалиста по лекарственному лечению
 */
export const DRUG_TREATMENT_PARTICIPANT_TYPE = {
    coding: [
        SPRF_PRIMARY_CODING,
        MDCTN_SECONDARY_CODING
    ],
    text: "Врач-онколог, специалист по лекарственному лечению"
}

/**
 * тип для cпециалиста по радиотерапевтическому лечению
 */
export const RADIATION_THERAPY_PARTICIPANT_TYPE = {
    coding: [
        SPRF_PRIMARY_CODING,
        RDTHRPST_SECONDARY_CODING
    ],
    text: "Врач-радиотерапевт"
}

/**
 * тип для дополнительного участника консилиума
 */
export const ADDITIONAL_PARTICIPANT_TYPE = {
    coding: [
        SPRF_PRIMARY_CODING,
        SUPPL_SECONDARY_CODING
    ],
    text: "Дополнительный участник"
}

/**
 * председатель (arm_chairman)
 */
export const CHAIRMAN_PARTICIPANT = {
    type: [
        CHAIRMAN_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * секретарь (arm_secretary)
 */
export const SECRETARY_PARTICIPANT = {
    type: [
        SECRETARY_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * лечащий врач (arm_practitioner)
 */
export const REFERRER_PARTICIPANT = {
    type: [
        REFERRER_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * cпециалист по хирургическому лечению (arm_expert)
 */
export const SURGICAL_PARTICIPANT = {
    type: [
        SURGICAL_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * cпециалист по лекарственному лечению (arm_expert)
 */
export const DRUG_TREATMENT_PARTICIPANT = {
    type: [
        DRUG_TREATMENT_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * cпециалист по радиотерапевтическому лечению (arm_expert)
 */
export const RADIATION_THERAPY_PARTICIPANT = {
    type: [
        RADIATION_THERAPY_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * дополнительный участник консилиума (arm_expert)
 */
export const ADDITIONAL_PARTICIPANT = {
    type: [
        ADDITIONAL_PARTICIPANT_TYPE,
        ONSITE_PARTICIPANT_TYPE
    ]
}

/**
 * участники при создании/редактировании консилиума
 */
export const DEFAULT_APPOINTMENT_PARTICIPANTS = [
    CHAIRMAN_PARTICIPANT, // председатель
    SECRETARY_PARTICIPANT, // секретарь
    SURGICAL_PARTICIPANT, // cпециалист по хирургическому лечению
    DRUG_TREATMENT_PARTICIPANT, // cпециалист по лекарственному лечению
    RADIATION_THERAPY_PARTICIPANT, // cпециалист по радиотерапевтическому лечению
]

/**
 * участники при создании/редактировании протокола
 */
export const DEFAULT_PROTOCOL_ENCOUNTER_PARTICIPANTS = [
    CHAIRMAN_PARTICIPANT, // председатель
    SECRETARY_PARTICIPANT, // секретарь
    REFERRER_PARTICIPANT, // лечащий врач
    SURGICAL_PARTICIPANT, // cпециалист по хирургическому лечению
    DRUG_TREATMENT_PARTICIPANT, // cпециалист по лекарственному лечению
    RADIATION_THERAPY_PARTICIPANT, // cпециалист по радиотерапевтическому лечению
]

/**
 * участники при создании/редактировании команды учатников
 */
export const DEFAULT_CARE_TEAM_PARTICIPANTS = [
    CHAIRMAN_PARTICIPANT, // председатель
    SECRETARY_PARTICIPANT, // секретарь
    SURGICAL_PARTICIPANT, // cпециалист по хирургическому лечению
    DRUG_TREATMENT_PARTICIPANT, // cпециалист по лекарственному лечению
    RADIATION_THERAPY_PARTICIPANT, // cпециалист по радиотерапевтическому лечению
]
