/**
 * https://www.hl7.org/implement/standards/fhir/backboneelement.html
 */

import { Element } from './Element';
import _clone from 'lodash.clone';


export class BackboneElement extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'BackboneElement';
    }

    /**
     * тут всё унаследовано от базового типа
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'modifierExtension',
        ]);

        // если мы инициализируемся именно как BackboneElement, нужно
        // скопировать все данные, какие есть, так как тип расширяемый

        let noCopy = [
        ];

        for (let key in data) {
            if (noCopy.indexOf(key) < 0) {
                this._data[key] = _clone(data[key], true);
            }
        }

    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check extension
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////


}

