import _get from "lodash/get";

/**
 * описание нозологической формы
 */
export class ConditionDescription {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * описание нозологической формы
     */
    get value() {
        return _get(this.conditionResource, "text.div", "");
    }

    /**
     * изменение описания нозологической формы
     */
    onChange = (value) => {
        if(typeof value == "string" && value.trim().length) {
            this.change(value);
        } else {
            this.reset();
        }
    }

    /**
     * создание/изменение описания нозологической формы
     */
    change = (value) => {
        this.conditionResource.text = {
            div: value,
            status: "additional"
        }
    }
    
    /**
     * сброс/удаление описания нозологической формы
     */
    reset = () => {
        if(this.conditionResource.text) {
            delete(this.conditionResource.text);
        }        
    }

}
