import _sortBy from "lodash/sortBy";
import { AbstractValueSet } from "./AbstractValueSet";

/**
 * справочник целей проведения консилиума
 */
export class ConsiliumRequestReasonValueSet extends AbstractValueSet {

    /**
     * при инициализации сразу загружаем справочник
     */
    constructor() {
        super("/ValueSet/fcsp-vs-nsi-1-2-643-5-1-13-13-11-1506/$expand");
    }

    /**
     * варианты для ReactSelect
     */
    get options() {
        return _sortBy(this.concepts, "property.sorder").map(concept => ({
            value: concept.code,
            label: concept.display,
            concept: concept
        }));
    }

}
