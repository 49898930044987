/**
 * https://www.hl7.org/implement/standards/fhir/valueset-request-intent.html
 */

export const REQUEST_INTENT = {
    PROPOSAL        : 'proposal',
    PLAN            : 'plan',
    DIRECTIVE       : 'directive',
    ORDER           : 'order',
    ORIGINAL_ORDER  : 'original-order',
    REFLEX_ORDER    : 'reflex-order',
    FILLER_ORDER    : 'filler-order',
    INSTANCE_ORDER  : 'instance-order',
    OPTION          : 'option'
}