import _get from "lodash/get";
import { observable } from "mobx";
import _orderBy from "lodash/orderBy";
import { ConsiliumRequestReasonValueSet } from "MODEL_STORE/DataSource/Stores/ValueSets/ConsiliumRequestReasonValueSet";

/**
 * система для указания цели проведения консилиума
 */
const SYSTEM = "urn:oid:1.2.643.5.1.13.13.11.1506";

/**
 * CodeableConcept.coding соответствует цели проведения консилиума?
 */
const IS_APPOINTMENT_REASON_CODING = (coding) => {
    return _get(coding, "system") == SYSTEM;
}

/**
 * CodeableConcept соответствует цели проведения консилиума?
 */
const IS_APPOINTMENT_REASON_CONCEPT = (codeableConcept) => {
    return _get(codeableConcept, "coding", []).find(IS_APPOINTMENT_REASON_CODING);
}

/**
 * создание CodeableConcept для ServiceRequest.reasonCode[]
 */
const MAKE_REASON_CODE_CODEABLE_CONCEPT = (conceptResource) => {
    return {
        text: conceptResource.definition,
        coding: [{
            code: conceptResource.code,
            system: conceptResource.system,
            version: conceptResource.version,
            display: conceptResource.display
        }]
    }
}

/**
 * класс для управления целью проведения консилиума
 * хранится как одна из reasonCode[] в ресурсе ServiceRequest
 */
export class AppointmentReason {

    /**
     * ссылка на ресурс ServiceRequest
     */
    @observable resource = null;

    /**
     * справочник целей проведения консилиума
     */
    static valueset = null;

    /**
     * resource - ссылка на ресурс ServiceRequest
     */
    constructor(resource) {
        this.resource = resource;
        if ( ! AppointmentReason.valueset ) {
            AppointmentReason.valueset = new ConsiliumRequestReasonValueSet();
        }
    }

    /**
     * список целей проведения консилиума (для ReactSelect)
     */
    get options() {
        return AppointmentReason.valueset.options;
    }

    /**
     * поиск необходимого CodeableConcept в ServiceRequest.reasonCode[]
     */
    get concept() {
        return _get(this.resource, "reasonCode", []).find(IS_APPOINTMENT_REASON_CONCEPT);
    }

    /**
     * поиск индекса CodeableConcept в ServiceRequest.reasonCode[]
     */
    get conceptIndex() {
        return _get(this.resource, "reasonCode", []).findIndex(IS_APPOINTMENT_REASON_CONCEPT);
    }

    /**
     * поиск coding в CodeableConcept
     */
    get coding() {
        return _get(this.concept, "coding", []).find(IS_APPOINTMENT_REASON_CODING);
    }

    /**
     * код цели проведения консилиума (например, "X05")
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название цели проведения консилиума (например, "Изменение тактики лечения")
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение для ReactSelect
     */
    get defaultValue() {
        return this.code && this.display ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * при изменении цели проведения консилиума
     */
    onChange = ({concept}) => {
        return concept ? this.change(MAKE_REASON_CODE_CODEABLE_CONCEPT(concept)) : this.reset();
    }

    /**
     * изменение цели проведения консилиума
     */
    change = (codeableConcept) => {
        if(this.conceptIndex >= 0) {
            this.updateReasonCode(codeableConcept);
        } else {
            this.createReasonCode(codeableConcept);
        }
    }

    /**
     * изменение цели проведения консилиума
     */
    updateReasonCode = (codeableConcept) => {
        this.resource.reasonCode[this.conceptIndex] = codeableConcept;
    }

    /**
     * создание цели проведения консилиума
     */
    createReasonCode = (codeableConcept) => {
        if( ! Array.isArray(this.resource.reasonCode) ) {
            this.resource.reasonCode = [];
        }
        this.resource.reasonCode.push(codeableConcept);
    }

    /**
     * сброс/удаление цели проведения консилиума
     */
    reset = () => {
        if(this.conceptIndex >= 0) {
            this.resource.reasonCode.splice(this.conceptIndex, 1);
        }
    }

}
