import { observable } from "mobx";

/**
 * информация о статусе слота (свободен/занят)
 */
export class AppointmentSlotStatus {

    /**
     * ссылка на ресурс слота
     */
    @observable slotResource = null;

    /**
     * инициализация
     */
    constructor(slotResource) {
        this.slotResource = slotResource;
    }

    /**
     * свободен?
     */
    get isFree() {
        return this.slotResource.status == "free";
    }

    /**
     * занят?
     */
    get isBusy() {
        return this.slotResource.status == "busy";
    }

}