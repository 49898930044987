export const THEMES = {
    //main
    darkMainTextColor: '#232735',
    darkMainTextColorRGB: '35, 39, 53',
    darkMainThemeColor: '#121232',
    lightenMainTextColor: '#8B90A0',
    mediumTextColor: '#505565',
    nodataTextColor: '#9C9C9C',
    lightGrayText: '#9C9C9C',
    subLabelTextColor:'#949494',
    whiteColor: '#FFFFFF',
    whiteColorRGB: '255, 255, 255',
    blackColorRGB: '0, 0, 0',
    blackColor: '#000000',
    mainBlueColor: '#2D9CDB',
    mainDarkBlueColor: '#1B75BB', //Новый темный синий
    mainLightBlueColor: '#C1DEF3', //Новый голубой
    mainGreenColor: "#27AE60",
    mainLightBlueHover: '#E6F2FA',
    mainRedColor: '#EB5757',
    mainRedHoverColor: '#DE5252',
    mainTransition: 'all .3s ease',
    transition: '.3s ease',
    mainIconBGHover: '#F0F1F3',
    secondIconBGHover: '#D1E9FA',
    titleBGGray: '#F0F1F3',
    mainBGGray: '#FFFFFF',
    innerBorderColor: '#DBDBDF',
    innerLightenBorderColor: '#F0F1F3',
    mainYellowColor: '#F2C94C',
    borderColor: '#F0F1F3',
    disabledIconColor: "#D3D4D8",
    greyColor: '#FAFAFC',
    warningOrangeColor: "#F2994A",
    contentBG: '#F0F0F0',
    disabledSaveIconColor: '#A1A4B1',
    activeConsiliumItemLeftBorder: '#114D7A',
    mainBoxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    mainHoverBlock: "#E9F5FC",
    mainActiveBlock: "#C1DEF3",
    selectedExpertBG: '#C1DEF3',
    viewModeBorderColor: '#D3D4D8',

    //breadcrumbs
    defaultBreadcrumbsColor: '#D3D4D8',

    //buttons
    blueButtonBG: '#1B75BB',
    blueButtonBGHover: '#2F80ED',
    darkButtonBG: '#1B75BB',
    darkButtonBGHover: '#2894e8',
    redButtonBG: '#EB5757',
    redButtonBGHover: '#FF8383',
    disabledFontColor: '#8B90A0',
    defaultButtonBG: '#FAFAFC',
    defaultButtonBorderColor: '#D3D4D8',
    defaultButtonTextColor: '#121232',
    defaultButtonHoverBG: '#e2e6ea',
    defaultButtonHoverBorder: '#dae0e5',
    plusButtonColor: '#2F80ED',
    orangeButtonColor: '#F2994A',
    orangeButtonBGHover: '#FFAE66',
    greenButtonBG: '#27AE60',
    greenButtonBGHover: '#5BCB8A',
    greyButtonBG: '#95A5A6',
    greyButtonBGHover: '#C2C2C2',
    disabledButtonBG: '#D3D4D8',
    disabledButtonBorder: '#A1A4B1',
    outlinedButtonBorder: '#1B75BB',
    outlinedButtonBorderDisabled: '#D3D4D8',

    //Icon Button
    iconButtonFill: '#A1A4B1',
    iconButtonFillDisabled: '#D3D4D8',
    iconButtonHoverBG: 'rgba(27, 117, 187, 0.1)',
    iconButtonActiveBG: 'rgba(27, 117, 187, 0.2)',
    iconButtonDisabled: '#D3D4D8',
    iconButtonDEfault: '#A1A4B1',

    //Radio Button
    defaultRadioColor: '#A1A4B1',
    activeRadioColor: "#1B75BB",


    //input, select, multiselect
    textareaTextColor: '#8B90A0',
    inputBorderColor: '#A1A4B1',
    disabledTextareaBG: '#F0F1F3',
    disabledTextareaBorder: '#D3D4D8',
    inputBorderHover: '#2D9CDB',
    inputBorderActive: '#2D9CDB',
    inputDisabledBG: '#F0F1F3',
    inputDisabledBorder: '#D3D4D8',
    errorInputBorderColor: '#EB5757',

    //NEW input, select, multiselect, textarea
    controlBorderColor: '#A1A4B1',
    controlBorderColorHover: '#121232',
    controlBorderColorActive: '#1B75BB',
    controlBorderColorError: '#EB5757',
    controlBorderColorDisabled: '#D3D4D8',
    controlBgColor: '#FFFFFF',
    controlBgColorHover: '#FAFAFC',
    controlBgColorDisabled: '#F0F1F3',
    controlPlaceholderColor: '#D3D4D8',
    controlPlaceholderDisabledColor: '#8B90A0',
    controlTextColor: '#232735',
    controlTextColorDisabled: '#8B90A0',
    controlLabelColor: '#232735',
    controlLabelColorError: '#EB5757',

    //datepicker, timepicker
    datepickerPopperBorder: '#1B75BB',
    datePickerMenuItemBGHover: '#C1DEF3',
    datePickerMenuItemBGActive: '#C1DEF3',
    datepickerSelectBorder: '#A1A4B1',
    datePickerRangeBorderColor: '#D3D4D8',
    navArrowColor: '#A1A4B1',
    outsideMonthColor: '#8B90A0',
    customInputArrow: '#A1A4B1',
    periodEdgeDaysBG: '#1B75BB',
    datePickerNormalFontColor: '#505565',
    datePickerDisabledBG: '#F0F1F3',
    datePickerDisabledBorder: '#D3D4D8',
    datepickerCalendarIconColor: '#BDBDBD',
    datePickerDisabledIcon: '#D3D4D8',
    datePickerIcon: '#A1A4B1',
    datePickerDayHoverBG: '#E9F5FC',

    //Native select for year and month in datepicker
    optionBgHover: '#E9F5FC',
    optionBgSelected: '#C1DEF3',

    //Heading Block
    headerBlockBG: '#F2FAFD',

    //Participant
    participantBlockBG: '#505565',
    participantRoleDefaultBG: '#00A3FF',

    //Tooltip
    tooltipBG: '#114D7A',

    //Link
    linkHoverColor: '#1B75BB',

    //COMPONENTS:

    //Header
    headerBG: '#1B75BB',
    headerPageTitleColor: '#F0F1F3',
    headerBellHoverBG: '#8CD0F3',

    //Header Notifications
    notificationLeftBorderColor: '#114D7A',
    lightNotificationText: '#A1A4B1',
    notificationItemHoverBG: '#F2FAFD',
    notificationFiltersColor: '#A1A4B1',
    notificationFilterIconActiveColor: 'rgba(27, 117, 187, 0.2)',

    //Menu
    userPanelBG: '#A1A4B1',
    mainItemBG: '#F0F1F3',
    menuItemFontColor: '#505565',
    activeItemBG: '#121232',
    optionFontColor: '#232735',
    amountBgColor: '#F2C94C',
    menuTransition: '.5s ease',

    //Table
    darkTableHeaderBG: '#232735',
    lightTableHeaderBG: '#F0F1F3',
    whiteHeaderBG: '#FFFFFF',
    // rowBorderColor: '#D3D4D8',
    rowBorderColor: '#F0F1F3',
    // hoverRowBG: "#F0F1F3",
    hoverRowBG: "#E9F5FC",
    headerBorderColor: '#D3D4D8',
    toggleArrowBG: '#FAFAFC',
    activeRow: '#E7F5FD',


    //CheckBox
    checkBoxBorderColorUnchecked: '#A1A4B1',
    checkBoxHoverShade: 'rgba(27, 117, 187, 0.1)',
    checkedColorBlue: '#1B75BB',
    checkedColorDefault: '#121232',
    checkedColorWhite: '#FFFFFF',
    checkedColorDisabled: '#9C9C9C',
    CheckedLabelColorDefault: '#232735',
    checkedBorderColorDefault: '#A1A4B1',

    //Modals
    modalHeaderDarkBG: '#121232',
    modalHeaderBlueBG: '#1B75BB',
    modalLabelColor: '#121232',
    modalBlockBG: '#FAFAFC',
    topBorderModalBottomColor: '#F0F1F3',

    //Status
    statusCreatedFont: '#0E578B',
    statusCreatedBG: '#D1E9FA',
    statusDeletedFont: '#D91E18',
    statusDeletedBG: '#FDEDED',
    statusAssignedFont: '#2A8859',
    statusAssignedBG: '#DCF4E8',
    statusMatchingFont:'#DF9606',
    statusMatchingBG:'#FFFDEB',
    statusCanceledFont: '#6C7A89',
    statusCanceledBG: '#F2F1EF',
    statusSignedBG: '#DCF4E8',
    statusStoppedFont: '#DF9606',
    statusStoppedBG: '#FFFDEB',
    statusBreakFont: '#D91E18',
    statusBreakBG: '#FDEDED',
    statusFinishedFont: '#6C7A89',
    statusFinishedBG: '#F2F1EF',
    statusErrorFont: '#ffffff',
    statusErrorBG: '#ff0000',
    statusBG: '#D3D4D8',
    statusPublishingFont: '#DF9606',
    statusSignedFont: '#4EAD89',
    statusPublishingBG: '#FFFDEB',

    //Статусы во вкладке Статус Лечения (Планы лечения)
    injectedStatusColor: '#27AE60',
    stoppedStatusColor: '#F4CD51',
    notInjectedStatusColor: '#EB5757',
    disabledLabelColor: '#A1A4B1',
    //Планы лечения, цвет строк таблиц в зависимости от статуса
    rowInProgressBG: 'rgba(39, 174, 95, .05)',
    rowStoppedBG: 'rgba(244, 205, 81, .15)',
    //Статусы во вкладке Статус Лечения (Планы лечения)

    //MKB Code
    mkbCodeBG: '#E7F5FD',
    mkbSelectedCodeBG: '#27AE60',
    mkbCodeHoverActive: '#C1DEF3',
    mkbCodeIcon: '#A1A4B1',

    //Chips
    chipBG: '#E7F5FD',
    chipBGHoverActive: '#C1DEF3',
    chipTextColor: '#232735',

    //Warning
    warningColor: '#f44',

    //Day
    dayWithLoadingDoseBG: '#F2994A',
    daySimpleBG: '#2D9CDB',

    confirmedMedicineRGB: '39, 174, 96',
    notConfirmedMedicineRGB: '235, 87, 87',
    creatininRequiredErrorRGB: '255, 83, 73',

    //Roles practitioner
    chairmanBGColor: '#9B51E0',
    secretaryBGColor: '#2D9CDB',
    expertBGColor: '#27AE60',
    consultantBGColor: '#232735',


    //Pagination
    pageNumberHoverBG: '#E9F5FC',
    pageNumberActiveBG: '#1B75BB',
    pageNumberNormalFontColor: '#8B90A0',
    pageNumberNormalArrowColor: '#A1A4B1',
    paginationArrowBG: '#A1A4B1',
    paginationSelectBorderColor: '#A1A4B1',

    //PAGES:

    //Dashboard

    //Scheme
    schemeHeaderBG: '#C1DEF3',
    schemeHeaderFontColor: '#232735',

    //Calendar
    borderNavigation: '#D3D4D8',
    borderCellActive: '#00A3FF',
    bgColorAppointments: '#F0F1F3',
    bgColorToday: '#1B75BB',
    blueAppointmentItem: '#2D9CDB',
    greenAppointmentItem: '#27AE60',
    orangeAppointmentItem: '#F2C94C',
    defaultAppointmentItem: '#A1A4B1',


    //Medicals
    topSearchBG: '#F7F7F7',
    patientBottomBorder: '#cdcdcd',
    patientInfoBG: '#F0F1F3',
    patientInfoLabelColor: '#121232',
    statusTextColor: '#0E578B',
    statusBlockBG: '#D1E9FA',
    moreInfoBorderColor: '#A1A4B1',
    patientCheckBoxFontColor: '#505565',
    ifCompletedExistsColor: '#F0F1F3',
    groupMedicineBG: '#4E4E5A',
    topContainerBG: '#fff',

    //Notification block
    noticeColor: 'rgba(242, 201, 76, 0.25)',
    exclamationColor: '#F2994A',
    notificationMessageColor: '#674323',
    NotificationLinkColor: '#2D9CDB',

    //SelectedItem (выбранный элемент с иконокй Корзина)
    selectedIconBG: '#F0F1F3',
    selectedItemFontColor: '#232735',
    selectedIconTrashColorRed: '#EB3F3F',
    selectedIconTrashColorBlue: '#1B75BB',
    selectedIconTrashColorDefault: '#232735',

    //Therapy block
    therapyGroupHeaderBG: '#F0F1F3',
    doseFontColor: '#8B90A0',
    defaultItemBG: '#D8D8D8',
    optionCircleBG: '#2F80ED',
    timePeriodFontColor: 'rgba(35, 39, 53, 0.7)',
    breakFontColor: '#8B90A0',
    verticalLineColor: '#656872',
    TherapyMainBlockBG: '#E5E5E5',
    diagnosisListBG: '#F2FAFD',
    optionBlockHoverBG: '#1B75BB',

    //Counter
    counterBG: '#F2C94C',


    //ContextMenu
    contextMenuBorder: '#A1A4B1',

    //ContextualMenu
    contextualMenuItemHoverBG: '#E7F5FD',
    contextualMenuItemActiveBG: '#C1DEF3',

    //Notification Block
    defaultNotificationBlock: '#2D9CDB',
    warningNotificationBlock: '#F2994A',
    warningNotificationBlockBG: '#F2C94C',
    errorNotificationBlockBG: '#F8D7DA',
    errorNotificationBlockText: '#721C24',

    //Therapy Table
    LineTherapyInactiveRow: '#F0F1F3',

    //Accordion
    choseMedicineAccordion: '#FAFAFC',
    accordionArrowButtonBG: '#D3D4D8',
    accordionHeaderHoverBG: '#FAFAFC',
    activeAccordionColor: '#1B75BB',

    //Status Tab
    statusGroupTopBG: '#D7DADF',

    //Incompatibility Level
    highLevel: '#EB5757',
    mediumLevel: '#F2994A',
    neutralLevel: '#2D9CDB',
    defaultLevel: '#D8D8D8',

    //Break block
    breakBlockBG: '#F0F1F3',
    //Cycle days block
    periodNameFontColor: '#727279',

    cycleAddButtonBG: '#F0F1F3',

    //Tabs
    tabFontColorDisabled: '#A1A4B1',

    //Consilium List
    consiliumItemBorderColor: '#D3D4D8',
    consiliumItemHoverBG: '#F0F1F3',
    consiliumItemBottomIconHover: '#D1E9FA',
    consiliumIconColor: '#1B75BB',
    noConsiliumIconColor: '#BDBDBD',

    //HealthJournal
    weekendTextColor: '#949494',
    goodStateColor: '39, 174, 96',
    normalStateColor: '244, 205, 81',
    badStateColor: '250, 172, 70',
    terribleStateColor: '235, 87, 87',
    medicineHelpedStatus: '#27AE60',
    medicineNotHelpedStatus: '#EB5757',
    medicineUndefinedStatus: '#1B75BB',
    healthRowHoverBG: '#F2FAFD',
    healthJournalBlueColor: '#1B75BB',

    //
    pdfFileBG: '#FAFAFC',

    //
    sendIconColor: '#333333',
    //---

    //Free place
    freePlaceBorderColor: '#D3D4D8',
    freePlaceHumanColor: '#949494',
    freePlaceAddColor: '#2F80ED',
    placeActiveColor: '#1B75BB',

    //RolesManagement
    noDataFoundTextColor: '#A1A4B1',

    //Tabs
    isErrorTabColor: '#F93737',
    isSuccessTabColor: '#1EDB6E',
    tabColor: '#232735',
    activeTabColor: '#1B75BB',
    activeTabBG: '#FAFAFC',

    //FONTS
    OpenSansRegular: "'opensans', Arial, sans-serif",
    OpenSansSemiBold: "'opensanssemibold', Arial, sans-serif",
    OpenSansBold: "'opensansbold', Arial, sans-serif",

    //FONT SIZES
    microFontSize: '6px',
    tinyFontSize: '8px',
    minFontSize: '10px',
    normalFontSize: '12px',
    footNoteFontSize: '12px',
    mediumFontSize: '14px',
    bigFontSize: '16px',
    eighteenFontSize: '18px',
    twentyFontSize: '20px',
    veryBigFontSize: '24px',
};
