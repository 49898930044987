export const  ACCESS_RIGHT_ACTION = {
    VIEW: "view",
    CREATE: "create",
    EDIT: "edit",
    REMOVE: "remove",
    ACTIVE: "active",
    ACTIVATION: "activation",
    REVOKED: "revoked",
    COMPLETED: "completed",
    ASSIGN: "assign",
    COMMENT: "comment",
    START: "start",
    SIGN: "sign",
    SCANNED_ADD: "scanned_add",
    CANCEL: "cancel",
    COPY: "copy",
    CONFIRM: "confirm",
    PAUSED: "paused",
    INTERRUPTED: "interrupted",
    PRINT: "print",
    ARCHIVED: "archived",
    RETURN_ACTIVE: "return_active",
    FINISH_SIGNING: "finish_signing",
    CLEAR_SIGNATURES: "clear_signatures",
    CREATE_FROM_PATIENT: "create_from_patient",
    MOVE: "move"
};

export const  ACCESS_RIGHT_INCLUDES = {
    NULL: "",
    OWN: "own",
    ALL: "all",
    INVITED: "invited"
};

export const DOC_TYPE = {
    NULL: "",
    DOCX: "docx"
}


export const  ACCESS_RIGHT_OBJECT = {
    MENU_DESKTOP: "menu_desktop",
    MENU_PATIENT: "menu_patient",
    MENU_PLAN: "menu_plan",
    MENU_TEMPLATE: "menu_template",
    MENU_SERVICEREQUEST: "menu_servicerequest",
    MENU_ALLDOCUMENTS: "menu_alldocuments",
    MENU_APPOINTMENT: "menu_appointment",
    MENU_PRACTITIONER: "menu_practitioner",
    MENU_ORGANIZATION: "menu_organization",
    MENU_PROTOCOL: "menu_protocol",
    MENU_SECURITY_ROLES: "menu_security_roles",
    PRACTITIONER_LIST: "practitioner_list",
    ORGANIZATION_LIST: "organization_list",
    PLAN_LIST: "plan_list",
    PATIENT_PLAN_LIST: "patient_plan_list",
    PATIENT_LIST: "patient_list",
    SERVICEREQUEST_LIST: "servicerequest_list",
    APPOINTMENT_LIST: "appointment_list",
    SERVICEREQUEST_PROTOCOL_LIST: "servicerequest_protocol_list",
    SERVICEREQUEST_PROTOCOL_LIST_REPORT: "servicerequest_protocol_list_report",
    DESKTOP: "desktop",
    PATIENT: "patient",
    PROTOCOL: "protocol",
    SERVICEREQUEST: "servicerequest",
    ALLDOCUMENTS_LIST: "alldocuments_list",
    APPOINTMENT: "appointment",
    PATIENT_CYCLE: "patient_cycle",
    PATIENT_PLAN: "patient_plan",
    SERVICEREQUEST_STATUS: "servicerequest_status",
    SERVICEREQUEST_PROTOCOL: "servicerequest_protocol",
    SERVICEREQUEST_PROTOCOL_EDOC: "servicerequest_protocol_edoc",
    SERVICEREQUEST_PRESENTATION: "servicerequest_presentation",
    APPOINTMENT_EXPERT: "appointment_expert",
    SERVICEREQUEST_LONGREAD: "servicerequest_longread",
    PRACTITIONER: "practitioner",
    ORGANIZATION: "organization",
    PLAN: "plan",
    CYCLE: "cycle",
    CYCLE_NAME: "cycle_name",
    MEDICATION: "medication",
    CYCLE_STARTDATE_DURATION: "cycle_startdate_duration",
    CYCLE_PATIENT_CONDITION: "cycle_patient_condition",
    CYCLE_DAILY_SUMMARY: "cycle_daily_summary",
    MODAL_OK: "modal_ok",
    PLAN_DIAGNOSIS: "plan_diagnosis",
    CYCLE_STATUS: "cycle_status",
    CYCLE_TREATMENT_STATUS: "cycle_treatment_status",
    CYCLE_TREATMENT_OUTCOMES: "cycle_treatment_outcomes",
    CYCLE_TEXT_RESULTS: "cycle_text_results",
    CYCLE_PROTOCOL: "cycle_protocol",
    CYCLE_SCHEME_KSG: "cycle_scheme_ksg",
    CYCLE_CLINREC: "cycle_clinrec",
    CYCLE_QUESTIONNAIRE: "cycle_questionnaire",
    CYCLE_COMMENT: "cycle_comment",
    PLAN_PROTOCOL: "plan_protocol",
    PLAN_NAME: "plan_name",
    PLAN_STATUS: "plan_status",
    TEMPLATE: "template",
    TEMPLATE_FEATURES: "template_features",
    SECURITY_ROLES: "security_roles",
    HEADER_AVATAR: "header_avatar",
    HEADER_ACTIONS: "header_actions",
    HEADER_UPTODATE: "header_uptodate",
    CARETEAM_MENU: "careteam_menu",
    CARETEAM_LIST: "careteam_list",
    CARETEAM: "careteam",
    TASK: "task",
    SERVICEREQUEST_PROTOCOL_RETURN_TO_SIGN: "servicerequest_protocol_return_to_sign",
    AVAILABILITY_OF_GLOBAL_SEARCH: "availability_of_global_search",
    ORDERS_LIST: "orders_list",
    ORDERS_LIST_DATE: "orders_list_date",
    CYCLE_ONHOLD: "cycle_onhold",
    PLAN_NEW_DIAGNOSIS: "plan_new_diagnosis",
    CYCLE_NUMBER: "cycle_number",
    CHECKUPS_OBLIGATION: "checkups_obligation",
};
