
;__webpack_public_path__ = (window && window.oncoParams && window.oncoParams.basePath ? window.oncoParams.basePath.replace(/\/+$/, '') + '/' : '/');

//
// если нужно сделать так. чтобы проект работал не из корня сайта,
// то нужно в index.html вписать вот такой скриптик:
//
// <script>
//     window.oncoParams = {
//         basePath: '/ok-arm',
//         configPath: '/ok-arm/config.json'
//     };
// </script>

//
// basePath попадет в __webpack_public_path__, от него зависят:
// 1) basename в компоненте BrowserRouter, это добавит префикс ко всем роутам реакта
// 2) file-loader в вебпаке тоже реагирует на этот параметр,
//    причем не так, как написано в документации, а по умолчанию, опции не нужны
//
// кроме того, в том же index.html нужно подменить пути к основным бандлам, поскольку
// параметр __webpack_public_path__ на это уже не влияет
//
//
// в контейнеризованном запуске и в кубере добавление скрипта делает nginx с помощью вот
// такой конструкции в блоке location, чтобы не делать отдельную сборку:
//
//        sub_filter_once off;
//        sub_filter 'src="/' 'src="/ok-arm/';
//        sub_filter 'href="/' 'href="/ok-arm/';
//        sub_filter '<head>' '<head><script>window.oncoParams = { basePath: "/ok-arm/" };</script>';
//
