/**
 * тут просто куча разных констант, которые, возможно, надо иногда рефакторить
 */

// значение по умолчанию для параметра PractitionerRole.code[].coding.version
// aidbox ожидает именно строку
export const PRACTITIONER_ROLE_CODE_VERSION = '4.2';

// значение по умолчанию для параметра PractitionerRole.specialty[].coding.version
export const PRACTITIONER_ROLE_SPECIALTY_VERSION = '4.2';

// TODO использовать везде эту константу. сейчас просто некогда рефакторить
export const RELATED_CONDITION_EXTENSION_URL = "http://hl7.org/fhir/StructureDefinition/condition-related";


export const MOVE_UP = 1
export const MOVE_DOWN = -1
export const ARM_CHEMOTHERAPIST = "arm_chemotherapist";
export const ARM_HLCHEMOTHERAPIST = "arm_hlchemotherapist";
export const ARM_ADMINISTRATOR = "arm_administrator";
