import { _BasicStore } from 'MODEL_STORE/DataSource/Stores/_BasicStore';
import { Organization } from 'MODEL_STORE/FHIR/Resources/Organization';
import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';

export class OrganizationStore extends _BasicStore {

    /**
     *
     */
    resourceUri = 'Organization';

    /**
     *
     */
    createElement(data) {
        return new Organization(data.resource);
    }

    search = (params, count = 999) => {

        // да, именно так, а не дефолтный параметр, ибо могут и null передать
        params = params || {};
        this.setLoadingState(true);

        if (this._order) {
            params._sort= this._order;
        }

        params.limit = count;

        let queryArray = []
        Object.keys(params).forEach(filterName => {
            queryArray.push(`${filterName}=${params[filterName]}`)
        });
        const query = queryArray.join("&");


        return basicRequestGet(`/api/mm/organizations?${query}`)
            .then(response => {
                this.setData(response.data.data);
                return this.data;
            })
            .finally(data => {
                this.setLoadingState(false);
                return data;
            });
    };

}
