import _get from "lodash/get";

/**
 * комментарий от врача
 */
export class ConditionComment {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * комментарий от врача
     */
    get value() {
        if( Array.isArray(this.conditionResource.note) ) {
            if ( this.conditionResource.note.length ) {
                return _get(this.conditionResource, "note.0.text", "");
            }
        }
        return "";
    }

    /**
     * изменение комментария
     */
    onChange = (value) => {
        if(typeof value == "string" && value.trim().length) {
            this.update(value);
        } else {
            this.remove();
        }
    }

    /**
     * добавление комментария
     */
    update = (value = "") => {
        this.conditionResource.note = [{
            "text": value
        }]
    }

    /**
     * удаление комментария
     */
    remove = () => {
        if ( this.conditionResource.note ) {
            delete(this.conditionResource.note);
        }
    }

    /**
     * удаление комментария (алиас)
     */
    reset = () => {
        return this.remove();
    }

}
