import _get from "lodash/get";
import { observable } from "mobx";

/**
 * статус заявки
 */
export class ServiceRequestStatus {

    /**
     * ресурс заявки
     */
    @observable resource = null;

    /**
     * инициализация
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * код статуса заявки
     */
    get code() {
        return {
            "break": "SS001034",
            "created": "SS001022",
            "deleted": "SS014038",
            "matching": "SS001035",
            "canceled": "SS014037",
            "assigned": "SS001036",
            "finished": "SS001033"
        }[this.value] || "SS015031";
    }

    /**
     * значение статуса заявки
     */
    get value() {
        if ( this.resource.status == "draft" && ["proposal"].includes(this.resource.intent) ) {
            return "created"; // создана SS00022
        }
        if ( this.resource.status == "entered-in-error" && ["proposal"].includes(this.resource.intent) ) {
            return "deleted"; // удалена SS00039
        }
        if ( this.resource.status == "on-hold" && ["plan"].includes(this.resource.intent) ) {
            return "matching"; // на согласовании SS00040
        }
        if ( this.resource.status == "on-hold" && ["proposal"].includes(this.resource.intent) ) {
            return "break"; // возвращена на доработку SS00041
        }
        if ( this.resource.status == "revoked" && ["plan", "order", "proposal"].includes(this.resource.intent) ) {
            return "canceled"; // аннулирована SS00042
        }
        if ( this.resource.status == "active" && ["plan", "order"].includes(this.resource.intent) ) {
            return "assigned"; // готова для рассмотрения SS00043
        }
        if ( this.resource.status == "completed" && ["order"].includes(this.resource.intent) ) {
            return "finished"; // рассмотрена SS00044
        }
        return "error";
    }

    /**
     * возвращена на доработку
     */
    get isBreak() {
        return this.value == "break";
    }

    /**
     * создана
     */
    get isCreated() {
        return this.value == "created";
    }

    /**
     * готова для рассмотрения
     */
    get isAssigned() {
        return this.value == "assigned";
    }

    /**
     * на согласовании
     */
    get isMatching() {
        return this.value == "matching";
    }

    /**
     * аннулирована
     */
    get isCanceled() {
        return this.value == "canceled";
    }

    /**
     * рассмотрена
     */
    get isFinished() {
        return this.value == "finished";
    }

    /**
     * может ли иметь причину отклонения/аннулирования заявки?
     */
    get canHaveComment() {
        return this.isBreak || this.isCanceled;
    }

    /**
     * может ли существовать протокол в этом статусе?
     */
    get canHaveProtocol() {
        return this.isActiveOrder || this.isCompletedOrder;
    }

    /**
     * создана?
     */
    get isDraftProposal() {
        return this.resource.status == "draft" && this.resource.intent == "proposal";
    }

    /**
     * удалена после создания?
     */
    get isEnteredInErrorProposal() {
        return this.resource.status == "entered-in-error" && this.resource.intent == "proposal";
    }

    /**
     * на согласовании?
     */
    get isOnHoldPlan() {
        return this.resource.status == "on-hold" && this.resource.intent == "plan";
    }

    /**
     * возвращена на доработку?
     */
    get isOnHoldProposal() {
        return this.resource.status == "on-hold" && this.resource.intent == "proposal";
    }

    /**
     * готова для рассмотрения?
     */
    get isActiveOrder() {
        return this.resource.status == "active" && this.resource.intent == "order";
    }

    /**
     * рассмотрена?
     */
    get isCompletedOrder() {
        return this.resource.status == "completed" && this.resource.intent == "order";
    }

    /**
     * аннулирована ли?
     */
    get isRevoked() {
        return this.resource.status == "revoked";
    }

    /**
     * аннулирована на согласовании?
     */
    get isRevokedPlan() {
        return this.resource.status == "revoked" && this.resource.intent == "plan";
    }

    /**
     * аннулирована на рассмотрении?
     */
    get isRevokedOrder() {
        return this.resource.status == "revoked" && this.resource.intent == "order";
    }

    /**
     * аннулирована на доработке?
     */
    get isRevokedProposal() {
        return this.resource.status == "revoked" && this.resource.intent == "proposal";
    }

    /**
     * можно ли аннулировать заявку в этом статусе?
     */
    get isRevocable() {
        return this.isOnHoldPlan || this.isOnHoldProposal || this.isActiveOrder;
    }

}
