import React from 'react';
import styled from "styled-components";

const AuthMainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #F0F1F3;
`;

const Auth = styled.div`
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  width: 320px;
  box-shadow: 0 2px 8px 0 #0000001A;
`;

export class AuthWrapper extends React.Component{
    render() {

        const {children} = this.props;

        return (
            <AuthMainWrapper>
                <Auth>
                    {children}
                </Auth>
            </AuthMainWrapper>
        )
    }
}
