import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

/**
 * цель проведения консилиума
 * (модель)
 * тут имеется в виду поле reasonCode,
 * где у нас лежит что-то вроде "Определение тактики лечения"
 */
export class ServiceRequestReason {

    /**
     * система кодирования
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.11.1506";

    /**
     * ссылка на ресурс заявки
     */
    serviceRequestResource = null;

    /**
     * инициализация
     */
    constructor(serviceRequestResource) {
        this.serviceRequestResource = serviceRequestResource;
    }

    /**
     * поиск необходимого CodeableConcept в ServiceRequest.reasonCode[]
     */
    get concept() {
        return _get(this.serviceRequestResource, "reasonCode", []).find(codeableConcept => {
            return _get(codeableConcept, "coding", []).find(coding => {
                return _get(coding, "system") == ServiceRequestReason.CODING_SYSTEM;
            });
        });
    }

    /**
     * поиск coding в CodeableConcept
     */
    get coding() {
        return _get(this.concept, "coding", []).find(coding => {
            return _get(coding, "system") == ServiceRequestReason.CODING_SYSTEM;
        });
    }

    /**
     * код цели проведения консилиума (например, "5")
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * указана ли (валидация)?
     */
    get isDefined() {
        return !! this.code;
    }

    /**
     * название цели проведения консилиума (например, "Изменение тактики лечения")
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение для ReactSelect
     */
    get defaultValue() {
        return this.code && this.display ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * изменение цели проведения консилиума
     */
    сhange = (concept) => {
        if( ! Array.isArray(this.serviceRequestResource.reasonCode) ) {
            this.serviceRequestResource.reasonCode = [];
        }
        const codeableConcept = {
            text: concept.definition,
            coding: [{
                code: concept.code,
                system: concept.system,
                version: concept.version,
                display: concept.display
            }]
        }
        const index = this.serviceRequestResource.reasonCode.findIndex(codeableConcept => {
            return _get(codeableConcept, "coding", []).find(coding => {
                return _get(coding, "system") == ServiceRequestReason.CODING_SYSTEM;
            });
        });
        if ( index >= 0 ) {
            this.serviceRequestResource.reasonCode[index] = codeableConcept;
        } else {
            this.serviceRequestResource.reasonCode.push(codeableConcept);
        }

        this.sortReasonCodes();
    }

    /**
     * Сортировка ресурса для соблюдения нужной последовательности не зависимо от порядка заполнения формы
     */
    sortReasonCodes = () => {
        this.serviceRequestResource.reasonCode = this.serviceRequestResource.reasonCode.sort(
            (a, b) => a.hasOwnProperty('text') && b.hasOwnProperty('coding') ? 1 : -1 )
    }


    /**
     * Изменение причины проведения консилиума
     */
    changeText = (text) => {
        if(this.textIndex >= 0) {
            this.updateText(text);
        } else {
            this.createText(text);
        }
    }

    /**
     * Изменение причины проведения консилиума
     */
    updateText = (text) => {
        _get(this.serviceRequestResource, 'reasonCode', [])[this.textIndex] = { text };
    }

    /**
     * Создание причины проведения консилиума
     */
    createText = (text) => {
        if( ! Array.isArray(this.serviceRequestResource.reasonCode) ) {
            this.serviceRequestResource.reasonCode = [];
        }
        this.serviceRequestResource.reasonCode.push({ text });
    }

    /**
     * Причина проведения консилиума
     */
    get text() {
        return _get(_get(this.serviceRequestResource, 'reasonCode', []).find(i => i.hasOwnProperty('text') && !i.hasOwnProperty('coding')), "text", "")
    }

    /**
     * поиск индекса text в Encounter.reasonCode[]
     */
    get textIndex() {
        return _get(this.serviceRequestResource, 'reasonCode', []).findIndex(i => i.hasOwnProperty('text') && !i.hasOwnProperty('coding'))
    }


    /**
     * указана ли (валидация)?
     */
    get isTextDefined() {
        return !_isEmpty(this.text);
    }

}
