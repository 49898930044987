import { AuthStore } from 'MODEL_STORE/DataSource/Stores/AuthStore';


/*

import { logAction } from 'Stat/logAction';


*/

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

const mapActionsToYm = {

    'AUTH:LOGIN' : { target: 'auth' },

    'APPOINTMENT:EDIT'   : { target: 'view_appointment', paramMapper: params => ({ entityId: params.appointmentId }) },
    'APPOINTMENT:LIST'   : { target: 'view_appointments_list' },
    'APPOINTMENT:CREATE' : { target: 'create_appointment', paramMapper: params => ({ entityId: params.appointmentId }) },
    'APPOINTMENT:UPDATE' : { target: 'edit_appointment', paramMapper: params => ({ entityId: params.appointmentId }) },
    'APPOINTMENT:DELETE' : { target: 'delete_appointment', paramMapper: params => ({ entityId: params.appointmentId }) },

    'SERVICEREQUEST:EDIT'   : { target: 'view_servicerequest', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:LIST'   : { target: 'view_servicerequests_list' },
    'SERVICEREQUEST:CREATE' : { target: 'create_servicerequest', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:UPDATE' : { target: 'edit_servicerequest', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:DELETE' : { target: 'delete_servicerequest', paramMapper: params => ({ entityId: params.serviceRequestId }) },

    'SERVICEREQUEST:STATUS:ONHOLD' : { target: 'servicerequest_onhold', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:STATUS:ACTIVE' : { target: 'servicerequest_active', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:STATUS:COMPLETED' : { target: 'servicerequest_completed', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:STATUS:ONHOLD-PROPOSAL' : { target: 'servicerequest_onhold_proposal', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:STATUS:REVOKED' : { target: 'servicerequest_revoked', paramMapper: params => ({ entityId: params.serviceRequestId }) },

    'SERVICEREQUEST:PRESENTATION:VIEW' : { target: 'view_presentation', paramMapper: params => ({ entityId: params.serviceRequestId }) },
    'SERVICEREQUEST:PRESENTATION:EDIT' : { target: 'edit_presentation', paramMapper: params => ({ entityId: params.serviceRequestId }) },

    'ORGANIZATION:EDIT'   : { target: 'view_organization', paramMapper: params => ({ entityId: params.organizationId }) },
    'ORGANIZATION:LIST'   : { target: 'view_organization_list' },
    'ORGANIZATION:CREATE' : { target: 'create_organization', paramMapper: params => ({ entityId: params.organizationId }) },
    'ORGANIZATION:UPDATE' : { target: 'edit_organization', paramMapper: params => ({ entityId: params.organizationId }) },
    'ORGANIZATION:DELETE' : { target: 'delete_organization', paramMapper: params => ({ entityId: params.organizationId }) },

    'PATIENT:EDIT'   : { target: 'view_patient', paramMapper: params => ({ entityId: params.patientId }) },
    'PATIENT:LIST'   : { target: 'view_patient_list' },
    'PATIENT:CREATE' : { target: 'create_patient', paramMapper: params => ({ entityId: params.patientId }) },
    'PATIENT:UPDATE' : { target: 'edit_patient', paramMapper: params => ({ entityId: params.patientId }) },
    'PATIENT:DELETE' : { target: 'delete_patient', paramMapper: params => ({ entityId: params.patientId }) },

    'PRACTITIONER:EDIT'   : { target: 'view_practitioner', paramMapper: params => ({ entityId: params.practitionerId }) },
    'PRACTITIONER:LIST'   : { target: 'view_practitioner_list' },
    'PRACTITIONER:CREATE' : { target: 'create_practitioner', paramMapper: params => ({ entityId: params.practitionerId }) },
    'PRACTITIONER:UPDATE' : { target: 'edit_practitioner', paramMapper: params => ({ entityId: params.practitionerId }) },
    'PRACTITIONER:DELETE' : { target: 'delete_practitioner', paramMapper: params => ({ entityId: params.practitionerId }) },

};



var fullList = {};

export function logAction(code, params) {

    params = params || {}

    // a bit of statistics
    if (!fullList[code]) {
        fullList[code] = 0;
    }
    fullList[code] += 1;

    // fancy logger marker
    const style = 'background-color: #00a; color: white; padding: 2px 4px 0px 4px;'

    //
    const userName = AuthStore.currentUserData && AuthStore.currentUserData.user ? AuthStore.currentUserData.user.userName : 'user unknown';

    console.log('%cACTION', style, code, params);
    console.log('user:', userName);
    console.log('stats:', fullList);

}
