/**
 * base class for both Element and Resource
 * no getters or setters here!
 */

import { observable, reaction } from 'mobx';
import uuid from 'uuid/v4';
import _clone from 'lodash.clone';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash.set';
import moment from "moment";
import { getFormat } from "Locale/datetime";

export class _BaseClass {

    /**
     *
     */
    _setClassName() {
        this._className = '_BaseClass';
    }

    /**
     * при наличии этого флажка ID не будет генерироваться
     * в дочернем классе обязательно выставлять его ДО super._init
     * хотя чую, это говнокод
     */
    _noAutoId = false;

    /**
     * признак того, что экземпляр создан с нуля, а не из ресурса
     */
    _isNewInstance = false;

    /**
     *
     */
    @observable
    _data;

    /**
     * сюда можно сложить оригинальные данные
     */
    _shadow;

    /**
     * клонируем данные, чтобы использоватиь где-то еще без привязки
     */
    _cloneData() {
        const cloned = _cloneDeep(this._data, true);

        if (cloned.hasOwnProperty('id')) {
            cloned.id = this._createId();
        }

        return cloned;
    }

    /**
     * делаем полный клон данных, с учетом Id
     */
    _cloneDataFull() {
        return _cloneDeep(this._data, true);
    }

    /**
     *
     * второй аргумент в конструкторе - колбек-функция, которую нужно вызвать при изменении данных
     */
    constructor(data = undefined, onChange = null) {

        this._setClassName();

        this._isNewInstance = typeof data == 'undefined';

        this._data = {};

        // TODO сделать распознавание, что нам подсунули - данные или модель

        this._init(data || {});

        // это дичайший говнокод, но уж как есть
        // если данные изменились, вызываем колбек
        if (onChange && typeof onChange == 'function') {
            reaction(
                () => JSON.stringify(this._data),
                () => onChange(this)
            );
        }
    }

    /**
     *
     */
    _init(data) {

        if ( ! this.hasOwnProperty('_className')) {
            throw 'Must specify _className at every descendant';
        }

        if ( ! this._noAutoId) {
            this._data.id = data.id || this._createId();
        }

    }

    /**
     *
     */
    _backup() {
        this._shadow = _clone(this._data, true);
    }

    /**
     *
     */
    _restore() {
        this._data = _clone(this._shadow, true);
    }

    /**
     *
     */
    _validate() {

        // TODO check ID
    }

    /**
     *
     */
    _validateFieldCode(value, valueReference) {

        return Object.values(valueReference).indexOf(value) >= 0;
    }

    /**
     *
     */
    _validateFieldDateTime(value) {

        // regex got from https://www.hl7.org/implement/standards/fhir/datatypes.html#dateTime
        return value.match(/([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?/);
    }

    /**
     *
     */
    _createId() {
        return uuid();
    }

    /**
     *
     */
    _arrayAdd(fieldName, resource) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            this._data[fieldName] = [];
        }

        this._data[fieldName].push(resource._data || resource);

        return this;
    }

    /**
     *
     */
    _arrayAddUnshift(fieldName, resource) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            this._data[fieldName] = [];
        }

        this._data[fieldName].unshift(resource._data || resource);

        return this;
    }

    _arrayAddIdex(fieldName, resource, index) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            this._data[fieldName] = [];
        }

        this._data[fieldName].splice(index, 0, resource._data || resource);

        return this;
    }

//    пока что компилятор неспособен обработать использование в классе его же наследника. Кто умеет - пишите.
//
//    /**
//     * у нас часто бывают массивы референсов, тут сделаем сразу шортхенд
//     */
//    _arrayAddReference(fieldName, resource) {
//
//        return this._arrayAdd(fieldName, Reference.fromResource(resource));
//    }

    /**
     *
     */
    _arrayDelete(fieldName, index) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            throw 'No field "' + fieldName + '"';
        }

        this._data[fieldName].splice(index, 1);

        return this;
    }

    /**
     *
     */
    _arrayReplaceById(fieldName, id, resource) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            throw `No field "${fieldName}"`;
        }

        if ( ! Array.isArray(this._data[fieldName])) {
            throw `Field "${fieldName}" is not an array`;
        }

        var index = this._data[fieldName].findIndex( elem => elem.id == id );

        if (index < 0) {
            throw `Element "${id}" is not found`;
        }

        this._data[fieldName][index] = resource._data;

        return this;
    }

    /**
     * resource = resource|string
     */
    _arrayReplaceByIndex(fieldName, index, resource) {

        if ( ! this._data.hasOwnProperty(fieldName)) {
            throw `No field "${fieldName}"`;
        }

        if ( ! Array.isArray(this._data[fieldName])) {
            throw `Field "${fieldName}" is not an array`;
        }

        if (index < 0) {
            throw `Bad index "${index}"`;
        }

        this._data[fieldName][index] = resource._data || resource;

        return this;
    }

    /**
     * некоторые поля при создании копируем как есть
     */
    _directCopyFields(data, list) {

        var existingFields = Object.keys(data);

        list.forEach( fieldName => {
            if (existingFields.indexOf(fieldName) >= 0) {
                this._data[fieldName] = data[fieldName];
            }
        });
    }

    /**
     * создание геттеров и сеттеров для неподдерживаемых свойств
     * на вход подаем массив названий полей
     */
    _unsupportedFields = (list) => {

        list.forEach(fieldName => {

            // здесь очень мерзко произведено обращение с this, нужно разобраться и исправить
            // хотя почему-то работает
            Object.defineProperty(this, fieldName, {
                get: () => {
                    throw 'Property ' + this._className + '.' + fieldName + ' is not supported';
                },
                set: (newValue) => {
                    throw 'Property ' + this._className + '.' + fieldName + ' is not supported';
                },
            });
        });

    }

    /**
     * установка поля типа dateTime
     * на вход можно подать строку или moment
     *
     * используем lodash.set, потому что в редких случаях поле не на первом уровне
     */
    _setDateTimeField(fieldName, newValue) {

        if (typeof newValue == 'string') {
            _set(this._data, fieldName, newValue);
            return;
        }

        if (moment.isMoment(newValue)) {
            _set(this._data, fieldName, newValue.format(getFormat("DT000005")));
            return;
        }

        if (newValue instanceof Date) {
            _set(this._data, fieldName, moment(newValue).format(getFormat("DT000005")));
            return;
        }

        console.error(`bad dateTime object for ${this._className}.${fieldName}:`, newValue);
        throw `bad dateTime object for ${this._className}.${fieldName}`;
    }

}

