/**
 * https://www.hl7.org/fhir/resource.html#Meta
 */

import { Element } from './Element';



export class Meta extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'Meta';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'source',
            'profile',
            'security',
        ]);
        
        // https://miramedix.atlassian.net/browse/MIRA-146
        if (data.tag) {
            this._data.tag = data.tag;
        }

        if (data.versionId) {
            this._data.versionId = data.versionId;
        }

        if (data.lastUpdated) {
            this._data.lastUpdated = data.lastUpdated;
        }

        if (data.createdAt) {
            this._data.createdAt = data.createdAt;
        }

    }

    /**
     *
     */
    _validate() {

        super._validate();

        // TODO check all
    }

    /**
     *
     */
    get versionId() {
        return this._data.versionId
    }

    /**
     *
     */
    set versionId(newValue) {
        this._data.versionId = newValue;
    }

    /**
     *
     */
    get lastUpdated() {
        return this._data.lastUpdated
    }

    /**
     *
     */
    set lastUpdated(newValue) {
        console.warn('setting Meta.lastUpdated is meaningless operation');
        this._data.lastUpdated = newValue;
    }

    /**
     *
     */
    get createdAt() {
        return this._data.createdAt
    }

}

