/**
 * https://www.hl7.org/implement/standards/fhir/datatypes.html#ContactPoint
 *
 * TODO реализовать ограничение, заявленное в стандарте - если непустое значение, то должно быть заполнено поле system
 *
 *
 * {
 *     "system": "email",
 *     "value": "info@ronc.ru",
 *     "use": "work"
 * },
 *
 */

import { Element } from './Element';
import { CONTACT_POINT_SYSTEM } from '../CodeSystem/ContactPointSystem';
import { CONTACT_POINT_USE } from '../CodeSystem/ContactPointUse';



export class ContactPoint extends Element {

    /**
     *
     */
    _setClassName() {
        this._className = 'ContactPoint';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'period',
        ]);

        this._directCopyFields(data, ['system', 'value', 'use', 'rank']);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get system() {
        return this._data.system;
    }

    /**
     *
     */
    set system(newValue) {
        this._data.system = newValue;
    }

    /**
     *
     */
    get value() {
        return this._data.value;
    }

    /**
     *
     */
    set value(newValue) {
        this._data.value = newValue;
    }

    /**
     *
     */
    get use() {
        return this._data.use;
    }

    /**
     *
     */
    set use(newValue) {
        this._data.use = newValue;
    }

}