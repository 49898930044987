import './basePath';
import 'core-js/modules/es.promise.finally';

import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { configure as configureMobx } from 'mobx';

import { BrowserRouter } from 'react-router-dom';
import { App } from 'App';
import { Toaster } from 'GUI_MAIN/Components/Toaster/Toaster';
import { hint } from 'GUI_MAIN/Utils/console';

import { TestStore } from 'MODEL_STORE/DataSource/Stores/TestStore';
import { AuthStore } from 'MODEL_STORE/DataSource/Stores/AuthStore';
import { ValuesetStore } from 'MODEL_STORE/DataSource/Stores/ValuesetStore';
import { ToasterStore } from 'MODEL_STORE/DataSource/Stores/ToasterStore';
import { CustomVersion } from "MODEL_STORE/DataSource/Stores/CustomVersion";
import { ContextualMenuStore } from 'GUI_MAIN/Elements/ContextualMenu/ContextualMenuStore';
import { DocumentReferenceStore } from 'MODEL_STORE/DataSource/Stores/DocumentReferenceStore';
import { DocumentViewerStore } from 'MODEL_STORE/DataSource/Stores/DocumentViewerStore';
import { WideScreenStore } from 'MODEL_STORE/DataSource/Stores/WideScreenStore';
import {ClientWindowStore} from 'MODEL_STORE/DataSource/Stores/ClientWindowStore';
import {SideMenuStore} from "GUI_MAIN/Components/SideMenu/SideMenuStore";
import {SidePanelsStore} from "GUI_MAIN/Components/PatientDocuments/SidePanelsStore";
import {MedicalCasesTableStore} from "GUI_MAIN/Components/Patient/Edit/MedicalCasesTableStore.js";
import { HistoryConnector } from 'GUI_MAIN/Utils/HistoryConnector';

import {PractitionerRolePopupStore} from 'MODEL_STORE/DataSource/Stores/PopupVisibilityStore';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import 'bootstrap/dist/css/bootstrap.css';

import './styles/general.sass';
import {GlobalStyle} from 'GUI_MAIN/Components/Fonts/FontsСonnection';
import {ThemeProvider} from "styled-components";
import {THEMES} from "./styles/themes";
import {HeaderStore} from "./MODEL_STORE/DataSource/Stores/HeaderStore";
import { config } from 'config';

import "core-js/proposals/string-replace-all";

console.hint = hint;

console.log('starting app....');

console.log('%cDEV VERSION', 'border: 1px white solid; font-weight: bold; color: white; background-color: red; padding: 2px 6px;');
console.log('version ' + __VERSION__ + ' ' + __BRANCH__ + ' / ' + __COMMIT_HASH__ + ', built at ' + __BUILD_DATE__);

configureMobx({
    // enforceActions: 'always'
});

// загрузка информации о версии приложения после конфига
config.load().then(CustomVersion.load).then( () => {

    config.validate(CustomVersion);

    const mobxStores = {
        TestStore,
        AuthStore,
        HeaderStore,
        ValuesetStore,
        ToasterStore,
        PractitionerRolePopupStore,
        ContextualMenuStore,
        DocumentReferenceStore: DocumentReferenceStore,
        DocumentViewerStore,
        WideScreenStore,
        ClientWindowStore,
        SideMenuStore,
        SidePanelsStore,
        MedicalCasesTableStore
    };

    console.log('BrowserRouter root: ' + __webpack_public_path__);

    const app = (
        <Provider { ...mobxStores }>
            <Fragment>
                <Toaster />
                <BrowserRouter basename={__webpack_public_path__}>
                    <HistoryConnector historyConnector={AuthStore.historyConnector} />
                    <GlobalStyle/>
                    <ThemeProvider theme={THEMES}>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </Fragment>
        </Provider>
    );

    ReactDOM.render(app, document.getElementById('root'));
});
