/**
 * тут у нас список всех OID, которые будут использованы
 *
 */


export const OID = {

    EMIAS_ID : 'urn:moscow:emias:entity:patient:id',

    ICD_10: 'urn:oid:1.2.643.5.1.13.13.11.1005', // МКБ-10. Версия 4. Международная классификация болезней и состояний, связанных со здоровьем 10 пересмотра. Версия 4

    MED_ORG_ROSTER_RU : 'urn:1.2.643.5.1.13.13.11.1461:5.151',
    POSITIONS_ROSTER : 'urn:1.2.643.5.1.13.13.11.1002',
    SPECIALTIES_ROSTER : 'urn:1.2.643.5.1.13.13.11.1066',

    PATIENT_MOBILITY: 'urn:mmdx:local:mobility',
    PATIENT_DISABILITY: 'urn:oid:1.2.643.5.1.13.13.11.1053',

}
