import _get from "lodash/get";

/**
 * класс для управления методом подтверждения диагноза
 */
export class ConditionEvidence {

    /**
     * система кодирования (Condition.evidence.code.coding.system)
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.99.2.128";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * элемент
     */
    get element() {
        return _get(this.conditionResource, "evidence", []).find(backboneElement => {
            return _get(backboneElement, "code", []).find(codeableConcept => {
                return _get(codeableConcept, "coding", []).find(coding => {
                    return _get(coding, "system") == ConditionEvidence.CODING_SYSTEM;
                });
            });
        });
    }

    /**
     * концепт
     */
    get concept() {
        return _get(this.element, "code", []).find(codeableConcept => {
            return _get(codeableConcept, "coding", []).find(coding => {
                return _get(coding, "system") == ConditionEvidence.CODING_SYSTEM;
            });
        });
    }

    /**
     * кодирование
     */
    get coding() {
        return _get(this.concept, "coding", []).find(coding => {
            return _get(coding, "system") == ConditionEvidence.CODING_SYSTEM;
        });
    }

    /**
     * описание
     */
    get text() {
        return _get(this.concept, "text", "");
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * значение (ReactSelect)
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.text || this.display
        } : "";
    }

    /**
     * изменение
     */
    onChange = ({concept}) => {
        this.conditionResource.evidence = [{
            "code": [{
                "coding": [{
                    code: concept.code,
                    system: concept.system,
                    display: concept.display,
                    version: concept.version,
                }],
                "text": concept.definition
            }]
        }]
    }

    /**
     * сброс значения
     */
    reset = () => {
        if( this.conditionResource.evidence ) {
            delete(this.conditionResource.evidence);
        }
    }

}
