import _get from "lodash/get";
import { observable } from "mobx";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";
import {AuthStore} from "MODEL_STORE/DataSource/Stores/AuthStore";

import {OrganizationsData} from "GUI_MAIN/Components/Organization/LoadOrganizations"

/**
 * количество загружаемых ресурсов организаций
 */
const RESOURCES_COUNT = 999;

/**
 * собираем опцию из ресурса (ReactSelect)
 */
const MAKE_OPTION = resource => ({
    value: resource.id,
    label: resource.name
})

/**
 * параметры запроса организаций
 */
const REQUEST_PARAMS = {
    "active": true,
    "_sort": "name",
    "_count": RESOURCES_COUNT,
    "_elements": [
        "id",
        "name"
    ].join(",")
}

export class OrganizationFilter {

    /**
     * идентификатор выбранной организации
     */
    @observable value = AuthStore.currentUserData.active_organization_id || "";

    /**
     * массив ресурсов организаций
     */
    @observable resources = [];

    organizationData = new OrganizationsData();

    /**
     * загружаем ресурсы организаций
     */
    constructor() {
        this.loadResources();
    }

    /**
     * загрузка ресурсов организаций
     * нужны только идентификаторы
     * и названия организаций
     * отсортированные по названию
     */
    loadResources = () => {
      this.organizationData.loadOrganizationsData().then(organizations => {
            this.resources = organizations;
          }).catch(this.onLoadFailure);
    }

    /**
     * при успешной загрузке ресурсов организаций
     */
    // onLoadSuccess = (response) => {
    //     this.resources = response.data.entry.map(entry => entry.resource);
    // }

    /**
     * при неудачной загрузке ресурсов организаций
     */
    onLoadFailure = (error) => {
        console.error("При загрузке ресурсов организаций произошла ошибка", error);
    }

    /**
     * выбранное значение организации (для React-Select)
     */
    get option() {
        return this.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения организаций (для React-Select)
     */
    get options() {
        return [...this.resources.map(MAKE_OPTION)];
    }

    /**
     * установка идентификатора выбранной организации
     */
    setOption = (selected) => {
        if(selected){
            this.value = _get(selected, "value", "");
        } else {
            this.value = ""
        }
        this.setLocalStorage();
    }

    /**
     * сброс фильтра (идентификатора выбранной организации)
     */
    reset = () => {
        this.value = "";
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
