import _get from "lodash/get";
import { CONFIRMED_CODE } from "MODEL_STORE/DataSource/Constants/valuesets";


/**
 * класс для управления состоянием подтверждения заболевания
 */
export class ConditionVerificationStatus {

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * код
     */
    get code() {
        return _get(this.conditionResource, "verificationStatus.coding.0.code");
    }

    /**
     * название
     */
    get display() {
        return _get(this.conditionResource, "verificationStatus.coding.0.display");
    }

    /**
     * подтверждено ли заболевание?
     */
    get isConfirmed() {
        return this.code == CONFIRMED_CODE;
    }

    /**
     * значение состояния (ReactSelect)
     */
    get value() {
        return (this.code && this.display) ? {
            value: this.code,
            label: this.display
        } : "";
    }

    /**
     * изменение состояния
     * https://miramedix.atlassian.net/browse/TB-999
     */
    onChange = ({concept}) => {
        this.conditionResource.verificationStatus = {
            coding: [{
                code: concept.code,
                system: concept.system,
                display: concept.display,
                userSelected: true
            }]
        }
    }

    /**
     * сброс состояния
     */
    reset = () => {
        if ( this.conditionResource.verificationStatus ) {
            delete(this.conditionResource.verificationStatus);
        }
    }

}
