import axios from 'axios';
import { observable } from "mobx";
import * as path from 'path';
import _get from 'lodash/get';

//
// конфиг будет загружаться из адреса, указанного в переменной window.oncoParams.сonfigPath
// это сделано для того, чтобы можно было подменять адрес конфига
//
// если адрес не будет указан - по умолчанию /config.json (именно из корня)
// (!) не пытайтесь делать относительные пути
//

class ConfigStoreClass {

    @observable loaded = false;

    config = {};

    basePath = () => {
        return _get(window, 'oncoParams.basePath' , '').replace(/\/+$/, '') + '/';
    }

    configPath = () => {
        return _get(window, 'oncoParams.configPath' , '/config.json');
    }

    get = (paramName, defaultValue) => {

        if (Object.keys(this.config).indexOf(paramName) < 0) {

            if (typeof defaultValue !== 'undefined') {
                return defaultValue;
            }
            console.warn(`parameter ${paramName} not found at config and default value is not provided`);
            return undefined;
        }

        return this.config[paramName];
    }

    load = () => {
        var url = path.join(this.configPath()) + '?d=' + Math.random();
        console.log('loading config from ' + url);

        // no axios wrapper - public endpoint
        return axios.get(url).then(response => {
            this.config = response.data;
            this.loaded = true;
        });
    }

    /**
     * некоторые кастомные конфиги нужно просто хардкодно перезаписать
     */
    override = (values) => {
        console.log('overriding config with these values:', values);
        this.config = { ...this.config, ...values };
    }

    /**
     * сюда можно положить проверку корректности конфига
     * класс CustomVersion передается, чтобы побороть Circular Dependency
     */
    validate = (CustomVersion) => {
    }

    get EXTERNAL_ADDRESS() {
        return this.get('EXTERNAL_ADDRESS').replace(/(https?:\/\/[^\/]+)\/?.*/, '$1/');
    }

    get AIDBOX_BASE_URL() {
        return this.get('AIDBOX_BASE_URL', '') || `${this.EXTERNAL_ADDRESS}box/`;
    }

    get BACKEND_BASE_URL() {
        return this.get('BACKEND_BASE_URL', '') || `${this.EXTERNAL_ADDRESS}backend-tb/`;
    }

    get BACKEND_V2_BASE_URL() {
        return this.get('BACKEND_V2_BASE_URL', '') || `${this.EXTERNAL_ADDRESS}api_v2/`;
    }

    get RENDER_REPORT_SERVICE_URL() {
        return this.get('RENDER_REPORT_SERVICE_URL', '') || `${this.EXTERNAL_ADDRESS}pdfgen/`;
    }

    get FILE_STORAGE_BASE_URL() {
        return this.get('FILE_STORAGE_BASE_URL', '') || `${this.EXTERNAL_ADDRESS}storage/`;
    }

}

export { ConfigStoreClass }
