import _get from "lodash/get";

/**
 * гистологический тип и код МКБ-О (морфологический тип опухоли)
 */
export class ConditionHistology {

    /**
     * система кодирования (минздрав)
     */
    static CODING_SYSTEM = "urn:oid:1.2.643.5.1.13.13.11.1486";

    /**
     * url расширения
     */
    static EXTENSION_URL = "http://hl7.org/fhir/us/mcode/StructureDefinition/onco-core-HistologyMorphologyBehavior-extension";

    /**
     * ссылка на ресурс заболевания
     */
    conditionResource = null;

    /**
     * conditionResource - ссылка на ресурс заболевания
     */
    constructor(conditionResource) {
        this.conditionResource = conditionResource;
    }

    /**
     * ссылка на расширение
     */
    get extension() {
        return _get(this.conditionResource, "extension", []).find(extension => {
            return extension.url == ConditionHistology.EXTENSION_URL;
        });
    }

    /**
     * индекс расширения
     */
    get extensionIndex() {
        return _get(this.conditionResource, "extension", []).findIndex(extension => {
            return extension.url == ConditionHistology.EXTENSION_URL;
        });
    }

    /**
     * ищем CodableConcept в расширении (предусмотрена обратная совместимость со справочниками)
     */
    get coding() {
        return _get(this.extension, "valueCodeableConcept.coding", []).find(coding => {
            return coding.system == ConditionHistology.CODING_SYSTEM;
        });
    }

    /**
     * система
     */
    get system() {
        return _get(this.coding, "system", "");
    }

    /**
     * описание
     */
    get text() {
        return _get(this.extension, "valueCodeableConcept.text", "");
    }

    /**
     * код
     */
    get code() {
        return _get(this.coding, "code", "");
    }

    /**
     * название
     */
    get display() {
        return _get(this.coding, "display", "");
    }

    /**
     * код МКБ-О
     */
    get icdoCode() {
        return this.display;
    }

    /**
     * код + название
     */
    get title() {
        return `${this.code}: ${this.display}`;
    }

    /**
     * значение для ReactSelect
     */
    get value() {
        return this.code ? {
            value: this.code,
            label: this.title,
        } : "";
    }

    /**
     * для клинического описания диагноза
     * см. generateDescription метод в Data
     */
    get description() {
        const text = this.display.toLowerCase();
        return `: ${text} [${this.code}]; `;
    }

    /**
     * при изменении кода
     */
    onChange = ({concept}) => {
        const extension = {
            url: ConditionHistology.EXTENSION_URL,
            valueCodeableConcept: {
                text: concept.definition,
                coding: [{
                    code: concept.code,
                    system: concept.system,
                    display: concept.display,
                    version: concept.version,
                }]
            }
        }
        if ( ! Array.isArray(this.conditionResource.extension) ) {
            this.conditionResource.extension = [];
        }
        const { extensionIndex } = this;
        if( extensionIndex >= 0 ) {
            this.conditionResource.extension[extensionIndex] = extension;
        } else {
            this.conditionResource.extension.push(extension);
        }
    }

    /**
     * сброс значения
     */
    reset = () => {
        if ( Array.isArray(this.conditionResource.extension) ) {
            const { extensionIndex } = this;
            if( extensionIndex >= 0 ) {
                this.conditionResource.extension.splice(extensionIndex, 1);
            }
        }
    }

}
