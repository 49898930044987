/**
 * штуковина для отладки
 */

import { observable, computed, autorun, action } from 'mobx';
import { toJS } from 'mobx';
import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';

class TestStoreClass {

    /**
     *
     */
    @observable
    loading = false;



    /**
     *
     */
    @action
    setLoadingState = (newValue) => {
        this.loading = !! newValue;
    }

    /**
     *
     */
    @observable
    data = null;



    /**
     *
     */
    @action
    setData = (newData) => {
        this.data = newData;
    }


    /**
     *
     */
    constructor() {
        autorun(() => {
        });
    }



    /**
     *
     */
    refresh = () => {

        this.setLoadingState(true);

        basicRequestGet('/Practitioner/?name=farn')
            .then( response => {
                this.setLoadingState(false);
                this.setData(response.data);
            });
    }

}


const TestStore = new TestStoreClass();

export { TestStore };
