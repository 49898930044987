/**
 *
 * https://www.hl7.org/implement/standards/fhir/practitioner.html
 *
 * типовой формат:
 *
 * {
 *     "identifier":[
 *         {                       <-- по соглашению, СНИЛС идет первым (хотя вроде обходимся без этого)
 *             "use":"official",
 *             "system":"urn:oid:1.2.643.100.3",
 *             "value":"132-456-789 90"
 *         },
 *         {
 *             "use":"official",
 *             "system":"1.2.643.5.1.13.13.12.2.77.7973.100.1.1.70",
 *             "value":"105"
 *         }
 *     ],
 *     "active":true,
 *     "name":[
 *         {
 *             "use":"official",
 *             "text":"Пирогов Н. И.",
 *             "family":"Пирогов",
 *             "given":[
 *                 "Николай",
 *                 "Иванович"
 *             ]
 *         }
 *     ],
 *     "telecom":[
 *         {
 *             "system":"phone",
 *             "value":"+7 (495) 123-45-67 доб. 89"
 *         },
 *         {
 *             "system":"email",
 *             "value":"mailbox@example.ru"
 *         }
 *     ],
 *     "address":[
 *         {
 *             "use":"home",
 *             "type":"both",
 *             "line":[
 *                 "микрорайон АБ, дом 3, корпус 2, квартира 136-137"
 *             ],
 *             "city":"город Пущино",
 *             "district":"Серпуховской район",
 *             "state":"50",
 *             "postalCode":"142280",
 *             "country":"RU"
 *         },
 *         {
 *             "use":"temp",
 *             "type":"both",
 *             "line":[
 *                 "микрорайон АБ, дом 3, корпус 2, квартира 136-137"
 *             ],
 *             "city":"город Пущино",
 *             "district":"Серпуховской район",
 *             "state":"50",
 *             "postalCode":"142280",
 *             "country":"RU"
 *         }
 *     ],
 *     "gender":"male",
 *     "birthDate":"1986-01-06" *
 * }
 *
 */

import { computed } from 'mobx';

import { DomainResource } from './DomainResource';
import { HumanName } from '../Elements/HumanName';
import { ContactPoint } from '../Elements/ContactPoint';
import { Address } from '../Elements/Address';
import { Identifier } from '../Elements/Identifier';

import { SNILS_IDENTIFIER_SYSTEM } from "MODEL_STORE/DataSource/Constants/systems";

export class Practitioner extends DomainResource {

    /**
     *
     */
    _setClassName() {
        this._className = 'Practitioner';
    }

    /**
     *
     */
    _init(data) {

        super._init(data);

        this._unsupportedFields([
            'photo',
            'qualification',
            'communication',
        ]);

        if (Array.isArray(data.name)) {
            data.name.forEach( e => {
                this._arrayAdd('name', new HumanName(e));
            });
        }
        // по соглашению, у нас ровно одно имя. поэтому если нету, сделаем
        else {
            this._arrayAdd('name', new HumanName());
        }

        if (Array.isArray(data.telecom)) {
            data.telecom.forEach( e => {
                this._arrayAdd('telecom', new ContactPoint(e));
            });
        }

        if (Array.isArray(data.identifier)) {
            data.identifier.forEach( e => {
                this._arrayAdd('identifier', new Identifier(e));
            });
        }

        if (Array.isArray(data.address)) {
            data.address.forEach( e => {
                this._arrayAdd('address', new Address(e));
            });
        }

        if (data.hasOwnProperty('birthDate')) {
            this._data.birthDate = data.birthDate;
        }

        this._data.gender = data.hasOwnProperty('gender') ? data.gender : 'unknown';

        this._data.active = ! data || ! data.hasOwnProperty('active') || !! data.active; // по умолчанию активен

        //
    }

    /**
     * функция валидации
     * на выходе список ошибок в формате { поле, сообщение }
     */
    _validate() {

        super._validate();

        if (this._data.hasOwnProperty('name')) {
            this._data.name.forEach(name => {
                (new HumanName(name))._validate();
            });
        }

        if (this._data.hasOwnProperty('telecom')) {
            this._data.telecom.forEach(telecom => {
                (new ContactPoint(telecom))._validate();
            });
        }

        if (this._data.hasOwnProperty('address')) {
            this._data.address.forEach(address => {
                (new Address(address))._validate();
            });
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    get identifier() {

        if ( ! this._data.identifier) {
            return [];
        }
        return this._data.identifier.map( e => new Identifier(e, e => this._arrayReplaceById('identifier', e.id, e) ) );
    }

    /**
     *
     */
    set identifier(newValue) {
        this._data.identifier = newValue.map( e => e._data );
    }

    /**
     *
     */
    get active() {
        return !! this._data.active
    }

    /**
     *
     */
    set active(newValue) {
        this._data.active = !! newValue;
    }

    /**
     *
     */
    get name() {

        if ( ! this._data.name) {
            return [];
        }
        return this._data.name.map( e => new HumanName(e, e => this._arrayReplaceById('name', e.id, e) ) );
    }

    /**
     *
     */
    set name(newValue) {
        this._data.name = newValue.map( e => e._data );
    }

    /**
     *
     */
    get telecom() {

        if ( ! this._data.telecom) {
            return [];
        }
        return this._data.telecom.map( e => new ContactPoint(e, e => this._arrayReplaceById('telecom', e.id, e) ) );
    }

    /**
     *
     */
    set telecom(newValue) {
        this._data.telecom = newValue.map( e => e._data );
    }

    /**
     *
     */
    get address() {

        if ( ! this._data.address) {
            return [];
        }
        return this._data.address.map( e => new Address(e, e => this._arrayReplaceById('address', e.id, e) ) );
    }

    /**
     *
     */
    set address(newValue) {
        this._data.address = newValue.map( e => e._data );
    }

    /**
     *
     */
    get gender() {
        return this._data.gender;
    }

    /**
     *
     */
    set gender(newValue) {
        this._data.gender = newValue;
    }

    /**
     *
     */
    get birthDate() {
        return this._data.birthDate;
    }

    /**
     *
     */
    set birthDate(newValue) {
        this._data.birthDate = newValue;
    }

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     * проверка на наличие имени
     */
    hasName() {
        return Array.isArray(this._data.name)
            && this._data.name.length;
    }

    /**
     * удобочитаемое имя в одну строку
     */
    get displayName() {
        return this.hasName()
            ? HumanName.fullNameShort(this._data.name[0])
            : 'неизвестный';
    }

    /**
     * полное имя в одну строку
     */
    get displayFullName() {
        return this.hasName()
            ? HumanName.fullNameFull(this._data.name[0])
            : 'неизвестный';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     */
    @computed
    get snils() {

        if ( ! Array.isArray(this._data.identifier)) {
            return '';
        }

        var idIndex = this._data.identifier.findIndex( e => e.system == SNILS_IDENTIFIER_SYSTEM);

        if (idIndex < 0) {
            return '';
        }

        return this._data.identifier[idIndex].value;
    }

    /**
     *
     */
    set snils(newValue) {

        var idIndex = 0;

        if ( ! Array.isArray(this._data.identifier)) {
            this._data.identifier = [];
        }

        idIndex = this._data.identifier.findIndex( e => e.system == SNILS_IDENTIFIER_SYSTEM);

        if (idIndex < 0) {

            idIndex = this._data.identifier.length;

            // TODO вообще надо через new Identifier делать
            this._data.identifier[idIndex] = {
                'use' : 'official',
                'system' : SNILS_IDENTIFIER_SYSTEM,
            }
        }

        this._data.identifier[idIndex].value = newValue;
    }

    /**
     * полный список список ролей для врача, дополнительно можно отфильтровать по организации
     */
    rolesAll(practitionerRoleStore, organization = null) {

        return practitionerRoleStore.data.filter( role => {

            return (
                role._data.practitioner.id == this._data.id
                &&
                ( ! organization || role._data.organization.id == organization._data.id)
            )
        });
    }

    /**
     * тот же список, но без "удаленных" записей
     */
    rolesActive(practitionerRoleStore, organization = null) {

        return this.rolesAll(practitionerRoleStore, organization).filter( role => ! role._detached);
    }


}
