import _get from "lodash/get";
import { observable } from "mobx";

const KIND_OF_MEDICAL_CARD_SYSTEM = "http://miramedix.ru/fhir/cs/fcsp-cs-nsi-1-2-643-5-1-13-13-11-1522"

/**
 * инвалидность пациента
 */
export class KindOfMedicalCard {

    /**
     * ссылка на ресурс
     */
    @observable resource = null;

    /**
     * id идентификатора случая в пациенте
     */
    @observable medicalCardNumberIdentifierId = null;

    /**
     * инициализация
     */
    constructor(medicalCardNumberIdentifierId, patient) {
        this.resource = patient;
        this.medicalCardNumberIdentifierId = medicalCardNumberIdentifierId;
    }

    /**
     * Получение типа
     */
    get kindOfMedicalCardCoding() {
        return _get(this.medicalCardNumberIdentifier, "type.coding", []).find(coding =>
            coding.system == KIND_OF_MEDICAL_CARD_SYSTEM);
    }

    /**
     * Получение идентификатора номера карты
     */
    get medicalCardNumberIdentifier() {
        const system = "urn:oid:1.2.643.5.1.13.13.12.2.66.6795.100.1.1.16"
        return _get(this.resource, "identifier", []).find(i =>
            i.system === system && (this.medicalCardNumberIdentifierId ?
              i.id == this.medicalCardNumberIdentifierId : !i.id));
    }

    /**
     * Отображение
     */
    get display() {
        return this.kindOfMedicalCardCoding ? this.kindOfMedicalCardCoding.display : "";
    }
    /**
     * Код
     */
    get code() {
        return this.kindOfMedicalCardCoding ? this.kindOfMedicalCardCoding.code : "";
    }

    /**
     * значение
     */
    get defaultValue() {
        return this.code && this.display ? {
            value: this.code,
            label: this.display
        } : "";
    }

    onChange = (concept) => {
        if (!this.kindOfMedicalCardCoding) {
            this.createKindOfMedicalCardCoding();
        }
        this.kindOfMedicalCardCoding.display = concept.label;
        this.kindOfMedicalCardCoding.code = concept.value;
    }

    createKindOfMedicalCardCoding = () => {
        if ( ! Array.isArray(this.medicalCardNumberIdentifier.type.coding) ) {
            this.medicalCardNumberIdentifier.type.coding = []
        }
        this.medicalCardNumberIdentifier.type.coding.push({
            system: KIND_OF_MEDICAL_CARD_SYSTEM,
            version: '7.13',
            code: '',
            display: ""
        })
    }

}
